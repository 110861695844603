import { Injectable } from '@angular/core';
import { CommonhelperService } from './commonhelper.service';
import { HealthCareApiServiceProxy, PopulationEpisodeDataOutput, HospitalEventOutput, EpisodesOfDataSummary } from '@shared/service-proxies/service-proxies';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MyHealthcareService {
  currentEpisodeYear: string;
  episodeCareData = new Array<PopulationEpisodeDataOutput>();
  yearList: string[];
  hospitalEvents: HospitalEventOutput[];
  summary: EpisodesOfDataSummary;

  constructor(
    public _commonHelper: CommonhelperService,
    private _healthCare: HealthCareApiServiceProxy,
    private _authService: AuthService
  ) { }

  isMyHealthCareActive(): boolean {
    return this._commonHelper.healthcareactivated;
  }

  getEpisodeData(year: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._healthCare.getPopulationEpisodeData(this._authService.user.access_token, year).subscribe(res => {
        this.episodeCareData = res;
        resolve();
      }, (err) => {
        reject();
      });
    });
  }

  private getHealthCareYearDropDownList(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._healthCare.getPopulationEpisodeDataYear(this._authService.user.access_token).subscribe((res: string[]) => {
        this.yearList = res;
        resolve();
      }, (err) => {
        reject();
      });
    });
  }

  getEpisodeOfCare(year?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._commonHelper.getHealthCareActivatedStatus()
        .then(() => {
          this.getHealthCareYearDropDownList().then(() => {
            resolve();
          }).catch(() => {
            reject();
          });
        }).catch(() => {
          reject();
        });
    });
  }

  getHospitalEventData(year): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._healthCare.getHospitalEvents(this._authService.user.access_token, year)
        .subscribe((res) => {
          this.hospitalEvents = res;
          resolve();
        }, (err) => {
          reject();
        });
    });
  }

  getSummary() {
    return new Promise<EpisodesOfDataSummary>((resolve, reject) => {
      if (this._commonHelper.healthcareactivated) {
        this._healthCare.getSummaryHealthCare(this._authService.user.access_token).subscribe((res: EpisodesOfDataSummary) => {
          this.summary = res;
          resolve(this.summary);
        }, (err) => {
          reject(err);
        });
      } else {
        resolve(null);
      }
    });
  }
}
