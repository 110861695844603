import { Injectable } from '@angular/core';

@Injectable({
   providedIn: 'root'
})
export class MonthPipeService {

   monthArray: string[] = [];


   transform(items: Array<string>): any {
      this.monthArray = new Array<string>();
      if (items == null || items.length <= 0) {
         return null;
      }
      items.forEach(item => {
         switch (item) {
            case 'Jan': this.monthArray.push('1');
               break;
            case 'Feb': this.monthArray.push('2');
               break;
            case 'Mar': this.monthArray.push('3');
               break;
            case 'Apr': this.monthArray.push('4');
               break;
            case 'May': this.monthArray.push('5');
               break;
            case 'Jun': this.monthArray.push('6');
               break;
            case 'Jul': this.monthArray.push('7');
               break;
            case 'Aug': this.monthArray.push('8');
               break;
            case 'Sep': this.monthArray.push('9');
               break;
            case 'Oct': this.monthArray.push('10');
               break;
            case 'Nov': this.monthArray.push('11');
               break;
            case 'Dec': this.monthArray.push('12');
               break;
         }

      });

      return this.monthArray;
   }
}
