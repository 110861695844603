import { Injectable } from '@angular/core';
import { WearablesServiceProxy, GetActiveCalorieDto, GetStepsDto, GetActiveMinutesDto, GetDistanceDto, GetAllSourceAndInfoDTO, Last7Days, GetGoalsDto } from '@shared/service-proxies/service-proxies';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';
import { CommonhelperService } from './commonhelper.service';

@Injectable({
  providedIn: 'root'
})
export class MyWearablesService {
  goalsMessages: GetGoalsDto[];
  goalsMessage = '';
  activeCalories: GetActiveCalorieDto[];
  stepsDto: GetStepsDto[];
  activeMinues: GetActiveMinutesDto[];
  distanceDto: GetDistanceDto[];
  getAllSyncedAct: GetAllSourceAndInfoDTO[] = new Array<GetAllSourceAndInfoDTO>();
  last7Days: Last7Days = new Last7Days();

  constructor(
    public _commonHelper: CommonhelperService,
    private _wearables: WearablesServiceProxy,
    private _authService: AuthService
  ) { }

  isMyDataWearableActive(): boolean {
    return this._commonHelper.userPermissionAllowd;
  }



  //#region month and week

  getActiveCalorieWeek(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._wearables.getActiveCalorieWeek(this._authService.user.access_token)
        .subscribe((res) => {
          this.activeCalories = res;
          resolve();
        }, (err) => {
          reject();
        });
    });
  }

  getSteps(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._wearables.getStepsCurrentWeek(this._authService.user.access_token)
        .subscribe((res) => {
          this.stepsDto = res;
          resolve();
        }, (err) => {
          reject();
        });
    });
  }

  getActiveMinutesWeek(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._wearables.getActiveMinutesWeek(this._authService.user.access_token)
        .subscribe((res) => {
          this.activeMinues = res;
          resolve();
        }, (err) => {
          reject();
        });
    });
  }

  getDistanceTravelledWeek(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._wearables.getDistanceTravelledWeek(this._authService.user.access_token)
        .subscribe((res) => {
          this.distanceDto = res;
          resolve();
        }, (err) => {
          reject();
        });
    });
  }

  getAllSyncedIntenseCount(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._wearables.getAllSyncedIntenseCount(this._authService.user.access_token)
        .subscribe((res) => {
          this.getAllSyncedAct = res;
          resolve();
        }, (err) => {
          reject();
        });
    });
  }

  getLast7Async(): Promise<Last7Days> {
    return new Promise<Last7Days>((resolve, reject) => {
      this._wearables.getLast7Async(this._authService.user.access_token)
        .subscribe((res) => {
          this.last7Days = res;
          resolve(res);
        }, (err) => {
          reject();
        });
    });
  }

  //#endregion

  //#region year
  getStepsYear(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._wearables.getStepsCurrentYear(this._authService.user.access_token)
        .subscribe((res) => {
          this.stepsDto = res;
          resolve();
        }, (err) => {
          reject();
        });
    });
  }

  getActiveMinutesYear(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._wearables.getActiveMinutesYear(this._authService.user.access_token)
        .subscribe((res) => {
          this.activeMinues = res;
          resolve();
        }, (err) => {
          reject();
        });
    });
  }

  getDistanceTravelledYear(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._wearables.getDistanceTravelledYear(this._authService.user.access_token)
        .subscribe((res) => {
          this.distanceDto = res;
          resolve();
        }, (err) => {
          reject();
        });
    });
  }

  getActiveCalorieYear(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._wearables.getActiveCalorieYear(this._authService.user.access_token)
        .subscribe((res) => {
          this.activeCalories = res;
          resolve();
        }, (err) => {
          reject();
        });
    });
  }

  //#endregion

  getGoalsAndCheckIfEntityRegistered(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._commonHelper.isEntityRegisteredWearables().then(() => {
        this.GetGoals().then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      });
    });
  }

  private GetGoals(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._wearables.getGoals(this._authService.user.access_token).subscribe((res: GetGoalsDto[]) => {
        this.goalsMessages = new Array<GetGoalsDto>();
        this.goalsMessages = res;
        if (res) {
          let d = new GetGoalsDto();
          d = this.goalsMessages.find(x => x.isAchieved === false);
          if (d === null || d === undefined) {
            if (res[0] !== undefined) {
              this.goalsMessage = res[0].message;
            }
          } else {
            this.goalsMessage = d.message;
          }
          if (this.goalsMessage === undefined) {
            this.goalsMessage = '';
          }
        }
        resolve();
      }, (err) => {
        reject();
      });
    });
  }

}

