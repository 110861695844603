var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { LayoutRefService } from '@metronic/app/core/services/layout/layout-ref.service';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AppConsts } from '@shared/AppConsts';
import { MenuAsideOffcanvasDirective } from '@metronic/app/core/directives/menu-aside-offcanvas.directive';
var Theme7LayoutComponent = /** @class */ (function (_super) {
    __extends(Theme7LayoutComponent, _super);
    function Theme7LayoutComponent(injector, layoutRefService) {
        var _this = _super.call(this, injector) || this;
        _this.layoutRefService = layoutRefService;
        _this.remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
        _this.defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + _this.currentTheme.baseSettings.menu.asideSkin + '.svg';
        return _this;
    }
    Theme7LayoutComponent.prototype.ngOnInit = function () {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    };
    Theme7LayoutComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.mMenuAsideOffcanvas = new MenuAsideOffcanvasDirective(_this.mAsideLeft);
            _this.mMenuAsideOffcanvas.ngAfterViewInit();
        });
        this.layoutRefService.addElement('header', this.mHeader.nativeElement);
    };
    return Theme7LayoutComponent;
}(ThemesLayoutBaseComponent));
export { Theme7LayoutComponent };
