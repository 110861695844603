import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FinancialsService = /** @class */ (function () {
    function FinancialsService(http) {
        this.http = http;
        this._url = '/assets/data/financials.json';
    }
    FinancialsService.prototype.getData = function () {
        //pass this data to
        return this.http.get(this._url)
            .catch(this.errorHandler);
    };
    FinancialsService.prototype.errorHandler = function (error) {
        return Observable.throw(error.message || 'Server Error');
    };
    FinancialsService.ngInjectableDef = i0.defineInjectable({ factory: function FinancialsService_Factory() { return new FinancialsService(i0.inject(i1.HttpClient)); }, token: FinancialsService, providedIn: "root" });
    return FinancialsService;
}());
export { FinancialsService };
var MessageService = /** @class */ (function () {
    function MessageService() {
        this.subject = new Subject();
        this.accountSubject = new Subject();
    }
    MessageService.prototype.sendMessage = function (message) {
        this.subject.next({ text: message });
    };
    MessageService.prototype.clearMessages = function () {
        this.subject.next();
        this.accountSubject.next();
    };
    MessageService.prototype.getMessage = function () {
        return this.subject.asObservable();
    };
    MessageService.ngInjectableDef = i0.defineInjectable({ factory: function MessageService_Factory() { return new MessageService(); }, token: MessageService, providedIn: "root" });
    return MessageService;
}());
export { MessageService };
