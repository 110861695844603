import { Product } from '../ProductFactory/Product';
import { BenefitPattern } from '../BenefitPatternFactory/BenefitPattern';
import { PremiumPattern } from '../PremiumPatternFactory/PremiumPattern';
import { RateTableEntry } from '../RateTableFactory/RateTableEntry';
import { AppConsts } from '../../../../shared/AppConsts';

export interface IAssociatedProduct extends Product {
    RequirementType: string;
    RiderPolicy: boolean;
    Accelerator: boolean;
    IsParentDependent: boolean;
    DependentParentGuid: string;
}

export class AssociatedProduct extends Product {
    RequirementType: string;
    RiderPolicy: boolean;
    Accelerator: boolean;
    IsParentDependent: boolean;
    DependentParentGuid: string;

    constructor(Uid: string, Name: string,
        MinBenefitAmount: number,
        MaxBenefitAmount: number,
        BenefitPatternSet: BenefitPattern[],
        PremiumPatternSet: PremiumPattern[],
        EventSet: string,
        Category: string,
        DisplayName: string,
        AssociatedProducts: AssociatedProduct[],
        DefaultBenefitPattern: BenefitPattern,
        DefaultPremiumPattern: PremiumPattern,
        RateTable: RateTableEntry[],
        RequirementType: string,
        RiderPolicy: boolean,
        Accelerator: boolean,
        IsDisablity: boolean,
        IsParentDependent: boolean,
        DependentParentGuid: string
    ) {
        super(Uid, Name,
            MinBenefitAmount,
            MaxBenefitAmount,
            BenefitPatternSet,
            PremiumPatternSet,
            EventSet,
            Category,
            DisplayName,
            AssociatedProducts,
            DefaultBenefitPattern,
            DefaultPremiumPattern,
            RateTable,
            IsDisablity);
        this.RequirementType = RequirementType;
        this.RiderPolicy = RiderPolicy;
        this.Accelerator = Accelerator;
        this.IsProductQuoted = this.RequirementType === AppConsts.requirementTyoe.Selectable ? false : true;
        this.IsParentDependent = IsParentDependent;
        this.DependentParentGuid = DependentParentGuid;
    }

    getIsBenefitTermEditable(): boolean {
        if (!this.DisplayName) {
            return false;
        }

        return this.DisplayName.toLowerCase().includes('disability') && this.Accelerator;
    }

    getAssociatedProductPremiumAmount(multiplicativeUndewritingLoading: number, additiveUnderwritingLoading: number): number {
        if (!this.RateTable) {
            return undefined;
        }

        let assuredValue = this.CurrentAssuredValue || this.DefaultAssuredValue;
        let benefitPatternUID = this.CurrentBenefitPattern ? this.CurrentBenefitPattern.UID : this.DefaultBenefitPattern.UID;
        let premiumPatternUID = this.CurrentPremiumPattern ? this.CurrentPremiumPattern.UID : this.DefaultPremiumPattern.UID;

        const getPremiumCalcOutput = (assuredValue: number, premiumRate: RateTableEntry): number => {
            return (assuredValue * premiumRate.UnitRate * premiumRate.BaseRate * multiplicativeUndewritingLoading) + (assuredValue * additiveUnderwritingLoading);
        };

        const ratesForBenefitPremiumPattern = this.RateTable
            .filter(r => r.BenefitPattern && r.BenefitPattern.UID === benefitPatternUID && r.PremiumPattern && r.PremiumPattern.UID === premiumPatternUID)
            .sort((a, b) => a.sumAssuredLimit - b.sumAssuredLimit);

        let premiumRate = ratesForBenefitPremiumPattern
            .filter(r => r.sumAssuredLimit <= this.CurrentAssuredValue && Math.abs(this.CurrentAssuredValue - r.sumAssuredLimit) < 100000)
            .pop();

        if (!premiumRate) {
            const rateLowerBound = ratesForBenefitPremiumPattern
                .filter(r => r.sumAssuredLimit <= this.CurrentAssuredValue)
                .pop();
            const sumAssuredLowerBound = rateLowerBound.sumAssuredLimit;
            const premiumLowerBound = getPremiumCalcOutput(sumAssuredLowerBound, rateLowerBound);

            const rateUpperBound = ratesForBenefitPremiumPattern
                .filter(r => r.sumAssuredLimit > this.CurrentAssuredValue)
                .pop();
            const sumAssuredUpperBound = rateUpperBound.sumAssuredLimit;
            const premiumUpperBound = getPremiumCalcOutput(sumAssuredUpperBound, rateUpperBound);

            const premium = premiumLowerBound + (this.CurrentAssuredValue - sumAssuredLowerBound) * ((premiumUpperBound - premiumLowerBound) / (sumAssuredUpperBound - sumAssuredLowerBound));
            return Math.round(premium);
        }

        let premium = getPremiumCalcOutput(assuredValue, premiumRate);
        return Math.round(premium);
    }

}


