import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MyWearablesRoutingModule } from './my-wearables-routing.module';
import { ModalModule, BsDropdownModule, TooltipModule, BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap';
import { AppCommonModule } from '@app/shared/common/app-common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { MyWearablesComponent } from './my-wearables.component';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { MyWearablesWidgetsModule } from '@app/shared/common/widgets/my-wearables-widgets/my-wearables-widgets.module';
import { MyWearablesCalenderComponent } from './my-wearables-calender/my-wearables-calender.component';

@NgModule({
  declarations: [MyWearablesComponent, MyWearablesCalenderComponent],
  imports: [
    CommonModule,
    MyWearablesRoutingModule,
    ModalModule,
    AppCommonModule,
    UtilsModule,
    NgbModule,
    BsDropdownModule.forRoot(),
    TooltipModule,
    RouterModule,
    MyWearablesWidgetsModule
  ], providers: [
    DatePipe,
    { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
    { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
    { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MyWearablesModule { }
