import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.css']
})
export class SupportModalComponent implements OnInit {

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  getWidth(): number {
    if (window.innerWidth < 500) {
      return window.innerWidth - 13;
    }

    return 400;
  }

  close(): void {
    this.bsModalRef.hide();
  }
}
