
export enum CreatePaymentDtoEditionPaymentType {
    _0 = 0,
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum CreatePaymentDtoPaymentPeriodType {
    _30 = 30,
    _365 = 365,
}

export enum CreatePaymentDtoSubscriptionPaymentGatewayType {
    _1 = 1,
    _2 = 2,
}

export enum CancelPaymentDtoGateway {
    _1 = 1,
    _2 = 2,
}

export enum PaymentGatewayModelGatewayType {
    _1 = 1,
    _2 = 2,
}

export enum SubscriptionPaymentDtoGateway {
    _1 = 1,
    _2 = 2,
}

export enum SubscriptionPaymentDtoPaymentPeriodType {
    _30 = 30,
    _365 = 365,
}

export enum SubscriptionPaymentDtoStatus {
    _1 = 1,
    _2 = 2,
    _3 = 3,
    _4 = 4,
    _5 = 5,
}

export enum TenantLoginInfoDtoSubscriptionPaymentType {
    _0 = 0,
    _1 = 1,
    _2 = 2,
}

export enum TenantLoginInfoDtoPaymentPeriodType {
    _30 = 30,
    _365 = 365,
}

export enum RegisterTenantInputSubscriptionStartType {
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum CloudBlockBlobBlobType {
    _0 = 0,
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum CloudBlobClientAuthenticationScheme {
    _0 = 0,
    _1 = 1,
    _2 = 2,
}

export enum BlobPropertiesBlobType {
    _0 = 0,
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum BlobPropertiesLeaseStatus {
    _0 = 0,
    _1 = 1,
    _2 = 2,
}

export enum BlobPropertiesLeaseState {
    _0 = 0,
    _1 = 1,
    _2 = 2,
    _3 = 3,
    _4 = 4,
    _5 = 5,
}

export enum BlobPropertiesLeaseDuration {
    _0 = 0,
    _1 = 1,
    _2 = 2,
}

export enum BlobPropertiesStandardBlobTier {
    _0 = 0,
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum BlobPropertiesRehydrationStatus {
    _0 = 0,
    _1 = 1,
    _2 = 2,
}

export enum BlobPropertiesPremiumPageBlobTier {
    _0 = 0,
    _1 = 1,
    _2 = 2,
    _3 = 3,
    _4 = 4,
    _5 = 5,
    _6 = 6,
    _7 = 7,
    _8 = 8,
}

export enum CopyStateStatus {
    _0 = 0,
    _1 = 1,
    _2 = 2,
    _3 = 3,
    _4 = 4,
}

export enum BlobRequestOptionsLocationMode {
    _0 = 0,
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum BlobContainerPropertiesLeaseStatus {
    _0 = 0,
    _1 = 1,
    _2 = 2,
}

export enum BlobContainerPropertiesLeaseState {
    _0 = 0,
    _1 = 1,
    _2 = 2,
    _3 = 3,
    _4 = 4,
    _5 = 5,
}

export enum BlobContainerPropertiesLeaseDuration {
    _0 = 0,
    _1 = 1,
    _2 = 2,
}

export enum BlobContainerPropertiesPublicAccess {
    _0 = 0,
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum IncomeStatisticsDateInterval {
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum IncomeStatisticsDateInterval2 {
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum State {
    _0 = 0,
    _1 = 1,
}

export enum SalesSummaryDatePeriod {
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum SalesSummaryDatePeriod2 {
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum DefaultTimezoneScope {
    _1 = 1,
    _2 = 2,
    _4 = 4,
    _7 = 7,
}

export enum IsTenantAvailableOutputState {
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum EntityChangeListDtoChangeType {
    _0 = 0,
    _1 = 1,
    _2 = 2,
}

export enum FriendDtoState {
    _1 = 1,
    _2 = 2,
}

export enum ChatMessageDtoSide {
    _1 = 1,
    _2 = 2,
}

export enum ChatMessageDtoReadState {
    _1 = 1,
    _2 = 2,
}

export enum ChatMessageDtoReceiverReadState {
    _1 = 1,
    _2 = 2,
}

export enum AccountPaymentHistoryDtoPaymentHistoryStatus {
    _0 = 0,
    _1 = 1,
    _2 = 2,
}

export enum UserNotificationState {
    _0 = 0,
    _1 = 1,
}

export enum TenantNotificationSeverity {
    _0 = 0,
    _1 = 1,
    _2 = 2,
    _3 = 3,
    _4 = 4,
}

export class AppChatMessageReadState {
    static Unread: number = ChatMessageDtoReadState._1;
    static Read: number = ChatMessageDtoReadState._2;
}

export enum QuoteGender {
    Male = 0,
    Female = 1,
    NonBinary = 2
}

export enum MaritialStatus {
    Single,
    Married,
    Domestic_partnership = 3,
    Divorced = 4,
    Widowed = 5,
    Cohabiting = 6,
}

export enum QuestionType {
    MultiAnswer,
    SingleSelect,
    FreeText,
    DataConsent,
    ConditionsSingleSelect,
    ConditionsMultiSelect,
    ConditionPopup,
    DNDQuesions,
    UnKown,
    Dob
}

export enum SmokerStatus {
    NonSmoker,
    Smoker
}

export enum Education {
    NoMatric,
    Matric,
    DiplomaLessThanThreeYear,
    DiplomaEqualOrMoreThanThreeYear,
    UnderGraduate,
    University4Years,
    PostGraduate
}

export enum PersonEducation {
    NoMatric,
    Matric,
    DiplomaLessThanFourYears,
    DiplomaFourYearsOrMoreThan,
    ThreeYearsUniversityUnderGraduateDegree,
    FourYearsUniversityUnderGraduateDegree,
    PostGraduateStudy
}

export class AppModiyClaimValue {
    static True = 'true';
    static False = 'false';
}

export class AppMyPermissionProtectedService {
    static AllowWearables = 'allow_wearable';
    static AllowHealthCare = 'allow_healthcare';
    static AllowCredit = 'allow_credit';
    static AllowFinancials = 'allow_financial';
}

export class AppChatSide {
    static Sender: number = ChatMessageDtoSide._1;
    static Receiver: number = ChatMessageDtoSide._2;
}

export class AppFriendshipState {
    static Accepted: number = FriendDtoState._1;
    static Blocked: number = FriendDtoState._2;
}


export class AppTimezoneScope {
    static Application: number = DefaultTimezoneScope._1;
    static Tenant: number = DefaultTimezoneScope._2;
    static User: number = DefaultTimezoneScope._4;
}

export class AppUserNotificationState {
    static Unread: number = UserNotificationState._0;
    static Read: number = UserNotificationState._1;
}

export class AppTenantAvailabilityState {
    static Available: number = IsTenantAvailableOutputState._1;
    static InActive: number = IsTenantAvailableOutputState._2;
    static NotFound: number = IsTenantAvailableOutputState._3;
}

export class AppIncomeStatisticsDateInterval {
    static Daily: number = IncomeStatisticsDateInterval._1;
    static Weekly: number = IncomeStatisticsDateInterval._2;
    static Monthly: number = IncomeStatisticsDateInterval._3;
}

export class SubscriptionStartType {
    static Free: number = RegisterTenantInputSubscriptionStartType._1;
    static Trial: number = RegisterTenantInputSubscriptionStartType._2;
    static Paid: number = RegisterTenantInputSubscriptionStartType._3;
}

export class EditionPaymentType {
    static NewRegistration: number = CreatePaymentDtoEditionPaymentType._0;
    static BuyNow: number = CreatePaymentDtoEditionPaymentType._1;
    static Upgrade: number = CreatePaymentDtoEditionPaymentType._2;
    static Extend: number = CreatePaymentDtoEditionPaymentType._3;
}

export class AppEditionExpireAction {
    static DeactiveTenant = 'DeactiveTenant';
    static AssignToAnotherEdition = 'AssignToAnotherEdition';
}

export class PaymentPeriodType {
    static Monthly: number = CreatePaymentDtoPaymentPeriodType._30;
    static Annual: number = CreatePaymentDtoPaymentPeriodType._365;
}

export class SubscriptionPaymentGatewayType {
    static Paypal: number = CreatePaymentDtoSubscriptionPaymentGatewayType._1;
    static Stripe: number = CreatePaymentDtoSubscriptionPaymentGatewayType._2;
}

export class AppSalesSummaryDatePeriod {
    static Daily: number = SalesSummaryDatePeriod._1;
    static Weekly: number = SalesSummaryDatePeriod._2;
    static Monthly: number = SalesSummaryDatePeriod._3;
}

export class SubscriptionPaymentType {
    static Manual: number = TenantLoginInfoDtoSubscriptionPaymentType._0;
    static RecurringAutomatic: number = TenantLoginInfoDtoSubscriptionPaymentType._1;
    static RecurringManual: number = TenantLoginInfoDtoSubscriptionPaymentType._2;
}

