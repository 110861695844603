import { HTTP_INTERCEPTORS } from '@angular/common/http';
import * as i0 from "@angular/core";
var AuthInterceptorService = /** @class */ (function () {
    function AuthInterceptorService() {
    }
    AuthInterceptorService.prototype.intercept = function (req, next) {
        return next.handle(req);
    };
    AuthInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function AuthInterceptorService_Factory() { return new AuthInterceptorService(); }, token: AuthInterceptorService, providedIn: "root" });
    return AuthInterceptorService;
}());
export { AuthInterceptorService };
export var authInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
];
