import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyHealthcareComponent } from './my-healthcare.component';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import { HealthRiskAssessmentOVerviewComponent } from './health-risk-assessment-overview/health-risk-assessment-overview.component';
import { HraAssessmentComponent } from './hra-assessment/hra-assessment.component';


@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      children: [
        { path: '', component: MyHealthcareComponent, canActivate: [AuthGuard] },
        { path: 'RiskAssessment', component: HealthRiskAssessmentOVerviewComponent, canActivate: [AuthGuard] },
        { path: 'RiskAssessment/:questionnaireKey', component: HraAssessmentComponent, canActivate: [AuthGuard] }
      ]
    }
  ])],
  exports: [RouterModule]
})
export class MyHealthcareRoutingModule { }
