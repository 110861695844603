/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../assets/metronic/app/core/directives/menu-aside-toggle.directive";
import * as i4 from "./theme3-brand.component";
var styles_Theme3BrandComponent = [];
var RenderType_Theme3BrandComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_Theme3BrandComponent, data: {} });
export { RenderType_Theme3BrandComponent as RenderType_Theme3BrandComponent };
function View_Theme3BrandComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "logo"], ["height", "38"], ["src", "assets/common/images/Elevate _ Life _ tag.png"]], null, null, null, null, null))], null, null); }
function View_Theme3BrandComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "logo"], ["height", "38"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((((_co.remoteServiceBaseUrl + "/TenantCustomization/GetTenantLogo?skin=") + _co.currentTheme.baseSettings.menu.asideSkin) + "&tenantId=") + _co.appSession.tenant.id) + "&id=") + _co.appSession.tenant.logoId); _ck(_v, 0, 0, currVal_0); }); }
function View_Theme3BrandComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "m-brand__logo-wrapper"], ["routerLink", "main/dashboard"], ["target", "_self"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Theme3BrandComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Theme3BrandComponent_3)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "_self"; var currVal_3 = "main/dashboard"; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = (!_co.appSession.tenant || !_co.appSession.tenant.logoId); _ck(_v, 3, 0, currVal_4); var currVal_5 = (_co.appSession.tenant && _co.appSession.tenant.logoId); _ck(_v, 5, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_Theme3BrandComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "m-brand__logo-wrapper"], ["href", "https://elevate.co.za"], ["target", "_self"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", "logo"], ["height", "38"], ["src", "assets/common/images/logo_teal.PNG"]], null, null, null, null, null))], null, null); }
function View_Theme3BrandComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block no-print"], ["href", "javascript:;"], ["id", "m_aside_left_hide_toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickLeftAsideHideToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
function View_Theme3BrandComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block no-print"], ["href", "javascript:;"], ["id", "m_aside_left_minimize_toggle"], ["mMenuAsideToggle", ""]], null, null, null, null, null)), i0.ɵdid(1, 4210688, null, 0, i3.MenuAsideToggleDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
function View_Theme3BrandComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block no-print"], ["href", "javascript:;"], ["id", "m_aside_left_offcanvas_toggle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
function View_Theme3BrandComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block no-print"], ["href", "javascript:;"], ["id", "m_aside_header_menu_mobile_toggle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
export function View_Theme3BrandComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "m-stack m-stack--ver m-stack--general"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "m-stack__item m-stack__item--middle m-brand__logo"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Theme3BrandComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Theme3BrandComponent_4)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 10, "div", [["class", "m-stack__item m-stack__item--middle m-brand__tools d-print-none"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Theme3BrandComponent_5)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Theme3BrandComponent_6)), i0.ɵdid(10, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Theme3BrandComponent_7)), i0.ɵdid(12, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Theme3BrandComponent_8)), i0.ɵdid(14, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 1, "a", [["class", "m-brand__icon m--visible-tablet-and-mobile-inline-block no-print"], ["href", "javascript:;"], ["id", "m_aside_header_topbar_mobile_toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickTopbarToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "i", [["class", "flaticon-more"]], [[1, "aria-label", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isInExtQuote(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isInExtQuote(); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.currentTheme.isLeftMenuUsed && _co.currentTheme.baseSettings.menu.allowAsideHiding); _ck(_v, 8, 0, currVal_2); var currVal_3 = ((_co.currentTheme.isLeftMenuUsed && _co.currentTheme.baseSettings.menu.allowAsideMinimizing) && !_co.currentTheme.baseSettings.menu.allowAsideHiding); _ck(_v, 10, 0, currVal_3); var currVal_4 = (_co.currentTheme.isLeftMenuUsed && _co.isAuthenticated); _ck(_v, 12, 0, currVal_4); var currVal_5 = ((_co.currentTheme.isTopMenuUsed || _co.currentTheme.isTabMenuUsed) && _co.isAuthenticated); _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.l("Expand"); _ck(_v, 16, 0, currVal_6); }); }
export function View_Theme3BrandComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "theme3-brand", [], null, null, null, View_Theme3BrandComponent_0, RenderType_Theme3BrandComponent)), i0.ɵdid(1, 49152, null, 0, i4.Theme3BrandComponent, [i0.Injector, i2.DOCUMENT, i1.ActivatedRoute], null, null)], null, null); }
var Theme3BrandComponentNgFactory = i0.ɵccf("theme3-brand", i4.Theme3BrandComponent, View_Theme3BrandComponent_Host_0, {}, {}, []);
export { Theme3BrandComponentNgFactory as Theme3BrandComponentNgFactory };
