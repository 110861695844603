<div class="m-portlet">
  <div class="m-portlet__head">
    <div class="m-portlet__head-caption head-text-display w-100">
      <div class="col">
        <div class="m--space-20"></div>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <a [routerLink]="['/app/main/SyncedDetails']" class="m-portlet__head-title">
            <h3 class="seemore m-portlet__head-text font-weight-bold">
              {{"synced" | localize}}
            </h3>
          </a>

          <i class="fa fa-circle-notch fa-spin loader" *ngIf="isLoading"></i>
        </div>
        
        <div class="m--space-10"></div>
        <h6 class="m-portlet__head-desc font-weight-light m--regular-font-size-sm3">
          {{"syncedinfo" | localize}}.
        </h6>
      </div>
    </div>
  </div>
  <div class="m-portlet__body m-portlet__body--no-top-padding">
    <div class="tab-pane active" id="kt_widget5_tab1_content" aria-expanded="true">
      <div class="m-widget5">
        <div class="m-widget5__item for-widget" *ngFor="let item of _wearableService.getAllSyncedAct">
          <div class="m-widget5__content d-table-cell text-center">
            <div class="m-widget5__pic">
              <img class="mt-2 width-percent-65" [src]="item.source | imagePipe" alt="">
            </div>
            <div class="m-widget5__section">
              <h5 class="mt-3 m-widget5__title">
                {{item.source || 'Other'}}
              </h5>
            </div>
          </div>
          <div class="m-widget5__content text-center">
            <div class="mt-3 m-widget5__stats">
              <span class="m-widget5__number mr-2">
                {{item.count}}
              </span>
              <span class="m-widget5__sales font-weight-bold"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <ng-template #tooltipContent>
        <div class="row">
          <div class="col-10">
            <div class="m--align-left m--margin-left-10">
              {{"ChatToolTipDescription"|localize}}
            </div>
          </div>
          <div class="col-2 right-px">
            <i class="fa fa-times" (click)="t.toggle()"></i>
          </div>
        </div>

        <div class="forwareableinput">
          <textarea rows="3" cols="40" placeholder="Description here" type="text" name="disputing"
            [(ngModel)]="currentUserQuery"
            class="m--margin-top-20  m--margin-10-tablet-and-mobile wearableinput wearables-chat-box-border"></textarea>
        </div>
        <div class="m--align-left m--margin-left-10">
          <button (click)="sendsms('Sync Details Widget');t.toggle()"
            class="disputing-css btn m-btn--sm m-btn--pill yellow-clr m--margin-top-10">
            <small class="font-weight-bold text-white">
              {{"Send"|localize}}
            </small>
          </button>
        </div>
      </ng-template>
      <div class="col pt-2">
        <span [routerLink]="['/app/main/SyncedDetails']"
          class="seemore font-weight-bold">{{"SeeMore" | localize}}</span>

      </div>
      <div class="col">
        <div class="m--align-right">
          <button type="button" (mouseout)="imgSrc = '../../../assets/common/images/chatblack.svg'"
            (mouseover)="imgSrc = '../../../assets/common/images/Elevate _ Dispute.png'"
            class="readmore btn m-btn--pill font-weight-bold yellow-clr btn-circle " placement="left" #t="ngbTooltip"
            [ngbTooltip]="tooltipContent" triggers="click" [autoClose]="'outside'" tooltipClass="Wearableround-tooltip">
            <img class="width-px-20" [src]="imgSrc">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>