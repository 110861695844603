var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { AfterViewInit, Injector, OnInit, ErrorHandler, OnDestroy } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { SubscriptionStartType } from '@shared/AppEnums';
import { ChatSignalrService } from 'app/shared/layout/chat/chat-signalr.service';
import * as moment from 'moment/moment';
import { AppComponentBase } from 'shared/common/app-component-base';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { NotificationSettingsModalComponent } from '@app/shared/layout/notifications/notification-settings-modal.component';
import { UserNotificationHelper } from '@app/shared/layout/notifications/UserNotificationHelper';
import { AzureInsightsService } from '@shared/AzureLogging/azure-insights.service';
import { NotificationSignalRService } from '@shared/NotificationSignalrService/notification-signal-r.service';
import { QuirkService } from './shared/services/quirk-api-service-proxy.service';
import { MyCommandCenterService } from './main/my-command-centre/my-command-center.service';
import 'shared/extensions/common-extensions.component';
import { PolicyService } from './shared/services/policy.service';
import { ClaimModifyServiceProxy } from '@shared/service-proxies/service-proxies';
import { Router } from '@angular/router';
export var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
export function changeTemplatePopupGlobal(item, item1) {
    if (item !== undefined) {
        document.getElementsByClassName('swal2-confirm')[0].innerText = item;
        document.getElementsByClassName('swal2-confirm')[0].classList.add('modal-button-clr');
        document.getElementsByClassName('swal2-confirm')[0].classList.add('border-radius-55');
        document.getElementsByClassName('swal2-confirm')[0].classList.add('font-size-12');
    }
    else {
        document.getElementsByClassName('swal2-confirm')[0].classList.add('d-none');
    }
    if (item1 !== undefined) {
        document.getElementsByClassName('swal2-cancel')[0].innerText = item1;
        document.getElementsByClassName('swal2-cancel')[0].classList.add('border-radius-55');
        document.getElementsByClassName('swal2-cancel')[0].classList.add('font-size-12');
    }
    else {
        document.getElementsByClassName('swal2-cancel')[0].classList.add('d-none');
    }
}
export function isMobileView_NewGlobal() {
    if (window.innerWidth < 768) {
        return true;
    }
    else {
        return false;
    }
}
var AppComponent = /** @class */ (function (_super) {
    __extends(AppComponent, _super);
    function AppComponent(injector, _chatSignalrService, _userNotificationHelper, myMonitoringService, _notificationChannel, quirkService, commandService, _policyService, _claimModifyService, router) {
        var _this = _super.call(this, injector) || this;
        _this._chatSignalrService = _chatSignalrService;
        _this._userNotificationHelper = _userNotificationHelper;
        _this.myMonitoringService = myMonitoringService;
        _this._notificationChannel = _notificationChannel;
        _this.quirkService = quirkService;
        _this.commandService = commandService;
        _this._policyService = _policyService;
        _this._claimModifyService = _claimModifyService;
        _this.router = router;
        _this.subscriptionStartType = SubscriptionStartType;
        _this.installationMode = true;
        _this.myMonitoringService.logPageView();
        return _this;
    }
    AppComponent.prototype.ngOnInit = function () {
        if (isIEOrEdge) {
            this.showIfIE();
        }
        this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        this.theme = abp.setting.get('App.UiManagement.Theme').toLocaleLowerCase();
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.registerModalOpenEvents();
        //if (this.appSession.application) {
        //    SignalRHelper.initSignalR(() => { this._chatSignalrService.init(); });
        //}
        this.getPersonAndUpdatePolicyUserClaim();
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    };
    AppComponent.prototype.showIfIE = function () {
        window.cookieconsent.initialise({
            'palette': {
                'popup': {
                    'background': '#3937a3'
                },
                'button': {
                    'background': '#e62576'
                }
            },
            'showLink': false,
            'content': {
                'message': 'Your web browser (Internet Explorer) is not fully compatible. Use different browser for more security, speed and the best experience on this site.',
                'dismiss': 'OKAY'
            }
        });
    };
    AppComponent.prototype.getPersonAndUpdatePolicyUserClaim = function () {
        var _this = this;
        if (sessionStorage.getItem('set_policy_user_status') !== '1' && this._authGuard.user) {
            this._policyService.getPerson(this._authGuard.user.profile.sub, false).subscribe(function (result) {
                var e_1, _a;
                sessionStorage.setItem('set_policy_user_status', '1');
                var hasActiveCover = false;
                if (result && result.CoverInstanceAPIs) {
                    try {
                        for (var _b = __values(result.CoverInstanceAPIs), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var cover = _c.value;
                            if (cover.CoverStatus === 'Active') {
                                hasActiveCover = true;
                                break;
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    if (hasActiveCover && !_this._authService.userPermission.includes('PolicyUser1')) {
                        _this._claimModifyService.addUserRole(_this._authService.user.access_token, 'PolicyUser1').subscribe(function (result) {
                            _this._authService.getUserRoleViaAjax(function () { });
                        });
                    }
                    else if (!hasActiveCover && _this._authService.userPermission.includes('PolicyUser1')) {
                        _this._claimModifyService.deleteUserRole(_this._authService.user.access_token, 'PolicyUser1').subscribe(function (result) {
                            _this._authService.getUserRoleViaAjax(function () { });
                        });
                    }
                }
            });
        }
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        // abp.signalr.autoConnect = false;
    };
    AppComponent.prototype.subscriptionStatusBarVisible = function () {
        return this.appSession.tenantId > 0 &&
            (this.appSession.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon());
    };
    AppComponent.prototype.subscriptionIsExpiringSoon = function () {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this.appSession.tenant.subscriptionEndDateUtc);
        }
        return false;
    };
    AppComponent.prototype.registerModalOpenEvents = function () {
        var _this = this;
        abp.event.on('app.show.loginAttemptsModal', function () {
            _this.loginAttemptsModal.show();
        });
        abp.event.on('app.show.linkedAccountsModal', function () {
            _this.linkedAccountsModal.show();
        });
        abp.event.on('app.show.changePasswordModal', function () {
            _this.changePasswordModal.show();
        });
        abp.event.on('app.show.changeProfilePictureModal', function () {
            _this.changeProfilePictureModal.show();
        });
        abp.event.on('app.show.mySettingsModal', function () {
            _this.mySettingsModal.show();
        });
    };
    AppComponent.prototype.getRecentlyLinkedUsers = function () {
        abp.event.trigger('app.getRecentlyLinkedUsers');
    };
    AppComponent.prototype.onMySettingsModalSaved = function () {
        abp.event.trigger('app.onMySettingsModalSaved');
    };
    return AppComponent;
}(AppComponentBase));
export { AppComponent };
var MyErrorHandler = /** @class */ (function () {
    function MyErrorHandler() {
    }
    MyErrorHandler.prototype.handleError = function (error) {
        abp.message.error(error);
        abp.ui.clearBusy();
    };
    return MyErrorHandler;
}());
export { MyErrorHandler };
