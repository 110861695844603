import { AbpHttpInterceptor } from '@abp/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ProductService } from '@app/shared/services/product.service';
import { MyCommandCenterService } from '@app/main/my-command-centre/my-command-center.service';
import { MyPoliciesService } from '@app/main/my-policies/my-policies.service';
import { PolicyService } from '@app/shared/services/policy.service';
import { MyDocumentsService } from '@app/main/my-resource-centre/my-documents/my-documents.service';
import { QuoteStatusService } from '@app/shared/services/quote-status.service';

@NgModule({
    providers: [
        ApiServiceProxies.UploadServiceProxy,
        ApiServiceProxies.NeedsCalculatorServiceProxy,
        ApiServiceProxies.EmailServiceProxy,
        ApiServiceProxies.BillingInfoServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.SMSServiceProxy,
        ApiServiceProxies.SyncingHumanApiServiceServiceProxy,
        ApiServiceProxies.YodleeServiceProxy,
        ApiServiceProxies.HealthCareApiServiceProxy,
        ApiServiceProxies.WearablesServiceProxy,
        ApiServiceProxies.ClaimModifyServiceProxy,
        ApiServiceProxies.MyFinancialServiceProxy,
        ApiServiceProxies.MyCreditServiceProxy,
        ApiServiceProxies.BeneficiaryModifyServiceProxy,
        ApiServiceProxies.QuirkServiceProxy,
        ApiServiceProxies.QuirkCachedServiceProxy,
        ApiServiceProxies.TokenIdentiyProviderServiceProxy,
        ApiServiceProxies.RewardsServiceProxy,
        ApiServiceProxies.NeedsCalculatorServiceProxy,
        ProductService,
        MyCommandCenterService,
        MyPoliciesService,
        MyDocumentsService,
        QuoteStatusService,
        PolicyService,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
