var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PolicyService } from '@app/shared/services/policy.service';
import { map } from 'rxjs/operators';
import { ProductService } from '@app/shared/services/product.service';
import { QuirkService } from '@app/shared/services/quirk-api-service-proxy.service';
var MyPoliciesService = /** @class */ (function (_super) {
    __extends(MyPoliciesService, _super);
    function MyPoliciesService(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.beneficiaryList = [];
        _this.vm = {
            currentPolicyId: '',
            currentQuote: null,
            entityId: ''
        };
        if (_this._authGuard.user) {
            _this.vm.entityId = _this._authGuard.user.profile.sub;
        }
        return _this;
    }
    Object.defineProperty(MyPoliciesService.prototype, "policyService", {
        get: function () {
            if (!this._policyService) {
                this._policyService = this.injector.get(PolicyService);
            }
            return this._policyService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MyPoliciesService.prototype, "quirkService", {
        get: function () {
            if (!this._quirkService) {
                this._quirkService = this.injector.get(QuirkService);
            }
            return this._quirkService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MyPoliciesService.prototype, "productService", {
        get: function () {
            if (!this._productService) {
                this._productService = this.injector.get(ProductService);
            }
            return this._productService;
        },
        enumerable: true,
        configurable: true
    });
    MyPoliciesService.prototype.getPolicyList = function (policyList) {
        return this.policyService.getPolicySets(policyList);
    };
    MyPoliciesService.prototype.getPolicyById = function (policyId) {
        return this.policyService.getPolicy(policyId)
            .pipe(map(function (res) {
            return res;
        }));
    };
    MyPoliciesService.prototype.getAPerson = function (personId, isCaching) {
        return this.policyService.getPerson(personId, isCaching)
            .pipe(map(function (res) {
            return res;
        }));
    };
    MyPoliciesService.prototype.getBeneificaryNamesList = function () {
        return this.beneficiaryList;
    };
    MyPoliciesService.prototype.setBeneficiaryLisT = function (data) {
        this.beneficiaryList = data;
    };
    return MyPoliciesService;
}(AppComponentBase));
export { MyPoliciesService };
