var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, Injector } from '@angular/core';
import { AppTimezoneScope } from '@shared/AppEnums';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ClaimModifyServiceProxy, ModifyClaimDto, ProfileServiceProxy, UserClaimEditDto } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { document, ModalDirective } from 'ngx-bootstrap';
import { Profile } from 'oidc-client';
import { SmsVerificationModalComponent } from './sms-verification-modal.component';
var MySettingsModalComponent = /** @class */ (function (_super) {
    __extends(MySettingsModalComponent, _super);
    function MySettingsModalComponent(injector, _profileService, _claimModifyService) {
        var _this = _super.call(this, injector) || this;
        _this._profileService = _profileService;
        _this._claimModifyService = _claimModifyService;
        _this.modalSave = new EventEmitter();
        _this.active = false;
        _this.saving = false;
        _this.isGoogleAuthenticatorEnabled = false;
        _this.isPhoneNumberEmpty = false;
        _this.showTimezoneSelection = abp.clock.provider.supportsMultipleTimezone;
        _this.defaultTimezoneScope = AppTimezoneScope.User;
        _this._initialTimezone = undefined;
        _this.restrictionDateValue = new Date();
        _this.GenderList = ['Male', 'Female', 'Non-binary'];
        _this.wasInside = false;
        _this.enabledField = [];
        _this.getCurrentUserInfo();
        return _this;
    }
    MySettingsModalComponent.prototype.getCurrentUserInfo = function () {
        var _this = this;
        abp.event.on('getExistsUserInfo', function () {
            _this._authService.getUser(function (res) {
                _this.existsCurrentUserInfo = res;
                var utcDate = _this.existsCurrentUserInfo.birthdate;
                _this.existsRestrictionDateValue = moment(utcDate, 'DD/MM/YYYY hh:mm:ss A').toDate();
            });
        });
    };
    MySettingsModalComponent.prototype.show = function () {
        var _this = this;
        this.active = true;
        this.restrictionMessage = false;
        this.lastTabActive = false;
        this.tabIndex = 1;
        this.profile_contact_scheme = 'profile';
        this.nameDisabled = true;
        this.surnameDisabled = true;
        this.genderDisabled = true;
        this.birthdayDisabled = true;
        this._authService.getUser(function (res) {
            _this.currentUserInfo = res;
            _this.selectedGender = _this.currentUserInfo.gender;
            var birthDate = _this.currentUserInfo.birthdate;
            _this.restrictionDateValue = moment(birthDate, 'DD/MM/YYYY hh:mm:ss A').toDate();
            abp.event.trigger('getExistsUserInfo');
            _this.modal.show();
        });
    };
    MySettingsModalComponent.prototype.profileTab = function () {
        this.lastTabActive = false;
        this.schemeDisabled = true;
        this.profile_contact_scheme = 'profile';
        this.tabIndex = 1;
        if (this.existsCurrentUserInfo.medical_scheme_membership_number !== this.currentUserInfo.medical_scheme_membership_number) {
            this.changesConfirmPopup();
        }
        else {
            this.disabledField();
        }
    };
    MySettingsModalComponent.prototype.contactTab = function () {
        this.nameDisabled = true;
        this.surnameDisabled = true;
        this.schemeDisabled = true;
        this.genderDisabled = true;
        this.birthdayDisabled = true;
        this.lastTabActive = false;
        this.profile_contact_scheme = 'contact';
        this.tabIndex = 2;
        if ((this.existsCurrentUserInfo.gender !== this.selectedGender)
            || (this.existsCurrentUserInfo.family_name !== this.currentUserInfo.family_name)
            || (this.existsCurrentUserInfo.given_name !== this.currentUserInfo.given_name)
            || this.existsRestrictionDateValue.getTime() !== this.restrictionDateValue.getTime()
            || (this.existsCurrentUserInfo.medical_scheme_membership_number !== this.currentUserInfo.medical_scheme_membership_number)) {
            this.changesConfirmPopup();
        }
        else {
            this.disabledField();
        }
    };
    MySettingsModalComponent.prototype.schemeTab = function () {
        this.profile_contact_scheme = 'scheme';
        this.tabIndex = 3;
        this.lastTabActive = true;
        this.nameDisabled = true;
        this.surnameDisabled = true;
        this.schemeDisabled = true;
        this.genderDisabled = true;
        this.birthdayDisabled = true;
        if ((this.existsCurrentUserInfo.gender !== this.selectedGender)
            || (this.existsCurrentUserInfo.family_name !== this.currentUserInfo.family_name)
            || (this.existsCurrentUserInfo.given_name !== this.currentUserInfo.given_name)
            || this.existsRestrictionDateValue.getTime() !== this.restrictionDateValue.getTime()) {
            this.changesConfirmPopup();
        }
        else {
            this.disabledField();
        }
    };
    MySettingsModalComponent.prototype.changesConfirmPopup = function () {
        var d = this;
        abp.message.confirm('', 'Do you want to save profile changes before you continue?', function (isConfirmed) {
            if (isConfirmed) {
                this._authservice.getUser(function (res) {
                    d.currentUserInfo = res;
                    d.selectedGender = d.currentUserInfo.gender;
                    var birthDate = d.currentUserInfo.birthdate;
                    d.restrictionDateValue = moment(birthDate, 'DD/MM/YYYY hh:mm:ss A').toDate();
                });
            }
            else {
                d.save(null);
            }
        }, true);
        document.getElementsByClassName('swal2-confirm')[0].innerText = 'Continue without saving';
        document.getElementsByClassName('swal2-confirm')[0].classList.add('modal-button-clr');
        document.getElementsByClassName('swal2-confirm')[0].classList.add('border-radius-55');
        document.getElementsByClassName('swal2-confirm')[0].classList.add('font-size-12');
        document.getElementsByClassName('swal2-cancel')[0].innerText = 'Save';
        document.getElementsByClassName('swal2-cancel')[0].classList.add('modal-button-clr');
        document.getElementsByClassName('swal2-cancel')[0].classList.add('border-radius-55');
        document.getElementsByClassName('swal2-cancel')[0].classList.add('font-size-12');
    };
    MySettingsModalComponent.prototype.disabledField = function () {
        this.nameDisabled = true;
        this.surnameDisabled = true;
        this.schemeDisabled = true;
        this.genderDisabled = true;
        this.birthdayDisabled = true;
    };
    MySettingsModalComponent.prototype.updateQrCodeSetupImageUrl = function () {
        var _this = this;
        this._profileService.updateGoogleAuthenticatorKey().subscribe(function (result) {
            _this.user.qrCodeSetupImageUrl = result.qrCodeSetupImageUrl;
            _this.isGoogleAuthenticatorEnabled = true;
        });
    };
    MySettingsModalComponent.prototype.smsVerify = function () {
        //this._profileService.sendVerificationSms()
        //    .subscribe(() => {
        //        this.smsVerificationModal.show();
        //    });
    };
    MySettingsModalComponent.prototype.changePhoneNumberToVerified = function () {
        this.isPhoneNumberConfirmed = true;
    };
    MySettingsModalComponent.prototype.onShown = function () {
        document.getElementById('Name').focus();
    };
    MySettingsModalComponent.prototype.close = function () {
        this.active = false;
        this.modal.hide();
    };
    MySettingsModalComponent.prototype.save = function (saveForm) {
        var _this = this;
        this.saving = true;
        this.saving = false;
        var claimList = new ModifyClaimDto();
        var claims = new UserClaimEditDto();
        claimList.userClaimEditDtos = new Array();
        abp.ui.setBusy();
        var given_name = this.currentUserInfo.given_name;
        claims.claimType = 'given_name';
        claims.claimValue = given_name;
        claimList.userClaimEditDtos.push(claims);
        var family_name = this.currentUserInfo.family_name.toString();
        claims = new UserClaimEditDto();
        claims.claimType = 'family_name';
        claims.claimValue = family_name;
        claimList.userClaimEditDtos.push(claims);
        var selectedDate = moment(this.restrictionDateValue).format('DD/MM/YYYY hh:mm:ss A');
        claims = new UserClaimEditDto();
        claims.claimType = 'birthdate';
        claims.claimValue = selectedDate;
        claimList.userClaimEditDtos.push(claims);
        var selectedGender = this.selectedGender;
        claims = new UserClaimEditDto();
        claims.claimType = 'gender';
        claims.claimValue = selectedGender;
        claimList.userClaimEditDtos.push(claims);
        claimList.accessToken = this._authService.user.access_token;
        var medical_scheme_membership_number = this.currentUserInfo.medical_scheme_membership_number;
        claims = new UserClaimEditDto();
        claims.claimType = 'medical_scheme_membership_number';
        claims.claimValue = medical_scheme_membership_number;
        claimList.userClaimEditDtos.push(claims);
        claimList.userId = this.currentUserInfo.sub;
        this._claimModifyService.modifyClaim(claimList).subscribe(function () {
            abp.event.trigger('updateProfileName');
            _this.lastTabActive ? _this.saveContinueSubmit('SavedSuccessfully', null) : _this.saveContinueSubmit('ChangesSavedSuccessfully', saveForm);
        }, function (error) {
            abp.ui.clearBusy();
            console.log(error);
        });
    };
    MySettingsModalComponent.prototype.saveContinueSubmit = function (value, saveForm) {
        if (value === 'SavedSuccessfully') {
            this.notify.info(this.l('SavedSuccessfully'));
            abp.ui.clearBusy();
            this.close();
        }
        else {
            this.notify.info(this.l('ChangesSavedSuccessfully'));
            if (saveForm != null) {
                this.tabIndex === 1 ? this.tabIndex = 2 : this.tabIndex = 3;
                // tslint:disable-next-line: no-unused-expression
                this.tabIndex === 3 ? this.lastTabActive = true : null;
                this.nameDisabled = true;
                this.surnameDisabled = true;
                this.schemeDisabled = true;
                this.genderDisabled = true;
                this.birthdayDisabled = true;
            }
            abp.event.trigger('getExistsUserInfo');
            abp.ui.clearBusy();
        }
        this.modalSave.emit(null);
    };
    MySettingsModalComponent.prototype.onValueChange = function (value) {
        var restrictionyear = new Date().getFullYear() - 18;
        var selectedFullyear = value != null ? value.getFullYear() : null;
        if (selectedFullyear >= restrictionyear) {
            this.restrictionMessage = true;
            this.saving = true;
        }
        else {
            this.restrictionMessage = false;
            this.saving = false;
        }
    };
    MySettingsModalComponent.prototype.onChange = function (value) {
        if (value === '') {
            this.restrictionMessage = false;
        }
        this.restrictionDateValue = value;
    };
    MySettingsModalComponent.prototype.edit = function (value) {
        var _this = this;
        this.nameDisabled = true;
        this.surnameDisabled = true;
        this.schemeDisabled = true;
        this.genderDisabled = true;
        this.birthdayDisabled = true;
        this._authService.getUser(function (res) {
            _this.currentUserInfo = res;
            _this.selectedGender = _this.currentUserInfo.gender;
            var birthDate = _this.currentUserInfo.birthdate;
            _this.restrictionDateValue = moment(birthDate, 'DD/MM/YYYY hh:mm:ss A').toDate();
        });
        switch (value) {
            case 'name':
                this.nameDisabled = false;
                break;
            case 'surname':
                this.surnameDisabled = false;
                break;
            case 'birthday':
                this.birthdayDisabled = false;
                break;
            case 'gender':
                this.genderDisabled = false;
                break;
            case 'scheme':
                this.schemeDisabled = false;
                break;
        }
    };
    return MySettingsModalComponent;
}(AppComponentBase));
export { MySettingsModalComponent };
