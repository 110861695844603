<a href="javascript:;" style="left: 1.5rem;" class="right-1_5em m-nav__link m-dropdown__toggle" id="m_topbar_notification_icon_not">    
    <span class="m-nav__link-icon">
        <span class="m-nav__link-icon-wrapper mr-5">
            <img width="25" src="../../../../assets/common/images/notification-eleate.png" [attr.aria-label]="l('Notifications')" />
            <!--<i class="dark-grey-clr fas fa-bell" [attr.aria-label]="l('Notifications')"></i>-->
        </span>
    </span>
</a>
<div style="left: 5rem;" class="left-5em m-dropdown__wrapper">
    <span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
    <div class="m-dropdown__inner">
        <div class="m-dropdown__header m--align-center elevategrad">
            <span class="m-dropdown__header-title">
                {{unreadNotificationCount}} {{"NewNotifications" | localize}}
            </span>
        </div>
        <div class="m-dropdown__body">
            <div class="m-dropdown__content">
                <div class="row">
                    <div class="text-left col-md-8">
                        <a href="javascript:;" (click)="setAllNotificationsAsRead(); $event.preventDefault(); $event.stopPropagation();" *ngIf="unreadNotificationCount">{{"SetAllAsRead" | localize}}</a>
                    </div>
                    <div class="text-right col-md-4">
                        <!--<a (click)="openNotificationSettingsModal()" class="text-right" href="javascript:;">{{"Settings" | localize}}</a>-->
                    </div>
                </div>
                <hr />
                <div>
                    <div class="m-list-timeline m-list-timeline--skin-light m-nav">
                        <div class="m-list-timeline__items" [ngClass]="{'empty':!notifications.length}">
                            <div *ngFor="let notification of notifications" class="m-list-timeline__item user-notification-item" (click)="gotoUrl(notification.url)" [ngClass]="{'user-notification-item-clickable': notification.url,'user-notification-item-unread': notification.isUnread}">
                                <span class="m-list-timeline__badge m-list-timeline__badge--success"></span>
                                <span class="m-list-timeline__icon {{notification.icon}}"></span>
                                <span class="m-list-timeline__text">
                                    {{notification.text}}
                                </span>
                                <span class="m-list-timeline__time">
                                    {{notification.time | momentFromNow}}
                                    <span *ngIf="notification.isUnread">
                                        <a href="javascript:;" *ngIf="notification.state=='UNREAD'" class="m-link set-notification-as-read" (click)="setNotificationAsRead(notification); $event.preventDefault(); $event.stopPropagation();">{{"SetAsRead" | localize}}</a>
                                    </span>
                                </span>
                            </div>
                            <span *ngIf="!notifications.length" class="notification-empty-text">
                                {{"ThereIsNoNotification" | localize}}
                            </span>
                        </div>
                        <div *ngIf="notifications.length" class="m-nav__separator m-nav__separator--fit"></div>
                        <div *ngIf="notifications.length" class="external notifications-footer">
                            <div class="text-center"><a routerLink="notifications" class="m-dropdown__toggle">{{"SeeAllNotifications" | localize}}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
