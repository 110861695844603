import { environment } from 'environments/environment';
import { AppInsights } from 'applicationinsights-js';
import * as i0 from "@angular/core";
var AzureInsightsService = /** @class */ (function () {
    function AzureInsightsService() {
        this.config = {
            instrumentationKey: environment.appInsights.instrumentationKey
        };
        if (!AppInsights.config) {
            AppInsights.downloadAndSetup(this.config);
        }
    }
    AzureInsightsService.prototype.logPageView = function (name, url, properties, measurements, duration) {
        AppInsights.trackPageView(name, url, properties, measurements, duration);
    };
    AzureInsightsService.prototype.logEvent = function (name, properties, measurements) {
        AppInsights.trackEvent(name, properties, measurements);
    };
    AzureInsightsService.prototype.logError = function (error, properties, measurements) {
        AppInsights.trackException(error, null, this.AddGlobalProperties(properties), measurements);
    };
    AzureInsightsService.prototype.AddGlobalProperties = function (properties) {
        if (!properties) {
            properties = {};
        }
        //add your custom properties such as app version
        return properties;
    };
    AzureInsightsService.prototype.setAuthenticatedUserId = function (userId) {
        AppInsights.setAuthenticatedUserContext(userId);
    };
    AzureInsightsService.ngInjectableDef = i0.defineInjectable({ factory: function AzureInsightsService_Factory() { return new AzureInsightsService(); }, token: AzureInsightsService, providedIn: "root" });
    return AzureInsightsService;
}());
export { AzureInsightsService };
