import { Component, OnInit, HostBinding, AfterViewInit, EventEmitter, Output, ElementRef, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'appmywearablescalender',
    templateUrl: './my-wearables-calender.component.html',
    styleUrls: ['./my-wearables-calender.component.css']
})
export class MyWearablesCalenderComponent extends AppComponentBase implements OnInit, AfterViewInit {

    @HostBinding('id') id = 'm_Calender_toggle';
    @HostBinding('class')
    classes = 'm-quick-sidebar m-quick-sidebar--tabbed m-quick-sidebar--skin-light';
    @HostBinding('attr.mQuickSidebarOffcanvas')
    @HostBinding('style.overflow') styleOverflow: any = 'hidden';
    mQuickSidebarOffcanvas: any;

    syncDayActiveStatus = true;
    syncMonthActiveStatus = false;
    syncWeekActiveStatus = false;
    isOpen: boolean;
    _pinned: any;

    constructor(private el: ElementRef,
        injector: Injector, ) {
        super(injector);
    }

    @Output() week = new EventEmitter<boolean>();
    @Output() month = new EventEmitter<boolean>();

    @Output() year = new EventEmitter<boolean>();



    RefreshPageWeek() {
        this.syncDayActiveStatus = !this.syncDayActiveStatus;
        if (this.syncDayActiveStatus) {
            this.syncWeekActiveStatus = false;
            this.syncMonthActiveStatus = false;
            this.week.emit(true);
        }
    }

    RefreshPageMonth() {
        this.syncWeekActiveStatus = !this.syncWeekActiveStatus;
        if (this.syncWeekActiveStatus) {
            this.syncDayActiveStatus = false;
            this.syncMonthActiveStatus = false;
            this.month.emit(true);
        }

    }

    RefreshPageYear() {
        this.syncMonthActiveStatus = !this.syncMonthActiveStatus;

        if (this.syncMonthActiveStatus) {
            this.syncWeekActiveStatus = false;
            this.syncDayActiveStatus = false;
            this.year.emit(true);

        }
    }


    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.mQuickSidebarOffcanvas = new mOffcanvas(this.el.nativeElement, {
            overlay: true,
            baseClass: 'm-quick-sidebar',
            closeBy: 'm_Calender_close',
            toggleBy: 'm_Calender_toggle'
        });

        this.mQuickSidebarOffcanvas.events.push({
            name: 'afterHide',
            handler: () => {
                if (this._pinned) {
                    this.mQuickSidebarOffcanvas.show();
                } else {
                    this.isOpen = false;
                }
            }
        }, {
            name: 'afterShow',
            handler: () => {
                this.isOpen = true;
            }
        });
    }


}
