﻿import { Injectable, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PolicyService } from '@app/shared/services/policy.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PoliciesModule, PersonModule } from '@shared/models/interfaces/elevate-data-models';
import { ProductService } from '@app/shared/services/product.service';
import { QuirkService } from '@app/shared/services/quirk-api-service-proxy.service';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class MyPoliciesService extends AppComponentBase {

    beneficiaryList = [];

    private _policyService: PolicyService;
    public get policyService(): PolicyService {
        if (!this._policyService) {
            this._policyService = this.injector.get(PolicyService);
        }
        return this._policyService;
    }

    private _quirkService: QuirkService;
    public get quirkService(): QuirkService {
        if (!this._quirkService) {
            this._quirkService = this.injector.get(QuirkService);
        }
        return this._quirkService;
    }


    private _productService: ProductService;
    public get productService(): ProductService {
        if (!this._productService) {
            this._productService = this.injector.get(ProductService);
        }
        return this._productService;
    }

    public vm = {
        currentPolicyId: '',
        currentQuote: null,
        entityId: ''
    };

    constructor(
        private injector: Injector,
    ) {
        super(injector);
        if (this._authGuard.user) {
            this.vm.entityId = this._authGuard.user.profile.sub;
        }
    }

    getPolicyList(policyList: PersonModule.IPolicyAPI[]): PoliciesModule.IPolicyModel[] {
        return this.policyService.getPolicySets(policyList);
    }




    getPolicyById(policyId: string): Observable<PoliciesModule.IPolicyModel> {
        return this.policyService.getPolicy(policyId)
            .pipe(map(res => {
                return res;
            }));
    }

    getAPerson(personId: string, isCaching?: boolean): Observable<PersonModule.IPersonModel> {
        return this.policyService.getPerson(personId, isCaching)
            .pipe(map(res => {
                return res;
            }));
    }

    getBeneificaryNamesList() {
        return this.beneficiaryList;
    }

    setBeneficiaryLisT(data: any) {
        this.beneficiaryList = data;
    }

}
