import { Component, OnInit, NgZone, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MyHealthcareService } from '@app/shared/services/my-healthcare.service';
import { HospitalEventOutput, SmsBody } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-hospital-events',
  templateUrl: './hospital-events.component.html',
  styleUrls: ['./hospital-events.component.css', '../../../../../main/my-healthcare/my-healthcare.component.css']
})
export class HospitalEventsComponent extends AppComponentBase implements OnInit {
  isLoading: boolean;
  seemoreActivated = false;
  hospEventData = new HospitalEventOutput();
  hospitalEvents: HospitalEventOutput[];
  requestAccessToHECharts: boolean = undefined;
  _healthCare: any;
  authguard: any;
  hospEventSelectedYear: any;
  yearActiveHE = new Array<boolean>();
  currentUserQuery = '';
  imgSrc: any = '../../../assets/common/images/chatblack.svg';

  constructor(private zone: NgZone, injector: Injector, public _healtCareService: MyHealthcareService) {
    super(injector);
  }

  ngOnInit() {
  }

  loadHospitalEvent() {
    this.isLoading = true;
    this.hospEventSelectedYear = this._healtCareService.yearList[0];
    let i = 0;
    this._healtCareService.yearList.forEach(x => {
      if (i === 0) {
        this.yearActiveHE.push(true);
      } else {
        this.yearActiveHE.push(false);
      }
      i++;
    });
    this.getHospitalEvents();
  }


  FilterWhithYearHospEvent(item, i) {
    let d = this.yearActiveHE.findIndex(x => x === true);
    this.yearActiveHE[d] = false;
    this.yearActiveHE[i] = true;
    this.hospEventSelectedYear = item;
    this.requestAccessToHECharts = undefined;
    this.getHospitalEvents();
  }

  getHospitalEvents() {
    this.isLoading = true;
    this._healtCareService.getHospitalEventData(this.hospEventSelectedYear)
      .then((res) => {
        this.loadHospitalEventsChart(this.hospEventSelectedYear);
      })
      .catch(() => {
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  loadHospitalEventsChart(year: string) {
    this.requestAccessToHECharts = this._healtCareService.hospitalEvents.length > 0 ? true : false;
    if (this.requestAccessToHECharts) {
      this.zone.runOutsideAngular(() => {
        Promise.all([
          import('@amcharts/amcharts4/core'),
          import('@amcharts/amcharts4/charts'),
          import('@amcharts/amcharts4/themes/animated')
        ]).then(modules => {
          const am4core = modules[0];
          const am4charts = modules[1];
          const am4themes_animated = modules[2].default;
          am4core.options.commercialLicense = true;

          am4core.useTheme(am4themes_animated);

          // Themes begin
          am4core.useTheme(am4themes_animated);
          // Themes end
          am4core.options.autoSetClassName = true;

          // Create chart instance
          let chartHE = am4core.create('HospitalEvent', am4charts.XYChart);
          chartHE.responsive.enabled = true;
          // Add data
          console.log(year);
          this._healtCareService.hospitalEvents.forEach(x => {
            chartHE.data.push({
              PaidAmount: x.totalAdmissionPaidAmount,
              drg: x.baseDRG,
              Headerhe: x.baseDRG,
              amountclaimed: x.totalAdmissionClaimedAmount,
              amountpaid: x.totalAdmissionPaidAmount,
              shortfallamount: x.shortfall_Amount,
              admdate: x.admissionDate,
              disdate: x.dischargeDate,
              hospital: x.admittingHospitalName,
              doc: x.admittingDoctorName,
              diagnosis: x.admissionPrimaryICD10CodeDescription
            });
          });

          chartHE.numberFormatter.numberFormat = '#.##a';
          chartHE.numberFormatter.bigNumberPrefixes = [
            { 'number': 1e+3, 'suffix': 'K' },
            { 'number': 1e+6, 'suffix': 'M' },
            { 'number': 1e+9, 'suffix': 'B' }
          ];
          // Create axes
          if (this._healtCareService.hospitalEvents.length > 0) {
            let categoryAxis1 = chartHE.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis1.dataFields.category = 'Headerhe';
            categoryAxis1.renderer.grid.template.disabled = true;
            categoryAxis1.renderer.labels.template.html = 'See More';
            categoryAxis1.renderer.labels.template.fontSize = 12;
            categoryAxis1.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
            categoryAxis1.renderer.minGridDistance = 30;
            categoryAxis1.renderer.labels.template.events.on('over', function (event) {
              event.target.stroke = am4core.color('#B01917');
              document.getElementsByClassName('');
            }, self);

            categoryAxis1.renderer.labels.template.events.on('hit', function (event) {
              this.hospEventData.baseDRG = event.target.dataItem.dataContext['Headerhe'];
              this.hospEventData.totalAdmissionClaimedAmount = event.target.dataItem.dataContext['amountclaimed'];
              this.hospEventData.totalAdmissionPaidAmount = event.target.dataItem.dataContext['amountpaid'];
              this.hospEventData.shortfall_Amount = event.target.dataItem.dataContext['shortfallamount'];
              this.hospEventData.admissionDate = event.target.dataItem.dataContext['admdate'];
              this.hospEventData.dischargeDate = event.target.dataItem.dataContext['disdate'];
              this.hospEventData.admittingHospitalName = event.target.dataItem.dataContext['hospital'];
              this.hospEventData.admittingDoctorName = event.target.dataItem.dataContext['doc'];
              this.hospEventData.admissionPrimaryICD10CodeDescription = event.target.dataItem.dataContext['diagnosis'];
              this.seemoreActivated = true;
            }, this);
          }
          let categoryAxis = chartHE.xAxes.push(new am4charts.CategoryAxis());
          categoryAxis.dataFields.category = 'Headerhe';
          categoryAxis.renderer.grid.template.disabled = true;
          categoryAxis.renderer.minGridDistance = 30;
          categoryAxis.renderer.line.strokeOpacity = 1;
          categoryAxis.renderer.labels.template.width = 105;
          categoryAxis.renderer.labels.template.paddingLeft = 26;

          categoryAxis.renderer.labels.template.wrap = true;
          categoryAxis.renderer.line.stroke = am4core.color('#B01917');

          let valueAxis = chartHE.yAxes.push(new am4charts.ValueAxis());
          valueAxis.renderer.line.strokeOpacity = 1;
          valueAxis.min = 0;
          valueAxis.renderer.line.stroke = am4core.color('#B01917');
          valueAxis.renderer.grid.template.disabled = true;
          valueAxis.title.text = 'Total Amount Paid';
          valueAxis.title.dx = -12;


          // Create series
          let series = chartHE.series.push(new am4charts.ColumnSeries());
          series.dataFields.valueY = 'PaidAmount';
          series.dataFields.categoryX = 'Headerhe';
          series.name = 'PaidAmount';
          series.sequencedInterpolation = true;
          series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
          let bullet = series.bullets.push(new am4charts.Bullet());
          let label = bullet.createChild(am4core.Label);
          label.horizontalCenter = 'middle';
          label.verticalCenter = 'top';
          label.y = am4core.percent(100);
          label.propertyFields.text = 'Date';

          label.stroke = am4core.color('#B01917');
          label.dy = -20;

          let columnTemplate = series.columns.template;
          columnTemplate.width = 40;
          columnTemplate.fill = am4core.color('#B01917');
          columnTemplate.stroke = am4core.color('#B01917');
        })
          .catch(e => {
            console.error('Error when creating chart', e);
          });
      });
    }
  }

  sendsms(PageName?) {
    let body = new SmsBody();
    body.userid = this._healtCareService._commonHelper._user.profile.sub;
    body.firstName = this._healtCareService._commonHelper._user.profile.given_name;
    body.lastName = this._healtCareService._commonHelper._user.profile.family_name;
    body.email = this._healtCareService._commonHelper._user.profile.email;
    body.phoneNumber = Array.isArray(this._healtCareService._commonHelper._user.profile.phone_number) ? this._healtCareService._commonHelper._user.profile.phone_number[0] : this._healtCareService._commonHelper._user.profile.phone_number;
    body.pageName = PageName;
    body.message = this.currentUserQuery;
    this.sendQueryEmail(body);
    this.currentUserQuery = '';
  }
}
