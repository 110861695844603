import { Injector } from '@angular/core';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HealthRiskAssessmentModule, UserMetadataModule } from '@shared/models/interfaces/elevate-data-models';
import { ClaimModifyServiceProxy, HealthCareApiServiceProxy, ModifyClaimDto, UserClaimEditDto } from '@shared/service-proxies/service-proxies';
import { ColorRangeDataModel, SliderChangeEventArgs, TicksDataModel } from '@syncfusion/ej2-angular-inputs';
import * as moment from 'moment';

export class HraAssessmentBaseComponent extends AppComponentBase {
    existingId: string;
    questionnaires: Array<HealthRiskAssessmentModule.Questionnaire>;
    currentQuestionnaire: HealthRiskAssessmentModule.Questionnaire;
    isLoading: boolean;
    claims: UserMetadataModule.UserMetadata;
    questionnaireKey: string;
    questionnaireTitle: string;
    questionTypeConsts: {
        SINGLE_SELECT: string,
        MULTI_SELECT: string,
        NUMBER_INPUT: string,
        CALC: string
    };
    defaultValues: {
        gender: string,
        age: number
    };
    ticks: TicksDataModel;

    constructor(
        injector: Injector,
        private authGuard: AuthGuard,
        private claimService: ClaimModifyServiceProxy,
        private healthCareService: HealthCareApiServiceProxy,
        private claimModifyService: ClaimModifyServiceProxy
    ) {
        super(injector);
        this.questionTypeConsts = AppConsts.HraQuestionTypes;
        this.questionnaireTitle = '...';
        this.existingId = null;
        this.defaultValues = null;
        this.ticks = {
            largeStep: 1,
            smallStep: 1,
            placement: 'After',
            showSmallTicks: false
        };
    }

    get phases(): Array<HealthRiskAssessmentModule.Phase> {
        if (!this.currentQuestionnaire) {
            return [];
        }

        return this.currentQuestionnaire.phases.filter(p => p.isVisible);
    }

    getQuestionsInPhase(phase: HealthRiskAssessmentModule.Phase): Array<HealthRiskAssessmentModule.Question> {
        if (!this.currentQuestionnaire) {
            return [];
        }

        return phase.questions.filter(q => q.isVisible);
    }

    checkTriggers(question: HealthRiskAssessmentModule.Question): void {
        if (!question.triggers) {
            return;
        }

        for (const trigger of question.triggers) {
            let isVisible: boolean;

            if (Array.isArray(trigger.range)) {
                isVisible = question.answersGiven.map(a => a.value).some(a => a >= trigger.range[0] && a <= trigger.range[1]);
            } else {
                isVisible = question.answersGiven.map(a => a.value).some(a => trigger.values.includes(a));

                if (!isVisible && trigger.values.includes('Other') && question.answersGiven.some(q => q.title === 'Other')) {
                    isVisible = true;
                }
            }

            if (trigger.key.startsWith('P')) {
                const triggeredPhase = this.currentQuestionnaire.phases.find(p => p.key === trigger.key);
                triggeredPhase.isVisible = isVisible;

                for (const triggeredQuestion of triggeredPhase.questions) {
                    triggeredQuestion.isVisible = isVisible;
                }

                if (!triggeredPhase.isVisible) {
                    for (const question of triggeredPhase.questions) {
                        question.answersGiven = [];
                    }
                }
            } else {
                const triggeredQuestion = (this.currentQuestionnaire.phases
                    .map(p => p.questions)
                    .customFlat() as Array<HealthRiskAssessmentModule.Question>)
                    .find(q => q.key === trigger.key);

                triggeredQuestion.isVisible = isVisible;

                if (!triggeredQuestion.isVisible) {
                    triggeredQuestion.answersGiven = [];
                }
            }
        }

        if (!question.reweight) {
            return;
        }

        if (question.answersGiven.some(a => question.reweight.values.includes(a.value))) {
            for (const phase of this.currentQuestionnaire.phases) {
                for (const phaseQuestion of phase.questions) {
                    if (question.reweight.questionKeys.includes(phaseQuestion.key)) {
                        phaseQuestion.weight /= question.reweight.reweightValue;
                    }
                }
            }

            question.reweight.hasBeenReweighted = true;
        } else if (question.reweight.hasBeenReweighted) {
            for (const phase of this.currentQuestionnaire.phases) {
                for (const phaseQuestion of phase.questions) {
                    if (question.reweight.questionKeys.includes(phaseQuestion.key)) {
                        phaseQuestion.weight *= question.reweight.reweightValue;
                    }
                }
            }
        }
    }

    onMultipleAnswerChange(answer: HealthRiskAssessmentModule.Answer, question: HealthRiskAssessmentModule.Question): void {
        if (this.isAnswerPresent(answer, question)) {
            if (answer.title === 'Other') {
                answer.value = null;
            }

            question.answersGiven = question.answersGiven.filter(a => a.title !== answer.title);
        } else if (answer.excludeOthers) {
            const otherAnswer = question.answersGiven.find(a => a.title === 'Other');

            if (otherAnswer) {
                otherAnswer.value = null;
            }

            question.answersGiven = [answer];
        } else {
            const excludeAnswer = question.answersGiven.find(a => a.excludeOthers);

            if (excludeAnswer) {
                question.answersGiven = question.answersGiven.filter(a => a.title !== excludeAnswer.title);
            }

            question.answersGiven.push(answer);
        }
    }

    isAnswerPresent(answer: HealthRiskAssessmentModule.Answer, question: HealthRiskAssessmentModule.Question): boolean {
        return question.answersGiven.some(a => a.title === answer.title);
    }

    setTextInputAnswer(value: string | number, question: HealthRiskAssessmentModule.Question, answerToSet: HealthRiskAssessmentModule.Answer): void {
        let answer = question.answersGiven.find(a => a.title === answerToSet.title);

        if (!answer) {
            answer = question.answers.find(a => a.title === answerToSet.title);
            question.answersGiven.push(answer);
        }

        answer.value = value;
    }

    getTextInputAnswer(question: HealthRiskAssessmentModule.Question, answerToGet: HealthRiskAssessmentModule.Answer): string | number {
        let answer = question.answersGiven.find(a => a.title === answerToGet.title);

        if (answer) {
            return answer.value;
        }

        return null;
    }

    allQuestionsAnswered(): boolean {
        if (!this.currentQuestionnaire) {
            return false;
        }

        for (const phase of this.currentQuestionnaire.phases) {
            if (!phase.isVisible) {
                continue;
            }

            for (const question of phase.questions) {
                if (!question.isVisible) {
                    continue;
                }

                if (!Array.isArray(question.answersGiven) || question.answersGiven.length === 0) {
                    return false;
                }
            }
        }

        return true;
    }

    private getSubstitutions(formula: string): Array<string> {
        const subs: Array<string> = [];

        for (let i = 0; i < formula.length; i++) {
            if (formula[i] === '{') {
                const nextClose = formula.indexOf('}', i);
                subs.push(formula.substring(i, nextClose + 1));
                i = nextClose;
            }
        }

        return subs;
    }

    private getSubstitutionReferencedQuestions(subKey: string, flatQuestions: Array<HealthRiskAssessmentModule.Question>): HealthRiskAssessmentModule.Question {
        const key = subKey.substring(1, subKey.length - 1);
        const referencedQuestion = flatQuestions.find(q => q.key === key);
        return referencedQuestion;
    }

    private getEvaluatedExpression(formula: string, subs: Array<string>, flatQuestions: Array<HealthRiskAssessmentModule.Question>): string {
        for (const sub of subs) {
            const referencedQuestion = this.getSubstitutionReferencedQuestions(sub, flatQuestions);

            if (referencedQuestion.answersGiven.length === 0) {
                return null;
            }

            formula = formula.replace(sub, referencedQuestion.answersGiven[0].value.toString());
        }

        return formula;
    }

    async submit(isCompleted: boolean): Promise<void> {
        this.isLoading = true;
        let achieved = 0;
        let max = 0;
        const flatQuestions: Array<HealthRiskAssessmentModule.Question> = this.currentQuestionnaire.phases.map(p => p.questions).customFlat();

        for (const phase of this.currentQuestionnaire.phases) {
            for (const question of phase.questions) {
                let includeQuestion = true;

                if (question.type === AppConsts.HraQuestionTypes.CALC) {
                    const subs = this.getSubstitutions(question.formula.expression);
                    question.formula.evaluatedExpression = this.getEvaluatedExpression(question.formula.expression, subs, flatQuestions);

                    if (!question.formula.evaluatedExpression && question.formula.fallbackExpression) {
                        const subs = this.getSubstitutions(question.formula.fallbackExpression);
                        question.formula.evaluatedExpression = this.getEvaluatedExpression(question.formula.fallbackExpression, subs, flatQuestions);
                    }

                    includeQuestion = Boolean(question.formula.evaluatedExpression);

                    if (includeQuestion) {
                        const result: number = eval(question.formula.evaluatedExpression);

                        if (question.answers.some(a => Array.isArray(a.range))) {
                            let answer = question.answers.find(a => result >= a.range[0] && result < a.range[1]);

                            if (!answer) {
                                const lowestAnswer = question.answers[0].range[0];
                                const highestAnswer = question.answers[question.answers.length - 1].range[1];

                                if (result < lowestAnswer) {
                                    answer = question.answers[0];
                                } else if (result >= highestAnswer) {
                                    answer = question.answers[question.answers.length - 1];
                                }
                            }

                            question.answersGiven = [answer];
                        } else {
                            const answer = question.answers.find(a => a.value === result);
                            question.answersGiven = [answer];
                        }
                    } else {
                        question.answersGiven = [];
                    }
                } else if (question.type === AppConsts.HraQuestionTypes.INDEX) {
                    const subs = this.getSubstitutions(question.formula.expression);

                    const rowQuestion = this.getSubstitutionReferencedQuestions(subs[0], flatQuestions);
                    const colQuestion = this.getSubstitutionReferencedQuestions(subs[1], flatQuestions);

                    if (rowQuestion.answersGiven.length === 0 || colQuestion.answersGiven.length === 0) {
                        includeQuestion = false;
                        question.answersGiven = [];
                        break;
                    }

                    const rowVal = rowQuestion.answersGiven[0].value;
                    const colVal = colQuestion.answersGiven[0].value;
                    const answer = question.answers.find(a => a.range[0] === rowVal && a.range[1] === colVal);

                    question.answersGiven = [answer];
                } else if (question.type === AppConsts.HraQuestionTypes.REFERENCE) {
                    includeQuestion = question.answersGiven.length > 0;
                } else if (!question.isVisible) {
                    includeQuestion = false;
                }

                if (includeQuestion && question.isScored) {
                    const questionMax = Math.max(...question.answers.map(a => +a.value));
                    let questionAchieved = 0;

                    for (const answerGiven of question.answersGiven) {
                        questionAchieved += +answerGiven.value;
                    }

                    const percentage = questionAchieved / questionMax;
                    const weightedScore = percentage * question.weight;
                    question.score = {
                        achieved: weightedScore,
                        max: question.weight
                    };

                    achieved += weightedScore;
                    max += question.weight;
                }
            }
        }

        achieved = Math.round(achieved * 100);
        max = Math.round(max * 100);

        const create: HealthRiskAssessmentModule.CreateHealthRiskAssessment = {
            data: {
                isCompleted,
                healthRiskAssessment: this.currentQuestionnaire,
                score: {
                    achieved,
                    max
                },
                userDetails: {
                age: this.claims.birthdate ? moment(moment()).diff(moment(this.claims.birthdate, 'DD/MM/YYYY hh:mm:ss A'), 'years') : null,
                    education: this.claims.education,
                    entityNumber: this.authGuard.user.profile.sub,
                    gender: this.claims.gender,
                    grossIncome: +this.claims.grossIncome,
                    netIncome: +this.claims.netIncome,
                    occupation: this.claims.occupation,
                    smokerStatus: this.claims.smokingStatus
                }
            },
            id: this.existingId,
            entityNumber: this.authGuard.user.profile.sub,
            questionnaireKey: this.currentQuestionnaire.key
        };

        let claimList = this.getClaimsToUpdate(isCompleted);
        await Promise.all([
            this.healthCareService.createHealthRiskAssessment(create).toPromise(),
            this.claimModifyService.modifyClaim(claimList).toPromise()
        ]);

        await this.updateClaimsIdsvr();
        this.isLoading = false;
    }

    private getQuestionnaireClaimType(): {
        statusClaim: string,
        dateClaim: string
    } {
        let claim: {
            statusClaim: string,
            dateClaim: string
        } = {
            dateClaim: null,
            statusClaim: null
        };

        switch (this.questionnaireKey) {
            case AppConsts.HraKeys.INTRO:
                claim.statusClaim = AppConsts.ClaimTypes.hra_intro_status;
                claim.dateClaim = AppConsts.ClaimTypes.hra_intro_date;
                break;
            case AppConsts.HraKeys.MY_NUTRITION:
                claim.statusClaim = AppConsts.ClaimTypes.hra_nutrition_status;
                claim.dateClaim = AppConsts.ClaimTypes.hra_nutrition_date;
                break;
            case AppConsts.HraKeys.MY_SLEEP:
                claim.statusClaim = AppConsts.ClaimTypes.hra_sleep_status;
                claim.dateClaim = AppConsts.ClaimTypes.hra_sleep_date;
                break;
            case AppConsts.HraKeys.MY_HEALTHY_BODY:
                claim.statusClaim = AppConsts.ClaimTypes.hra_body_status;
                claim.dateClaim = AppConsts.ClaimTypes.hra_body_date;
                break;
            case AppConsts.HraKeys.MY_HEALTHY_MIND:
                claim.statusClaim = AppConsts.ClaimTypes.hra_mind_status;
                claim.dateClaim = AppConsts.ClaimTypes.hra_mind_date;
                break;
            case AppConsts.HraKeys.MY_EXERCISE:
                claim.statusClaim = AppConsts.ClaimTypes.hra_exercise_status;
                claim.dateClaim = AppConsts.ClaimTypes.hra_exercise_date;
                break;
        }

        return claim;
    }

    getClaimsToUpdate(isCompleted: boolean): ModifyClaimDto {
        let claimList = new ModifyClaimDto();
        claimList.userClaimEditDtos = new Array<UserClaimEditDto>();
        claimList.accessToken = this._authService.user.access_token;
        claimList.userId = this._authService.user.profile.sub;

        if ((!Boolean(this._authService.user.profile.hra_goal_completed) || this._authService.user.profile.hra_goal_completed === 'false') && this.questionnaireKey !== AppConsts.HraKeys.INTRO) {
            const questionnairesToComplete = [1, 2, 3, 4, 5, 5, 1, 2, 3, 4, 5, 5];
            const currentToComplete = questionnairesToComplete[moment().month()];

            let currentCompleted = 0;

            if (isCompleted) {
                currentCompleted++;
            }

            const statusClaims = [
                AppConsts.ClaimTypes.hra_nutrition_status,
                AppConsts.ClaimTypes.hra_sleep_status,
                AppConsts.ClaimTypes.hra_body_status,
                AppConsts.ClaimTypes.hra_mind_status,
                AppConsts.ClaimTypes.hra_exercise_status
            ];

            for (const claim of statusClaims) {
                const claimValue = this._authService.user.profile[claim];

                if (Boolean(claimValue) && claimValue === AppConsts.HraQuestionnaireStatuses.COMPLETED) {
                    currentCompleted++;
                }
            }

            if (currentCompleted >= currentToComplete) {
                claimList.userClaimEditDtos.push(
                    new UserClaimEditDto({
                        claimType: AppConsts.ClaimTypes.hra_goal_completed,
                        claimValue: 'true'
                    }),
                    new UserClaimEditDto({
                        claimType: AppConsts.ClaimTypes.hra_intro_date,
                        claimValue: moment().format('DD/MM/YYYY hh:mm:ss A')
                    }));
            }
        }

        let claim = this.getQuestionnaireClaimType();

        claimList.userClaimEditDtos.push(
            new UserClaimEditDto({
                claimType: claim.statusClaim,
                claimValue: isCompleted ? 'Completed' : 'Saved'
            }),
            new UserClaimEditDto({
                claimType: claim.dateClaim,
                claimValue: moment().format('DD/MM/YYYY hh:mm:ss A')
            })
        );


        return claimList;
    }

    async setQuestionnaire(): Promise<void> {
        const result = await Promise.all([
            import(`../../../../assets/data/health-risk-assessments/${this.questionnaireKey}.json`),
            this.healthCareService.GetLatestHealthRiskAssessment(`${this.authGuard.user.profile.sub}_${this.questionnaireKey}`).toPromise(),
            this.claimService.getClaims(this.authGuard.user.profile.sub, this.authGuard.user.access_token).toPromise(),
        ]);

        this.currentQuestionnaire = result[0];

        if (result[1]) {
            const savedQuestionnaire = result[1];
            let claim = this.getQuestionnaireClaimType();

            if (!savedQuestionnaire.data.isCompleted && (!Boolean(this._authService.user.profile[claim.statusClaim]) || this._authService.user.profile[claim.statusClaim] === AppConsts.HraQuestionnaireStatuses.SAVED)) {
                this.existingId = savedQuestionnaire.id;
                this.currentQuestionnaire = savedQuestionnaire.data.healthRiskAssessment;

                for (const phase of this.currentQuestionnaire.phases) {
                    for (const question of phase.questions) {
                        const answeredQuestionValues = question.answersGiven.map(a => a.value);
                        question.answersGiven = question.answers.filter(a => answeredQuestionValues.includes(a.value));
                    }
                }
            }
        }

        const referencedQuestions = (this.currentQuestionnaire.phases
            .map(p => p.questions)
            .customFlat() as Array<HealthRiskAssessmentModule.Question>)
            .filter(q => Boolean(q.reference))
            .map(q => q.reference);

        if (referencedQuestions.length > 0) {
            const referencedQuestionnaireObservables = referencedQuestions
                .map(r => this.healthCareService.GetLatestHealthRiskAssessment(`${this.authGuard.user.profile.sub}_${r.questionnaireKey}`).toPromise());

            const referencedQuestionnaires = await Promise.all(referencedQuestionnaireObservables);

            for (const phase of this.currentQuestionnaire.phases) {
                for (let question of phase.questions) {
                    if (Boolean(question.reference)) {
                        const referencedQuestionnaire = referencedQuestionnaires.find(r => r.data.healthRiskAssessment.key === question.reference.questionnaireKey);
                        const referencedQuestion = (referencedQuestionnaire.data.healthRiskAssessment.phases
                            .map(p => p.questions)
                            .customFlat() as Array<HealthRiskAssessmentModule.Question>)
                            .find(q => q.key === question.reference.questionKey);

                        question.answersGiven = referencedQuestion.answersGiven;
                    }
                }
            }
        }

        this.questionnaireTitle = this.currentQuestionnaire.title;

        if (this.defaultValues) {
            for (const phase of this.currentQuestionnaire.phases) {
                for (const question of phase.questions) {
                    if (!question.defaultValue) {
                        continue;
                    }

                    if (question.answers.length === 1 && question.answers[0].value === null) {
                        question.answersGiven = question.answers;
                        question.answersGiven[0].value = this.defaultValues[question.defaultValue];
                    } else {
                        const defaultValue = this.defaultValues[question.defaultValue];
                        const answers = question.answers.filter(a => a.value === defaultValue);
                        question.answersGiven = answers;
                    }
                }
            }
        }

        this.claims = result[2];
    }

    getSliderValues(question: HealthRiskAssessmentModule.Question): Array<string> {
        return question.answers
            .sort((a, b) => {

                if (a.value > b.value) {
                    return 1;
                } else if (a.value < b.value) {
                    return -1;
                }

                return +a.title > +b.title ? 1 : -1;
            })
            .map(a => a.title);
    }

    setSliderValue(event: SliderChangeEventArgs, question: HealthRiskAssessmentModule.Question): void {
        const answers = this.getSliderValues(question);
        const answerValue = answers[+event.value];
        const answer = question.answers.find(a => a.title === answerValue);
        question.answersGiven = [answer];
    }

    getSliderValue(question: HealthRiskAssessmentModule.Question): number {
        const answers = this.getSliderValues(question);

        if (question.answersGiven.length === 0) {
            question.answersGiven = [question.answers[0]];
        }

        const index = answers.findIndex(a => a === question.answersGiven[0].title);
        return index;
    }

    hasSuffix(question: HealthRiskAssessmentModule.Question): boolean {
        return Boolean(question.suffix);
    }
}
