﻿import { Inject, Injectable, Injector } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { mergeMap as _observableMergeMap, catchError as _observableCatch, map } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf, of, forkJoin } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';
import { ProductListModule, BenefitModule, PremiumPatternModule, RateTableModule, BenficiaryModule, BenificeryModule, QuoteModule, IIncrementalQuote, RatingFactorModule, BeneficieryOutputModule, RatingFactorInputResponseModule, RatingFactorResponseNewModule, RateTableEntriesFlatModule, GetMaxSumAssuredModule, GetAllQuotes, GetAllQuotesResult, CommissionModule, PoliciesModule } from '@shared/models/interfaces/elevate-data-models';
import { ProductQuote, RiderProductQuote } from '@shared/extensions/my-command-center-extensions';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';
import { HttpMethodBaseService } from './HttpMethodsBase/httpmethods-base';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';


const ACCESS_TOKEN = '';

@Injectable()
export class ProductService {
    private cachedResponses = new Map();
    private productRateTableMap = new Map();
    readonly API_BASE_URL_IDP = abp.setting.values['PortalSettings.authority'];
    //readonly API_BASE_URL_IDP = 'http://localhost:53749';
    readonly ELEVATE_ACCP_ENDPOINT = abp.setting.values[AppConsts.KeyVaultSecrets.apimElevateAccpendpoint];
    readonly APIM_CLIENT_ENDPOINT = abp.setting.values[AppConsts.KeyVaultSecrets.apimClientEndpoint];


    constructor(
        private httpMethodBaseService: HttpMethodBaseService,
        private _authService: AuthService,
        @Inject(API_BASE_URL) private appServiceBaseUrl
    ) { }

    getProductList(generation?: string): Observable<ProductListModule.IProductListModel[]> {
        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getAllProduct.replace('{GENERATION_NAME}', abp.setting.values[AppConsts.KeyVaultSecrets.ActiveGenerationName]);
        const conditionsFromCache = this.cachedResponses.get(_url);
        if (conditionsFromCache) { return of(conditionsFromCache); }
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        return this.httpMethodBaseService.get<ProductListModule.IProductListModelResult>(_url, headers)
            .pipe(map((res: ProductListModule.IProductListModelResult) => {
                this.cachedResponses.set(_url, res.result);
                return res.result;
            }));
    }


    getBenefitPatterSetForMultipleUid(uid: string[]): Observable<BenefitModule.IBenefitModel[]> {

        let observableArray = new Array<Observable<BenefitModule.IBenefitModel>>();

        uid.forEach(x => {
            observableArray.push(this.getBenefitPatternSet(x));
        });

        return forkJoin(observableArray).pipe(map((res: BenefitModule.IBenefitModel[]) => {
            return res;
        }));
    }


    getBenefitPatternSet(uid: string): Observable<BenefitModule.IBenefitModel> {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });

        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPatternSet.replace('{GUID}', uid);

        return this.httpMethodBaseService.get<BenefitModule.IBenefitModelResult>(_url, headers)
            .pipe(map((res: BenefitModule.IBenefitModelResult) => {
                return res.result;
            }));
    }


    getPremiumPatterSetForMultipleUid(uid: string[]): Observable<PremiumPatternModule.IPremiumPatternModel[]> {

        let observableArray = new Array<Observable<PremiumPatternModule.IPremiumPatternModel>>();

        uid.forEach(x => {
            observableArray.push(this.getPremiumPatternSet(x));
        });

        return forkJoin(observableArray).pipe(map((res: PremiumPatternModule.IPremiumPatternModel[]) => {
            return res;
        }));
    }

    getPremiumPatternSet(uid: string): Observable<PremiumPatternModule.IPremiumPatternModel> {
        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPatternSet.replace('{GUID}', uid);

        const conditionsFromCache = this.cachedResponses.get(_url);
        if (conditionsFromCache) { return of(conditionsFromCache); }

        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });

        let response = this.httpMethodBaseService.get<PremiumPatternModule.IPremiumPatternModelResult>(_url, headers)
            .pipe(map((res: PremiumPatternModule.IPremiumPatternModelResult) => {
                return res.result;
            }));

        response.subscribe(res => this.cachedResponses.set(_url, res));

        return response;

    }


    getRateTableForProduct(productId: string, ratingFactorSet: RatingFactorInputResponseModule.RatingFactor[], lowerLimit: number | null = null, upperLimit: number | null = null): Observable<RateTableEntriesFlatModule.RateTableEntries[]> {
        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getRateTableEntryFlat;
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });

        const body: {
            ProductGUID: string,
            RatingFactors: Array<RatingFactorInputResponseModule.RatingFactor>,
            SumAssuredUpperLimit?: number,
            SumAssuredLowerLimit?: number
        } = {
            ProductGUID: productId,
            RatingFactors: ratingFactorSet
        };

        if (upperLimit) {
            body.SumAssuredUpperLimit = upperLimit;
        }

        if (lowerLimit) {
            body.SumAssuredLowerLimit = lowerLimit;
        }

        let response = this.httpMethodBaseService.post<RateTableEntriesFlatModule.RateTableEntriesResult>(_url, body, headers)
            .pipe(map((res: RateTableEntriesFlatModule.RateTableEntriesResult) => {
                return res.result;
            }));
        return (response as any);
    }

    productQuoted(quoteId: string, data: ProductQuote, productQuoteId, requestMethod: string): Observable<any> {

        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.postProductQuoted.replace('{Quote_GUID}', quoteId);
        if (productQuoteId) {
            _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.putProductQuoted.replace('{Quote_GUID}', quoteId).replace('{ProductQuoted_GUID}', productQuoteId);
        }
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });

        return this.httpMethodBaseService.request<any>(requestMethod, _url, data, headers)
            .pipe(map((res: any) => {
                return res ? res.result : undefined;
            }));
    }


    addBeneficeryToQuote(productQuotedId: string, quoteId: string, beneficiaryForQuote: BenificeryModule.IBeneficiaryForQuote[]): Observable<boolean> {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        return this.httpMethodBaseService.post<boolean>(this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.createBeneficiary
            .replace('{Quote_GUID}', quoteId)
            .replace('{ProductQuoted_GUID}', productQuotedId), beneficiaryForQuote, headers)
            .pipe(map(res => {
                return true;
            }));
    }

    getBeneficary(quoteUid: string, productUid: string): Observable<Array<{
        AllocationPercentage: number
        AssuredType: string
        Beneficiary: string
    }>> {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        return this.httpMethodBaseService.get<any>(this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getBeneficiariesForProductQuoted
            .replace('{Quote_GUID}', quoteUid)
            .replace('{ProductQuoted_GUID}', productUid), headers)
            .pipe(map(res => {
                return res.result;
            }));
    }


    getAllBeneficieriesForQuote(quoteId, productId): Observable<BeneficieryOutputModule.BeneficieryOutput[]> {
        return this.httpMethodBaseService.get<BeneficieryOutputModule.BeneficieryOutputResult>(this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getBeneficiariesForProductQuoted
            .replace('{Quote_GUID}', quoteId)
            .replace('{ProductQuoted_GUID}', productId))
            .pipe(map(res => {
                return res.result;
            }));
    }

    addNonIndividualExclusionSet(quoteId: string, exclusionSetShortCode?: string): Observable<boolean> {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        return this.httpMethodBaseService.post<boolean>(this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.addNonIndividualExclusionSet
            .replace('{QuoteGUID}', quoteId)
            .replace('{ExclusionSetShortCode}', abp.setting.values[AppConsts.KeyVaultSecrets.GeneralExclusions]), '', headers)
            .pipe(map(res => {
                return true;
            }));
    }

    acceptQuote(input: { accessToken: string, quoteId: string, includeIntermediaryDocument: boolean, senderGuid: string, ccLifeAssured: boolean, ccEmails: Array<string> }): Observable<any> {
        return this.httpMethodBaseService.post<any>(`${this.appServiceBaseUrl}/api/services/app/Quotes/AcceptQuote`, input);
    }

    getNeedsAssessmentSumAssuredLevels(): Observable<Array<QuoteModule.INeedsAssessmentSumAssuredLevel>> {
        return this.httpMethodBaseService.get<{ result: Array<QuoteModule.INeedsAssessmentSumAssuredLevel> }>(`${this.appServiceBaseUrl}/api/services/app/Quotes/GetNeedsAssessmentSumAssuredLevels`)
            .pipe(map(res => res.result));
    }

    sendUpdateBeneficiaryMail(policyUid: string): Observable<boolean> {
        return this.httpMethodBaseService.post<boolean>(`${this.appServiceBaseUrl}/api/services/app/Email/SendUpdateBeneficiaryMail?policyUid=${policyUid}`);
    }

    sendUpdateBankingMail(policyUid: string): Observable<boolean> {
        return this.httpMethodBaseService.post<boolean>(`${this.appServiceBaseUrl}/api/services/app/Email/SendUpdateBankingMail?policyUid=${policyUid}`);
    }

    getAQuote(quoteId: string): Observable<QuoteModule.IQuoteDetails> {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        return this.httpMethodBaseService.get<QuoteModule.IQuoteDetailsResult>(this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getAQuote
            .replace('{Quote_GUID}', quoteId), headers)
            .pipe(map(res => {
                return res.result;
            }));
    }

    getQuoteOnAPersonBasedOnRole(personGuid: string, role: string): Observable<Array<GetAllQuotes>> {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        return this.httpMethodBaseService.get<GetAllQuotesResult>(this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getQuoteOnAPersonBasedOnRole
            .replace('{PersonGUID}', personGuid).replace('{Role}', role), headers)
            .pipe(map(res => {
                return res.result;
            }));
    }

    createOrUpdateRiderProduct(data: RiderProductQuote, quoteId, productQuoteID, riderQuotedId?): Observable<any> {
        let request = 'post';
        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.createRider.replace('{Quote_GUID}', quoteId).replace('{MainProductQuoted_GUID}', productQuoteID);
        if (riderQuotedId) {
            _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.updateRider.replace('{Quote_GUID}', quoteId).replace('{MainProductQuoted_GUID}', productQuoteID).replace('{Rider_Quoted_GUID}', riderQuotedId);
            request = 'put';
        }
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        return this.httpMethodBaseService.request<any>(request, _url, data, headers)
            .pipe(map(res => {
                return res.result;
            }));
    }

    getRiderProductQuoted(quoteId, productQuoteID) {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getQuotedRider.replace('{Quote_GUID}', quoteId).replace('{ProductQuoted_GUID}', productQuoteID);
        return this.httpMethodBaseService.get<any>(_url, headers)
            .pipe(map(res => {
                return res.result;
            }));
    }

    deleteRiderProduct(quoteId, productQuotedId) {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.deleteRiderProduct.replace('{Quote_GUID}', quoteId).replace('{ProductQuoted_GUID}', productQuotedId);
        return this.httpMethodBaseService.delete<any>(_url, headers)
            .pipe(map(res => {
                return res.result;
            }));
    }

    //idp

    createOrUpdateBeneficiaryAndGetEntityId(beneficiaryDetails: BenficiaryModule.IBeneficiaryModel): Observable<string> {
        return this.httpMethodBaseService.post<string>(this.API_BASE_URL_IDP + AppConsts.Endpoints.addBeneficiary, beneficiaryDetails)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteBeneficiary(beneficiaryGuid: string, policyGuid: string): Observable<{}> {
        return this.httpMethodBaseService.delete(this.API_BASE_URL_IDP + AppConsts.Endpoints.deleteBeneficiary.replace('{BENEFICIARY_GUID}', beneficiaryGuid).replace('{POLICY_GUID}', policyGuid));
    }

    getBeneficiariesForPolicy(policyGuid: string): Observable<Array<BenficiaryModule.IBeneficiaryModel>> {
        return this.httpMethodBaseService.get<Array<BenficiaryModule.IBeneficiaryModel>>(this.API_BASE_URL_IDP + AppConsts.Endpoints.getBeneficiariesForPolicy.replace('{POLICY_GUID}', policyGuid));
    }

    getBeneficaryFromIdp(guid: string, policyUid: string = null): Observable<BenficiaryModule.IBeneficiaryModel> {
        let queryParams = '';

        if (policyUid != null) {
            queryParams += '?policyGuid=' + policyUid;
        }

        return this.httpMethodBaseService.get<BenficiaryModule.IBeneficiaryModel>(this.API_BASE_URL_IDP + AppConsts.Endpoints.getBeneficaryFromIdp.replace('{BENEFICIARY_GUID}', guid) + queryParams);
    }

    getAllProductSetupsFromRates(accessToken: string): Observable<Array<BenficiaryModule.IProductConfig>> {
        return this.httpMethodBaseService
            .get<{ result: Array<BenficiaryModule.IProductConfig> }>(`${this.appServiceBaseUrl}/api/services/app/Product/GetAllProductSetups?accessToken=${accessToken}`)
            .pipe(map(res => res.result));
    }

    addIndividualExclusionSet(params: {
        ShortCode: string,
        DisplayName: string,
        CategoryCode: string,
        CategoryName: string,
        Description: string
    }, quoteId: string) {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        return this.httpMethodBaseService.post<string>(this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.addIndividualExclusionSet.replace('{QUOTE_ID}', quoteId), params, headers)
            .pipe(map(res => {
                return res;
            }));
    }

    addExclusion(params: {
        ExclusionCode: string,
        DisplayName: string,
        ConditionName: string,
        Description: string
    }, quoteId: string) {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        return this.httpMethodBaseService.post<string>(this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.addExclusion.replace('{QUOTE_ID}', quoteId), params, headers)
            .pipe(map(res => {
                return res;
            }));
    }

    getMendixProductAndQuircMapping(): Observable<any> {
        let url = abp.setting.values[AppConsts.KeyVaultSecrets.AppConfigAngularQuirckMendixMappingUrl];
        let cachedResponse = this.cachedResponses.get(url);
        if (cachedResponse) {
            return of(cachedResponse);
        }
        return this.httpMethodBaseService.get<any>(url)
            .pipe(map(res => {
                this.cachedResponses.set(url, res);
                return res;
            }));
    }

    getAllEventSet(): Observable<any> {

        let cachedResponse = this.cachedResponses.get(this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getAllEventSet);
        if (cachedResponse) {
            return of(cachedResponse);
        }

        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });

        return this.httpMethodBaseService.get<any>(this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getAllEventSet, headers)
            .pipe(map(res => {
                this.cachedResponses.set(this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getAllEventSet, res.result);
                return res.result;
            }));
    }

    getMaxAndMinSummAssured(getMaxInput: GetMaxSumAssuredModule.GetMaxSumAssuredInput): Observable<number> {
        let url = abp.setting.values[AppConsts.KeyVaultSecrets.GetMaxSumAssuredFuncUrl];
        return this.httpMethodBaseService.post<number>(url, getMaxInput);
    }


    getBenefitPatterns(guids: Array<string>, accessToken: string): Observable<Array<PoliciesModule.IBenefitPattern>> {
        const url = `${this.appServiceBaseUrl}/api/services/app/Product/PostBenefitPatterns`;
        return this.httpMethodBaseService
            .post<{ result: Array<PoliciesModule.IBenefitPattern> }>(url, {
                accessToken: accessToken,
                guids: guids
            })
            .pipe(map(res => res.result));
    }

    getPremiumPatterns(guids: Array<string>, accessToken: string): Observable<Array<PoliciesModule.IPremiumPattern>> {
        const url = `${this.appServiceBaseUrl}/api/services/app/Product/PostPremiumPatterns`;
        return this.httpMethodBaseService
            .post<{ result: Array<PoliciesModule.IPremiumPattern> }>(url, {
                accessToken: accessToken,
                guids: guids
            })
            .pipe(map(res => res.result));
    }

    getProducts(guids: Array<string>, accessToken: string): Observable<Array<ProductListModule.IProductListModel>> {
        const url = `${this.appServiceBaseUrl}/api/services/app/Product/PostProducts`;
        return this.httpMethodBaseService
            .post<{ result: Array<ProductListModule.IProductListModel> }>(url, {
                accessToken: accessToken,
                guids: guids
            })
            .pipe(map(res => res.result));
    }

    getBrokerCommission(brokerAdminGuid: string, quoteGuid: string): Observable<CommissionModule.BrokerCommission> {
        const url = abp.setting.values[AppConsts.KeyVaultSecrets.AppGetBrokerCommissionFuncUrl] + '?brokerAdminGuid=' + brokerAdminGuid + '&quoteGuid=' + quoteGuid;
        return this.httpMethodBaseService.get<CommissionModule.BrokerCommission>(url);
    }
}
