import { Injector, Component, ViewEncapsulation, Inject } from '@angular/core';

import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';

import { DOCUMENT } from '@angular/common';
import { AuthService } from '../../../common/IDSVRAuthProviders/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './theme3-brand.component.html',
    selector: 'theme3-brand',
    encapsulation: ViewEncapsulation.None
})
export class Theme3BrandComponent extends AppComponentBase {

    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

    private _isAuthenticated: boolean;
    public get isAuthenticated(): boolean {
        return this._isAuthenticated;
    }
    public set isAuthenticated(value: boolean) {
        this._isAuthenticated = value;
    }

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        private route: ActivatedRoute
    ) {
        super(injector);
        this.isAuthenticated = this._authService.isLoggedIn();
    }

    isInExtQuote(): boolean {
        try {
            const url = this.route.snapshot['_routerState'].url;
            return url.includes('ExtQuote') || url.includes('ExtCalculator') || url.includes('fna');
        } catch {
            return false;
        }
    }

    clickTopbarToggle(): void {
        this.document.body.classList.toggle('m-topbar--on');
    }

    clickLeftAsideHideToggle(): void {
        this.document.body.classList.toggle('m-aside-left--hide');
    }
}
