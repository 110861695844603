import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetMyActiveMinutesComponent } from './widget-my-active-minutes/widget-my-active-minutes.component';
import { WidgetMyStepsComponent } from './widget-my-steps/widget-my-steps.component';
import { WidgetMyTotalDistanceComponent } from './widget-my-total-distance/widget-my-total-distance.component';
import { WidgetMyActiveCaloriesComponent } from './widget-my-active-calories/widget-my-active-calories.component';
import { WidgetMySyncedActivitiesComponent } from './widget-my-synced-activities/widget-my-synced-activities.component';
import { WidgetLastSevenDaysComponent } from './widget-last-seven-days/widget-last-seven-days.component';
import { AppCommonModule } from '../../app-common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations:
    [
      WidgetMyActiveMinutesComponent,
      WidgetMyStepsComponent,
      WidgetMyTotalDistanceComponent,
      WidgetMyActiveCaloriesComponent,
      WidgetMySyncedActivitiesComponent,
      WidgetLastSevenDaysComponent
    ],
  imports: [
    CommonModule,
    AppCommonModule,
    UtilsModule,
    RouterModule
  ],
  exports: [
    WidgetMyActiveMinutesComponent,
    WidgetMyStepsComponent,
    WidgetMyTotalDistanceComponent,
    WidgetMyActiveCaloriesComponent,
    WidgetMySyncedActivitiesComponent,
    WidgetLastSevenDaysComponent
  ]
})
export class MyWearablesWidgetsModule { }
