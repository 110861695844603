import { Component, OnInit, Injector, ViewChild, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HealthCareApiServiceProxy, PopulationEpisodeDataOutput, CreateHealthCareUserInputDto, ClaimModifyServiceProxy, ModifyClaimDto, SmsBody, UserClaimEditDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective, document } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { AppConsts } from '@shared/AppConsts';
import { MyCommandCenterService } from '@app/main/my-command-centre/my-command-center.service';
import { PersonModule } from '@shared/models/interfaces/elevate-data-models';
import { FacebookService } from '@shared/facebook/facebook.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
    selector: 'appmyhealthcaremodal',
    templateUrl: './my-health-care-modal.component.html',
    styleUrls: ['./my-health-care-modal.component.css']
})
export class MyHealthCareModalComponent extends AppComponentBase implements OnDestroy, OnInit {
    idNumber: string;

    constructor(
        public datepipe: DatePipe,
        injector: Injector,
        private _claimModify: ClaimModifyServiceProxy,
        private _healthCare: HealthCareApiServiceProxy,
        private _commandCenterService: MyCommandCenterService,
        private _facebookService: FacebookService,
        private router: Router
    ) {
        super(injector);
        this.medicalSchemes = [];
    }

    @ViewChild('myhealthmodal') modal: ModalDirective;

    @ViewChild('HealthId') nameInput: ElementRef;

    @Output() healthcareChanged = new EventEmitter<boolean>();

    episodeCareData = new Array<PopulationEpisodeDataOutput>();

    active = false;
    saving = false;
    schemename: string;
    MemberShipNo = '';
    checkedTf = false;
    medicalSchemes: Array<PersonModule.MedicalScheme>;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit(): void {
        this._commandCenterService.getMedicalSchemes(this._authService.user.access_token).subscribe(result => {
            this.medicalSchemes = result;
            this.setMedicalScheme();
        });
    }

    private setMedicalScheme() {
        const currentMedicalScheme = this.medicalSchemes.find(m => m.medicalSchemeName === this._authGuard.user.profile.medical_scheme_name);

        if (currentMedicalScheme) {
            this.schemename = currentMedicalScheme.medicalSchemeName;
            this.MemberShipNo = this._authGuard.user.profile.medical_scheme_membership_number !== '0000000000000' ? this._authGuard.user.profile.medical_scheme_membership_number : '';
        }
    }


    private async updateIdNumberClaim(): Promise<void> {
        let mdto = new ModifyClaimDto();
        mdto.accessToken = this._authService.user.access_token;
        mdto.newClaimType = 'id_number';
        mdto.newClaimValue = this.idNumber.toString();
        mdto.userId = this._authGuard.user.profile.sub;

        await this._claimModify.modifyClaim(mdto).toPromise();
        await this.updateClaimsIdsvr();
    }

    show() {
        this.modal.show();
        this.active = true;
        this.checkedTf = false;

        if (this.medicalSchemes && this.medicalSchemes.length > 0) {
            this.setMedicalScheme();
        }
    }

    changeTemplatePopup(item, item1) {
        if (item !== undefined) {
            document.getElementsByClassName('swal2-confirm')[0].innerText = item;
            document.getElementsByClassName('swal2-confirm')[0].classList.add('modal-button-clr');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('font-size-12');
        }

        if (item1 !== undefined) {
            document.getElementsByClassName('swal2-cancel')[0].innerText = item1;
            document.getElementsByClassName('swal2-cancel')[0].classList.add('modal-button-clr');
            document.getElementsByClassName('swal2-cancel')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-cancel')[0].classList.add('font-size-12');
        }
    }

    getHealthCareUserInputDto(): CreateHealthCareUserInputDto | null {
        let dtoHe = new CreateHealthCareUserInputDto();
        const date = moment(this._authGuard.user.profile.birthdate, 'DD/MM/YYYY hh:mm:ss A');
        dtoHe.doBserialized = this.datepipe.transform(date, 'yyyy-MM-dd');
        dtoHe.gender = this._authGuard.user.profile.gender.toLowerCase() === 'Male'.toLowerCase() ? 'M' : 'F';
        dtoHe.schemeName = this.schemename;
        dtoHe.membershipNumber = this.MemberShipNo;
        dtoHe.firstName = this._authGuard.user.profile.given_name;
        return dtoHe;
    }

    getInputForHealthCareClaimChange(newClaimValue): ModifyClaimDto | null {
        let mdto = new ModifyClaimDto();
        mdto.accessToken = this._authService.user.access_token;
        mdto.newClaimType = AppConsts.allowhealthcare;
        mdto.newClaimValue = newClaimValue;
        mdto.userId = this._authGuard.user.profile.sub;
        return mdto;
    }

    private updateMedicalSchemeClaims(): void {
        if (this.schemename !== this._authGuard.user.profile.medical_scheme_name) {
            if (this._authGuard.user.profile.medical_scheme_name === AppConsts.SupportedMedicalAids.Medshield || this._authGuard.user.profile.medical_scheme_name === AppConsts.SupportedMedicalAids.Bestmed) {
                this._claimModify.deleteUserRole(this._authService.user.access_token, 'FreeUser1').subscribe(() => {
                    this._authService.getUserRoleViaAjax(() => { });
                });
            } else if (this.schemename === AppConsts.SupportedMedicalAids.Medshield || this.schemename === AppConsts.SupportedMedicalAids.Bestmed) {
                this._claimModify.addUserRole(this._authService.user.access_token, 'FreeUser1').subscribe(() => {
                    this._authService.getUserRoleViaAjax(() => { });
                });
            }

            let claimList = new ModifyClaimDto();
            claimList.userClaimEditDtos = new Array<UserClaimEditDto>();

            claimList.userClaimEditDtos.push(new UserClaimEditDto({
                claimType: 'medical_scheme_name',
                claimValue: this.schemename
            }));

            claimList.userClaimEditDtos.push(new UserClaimEditDto({
                claimType: 'medical_scheme_membership_number',
                claimValue: this.MemberShipNo
            }));

            claimList.accessToken = this._authService.user.access_token;
            claimList.userId = this._authGuard.user.profile.sub;

            this._claimModify.modifyClaim(claimList).subscribe(() => {
                this.updateClaimsIdsvr();
            }, (error) => {
                abp.ui.clearBusy();
                console.log(error);
            });
        }
    }

    private showFailedRegisterMessage(): void {
        let currentCompThis = this;

        abp.message.confirm(
            AppConsts.HelthCareRetryMessage,
            'Oops! HealthCare activation failed!',
            function (isConfirmed) {
                abp.ui.setBusy();
                currentCompThis._claimModify.modifyClaim(currentCompThis.getInputForHealthCareClaimChange('false')).subscribe(
                    res => {
                        abp.ui.clearBusy();
                        currentCompThis.updateClaimsIdsvr();
                    },
                    (err) => {
                        abp.ui.clearBusy();
                    });
                if (isConfirmed) {
                    currentCompThis.MemberShipNo = '';
                    return;
                } else {
                    currentCompThis.close();
                    currentCompThis.sendsms();
                    currentCompThis.MemberShipNo = '';
                }
            }, true);
        this.changeTemplatePopup(AppConsts.tryAgain, AppConsts.contactSupport);
        abp.ui.clearBusy();
    }

    async save() {
        abp.ui.setBusy();
        this.updateMedicalSchemeClaims();
        this.saving = true;

        if (this._authGuard.user.profile.id_number !== this.idNumber.toString()) {
            if (this._authGuard.user.profile.id_number === '0000000000000') {
                await this.updateIdNumberClaim();
            } else {
                abp.ui.clearBusy();
                abp.message.warn('Your ID number does not match the one we have on record', 'Wearables activation failed', false);
                this._commandCenterService.changeTemplatePopup('OK', undefined);
                this.close();
                return;
            }
        }

        this._claimModify.modifyClaim(this.getInputForHealthCareClaimChange('true')).subscribe(res => {
            this.updateClaimsIdsvr().then(() => {
                if (this.schemename !== AppConsts.SupportedMedicalAids.Medshield && this.schemename !== AppConsts.SupportedMedicalAids.Bestmed) {
                    const message =
                        'A user has tried to activate healthcare data for an unsupported medical scheme\n\n' +
                        'Name: ' + this._authService.user.profile.name + '\n' +
                        'Surname: ' + this._authService.user.profile.surname + '\n' +
                        'Email: ' + this._authService.user.profile.email + '\n' +
                        'Phone: ' + this._authService.user.profile.phone_number + '\n' +
                        'Medical scheme: ' + this.schemename;

                    this._emailService.sendGenericMail('Unsupported medical scheme', message, abp.setting.get('SupportEmail.To')).subscribe(() => {
                        console.log('Unsupported medical scheme email sent');
                    });

                    this._healthCare.registerUnsupportedUser(this._authService.user.access_token, this.getHealthCareUserInputDto()).subscribe(
                        () => {
                            abp.ui.clearBusy();
                            this.modal.hide();

                            const popup = abp.message.info(
                                'We do not support this medical scheme just yet. We are working hard to increase our network of healthcare data partners. We have stored your consent and we will sync through this data as soon as this integration is available.<br><br><b>In the meantime, complete the Elevate Health Risk Assessment on the next page to understand your state of health and wellbeing at a whole new level!',
                                'Healthcare data activation unsuccessful',
                                true);

                            this.changeTemplatePopup('CONTINUE', undefined);

                            popup.then(() => {
                                this.router.navigateByUrl('/app/main/MyHealthCare/RiskAssessment');
                            });
                        },
                        (err) => {
                            abp.ui.clearBusy();
                            console.error(err);
                            this.showFailedRegisterMessage();
                        });
                } else {
                    this._healthCare.registerUser(this._authService.user.access_token, this.getHealthCareUserInputDto()).subscribe(
                        () => {
                            abp.ui.clearBusy();
                            abp.message.success(AppConsts.healthCareSucees,
                                'Healthcare data activated!', true);
                            this.changeTemplatePopup('CLOSE', undefined);
                            this.healthcareChanged.emit(true);
                            this.saving = true;
                            this.active = false;
                            this.updateClaimsIdsvr();
                            this._facebookService.logEvent('Portal activate healthcare');
                            this.modal.hide();
                        },
                        (err) => {
                            abp.ui.clearBusy();
                            console.error(err);
                            this.showFailedRegisterMessage();
                        });
                }
            });
        });
    }

    sendsms(PageName?) {
        let body = new SmsBody();
        body.userid = this._authGuard.user.profile.sub;
        body.firstName = this._authGuard.user.profile.given_name;
        body.lastName = this._authGuard.user.profile.family_name;
        body.email = this._authGuard.user.profile.email;
        body.phoneNumber = Array.isArray(this._authGuard.user.profile.phone_number) ? this._authGuard.user.profile.phone_number[0] : this._authGuard.user.profile.phone_number;
        body.pageName = PageName;
        body.message = 'We Want to activate HealthCare Data';
        this.sendQueryEmail(body);
    }

    close(): void {
        this.idNumber = null;
        this.active = false;
        this.MemberShipNo = '';
        this.modal.hide();
        this.updateClaimsIdsvr();
    }

    ngOnDestroy(): void {
        this.modalSave.unsubscribe();
    }
}
