import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EpisodeCareComponent } from './episode-care/episode-care.component';
import { HospitalEventsComponent } from './hospital-events/hospital-events.component';
import { WidgetHealthRecordComponent } from './widget-health-record/widget-health-record.component';
import { AppCommonModule } from '../../app-common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [EpisodeCareComponent, HospitalEventsComponent, WidgetHealthRecordComponent],
  imports: [
    CommonModule,
    AppCommonModule,
    UtilsModule,
    RouterModule,

  ],
  exports: [EpisodeCareComponent, HospitalEventsComponent, WidgetHealthRecordComponent]

})
export class MyHealthcareWidgetsModule { }
