import { MyFinancialServiceProxy, GetMyFundsDetailsOutput, MyNetWorthOutput, TransactionHistoryOuput, TransactionCategoryOutputDto, AccountSummaryOutputWithType, DropDownOutputDto, AccountDropdownDetails, ChartOuptutDto, YodleeServiceProxy, LastFinancialDateDto, AccountLastUpdatedDate } from '@shared/service-proxies/service-proxies';
import { CommonhelperService } from './commonhelper.service';
import * as moment from 'moment/moment';
import { MessageService } from '@app/main/my-financials/financials.service';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../../main/my-financials/financials.service";
import * as i2 from "../../../shared/service-proxies/service-proxies";
import * as i3 from "./commonhelper.service";
import * as i4 from "../common/IDSVRAuthProviders/auth.service";
var MyFinancialService = /** @class */ (function () {
    function MyFinancialService(messageService, _financialServiceProxy, _yodleeAppService, _commonService, _authService) {
        this.messageService = messageService;
        this._financialServiceProxy = _financialServiceProxy;
        this._yodleeAppService = _yodleeAppService;
        this._commonService = _commonService;
        this._authService = _authService;
        this.breadCrumbsDetail = new Array(3);
        this.fundDetails = new Array();
        this.netWorthOuputDetails = new MyNetWorthOutput();
        this.transactionHistoryOuput = new Array();
        this.transactionCategoryOutputDtofront = new Array();
        this.transactionCategoryOutputDto = new Array();
        this.accountSummaryOutput = new Array();
        this.pageNo = 0;
        this.startDate = null;
        this.endDate = null;
        this.accountLastUpdatedDates = new Array();
        this.lastUpdatedDate = null;
        this.graphData = new Array();
        this.totalSum = 0;
        this.fetchingRecords = false;
        this.breadCrumbsDetail = [{
                routePath: '/app/main/dashboard',
                routeTitle: 'MY DATA'
            },
            {
                routePath: '/app/main/MyFinancials',
                routeTitle: 'MY FINANCES'
            }];
        this.dropDownOutput = new DropDownOutputDto();
        this.dropDownOutput.accountType = new Array();
        this.dropDownOutput.accountDropdownDetails = new Array();
        this.dropDownOutput.fullDetails = new Array();
    }
    MyFinancialService.prototype.isMyFinancialactivated = function () {
        return this._commonService.myFinancialactivated;
    };
    MyFinancialService.prototype.getAllData = function (startDate, endDate, lastUpdatedDateList) {
        var _this = this;
        this.hasFinishedGetAll = false;
        var startDateJson = JSON.parse(JSON.stringify(startDate));
        var endDateJson = JSON.parse(JSON.stringify(endDate));
        this.getFunds(startDateJson, endDateJson).subscribe(function (res) {
            _this.fundDetails = res.length > 0 ? res : _this.fundDetails;
        }, function (err) {
            console.error(err);
        }, function () {
            _this.messageService.sendMessage('getFunds');
        });
        this.getMyNetWorth(startDateJson, endDateJson, lastUpdatedDateList).subscribe(function (res) {
            _this.netWorthOuputDetails = res;
        }, function (err) {
            console.error(err);
        }, function () {
            _this.messageService.sendMessage('getMyNetWorth');
        });
        this.getMyAccountSummary(startDateJson, endDateJson, lastUpdatedDateList)
            .subscribe(function (res) {
            _this.accountSummaryOutput = res.length > 0 ? res : _this.accountSummaryOutput;
            var accountIds = [];
            _this.accountSummaryOutput.forEach(function (y) { return y.accountSummaryOutputs.forEach(function (x) { return accountIds.push(x.accountId); }); });
            _this.accountSummaryOutput.forEach(function (y) { return y.accountSummaryOutputs.forEach(function (x) { return x.showDelete = true; }); });
            _this.accountSummaryOutput.forEach(function (y) { return y.accountSummaryOutputs.forEach(function (x) { return x.showConfirm = false; }); });
            _this.getMyTransaction(startDateJson, endDateJson, accountIds).subscribe(function (res) {
                _this.transactionHistoryOuput = res.length > 0 ? res : _this.transactionHistoryOuput;
                _this.transactionCategoryOutputDtofront = res.length > 0 ? res : _this.transactionCategoryOutputDtofront;
            }, function (err) {
                console.error(err);
            }, function () {
                _this.messageService.sendMessage('getMyTransaction');
            });
        }, function (err) {
            console.error(err);
        }, function () {
            _this.messageService.sendMessage('getMyAccountSummary');
        });
        this.getMyTransactionCategories(startDateJson, endDateJson)
            .subscribe(function (res) {
            _this.transactionCategoryOutputDto = res.length > 0 ? res : _this.transactionCategoryOutputDto;
            _this.totalSum = 0;
            _this.transactionCategoryOutputDto.forEach(function (res) {
                _this.totalSum += Math.abs(Math.round(res.amount));
            });
        }, function (err) {
            console.error(err);
        }, function () {
            _this.messageService.sendMessage('getMyTransactionCategories');
        });
    };
    MyFinancialService.prototype.getFunds = function (startDate, endDate) {
        var _this = this;
        return this._financialServiceProxy.getMyFunds(moment(startDate), moment(endDate), this._authService.user.access_token).pipe(finalize(function () { _this.fetchingRecords = false; }));
    };
    MyFinancialService.prototype.getGraphData = function (startDate, endDate, accountId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._financialServiceProxy.getGranulDataForGraph(moment(startDate), moment(endDate), _this._authService.user.access_token, accountId).pipe(finalize(function () { _this.fetchingRecords = false; }))
                .subscribe(function (res) {
                _this.graphData = res;
                _this.messageService.sendMessage('getGraphData');
                abp.ui.clearBusy();
                resolve(null);
            }, function (err) {
                reject(null);
                abp.ui.clearBusy();
            });
        });
    };
    MyFinancialService.prototype.isEntityRegistered = function () {
        var _this = this;
        return new Promise(function (resovle, reject) {
            _this._yodleeAppService.isEntityRegistered(_this._authService.user.access_token)
                .pipe(finalize(function () { _this.fetchingRecords = false; })).subscribe(function (res) {
                _this._commonService.myFinancialactivated = res;
                return resovle(res);
            }, function (err) {
                return reject(false);
            });
        });
    };
    MyFinancialService.prototype.getMyNetWorth = function (startDate, endDate, lastUpdatedDateList) {
        var _this = this;
        return this._financialServiceProxy.getMyNetWorth(moment(startDate), moment(endDate), this._authService.user.access_token, lastUpdatedDateList).pipe(finalize(function () { _this.fetchingRecords = false; }));
    };
    MyFinancialService.prototype.getMyTransaction = function (startDate, endDate, accountIds) {
        var _this = this;
        return this._financialServiceProxy.getMyRecentTransactions(moment(startDate), moment(endDate), this._authService.user.access_token, accountIds).pipe(finalize(function () { _this.fetchingRecords = false; }));
    };
    MyFinancialService.prototype.getMyTransactionFilter = function (startDate, endDate, accountId) {
        var _this = this;
        this._financialServiceProxy.getMyRecentTransactions(moment(startDate), moment(endDate), this._authService.user.access_token, accountId).pipe(finalize(function () { _this.fetchingRecords = false; }))
            .subscribe(function (res) {
            _this.transactionHistoryOuput = res;
        }, function (err) {
        });
    };
    MyFinancialService.prototype.getMyLatestUpdatedFinancialDataDate = function () {
        var _this = this;
        return this._financialServiceProxy.getMyLastUpdatedFinancialDataDate(this._authService.user.access_token).pipe(finalize(function () { _this.fetchingRecords = false; }), tap(function (val) { return _this.lastUpdatedDate = val; }));
    };
    MyFinancialService.prototype.queueFinancialDataSync = function () {
        var _this = this;
        return new Promise(function (resovle, reject) {
            _this._yodleeAppService.queueFinancialDataSync(_this._authService.user.access_token)
                .pipe(finalize(function () { _this.fetchingRecords = false; })).subscribe(function (res) {
                return resovle(res);
            }, function (err) {
                return reject(false);
            });
        });
    };
    MyFinancialService.prototype.queueTruIdFinancialDataSync = function (collectionId) {
        var _this = this;
        return new Promise(function (resovle, reject) {
            _this._yodleeAppService.queueTruIdFinancialDataSync(_this._authService.user.access_token, collectionId)
                .pipe(finalize(function () { _this.fetchingRecords = false; })).subscribe(function (res) {
                return resovle(res);
            }, function (err) {
                return reject(false);
            });
        });
    };
    MyFinancialService.prototype.getMyTransactionCategories = function (startDate, endDate) {
        var _this = this;
        return this._financialServiceProxy.getSpendingCategories(moment(startDate), moment(endDate), this._authService.user.access_token).pipe(finalize(function () { _this.fetchingRecords = false; }));
    };
    MyFinancialService.prototype.getMyAccountSummary = function (startDate, endDate, lastUpdatedDateList) {
        var _this = this;
        return this._financialServiceProxy.getMyAccountSummary(moment(startDate), moment(endDate), this._authService.user.access_token, lastUpdatedDateList).pipe(finalize(function () { _this.fetchingRecords = false; }));
    };
    MyFinancialService.prototype.getFilterDropDownForTransaction = function () {
        var _this = this;
        this._financialServiceProxy.getFilterForTransaction(this._authService.user.access_token).pipe(finalize(function () { _this.fetchingRecords = false; }))
            .subscribe(function (res) {
            _this.dropDownOutput = res;
        }, function (err) {
        });
    };
    MyFinancialService.prototype.deleteAccount = function (accountId) {
        var _this = this;
        return new Promise(function (resovle, reject) {
            _this._yodleeAppService.deleteAccount(_this._authService.user.access_token, accountId)
                .pipe(finalize(function () { _this.fetchingRecords = false; })).subscribe(function (res) {
                return resovle(res);
            }, function (err) {
                return reject(false);
            });
        });
    };
    MyFinancialService.prototype.syncAccounts = function (providerAccountId) {
        var _this = this;
        return new Promise(function (resovle, reject) {
            _this._yodleeAppService.getAllAccountYodlee(_this._authService.user.access_token, providerAccountId, '')
                .pipe(finalize(function () { _this.fetchingRecords = false; })).subscribe(function (res) {
                return resovle(res);
            }, function (err) {
                return reject(false);
            });
        });
    };
    MyFinancialService.prototype.syncYodleeData = function () {
        var _this = this;
        return new Promise(function (resovle, reject) {
            _this._yodleeAppService.getAllDataYodlee(_this._authService.user.access_token)
                .pipe(finalize(function () { _this.fetchingRecords = false; })).subscribe(function (res) {
                return resovle(res);
            }, function (err) {
                return reject(false);
            });
        });
    };
    MyFinancialService.ngInjectableDef = i0.defineInjectable({ factory: function MyFinancialService_Factory() { return new MyFinancialService(i0.inject(i1.MessageService), i0.inject(i2.MyFinancialServiceProxy), i0.inject(i2.YodleeServiceProxy), i0.inject(i3.CommonhelperService), i0.inject(i4.AuthService)); }, token: MyFinancialService, providedIn: "root" });
    return MyFinancialService;
}());
export { MyFinancialService };
