var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, NgZone, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MyWearablesService } from '@app/shared/services/my-wearables.service';
var WidgetLastSevenDaysComponent = /** @class */ (function (_super) {
    __extends(WidgetLastSevenDaysComponent, _super);
    function WidgetLastSevenDaysComponent(zone, injector, _wearableService) {
        var _this = _super.call(this, injector) || this;
        _this.zone = zone;
        _this._wearableService = _wearableService;
        return _this;
    }
    WidgetLastSevenDaysComponent.prototype.ngOnInit = function () {
        if (!this._wearableService.last7Days || !this._wearableService.last7Days.mstact) {
            this.isLoading = true;
        }
    };
    WidgetLastSevenDaysComponent.prototype.scrollToTop = function () {
        window.scrollTo(0, 0);
    };
    WidgetLastSevenDaysComponent.prototype.getLast7Async = function () {
        var _this = this;
        this._wearableService.getLast7Async().then(function () {
            _this.isLoading = false;
        });
    };
    return WidgetLastSevenDaysComponent;
}(AppComponentBase));
export { WidgetLastSevenDaysComponent };
