import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { AzureInsightsService } from './azure-insights.service';

@Injectable({
    providedIn: 'root'
})
export class MonitorServiceService extends ErrorHandler {

    constructor(private injector: Injector) {
        super();
    }


    handleError(error: any): void {
        //abp.ui.clearBusy();
        const monitoringService = this.injector.get(AzureInsightsService);
        monitoringService.logError(error);
        super.handleError(error);
    }
}
