<div class="m-portlet">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="col pt-3">
                <div class="m-portlet__head-title">
                    <h5 class="mt-3 m-portlet__head-text font-weight-bold black-clr">
                        {{"EPISODES" | localize}}
                    </h5>
                    <a class="mt-3" placement="right" ngbTooltip="{{'EPINFO'|localize}}"
                        tooltipClass="customHeightforinfo">
                        <i class="fa fa-info-circle clr-dark-maroon m--margin-bottom-20 m--margin-left-5">
                        </i>
                    </a>
                </div>
                <h6 class="m-portlet__head-desc font-weight-light m--regular-font-size-sm3">
                    {{"EPSUB" | localize}}
                </h6>
            </div>
        </div>
        <div class="my-epcare-drpdwn top28 d-flex flex-row align-items-center">
            <i class="fa fa-circle-notch fa-spin loader mr-2" *ngIf="isLoading"></i>
            <button type="button"
                class="btn z-index-2 btn-sm bg-dark-maroon m-btn--pill dropdown-toggle font-weight-bold text-white"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{currentEpisodeYear}}
            </button>
            <div class="max-height dropdown-lg z-index-1 dropdown-menu">
                <a [className]="yearActive[i] ? 'active dropdown-item' : 'dropdown-item'"
                    (click)="FilterWhithYearEpisode(item,i);"
                    *ngFor="let item of this._healtCareService.yearList;index as i"
                    class="active-drpdown dropdown-item">{{item}}</a>
            </div>
        </div>
    </div>
    <div class="m-portlet__body">
        <div *ngIf="showChart && showChart!=undefined" id="episodeofcarechart"
            class="font-weight-bold m--regular-font-size-sm5"></div>
        <div class="text-center font-weight-bold" *ngIf="!showChart && showChart!=undefined">
            No records
            found for year {{currentEpisodeYear}}
        </div>
    </div>
    <div class="m-portlet__foot m-portlet__no-border ">
        <ng-template #tooltipContent>

            <div class="row">
                <div class="col-10">
                    <div class="m--align-left m--margin-left-10">
                        {{"ChatToolTipDescription" | localize}}
                    </div>
                </div>
                <div class="col-2 right-px">
                    <i class="fa fa-times" (click)="h.toggle()"></i>
                </div>
            </div>
            <textarea rows="3" cols="40" [(ngModel)]="currentUserQuery" placeholder="Description here" type="text"
                name="disputing"
                class="m--margin-top-20  m--margin-10-tablet-and-mobile healthcare-chat-box-border"></textarea>
            <div class="m--align-left m--margin-left-10">
                <button (click)="sendsms('Episode Of Care')"
                    class="disputing-css btn m-btn--sm m-btn--pill bg-dark-maroon m--margin-top-10">
                    <small class="font-weight-bold text-white">
                        {{"Send" | localize}}
                    </small>
                </button>
            </div>
        </ng-template>
        <div class="m--align-right">
            <button type="button" (mouseout)="imgSrc = '../../../assets/common/images/chatblack.svg'"
                (mouseover)="imgSrc = '../../../assets/common/images/Elevate _ Dispute.png'"
                class="readmore btn m-btn--pill font-weight-bold bg-dark-maroon btn-circle " placement="left"
                #h="ngbTooltip" [ngbTooltip]="tooltipContent" triggers="click" [autoClose]="'outside'"
                tooltipClass="round-tooltip">
                <img class="width-px-20" [src]="imgSrc">
            </button>
        </div>
    </div>
</div>