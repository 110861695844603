/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i2 from "ngx-image-cropper";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "../../../../shared/common/pipes/localize.pipe";
import * as i7 from "ngx-bootstrap/modal";
import * as i8 from "ngx-bootstrap/component-loader";
import * as i9 from "./change-profile-picture-modal.component";
import * as i10 from "../../../../shared/service-proxies/service-proxies";
var styles_ChangeProfilePictureModalComponent = [];
var RenderType_ChangeProfilePictureModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChangeProfilePictureModalComponent, data: {} });
export { RenderType_ChangeProfilePictureModalComponent as RenderType_ChangeProfilePictureModalComponent };
function View_ChangeProfilePictureModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [["class", "customValidation"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["*Please select a JPG/JPEG/PNG file"]))], null, null); }
function View_ChangeProfilePictureModalComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "image-cropper", [["format", "png"]], [[4, "text-align", null]], [[null, "imageCroppedFile"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i0.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("imageCroppedFile" === en)) {
        var pd_5 = (_co.fileUpload($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i1.View_ImageCropperComponent_0, i1.RenderType_ImageCropperComponent)), i0.ɵdid(1, 573440, null, 0, i2.ImageCropperComponent, [i3.DomSanitizer, i0.ChangeDetectorRef, i0.NgZone], { imageChangedEvent: [0, "imageChangedEvent"], format: [1, "format"], maintainAspectRatio: [2, "maintainAspectRatio"], aspectRatio: [3, "aspectRatio"], resizeToWidth: [4, "resizeToWidth"] }, { imageCroppedFile: "imageCroppedFile" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imageChangedEvent; var currVal_2 = "png"; var currVal_3 = true; var currVal_4 = (4 / 4); var currVal_5 = 128; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).alignImage; _ck(_v, 0, 0, currVal_0); }); }
function View_ChangeProfilePictureModalComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["height", "100"], ["width", "100"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profilePicture; _ck(_v, 0, 0, currVal_0); }); }
function View_ChangeProfilePictureModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 31, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.save() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i4.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(2, 4210688, [["changeProfilePictureModalForm", 4]], 0, i4.NgForm, [[8, null], [8, null]], null, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i4.ControlContainer, null, [i4.NgForm]), i0.ɵdid(4, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["CHANGE PROFILE PICTURE"])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(12, 0, null, null, 11, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "input", [["accept", "image/*"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileChangeEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "span", [["class", "help-block m-b-none"]], null, null, null, null, null)), (_l()(), i0.ɵted(16, null, ["", ""])), i0.ɵppd(17, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChangeProfilePictureModalComponent_2)), i0.ɵdid(19, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChangeProfilePictureModalComponent_3)), i0.ɵdid(21, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChangeProfilePictureModalComponent_4)), i0.ɵdid(23, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(24, 0, null, null, 7, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["REMOVE"])), (_l()(), i0.ɵeld(27, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["CLOSE"])), (_l()(), i0.ɵeld(29, 0, null, null, 2, "button", [["class", "btn btn-danger"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["SAVE"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.inValidExt; _ck(_v, 19, 0, currVal_9); var currVal_10 = _co.imageChangedEvent; _ck(_v, 21, 0, currVal_10); var currVal_11 = (_co.profilePicture && !_co.imageChangedEvent); _ck(_v, 23, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.l("Close"); _ck(_v, 9, 0, currVal_7); var currVal_8 = i0.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i0.ɵnov(_v.parent, 0), "ProfilePicture_Change_Info", _co.maxProfilPictureBytesUserFriendlyValue)); _ck(_v, 16, 0, currVal_8); var currVal_12 = (!_co.imageChangedEvent && !_co.profilePicture); _ck(_v, 25, 0, currVal_12); var currVal_13 = _co.saving; _ck(_v, 27, 0, currVal_13); var currVal_14 = ((!i0.ɵnov(_v, 2).form.valid || _co.saving) || !_co.imageChangedEvent); _ck(_v, 29, 0, currVal_14); }); }
export function View_ChangeProfilePictureModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i6.LocalizePipe, [i0.Injector]), i0.ɵqud(402653184, 1, { modal: 0 }), (_l()(), i0.ɵeld(2, 16777216, null, null, 6, "div", [["aria-hidden", "true"], ["aria-labelledby", "myLargeModalLabel"], ["bsModal", ""], ["class", "modal fade"], ["role", "dialog"], ["tabindex", "-1"]], null, [[null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 212992, [[1, 4], ["changeProfilePictureModal", 4]], 0, i7.ModalDirective, [i0.ElementRef, i0.ViewContainerRef, i0.Renderer2, i8.ComponentLoaderFactory], { config: [0, "config"] }, null), i0.ɵpod(4, { backdrop: 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "modal-dialog modal-lg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChangeProfilePictureModalComponent_1)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, "static"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.active; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_ChangeProfilePictureModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "changeProfilePictureModal", [], null, null, null, View_ChangeProfilePictureModalComponent_0, RenderType_ChangeProfilePictureModalComponent)), i0.ɵdid(1, 49152, null, 0, i9.ChangeProfilePictureModalComponent, [i0.Injector, i10.UserServiceProxy, i10.ClaimModifyServiceProxy], null, null)], null, null); }
var ChangeProfilePictureModalComponentNgFactory = i0.ɵccf("changeProfilePictureModal", i9.ChangeProfilePictureModalComponent, View_ChangeProfilePictureModalComponent_Host_0, {}, {}, []);
export { ChangeProfilePictureModalComponentNgFactory as ChangeProfilePictureModalComponentNgFactory };
