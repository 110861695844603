var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, AfterViewInit, Injector } from '@angular/core';
import { XYChart } from '@amcharts/amcharts4/charts';
import { HealthCareApiServiceProxy, HospitalEventOutput, SmsBody } from '@shared/service-proxies/service-proxies';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { MyHealthCareModalComponent } from './my-health-care-modal/my-health-care-modal.component';
import { MonthPipeService } from '@shared/service-proxies/month-pipe.service';
am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EpisodeCareComponent } from '@app/shared/common/widgets/my-healthcare-widgets/episode-care/episode-care.component';
import { MyHealthcareService } from '@app/shared/services/my-healthcare.service';
import { ChangeDetectorRef } from '@angular/core';
import { HospitalEventsComponent } from '@app/shared/common/widgets/my-healthcare-widgets/hospital-events/hospital-events.component';
import { Router } from '@angular/router';
var MyHealthcareComponent = /** @class */ (function (_super) {
    __extends(MyHealthcareComponent, _super);
    function MyHealthcareComponent(injector, _healthCare, _changeDetecter, _monthPipe, _myHealthCareService, _router) {
        var _this = _super.call(this, injector) || this;
        _this._healthCare = _healthCare;
        _this._changeDetecter = _changeDetecter;
        _this._monthPipe = _monthPipe;
        _this._myHealthCareService = _myHealthCareService;
        _this._router = _router;
        _this.itemSelectCount = 0;
        _this.numberOfCallsSelect = 0;
        _this.requestAccessToHECharts = undefined;
        _this.showChart = undefined;
        _this.healthRecordDate = new Array();
        _this.hospEventSelectedYear = '';
        _this.imgSrc = '../../../assets/common/images/chatblack.svg';
        _this.episodeCareData = new Array();
        _this.chartCareDataCopyTemp = new Array();
        _this.isCollapsedpharmacy2 = true;
        _this.isCollapsedpharmacy1 = true;
        _this.isCollapsedRadiology = true;
        _this.isCollapsedSergery = true;
        _this.model = 1;
        _this.yearList = new Array();
        _this.hospEventData = new HospitalEventOutput();
        _this.disputingText = '';
        _this.ifActivated = false;
        _this.yearFilterList = [];
        _this.yearState = [];
        _this.monthState = [];
        //descriptionState = [];
        _this.selectedMonth = [];
        _this.selectedMonthNumber = [];
        //selectAllDescription: boolean;
        //descriptionToggle: boolean;
        //showAllDescription: boolean;
        _this.monthFilterList = [
            { name: 'Jan', selected: false },
            { name: 'Feb', selected: false },
            { name: 'Mar', selected: false },
            { name: 'Apr', selected: false },
            { name: 'May', selected: false },
            { name: 'Jun', selected: false },
            { name: 'Jul', selected: false },
            { name: 'Aug', selected: false },
            { name: 'Sep', selected: false },
            { name: 'Oct', selected: false },
            { name: 'Nov', selected: false },
            { name: 'Dec', selected: false },
        ];
        _this.fields = { text: 'name', value: 'id' };
        _this.descriptionPlaceholder = 'Filter by description';
        _this.selectedDescription = new Array();
        _this.popHeight = '200px';
        _this.yearActive = new Array();
        _this.removingCount = 0;
        _this.numberOfCalls = 0;
        _this.enableGroupCheckBox = false;
        _this.allowFiltering = true;
        _this.filterBarPlaceholder = 'Search Description';
        _this.yearListHE = new Array();
        _this.yearActiveHE = new Array();
        _this.seemoreActivated = false;
        _this.arrayforHE = new Array();
        _this.yearOfHr = new Array();
        _this.yearActiveHr = new Array();
        return _this;
    }
    MyHealthcareComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mode = 'CheckBox';
        this.selectAllText = 'Select All';
        this.yearToggle = true;
        this.yearState[0] = true;
        this.selectedMonth = new Array();
        this.currentYear = new Date().getFullYear();
        this.selectedYear = this.currentYear;
        for (var initialYear = this.currentYear; initialYear >= 2013; initialYear--) {
            this.yearFilterList.push(initialYear);
        }
        abp.ui.setBusy();
        this.updateClaimsIdsvr()
            .then(function (res) {
            _this._myHealthCareService.getEpisodeOfCare().then(function (res) {
                _this.episodeOfCareref.loadEpisodeOFCare();
                abp.ui.clearBusy();
            });
        })
            .catch(function (err) {
            _this.episodeOfCareref.loadEpisodeOFCare();
            abp.ui.clearBusy();
        });
    };
    MyHealthcareComponent.prototype.goToHealthRiskAssessment = function () {
        this._router.navigateByUrl('/app/main/MyHealthCare/RiskAssessment');
    };
    MyHealthcareComponent.prototype.getDescriptionDropdown = function () {
        var _this = this;
        this._healthCare.getDescriptionDropdownValue(this._authGuard.user.access_token)
            .subscribe(function (res) {
            _this.descriptionFilterList = new Array();
            _this.sortedFilterList = new Array();
            if (res != null) {
                res.forEach(function (value, index) {
                    if (value.toLowerCase() === 'pharmacy' || value.toLowerCase() === 'radiologist' || value.toLowerCase() === 'general practitioner' || value.toLowerCase() === 'optometrist') {
                        _this.sortedFilterList.push({
                            id: index.toString(),
                            name: value
                        });
                    }
                });
                _this.descriptionFilterList = _this.sortedFilterList;
            }
            // Array.isArray(res) && res.length ? this.showAllDescription = true : this.showAllDescription = false;
        });
    };
    MyHealthcareComponent.prototype.showHealthCareChart = function (item) {
        this._changeDetecter.detectChanges();
        this.healthcareactivated = true;
        this.episodeOfCareref.loadEpisodeOFCare();
    };
    MyHealthcareComponent.prototype.ngAfterViewInit = function () {
    };
    MyHealthcareComponent.prototype.getIfActivated = function () {
        var _this = this;
        this._healthCare.getDataActivatedStatus(this.getAccessToken()).subscribe(function (res) {
            _this.ifActivated = res;
        });
    };
    MyHealthcareComponent.prototype.getAccessToken = function () {
        return this._authService.user.access_token;
    };
    MyHealthcareComponent.prototype.getEntity = function () {
        return this._authService.user.access_token;
    };
    MyHealthcareComponent.prototype.FilterWhithYearHospEvent = function (item, i) {
        // let d = this.yearActiveHE.findIndex(x => x === true);
        // this.yearActiveHE[d] = false;
        // this.yearActiveHE[i] = true;
        // this.hospEventSelectedYear = item;
        // this.loadHospitalEvents(item);
        this.hospitalEventsref.FilterWhithYearHospEvent(item, i);
    };
    MyHealthcareComponent.prototype.getHospEventYear = function () {
        this._changeDetecter.detectChanges();
        this.hospitalEventsref.loadHospitalEvent();
    };
    MyHealthcareComponent.prototype.gettHealthRecordYear = function () {
        var _this = this;
        this.getDescriptionDropdown();
        if (this.yearOfHr.length > 0) {
            this.loadHealthRecord(this.yearOfHr[0], null, null);
        }
        else {
            this._healthCare.getHealthRecordDataYear(this.getEntity()).subscribe(function (res) {
                _this.yearOfHr = res;
                var i = 0;
                _this.yearOfHr.forEach(function (x) {
                    if (i === 0) {
                        _this.yearActiveHr.push(true);
                    }
                    else {
                        _this.yearActiveHr.push(false);
                    }
                    i++;
                });
                _this.loadHealthRecord(res[0], null, null);
            }, function (err) {
                abp.ui.clearBusy();
            });
        }
    };
    MyHealthcareComponent.prototype.FilterHRYear = function (item, i) {
        var d = this.yearActiveHr.findIndex(function (x) { return x === true; });
        this.yearActiveHr[d] = false;
        this.yearActiveHr[i] = true;
        this.loadHealthRecord(item, null, null);
    };
    MyHealthcareComponent.prototype.loadHealthRecord = function (year, month, description) {
        var _this = this;
        this.NoHealthcare = false;
        this.isLoading = true;
        this._healthCare.getHealthRecordData(month, description, year, this._authService.user.access_token).subscribe(function (res) {
            if (res === null || res.length <= 0) {
                _this.NoHealthcare = true;
            }
            else {
                _this.NoHealthcare = false;
                res.forEach(function (x) {
                    _this.arrayforHE.push(false);
                });
            }
            _this.healthRecordDate = res;
        }, function (err) {
        }, function () {
            _this.isLoading = false;
        });
    };
    MyHealthcareComponent.prototype.yearDropdownSelect = function (year, Index) {
        var _this = this;
        this.selectedYear = year;
        this.yearToggle = true;
        this.yearFilterList.forEach(function (y, Index) {
            _this.yearState[Index] = false;
        });
        this.yearState[Index] = !this.yearState[Index];
        this.loadHealthRecord(this.selectedYear.toString(), this.selectedMonthNumber, this.selectedDescription);
    };
    MyHealthcareComponent.prototype.monthDropdownSelect = function (e, Index, month) {
        if (e.target.checked) {
            this.monthToggle = true;
            this.monthState[Index] = !this.monthState[Index];
            this.selectedMonth.push(month.name);
        }
        else {
            this.monthState[Index] = !this.monthState[Index];
            this.selectAll = false;
            // tslint:disable-next-line: no-unused-expression
            this.monthFilterList.every(function (m) { return m.selected === false; }) ? this.monthToggle = false : null;
            var index = this.selectedMonth.indexOf(month.name, 0);
            if (index > -1) {
                this.selectedMonth.splice(index, 1);
            }
        }
        this.selectedMonthNumber = this._monthPipe.transform(this.selectedMonth);
        this.loadHealthRecord(this.selectedYear.toString(), this.selectedMonthNumber, this.selectedDescription);
    };
    MyHealthcareComponent.prototype.allMonth = function () {
        var _this = this;
        if (this.monthFilterList.every(function (val) { return val.selected === true; })) {
            this.monthToggle = false;
            this.selectAll = false;
            this.monthFilterList.forEach(function (val, index) {
                val.selected = false;
                _this.selectedMonth = [];
                if (!val.selected) {
                    _this.monthState[index] = false;
                }
            });
        }
        else {
            this.monthToggle = true;
            this.selectAll = true;
            this.selectedMonth = [];
            this.monthFilterList.forEach(function (val, index) {
                val.selected = true;
                _this.selectedMonth.push(val.name);
                if (val.selected) {
                    _this.monthState[index] = true;
                }
            });
        }
        this.selectedMonthNumber = this._monthPipe.transform(this.selectedMonth);
        this.loadHealthRecord(this.selectedYear.toString(), this.selectedMonthNumber, this.selectedDescription);
    };
    MyHealthcareComponent.prototype.onItemSelected = function (item) {
        this.itemSelectCount += 1;
        this.selectedDescription.push(item.itemData.name);
        this.numberOfCallsSelect = this.numberOfCallsSelect + 1;
        if (this.itemSelectCount === this.numberOfCallsSelect) {
            this.loadHealthRecord(this.selectedYear.toString(), this.selectedMonthNumber, this.selectedDescription);
            this.itemSelectCount = 0;
            this.numberOfCallsSelect = 0;
        }
    };
    MyHealthcareComponent.prototype.onItemRemoved = function (item) {
        var _this = this;
        this.removingCount += 1;
        this.selectedDescription.splice(this.selectedDescription.findIndex(function (x) { return x === item.itemData.name; }), 1);
        setTimeout(function () {
            _this.numberOfCalls = _this.numberOfCalls + 1;
            if (_this.removingCount === _this.numberOfCalls) {
                _this.loadHealthRecord(_this.selectedYear.toString(), _this.selectedMonthNumber, _this.selectedDescription);
                _this.removingCount = 0;
                _this.numberOfCalls = 0;
            }
        }, 150);
    };
    MyHealthcareComponent.prototype.clearFilter = function () {
        var _this = this;
        this.dropDownListObject.value = null;
        this.yearFilterList.forEach(function (y, Index) {
            _this.yearState[Index] = false;
        });
        this.yearToggle = true;
        this.yearState[0] = true;
        this.selectedYear = this.yearFilterList[0];
        this.monthToggle = false;
        this.selectAll = false;
        this.monthFilterList.forEach(function (val, index) {
            val.selected = false;
            if (!val.selected) {
                _this.monthState[index] = false;
            }
        });
        this.selectedMonthNumber = new Array();
        this.selectedMonth = new Array();
        this.selectedDescription = new Array();
        this.loadHealthRecord(this.selectedYear.toString(), null, null);
    };
    MyHealthcareComponent.prototype.sendsms = function (PageName) {
        var body = new SmsBody();
        body.userid = this._authGuard.user.profile.sub;
        body.firstName = this._authGuard.user.profile.given_name;
        body.lastName = this._authGuard.user.profile.family_name;
        body.email = this._authGuard.user.profile.email;
        body.phoneNumber = Array.isArray(this._authGuard.user.profile.phone_number) ? this._authGuard.user.profile.phone_number[0] : this._authGuard.user.profile.phone_number;
        body.pageName = PageName;
        body.message = this.currentUserQuery;
        this.sendQueryEmail(body);
        this.currentUserQuery = '';
    };
    MyHealthcareComponent.prototype.determineWindowsWidth = function () {
        if (window.innerWidth < 700) {
            return false;
        }
        else {
            return true;
        }
    };
    MyHealthcareComponent.prototype.selectedYearFromNav = function (item) {
        console.log('Healthcare year hitting: ' + item);
        this.selectedYear = item;
        this.loadHealthRecord(this.selectedYear.toString(), this.selectedMonthNumber, this.selectedDescription);
    };
    MyHealthcareComponent.prototype.selectedMonthFromNav = function (item) {
        console.log('Healthcare MONTH hitting: ' + item);
    };
    return MyHealthcareComponent;
}(AppComponentBase));
export { MyHealthcareComponent };
