﻿// "PreDev" enabled environment

export const environment = {
    production: false,
    hmr: false,
    predev: true,
    appConfig: 'appconfig.predevelopment.docker.b.json',
    appInsights: {
        instrumentationKey: '7a266488-2276-4746-8d74-0fb91cc52e5f'
    }
};
