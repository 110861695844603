﻿import { Injectable, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PolicyService } from '@app/shared/services/policy.service';
import { PoliciesModule, PersonModule } from '@shared/models/interfaces/elevate-data-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable()
export class MyDocumentsService extends AppComponentBase {


    private _policyService: PolicyService;
    public get policyService(): PolicyService {
        if (!this._policyService) {
            this._policyService = this.injector.get(PolicyService);
        }
        return this._policyService;
    }

    public vm = {
        currentPolicyId: '',
        entityId: ''
    };

    constructor(
        private injector: Injector,
    ) {
        super(injector);
        if (this._authGuard.user) {
            this.vm.entityId = this._authGuard.user.profile.sub;
        }
    }

    getPolicyList(policyList: PersonModule.IPolicyAPI[]): PoliciesModule.IPolicyModel[] {
        return this.policyService.getPolicySets(policyList);
    }

    getAPerson(personId: string, isCaching?: boolean): Observable<PersonModule.IPersonModel> {
        return this.policyService.getPerson(personId, isCaching)
            .pipe(map(res => {
                return res;
            }));
    }

    getPolicyById(policyId: string): Observable<PoliciesModule.IPolicyModel> {
        return this.policyService.getPolicy(policyId)
            .pipe(map(res => {
                return res;
            }));
    }

}
