<div class="m-subheader mt-3 ">

    <div class="row align-items-center">

        <div class="mr-auto ml-3">

            <h3 class="m-subheader__title pb-0 font-weight-light m--icon-font-size-sm1">

                <a class="brd-clr" [routerLink]="['/app/main/dashboard']">
                    MY DATA
                </a>>

                <a class="brd-clr" [routerLink]="['/app/main/MyHealthCare']">
                    MY HEALTHCARE
                </a>>

                <a class="font-weight-bold brd-clr">
                    HEALTH RISK ASSESSMENT
                </a>
            </h3>
        </div>
    </div>
</div>

<div class="m-content">

    <div class="row">

        <div class="col-lg-6 col-md-6 order-xl-1 order-lg-1 col-sm-12">

            <div class="m-portlet m-portlet--full-height bg-grey sales-summary-chart">

                <div class="m-portlet__head">

                    <div class="m-portlet__head-caption">

                        <div class="m-portlet__head-title">

                            <h3 class="m-portlet__head-text">

                                <small class="font-weight-bold text-white">
                                    See your personlised set of wellness goals for {{currentDate | date : 'MMMM'}}
                                </small>
                            </h3>
                        </div>
                    </div>

                    <div class="m-portlet__head-tools">

                        <button class="topBtn btn bg-white m-btn--pill btn-block" [routerLink]="['/app/main/MyGoals']">

                            <small class="font-weight-bold m--margin-left-10 m--margin-right-10">
                                My Goals
                            </small>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 order-xl-1 order-lg-1 col-sm-12">

            <div class="m-portlet m-portlet--full-height green-bg-clr sales-summary-chart">

                <div class="m-portlet__head">

                    <div class="m-portlet__head-caption">

                        <div class="m-portlet__head-title">

                            <h3 class="m-portlet__head-text">

                                <small class="font-weight-bold text-white">
                                    You are earning rewards each month!
                                </small>
                            </h3>
                        </div>
                    </div>

                    <div class="m-portlet__head-tools">

                        <button class="topBtn bg-white btn m-btn--pill btn-block"
                            [routerLink]="['/app/main/MyRewards/ElevationFund']">

                            <small class="font-weight-bold m--margin-left-10 m--margin-right-10">
                                See how much
                            </small>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-lg-6 col-md-12 order-xl-1 order-lg-1 col-sm-12">

            <div class="m-portlet pb-4">

                <div class="m-portlet__head">

                    <div class="m-portlet__head-caption">

                        <div class="m-portlet__head-title">

                            <h3 class="m-portlet__head-text font-weight-bold c-dark-maroon">

                                HEALTH RISK ASSESSMENT
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="m-portlet__body pb-0 pt-0">

                    <p>
                        Understanding your state of health and wellness is more than half the work needed to stay on the
                        journey to being the most well you can be. The questionnaires below help us elicit the
                        important
                        information needed for us to empower you with the knowledge and resources to manage and
                        improve
                        your wellbeing.
                    </p>

                    <div class="d-flex flex-row justify-content-end align-items-center mt-2" *ngIf="!descriptionShown">

                        <p class="mr-2 mb-0 font-weight-bold">
                            See more
                        </p>

                        <i class="la collapsed point-cur la-plus-circle font-weight-bold"
                            (click)="descriptionShown = true"></i>
                    </div>

                    <div *ngIf="descriptionShown">

                        <p class="mt-4">
                            The more attention and honesty you give to answering the questions, the more personalised
                            and
                            applicable the knowledge we can provide - as well as allow us to make your monthly wellness
                            goals
                            even more personalised and tailored to achieve the optimum wellness benefits.
                        </p>

                        <p class="mt-4">
                            And with Elevate Rewards you benefit even further! By completing all of these questionnaires
                            twice
                            per year you will earn more cashbacks each month on your Elevate Life policies.
                        </p>

                        <p class="mt-4">
                            Visit the My Goals tab - under the My Healthcare goals section - for more information.
                        </p>

                        <div class="d-flex flex-row justify-content-end align-items-center mt-2">

                            <p class="mr-2 mb-0 font-weight-bold">
                                See less
                            </p>

                            <i class="la collapsed point-cur la-times-circle font-weight-bold"
                                (click)="descriptionShown = false"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion accordion-solid accordion-toggle-plus pb-4" id="accordionExample6">

                <div class="card acd mb-1"
                    *ngFor="let category of categories | fillterBy : 'isShown' : 'true'; let i = index">

                    <div class="card-header bg-white" id="headingOne6">

                        <div class="d-flex flex-row justify-content-between align-items-center">

                            <div class="d-flex flex-column">

                                <h6 id="h51" class="mb-0 c-dark-maroon font-weight-bold">{{category.title |
                                    uppercase}}
                                </h6>

                                <small id="small1" class="font-weight-bold black">
                                    {{category.description}}
                                </small>
                            </div>

                            <button *ngIf="category.status === null" type="button"
                                [routerLink]="'/app/main/MyHealthCare/RiskAssessment/' + category.key"
                                class="btn bg-dark-maroon position-relative black m-btn--pill font-weight-bold text-white">

                                <small class="font-weight-bold">
                                    Get Started
                                </small>
                            </button>

                            <button *ngIf="category.status === 'Saved'" type="button"
                                [routerLink]="'/app/main/MyHealthCare/RiskAssessment/' + category.key"
                                class="btn bg-dark-maroon position-relative black m-btn--pill font-weight-bold text-white">

                                <small class="font-weight-bold">
                                    Resume
                                </small>
                            </button>

                            <div class="d-flex flex-row justify-content-end align-items-center"
                                *ngIf="category.status === 'Completed'">

                                <small style="font-weight: 500" class="mr-1">
                                    View report
                                </small>

                                <i class="la collapsed point-cur" (click)="accordianOpen(i, category)"
                                    [ngClass]="{'la-plus-circle': !getSelectedAccordian(i), 'la-times-circle': getSelectedAccordian(i)}"></i>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="getSelectedAccordian(i)" class="card-body bg-dark-maroon">

                        <div *ngIf="!isLoadingReport; else loadingReport">

                            <div *ngIf="category.report; else noReport"
                                class="d-flex flex-column justify-content-center align-items-start">                               

                                <div *ngFor="let description of category.report.descriptions" class="mb-2"
                                    [@bouncein]="'idle-bounceIn'">

                                    <div class="font-weight-bold text-white mb-2" *ngIf="description.title">
                                        {{description.title}}
                                    </div>

                                    <ul *ngIf="description.list.length > 0" class="mb-2">

                                        <li class="small text-white" *ngFor="let list of description.list">
                                            {{list}}
                                        </li>
                                    </ul>

                                    <div class="small text-white mb-2" *ngFor="let content of description.content">
                                        {{content}}
                                    </div>
                                </div>

                                <div *ngIf="category.report.positiveOutcomes.length > 0" class="mb-2"
                                    [@bouncein]="'idle-bounceIn'">

                                    <div class="text-white font-weight-bold mb-2">
                                        Your answers suggest you are succeeding at:
                                    </div>

                                    <ul class="mb-2">

                                        <li class="text-white mb-1 small"
                                            *ngFor="let outcome of category.report.positiveOutcomes">
                                            {{outcome}}
                                        </li>
                                    </ul>
                                </div>

                                <div *ngIf="category.report.negativeOutcomes.length > 0" class="mb-2"
                                    [@bouncein]="'idle-bounceIn'">

                                    <div class="text-white font-weight-bold mb-2">
                                        Some advice on areas to improve:
                                    </div>

                                    <ul>

                                        <li class="text-white small"
                                            *ngFor="let outcome of category.report.negativeOutcomes">
                                            {{outcome}}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <ng-template #noReport>

                                <div [@bouncein]="'idle-bounceIn'"
                                    class="card-body bg-dark-maroon d-flex flex-column justify-content-center align-items-start">

                                    <div class="font-weight-bold text-white mb-2 mt-2 small">
                                        Nothing to see here yet...
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                        <ng-template #loadingReport>

                            <div [@bouncein]="'idle-bounceIn'"
                                class="d-flex flex-row justify-content-center align-items-center p-2">

                                <i class="fa fa-lg fa-circle-notch fa-2x fa-spin text-white"></i>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 order-xl-1 order-lg-1 col-sm-12">


            <div class="row">

                <div class="col-md-6 col-sm-12 mb-2p2rem">

                    <div class="card acd h-100 p-4">

                        <div class="fs-1p3rem c-teal font-weight-bold mb-0">OVERALL SCORE</div>

                        <div class="small">All completed questionnaires considered</div>

                        <div class="d-flex flex-row justify-content-center align-items-end mt-3 mb-4">

                            <div class="c-teal h1 mb-0 font-weight-bold fs-45px">{{totals.achieved}}</div>

                            <div class="font-weight-bold mb-1 ml-1">/100</div>
                        </div>

                        <div class="fs-1p3rem c-dark-blue font-weight-bold mb-0"><i>PEOPLE-LIKE-YOU</i> SCORE</div>

                        <div class="small">Taking into account your personal factors</div>

                        <div class="d-flex flex-row justify-content-center align-items-end mt-3">

                            <div class="c-dark-blue h1 mb-0 font-weight-bold fs-45px">{{totals.average}}</div>

                            <div class="font-weight-bold mb-1 ml-1">/100</div>
                        </div>
                    </div>

                </div>

                <div class="col-md-6 col-sm-12 mb-2p2rem"
                    *ngFor="let category of categories | fillterBy : 'isShown' : 'true'; let i = index">

                    <div class="card pt-2 pl-2 pr-2">
                        <div [id]="category.chart" style="height: 280px;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>