<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body border-0 text-center">
    <img *ngIf="icon" style="width:147px;" [src]="icon" />
    <h5 *ngIf="message" class="mt-4 {{textAlignLeft ? 'text-left' : ''}}">{{message}}</h5>
    <div *ngIf="messages && messages.length > 0">
        <h5 class="mt-4 {{textAlignLeft ? 'text-left' : ''}}" *ngFor="let item of messages">{{item}}</h5>
    </div>


    <div *ngIf="withToggle" class="d-flex justify-content-center pt-4">
        <span class=" m-switch permission-switch m-switch--sm">
            <label>
                <input required [(ngModel)]="haveYouToldUsEverything" type="checkbox" id="confirmationFlag"
                       name="confirmation">
                <span class="slider"></span>
            </label>
        </span>
        <span class="font-size-12 pt-1">OK</span>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="close()">{{closeBtnName}}</button>
    <button [disabled]="!haveYouToldUsEverything && withToggle" *ngIf="actionButtonName" type="button" class="btn btn-danger"
            (click)="submit()">
        {{actionButtonName}}
    </button>
</div>