var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, Injector, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HealthCareApiServiceProxy, CreateHealthCareUserInputDto, ClaimModifyServiceProxy, ModifyClaimDto, SmsBody, UserClaimEditDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective, document } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { AppConsts } from '@shared/AppConsts';
import { MyCommandCenterService } from '@app/main/my-command-centre/my-command-center.service';
import { FacebookService } from '@shared/facebook/facebook.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
var MyHealthCareModalComponent = /** @class */ (function (_super) {
    __extends(MyHealthCareModalComponent, _super);
    function MyHealthCareModalComponent(datepipe, injector, _claimModify, _healthCare, _commandCenterService, _facebookService, router) {
        var _this = _super.call(this, injector) || this;
        _this.datepipe = datepipe;
        _this._claimModify = _claimModify;
        _this._healthCare = _healthCare;
        _this._commandCenterService = _commandCenterService;
        _this._facebookService = _facebookService;
        _this.router = router;
        _this.healthcareChanged = new EventEmitter();
        _this.episodeCareData = new Array();
        _this.active = false;
        _this.saving = false;
        _this.MemberShipNo = '';
        _this.checkedTf = false;
        _this.modalSave = new EventEmitter();
        _this.medicalSchemes = [];
        return _this;
    }
    MyHealthCareModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._commandCenterService.getMedicalSchemes(this._authService.user.access_token).subscribe(function (result) {
            _this.medicalSchemes = result;
            _this.setMedicalScheme();
        });
    };
    MyHealthCareModalComponent.prototype.setMedicalScheme = function () {
        var _this = this;
        var currentMedicalScheme = this.medicalSchemes.find(function (m) { return m.medicalSchemeName === _this._authGuard.user.profile.medical_scheme_name; });
        if (currentMedicalScheme) {
            this.schemename = currentMedicalScheme.medicalSchemeName;
            this.MemberShipNo = this._authGuard.user.profile.medical_scheme_membership_number !== '0000000000000' ? this._authGuard.user.profile.medical_scheme_membership_number : '';
        }
    };
    MyHealthCareModalComponent.prototype.updateIdNumberClaim = function () {
        return __awaiter(this, void 0, void 0, function () {
            var mdto;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        mdto = new ModifyClaimDto();
                        mdto.accessToken = this._authService.user.access_token;
                        mdto.newClaimType = 'id_number';
                        mdto.newClaimValue = this.idNumber.toString();
                        mdto.userId = this._authGuard.user.profile.sub;
                        return [4 /*yield*/, this._claimModify.modifyClaim(mdto).toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.updateClaimsIdsvr()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MyHealthCareModalComponent.prototype.show = function () {
        this.modal.show();
        this.active = true;
        this.checkedTf = false;
        if (this.medicalSchemes && this.medicalSchemes.length > 0) {
            this.setMedicalScheme();
        }
    };
    MyHealthCareModalComponent.prototype.changeTemplatePopup = function (item, item1) {
        if (item !== undefined) {
            document.getElementsByClassName('swal2-confirm')[0].innerText = item;
            document.getElementsByClassName('swal2-confirm')[0].classList.add('modal-button-clr');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('font-size-12');
        }
        if (item1 !== undefined) {
            document.getElementsByClassName('swal2-cancel')[0].innerText = item1;
            document.getElementsByClassName('swal2-cancel')[0].classList.add('modal-button-clr');
            document.getElementsByClassName('swal2-cancel')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-cancel')[0].classList.add('font-size-12');
        }
    };
    MyHealthCareModalComponent.prototype.getHealthCareUserInputDto = function () {
        var dtoHe = new CreateHealthCareUserInputDto();
        var date = moment(this._authGuard.user.profile.birthdate, 'DD/MM/YYYY hh:mm:ss A');
        dtoHe.doBserialized = this.datepipe.transform(date, 'yyyy-MM-dd');
        dtoHe.gender = this._authGuard.user.profile.gender.toLowerCase() === 'Male'.toLowerCase() ? 'M' : 'F';
        dtoHe.schemeName = this.schemename;
        dtoHe.membershipNumber = this.MemberShipNo;
        dtoHe.firstName = this._authGuard.user.profile.given_name;
        return dtoHe;
    };
    MyHealthCareModalComponent.prototype.getInputForHealthCareClaimChange = function (newClaimValue) {
        var mdto = new ModifyClaimDto();
        mdto.accessToken = this._authService.user.access_token;
        mdto.newClaimType = AppConsts.allowhealthcare;
        mdto.newClaimValue = newClaimValue;
        mdto.userId = this._authGuard.user.profile.sub;
        return mdto;
    };
    MyHealthCareModalComponent.prototype.updateMedicalSchemeClaims = function () {
        var _this = this;
        if (this.schemename !== this._authGuard.user.profile.medical_scheme_name) {
            if (this._authGuard.user.profile.medical_scheme_name === AppConsts.SupportedMedicalAids.Medshield || this._authGuard.user.profile.medical_scheme_name === AppConsts.SupportedMedicalAids.Bestmed) {
                this._claimModify.deleteUserRole(this._authService.user.access_token, 'FreeUser1').subscribe(function () {
                    _this._authService.getUserRoleViaAjax(function () { });
                });
            }
            else if (this.schemename === AppConsts.SupportedMedicalAids.Medshield || this.schemename === AppConsts.SupportedMedicalAids.Bestmed) {
                this._claimModify.addUserRole(this._authService.user.access_token, 'FreeUser1').subscribe(function () {
                    _this._authService.getUserRoleViaAjax(function () { });
                });
            }
            var claimList = new ModifyClaimDto();
            claimList.userClaimEditDtos = new Array();
            claimList.userClaimEditDtos.push(new UserClaimEditDto({
                claimType: 'medical_scheme_name',
                claimValue: this.schemename
            }));
            claimList.userClaimEditDtos.push(new UserClaimEditDto({
                claimType: 'medical_scheme_membership_number',
                claimValue: this.MemberShipNo
            }));
            claimList.accessToken = this._authService.user.access_token;
            claimList.userId = this._authGuard.user.profile.sub;
            this._claimModify.modifyClaim(claimList).subscribe(function () {
                _this.updateClaimsIdsvr();
            }, function (error) {
                abp.ui.clearBusy();
                console.log(error);
            });
        }
    };
    MyHealthCareModalComponent.prototype.showFailedRegisterMessage = function () {
        var currentCompThis = this;
        abp.message.confirm(AppConsts.HelthCareRetryMessage, 'Oops! HealthCare activation failed!', function (isConfirmed) {
            abp.ui.setBusy();
            currentCompThis._claimModify.modifyClaim(currentCompThis.getInputForHealthCareClaimChange('false')).subscribe(function (res) {
                abp.ui.clearBusy();
                currentCompThis.updateClaimsIdsvr();
            }, function (err) {
                abp.ui.clearBusy();
            });
            if (isConfirmed) {
                currentCompThis.MemberShipNo = '';
                return;
            }
            else {
                currentCompThis.close();
                currentCompThis.sendsms();
                currentCompThis.MemberShipNo = '';
            }
        }, true);
        this.changeTemplatePopup(AppConsts.tryAgain, AppConsts.contactSupport);
        abp.ui.clearBusy();
    };
    MyHealthCareModalComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        abp.ui.setBusy();
                        this.updateMedicalSchemeClaims();
                        this.saving = true;
                        if (!(this._authGuard.user.profile.id_number !== this.idNumber.toString())) return [3 /*break*/, 3];
                        if (!(this._authGuard.user.profile.id_number === '0000000000000')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updateIdNumberClaim()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        abp.ui.clearBusy();
                        abp.message.warn('Your ID number does not match the one we have on record', 'Wearables activation failed', false);
                        this._commandCenterService.changeTemplatePopup('OK', undefined);
                        this.close();
                        return [2 /*return*/];
                    case 3:
                        this._claimModify.modifyClaim(this.getInputForHealthCareClaimChange('true')).subscribe(function (res) {
                            _this.updateClaimsIdsvr().then(function () {
                                if (_this.schemename !== AppConsts.SupportedMedicalAids.Medshield && _this.schemename !== AppConsts.SupportedMedicalAids.Bestmed) {
                                    var message = 'A user has tried to activate healthcare data for an unsupported medical scheme\n\n' +
                                        'Name: ' + _this._authService.user.profile.name + '\n' +
                                        'Surname: ' + _this._authService.user.profile.surname + '\n' +
                                        'Email: ' + _this._authService.user.profile.email + '\n' +
                                        'Phone: ' + _this._authService.user.profile.phone_number + '\n' +
                                        'Medical scheme: ' + _this.schemename;
                                    _this._emailService.sendGenericMail('Unsupported medical scheme', message, abp.setting.get('SupportEmail.To')).subscribe(function () {
                                        console.log('Unsupported medical scheme email sent');
                                    });
                                    _this._healthCare.registerUnsupportedUser(_this._authService.user.access_token, _this.getHealthCareUserInputDto()).subscribe(function () {
                                        abp.ui.clearBusy();
                                        _this.modal.hide();
                                        var popup = abp.message.info('We do not support this medical scheme just yet. We are working hard to increase our network of healthcare data partners. We have stored your consent and we will sync through this data as soon as this integration is available.<br><br><b>In the meantime, complete the Elevate Health Risk Assessment on the next page to understand your state of health and wellbeing at a whole new level!', 'Healthcare data activation unsuccessful', true);
                                        _this.changeTemplatePopup('CONTINUE', undefined);
                                        popup.then(function () {
                                            _this.router.navigateByUrl('/app/main/MyHealthCare/RiskAssessment');
                                        });
                                    }, function (err) {
                                        abp.ui.clearBusy();
                                        console.error(err);
                                        _this.showFailedRegisterMessage();
                                    });
                                }
                                else {
                                    _this._healthCare.registerUser(_this._authService.user.access_token, _this.getHealthCareUserInputDto()).subscribe(function () {
                                        abp.ui.clearBusy();
                                        abp.message.success(AppConsts.healthCareSucees, 'Healthcare data activated!', true);
                                        _this.changeTemplatePopup('CLOSE', undefined);
                                        _this.healthcareChanged.emit(true);
                                        _this.saving = true;
                                        _this.active = false;
                                        _this.updateClaimsIdsvr();
                                        _this._facebookService.logEvent('Portal activate healthcare');
                                        _this.modal.hide();
                                    }, function (err) {
                                        abp.ui.clearBusy();
                                        console.error(err);
                                        _this.showFailedRegisterMessage();
                                    });
                                }
                            });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MyHealthCareModalComponent.prototype.sendsms = function (PageName) {
        var body = new SmsBody();
        body.userid = this._authGuard.user.profile.sub;
        body.firstName = this._authGuard.user.profile.given_name;
        body.lastName = this._authGuard.user.profile.family_name;
        body.email = this._authGuard.user.profile.email;
        body.phoneNumber = Array.isArray(this._authGuard.user.profile.phone_number) ? this._authGuard.user.profile.phone_number[0] : this._authGuard.user.profile.phone_number;
        body.pageName = PageName;
        body.message = 'We Want to activate HealthCare Data';
        this.sendQueryEmail(body);
    };
    MyHealthCareModalComponent.prototype.close = function () {
        this.idNumber = null;
        this.active = false;
        this.MemberShipNo = '';
        this.modal.hide();
        this.updateClaimsIdsvr();
    };
    MyHealthCareModalComponent.prototype.ngOnDestroy = function () {
        this.modalSave.unsubscribe();
    };
    return MyHealthCareModalComponent;
}(AppComponentBase));
export { MyHealthCareModalComponent };
