var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { FeatureCheckerService } from '@abp/features/feature-checker.service';
import { LocalizationService } from '@abp/localization/localization.service';
import { MessageService } from '@abp/message/message.service';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { NotifyService } from '@abp/notify/notify.service';
import { SettingService } from '@abp/settings/setting.service';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import { WearablesServiceProxy, ModifyClaimDto, EmailServiceProxy } from '@shared/service-proxies/service-proxies';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import { BsModalService } from 'ngx-bootstrap';
import { ActionModalComponent } from '@app/shared/common/widgets/action-modal/action-modal.component';
import 'shared/extensions/common-extensions.component';
import 'shared/extensions/my-command-center-extensions';
var AppComponentBase = /** @class */ (function () {
    function AppComponentBase(injector) {
        this.localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
        this.configModal = {
            backdrop: true,
            ignoreBackdropClick: true,
            animated: true,
            class: 'w-350px mt-48'
        };
        this.modalService = injector.get(BsModalService);
        this._wearables = injector.get(WearablesServiceProxy);
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this._authGuard = injector.get(AuthGuard);
        this._authService = injector.get(AuthService);
        this._emailService = injector.get(EmailServiceProxy);
    }
    AppComponentBase.prototype.getNewModifyClaimRequest = function (newValue, newType) {
        var modifyClaim = new ModifyClaimDto();
        modifyClaim.accessToken = this._authService.user.access_token;
        modifyClaim.userId = this._authGuard.user.profile.sub;
        modifyClaim.newClaimType = newType;
        modifyClaim.newClaimValue = newValue;
        modifyClaim.oldClaimType = 'allow_wearable';
        modifyClaim.oldClaimValue = 'false';
        return modifyClaim;
    };
    AppComponentBase.prototype.isMobileView_New = function () {
        if (window.innerWidth < 1025) {
            return true;
        }
        else {
            return false;
        }
    };
    AppComponentBase.prototype.openModalWithComponentGlobal = function (title, message, butttoname, imageNeeded, actionButton, callback, textAlignLeft, messages, withToggle) {
        if (textAlignLeft === void 0) { textAlignLeft = false; }
        var initialState = {
            list: [],
            title: title
        };
        this.bsModalRef = this.modalService.show(ActionModalComponent, this.configModal);
        this.bsModalRef.content.closeBtnName = butttoname || 'CLOSE';
        this.bsModalRef.content.title = title;
        this.bsModalRef.content.actionButtonName = actionButton;
        this.bsModalRef.content.icon = imageNeeded ? 'https://elevateme.blob.core.windows.net/publicimages/659889.svg' : null;
        this.bsModalRef.content.textAlignLeft = textAlignLeft;
        this.bsModalRef.content.message = message;
        this.bsModalRef.content.messages = messages;
        this.bsModalRef.content.withToggle = withToggle;
        this.bsModalRef.content.actionEvent.subscribe(function (res) {
            if (!callback) {
                return;
            }
            callback(res);
        });
    };
    AppComponentBase.prototype.getDeepCopied = function (param) {
        return JSON.parse(JSON.stringify(param));
    };
    AppComponentBase.prototype.updateClaimsIdsvr = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this._authGuard.GetUserInfo(function () {
                abp.event.trigger('ClaimUpdate');
                resolve();
            }, true);
        });
    };
    AppComponentBase.prototype.isLoggedInAuth = function () {
        return this._authGuard.user && !this._authGuard.user.expired;
    };
    AppComponentBase.prototype.l = function (key) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    };
    AppComponentBase.prototype.ls = function (sourcename, key) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        var localizedText = this.localization.localize(key, sourcename);
        if (!localizedText) {
            localizedText = key;
        }
        if (!args || !args.length) {
            return localizedText;
        }
        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    };
    AppComponentBase.prototype.determineWindowsWidth = function () {
        if (window.innerWidth < 700) {
            return false;
        }
        else {
            return true;
        }
    };
    AppComponentBase.prototype.sendQueryEmail = function (smsBody) {
        this._wearables.sendDispute(smsBody).subscribe(function (res) {
            abp.notify.success('Sent Successfully!');
        });
    };
    AppComponentBase.prototype.isGranted = function (permissionName) {
        return this.permission.isGranted(permissionName);
    };
    AppComponentBase.prototype.isGrantedAny = function () {
        var permissions = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            permissions[_i] = arguments[_i];
        }
        var e_1, _a;
        if (!permissions) {
            return false;
        }
        try {
            for (var permissions_1 = __values(permissions), permissions_1_1 = permissions_1.next(); !permissions_1_1.done; permissions_1_1 = permissions_1.next()) {
                var permission = permissions_1_1.value;
                if (this.isGranted(permission)) {
                    return true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (permissions_1_1 && !permissions_1_1.done && (_a = permissions_1.return)) _a.call(permissions_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    AppComponentBase.prototype.s = function (key) {
        return abp.setting.get(key);
    };
    AppComponentBase.prototype.appRootUrl = function () {
        return this.appUrlService.appRootUrl;
    };
    Object.defineProperty(AppComponentBase.prototype, "currentTheme", {
        get: function () {
            return this.appSession.theme;
        },
        enumerable: true,
        configurable: true
    });
    return AppComponentBase;
}());
export { AppComponentBase };
