var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModifyClaimDto, UserClaimEditDto } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
var HraAssessmentBaseComponent = /** @class */ (function (_super) {
    __extends(HraAssessmentBaseComponent, _super);
    function HraAssessmentBaseComponent(injector, authGuard, claimService, healthCareService, claimModifyService) {
        var _this = _super.call(this, injector) || this;
        _this.authGuard = authGuard;
        _this.claimService = claimService;
        _this.healthCareService = healthCareService;
        _this.claimModifyService = claimModifyService;
        _this.questionTypeConsts = AppConsts.HraQuestionTypes;
        _this.questionnaireTitle = '...';
        _this.existingId = null;
        _this.defaultValues = null;
        _this.ticks = {
            largeStep: 1,
            smallStep: 1,
            placement: 'After',
            showSmallTicks: false
        };
        return _this;
    }
    Object.defineProperty(HraAssessmentBaseComponent.prototype, "phases", {
        get: function () {
            if (!this.currentQuestionnaire) {
                return [];
            }
            return this.currentQuestionnaire.phases.filter(function (p) { return p.isVisible; });
        },
        enumerable: true,
        configurable: true
    });
    HraAssessmentBaseComponent.prototype.getQuestionsInPhase = function (phase) {
        if (!this.currentQuestionnaire) {
            return [];
        }
        return phase.questions.filter(function (q) { return q.isVisible; });
    };
    HraAssessmentBaseComponent.prototype.checkTriggers = function (question) {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d, e_5, _e;
        if (!question.triggers) {
            return;
        }
        var _loop_1 = function (trigger) {
            var e_6, _a, e_7, _b;
            var isVisible = void 0;
            if (Array.isArray(trigger.range)) {
                isVisible = question.answersGiven.map(function (a) { return a.value; }).some(function (a) { return a >= trigger.range[0] && a <= trigger.range[1]; });
            }
            else {
                isVisible = question.answersGiven.map(function (a) { return a.value; }).some(function (a) { return trigger.values.includes(a); });
                if (!isVisible && trigger.values.includes('Other') && question.answersGiven.some(function (q) { return q.title === 'Other'; })) {
                    isVisible = true;
                }
            }
            if (trigger.key.startsWith('P')) {
                var triggeredPhase = this_1.currentQuestionnaire.phases.find(function (p) { return p.key === trigger.key; });
                triggeredPhase.isVisible = isVisible;
                try {
                    for (var _c = __values(triggeredPhase.questions), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var triggeredQuestion = _d.value;
                        triggeredQuestion.isVisible = isVisible;
                    }
                }
                catch (e_6_1) { e_6 = { error: e_6_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                    }
                    finally { if (e_6) throw e_6.error; }
                }
                if (!triggeredPhase.isVisible) {
                    try {
                        for (var _e = __values(triggeredPhase.questions), _f = _e.next(); !_f.done; _f = _e.next()) {
                            var question_1 = _f.value;
                            question_1.answersGiven = [];
                        }
                    }
                    catch (e_7_1) { e_7 = { error: e_7_1 }; }
                    finally {
                        try {
                            if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                        }
                        finally { if (e_7) throw e_7.error; }
                    }
                }
            }
            else {
                var triggeredQuestion = this_1.currentQuestionnaire.phases
                    .map(function (p) { return p.questions; })
                    .customFlat()
                    .find(function (q) { return q.key === trigger.key; });
                triggeredQuestion.isVisible = isVisible;
                if (!triggeredQuestion.isVisible) {
                    triggeredQuestion.answersGiven = [];
                }
            }
        };
        var this_1 = this;
        try {
            for (var _f = __values(question.triggers), _g = _f.next(); !_g.done; _g = _f.next()) {
                var trigger = _g.value;
                _loop_1(trigger);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_a = _f.return)) _a.call(_f);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (!question.reweight) {
            return;
        }
        if (question.answersGiven.some(function (a) { return question.reweight.values.includes(a.value); })) {
            try {
                for (var _h = __values(this.currentQuestionnaire.phases), _j = _h.next(); !_j.done; _j = _h.next()) {
                    var phase = _j.value;
                    try {
                        for (var _k = __values(phase.questions), _l = _k.next(); !_l.done; _l = _k.next()) {
                            var phaseQuestion = _l.value;
                            if (question.reweight.questionKeys.includes(phaseQuestion.key)) {
                                phaseQuestion.weight /= question.reweight.reweightValue;
                            }
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_l && !_l.done && (_c = _k.return)) _c.call(_k);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_j && !_j.done && (_b = _h.return)) _b.call(_h);
                }
                finally { if (e_2) throw e_2.error; }
            }
            question.reweight.hasBeenReweighted = true;
        }
        else if (question.reweight.hasBeenReweighted) {
            try {
                for (var _m = __values(this.currentQuestionnaire.phases), _o = _m.next(); !_o.done; _o = _m.next()) {
                    var phase = _o.value;
                    try {
                        for (var _p = __values(phase.questions), _q = _p.next(); !_q.done; _q = _p.next()) {
                            var phaseQuestion = _q.value;
                            if (question.reweight.questionKeys.includes(phaseQuestion.key)) {
                                phaseQuestion.weight *= question.reweight.reweightValue;
                            }
                        }
                    }
                    catch (e_5_1) { e_5 = { error: e_5_1 }; }
                    finally {
                        try {
                            if (_q && !_q.done && (_e = _p.return)) _e.call(_p);
                        }
                        finally { if (e_5) throw e_5.error; }
                    }
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_o && !_o.done && (_d = _m.return)) _d.call(_m);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
    };
    HraAssessmentBaseComponent.prototype.onMultipleAnswerChange = function (answer, question) {
        if (this.isAnswerPresent(answer, question)) {
            if (answer.title === 'Other') {
                answer.value = null;
            }
            question.answersGiven = question.answersGiven.filter(function (a) { return a.title !== answer.title; });
        }
        else if (answer.excludeOthers) {
            var otherAnswer = question.answersGiven.find(function (a) { return a.title === 'Other'; });
            if (otherAnswer) {
                otherAnswer.value = null;
            }
            question.answersGiven = [answer];
        }
        else {
            var excludeAnswer_1 = question.answersGiven.find(function (a) { return a.excludeOthers; });
            if (excludeAnswer_1) {
                question.answersGiven = question.answersGiven.filter(function (a) { return a.title !== excludeAnswer_1.title; });
            }
            question.answersGiven.push(answer);
        }
    };
    HraAssessmentBaseComponent.prototype.isAnswerPresent = function (answer, question) {
        return question.answersGiven.some(function (a) { return a.title === answer.title; });
    };
    HraAssessmentBaseComponent.prototype.setTextInputAnswer = function (value, question, answerToSet) {
        var answer = question.answersGiven.find(function (a) { return a.title === answerToSet.title; });
        if (!answer) {
            answer = question.answers.find(function (a) { return a.title === answerToSet.title; });
            question.answersGiven.push(answer);
        }
        answer.value = value;
    };
    HraAssessmentBaseComponent.prototype.getTextInputAnswer = function (question, answerToGet) {
        var answer = question.answersGiven.find(function (a) { return a.title === answerToGet.title; });
        if (answer) {
            return answer.value;
        }
        return null;
    };
    HraAssessmentBaseComponent.prototype.allQuestionsAnswered = function () {
        var e_8, _a, e_9, _b;
        if (!this.currentQuestionnaire) {
            return false;
        }
        try {
            for (var _c = __values(this.currentQuestionnaire.phases), _d = _c.next(); !_d.done; _d = _c.next()) {
                var phase = _d.value;
                if (!phase.isVisible) {
                    continue;
                }
                try {
                    for (var _e = __values(phase.questions), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var question = _f.value;
                        if (!question.isVisible) {
                            continue;
                        }
                        if (!Array.isArray(question.answersGiven) || question.answersGiven.length === 0) {
                            return false;
                        }
                    }
                }
                catch (e_9_1) { e_9 = { error: e_9_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                    }
                    finally { if (e_9) throw e_9.error; }
                }
            }
        }
        catch (e_8_1) { e_8 = { error: e_8_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_8) throw e_8.error; }
        }
        return true;
    };
    HraAssessmentBaseComponent.prototype.getSubstitutions = function (formula) {
        var subs = [];
        for (var i = 0; i < formula.length; i++) {
            if (formula[i] === '{') {
                var nextClose = formula.indexOf('}', i);
                subs.push(formula.substring(i, nextClose + 1));
                i = nextClose;
            }
        }
        return subs;
    };
    HraAssessmentBaseComponent.prototype.getSubstitutionReferencedQuestions = function (subKey, flatQuestions) {
        var key = subKey.substring(1, subKey.length - 1);
        var referencedQuestion = flatQuestions.find(function (q) { return q.key === key; });
        return referencedQuestion;
    };
    HraAssessmentBaseComponent.prototype.getEvaluatedExpression = function (formula, subs, flatQuestions) {
        var e_10, _a;
        try {
            for (var subs_1 = __values(subs), subs_1_1 = subs_1.next(); !subs_1_1.done; subs_1_1 = subs_1.next()) {
                var sub = subs_1_1.value;
                var referencedQuestion = this.getSubstitutionReferencedQuestions(sub, flatQuestions);
                if (referencedQuestion.answersGiven.length === 0) {
                    return null;
                }
                formula = formula.replace(sub, referencedQuestion.answersGiven[0].value.toString());
            }
        }
        catch (e_10_1) { e_10 = { error: e_10_1 }; }
        finally {
            try {
                if (subs_1_1 && !subs_1_1.done && (_a = subs_1.return)) _a.call(subs_1);
            }
            finally { if (e_10) throw e_10.error; }
        }
        return formula;
    };
    HraAssessmentBaseComponent.prototype.submit = function (isCompleted) {
        return __awaiter(this, void 0, void 0, function () {
            var e_11, _a, e_12, _b, achieved, max, flatQuestions, _c, _d, phase, _loop_2, this_2, _e, _f, question, state_1, create, claimList;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        this.isLoading = true;
                        achieved = 0;
                        max = 0;
                        flatQuestions = this.currentQuestionnaire.phases.map(function (p) { return p.questions; }).customFlat();
                        try {
                            for (_c = __values(this.currentQuestionnaire.phases), _d = _c.next(); !_d.done; _d = _c.next()) {
                                phase = _d.value;
                                _loop_2 = function (question) {
                                    var e_13, _a;
                                    var includeQuestion = true;
                                    if (question.type === AppConsts.HraQuestionTypes.CALC) {
                                        var subs = this_2.getSubstitutions(question.formula.expression);
                                        question.formula.evaluatedExpression = this_2.getEvaluatedExpression(question.formula.expression, subs, flatQuestions);
                                        if (!question.formula.evaluatedExpression && question.formula.fallbackExpression) {
                                            var subs_2 = this_2.getSubstitutions(question.formula.fallbackExpression);
                                            question.formula.evaluatedExpression = this_2.getEvaluatedExpression(question.formula.fallbackExpression, subs_2, flatQuestions);
                                        }
                                        includeQuestion = Boolean(question.formula.evaluatedExpression);
                                        if (includeQuestion) {
                                            var result_1 = eval(question.formula.evaluatedExpression);
                                            if (question.answers.some(function (a) { return Array.isArray(a.range); })) {
                                                var answer = question.answers.find(function (a) { return result_1 >= a.range[0] && result_1 < a.range[1]; });
                                                if (!answer) {
                                                    var lowestAnswer = question.answers[0].range[0];
                                                    var highestAnswer = question.answers[question.answers.length - 1].range[1];
                                                    if (result_1 < lowestAnswer) {
                                                        answer = question.answers[0];
                                                    }
                                                    else if (result_1 >= highestAnswer) {
                                                        answer = question.answers[question.answers.length - 1];
                                                    }
                                                }
                                                question.answersGiven = [answer];
                                            }
                                            else {
                                                var answer = question.answers.find(function (a) { return a.value === result_1; });
                                                question.answersGiven = [answer];
                                            }
                                        }
                                        else {
                                            question.answersGiven = [];
                                        }
                                    }
                                    else if (question.type === AppConsts.HraQuestionTypes.INDEX) {
                                        var subs = this_2.getSubstitutions(question.formula.expression);
                                        var rowQuestion = this_2.getSubstitutionReferencedQuestions(subs[0], flatQuestions);
                                        var colQuestion = this_2.getSubstitutionReferencedQuestions(subs[1], flatQuestions);
                                        if (rowQuestion.answersGiven.length === 0 || colQuestion.answersGiven.length === 0) {
                                            includeQuestion = false;
                                            question.answersGiven = [];
                                            return "break";
                                        }
                                        var rowVal_1 = rowQuestion.answersGiven[0].value;
                                        var colVal_1 = colQuestion.answersGiven[0].value;
                                        var answer = question.answers.find(function (a) { return a.range[0] === rowVal_1 && a.range[1] === colVal_1; });
                                        question.answersGiven = [answer];
                                    }
                                    else if (question.type === AppConsts.HraQuestionTypes.REFERENCE) {
                                        includeQuestion = question.answersGiven.length > 0;
                                    }
                                    else if (!question.isVisible) {
                                        includeQuestion = false;
                                    }
                                    if (includeQuestion && question.isScored) {
                                        var questionMax = Math.max.apply(Math, __spread(question.answers.map(function (a) { return +a.value; })));
                                        var questionAchieved = 0;
                                        try {
                                            for (var _b = __values(question.answersGiven), _c = _b.next(); !_c.done; _c = _b.next()) {
                                                var answerGiven = _c.value;
                                                questionAchieved += +answerGiven.value;
                                            }
                                        }
                                        catch (e_13_1) { e_13 = { error: e_13_1 }; }
                                        finally {
                                            try {
                                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                            }
                                            finally { if (e_13) throw e_13.error; }
                                        }
                                        var percentage = questionAchieved / questionMax;
                                        var weightedScore = percentage * question.weight;
                                        question.score = {
                                            achieved: weightedScore,
                                            max: question.weight
                                        };
                                        achieved += weightedScore;
                                        max += question.weight;
                                    }
                                };
                                this_2 = this;
                                try {
                                    for (_e = __values(phase.questions), _f = _e.next(); !_f.done; _f = _e.next()) {
                                        question = _f.value;
                                        state_1 = _loop_2(question);
                                        if (state_1 === "break")
                                            break;
                                    }
                                }
                                catch (e_12_1) { e_12 = { error: e_12_1 }; }
                                finally {
                                    try {
                                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                                    }
                                    finally { if (e_12) throw e_12.error; }
                                }
                            }
                        }
                        catch (e_11_1) { e_11 = { error: e_11_1 }; }
                        finally {
                            try {
                                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                            }
                            finally { if (e_11) throw e_11.error; }
                        }
                        achieved = Math.round(achieved * 100);
                        max = Math.round(max * 100);
                        create = {
                            data: {
                                isCompleted: isCompleted,
                                healthRiskAssessment: this.currentQuestionnaire,
                                score: {
                                    achieved: achieved,
                                    max: max
                                },
                                userDetails: {
                                    age: this.claims.birthdate ? moment(moment()).diff(moment(this.claims.birthdate, 'DD/MM/YYYY hh:mm:ss A'), 'years') : null,
                                    education: this.claims.education,
                                    entityNumber: this.authGuard.user.profile.sub,
                                    gender: this.claims.gender,
                                    grossIncome: +this.claims.grossIncome,
                                    netIncome: +this.claims.netIncome,
                                    occupation: this.claims.occupation,
                                    smokerStatus: this.claims.smokingStatus
                                }
                            },
                            id: this.existingId,
                            entityNumber: this.authGuard.user.profile.sub,
                            questionnaireKey: this.currentQuestionnaire.key
                        };
                        claimList = this.getClaimsToUpdate(isCompleted);
                        return [4 /*yield*/, Promise.all([
                                this.healthCareService.createHealthRiskAssessment(create).toPromise(),
                                this.claimModifyService.modifyClaim(claimList).toPromise()
                            ])];
                    case 1:
                        _g.sent();
                        return [4 /*yield*/, this.updateClaimsIdsvr()];
                    case 2:
                        _g.sent();
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    HraAssessmentBaseComponent.prototype.getQuestionnaireClaimType = function () {
        var claim = {
            dateClaim: null,
            statusClaim: null
        };
        switch (this.questionnaireKey) {
            case AppConsts.HraKeys.INTRO:
                claim.statusClaim = AppConsts.ClaimTypes.hra_intro_status;
                claim.dateClaim = AppConsts.ClaimTypes.hra_intro_date;
                break;
            case AppConsts.HraKeys.MY_NUTRITION:
                claim.statusClaim = AppConsts.ClaimTypes.hra_nutrition_status;
                claim.dateClaim = AppConsts.ClaimTypes.hra_nutrition_date;
                break;
            case AppConsts.HraKeys.MY_SLEEP:
                claim.statusClaim = AppConsts.ClaimTypes.hra_sleep_status;
                claim.dateClaim = AppConsts.ClaimTypes.hra_sleep_date;
                break;
            case AppConsts.HraKeys.MY_HEALTHY_BODY:
                claim.statusClaim = AppConsts.ClaimTypes.hra_body_status;
                claim.dateClaim = AppConsts.ClaimTypes.hra_body_date;
                break;
            case AppConsts.HraKeys.MY_HEALTHY_MIND:
                claim.statusClaim = AppConsts.ClaimTypes.hra_mind_status;
                claim.dateClaim = AppConsts.ClaimTypes.hra_mind_date;
                break;
            case AppConsts.HraKeys.MY_EXERCISE:
                claim.statusClaim = AppConsts.ClaimTypes.hra_exercise_status;
                claim.dateClaim = AppConsts.ClaimTypes.hra_exercise_date;
                break;
        }
        return claim;
    };
    HraAssessmentBaseComponent.prototype.getClaimsToUpdate = function (isCompleted) {
        var e_14, _a;
        var claimList = new ModifyClaimDto();
        claimList.userClaimEditDtos = new Array();
        claimList.accessToken = this._authService.user.access_token;
        claimList.userId = this._authService.user.profile.sub;
        if ((!Boolean(this._authService.user.profile.hra_goal_completed) || this._authService.user.profile.hra_goal_completed === 'false') && this.questionnaireKey !== AppConsts.HraKeys.INTRO) {
            var questionnairesToComplete = [1, 2, 3, 4, 5, 5, 1, 2, 3, 4, 5, 5];
            var currentToComplete = questionnairesToComplete[moment().month()];
            var currentCompleted = 0;
            if (isCompleted) {
                currentCompleted++;
            }
            var statusClaims = [
                AppConsts.ClaimTypes.hra_nutrition_status,
                AppConsts.ClaimTypes.hra_sleep_status,
                AppConsts.ClaimTypes.hra_body_status,
                AppConsts.ClaimTypes.hra_mind_status,
                AppConsts.ClaimTypes.hra_exercise_status
            ];
            try {
                for (var statusClaims_1 = __values(statusClaims), statusClaims_1_1 = statusClaims_1.next(); !statusClaims_1_1.done; statusClaims_1_1 = statusClaims_1.next()) {
                    var claim_1 = statusClaims_1_1.value;
                    var claimValue = this._authService.user.profile[claim_1];
                    if (Boolean(claimValue) && claimValue === AppConsts.HraQuestionnaireStatuses.COMPLETED) {
                        currentCompleted++;
                    }
                }
            }
            catch (e_14_1) { e_14 = { error: e_14_1 }; }
            finally {
                try {
                    if (statusClaims_1_1 && !statusClaims_1_1.done && (_a = statusClaims_1.return)) _a.call(statusClaims_1);
                }
                finally { if (e_14) throw e_14.error; }
            }
            if (currentCompleted >= currentToComplete) {
                claimList.userClaimEditDtos.push(new UserClaimEditDto({
                    claimType: AppConsts.ClaimTypes.hra_goal_completed,
                    claimValue: 'true'
                }), new UserClaimEditDto({
                    claimType: AppConsts.ClaimTypes.hra_intro_date,
                    claimValue: moment().format('DD/MM/YYYY hh:mm:ss A')
                }));
            }
        }
        var claim = this.getQuestionnaireClaimType();
        claimList.userClaimEditDtos.push(new UserClaimEditDto({
            claimType: claim.statusClaim,
            claimValue: isCompleted ? 'Completed' : 'Saved'
        }), new UserClaimEditDto({
            claimType: claim.dateClaim,
            claimValue: moment().format('DD/MM/YYYY hh:mm:ss A')
        }));
        return claimList;
    };
    HraAssessmentBaseComponent.prototype.setQuestionnaire = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_15, _a, e_16, _b, e_17, _c, e_18, _d, e_19, _e, e_20, _f, result, savedQuestionnaire, claim, _g, _h, phase, _loop_3, _j, _k, question, referencedQuestions, referencedQuestionnaireObservables, referencedQuestionnaires, _l, _m, phase, _loop_4, _o, _p, question, _q, _r, phase, _loop_5, this_3, _s, _t, question;
            var _this = this;
            return __generator(this, function (_u) {
                switch (_u.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            import("../../../../assets/data/health-risk-assessments/" + this.questionnaireKey + ".json"),
                            this.healthCareService.GetLatestHealthRiskAssessment(this.authGuard.user.profile.sub + "_" + this.questionnaireKey).toPromise(),
                            this.claimService.getClaims(this.authGuard.user.profile.sub, this.authGuard.user.access_token).toPromise(),
                        ])];
                    case 1:
                        result = _u.sent();
                        this.currentQuestionnaire = result[0];
                        if (result[1]) {
                            savedQuestionnaire = result[1];
                            claim = this.getQuestionnaireClaimType();
                            if (!savedQuestionnaire.data.isCompleted && (!Boolean(this._authService.user.profile[claim.statusClaim]) || this._authService.user.profile[claim.statusClaim] === AppConsts.HraQuestionnaireStatuses.SAVED)) {
                                this.existingId = savedQuestionnaire.id;
                                this.currentQuestionnaire = savedQuestionnaire.data.healthRiskAssessment;
                                try {
                                    for (_g = __values(this.currentQuestionnaire.phases), _h = _g.next(); !_h.done; _h = _g.next()) {
                                        phase = _h.value;
                                        _loop_3 = function (question) {
                                            var answeredQuestionValues = question.answersGiven.map(function (a) { return a.value; });
                                            question.answersGiven = question.answers.filter(function (a) { return answeredQuestionValues.includes(a.value); });
                                        };
                                        try {
                                            for (_j = __values(phase.questions), _k = _j.next(); !_k.done; _k = _j.next()) {
                                                question = _k.value;
                                                _loop_3(question);
                                            }
                                        }
                                        catch (e_16_1) { e_16 = { error: e_16_1 }; }
                                        finally {
                                            try {
                                                if (_k && !_k.done && (_b = _j.return)) _b.call(_j);
                                            }
                                            finally { if (e_16) throw e_16.error; }
                                        }
                                    }
                                }
                                catch (e_15_1) { e_15 = { error: e_15_1 }; }
                                finally {
                                    try {
                                        if (_h && !_h.done && (_a = _g.return)) _a.call(_g);
                                    }
                                    finally { if (e_15) throw e_15.error; }
                                }
                            }
                        }
                        referencedQuestions = this.currentQuestionnaire.phases
                            .map(function (p) { return p.questions; })
                            .customFlat()
                            .filter(function (q) { return Boolean(q.reference); })
                            .map(function (q) { return q.reference; });
                        if (!(referencedQuestions.length > 0)) return [3 /*break*/, 3];
                        referencedQuestionnaireObservables = referencedQuestions
                            .map(function (r) { return _this.healthCareService.GetLatestHealthRiskAssessment(_this.authGuard.user.profile.sub + "_" + r.questionnaireKey).toPromise(); });
                        return [4 /*yield*/, Promise.all(referencedQuestionnaireObservables)];
                    case 2:
                        referencedQuestionnaires = _u.sent();
                        try {
                            for (_l = __values(this.currentQuestionnaire.phases), _m = _l.next(); !_m.done; _m = _l.next()) {
                                phase = _m.value;
                                _loop_4 = function (question) {
                                    if (Boolean(question.reference)) {
                                        var referencedQuestionnaire = referencedQuestionnaires.find(function (r) { return r.data.healthRiskAssessment.key === question.reference.questionnaireKey; });
                                        var referencedQuestion = referencedQuestionnaire.data.healthRiskAssessment.phases
                                            .map(function (p) { return p.questions; })
                                            .customFlat()
                                            .find(function (q) { return q.key === question.reference.questionKey; });
                                        question.answersGiven = referencedQuestion.answersGiven;
                                    }
                                };
                                try {
                                    for (_o = __values(phase.questions), _p = _o.next(); !_p.done; _p = _o.next()) {
                                        question = _p.value;
                                        _loop_4(question);
                                    }
                                }
                                catch (e_18_1) { e_18 = { error: e_18_1 }; }
                                finally {
                                    try {
                                        if (_p && !_p.done && (_d = _o.return)) _d.call(_o);
                                    }
                                    finally { if (e_18) throw e_18.error; }
                                }
                            }
                        }
                        catch (e_17_1) { e_17 = { error: e_17_1 }; }
                        finally {
                            try {
                                if (_m && !_m.done && (_c = _l.return)) _c.call(_l);
                            }
                            finally { if (e_17) throw e_17.error; }
                        }
                        _u.label = 3;
                    case 3:
                        this.questionnaireTitle = this.currentQuestionnaire.title;
                        if (this.defaultValues) {
                            try {
                                for (_q = __values(this.currentQuestionnaire.phases), _r = _q.next(); !_r.done; _r = _q.next()) {
                                    phase = _r.value;
                                    _loop_5 = function (question) {
                                        if (!question.defaultValue) {
                                            return "continue";
                                        }
                                        if (question.answers.length === 1 && question.answers[0].value === null) {
                                            question.answersGiven = question.answers;
                                            question.answersGiven[0].value = this_3.defaultValues[question.defaultValue];
                                        }
                                        else {
                                            var defaultValue_1 = this_3.defaultValues[question.defaultValue];
                                            var answers = question.answers.filter(function (a) { return a.value === defaultValue_1; });
                                            question.answersGiven = answers;
                                        }
                                    };
                                    this_3 = this;
                                    try {
                                        for (_s = __values(phase.questions), _t = _s.next(); !_t.done; _t = _s.next()) {
                                            question = _t.value;
                                            _loop_5(question);
                                        }
                                    }
                                    catch (e_20_1) { e_20 = { error: e_20_1 }; }
                                    finally {
                                        try {
                                            if (_t && !_t.done && (_f = _s.return)) _f.call(_s);
                                        }
                                        finally { if (e_20) throw e_20.error; }
                                    }
                                }
                            }
                            catch (e_19_1) { e_19 = { error: e_19_1 }; }
                            finally {
                                try {
                                    if (_r && !_r.done && (_e = _q.return)) _e.call(_q);
                                }
                                finally { if (e_19) throw e_19.error; }
                            }
                        }
                        this.claims = result[2];
                        return [2 /*return*/];
                }
            });
        });
    };
    HraAssessmentBaseComponent.prototype.getSliderValues = function (question) {
        return question.answers
            .sort(function (a, b) {
            if (a.value > b.value) {
                return 1;
            }
            else if (a.value < b.value) {
                return -1;
            }
            return +a.title > +b.title ? 1 : -1;
        })
            .map(function (a) { return a.title; });
    };
    HraAssessmentBaseComponent.prototype.setSliderValue = function (event, question) {
        var answers = this.getSliderValues(question);
        var answerValue = answers[+event.value];
        var answer = question.answers.find(function (a) { return a.title === answerValue; });
        question.answersGiven = [answer];
    };
    HraAssessmentBaseComponent.prototype.getSliderValue = function (question) {
        var answers = this.getSliderValues(question);
        if (question.answersGiven.length === 0) {
            question.answersGiven = [question.answers[0]];
        }
        var index = answers.findIndex(function (a) { return a === question.answersGiven[0].title; });
        return index;
    };
    HraAssessmentBaseComponent.prototype.hasSuffix = function (question) {
        return Boolean(question.suffix);
    };
    return HraAssessmentBaseComponent;
}(AppComponentBase));
export { HraAssessmentBaseComponent };
