import { Component, OnInit, NgZone, AfterViewInit, ViewEncapsulation, ViewChild, Injector } from '@angular/core';

import { Cursor, XYChart } from '@amcharts/amcharts4/charts';
import { HealthCareApiServiceProxy, PopulationEpisodeDataOutput, HospitalEventOutput, HealthRecordOutput, SmsBody } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { MyHealthCareModalComponent } from './my-health-care-modal/my-health-care-modal.component';
import { MonthPipeService } from '@shared/service-proxies/month-pipe.service';
am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);
import { CheckBoxSelectionService, MultiSelect, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EpisodeCareComponent } from '@app/shared/common/widgets/my-healthcare-widgets/episode-care/episode-care.component';
import { MyHealthcareService } from '@app/shared/services/my-healthcare.service';
import { ChangeDetectorRef } from '@angular/core';
import { HospitalEventsComponent } from '@app/shared/common/widgets/my-healthcare-widgets/hospital-events/hospital-events.component';
import { Router } from '@angular/router';

interface MultiselectFilter {
    id: string;
    name: string;
}

@Component({
    selector: 'app-my-healthcare',
    templateUrl: './my-healthcare.component.html',
    styleUrls: ['./my-healthcare.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
    providers: [CheckBoxSelectionService]
})


export class MyHealthcareComponent extends AppComponentBase implements OnInit, AfterViewInit {
    itemSelectCount = 0;
    numberOfCallsSelect = 0;
    NoHealthcare: boolean;
    currentEpisodeYear: any;
    currentUserQuery: string;
    requestAccessToHECharts: boolean = undefined;
    showChart: boolean = undefined;
    isLoading: boolean;
    constructor(
        injector: Injector,
        private _healthCare: HealthCareApiServiceProxy,
        private _changeDetecter: ChangeDetectorRef,
        private _monthPipe: MonthPipeService,
        public _myHealthCareService: MyHealthcareService,
        private _router: Router
    ) {
        super(injector);
    }

    @ViewChild('checkbox', { read: true })
    hospitalEvents: HospitalEventOutput[];
    healthRecordDate = new Array<HealthRecordOutput>();
    healthcareactivated: boolean;
    @ViewChild('appmyhealthcaremodal') appmyhealthcaremodal: MyHealthCareModalComponent;
    @ViewChild('episodeOfCareref') episodeOfCareref: EpisodeCareComponent;
    @ViewChild('hospitalEventsref') hospitalEventsref: HospitalEventsComponent;


    hospEventSelectedYear: any = '';
    imgSrc: any = '../../../assets/common/images/chatblack.svg';

    episodeCareData = new Array<PopulationEpisodeDataOutput>();
    chartCareDataCopyTemp = new Array<PopulationEpisodeDataOutput>();

    public isCollapsedpharmacy2 = true;
    public isCollapsedpharmacy1 = true;
    public isCollapsedRadiology = true;
    public isCollapsedSergery = true;
    model = 1;
    schemename: string;
    MemberShipNo: string;
    yearList = new Array<string>();
    hospEventData = new HospitalEventOutput();
    disputingText = '';
    ifActivated = false;
    yearFilterList = [];
    monthToggle: boolean;
    currentYear: number;
    selectedYear: number;
    yearToggle: boolean;
    yearState = [];
    monthState = [];
    sourceFilterList: MultiselectFilter[];

    @ViewChild('checkbox')
    public dropDownListObject: DropDownListComponent;
    //descriptionState = [];
    selectedMonth: string[] = [];
    selectedMonthNumber: string[] = [];
    selectAll: boolean;
    //selectAllDescription: boolean;
    //descriptionToggle: boolean;
    //showAllDescription: boolean;
    monthFilterList = [
        { name: 'Jan', selected: false },
        { name: 'Feb', selected: false },
        { name: 'Mar', selected: false },
        { name: 'Apr', selected: false },
        { name: 'May', selected: false },
        { name: 'Jun', selected: false },
        { name: 'Jul', selected: false },
        { name: 'Aug', selected: false },
        { name: 'Sep', selected: false },
        { name: 'Oct', selected: false },
        { name: 'Nov', selected: false },
        { name: 'Dec', selected: false },
    ];
    descriptionFilterList: MultiselectFilter[];
    sortedFilterList: MultiselectFilter[];
    fields: Object = { text: 'name', value: 'id' };
    descriptionPlaceholder = 'Filter by description';
    selectedDescription = new Array<string>();
    currentUserInfo: any;
    mode: string;
    selectAllText: string;
    popHeight = '200px';
    yearActive = new Array<boolean>();
    descriptionFetchingDone: boolean;
    removingCount = 0;
    numberOfCalls = 0;
    enableGroupCheckBox = false;
    allowFiltering = true;
    filterBarPlaceholder = 'Search Description';
    chart: XYChart;
    yearListHE = new Array<string>();
    yearActiveHE = new Array<boolean>();
    seemoreActivated = false;
    arrayforHE = new Array<boolean>();
    yearOfHr = new Array<string>();
    yearActiveHr = new Array<boolean>();

    ngOnInit() {
        this.mode = 'CheckBox';
        this.selectAllText = 'Select All';
        this.yearToggle = true;
        this.yearState[0] = true;
        this.selectedMonth = new Array<string>();
        this.currentYear = new Date().getFullYear();
        this.selectedYear = this.currentYear;
        for (let initialYear = this.currentYear; initialYear >= 2013; initialYear--) {
            this.yearFilterList.push(initialYear);
        }
        abp.ui.setBusy();
        this.updateClaimsIdsvr()
            .then(res => {
                this._myHealthCareService.getEpisodeOfCare().then(res => {
                    this.episodeOfCareref.loadEpisodeOFCare();
                    abp.ui.clearBusy();
                });
            })
            .catch((err) => {
                this.episodeOfCareref.loadEpisodeOFCare();
                abp.ui.clearBusy();
            });
    }

    goToHealthRiskAssessment(): void {
        this._router.navigateByUrl('/app/main/MyHealthCare/RiskAssessment');
    }

    getDescriptionDropdown() {
        this._healthCare.getDescriptionDropdownValue(this._authGuard.user.access_token)
            .subscribe((res: string[]) => {
                this.descriptionFilterList = new Array<MultiselectFilter>();
                this.sortedFilterList = new Array<MultiselectFilter>();
                if (res != null) {


                    res.forEach((value, index) => {
                        if (value.toLowerCase() === 'pharmacy' || value.toLowerCase() === 'radiologist' || value.toLowerCase() === 'general practitioner' || value.toLowerCase() === 'optometrist') {
                            this.sortedFilterList.push({
                                id: index.toString(),
                                name: value
                            });

                        }
                    });

                    this.descriptionFilterList = this.sortedFilterList;


                }
                // Array.isArray(res) && res.length ? this.showAllDescription = true : this.showAllDescription = false;
            });
    }


    showHealthCareChart(item) {
        this._changeDetecter.detectChanges();
        this.healthcareactivated = true;
        this.episodeOfCareref.loadEpisodeOFCare();
    }

    ngAfterViewInit() {
    }

    getIfActivated() {
        this._healthCare.getDataActivatedStatus(this.getAccessToken()).subscribe((res: boolean) => {
            this.ifActivated = res;
        });
    }

    getAccessToken(): string | undefined {
        return this._authService.user.access_token;
    }

    getEntity(): string {
        return this._authService.user.access_token;
    }

    FilterWhithYearHospEvent(item, i) {
        // let d = this.yearActiveHE.findIndex(x => x === true);
        // this.yearActiveHE[d] = false;
        // this.yearActiveHE[i] = true;
        // this.hospEventSelectedYear = item;
        // this.loadHospitalEvents(item);

        this.hospitalEventsref.FilterWhithYearHospEvent(item, i);
    }

    getHospEventYear() {
        this._changeDetecter.detectChanges();
        this.hospitalEventsref.loadHospitalEvent();
    }


    gettHealthRecordYear() {
        this.getDescriptionDropdown();
        if (this.yearOfHr.length > 0) {
            this.loadHealthRecord(this.yearOfHr[0], null, null);
        } else {
            this._healthCare.getHealthRecordDataYear(this.getEntity()).subscribe((res: string[]) => {
                this.yearOfHr = res;
                let i = 0;
                this.yearOfHr.forEach(x => {
                    if (i === 0) {
                        this.yearActiveHr.push(true);
                    } else {
                        this.yearActiveHr.push(false);
                    }
                    i++;
                });
                this.loadHealthRecord(res[0], null, null);
            }, (err) => {
                abp.ui.clearBusy();
            });
        }
    }

    FilterHRYear(item: string, i) {
        let d = this.yearActiveHr.findIndex(x => x === true);
        this.yearActiveHr[d] = false;
        this.yearActiveHr[i] = true;
        this.loadHealthRecord(item, null, null);
    }

    loadHealthRecord(year: string, month: string[], description: string[]) {
        this.NoHealthcare = false;
        this.isLoading = true;

        this._healthCare.getHealthRecordData(month, description, year, this._authService.user.access_token).subscribe(res => {
            if (res === null || res.length <= 0) {
                this.NoHealthcare = true;
            } else {
                this.NoHealthcare = false;

                res.forEach(x => {
                    this.arrayforHE.push(false);
                });
            }
            this.healthRecordDate = res;

        }, (err) => {
        },
            () => {
                this.isLoading = false;
            }
        );
    }


    yearDropdownSelect(year: number, Index: number) {

        this.selectedYear = year;
        this.yearToggle = true;
        this.yearFilterList.forEach((y, Index) => {
            this.yearState[Index] = false;
        });
        this.yearState[Index] = !this.yearState[Index];
        this.loadHealthRecord(this.selectedYear.toString(), this.selectedMonthNumber, this.selectedDescription);


    }

    monthDropdownSelect(e, Index: number, month: any) {
        if (e.target.checked) {
            this.monthToggle = true;
            this.monthState[Index] = !this.monthState[Index];
            this.selectedMonth.push(month.name);
        } else {
            this.monthState[Index] = !this.monthState[Index];
            this.selectAll = false;
            // tslint:disable-next-line: no-unused-expression
            this.monthFilterList.every(m => m.selected === false) ? this.monthToggle = false : null;
            let index = this.selectedMonth.indexOf(month.name, 0);
            if (index > -1) {
                this.selectedMonth.splice(index, 1);
            }
        }

        this.selectedMonthNumber = this._monthPipe.transform(this.selectedMonth);

        this.loadHealthRecord(this.selectedYear.toString(), this.selectedMonthNumber, this.selectedDescription);
    }

    allMonth() {
        if (this.monthFilterList.every(val => val.selected === true)) {
            this.monthToggle = false;
            this.selectAll = false;
            this.monthFilterList.forEach((val, index) => {
                val.selected = false;
                this.selectedMonth = [];

                if (!val.selected) {
                    this.monthState[index] = false;
                }
            });
        } else {
            this.monthToggle = true;
            this.selectAll = true;
            this.selectedMonth = [];

            this.monthFilterList.forEach((val, index) => {
                val.selected = true;
                this.selectedMonth.push(val.name);

                if (val.selected) {
                    this.monthState[index] = true;
                }
            });
        }

        this.selectedMonthNumber = this._monthPipe.transform(this.selectedMonth);

        this.loadHealthRecord(this.selectedYear.toString(), this.selectedMonthNumber, this.selectedDescription);

    }

    onItemSelected(item) {
        this.itemSelectCount += 1;

        this.selectedDescription.push(item.itemData.name);
        this.numberOfCallsSelect = this.numberOfCallsSelect + 1;
        if (this.itemSelectCount === this.numberOfCallsSelect) {
            this.loadHealthRecord(this.selectedYear.toString(), this.selectedMonthNumber, this.selectedDescription);
            this.itemSelectCount = 0;
            this.numberOfCallsSelect = 0;
        }
    }

    onItemRemoved(item) {
        this.removingCount += 1;
        this.selectedDescription.splice(this.selectedDescription.findIndex(x => x === item.itemData.name), 1);
        setTimeout(() => {
            this.numberOfCalls = this.numberOfCalls + 1;
            if (this.removingCount === this.numberOfCalls) {
                this.loadHealthRecord(this.selectedYear.toString(), this.selectedMonthNumber, this.selectedDescription);
                this.removingCount = 0;
                this.numberOfCalls = 0;
            }
        }, 150);
    }

    clearFilter() {
        this.dropDownListObject.value = null;
        this.yearFilterList.forEach((y, Index) => {
            this.yearState[Index] = false;
        });
        this.yearToggle = true;
        this.yearState[0] = true;
        this.selectedYear = this.yearFilterList[0];

        this.monthToggle = false;
        this.selectAll = false;
        this.monthFilterList.forEach((val, index) => {
            val.selected = false;
            if (!val.selected) {
                this.monthState[index] = false;
            }
        });
        this.selectedMonthNumber = new Array<string>();
        this.selectedMonth = new Array<string>();
        this.selectedDescription = new Array<string>();
        this.loadHealthRecord(this.selectedYear.toString(), null, null);
    }


    sendsms(PageName?) {
        let body = new SmsBody();
        body.userid = this._authGuard.user.profile.sub;
        body.firstName = this._authGuard.user.profile.given_name;
        body.lastName = this._authGuard.user.profile.family_name;
        body.email = this._authGuard.user.profile.email;
        body.phoneNumber = Array.isArray(this._authGuard.user.profile.phone_number) ? this._authGuard.user.profile.phone_number[0] : this._authGuard.user.profile.phone_number;
        body.pageName = PageName;
        body.message = this.currentUserQuery;
        this.sendQueryEmail(body);
        this.currentUserQuery = '';
    }

    determineWindowsWidth() {
        if (window.innerWidth < 700) {
            return false;
        } else {
            return true;
        }
    }

    selectedYearFromNav(item) {
        console.log('Healthcare year hitting: ' + item);
        this.selectedYear = item;
        this.loadHealthRecord(this.selectedYear.toString(), this.selectedMonthNumber, this.selectedDescription);
    }

    selectedMonthFromNav(item) {
        console.log('Healthcare MONTH hitting: ' + item);

    }
}
