import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import getSymbolFromCurrency from 'currency-symbol-map/map';

@Pipe({ name: 'imagePipe' })
export class ImagePipe implements PipeTransform {

    imageList = new Array<string>();

    transform(value: string): string | null {

        if (value === null || value === undefined) {
            return '../../../assets/common/images/Elevate _ Wearables - Steps.png';
        }


        if (value.toLocaleLowerCase() === 'paid') {
            return '../../../assets/common/images/MyBillingInfo/paid.svg';
        } else if (value.toLocaleLowerCase() === 'outstanding') {
            return '../../../assets/common/images/MyBillingInfo/outstanding.svg';
        }

        if (value.toLocaleLowerCase() === 'General Practitioner'.toLocaleLowerCase()) {
            return '../../../assets/common/images/GP.png';
        } else if (value.toLocaleLowerCase() === 'OPTOMETRIST'.toLocaleLowerCase() || value.toLocaleLowerCase() === 'PHYSIOTHERAPIST'.toLocaleLowerCase()) {
            return '../../../assets/common/images/Allied_Health.png';
        } else if (value.toLocaleLowerCase() === 'Blood Product Courier'.toLocaleLowerCase()) {
            return '../../../assets/common/images/Anaesthetist.png';
        } else if (value.toLocaleLowerCase() === 'Chiropractor'.toLocaleLowerCase() || value.toLocaleLowerCase() === 'INTERN'.toLocaleLowerCase()) {
            return '../../../assets/common/images/Specialist.png';
        } else if (value.toLocaleLowerCase() === 'Ambulance Advanced'.toLocaleLowerCase()) {
            return '../../../assets/common/images/Pathology.png';
        } else if (value.toLocaleLowerCase() === 'Homeopath'.toLocaleLowerCase() || value.toLocaleLowerCase() === 'Urologist'.toLocaleLowerCase()) {
            return '../../../assets/common/images/Surgery.png';
        } else if (value.toLocaleLowerCase() === 'Anaesthetist'.toLocaleLowerCase()) {
            return '../../../assets/common/images/Radiology.png';
        } else if (value.toLocaleLowerCase() === 'Ambulance Intermediate'.toLocaleLowerCase() || value.toLocaleLowerCase() === 'PROVINCIAL HOSPITAL'.toLocaleLowerCase() || value.toLocaleLowerCase() === 'Private Hospital "A"'.toLocaleLowerCase() || value.toLocaleLowerCase() === 'Private Hospital "B"'.toLocaleLowerCase()) {
            return '../../../assets/common/images/Hospital.png';
        } else if (value.toLocaleLowerCase() === 'GENERAL DENTIST'.toLocaleLowerCase()) {
            return '../../../assets/common/images/Dental_Care.png';
        } else if (value.toLocaleLowerCase() === 'PHARMACY'.toLocaleLowerCase()) {
            return '../../../assets/common/images/Pharmacy.png';
        } else if (value.toLocaleLowerCase() === 'General Practitioner'.toLocaleLowerCase()) {
            return '../../../assets/common/images/Other.png';
        } else if (value.toLocaleLowerCase() === 'run') {
            return '../../../assets/common/images/Black/runoutdoor.png';
        } else if (value.toLocaleLowerCase() === 'pilates') {
            return '../../../assets/common/images/Black/pilates.png';
        } else if (value.toLocaleLowerCase() === 'workout') {
            return '../../../assets/common/images/Black/workout.png';
        } else if (value.toLocaleLowerCase() === 'cycle') {
            return '../../../assets/common/images/Black/cycle_outdoor.png';
        } else if (value.toLocaleLowerCase() === 'other' || value.toLocaleLowerCase() === 'PATHOLOGIST'.toLocaleLowerCase()) {
            return '../../../assets/common/images/Other_white.png';
        } else if (value.toLocaleLowerCase() === 'dancing') {
            return '../../../assets/common/images/Black/dancing.png';
        } else if (value.toLocaleLowerCase() === 'golf') {
            return '../../../assets/common/images/Black/golf.png';
        } else if (value.toLocaleLowerCase() === 'hike') {
            return '../../../assets/common/images/Black/hike.png';
        } else if (value.toLocaleLowerCase() === 'cycle') {
            return '../../../assets/common/images/Black/cycle_indoor.png';
        } else if (value.toLocaleLowerCase() === 'swim') {
            return '../../../assets/common/images/Black/swim.png';
        } else if (value.toLocaleLowerCase() === 'tennis') {
            return '../../../assets/common/images/Black/tennis.png';
        } else if (value.toLocaleLowerCase() === 'run') {
            return '../../../assets/common/images/Black/runindoor.png';
        } else if (value.toLocaleLowerCase() === 'walk') {
            return '../../../assets/common/images/Black/walk.png';
        } else if (value.toLocaleLowerCase() === 'yoga') {
            return '../../../assets/common/images/Black/yoga.png';
        } else if (value.toLocaleLowerCase() === 'row') {
            return '../../../assets/common/images/Black/rowing.png';
        } else if (value.toLocaleLowerCase() === 'sport') {
            return '../../../assets/common/images/Black/sport.png';
        } else {
            return '../../../assets/common/Black/runindoor.png';
        }

    }

}

@Pipe({ name: 'questionableBoolean' })
export class QuestionableBooleanPipe implements PipeTransform {
    transform(value: string): string {
        return value.toLowerCase() === 'true' ? 'Yes' : 'No';
    }
}



@Pipe({ name: 'CurrencyZar' })
export class CurrencyPipeZar implements PipeTransform {
    constructor(private authService: AuthService) {
    }

    transform(
        value: any = 0,
        displayDecimals: boolean = true,
        showSymbol: boolean = true,
        checkTenant: boolean = false,
        currencyCode: string = 'ZAR'
    ): string | null {
        let symbol = 'R';

        if (checkTenant && this.authService.user && this.authService.user.profile && this.authService.user.profile.tenant_name === 'Australia') {
            symbol = 'A$';
        }

        if (currencyCode !== 'ZAR') {
            symbol = getSymbolFromCurrency[currencyCode];
        }

        if (Number.isNaN(value)) {
            if (displayDecimals) {
                return showSymbol ? `${symbol} 0.00` : '0.00';
            }

            return showSymbol ? `${symbol} 0` : '0';
        }

        value = +value;

        if (value != null) {
            const decimals = displayDecimals ? 2 : 0;

            const formattedValue = Math.abs(value).toLocaleString('en', {
                'minimumFractionDigits': decimals,
                'maximumFractionDigits': decimals
            }).replace(/,/g, ' ');

            if (value < 0) {
                return showSymbol ? `-${symbol} ${formattedValue}` : `-${formattedValue}`;
            }

            return showSymbol ? `${symbol} ${formattedValue}` : `${formattedValue}`;
        }

        if (displayDecimals) {
            return showSymbol ? `${symbol} 0.00` : '0.00';
        }

        return showSymbol ? `${symbol} 0` : '0';
    }

    eliminateZero(data: string): string {
        if (!data.startsWith('0')) {
            return data;
        } else {
            return this.eliminateZero(data.substring(1));
        }
    }

}

@Pipe({ name: 'thousandSeperator' })
export class ThousandSeperatorPipe implements PipeTransform {

    transform(value: any): string {
        if (!value) {
            if (value === 0 && value !== '') {
                return '0';
            } else {
                return '';
            }
        }
        let stringValue = value.toString().trim();
        while (stringValue.indexOf(' ') > 0) {
            stringValue = stringValue.replace(' ', '');
        }
        let regex = new RegExp('^[0-9]*$');
        let isDigit = regex.test(stringValue);

        if (isDigit) {
            return parseFloat(stringValue).toLocaleString('en').replace(/,/g, ' ');
        }

        return stringValue;
    }
}

@Pipe({
    name: 'myfilterForSplit',
    pure: false
})
export class MyFilterPipe implements PipeTransform {
    transform(items: string): any {

        return items.split(';');
    }
}


@Pipe({
    name: 'truncateUnderscore'
})
export class TruncateUnderscorePipe implements PipeTransform {
    transform(item: string): string {
        if (item && typeof (item) === 'string' && item.trim().startsWith('_')) {
            item = item.slice(1);

            return item;
        }
        if (item && typeof (item) === 'string') {
            return item.replace('_', ' ');
        }
        return item;
    }
}

@Pipe({
    name: 'removeAllUndescore'
})
export class RemoveAllUnderscore implements PipeTransform {
    transform(item: string): string {
        return item.replace(/_/g, ' ');
    }
}

@Pipe({
    name: 'truncateDecimal'
})
export class TruncateDecimalPipe implements PipeTransform {
    transform(item: string): string {
        let formattedString = item.split('.');
        let amountValue = formattedString[0];
        return amountValue;
    }
}


@Pipe({
    name: 'yearsPipe'
})
export class YearsPipe implements PipeTransform {
    transform(item: string): string {

        if (item.toLocaleLowerCase() === 'wol') {
            return 'whole of life';
        }

        if (Number.isNaN(parseInt(item))) {
            return item;
        }
        item = 'up to ' + item + ' years old';
        return item;
    }
}

@Pipe({
    name: 'groupBy',
    pure: true
})
export class GroupByPipe implements PipeTransform {
    transform(collection: any[], property: string): any[] {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!collection) {
            return null;
        }

        if (property.includes('.')) {
            let propArray = property.split('.');
            let propFirstItem = propArray[0];
            let propSecondItem = propArray[1];
            const groupedCollection = collection.reduce((previous, current) => {
                if (!previous[current[propFirstItem][propSecondItem]]) {
                    previous[current[propFirstItem][propSecondItem]] = [current];
                } else {
                    previous[current[propFirstItem][propSecondItem]].push(current);
                }

                return previous;
            }, {});
            let d = Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
            return d;
        }

        const groupedCollection = collection.reduce((previous, current) => {
            if (!previous[current[property]]) {
                previous[current[property]] = [current];
            } else {
                previous[current[property]].push(current);
            }

            return previous;
        }, {});

        // this will return an array of objects, each object containing a group of objects
        let d = Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
        return d;
    }
}

@Pipe({
    name: 'fillterBy'
})
export class FilterByPipe implements PipeTransform {
    transform(collection: any[], property: string, value: any): any[] {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!collection || !value) {
            return null;
        }

        if (property.includes('.')) {
            let propArray = property.split('.');
            let propFirstItem = propArray[0];
            let propSecondItem = propArray[1];
            if (typeof (value) === 'string') {
                return collection.filter(x => x[propFirstItem][propSecondItem].toString() === value);
            } else if (typeof (value) === 'object') {
                return collection.filter(x => value.includes(x[propFirstItem][propSecondItem]));
            }

        }


        if (typeof (value) === 'string') {
            return collection.filter(x => x[property].toString() === value);
        } else if (typeof (value) === 'object') {
            return collection.filter(x => value.includes(x[property]));
        }
    }
}



@Pipe({
    name: 'customsort'
})
export class ArraySortPipe implements PipeTransform {
    transform(array: any, field: string): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: any, b: any) => {
            const aVal = +(a[field].replace(/[^0-9\.]+/g, ''));
            const bVal = +(b[field].replace(/[^0-9\.]+/g, ''));

            if (aVal < bVal) {
                return -1;
            } else if (aVal > bVal) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}

