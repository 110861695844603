import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { FeatureCheckerService } from '@abp/features/feature-checker.service';
import { LocalizationService } from '@abp/localization/localization.service';
import { MessageService } from '@abp/message/message.service';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { NotifyService } from '@abp/notify/notify.service';
import { SettingService } from '@abp/settings/setting.service';
import { Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import { UiCustomizationSettingsDto, WearablesServiceProxy, SmsBody, ModifyClaimDto, EmailServiceProxy } from '@shared/service-proxies/service-proxies';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap';
import { ActionModalComponent } from '@app/shared/common/widgets/action-modal/action-modal.component';
import 'shared/extensions/common-extensions.component';
import 'shared/extensions/my-command-center-extensions';

export abstract class AppComponentBase {

    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    configModal: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
        animated: true,
        class: 'w-350px mt-48'
    };

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    _wearables: WearablesServiceProxy;
    _authGuard: AuthGuard;
    _authService: AuthService;
    _emailService: EmailServiceProxy;
    modalService: BsModalService;
    bsModalRef: any;

    constructor(injector: Injector) {
        this.modalService = injector.get(BsModalService);
        this._wearables = injector.get(WearablesServiceProxy);
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this._authGuard = injector.get(AuthGuard);
        this._authService = injector.get(AuthService);
        this._emailService = injector.get(EmailServiceProxy);
    }

    getNewModifyClaimRequest(newValue: string, newType: string): ModifyClaimDto {
        let modifyClaim = new ModifyClaimDto();
        modifyClaim.accessToken = this._authService.user.access_token;
        modifyClaim.userId = this._authGuard.user.profile.sub;
        modifyClaim.newClaimType = newType;
        modifyClaim.newClaimValue = newValue;
        modifyClaim.oldClaimType = 'allow_wearable';
        modifyClaim.oldClaimValue = 'false';
        return modifyClaim;
    }

    isMobileView_New() {
        if (window.innerWidth < 1025) {
            return true;
        } else {
            return false;
        }
    }


    openModalWithComponentGlobal(title, message, butttoname, imageNeeded?: boolean, actionButton?: string, callback?, textAlignLeft = false, messages?: string[], withToggle?: boolean) {
        const initialState = {
            list: [],
            title: title
        };
        this.bsModalRef = this.modalService.show(ActionModalComponent, this.configModal);
        this.bsModalRef.content.closeBtnName = butttoname || 'CLOSE';
        this.bsModalRef.content.title = title;
        this.bsModalRef.content.actionButtonName = actionButton;
        this.bsModalRef.content.icon = imageNeeded ? 'https://elevateme.blob.core.windows.net/publicimages/659889.svg' : null;
        this.bsModalRef.content.textAlignLeft = textAlignLeft;
        this.bsModalRef.content.message = message;
        this.bsModalRef.content.messages = messages;
        this.bsModalRef.content.withToggle = withToggle;

        this.bsModalRef.content.actionEvent.subscribe(res => {
            if (!callback) { return; }
            callback(res);
        });
    }

    getDeepCopied(param): any {
        return JSON.parse(JSON.stringify(param));
    }

    updateClaimsIdsvr(): Promise<void> {
        return new Promise<void>((resolve) => {
            this._authGuard.GetUserInfo(() => {
                abp.event.trigger('ClaimUpdate');
                resolve();
            }, true);
        });
    }

    isLoggedInAuth(): boolean {
        return this._authGuard.user && !this._authGuard.user.expired;
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }


    determineWindowsWidth() {
        if (window.innerWidth < 700) {
            return false;
        } else {
            return true;
        }
    }

    sendQueryEmail(smsBody: SmsBody) {
        this._wearables.sendDispute(smsBody).subscribe(res => {
            abp.notify.success('Sent Successfully!');
        });
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }
}
