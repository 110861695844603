var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ChangePasswordInput, PasswordComplexitySetting, ProfileServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
var ChangePasswordModalComponent = /** @class */ (function (_super) {
    __extends(ChangePasswordModalComponent, _super);
    function ChangePasswordModalComponent(injector, _profileService) {
        var _this = _super.call(this, injector) || this;
        _this._profileService = _profileService;
        _this.passwordComplexitySetting = new PasswordComplexitySetting();
        _this.saving = false;
        _this.active = false;
        return _this;
    }
    ChangePasswordModalComponent.prototype.show = function () {
        var _this = this;
        this.active = true;
        this.currentPassword = '';
        this.password = '';
        this.confirmPassword = '';
        this._profileService.getPasswordComplexitySetting().subscribe(function (result) {
            _this.passwordComplexitySetting = result.setting;
            _this.modal.show();
        });
    };
    ChangePasswordModalComponent.prototype.onShown = function () {
        document.getElementById('CurrentPassword').focus();
    };
    ChangePasswordModalComponent.prototype.close = function () {
        this.active = false;
        this.modal.hide();
    };
    ChangePasswordModalComponent.prototype.save = function () {
        var _this = this;
        var input = new ChangePasswordInput();
        input.currentPassword = this.currentPassword;
        input.newPassword = this.password;
        this.saving = true;
        this._profileService.changePassword(input)
            .pipe(finalize(function () { _this.saving = false; }))
            .subscribe(function () {
            _this.notify.info(_this.l('YourPasswordHasChangedSuccessfully'));
            _this.close();
        });
    };
    return ChangePasswordModalComponent;
}(AppComponentBase));
export { ChangePasswordModalComponent };
