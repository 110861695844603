var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, Injector, NgZone, ChangeDetectorRef, AfterViewInit, QueryList } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Last7Days, ClaimModifyServiceProxy, HumanAppsDto, SyncingHumanApiServiceServiceProxy, TokenClassDto, ModifyClaimDto, SmsBody } from '@shared/service-proxies/service-proxies';
import { IPoint } from '@amcharts/amcharts4/core';
import { MyWearablesService } from '@app/shared/services/my-wearables.service';
var MyWearablesComponent = /** @class */ (function (_super) {
    __extends(MyWearablesComponent, _super);
    function MyWearablesComponent(injector, zone, _claimModify, _syncingHumanApiServiceServiceProxy, _wearableService, _changeDetecter) {
        var _this = _super.call(this, injector) || this;
        _this.zone = zone;
        _this._claimModify = _claimModify;
        _this._syncingHumanApiServiceServiceProxy = _syncingHumanApiServiceServiceProxy;
        _this._wearableService = _wearableService;
        _this._changeDetecter = _changeDetecter;
        _this.ststs = true;
        _this.chartFilterTimePeriod = [{
                id: 1,
                Name: 'Week',
                IsSelected: true
            },
            {
                id: 2,
                Name: 'Month',
                IsSelected: false
            },
            {
                id: 3,
                Name: 'Year',
                IsSelected: false
            }];
        _this.ipoint = { x: 0, y: 1 };
        _this.activeCalories = new Array();
        _this.imgSrc = '../../../assets/common/images/chatblack.svg';
        _this.Humanappdto = new HumanAppsDto();
        _this.saving = false;
        _this.chartXAxistest = 'LAST 7 DAYS';
        _this.wearableenum = true;
        _this.activeMinues = new Array();
        _this.distanceDto = new Array();
        _this.stepsDto = new Array();
        _this.getAllSyncedAct = new Array();
        _this.last7Days = new Last7Days();
        _this.mlist = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        _this.ifActivated = undefined;
        _this.goalsMessages = new Array();
        _this.goalsMessage = '';
        _this.vo2change = true;
        _this.distance = true;
        _this.MyActiveMinval = true;
        _this.heartRatechange = true;
        _this.fitBitchange = true;
        return _this;
    }
    MyWearablesComponent.prototype.ngOnInit = function () {
        formComponent = this;
        abphc = abp;
        this.showchart = 'week';
        this.getPageData();
    };
    MyWearablesComponent.prototype.getChartsForWeek = function () {
        var _this = this;
        this._changeDetecter.detectChanges();
        if (this.widgetmyactiveminutes.first || this.widgetmytotaldistance.first || this.appwidgetmyactivecalories.first || this.appwidgetmysteps.first) {
            this.callWetherTherisNoChange();
            return;
        }
        this.widgetmyactiveminutes.changes.subscribe(function (comps) {
            _this.widgetMyActiveMinutesComponent = comps.first;
            _this.widgetMyActiveMinutesComponent.getActiveMinutesWeekOrMonth(_this.chartXAxistest, _this.showchart).then(function () {
                abp.ui.clearBusy();
            });
        });
        this.widgetmytotaldistance.changes.subscribe(function (comps) {
            _this.widgetMyTotalDistanceComponent = comps.first;
            _this.widgetMyTotalDistanceComponent.getDistanceTravelledWeek(_this.chartXAxistest, _this.showchart).then(function () {
                abp.ui.clearBusy();
            });
        });
        this.appwidgetmysteps.changes.subscribe(function (comps) {
            _this.widgetMyStepsComponent = comps.first;
            _this.widgetMyStepsComponent.getStepsCurrentWeek(_this.chartXAxistest, _this.showchart).then(function () {
                abp.ui.clearBusy();
            });
        });
        this.appwidgetmyactivecalories.changes.subscribe(function (comps) {
            _this.widgetMyActiveCaloriesComponent = comps.first;
            _this.widgetMyActiveCaloriesComponent.getActiveCalorieWeek(_this.chartXAxistest, _this.showchart).then(function () {
                abp.ui.clearBusy();
            });
        });
    };
    MyWearablesComponent.prototype.callWetherTherisNoChange = function () {
        this._changeDetecter.detectChanges();
        this.widgetMyActiveMinutesComponent = this.widgetmyactiveminutes.first;
        this.widgetMyTotalDistanceComponent = this.widgetmytotaldistance.first;
        this.widgetMyActiveCaloriesComponent = this.appwidgetmyactivecalories.first;
        this.widgetMyStepsComponent = this.appwidgetmysteps.first;
        Promise.all([
            this.widgetMyActiveMinutesComponent.getActiveMinutesWeekOrMonth(this.chartXAxistest, this.showchart),
            this.widgetMyTotalDistanceComponent.getDistanceTravelledWeek(this.chartXAxistest, this.showchart),
            this.widgetMyActiveCaloriesComponent.getActiveCalorieWeek(this.chartXAxistest, this.showchart),
            this.widgetMyStepsComponent.getStepsCurrentWeek(this.chartXAxistest, this.showchart)
        ]).then(function () {
            abp.ui.clearBusy();
        });
    };
    MyWearablesComponent.prototype.getNonYearFilterData = function () {
        var _this = this;
        this.appwidgetlastsevendays.changes.subscribe(function (comps) {
            _this.widgetLastSevenDaysComponent = comps.first;
            _this.widgetLastSevenDaysComponent.getLast7Async();
        });
        this.appwidgetmysyncedactivities.changes.subscribe(function (comps) {
            _this.widgetMySyncedActivitiesComponent = comps.first;
            _this.widgetMySyncedActivitiesComponent.getAllSyncedIntenseCount();
        });
    };
    MyWearablesComponent.prototype.callWetherTherisNoChangeNonYearFilterData = function () {
        this.widgetMySyncedActivitiesComponent = this.appwidgetmysyncedactivities.first;
        this.widgetMySyncedActivitiesComponent.getAllSyncedIntenseCount();
        this.widgetLastSevenDaysComponent = this.appwidgetlastsevendays.first;
        this.widgetLastSevenDaysComponent.getLast7Async();
    };
    MyWearablesComponent.prototype.ngAfterViewInit = function () {
    };
    MyWearablesComponent.prototype.getPageData = function () {
        var _this = this;
        abp.ui.setBusy();
        this.updateClaimsIdsvr().then(function () {
            _this._wearableService.getGoalsAndCheckIfEntityRegistered().then(function (res) {
                abp.ui.clearBusy();
                if (_this._wearableService.isMyDataWearableActive() === true) {
                    _this.getChartsForWeek();
                    if (_this.appwidgetmysyncedactivities.first || _this.appwidgetlastsevendays.first) {
                        _this.callWetherTherisNoChangeNonYearFilterData();
                        return;
                    }
                    _this.getNonYearFilterData();
                }
                else {
                    abp.ui.clearBusy();
                }
            });
        });
    };
    MyWearablesComponent.prototype.defaultFilter = function (id) {
        switch (id) {
            case 1: {
                this.weekChange();
                break;
            }
            case 2: {
                this.monthChange();
                break;
            }
            case 3: {
                this.yearChange();
                break;
            }
            default: {
                this.weekChange();
                break;
            }
        }
        this.chartFilterTimePeriod.forEach(function (x) {
            x.IsSelected = false;
        });
    };
    MyWearablesComponent.prototype.initializeChart = function () {
    };
    MyWearablesComponent.prototype.determineWindowsWidth = function () {
        if (window.innerWidth < 700) {
            return false;
        }
        else {
            return true;
        }
    };
    MyWearablesComponent.prototype.scrollToTop = function () {
        window.scrollTo(0, 0);
    };
    MyWearablesComponent.prototype.ActivateUserOnConnect = function () {
        var _this = this;
        this.getPageData();
        this.updateClaimsIdsvr().then(function () {
            _this._wearableService.getGoalsAndCheckIfEntityRegistered();
        });
    };
    MyWearablesComponent.prototype.getIfActivated = function () {
        this.getPageData();
    };
    MyWearablesComponent.prototype.weekChange = function () {
        this.chartXAxistest = 'LAST 7 DAYS ';
        this.showchart = 'week';
        this.getChartsForWeek();
    };
    MyWearablesComponent.prototype.monthChange = function () {
        this.chartXAxistest = 'LAST 4 WEEKS';
        this.showchart = 'month';
        this.getWearableMonth();
    };
    MyWearablesComponent.prototype.yearChange = function () {
        this.chartXAxistest = 'LAST 12 MONTHS';
        this.showchart = 'year';
        this.getWearablesYear();
    };
    MyWearablesComponent.prototype.getAccessToken = function () {
        return this._authService.user.access_token;
    };
    MyWearablesComponent.prototype.getWearables = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.getAccessToken() !== undefined) {
                }
                return [2 /*return*/];
            });
        });
    };
    MyWearablesComponent.prototype.getWearableMonth = function () {
        Promise.all([
            this.widgetMyActiveCaloriesComponent.getActiveCalorieWeek(this.chartXAxistest, this.showchart),
            this.widgetMyActiveMinutesComponent.getActiveMinutesWeekOrMonth(this.chartXAxistest, this.showchart),
            this.widgetMyTotalDistanceComponent.getDistanceTravelledWeek(this.chartXAxistest, this.showchart),
            this.widgetMyStepsComponent.getStepsCurrentWeek(this.chartXAxistest, this.showchart)
        ]).then(function () {
            abp.ui.clearBusy();
        });
    };
    MyWearablesComponent.prototype.getWearablesYear = function () {
        if (this.getAccessToken() !== undefined) {
            Promise.all([
                this.widgetMyActiveCaloriesComponent.getActiveCalorieYear(this.chartXAxistest, this.showchart),
                this.widgetMyActiveMinutesComponent.getActiveMinutesYear(this.chartXAxistest, this.showchart),
                this.widgetMyTotalDistanceComponent.getDistanceTravelledYear(this.chartXAxistest, this.showchart),
                this.widgetMyStepsComponent.getStepsCurrentYear(this.chartXAxistest, this.showchart)
            ]).then(function () {
                abp.ui.clearBusy();
            });
        }
        else {
            abp.ui.clearBusy();
        }
    };
    MyWearablesComponent.prototype.ConnectHumanApi = function () {
        var _this = this;
        var entityNumber = this._authGuard.user.profile.sub;
        var serverAdd = abp.setting.get('ServerRootAddress');
        this.saving = true;
        var wd = new ModifyClaimDto();
        if (this._authGuard.user.profile.allow_wearable === 'false') {
            this.ifActivated = true;
            wd.accessToken = this._authService.user.access_token;
            wd.userId = this._authGuard.user.profile.sub;
            wd.newClaimType = 'allow_wearable';
            wd.newClaimValue = 'true';
            wd.oldClaimType = 'allow_wearable';
            wd.oldClaimValue = 'false';
            this._claimModify.modifyClaim(wd).subscribe(function (res) {
                _this.updateClaimsIdsvr();
                _this._syncingHumanApiServiceServiceProxy.getPublicAccessTokenAsync(_this._authService.user.access_token).subscribe(function (result) {
                    _this.publicToken = result.publicToken;
                    HumanApiConnect(serverAdd, entityNumber, _this.publicToken, _this._authService.user.access_token);
                    _this.getPageData();
                });
            });
        }
        else {
            this._syncingHumanApiServiceServiceProxy.getPublicAccessTokenAsync(this._authService.user.access_token).subscribe(function (result) {
                _this.publicToken = result.publicToken;
                HumanApiConnect(serverAdd, entityNumber, _this.publicToken, _this._authService.user.access_token);
            });
        }
    };
    MyWearablesComponent.prototype.registerEntity = function (err, sessionTokenObject) {
        var _this = this;
        abphc.ui.setBusy();
        var tdto = new TokenClassDto();
        tdto.accessToken = this._authService.user.access_token;
        tdto.clientId = sessionTokenObject.clientId;
        tdto.entityNumber = this._authGuard.user.profile.sub;
        tdto.humanId = sessionTokenObject.humanId;
        tdto.userId = sessionTokenObject.userId;
        tdto.sessionToken = sessionTokenObject.sessionToken;
        tdto.birthDate = this._authGuard.user.profile.birthdate;
        tdto.gender = this._authGuard.user.profile.gender;
        this._syncingHumanApiServiceServiceProxy.registerEntity(tdto).subscribe(function (res) {
            if (res) {
                _this.ifActivated = res;
                var wd = new ModifyClaimDto();
                wd.accessToken = _this._authService.user.access_token;
                wd.userId = _this._authGuard.user.profile.sub;
                wd.newClaimType = 'allow_wearable';
                wd.newClaimValue = 'true';
                wd.oldClaimType = 'allow_wearable';
                wd.oldClaimValue = 'false';
                _this._claimModify.modifyClaim(wd).subscribe(function (res) {
                    _this.updateClaimsIdsvr();
                    abp.ui.clearBusy();
                    _this.getPageData();
                    _this.callCharts(true);
                    abp.message.success('Please note that activity data can take upto <strong>1 hour</strong> to be visualized.', 'Wearable data source activated!', true);
                    var d = document.getElementsByClassName('swal2-confirm');
                    d[0].classList.add('modal-button-clr');
                    d[0].classList.add('border-radius-55');
                    d[0].innerText = 'CLOSE';
                });
            }
        }, function (err) {
            abp.ui.clearBusy();
        });
    };
    MyWearablesComponent.prototype.callCharts = function (item) {
    };
    MyWearablesComponent.prototype.callOnClose = function (item) {
        var _this = this;
        this._syncingHumanApiServiceServiceProxy.isEntityRegistered(this._authService.user.access_token).subscribe(function (result) {
            if (!result) {
                var wd = new ModifyClaimDto({
                    accessToken: _this._authService.user.access_token,
                    newClaimType: 'allow_wearable',
                    newClaimValue: 'false',
                    oldClaimType: 'allow_wearable',
                    oldClaimValue: 'true',
                    userId: _this._authGuard.user.profile.sub,
                    userClaimEditDtos: null
                });
                _this._claimModify.modifyClaim(wd).subscribe(function (res) {
                    _this.updateClaimsIdsvr();
                });
            }
        });
    };
    MyWearablesComponent.prototype.sendsms = function (PageName) {
        var body = new SmsBody();
        body.userid = this._authGuard.user.profile.sub;
        body.firstName = this._authGuard.user.profile.given_name;
        body.lastName = this._authGuard.user.profile.family_name;
        body.email = this._authGuard.user.profile.email;
        body.phoneNumber = Array.isArray(this._authGuard.user.profile.phone_number) ? this._authGuard.user.profile.phone_number[0] : this._authGuard.user.profile.phone_number;
        body.pageName = PageName;
        body.message = this.currentUserQuery;
        this.sendQueryEmail(body);
        this.currentUserQuery = '';
    };
    return MyWearablesComponent;
}(AppComponentBase));
export { MyWearablesComponent };
