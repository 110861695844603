import { IAjaxResponse } from '@abp/abpHttpInterceptor';
import { TokenService } from '@abp/auth/token.service';
import { Component, Injector, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileServiceProxy, UpdateProfilePictureInput, ProfilePictureDto, UserServiceProxy, ModifyClaimDto, ClaimModifyServiceProxy } from '@shared/service-proxies/service-proxies';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Profile } from 'oidc-client';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'changeProfilePictureModal',
    templateUrl: './change-profile-picture-modal.component.html'
})
export class ChangeProfilePictureModalComponent extends AppComponentBase {

    @ViewChild('changeProfilePictureModal') modal: ModalDirective;

    public active = false;
    public uploader: FileUploader;
    public temporaryPictureUrl: string;
    public saving = false;

    private maxProfilPictureBytesUserFriendlyValue = 5;
    private temporaryPictureFileName: string;
    private _uploaderOptions: FileUploaderOptions = {};
    profileChange: ProfilePictureDto = new ProfilePictureDto();
    imageChangedEvent: any = '';
    inValidExt = false;
    profilePicture: any;

    constructor(
        injector: Injector,
        private _userService: UserServiceProxy,
        private _claims: ClaimModifyServiceProxy
    ) {
        super(injector);
    }

    initializeModal(): void {
        this.active = true;
        this.temporaryPictureUrl = '';
        this.temporaryPictureFileName = '';
        this.inValidExt = false;
        this.profilePicture = '';
        this.getProfilePicture();
    }

    show(): void {
        this.initializeModal();
        this.modal.show();
    }

    close(): void {
        this.active = false;
        this.imageChangedEvent = '';
        this.modal.hide();
    }

    getProfilePicture(): void {
        this._authGuard.GetUserInfo((res: Profile) => {
            this.profilePicture = res.picture;
            if (this.profilePicture === undefined || this.profilePicture == null || this.profilePicture === '') {
                this.profilePicture = '';
            }
        });
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        if (this.imageChangedEvent.target.files.length > 0) {
            let selectedFile = this.imageChangedEvent.target.files[0];
            let idxDot = selectedFile.name.lastIndexOf('.') + 1;
            let extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png' || extFile === 'svg') {
                this.inValidExt = false;
            } else {
                this.imageChangedEvent = '';
                this.inValidExt = true;
            }
        } else {
            this.imageChangedEvent = '';
            this.inValidExt = false;
        }
    }

    save(): void {
        this.saving = true;
        this.profileChange.fileName = this._authGuard.user.profile.sub;
        this.profileChange.claimData = new ModifyClaimDto();
        this.profileChange.claimData.accessToken = this._authService.user.access_token;
        this.profileChange.claimData.oldClaimType = 'picture';
        this.profileChange.claimData.newClaimType = 'picture';
        this.profileChange.claimData.userId = this._authGuard.user.profile.sub;
        this.profileChange.claimData.oldClaimValue = this._authGuard.user.profile.picture;

        this._userService.updateProfilePicture(this.profileChange)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(res => {
                if (res) {
                    abp.event.trigger('profilePictureChanged');
                    this.close();
                    abp.notify.success('Profile Picture Changed');
                } else {
                    this.close();
                    abp.notify.error('Profile Picture not changed.');
                }
            }, (err) => {
                abp.event.trigger('profilePictureChanged');
                this.close();
            });
    }

    remove() {
        if (this.imageChangedEvent) {
            this.imageChangedEvent.srcElement.value = '';
            this.imageChangedEvent = '';
        } else if (this.profilePicture) {
            this.profileChange.claimData = new ModifyClaimDto();
            this.profileChange.claimData.accessToken = this._authService.user.access_token;
            this.profileChange.claimData.oldClaimType = 'picture';
            this.profileChange.claimData.newClaimType = 'picture';
            this.profileChange.claimData.userId = this._authGuard.user.profile.sub;
            this.profileChange.claimData.oldClaimValue = this._authGuard.user.profile.picture;
            this.profileChange.claimData.newClaimValue = '';
            this._claims.modifyClaim(this.profileChange.claimData).subscribe(res => {
                this.modal.hide();
                abp.event.trigger('profilePictureChanged');
                abp.notify.success('Profile Picture Removed');
            });
        }
    }

    fileUpload(file: File) {
        let reader = new FileReader();
        reader.onloadend = (e: any) => {
            this.profileChange.base64 = e.target.result;
        };
        reader.readAsDataURL(file);
    }
}
