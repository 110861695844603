<div bsModal #changeProfilePictureModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #changeProfilePictureModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>CHANGE PROFILE PICTURE</span>
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <input type="file" (change)="fileChangeEvent($event)" accept="image/*"/> 
                        <span class="help-block m-b-none">{{"ProfilePicture_Change_Info" | localize:maxProfilPictureBytesUserFriendlyValue}}</span>
                    </div>
                    <label *ngIf="inValidExt" class="customValidation">*Please select a JPG/JPEG/PNG file</label>
                    <image-cropper *ngIf="imageChangedEvent"
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="4 / 4"
                        [resizeToWidth]="128"
                        format="png"
                        (imageCroppedFile)="fileUpload($event)"
                    ></image-cropper>
                    <img *ngIf="profilePicture && !imageChangedEvent" [src]="profilePicture" height="100" width="100"/>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="remove()" [disabled]="!imageChangedEvent && !profilePicture">REMOVE</button> 
                    <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving">CLOSE</button>
                    <button type="submit" class="btn btn-danger" [disabled]="!changeProfilePictureModalForm.form.valid || saving || !imageChangedEvent"><span>SAVE</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
