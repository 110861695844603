import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HttpMethodBaseService = /** @class */ (function () {
    function HttpMethodBaseService(http) {
        this.http = http;
    }
    HttpMethodBaseService.prototype.get = function (url, headers) {
        return this.http.get(url, { headers: headers });
    };
    HttpMethodBaseService.prototype.post = function (url, body, headers) {
        return this.http.post(url, body, { headers: headers });
    };
    HttpMethodBaseService.prototype.put = function (url, body, headers) {
        return this.http.put(url, body, { headers: headers });
    };
    HttpMethodBaseService.prototype.delete = function (url, headers) {
        return this.http.delete(url, { headers: headers });
    };
    HttpMethodBaseService.prototype.request = function (request, url, body, headers) {
        return this.http.request(request, url, { body: body, headers: headers });
    };
    HttpMethodBaseService.ngInjectableDef = i0.defineInjectable({ factory: function HttpMethodBaseService_Factory() { return new HttpMethodBaseService(i0.inject(i1.HttpClient)); }, token: HttpMethodBaseService, providedIn: "root" });
    return HttpMethodBaseService;
}());
export { HttpMethodBaseService };
