import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponseBase, HttpResponse } from '@angular/common/http';
import { forkJoin, of, Subject } from 'rxjs';
import { mergeMap as _observableMergeMap, catchError as _observableCatch, catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { SwaggerException, QuirkServiceProxy, GetQuestionAnswerTypeOutPut, CreateOrUpdateApplicant, Customer, UpdatePolicyOfApplicantWithAnswers } from '@shared/service-proxies/service-proxies';
import { AccessTokenProvider } from './accessTokenProvider';
import { AppConsts } from '@shared/AppConsts';
import { PolicyModule, CreateOrUpdatePolicyInputModule, CreateOrUpdatePolicyDtoOutpuModel, CreateOrUpdateApplicantDtoOutpuModel, ConditionListModule, SubmitAnswerAndGetUpdatedQuestions, GetQuestionariesModule, CreateQuote, QuirkQuestionsDictonaryModule, SubmitQuestionnaireModule, PolicyStatusChangeModule, Brokerage, BrokerageResult } from '../models_elevate/models';
import { KeyedCollection } from 'shared/utils/dictonary-helper';
import { AnonymousUserDetail } from './quote.service';
import { QuestionType } from '@shared/AppEnums';
import { SocialEconomicModule } from '../../../shared/models/interfaces/elevate-data-models';
import { HttpMethodBaseService } from './HttpMethodsBase/httpmethods-base';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';

//#region newcode

const ACCESS_TOKEN = '';

@Injectable({
    providedIn: 'root'
})
export class QuirkService {
    readonly API_BASE_QUIRK_URL = abp.setting.values[AppConsts.KeyVaultSecrets.quirkRootUrl];
    readonly CEDANT_ID = abp.setting.values[AppConsts.KeyVaultSecrets.cedantId];
    readonly ELEVATE_ACCP_ENDPOINT = abp.setting.values[AppConsts.KeyVaultSecrets.apimElevateAccpendpoint];
    readonly APIM_CLIENT_ENDPOINT = abp.setting.values[AppConsts.KeyVaultSecrets.apimClientEndpoint];

    public cachedAllQuestionResponse = new Map();
    allQuestionsNeedToBePresented: QuirkQuestionsDictonaryModule.Value[] = [];
    public policyId: number;
    hasLoadedConditions: boolean;
    policies: Array<PolicyModule.IPolicyListResult>;

    constructor(private httpMethodBaseService: HttpMethodBaseService,
        private _authService: AuthService) {
    }

    conditionsSubject: Subject<boolean> = new Subject<boolean>();

    init() {
        this.getQuestionnaireProfileVersion();
        this.getKeyToValueMapping('Personal Info');
        this.getSocioEconomicDimension();
    }

    getSocioEconomicDimension(): SocialEconomicModule.SocialEconomic[] {
        let endPoint = abp.setting.values[AppConsts.KeyVaultSecrets.QuirkApiDetailsQuestionMapping] + AppConsts.Endpoints.socioEconomicDimension;

        let socioResponse = this.cachedAllQuestionResponse.get(endPoint);
        if (socioResponse) {
            return socioResponse;
        }

        this.httpMethodBaseService.get<SocialEconomicModule.SocialEconomic[]>(endPoint)
            .subscribe(res => {
                this.cachedAllQuestionResponse.set(endPoint, res);
                return res;
            });
    }

    getLatestPolicy(userEntityGuid): Observable<PolicyModule.IPolicyListResult> {
        let _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getPolicyList;

        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        return this.httpMethodBaseService.post<PolicyModule.IGetAllPolicyList>(_url,
            {
                CustomerReferenceId: userEntityGuid,
                ShowErrorDetails: true
            }, headers).pipe(
                map((response: PolicyModule.IGetAllPolicyList) => {
                    this.policies = response.PolicyListResult;
                    if (response.NumberOfPolicies === 0) { return null; }
                    const policyList = response.PolicyListResult.reverse()[0];
                    if (policyList.AcceptedStatusName === 'TakenUp' || policyList.AcceptedStatusName === 'CaptureError') { return null; }
                    return policyList;
                })
            );
    }

    getPolicies(userEntityGuid: string): Observable<Array<PolicyModule.IPolicyListResult>> {
        let _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getPolicyList;

        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });

        return this.httpMethodBaseService.post<PolicyModule.IGetAllPolicyList>(_url, {
            CustomerReferenceId: userEntityGuid,
            ShowErrorDetails: true
        }, headers).pipe(
            map((response: PolicyModule.IGetAllPolicyList) => {
                return response.PolicyListResult;
            })
        );
    }

    getPolicy(userEntityGuid: string, quoteGuid: string): Observable<PolicyModule.IPolicyListResult> {
        let _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getPolicyList;

        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        return this.httpMethodBaseService.post<PolicyModule.IGetAllPolicyList>(_url,
            {
                CustomerReferenceId: userEntityGuid,
                ShowErrorDetails: true
            }, headers).pipe(
                map((response: PolicyModule.IGetAllPolicyList) => {
                    if (response.NumberOfPolicies === 0) {
                        return null;
                    }

                    this.policies = response.PolicyListResult;
                    const policy = response.PolicyListResult.find(p => p.ExternalReferenceId === quoteGuid);

                    if (!policy) {
                        return null;
                    }

                    return policy;
                })
            );
    }

    createOrUpdatePolicy(updatePolicyOfApplicantWithAnswers:
        CreateOrUpdatePolicyInputModule.IUpdatePolicyOfApplicantWithAnswers, isCached):
        Observable<CreateOrUpdatePolicyDtoOutpuModel.ICreateOrUpdatePolicyDto> {

        let _headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });

        let cachedQuestions = this.cachedAllQuestionResponse.get('UpdatedQuestion');
        if (isCached && cachedQuestions) {
            return of(cachedQuestions);
        }

        return this.httpMethodBaseService.post(this.API_BASE_QUIRK_URL + AppConsts.Endpoints.createOrUpdatePolicy, updatePolicyOfApplicantWithAnswers,
            _headers).pipe(map((response: CreateOrUpdatePolicyDtoOutpuModel.ICreateOrUpdatePolicyDto) => {
                this.cachedAllQuestionResponse.set('UpdatedQuestion', response);
                return response;
            }));
    }

    createOrUpdateApplicant(createOrUpdateApplicantDtoOutpuModel:
        CreateOrUpdateApplicantDtoOutpuModel.ICreateOrUpdateApplicant) {
        let _headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });

        return this.httpMethodBaseService.post(this.API_BASE_QUIRK_URL + AppConsts.Endpoints.createOrUpdateApplicant, createOrUpdateApplicantDtoOutpuModel,
            _headers
        ).pipe(map((response: any) => {
            return response;
        }));
    }

    getBrokerage(brokerageGuid: string): Observable<Brokerage> {
        const url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getBrokerage.replace('{BrokerageGUID}', brokerageGuid);

        let cachedBrokerage = this.cachedAllQuestionResponse.get(url);

        if (cachedBrokerage) {
            return of(cachedBrokerage);
        }

        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });

        return this.httpMethodBaseService.get<BrokerageResult>(url, headers).pipe(map((brokerage: BrokerageResult) => {
            this.cachedAllQuestionResponse.set(url, brokerage.result);
            return brokerage.result;
        }));
    }

    getConditionsFromQuestion(externalReferenceId: string): Observable<ConditionListModule.IDatum[]> {
        let _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getConditionList;
        const conditionsFromCache = this.cachedAllQuestionResponse.get(_url + externalReferenceId);
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        if (conditionsFromCache) {
            return of(conditionsFromCache);
        }

        let response = this.httpMethodBaseService.post<ConditionListModule.IConditionOutPut>(_url,
            {
                CedantId: parseInt(this.CEDANT_ID),
                ExternalReferenceId: externalReferenceId,
                ShowErrorDetails: '1'
            }, headers).pipe(
                map((response: ConditionListModule.IConditionOutPut) => {
                    return response.Data;
                })
            );

        response.pipe(
            tap(conditionsList => this.cachedAllQuestionResponse.set(_url + externalReferenceId, conditionsList))
        );

        return response;
    }

    getOccupationList(externalReferenceId: string) {
        let _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getConditionList;
        const conditionsFromCache = this.cachedAllQuestionResponse.get(_url);
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        if (conditionsFromCache) {
            return of(conditionsFromCache);
        }

        let response = this.httpMethodBaseService.post<ConditionListModule.IConditionOutPut>(_url,
            {
                CedantId: parseInt(this.CEDANT_ID),
                ExternalReferenceId: externalReferenceId,
                ShowErrorDetails: '1'
            }, headers).pipe(
                map((response: ConditionListModule.IConditionOutPut) => {
                    return response.Data;
                })
            );
        response.subscribe(occupationList => this.cachedAllQuestionResponse.set(_url, occupationList));
        return response;
    }

    submitProcessQuestionaryAndProvideAsperDictonaryMapping(submitQuestionAndAnswers: SubmitAnswerAndGetUpdatedQuestions, userDetails?: AnonymousUserDetail, isCached?): Observable<{ extId: string, questions: GetQuestionariesModule.IQuestion[] }[]> {

        return this.getQuestionForStepper(submitQuestionAndAnswers.sections.ExternalReferenceId, ACCESS_TOKEN,
            submitQuestionAndAnswers.customerReferenceid,
            submitQuestionAndAnswers.policyReferenceId, this.getAnsweredQuestions(submitQuestionAndAnswers.sections, userDetails), isCached).pipe(map(res => {
                return res;
            }));

    }

    getQuestionForStepper(sectionName,
        accessToken,
        customerReferenceId,
        policyReferenceId,
        answereQuestions: CreateOrUpdatePolicyInputModule.IAnsweredQuestion[], isCached):
        Observable<{ extId: string, questions: GetQuestionariesModule.IQuestion[] }[]> {
        return this.createOrUpdatePolicy({
            AnsweredQuestions: answereQuestions,
            CedantId: this.CEDANT_ID,
            CustomerReferenceid: customerReferenceId,
            DisplayAnswers: 'true',
            PolicyReferenceId: policyReferenceId,
            ProcessDisclosures: 'true',
            ShowErrorDetails: '1'
        }, isCached).pipe(map((res: CreateOrUpdatePolicyDtoOutpuModel.ICreateOrUpdatePolicyDto) => {
            this.policyId = res.PolicyDetail.PolicyId;
            return this.processGetQuestionForStepper(res.PolicyDetail, sectionName);
        }));
    }

    getQuestionById(id: number[]): GetQuestionariesModule.IQuestion[] {
        let _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getQuestionnaireProfileVersion;
        let res = JSON.parse(this.cachedAllQuestionResponse.get(_url)) as GetQuestionariesModule.IGetQuestionsForSectionOutputDto;

        let sectionToplayer = res.QuestionDetails.Sections;

        let result1 = (sectionToplayer.map(({ Questions }) => ({ Questions }.Questions.filter(x => id.some(a => a === x.Id)))) as any).customFlat() as GetQuestionariesModule.IQuestion[];
        let result2 = (sectionToplayer.map(({ Sections }) => ({ Sections }.Sections.map(({ Questions }) => ({ Questions }.Questions.filter(x => id.some(a => a === x.Id)))))) as any).customFlat().customFlat() as GetQuestionariesModule.IQuestion[];
        let result3Temp = (sectionToplayer.map(({ Sections }) => ({ Sections }.Sections.map(({ Sections }) => ({ Sections }.Sections)))) as any).customFlat().customFlat() as GetQuestionariesModule.ISection[];

        let result3 = (result3Temp.map(({ Questions }) => ({ Questions }.Questions.filter(x => id.some(a => a === x.Id)))) as any).customFlat() as GetQuestionariesModule.IQuestion[];

        if (result1 && result1.length) {
            return result1.setQuestionType();
        } else if (result2 && result2.length) {
            return result2.setQuestionType();
        } else if (result3 && result3.length) {
            return result3.setQuestionType();
        } else {
            return null;
        }
    }

    checkQuestionPresent(item: string) {
        return this.allQuestionsNeedToBePresented.some(x => x.extId === item.toLowerCase() || x.extId === item.toUpperCase());
    }

    getQuestionByExtReferenceId(extReferenceId: string[]): GetQuestionariesModule.IQuestion[] {
        let _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getQuestionnaireProfileVersion;
        let res = JSON.parse(this.cachedAllQuestionResponse.get(_url)) as GetQuestionariesModule.IGetQuestionsForSectionOutputDto;

        let sectionToplayer = res.QuestionDetails.Sections as any;
        res = undefined;

        let result1 = (sectionToplayer.map(({ Questions }) => ({ Questions }.Questions.filter(x => extReferenceId.some(a => a === x.ExternalReferenceId)))) as any).customFlat() as GetQuestionariesModule.IQuestion[];
        let result2 = (sectionToplayer.map(({ Sections }) => ({ Sections }.Sections.map(({ Questions }) => ({ Questions }.Questions.filter(x => extReferenceId.some(a => a === x.ExternalReferenceId)))))) as any).customFlat().customFlat() as GetQuestionariesModule.IQuestion[];
        let result3Temp = (sectionToplayer.map(({ Sections }) => ({ Sections }.Sections.map(({ Sections }) => ({ Sections }.Sections)))) as any).customFlat().customFlat() as GetQuestionariesModule.ISection[];

        let result3 = (result3Temp.map(({ Questions }) => ({ Questions }.Questions.filter(x => extReferenceId.some(a => a === x.ExternalReferenceId)))) as any).customFlat() as GetQuestionariesModule.IQuestion[];

        if (result1 && result1.length) {
            return <GetQuestionariesModule.IQuestion[]>result1.setQuestionType();
        } else if (result2 && result2.length) {
            return <GetQuestionariesModule.IQuestion[]>result2.setQuestionType();
        } else if (result3 && result3.length) {
            return <GetQuestionariesModule.IQuestion[]>result3.setQuestionType();
        } else {
            return null;
        }
    }

    processGetQuestionForStepper(policyDetails:
        CreateOrUpdatePolicyDtoOutpuModel.IPolicyDetail, sectionName): { extId: string, questions: GetQuestionariesModule.IQuestion[] }[] {

        let keyValueMappingDictonary = this.getKeyToValueMapping(sectionName);


        let filteredPerSection = policyDetails.AnsweredQuestions.filter(x => keyValueMappingDictonary.ContainsKey(x.ExternalReferenceId.toLowerCase()));

        let keyValueSet = this.handleAnsweredQuestions(filteredPerSection, keyValueMappingDictonary);

        let filteredPerSectionQuestion = policyDetails.UnAnsweredQuestions.filter(x => keyValueMappingDictonary.ContainsKey(x.ExternalReferenceId.toLowerCase()));

        keyValueSet = this.handleUnAnsweredQuestions(filteredPerSectionQuestion, keyValueMappingDictonary, keyValueSet);

        return keyValueSet.getList();

    }


    //#region Helper Methods

    /**
     * comment practicing for code readability
     * Create A Quote Or Update A Quote
     * @param createQuote
     * @param quoteGUid
     */
    CreateOrUpdateQuote(createQuote: CreateQuote, quoteGUid?: string): Observable<string> {
        let _url = '';
        let request = '';
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        if (quoteGUid) {
            _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.updateQuote.replace('{Quote_GUID}', quoteGUid);
            request = 'put';
        } else {
            _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.createQuote;
            request = 'post';
        }

        return this.httpMethodBaseService.request<any>(request, _url, createQuote, headers)
            .pipe(map((response) => {
                return response.result.GUID;
            }));
    }

    getPolicySchedule(customerReferenceid: string, policyExternalReferenceId: string): Observable<SubmitQuestionnaireModule.SubmitQuestionnaire> {
        const url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getPolicySchedule;
        const body = {
            CedantId: this.CEDANT_ID,
            ShowErrorDetails: '1',
            CustomerReferenceid: customerReferenceid,
            PolicyReferenceId: policyExternalReferenceId
        };

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });

        return this.httpMethodBaseService.post<SubmitQuestionnaireModule.SubmitQuestionnaire>(url, body, headers);
    }

    private getQuestionnaireProfileVersion(): Observable<GetQuestionariesModule.IGetQuestionsForSectionOutputDto> {

        let _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getQuestionnaireProfileVersion;
        let alreadyCachedResult = this.cachedAllQuestionResponse.get(_url);
        if (alreadyCachedResult) {
            return of(JSON.parse(alreadyCachedResult));
        }

        let _body = {
            CedantId: this.CEDANT_ID,
            ShowErrorDetails: '1'
        };

        let _headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });

        let response = this.httpMethodBaseService.post<GetQuestionariesModule.IGetQuestionsForSectionOutputDto>(_url, _body,
            _headers
        ).pipe(map((response: GetQuestionariesModule.IGetQuestionsForSectionOutputDto) => {
            return response;
        }));

        const conditions: Array<{
            question: GetQuestionariesModule.IQuestion,
            conditions: Observable<ConditionListModule.IDatum[]>;
        }> = [];

        if (!this.hasLoadedConditions) {
            response.subscribe(res => {
                // Get conditions for dropdown questions.
                for (const section of res.QuestionDetails.Sections) {
                    for (const question of section.Questions) {
                        if (question.Answer.length > 0 && (question.Answer[0].AnswerType === AppConsts.AnswerType.SelectionAnswerProvider || question.Answer[0].AnswerType === AppConsts.AnswerType.MultipleSelectionAnswerProvider)) {
                            conditions.push({
                                question,
                                conditions: this.getConditionsFromQuestion(question.ExternalReferenceId)
                            });
                        }
                    }
                }

                if (conditions.length > 0) {
                    const allConditions = conditions.map(c => c.conditions);

                    forkJoin(allConditions).subscribe(conditionRes => {
                        for (let i = 0; i < conditions.length; i++) {
                            conditions[i].question.CondtionValues = conditionRes[i];
                        }

                        this.cachedAllQuestionResponse.set(_url, JSON.stringify(res));
                        this.hasLoadedConditions = true;
                        this.conditionsSubject.next(true);
                    });
                } else {
                    this.cachedAllQuestionResponse.set(_url, JSON.stringify(res));
                    this.hasLoadedConditions = true;
                    this.conditionsSubject.next(true);
                }
            });
        }

        return response;
    }

    conditionsObservable(): Observable<boolean> {
        return this.conditionsSubject.asObservable();
    }

    private handleUnAnsweredQuestions(filteredPerSection: CreateOrUpdatePolicyDtoOutpuModel.IUnAnsweredQuestion[],
        keyValueMappingDictonary: KeyedCollection<string>, keyValueSet: KeyedCollection<GetQuestionariesModule.IQuestion[]>) {
        let keyValueWithQuestion = keyValueSet;
        filteredPerSection.forEach(item => {
            let questionForCurrentIteration = this.getQuestionByExtReferenceId([item.ExternalReferenceId])[0];

            questionForCurrentIteration = [questionForCurrentIteration].setQuestionType()[0];

            let currSection = keyValueMappingDictonary.Item(item.ExternalReferenceId.toLowerCase());
            let extValue = keyValueWithQuestion.Item(currSection);
            if (extValue) {
                keyValueWithQuestion.Item(currSection).push(questionForCurrentIteration);
            } else {
                keyValueWithQuestion.Add(currSection, [questionForCurrentIteration]);
            }
        });

        return keyValueWithQuestion;

    }

    private handleAnsweredQuestions(filteredPerSection: CreateOrUpdatePolicyDtoOutpuModel.IAnsweredQuestion[], keyValueMappingDictonary: KeyedCollection<string>) {
        let keyValueWithQuestion = new KeyedCollection<GetQuestionariesModule.IQuestion[]>();

        filteredPerSection.forEach(item => {
            let questionForCurrentIteration = this.getQuestionByExtReferenceId([item.ExternalReferenceId])[0];

            if (item.MultipleAnswers != null && item.MultipleAnswers.length > 0 &&
                questionForCurrentIteration &&
                questionForCurrentIteration.Answer.length > 0 && questionForCurrentIteration.AllowMultiAnswers &&
                questionForCurrentIteration.Answer[0].AnswerType === AppConsts.AnswerType.BasicAnswerProvider) {

                questionForCurrentIteration.ValueGiven = (item.MultipleAnswers.map(({ Value }) => ({ Value }.Value)) as any).customFlat();
                questionForCurrentIteration.Answer.filter(x => item.MultipleAnswers.some(c => c.Value === x.Value)).forEach(a => {
                    a.ExistingAnswer = true;
                });

                questionForCurrentIteration.QuestionType = QuestionType.MultiAnswer;

            } else if ((item.MultipleAnswers == null || item.MultipleAnswers.length === 0) &&
                questionForCurrentIteration &&
                questionForCurrentIteration.Answer.length > 0 && !questionForCurrentIteration.AllowMultiAnswers &&
                (questionForCurrentIteration.Answer[0].AnswerType === AppConsts.AnswerType.BasicAnswerProvider)) {

                questionForCurrentIteration.Answer.filter(x => x.AnswerType === 'SingleEntryAnswerProvider' && item.Value === x.Value).forEach(x => x.ExistingAnswer = true);

                questionForCurrentIteration.ValueGiven = questionForCurrentIteration.Answer[0].DataType === 'Number' ? +item.Value : item.Value;
                questionForCurrentIteration.QuestionType = QuestionType.SingleSelect;

            } else if ((item.MultipleAnswers == null || item.MultipleAnswers.length === 0) &&
                questionForCurrentIteration &&
                questionForCurrentIteration.Answer.length > 0 && !questionForCurrentIteration.AllowMultiAnswers &&
                (questionForCurrentIteration.Answer[0].AnswerType === AppConsts.AnswerType.SingleEntryAnswerProvider)) {

                questionForCurrentIteration.Answer.filter(x => x.AnswerType === 'SingleEntryAnswerProvider' && item.Value === x.Value).forEach(x => x.ExistingAnswer = true);

                questionForCurrentIteration.ValueGiven = questionForCurrentIteration.Answer[0].DataType === 'Number' ? +item.Value : item.Value;
                questionForCurrentIteration.QuestionType = QuestionType.FreeText;

            } else if (questionForCurrentIteration &&
                questionForCurrentIteration.Answer.length > 0 && questionForCurrentIteration.AllowMultiAnswers &&
                questionForCurrentIteration.Answer[0].AnswerType === AppConsts.AnswerType.MultipleSelectionAnswerProvider) {
                questionForCurrentIteration.QuestionType = QuestionType.ConditionsMultiSelect;
                questionForCurrentIteration.ValueGiven = item.MultipleAnswers.map(res => +res.Value);
                questionForCurrentIteration.valueCondtions = item.MultipleAnswers.map(res => +res.Value);
            } else if (questionForCurrentIteration &&
                questionForCurrentIteration.Answer.length > 0 && !questionForCurrentIteration.AllowMultiAnswers &&
                questionForCurrentIteration.Answer[0].AnswerType === AppConsts.AnswerType.SelectionAnswerProvider) {
                questionForCurrentIteration.QuestionType = QuestionType.ConditionsSingleSelect;
                questionForCurrentIteration.ValueGiven = item.Value;
                questionForCurrentIteration.valueCondtions = +item.Value;
            }

            if (questionForCurrentIteration && (
                questionForCurrentIteration.ExternalReferenceId.indexOf('consent') > -1 || questionForCurrentIteration.ExternalReferenceId.indexOf('disclaimer') > -1)) {
                questionForCurrentIteration.QuestionType = QuestionType.DataConsent;
                questionForCurrentIteration.AnsweredStatus = questionForCurrentIteration.Answer.some(x => x.Value === item.Value);
            }


            questionForCurrentIteration.AnsweredStatus = true;
            let currSection = keyValueMappingDictonary.Item(item.ExternalReferenceId.toLowerCase());
            let extItem = keyValueWithQuestion.Item(currSection);

            if (extItem) {
                keyValueWithQuestion.Item(currSection).push(questionForCurrentIteration);
            } else {
                keyValueWithQuestion.Add(currSection, [questionForCurrentIteration]);
            }
        });

        return keyValueWithQuestion;
    }


    private getKeyToValueMapping(parentSectId): KeyedCollection<string> {

        let endPoint = abp.setting.values[AppConsts.KeyVaultSecrets.QuirkApiDetailsQuestionMapping] + AppConsts.Endpoints.quirkDictonary;

        let keyValueMapped = this.cachedAllQuestionResponse.get(endPoint);
        if (keyValueMapped) {
            return keyValueMapped.Item(parentSectId);
        }

        let keyValue = new KeyedCollection<KeyedCollection<string>>();
        this.httpMethodBaseService.get<QuirkQuestionsDictonaryModule.IQuirkQuestionsDictonary[]>(endPoint)
            .subscribe(res => {
                res.forEach(x => {
                    x.value.forEach(x => x.extId = x.extId.toLowerCase());
                    let d = new KeyedCollection<string>();
                    keyValue.Add(x.name, d);
                    keyValue.Item(x.name).AddRange(x.value);
                    this.allQuestionsNeedToBePresented = this.allQuestionsNeedToBePresented.concat(x.value);
                });
                this.cachedAllQuestionResponse.set(endPoint, keyValue);
            });

        return keyValue.Item(parentSectId);
    }


    private getAnsweredQuestions(section: GetQuestionariesModule.ISection, userDetails?: AnonymousUserDetail): CreateOrUpdatePolicyInputModule.IAnsweredQuestion[] {

        let anseweredQuestions: CreateOrUpdatePolicyInputModule.IAnsweredQuestion[] = [];

        section.Questions.forEach(item => {

            if (['net_monthly_income', 'Household_income', 'monthly_income'].some(x => x === item.ExternalReferenceId)) {
                this.cachedAllQuestionResponse.set(item.ExternalReferenceId, item.ValueGiven);
            }

            let answeres: CreateOrUpdatePolicyInputModule.IAnsweredQuestion;
            if (item.ExternalReferenceId.toUpperCase().startsWith('DND')) {
                item = [item].checkForAnsweringDND(userDetails);
            }

            if (item.AllowMultiAnswers) {

                let multiAnswer: CreateOrUpdatePolicyInputModule.IMultipleAnswer[] = [];


                if (item.ValueGiven) {
                    item.ValueGiven.forEach(x => {
                        multiAnswer.push({
                            Value: x
                        });
                    });
                }



                answeres = {
                    QuestionProfileId: item.Id,
                    MultipleAnswers: multiAnswer
                };
            } else {
                answeres = {
                    QuestionProfileId: item.Id,
                    Value: item.Answer[0].DataType === 'Number' ? +item.ValueGiven : item.ValueGiven
                };
            }
            anseweredQuestions.push(answeres);
        });

        return anseweredQuestions;
    }

    submitQuestionnaire(paramSubmit?: {
        PolicyId: number
    }, paramSubmit2?: {
        CustomerReferenceid?: string,
        CedantId?: string,
        PolicyReferenceId?: string
    }): Observable<SubmitQuestionnaireModule.SubmitQuestionnaire> {
        if (paramSubmit2) {
            paramSubmit2.CedantId = this.CEDANT_ID;
        }

        let submitquestionnare = this.cachedAllQuestionResponse.get(paramSubmit ? paramSubmit.PolicyId : paramSubmit2.PolicyReferenceId);
        if (submitquestionnare) {
            return of(submitquestionnare);
        }

        let endPoint = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.submitQuestionnaire;
        return this.httpMethodBaseService.post<SubmitQuestionnaireModule.SubmitQuestionnaire>(endPoint, paramSubmit ? paramSubmit : paramSubmit2).pipe(map(res => {
            this.cachedAllQuestionResponse.set(paramSubmit ? paramSubmit.PolicyId : paramSubmit2.PolicyReferenceId, res);
            return res;
        }));
    }

    updatePolicyStatus(paramPolicy: {
        PolicyId: number,
        PolicyStatusName: string,
        ShowErrorDetails: string
    }): Observable<PolicyStatusChangeModule.PolicyStatusChange> {
        let endPoint = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.updatePolicyStatus;
        return this.httpMethodBaseService.post<PolicyStatusChangeModule.PolicyStatusChange>(endPoint, paramPolicy).pipe(map(res => {
            console.log(res);
            return res;
        }));
    }

    getConditionSearch(setupTable: {
        SetupTableId: string,
        SearchFilter: string,
        ShowErrorDetails?: string
    }): Observable<ConditionListModule.IDatum[]> {

        setupTable.ShowErrorDetails = '1';

        let resultFromCache = this.cachedAllQuestionResponse.get(setupTable.SearchFilter + setupTable.SetupTableId);

        if (resultFromCache) {
            return of(resultFromCache);
        }

        let endPoint = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getSetupTableData;
        return this.httpMethodBaseService.post<ConditionListModule.IConditionOutPut>(endPoint, setupTable).pipe(map((res: ConditionListModule.IConditionOutPut) => {
            this.cachedAllQuestionResponse.set(setupTable.SearchFilter + setupTable.SetupTableId, res.Data);
            return res.Data;
        }));
    }

    clonePolicy(policyId) {
        let endPoint = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.clonePolicy;
        let body = {
            PolicyReferenceId: policyId,
            ShowErrorDetails: '1'
        };
        return this.httpMethodBaseService.post<any>(endPoint, body).pipe(map((res: any) => {
            this.cachedAllQuestionResponse.set(endPoint, res);
            return res;
        }));
    }

    async getAnsweredQuestion(customerReferenceId: string, policyReferenceId: string, extId: string): Promise<CreateOrUpdatePolicyDtoOutpuModel.IAnsweredQuestion> {
        const policyInput = {
            AnsweredQuestions: [],
            CedantId: this.CEDANT_ID,
            CustomerReferenceid: customerReferenceId,
            DisplayAnswers: 'true',
            PolicyReferenceId: policyReferenceId,
            ProcessDisclosures: 'true',
            ShowErrorDetails: '1'
        };

        const policyResult = await this.createOrUpdatePolicy(policyInput, true).toPromise();
        const question = policyResult.PolicyDetail.AnsweredQuestions.find(a => a.ExternalReferenceId === extId);
        return question;
    }

    //#endregion
}
