import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class AppNavigationService {
    rewardDefaultPageName = '/app/main/MyRewards/ElevationFund';

    constructor(
        private _appSessionService: AppSessionService,
        private _authService: AuthService,
    ) {
        if (this._authService.userPermission) {
            this.rewardDefaultPageName = this._authService.userPermission.indexOf('BasicUser1') < 0 ? '/app/main/MyRewards/OtherRewards' : this.rewardDefaultPageName;
        }
    }

    getMenu(): AppMenu {
        let self = this;

        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('MY POLICIES', ['PolicyUser1'], [], ' text-left-sidebar pt-1', '../../../../assets/common/images/mypolicies.svg', '/app/main/MyPolicies', []),
            new AppMenuItem('MY DATA', ['PolicyUser1', 'FreeUser1', 'TrialUser1', 'BasicUser1'], [], 'mainmen text-left-sidebar pt-1', '../../../../assets/common/images/magnifier.svg', '/app/main/dashboard', [
                new AppMenuItem('MY WEARABLES', ['PolicyUser1', 'FreeUser1', 'TrialUser1'], [], 'wear-yellow-text', '../../../../assets/common/images/my_wearables.svg', '/app/main/MyWearables'),
                new AppMenuItem('MY HEALTHCARE', ['PolicyUser1', 'FreeUser1', 'TrialUser1'], [], 'myHealth-clr-changes-sub ', '../../../../assets/common/images/my_healthCare.svg', this._authService.userPermission.includes('FreeUser1') ? '/app/main/MyHealthCare' : '/app/main/MyHealthCare/RiskAssessment'),
                new AppMenuItem('MY CREDIT', ['PolicyUser1', 'TrialUser1'], [], 'mycredit-green-clrs', '../../../../assets/common/images/my_credit.svg', '/app/main/MyCredit'),
                new AppMenuItem('MY FINANCE', ['PolicyUser1', 'TrialUser1'], [], 'myfinancial-txt-clrs', '../../../../assets/common/images/my_finance.svg', '/app/main/MyFinancials')
            ]),
            new AppMenuItem('MY GOALS', ['BasicUser1'], [], 'text-left-sidebar pt-1', '../../../../assets/common/images/dart.svg', '/app/main/MyGoals'),
            new AppMenuItem('MY REWARDS', ['BasicUser1'], [], 'text-left-sidebar pt-1', '../../../../assets/common/images/myReward.svg', this.rewardDefaultPageName, [
                new AppMenuItem('ELEVATE REWARDS', ['BasicUser1'], [], 'mycredit-green-clrs', '../../../../assets/common/images/Elevation_Fund/Fund.svg', '/app/main/MyRewards/ElevationFund'),
                new AppMenuItem('MY MEDSHIELD REWARDS', ['FreeUser1'], [], 'mycredit-green-clrs', '../../../../assets/common/images/Medshield_Reward.svg', '/app/main/MyRewards/OtherRewards')
            ]),
            new AppMenuItem('MY POLICY MANAGEMENT', ['BasicUser1'], [], ' text-left-sidebar pt-1', '../../../../assets/common/images/mycommandcentre.svg', '/app/main/MyCommandCentre', [
                new AppMenuItem('NEW POLICY', ['BasicUser1'], [], 'cmd-text-clr', '../../../../assets/common/images/ADD_A_NEW_POLICY.svg', '/app/main/MyCommandCentre/MyPermissions'),
                new AppMenuItem('MAKE CLAIM', ['PolicyUser1'], [], 'cmd-text-clr', '../../../../assets/common/images/ADD_A_NEW_POLICY.svg', '/app/main/MyCommandCentre/claims'),
                //new AppMenuItem('EDIT POLICY', 'PolicyUser1', 'cmd-text-clr', '../../../../assets/common/images/ADD_A_NEW_POLICY.svg', '/app/main/MyCommandCentre/editpolicy'),
                //new AppMenuItem('ADD-ON BENEFITS', 'PolicyUser1', 'cmd-text-clr', '../../../../assets/common/images/ACCESS ADDITIONAL1.svg', ''),
                new AppMenuItem('BILLING INFO', ['PolicyUser1'], [], 'cmd-text-clr', '../../../../assets/common/images/CHANGE BILLING.svg', '/app/main/MyCommandCentre/banking'),
                new AppMenuItem('BENEFICIARIES', ['PolicyUser1'], [], 'cmd-text-clr', '../../../../assets/common/images/CHANGE BENEFICIARY.svg', '/app/main/MyCommandCentre/beneficiary')
            ]),
            //new AppMenuItem('MY BILLING', 'PolicyUser1', 'text-left-sidebar pt-1', '../../../../assets/common/images/my_billing_info.svg', '/app/main/MyBillingInfo'),
            new AppMenuItem('MY RESOURCE CENTRE', ['BasicUser1'], [], 'text-left-sidebar pt-1', '../../../../assets/common/images/MyResourceCentre/MY_RESOURCE_CENTRE.svg', '/app/main/MyResourceCentre', [
                new AppMenuItem('MY LIBRARY', ['PolicyUser1'], [], 'cmd-text-clr', '../../../../assets/common/images/MyResourceCentre/My Documents/Icon.svg', '/app/main/MyResourceCentre/MyDocuments'),
                new AppMenuItem('MY NEEDS CALCULATOR', ['BasicUser1'], [], 'myHealth-clr-changes-sub', '../../../../assets/common/images/MyResourceCentre/MyNeedsCalculator/Icon.svg', '/app/main/MyResourceCentre/MyNeedsCalculator'),
            ]),
            new AppMenuItem('MY SUPPORT', ['BasicUser1'], [], 'text-left-sidebar pt-1', '../../../../assets/common/images/MySupport.svg', '/app/main/MySupport'),
            new AppMenuItem('USER MANAGEMENT', ['AdminUI Administrator'], [], 'text-left-sidebar pt-1', '../../../../assets/common/images/users.svg', '/app/main/user')]);
    }

    hasOneVisibleSubItem(menuItem: AppMenuItem): boolean {
        if (menuItem.items.length === 0) {
            return false;
        }

        for (const item of menuItem.items) {
            if (this.showMenuItem(item)) {
                return true;
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionNames.includes('Pages.Administration.Tenant.SubscriptionManagement') && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.permissionNames.length > 0 && !this._authService.userPermission.some(p => menuItem.permissionNames.includes(p))) {
            hideMenuItem = true;
        }

        if (menuItem.hideFor && this._authService.userPermission.some(p => menuItem.hideFor.includes(p))) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        return !hideMenuItem;
    }
}
