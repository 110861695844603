import { ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
var ButtonBusyDirective = /** @class */ (function () {
    function ButtonBusyDirective(_element, _appLocalizationService) {
        this._element = _element;
        this._appLocalizationService = _appLocalizationService;
    }
    Object.defineProperty(ButtonBusyDirective.prototype, "buttonBusy", {
        set: function (isBusy) {
            this.refreshState(isBusy);
        },
        enumerable: true,
        configurable: true
    });
    ButtonBusyDirective.prototype.ngOnInit = function () {
        this._button = this._element.nativeElement;
    };
    ButtonBusyDirective.prototype.ngAfterViewInit = function () {
        this._originalButtonInnerHtml = this._button.innerHTML;
    };
    ButtonBusyDirective.prototype.refreshState = function (isBusy) {
        if (!this._button) {
            return;
        }
        if (isBusy) {
            // disable button
            this._button.setAttribute('disabled', 'disabled');
            var text = this.busyText ? this.busyText : this._appLocalizationService.l('ProcessingWithThreeDot');
            this._button.innerHTML = '<i class="fa fa-spin fa-spinner"></i>' +
                '<span>' + text.toUpperCase() + '</span>';
            this._button.setAttribute('_disabledBefore', true);
        }
        else {
            if (!this._button.getAttribute('_disabledBefore')) {
                return;
            }
            // enable button
            this._button.removeAttribute('disabled');
            this._button.innerHTML = this._originalButtonInnerHtml;
        }
    };
    return ButtonBusyDirective;
}());
export { ButtonBusyDirective };
