<div id="HealthCareDashBoard">
    <div class="m-subheader mt-3">
        <div class="row align-items-center">
            <div class="mr-auto ml-3">
                <h3 class="m-subheader__title pb-0 font-weight-light m--icon-font-size-sm1">
                    <a class="brd-clr" [routerLink]="['/app/main/dashboard']">
                        {{"MyData" | localize}}
                    </a>>
                    <a class="font-weight-bold brd-clr">
                        {{"myhealthcare" | localize}}
                    </a>
                </h3>
                <small class="m-section__sub">
                    {{"note" | localize}}
                </small>
            </div>
        </div>
    </div>


    <div class="m-content" *ngIf="!_myHealthCareService.isMyHealthCareActive()">
        <div class="row">
            <div class="col-md-6">
                <div class="m-portlet m-portlet--full-height bg-maroon sales-summary-chart">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text">
                                    <small class="font-weight-bold text-white">
                                        Activate My Healthcare to see your data, get goals, and be rewarded.
                                    </small>
                                </h3>
                            </div>
                        </div>
                        <div class="m-portlet__head-tools">
                            <button (click)="appmyhealthcaremodal.show()" class="btn m-btn--pill btn-block">
                                <small class="font-weight-bold m--margin-left-10 m--margin-right-10">
                                    Activate
                                </small>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="m-content" *ngIf="_myHealthCareService.isMyHealthCareActive()">
        <div class="row">
            <div class="col-md-6 col-sm-12 col-xl-6">
                <div class="m-portlet m-portlet--full-height">
                    <div class="m-portlet__head bg-dark-maroon">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text">
                                    <small class="font-weight-bold text-white">
                                        Your Goals are ready to be achieved!
                                    </small>
                                </h3>
                            </div>
                        </div>
                        <div class="m-portlet__head-tools">
                            <button [routerLink]="['/app/main/MyGoals']"
                                class="readmore btn m-btn--pill btn-secondary font-weight-bold">
                                <small class="font-weight-bold m--margin-left-10 m--margin-right-10">
                                    {{"SeeMore" | localize}}
                                </small>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xl-6 mt-c-4 left-4">
                <div class="text-center btn-group-toggle d-flex flex-md-row flex-sm-column flex-wrap" ngbRadioGroup
                    name="radioBasic" [(ngModel)]="model">

                    <div class="d-flex flex-row w-sm-100">

                        <label ngbButtonLabel
                            class="w-sm-50 d-inline ml-1 mr-1 shadow-lg btn-hover-clr bg-white btn m-btn--pill  btn-active-focus font-weight-bold">
                            <input ngbButton type="radio" (change)="goToHealthRiskAssessment()" [value]="0"><small
                                class="font-weight-bold">
                                HRA
                            </small>
                        </label>

                        <label ngbButtonLabel
                            class="w-sm-50 d-inline ml-1 mr-1 shadow-lg btn-hover-clr bg-white btn m-btn--pill  btn-active-focus font-weight-bold">
                            <input ngbButton type="radio" (change)="showHealthCareChart('')" [value]="1"><small
                                class="font-weight-bold">
                                {{"episodescare" | localize}}
                            </small>
                        </label>
                    </div>

                    <div class="d-flex flex-row w-sm-100">

                        <label ngbButtonLabel
                            class="w-sm-50 d-inline ml-1 mr-1 shadow-lg btn-hover-clr btn bg-white m-btn--pill btn-active-focus font-weight-bold">
                            <input ngbButton type="radio" (change)="getHospEventYear()" [value]=2><small
                                class="font-weight-bold">
                                {{"hospitalevents" | localize}}
                            </small>
                        </label>

                        <label ngbButtonLabel
                            class="w-sm-50 d-inline ml-1 mr-1 shadow-lg btn-hover-clr btn bg-white m-btn--pill  btn-active-focus font-weight-bold">
                            <input ngbButton type="radio" (change)="gettHealthRecordYear()" [value]=3><small
                                class="font-weight-bold">
                                {{"healthrecord" | localize}}
                            </small>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-c-p-4">
            <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="model==1">
                <app-episode-care #episodeOfCareref></app-episode-care>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="model==2">
                <app-hospital-events #hospitalEventsref></app-hospital-events>
            </div>
        </div>


        <div class="row">
            <div class="col-md-6" *ngIf="model==3">
                <div class="m-portlet">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="col">
                                <div class="m-portlet__head-title">
                                    <h5 class="mt-5 m-portlet__head-text font-weight-bold black-clr">
                                        {{"HealthRec" | localize}}
                                    </h5>
                                    <a class="mt-5" placement="right" ngbTooltip="{{'HRINFO' | localize}}"
                                        tooltipClass="customHeightforinfo">
                                        <i
                                            class="fa fa-info-circle clr-dark-maroon m--margin-bottom-20 m--margin-left-5">
                                        </i>
                                    </a>
                                </div>
                                <div class="m--space-10"></div>
                                <h6 class="m-portlet__head-desc font-weight-light m--regular-font-size-sm3">
                                    {{"HRSUB" | localize}}
                                </h6>
                            </div>
                        </div>
                        <ng-template #tooltipContent>

                            <div class="row">
                                <div class="col-10">
                                    <div class="m--align-left m--margin-left-10">
                                        {{"ChatToolTipDescription"|localize}}
                                    </div>
                                </div>
                                <div class="col-2 right-px">
                                    <i class="fa fa-times" (click)="h3.toggle()"></i>
                                </div>
                            </div>
                            <textarea rows="3" cols="40" [(ngModel)]="currentUserQuery" placeholder="Description here"
                                type="text" name="disputing"
                                class="m--margin-top-20  m--margin-10-tablet-and-mobile healthcare-chat-box-border"></textarea>
                            <div class="m--align-left m--margin-left-10">
                                <button (click)="sendsms('Health Record')"
                                    class="disputing-css btn m-btn--sm m-btn--pill bg-dark-maroon m--margin-top-10">
                                    <small class="font-weight-bold text-white">
                                        Send
                                    </small>
                                </button>
                            </div>
                        </ng-template>
                        <div class="m--align-right m--margin-top-10 d-flex align-items-center">
                            <i class="fa fa-circle-notch fa-spin loader mr-2" *ngIf="isLoading"></i>

                            <button type="button" (mouseout)="imgSrc = '../../../assets/common/images/chatblack.svg'"
                                (mouseover)="imgSrc = '../../../assets/common/images/Elevate _ Dispute.png'"
                                class="readmore btn m-btn--pill font-weight-bold bg-dark-maroon btn-circle"
                                #h3="ngbTooltip" placement="left" [ngbTooltip]="tooltipContent" triggers="click"
                                [autoClose]="'outside'" tooltipClass="round-tooltip">
                                <img class="width-px-20" [src]="imgSrc">
                            </button>
                        </div>
                    </div>
                    <div class="m-portlet__body">

                    </div>
                </div>
            </div>
        </div>


        <div class="row mb-3">
            <div class="col">

                <div *ngIf="model==3" class="align-items-center">
                    <div class="row ">
                        <div [attr.id]="!determineWindowsWidth()? 'm_Calender_toggle_year':'my-healthrec-drpdwn'"
                            class="ml-4 my-epcare-drpdwn top28 btn-group">
                            <button type="button"
                                class="btn z-index-2 btn-sm bg-dark-maroon m-btn--pill dropdown-toggle font-weight-bold text-white"
                                [attr.data-toggle]="!determineWindowsWidth()? '': 'dropdown'"
                                [attr.aria-haspopup]="!determineWindowsWidth()? '': 'true'"
                                [attr.aria-expanded]="!determineWindowsWidth()? '': 'false'">
                                {{selectedYear}}
                            </button>
                            <div *ngIf="determineWindowsWidth()"
                                class="max-height dropdown-lg z-index-1  dropdown-menu">
                                <a [className]="yearState[i] ? 'active dropdown-item' : 'dropdown-item'"
                                    (click)="yearDropdownSelect(item,i);" *ngFor="let item of yearFilterList;index as i"
                                    class="active-drpdown dropdown-item">{{item}}</a>
                            </div>
                        </div>

                        <div [attr.id]="!determineWindowsWidth()? 'm_Calender_toggle_month' :'dropdownMonth'"
                            class="btn-group my-epcare-month-drpdwn mr-4 ml-4" dropdown [insideClick]="true">
                            <button [ngClass]="{'onClick' : monthToggle, 'notClick': !monthToggle}" dropdownToggle
                                type="button"
                                class="btn z-index-2 btn-hover-clr font-weight-bold no-hover-cls btn-xs btn-elevate btn-hover dropdown-toggle   m--margin-right-10-tablet-and-mobile"
                                aria-controls="dropdown-animated "
                                [attr.data-toggle]="!determineWindowsWidth()? '': 'dropdown'"
                                [attr.aria-haspopup]="!determineWindowsWidth()? '': 'true'"
                                [attr.aria-expanded]="!determineWindowsWidth()? '': 'false'"
                                style="border-radius: 1.3rem;padding: 10px 27px 10px 27px;background-color: white;color: black;">
                                {{'Month' | localize}} <span class="caret"></span>
                            </button>
                            <div *ngIf="determineWindowsWidth()">
                                <ul id="dropdown-animated" *dropdownMenu
                                    class="dropdown-menu dropdown-lg z-index-1 scrollable-menu dropdown-menu-month menu"
                                    role="menu" aria-labelledby="button-animated ">
                                    <li role="menuitem">
                                        <label [ngClass]="{checked:selectAll}"
                                            class="m-checkbox mb-0 m-checkbox--bold container">
                                            <input id="checkdemo" [checked]="selectAll" type="checkbox"
                                                (change)="allMonth()">
                                            {{'All' | localize}}
                                            <span for="checkdemo" class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li role="menuitem" *ngFor="let month of monthFilterList;let i = index;">
                                        <label [ngClass]="{checked:monthState[i]}"
                                            class="m-checkbox  mb-0 m-checkbox--bold container">
                                            <input id="checkdemo" type="checkbox" [(ngModel)]="month.selected"
                                                (change)="monthDropdownSelect($event,i,month)"> {{month.name}}
                                            <span for="checkdemo" class="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <ejs-multiselect class="level-layout custom-ejs-dd" placeholder="Start typing."
                            style="width: 160px !important;" id='multiselect-description-checkbox' #checkbox
                            [dataSource]='descriptionFilterList' [fields]='fields' [mode]='mode'
                            [selectAllText]='selectAllText' showSelectAll=true
                            [enableGroupCheckBox]='enableGroupCheckBox' class="ml-4 mr-4"
                            [allowFiltering]='allowFiltering' [filterBarPlaceholder]='filterBarPlaceholder'
                            [placeholder]='descriptionPlaceholder' [popupHeight]='popHeight' [showDropDownIcon]='true'
                            (select)="onItemSelected($event)" (removed)="onItemRemoved($event)">
                            <ng-template #noRecordsTemplate>
                                <span> Start typing </span>
                            </ng-template>
                        </ejs-multiselect>

                        <div (click)="clearFilter()" class="mt-2 font-weight-bold  clear-btn point-cur">
                            {{'ClearFilters' | localize}}
                        </div>

                    </div>
                </div>
            </div>
        </div>


        <div class="row align-items-center">
            <div *ngIf="NoHealthcare && model==3" class="col-md-6">
                <div class="m-portlet m-portlet--full-height ">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text">
                                    <small class="font-weight-bold black-clr">
                                        No Data for this Filter
                                    </small>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div *ngFor="let item of healthRecordDate;index as i">
            <div class="row" *ngIf="i%2==0">
                <div class="col-md-6" *ngIf="model==3 && item!=undefined">
                    <div class="m-portlet">
                        <div class="m-portlet__body">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="row" style="display:inherit">
                                        <button type="button" class="btn bg-dark-maroon btn-circle btn-xl ">
                                            <img class="w-75" [src]="item.disciplineCodeDescription | imagePipe" />
                                        </button>
                                    </div>
                                    <div class="m--space-10"></div>
                                    <div class="row">
                                        <span>
                                            <small class="m--icon-font-size-sm5 font-weight-bold">
                                                {{item.disciplineCodeDescription | uppercase}}
                                            </small>
                                            <span (click)="arrayforHE[i] = !arrayforHE[i]" class="point-cur">
                                                <i [ngClass]="{'la-angle-up':arrayforHE[i],'la-angle-down':!arrayforHE[i]}"
                                                    class="la m--icon-font-size-sm2 clr-dark-maroon font-weight-bold"
                                                    [attr.aria-expanded]="arrayforHE[i]" aria-controls="i"></i>
                                            </span>
                                        </span>

                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="row">
                                        <div class="col">
                                            <span class="black-clr m--regular-font-size-sm5">
                                                <small class="font-weight-bold">EVENT DATE</small>
                                            </span>
                                        </div>
                                        <div class="col">
                                            <span class="black-clr m--regular-font-size-sm5">
                                                <small class="font-weight-bold">TREATING DOCTOR</small>
                                            </span>
                                        </div>
                                        <div class="col">
                                            <span class="black-clr m--regular-font-size-sm5">
                                                <small class="font-weight-bold">AMOUNT PAID</small>
                                            </span>
                                        </div>
                                    </div>
                                    <hr class="bg-dark-maroon" />
                                    <div class="row">
                                        <div class="col">
                                            <span
                                                class="clr-dark-maroon m--regular-font-size-sm1 font-weight-bold">{{item.serviceDate}}</span>
                                        </div>
                                        <div class="col">
                                            <span
                                                class="clr-dark-maroon m--regular-font-size-sm1 font-weight-bold m--align-left">{{item.providerName}}</span>
                                        </div>
                                        <div class="col">
                                            <span
                                                class="clr-dark-maroon m--regular-font-size-sm1 font-weight-bold">{{item.paidAmount
                                                | number : '1.0-2' }}</span>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body m-portlet__body--no-padding m--padding-top-35-desktop  m--padding-top-35-tablet-and-mobile"
                                        id="{{i}}" [ngbCollapse]="!arrayforHE[i]">
                                        <div class="row">
                                            <div class="col-md-12 m--regular-font-size-sm5 font-weight-bold">
                                                <label class="row">
                                                    <span class="col">Diagnosis code</span><span
                                                        class="col">{{item.icD10Code}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Diagnosis</span><span
                                                        class="col">{{item.icD10CodeDescription}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Service provided</span><span
                                                        class="col">{{item.itemCodeDescription}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">PMB</span><span class="col">{{item.pmbIndicator |
                                                        questionableBoolean}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Medicine name</span><span
                                                        class="col">{{item.nappiProductName}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Medicine category</span><span
                                                        class="col">{{item.atC5Description}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Units/quantity</span><span
                                                        class="col">{{item.units}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Amount claimed</span><span
                                                        class="col">{{item.claimedAmount | number : '1.0-2'}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Shortfall amount</span><span
                                                        class="col">{{item.shortFallAmount | number : '1.0-2'}}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="model==3 && healthRecordDate[(i+1)]!=undefined">
                    <div class="m-portlet ">
                        <div class="m-portlet__body">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="row" style="display:inherit">
                                        <button type="button" class="btn bg-dark-maroon btn-circle btn-xl">
                                            <img class="w-75"
                                                [src]="healthRecordDate[(i+1)].disciplineCodeDescription | imagePipe" />
                                        </button>
                                    </div>
                                    <div class="m--space-10"></div>
                                    <div class="row">
                                        <span>
                                            <small class="m--icon-font-size-sm5 font-weight-bold">
                                                {{healthRecordDate[(i+1)].disciplineCodeDescription | uppercase}}
                                            </small>
                                            <span (click)="arrayforHE[(i+1)] = !arrayforHE[(i+1)]" class="point-cur ">
                                                <i [ngClass]="{'la-angle-up':arrayforHE[(i+1)],'la-angle-down':!arrayforHE[(i+1)]}"
                                                    class="la m--icon-font-size-sm2 clr-dark-maroon font-weight-bold"
                                                    [attr.aria-expanded]="arrayforHE[(i+1)]" aria-controls="i+1"></i>
                                            </span>
                                        </span>

                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="row">
                                        <div class="col">
                                            <span class="black-clr m--regular-font-size-sm5">
                                                <small class="font-weight-bold">EVENT DATE</small>
                                            </span>
                                        </div>
                                        <div class="col">
                                            <span class="black-clr m--regular-font-size-sm5">
                                                <small class="font-weight-bold">TREATING DOCTOR</small>
                                            </span>
                                        </div>
                                        <div class="col">
                                            <span class="black-clr m--regular-font-size-sm5">
                                                <small class="font-weight-bold">AMOUNT PAID</small>
                                            </span>
                                        </div>
                                    </div>
                                    <hr class="bg-dark-maroon" />
                                    <div class="row">
                                        <div class="col">
                                            <span
                                                class="clr-dark-maroon m--regular-font-size-sm1 font-weight-bold">{{healthRecordDate[(i+1)].serviceDate}}</span>
                                        </div>
                                        <div class="col">
                                            <span
                                                class="clr-dark-maroon m--regular-font-size-sm1 font-weight-bold m--align-left">{{healthRecordDate[(i+1)].providerName}}</span>
                                        </div>
                                        <div class="col">
                                            <span
                                                class="clr-dark-maroon m--regular-font-size-sm1 font-weight-bold">{{healthRecordDate[(i+1)].paidAmount
                                                | number : '1.0-2' }}</span>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body m-portlet__body--no-padding m--padding-top-35-desktop  m--padding-top-35-tablet-and-mobile"
                                        id="{{i+1}}" [ngbCollapse]="!arrayforHE[(i+1)]">
                                        <div class="row">
                                            <div class="col-md-12 m--regular-font-size-sm5 font-weight-bold">
                                                <label class="row">
                                                    <span class="col">Diagnosis code</span><span
                                                        class="col">{{healthRecordDate[(i+1)].icD10Code}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Diagnosis</span><span
                                                        class="col">{{healthRecordDate[(i+1)].icD10CodeDescription}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Service provided</span><span
                                                        class="col">{{healthRecordDate[(i+1)].itemCodeDescription}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">PMB</span><span
                                                        class="col">{{healthRecordDate[(i+1)].pmbIndicator |
                                                        questionableBoolean }}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Medicine name</span><span
                                                        class="col">{{healthRecordDate[(i+1)].nappiProductName}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Medicine category</span><span
                                                        class="col">{{healthRecordDate[(i+1)].atC5Description}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Units/quantity</span><span
                                                        class="col">{{healthRecordDate[(i+1)].units}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Amount claimed</span><span
                                                        class="col">{{healthRecordDate[(i+1)].claimedAmount | number :
                                                        '1.0-2'}}</span>
                                                </label>
                                                <label class="row">
                                                    <span class="col">Shortfall amount</span><span
                                                        class="col">{{healthRecordDate[(i+1)].shortFallAmount | number :
                                                        '1.0-2'}}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<appmyhealthcaremodal (showchart)="showHealthCareChart($event)" #appmyhealthcaremodal></appmyhealthcaremodal>

<app-global-filter-year-nav (yearEventHeal)="selectedYearFromNav($event)"
    style="height: 100% !important;width: 200px !important;top: 91px !important;">
</app-global-filter-year-nav>

<app-global-filter-month-nav (monthEvent)="selectedMonthFromNav($event)"
    style="height: 100% !important;width: 200px !important;top: 91px !important;">
</app-global-filter-month-nav>