import { Component, OnInit, NgZone, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MyWearablesService } from '@app/shared/services/my-wearables.service';
import { NumberFormatter } from '@amcharts/amcharts4/core';

@Component({
  selector: 'app-widget-my-steps',
  templateUrl: './widget-my-steps.component.html',
  styleUrls: ['./widget-my-steps.component.css']
})
export class WidgetMyStepsComponent extends AppComponentBase implements OnInit {
  isLoading: boolean;
  showchart = 'week';
  chartXAxistest = 'LAST 7 DAYS';

  constructor(private zone: NgZone, injector: Injector, private _wearableService: MyWearablesService) {
    super(injector);
  }

  ngOnInit() {
    if (!this._wearableService.stepsDto || this._wearableService.stepsDto.length === 0) {
      this.isLoading = true;
    }
  }

  getStepsCurrentWeek(chartXAxistest, showchart): Promise<void> {
    return new Promise((resolve) => {
      this.isLoading = true;
      this.showchart = showchart;
      this.chartXAxistest = chartXAxistest;
      this._wearableService.getSteps().then(() => {
        this.ShowSteps();
        this.isLoading = false;
        resolve();
      });
    });
  }

  getStepsCurrentYear(chartXAxistest, showchart) {
    this.isLoading = true;
    this.showchart = showchart;
    this.chartXAxistest = chartXAxistest;
    this._wearableService.getStepsYear().then(() => {
      this.ShowSteps();
      this.isLoading = false;
    });
  }



  ShowSteps() {
    this.zone.runOutsideAngular(() => {
      Promise.all([
        import('@amcharts/amcharts4/core'),
        import('@amcharts/amcharts4/charts'),
        import('@amcharts/amcharts4/themes/animated')
      ])
        .then(modules => {
          const am4core = modules[0];
          const am4charts = modules[1];
          const am4themes_animated = modules[2].default;
          am4core.options.commercialLicense = true;
          am4core.useTheme(am4themes_animated);

          let chart = am4core.create('coloumnChartMySteps', am4charts.XYChart);
          chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
          chart.dateFormatter.inputDateFormat = 'YYYY-MM-dd';
          chart.language.locale['_thousandSeparator'] = ' ';

          this._wearableService.stepsDto.forEach(x => {
            chart.data.push({
              date: x.informationDate.format('YYYY-MM-DD'),
              price: x.steps
            });
          });

          let dateAxis = chart.xAxes.push(new am4charts.DateAxis());



          if (this.showchart === 'week') {
            dateAxis.renderer.grid.template.strokeOpacity = 0;
            dateAxis.renderer.minGridDistance = 20;
            dateAxis.dateFormats.setKey('day', 'd');
            dateAxis.tooltip.hiddenState.properties.opacity = 1;
            dateAxis.tooltip.hiddenState.properties.visible = true;
            dateAxis.tooltip.adapter.add('x', function (x, target) {
              return am4core.utils.spritePointToSvg({ x: chart.plotContainer.pixelX, y: 0 }, chart.plotContainer).x - 12 + chart.plotContainer.pixelWidth / 2;
            });
          } else if (this.showchart === 'month') {
            dateAxis.renderer.minGridDistance = 100;
            dateAxis.periodChangeDateFormats.setKey('month', '[bold]MMM[/]');
            dateAxis.renderer.grid.template.disabled = true;
          } else if (this.showchart === 'year') {
            dateAxis.renderer.minGridDistance = 10;
            dateAxis.periodChangeDateFormats.setKey('month', '[bold]yyyy[/]');
            dateAxis.renderer.grid.template.disabled = true;

          } else {
            dateAxis.renderer.grid.template.disabled = true;
            dateAxis.renderer.grid.template.location = 0;
            dateAxis.renderer.tooltip.disabled = true;
            dateAxis.renderer.ticks.template.disabled = true;

          }
          dateAxis.title.align = 'left';

          dateAxis.title.text = this.chartXAxistest;
          dateAxis.renderer.line.strokeOpacity = 1;
          dateAxis.renderer.line.strokeWidth = 2;
          dateAxis.renderer.line.stroke = am4core.color('#FFB822');
          dateAxis.title.dy = 12;
          dateAxis.title.paddingLeft = 25;



          let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

          if (this.showchart === 'week') {
            valueAxis.renderer.inside = true;
            valueAxis.renderer.labels.template.fillOpacity = 0.3;
            valueAxis.renderer.grid.template.strokeOpacity = 0;
            valueAxis.cursorTooltipEnabled = false;
          }

          valueAxis.renderer.grid.template.disabled = true;
          valueAxis.renderer.labels.template.disabled = true;
          valueAxis.title.text = 'STEPS';
          valueAxis.renderer.line.strokeOpacity = 1;
          valueAxis.renderer.line.strokeWidth = 2;
          valueAxis.renderer.line.stroke = am4core.color('#FFB822');
          valueAxis.title.dx = -12;

          // Create series
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.valueY = 'price';
          series.dataFields.dateX = 'date';
          series.columns.template.tooltipText = '{valueY}';
          series.columns.template.fill = am4core.color('#FCBB33');
          series.tooltip.getFillFromObject = false;
          series.tooltip.background.fill = am4core.color('black');
          series.stroke = am4core.color('#FCBB33');
          series.sequencedInterpolation = true;
          if (this.showchart === 'week') {
            series.tooltip.pointerOrientation = 'vertical';
            series.tooltip.hiddenState.properties.opacity = 1;
            series.tooltip.hiddenState.properties.visible = true;
            series.tooltip.adapter.add('x', function (x, target) {
              return am4core.utils.spritePointToSvg({ x: chart.plotContainer.pixelX, y: 0 }, chart.plotContainer).x - 12 + chart.plotContainer.pixelWidth / 2;
            });
          }
          let columnTemplate = series.columns.template;
          columnTemplate.width = 10;
          columnTemplate.strokeOpacity = 0;
          columnTemplate.column.cornerRadiusTopLeft = 50;
          columnTemplate.column.cornerRadiusTopRight = 50;
          chart.cursor = new am4charts.XYCursor();
          if (this.showchart === 'week') {
            chart.cursor.behavior = 'panX';
            chart.cursor.lineX.disabled = true;
            chart.zoomOutButton.disabled = true;

            chart.events.on('datavalidated', function () {
              let y = this._wearableService.stepsDto[this._wearableService.stepsDto.length - 1].informationDate.year();
              let m = this._wearableService.stepsDto[this._wearableService.stepsDto.length - 1].informationDate.month();
              let d = this._wearableService.stepsDto[this._wearableService.stepsDto.length - 1].informationDate.date();

              let subdays = this._wearableService.stepsDto[this._wearableService.stepsDto.length - 1].informationDate.subtract(6, 'days');

              let y1 = subdays.year();
              let m1 = subdays.month();
              let d1 = subdays.date();

              dateAxis.zoomToDates(new Date(y1, m1, d1), new Date(y, m, d + 1), false, true);
            }, this);
            let middleLine = chart.plotContainer.createChild(am4core.Line);
            middleLine.strokeOpacity = 1;
            middleLine.stroke = am4core.color('#000000');
            middleLine.strokeDasharray = '2,2';
            middleLine.align = 'center';
            middleLine.zIndex = 1;
            middleLine.adapter.add('y2', function (y2, target) {
              return target.parent.pixelHeight;
            });
            chart.cursor.events.on('cursorpositionchanged', updateTooltip);

            dateAxis.events.on('datarangechanged', updateTooltip);
          } else {
            chart.zoomOutButton.disabled = true;
            chart.cursor.behavior = 'none';

          }
          function updateTooltip() {
            dateAxis.showTooltipAtPosition(0.5);
            series.showTooltipAtPosition(0.5, 0);
            series.tooltip.validate(); // otherwise will show other columns values for a second
          }
        })
        .catch(e => {
          console.error('Error when creating chart', e);
        });
    });
  }
}
