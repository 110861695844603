import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IAccount } from './financial';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class FinancialsService {
    private _url = '/assets/data/financials.json';

    constructor(private http: HttpClient) { }

    getData(): Observable<IAccount[]> {
        //pass this data to
        return this.http.get<IAccount[]>(this._url)
            .catch(this.errorHandler);
    }
    errorHandler(error: HttpErrorResponse) {
        return Observable.throw(error.message || 'Server Error');
    }
}



@Injectable({ providedIn: 'root' })
export class MessageService {
    private subject = new Subject<any>();
    private accountSubject = new Subject<any>();

    sendMessage(message: string) {
        this.subject.next({ text: message });
    }

    clearMessages() {
        this.subject.next();
        this.accountSubject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
