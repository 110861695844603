import { BenefitPattern } from '../BenefitPatternFactory/BenefitPattern';
import { PremiumPattern } from '../PremiumPatternFactory/PremiumPattern';
import { RateTableEntry } from '../RateTableFactory/RateTableEntry';
import { AssociatedProduct } from '../AssociatedProductFactory/associated-product';
import { RateTableEntryFactory } from '../RateTableFactory/IIncompleteRateTableEntry';
import { Injector } from '@angular/core';

export class Product {
    UID: string;
    Name: string;
    MinBenefitAmount: number;
    MaxBenefitAmount: number;
    OriginalMaxBenefitAmount: number;
    BenefitPatternSet: BenefitPattern[];
    PremiumPatternSet: PremiumPattern[];
    EventSet: string;
    Category: string;
    DisplayName: string;
    AssociatedProducts: AssociatedProduct[];
    DefaultBenefitPattern: BenefitPattern;
    DefaultPremiumPattern: PremiumPattern;
    DefaultAssuredValue: number;
    CurrentBenefitPattern: BenefitPattern;
    CurrentPremiumPattern: PremiumPattern;
    CurrentAssuredValue = 0;
    currentPremiumValue: number;
    RateTable: RateTableEntry[];
    currentRateTable: RateTableEntry;

    //Ui Binding
    IsProductQuoted: boolean;
    isIncludedFromQuirc: boolean;
    isDisablitiy: boolean;
    RequirementType: string;

    //passholdingvariables
    constructor(Uid: string, Name: string,
        MinBenefitAmount: number,
        MaxBenefitAmount: number,
        BenefitPatternSet: BenefitPattern[],
        PremiumPatternSet: PremiumPattern[],
        EventSet: string,
        Category: string,
        DisplayName: string,
        AssociatedProducts: AssociatedProduct[],
        DefaultBenefitPattern: BenefitPattern,
        DefaultPremiumPattern: PremiumPattern,
        RateTable: RateTableEntry[],
        isDisability: boolean) {
        this.UID = Uid;
        this.Name = Name;
        this.MinBenefitAmount = MinBenefitAmount;
        this.MaxBenefitAmount = MaxBenefitAmount;
        this.OriginalMaxBenefitAmount = MaxBenefitAmount;
        this.BenefitPatternSet = BenefitPatternSet;
        this.PremiumPatternSet = PremiumPatternSet;
        this.EventSet = EventSet;
        this.Category = Category;
        this.DisplayName = DisplayName;
        this.AssociatedProducts = AssociatedProducts;
        this.DefaultBenefitPattern = DefaultBenefitPattern;
        this.DefaultPremiumPattern = DefaultPremiumPattern;
        this.DefaultAssuredValue = this.MinBenefitAmount;
        //this.CurrentBenefitPattern = DefaultBenefitPattern;
        //this.CurrentPremiumPattern = DefaultPremiumPattern;
        this.RateTable = RateTable;
        this.isIncludedFromQuirc = true;
        this.isDisablitiy = isDisability;
    }

    getPremiumAmount(multiplicativeUndewritingLoading: number, additiveUnderwritingLoading: number = 0): number {
        if (
            !this.currentRateTable ||
            !this.currentRateTable.BenefitPattern ||
            !this.currentRateTable.BenefitPattern.UID ||
            !this.currentRateTable.PremiumPattern ||
            !this.currentRateTable.PremiumPattern.UID
        ) {
            return undefined;
        }

        let assuredValue = this.CurrentAssuredValue || this.DefaultAssuredValue;
        let benefitPatternUID = this.currentRateTable.BenefitPattern.UID;
        let premiumPatternUID = this.currentRateTable.PremiumPattern.UID;

        const getPremiumCalcOutput = (assuredValue: number, premiumRate: RateTableEntry): number => {
            return (assuredValue * premiumRate.UnitRate * premiumRate.BaseRate * multiplicativeUndewritingLoading) + (assuredValue * additiveUnderwritingLoading);
        };

        const ratesForBenefitPremiumPattern = this.RateTable
            .filter(r => r.BenefitPattern.UID === benefitPatternUID && r.PremiumPattern.UID === premiumPatternUID)
            .sort((a, b) => a.sumAssuredLimit - b.sumAssuredLimit);

        let premiumRate = ratesForBenefitPremiumPattern
            .filter(r => r.sumAssuredLimit <= this.CurrentAssuredValue && Math.abs(this.CurrentAssuredValue - r.sumAssuredLimit) < 100000)
            .pop();

        if (!premiumRate) {
            const rateLowerBound = ratesForBenefitPremiumPattern
                .filter(r => r.sumAssuredLimit <= this.CurrentAssuredValue)
                .pop();
            const sumAssuredLowerBound = rateLowerBound.sumAssuredLimit;
            const premiumLowerBound = getPremiumCalcOutput(sumAssuredLowerBound, rateLowerBound);

            const rateUpperBound = ratesForBenefitPremiumPattern
                .filter(r => r.sumAssuredLimit > this.CurrentAssuredValue)
                .pop();
            const sumAssuredUpperBound = rateUpperBound.sumAssuredLimit;
            const premiumUpperBound = getPremiumCalcOutput(sumAssuredUpperBound, rateUpperBound);

            const premium = premiumLowerBound + (this.CurrentAssuredValue - sumAssuredLowerBound) * ((premiumUpperBound - premiumLowerBound) / (sumAssuredUpperBound - sumAssuredLowerBound));
            return Math.round(premium);
        }

        let premium = getPremiumCalcOutput(assuredValue, premiumRate);
        return Math.round(premium);
    }

    async getRateTableAsync(injector: Injector, lowerLimit: number, upperLimit: number, sumAssuredValue: number | null = null, age: string) {
        try {
            let rateTableEntryFactory = await new RateTableEntryFactory(injector)
                .withMetaData(this.UID, lowerLimit, upperLimit, age);

            if (sumAssuredValue) {
                await rateTableEntryFactory.addRateEntry(this.UID, sumAssuredValue, age);
            }

            this.RateTable = [];

            if (!rateTableEntryFactory) {
                rateTableEntryFactory = await new RateTableEntryFactory(injector)
                    .withMetaData(this.UID, lowerLimit, upperLimit, age);
            }
            rateTableEntryFactory.extractRateTableEntryUIDList()
                .forEach(UID => {
                    let rateTableEntry = rateTableEntryFactory
                        .forRateTableEntry(UID)
                        .extractUID()
                        .extractBenefitPattern(this.BenefitPatternSet)
                        .extractPremiumPattern(this.PremiumPatternSet)
                        .extractBaseRate()
                        .extractUnitRate()
                        .extractSumAssuredLimit()
                        .build();

                    if (rateTableEntry.BaseRate < 0) {
                        return;
                    }

                    if (rateTableEntry.BenefitPattern && rateTableEntry.BenefitPattern.maxPaymentAge && rateTableEntry.BenefitPattern.maxPaymentAge !== rateTableEntry.BenefitPattern.benefitEscallationTerm) {
                        return;
                    }

                    this.RateTable.push(rateTableEntry);
                });
            return this;
        } catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    }

    setDefaultBenefitPattern() {
        try {
            if (!this.sortedRateTable() || this.sortedRateTable().length === 0) { return this; }
            this.DefaultBenefitPattern = this.BenefitPatternSet.find(x => x.UID === this.sortedRateTable()[0].BenefitPattern.UID);
            return this;
        } catch (error) {
            throw new Error(error);
        }
    }
    setDefaultPremiumPattern() {
        try {
            if (!this.sortedRateTable() || this.sortedRateTable().length === 0) { return this; }
            this.DefaultPremiumPattern = this.PremiumPatternSet.find(x => x.UID === this.sortedRateTable()[0].PremiumPattern.UID);
            return this;
        } catch (error) {
            throw new Error(error);
        }
    }


    private sortedRateTable() {
        if (!this.RateTable) { return []; }
        return this.RateTable.sort((a, b) => a.BaseRate - b.BaseRate);
    }

}

export interface BenefitPatternSet {
    UID: string;
    FriendlyIdentifier: string;
}

export interface PremiumPatternSet {
    UID: string;
    FriendlyIdentifier: string;
}

export interface EventSet {
    ShortCode: string;
    Description: string;
}


