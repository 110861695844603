var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Injector, ElementRef, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppNavigationService } from './app-navigation.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LayoutRefService } from '@metronic/app/core/services/layout/layout-ref.service';
import { document } from 'ngx-bootstrap';
var SideBarMenuComponent = /** @class */ (function (_super) {
    __extends(SideBarMenuComponent, _super);
    function SideBarMenuComponent(injector, el, router, layoutRefService, permission, _appNavigationService, changeDetector, document) {
        var _this = _super.call(this, injector) || this;
        _this.el = el;
        _this.router = router;
        _this.layoutRefService = layoutRefService;
        _this.permission = permission;
        _this._appNavigationService = _appNavigationService;
        _this.changeDetector = changeDetector;
        _this.document = document;
        _this.menu = null;
        _this.currentRouteUrl = '';
        return _this;
    }
    SideBarMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.menu = this._appNavigationService.getMenu();
        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) { return _this.currentRouteUrl = _this.router.url.split(/[?#]/)[0]; });
    };
    SideBarMenuComponent.prototype.gotToBuyAPolicy = function () {
        var _this = this;
        this.router.navigate(['/app/main/MyCommandCentre/NewPolicy']).then(function () {
            _this.changeDetector.detectChanges();
        });
    };
    SideBarMenuComponent.prototype.isRoutedToBuyAPolicy = function () {
        if (this.currentRouteUrl.includes('NewPolicy')) {
            return true;
        }
        return false;
    };
    SideBarMenuComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.layoutRefService.addElement('asideLeft', _this.el.nativeElement);
        });
    };
    SideBarMenuComponent.prototype.showMenuItem = function (menuItem) {
        var d = this._appNavigationService.showMenuItem(menuItem);
        return d;
    };
    SideBarMenuComponent.prototype.hasOneVisibleSubItem = function (menuItem) {
        return this._appNavigationService.hasOneVisibleSubItem(menuItem);
    };
    SideBarMenuComponent.prototype.preventDefault = function (e) {
        e.preventDefault();
    };
    SideBarMenuComponent.prototype.removeSidebar = function () {
        var d = document.getElementById('m_aside_left');
        if (d.classList.contains('m-aside-left--on')) {
            d.classList.remove('m-aside-left--on');
            document.getElementsByClassName('m-aside-left-overlay')[0].classList.remove('m-aside-left-overlay');
        }
    };
    SideBarMenuComponent.prototype.isMenuItemIsActive = function (item) {
        if (item.items.length) {
            return this.isMenuRootItemIsActive(item);
        }
        if (!item.route) {
            return false;
        }
        // dashboard
        if (item.route !== '/' && this.currentRouteUrl.startsWith(item.route)) {
            return true;
        }
        return this.currentRouteUrl.replace(/\/$/, '') === item.route.replace(/\/$/, '');
    };
    SideBarMenuComponent.prototype.isMenuRootItemIsActive = function (item) {
        var e_1, _a;
        var result = false;
        try {
            for (var _b = __values(item.items), _c = _b.next(); !_c.done; _c = _b.next()) {
                var subItem = _c.value;
                result = this.isMenuItemIsActive(subItem);
                if (result) {
                    return true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     * @param e Event
     */
    SideBarMenuComponent.prototype.mouseEnter = function (e) {
        var _this = this;
        if (!this.currentTheme.baseSettings.menu.allowAsideMinimizing) {
            return;
        }
        // check if the left aside menu is fixed
        if (this.document.body.classList.contains('m-aside-left--fixed')) {
            if (this.outsideTm) {
                clearTimeout(this.outsideTm);
                this.outsideTm = null;
            }
            this.insideTm = setTimeout(function () {
                // if the left aside menu is minimized
                if (_this.document.body.classList.contains('m-aside-left--minimize') && mUtil.isInResponsiveRange('desktop')) {
                    // show the left aside menu
                    _this.document.body.classList.remove('m-aside-left--minimize');
                    _this.document.body.classList.add('m-aside-left--minimize-hover');
                }
            }, 300);
        }
    };
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     * @param e Event
     */
    SideBarMenuComponent.prototype.mouseLeave = function (e) {
        var _this = this;
        if (!this.currentTheme.baseSettings.menu.allowAsideMinimizing) {
            return;
        }
        if (this.document.body.classList.contains('m-aside-left--fixed')) {
            if (this.insideTm) {
                clearTimeout(this.insideTm);
                this.insideTm = null;
            }
            this.outsideTm = setTimeout(function () {
                // if the left aside menu is expand
                if (_this.document.body.classList.contains('m-aside-left--minimize-hover') && mUtil.isInResponsiveRange('desktop')) {
                    // hide back the left aside menu
                    _this.document.body.classList.remove('m-aside-left--minimize-hover');
                    _this.document.body.classList.add('m-aside-left--minimize');
                }
            }, 500);
        }
    };
    return SideBarMenuComponent;
}(AppComponentBase));
export { SideBarMenuComponent };
