<div class="modal-header">
  <h5 class="modal-title">
      <span>{{"Support" | localize}}</span>
  </h5>
  <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body mp-0 mt-10">  
  <iframe #iframe class="no-border" height="652px" [width]="getWidth()" src="https://connect.solgari.com/connect/default.aspx?token=K8Rjg4"
    title="Support"></iframe>
</div>