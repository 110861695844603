var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EntityDtoOfGuid, NotificationServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment/moment';
import * as Push from 'push.js'; // if using ES6
import { AppConsts } from '@shared/AppConsts';
var UserNotificationHelper = /** @class */ (function (_super) {
    __extends(UserNotificationHelper, _super);
    function UserNotificationHelper(injector, _notificationService) {
        var _this = _super.call(this, injector) || this;
        _this._notificationService = _notificationService;
        return _this;
    }
    UserNotificationHelper.prototype.getUrl = function (userNotification) {
        switch (userNotification.notification.notificationName) {
            case 'App.NewUserRegistered':
                return '/app/admin/users?filterText=' + userNotification.notification.data.properties.emailAddress;
            case 'App.NewTenantRegistered':
                return '/app/admin/tenants?filterText=' + userNotification.notification.data.properties.tenancyName;
            case 'App.GdprDataPrepared':
                return AppConsts.remoteServiceBaseUrl + '/File/DownloadBinaryFile?id=' + userNotification.notification.data.properties.binaryObjectId + '&contentType=application/zip&fileName=collectedData.zip';
            //Add your custom notification names to navigate to a URL when user clicks to a notification.
        }
        //No url for this notification
        return '';
    };
    /* PUBLIC functions ******************************************/
    UserNotificationHelper.prototype.getUiIconBySeverity = function (severity) {
        switch (severity) {
            case abp.notifications.severity.SUCCESS:
                return 'fa fa-check';
            case abp.notifications.severity.WARN:
                return 'fa fa-exclamation-triangle';
            case abp.notifications.severity.ERROR:
                return 'fa fa-bolt';
            case abp.notifications.severity.FATAL:
                return 'fa fa-bomb';
            case abp.notifications.severity.INFO:
            default:
                return 'fa fa-info';
        }
    };
    UserNotificationHelper.prototype.format = function (userNotification, truncateText) {
        var formatted = {
            userNotificationId: userNotification.id,
            text: abp.notifications.getFormattedMessageFromUserNotification(userNotification),
            time: moment(userNotification.notification.creationTime).format('YYYY-MM-DD HH:mm:ss'),
            creationTime: userNotification.notification.creationTime,
            icon: this.getUiIconBySeverity(userNotification.notification.severity),
            state: abp.notifications.getUserNotificationStateAsString(userNotification.state),
            data: userNotification.notification.data,
            url: this.getUrl(userNotification),
            isUnread: userNotification.state === abp.notifications.userNotificationState.UNREAD
        };
        if (truncateText || truncateText === undefined) {
            formatted.text = abp.utils.truncateStringWithPostfix(formatted.text, 100);
        }
        return formatted;
    };
    UserNotificationHelper.prototype.show = function (userNotification) {
        var _this = this;
        //Application notification
        abp.notifications.showUiNotifyForUserNotification(userNotification, {
            'onclick': function () {
                //Take action when user clicks to live toastr notification
                var url = _this.getUrl(userNotification);
                if (url) {
                    location.href = url;
                }
            }
        });
        //Desktop notification
        Push.default.create('ElevateMe', {
            body: this.format(userNotification).text,
            icon: abp.appPath + 'assets/common/images/app-logo-small.svg',
            timeout: 6000,
            onClick: function () {
                window.focus();
                this.close();
            }
        });
    };
    UserNotificationHelper.prototype.setAllAsRead = function (callback) {
        this._notificationService.setAllNotificationsAsRead().subscribe(function () {
            abp.event.trigger('app.notifications.refresh');
            if (callback) {
                callback();
            }
        });
    };
    UserNotificationHelper.prototype.setAsRead = function (userNotificationId, callback) {
        var input = new EntityDtoOfGuid();
        input.id = userNotificationId;
        this._notificationService.setNotificationAsRead(input).subscribe(function () {
            abp.event.trigger('app.notifications.read', userNotificationId);
            if (callback) {
                callback(userNotificationId);
            }
        });
    };
    UserNotificationHelper.prototype.openSettingsModal = function () {
        this.settingsModal.show();
    };
    return UserNotificationHelper;
}(AppComponentBase));
export { UserNotificationHelper };
