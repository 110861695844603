﻿
export class PremiumPattern {
    UID: string;
    premiumIncreaseIndex: string;
    premiumIncrease: number | string;
    premiumEscallationTerm: string;

    constructor(UID: string,
        premiumIncreaseIndex: string,
        premiumIncrease: number | string,
        premiumEscallationTerm: string) {
        this.UID = UID;
        this.premiumIncreaseIndex = premiumIncreaseIndex;
        this.premiumIncrease = premiumIncrease;
        this.premiumEscallationTerm = premiumEscallationTerm;
    }
}
