﻿import { Injector, Injectable, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { forkJoin, Observable, of } from 'rxjs';
import { ProductListModule, BenefitModule, PremiumPatternModule, RateTableModule, BenficiaryModule, BenificeryModule, CommissionModule, QuoteModule, RatingFactorModule, RatingFactorResponseNewModule, RatingFactorInputResponseModule, IQuircAnalysisAfterSubmitting, PersonModule, GetMaxSumAssuredModule, defaultOccupationClass, GetAllQuotes } from '@shared/models/interfaces/elevate-data-models';
import { ProductService } from '@app/shared/services/product.service';
import { map } from 'rxjs/operators';
import { GetQuestionariesModule, SubmitAnswerAndGetUpdatedQuestions, CreateQuote, IBankDetails, CreateOrUpdatePolicyInputModule, SubmitQuestionnaireModule, PolicyModule, CreateOrUpdatePolicyDtoOutpuModel, InsuranceAssessment, MedicalInsuranceAssessment } from '@app/shared/models_elevate/models';
import { QuirkService } from '@app/shared/services/quirk-api-service-proxy.service';
import { AppConsts } from '@shared/AppConsts';
import { AnonymousUserDetail } from '@app/shared/services/quote.service';
import { ProductQuote, RiderProductQuote } from '@shared/extensions/my-command-center-extensions';
import { SynopsisDetails } from './Levels/phase-level-five/banking-summary/banking-summary.component';
import * as moment from 'moment';
import { PolicyService } from '@app/shared/services/policy.service';
import { ProductFactory } from '@app/shared/FactoryResources/ProductFactory/IIncompleteProduct';
import { Product } from '@app/shared/FactoryResources/ProductFactory/Product';
import { ProductSummary } from './Levels/product-step/product-step-summary/product-step-summary.component';
import { HttpHeaders } from '@angular/common/http';
import { HttpMethodBaseService } from '@app/shared/services/HttpMethodsBase/httpmethods-base';
import { Router } from '@angular/router';
import { AssociatedProduct } from '@app/shared/FactoryResources/AssociatedProductFactory/associated-product';
import { PoliciesModule } from '@shared/models/interfaces/elevate-data-models';

@Injectable()
export class MyCommandCenterService extends AppComponentBase {
    readonly CEDANT_ID = abp.setting.values[AppConsts.KeyVaultSecrets.cedantId];
    readonly API_BASE_URL_IDP = abp.setting.values['PortalSettings.authority'];
    productQuoteDictionary = new Map();
    hasFinishedSubscription = true;

    private _productService: ProductService;
    public get productService(): ProductService {
        if (!this._productService) {
            this._productService = this.injector.get(ProductService);
        }
        return this._productService;
    }

    private _policyService: PolicyService;
    public get policyService(): PolicyService {
        if (!this._policyService) {
            this._policyService = this.injector.get(PolicyService);
        }
        return this._policyService;
    }

    private _quirkService: QuirkService;
    public get quirkService(): QuirkService {
        if (!this._quirkService) {
            this._quirkService = this.injector.get(QuirkService);
        }
        return this._quirkService;
    }

    public vm: {
        externalQuoteReferencId: string,
        sumAssuredExtQuote: string,
        productSyncedOrNot: boolean,
        producSpinnerEmitter: EventEmitter<boolean>,
        productEmitter: EventEmitter<boolean>,
        productDetailsFromFactory: Array<Product>,
        questionsTobeStore: Array<GetQuestionariesModule.IQuestion>,
        currentPolicyId: string,
        CustomerReferenceid: string,
        CedantId: string,
        userDetails: AnonymousUserDetail,
        initialUserDetails: AnonymousUserDetail,
        entityId: string,
        opportunityId: string,
        quoteId: string,
        productDetails: Array<{ productSectionName: string, products: Array<ProductListModule.IProductListModel> }>,
        quotedProductDetails: Array<ProductListModule.ISelectedProductDetails>,
        conditionliststore: Map<any, any>,
        selectedProduct: Array<ProductListModule.ISelectedProductDetails>,
        medicalStepsValidater: Map<any, any>,
        additionalInfoStepsValidater: Map<any, any>,
        productSummary: Array<ProductSummary>,
        quircAfterSubmittingAnalysis: Array<IQuircAnalysisAfterSubmitting>,
        isImprovingQuoteNeccesary: boolean,
        isReferred: boolean,
        isMinimumBenefitNotMet: boolean,
        submitQuestionaireResult: any,
        quickQuoteMaxSumAssuredInput: GetMaxSumAssuredModule.GetMaxSumAssuredInput,
        quotePolicyCouples: Array<{
            quote: QuoteModule.IQuoteDetails,
            policy: PolicyModule.IPolicyListResult,
            currentValues: Array<{
                productUid: string,
                sumAssured: number,
                premium: number
            }>
        }>,
        acceptedQuotePolicyCouples: Array<{
            quote: QuoteModule.IQuoteDetails,
            policy: PolicyModule.IPolicyListResult
        }>,
        productExclusionMap: Array<{
            descriptionCategory: string,
            productCategory: string,
            exclusions: Array<string>
        }>,
        riderProductAdded: {
            quoteUid: string,
            mainProductUid: string,
            product: AssociatedProduct
        }
        riderProductRemoved: {
            quoteUid: string,
            mainProductUid: string,
            product: AssociatedProduct
        },
        needsAssessmentSumAssuredLevels: Array<QuoteModule.INeedsAssessmentSumAssuredLevel>,
        insuranceAssessment: MedicalInsuranceAssessment,
        lifeInsuredPolicies: Array<PoliciesModule.IPolicy>
    } = {
            externalQuoteReferencId: '',
            sumAssuredExtQuote: '',
            productSyncedOrNot: false,
            producSpinnerEmitter: new EventEmitter<boolean>(),
            productEmitter: new EventEmitter<boolean>(),
            productDetailsFromFactory: new Array<Product>(),
            questionsTobeStore: new Array<GetQuestionariesModule.IQuestion>(),
            currentPolicyId: '',
            CustomerReferenceid: '',
            CedantId: abp.setting.values[AppConsts.KeyVaultSecrets.cedantId],
            userDetails: new AnonymousUserDetail(),
            initialUserDetails: new AnonymousUserDetail(),
            entityId: '',
            opportunityId: '',
            quoteId: '',
            productDetails: new Array<{ productSectionName: string, products: Array<ProductListModule.IProductListModel> }>(),
            quotedProductDetails: new Array<ProductListModule.ISelectedProductDetails>(),
            conditionliststore: new Map(),
            selectedProduct: new Array<ProductListModule.ISelectedProductDetails>(),
            medicalStepsValidater: new Map(),
            additionalInfoStepsValidater: new Map(),
            productSummary: Array<ProductSummary>(),
            quircAfterSubmittingAnalysis: Array<IQuircAnalysisAfterSubmitting>(),
            isImprovingQuoteNeccesary: false,
            isReferred: false,
            isMinimumBenefitNotMet: false,
            submitQuestionaireResult: null,
            quotePolicyCouples: [],
            acceptedQuotePolicyCouples: [],
            quickQuoteMaxSumAssuredInput: {
                age: null,
                existingCover: {
                    currentElevate: null,
                    existingElevate: null,
                    existingOther: null,
                    productSubCategory: null,
                },
                grossMonthlyIncome: null,
                houseHoldIncome: null,
                isAccelerator: null,
                isSelfEmployed: false,
                mainProductCurrentSumAssured: null,
                netMonthlyIncome: null,
                employmentCategory: null,
                needsAnalysis: 'None',
                policyReason: null,
                productCategory: null,
                maxBenefitAmount: null
            },
            productExclusionMap: [],
            riderProductAdded: null,
            riderProductRemoved: null,
            needsAssessmentSumAssuredLevels: [],
            insuranceAssessment: new MedicalInsuranceAssessment(),
            lifeInsuredPolicies: []
        };

    public paymentObject = {
        productDetails: new Array<SynopsisDetails>(),
        beneficiaryDetails: new Array<SynopsisDetails>(),
        bankingDetails: new Array<SynopsisDetails>()
    };


    constructor(
        private injector: Injector,
        private httpMethodBaseService: HttpMethodBaseService
    ) {
        super(injector);
        if (this._authGuard.user) {
            this.vm.entityId = this._authGuard.user.profile.sub;
        }
    }

    initCommandCenterVMRESET() {
        this.vm.quotedProductDetails = [];
        this.vm.questionsTobeStore = [];
        this.vm.quoteId = '';
        this.vm.currentPolicyId = '';
        this.vm.selectedProduct = [];
        this.vm.productSummary = [];
        this.vm.isReferred = false;
        this.vm.isMinimumBenefitNotMet = false;
        this.vm.submitQuestionaireResult = {};
        this.vm.quotePolicyCouples = [];
        this.vm.acceptedQuotePolicyCouples = [];
        this.vm.medicalStepsValidater = new Map();
        this.vm.productExclusionMap = [];
        this.vm.needsAssessmentSumAssuredLevels = [];
        this.vm.productDetailsFromFactory.forEach(x => {
            x.CurrentBenefitPattern = undefined;
            x.CurrentPremiumPattern = undefined;
            x.IsProductQuoted = false;
            x.currentRateTable = undefined;
            x.currentPremiumValue = undefined;
            x.CurrentAssuredValue = this.getDeepCopied(x.MinBenefitAmount);
            if (!x.AssociatedProducts) { return; }
            x.AssociatedProducts.forEach(item => {
                item.CurrentBenefitPattern = undefined;
                item.CurrentPremiumPattern = undefined;
                item.IsProductQuoted = item.RequirementType === AppConsts.requirementTyoe.Selectable ? false : true;
                item.currentRateTable = undefined;
                item.CurrentAssuredValue = this.getDeepCopied(item.MinBenefitAmount);
                item.currentPremiumValue = undefined;
            });
        });
    }

    async getMaxSumAssured(productUid: string, maxBenefitAmount: number, options: { mainProductCurrentSumAssured?: number, isAccelerator?: boolean, skipExistingCover?: boolean, productSubCategory?: string, mainProductCheck?: boolean } = {}): Promise<number> {
        const self = this;
        const quircProduct = this.vm.quircAfterSubmittingAnalysis.find(q => q.MendixProductUID === productUid);

        const policyResult = await this.quirkService.createOrUpdatePolicy({
            AnsweredQuestions: [],
            CedantId: this.vm.CedantId,
            CustomerReferenceid: this.vm.entityId,
            DisplayAnswers: 'true',
            PolicyReferenceId: this.vm.currentPolicyId,
            ProcessDisclosures: 'true',
            ShowErrorDetails: '1'
        }, true).toPromise();

        const policyReason = getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.PolicyReason);

        let input: GetMaxSumAssuredModule.GetMaxSumAssuredInput = {
            age: +getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.Age).Value,
            existingCover: getExistingCover(options.skipExistingCover, policyResult),
            grossMonthlyIncome: this.vm.userDetails.confirmedGrossIncome || +getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.GrossMonthlyIncome).Value,
            houseHoldIncome: +getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.HouseHoldIncome, AppConsts.QuirckExtQuestionId.GrossMonthlyIncome).Value,
            isSelfEmployed: getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.IsSelfEmployed).Value === '0' ? false : true,
            netMonthlyIncome: this.vm.userDetails.confirmedNetIncome || +getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.NetMonthlyIncome, AppConsts.QuirckExtQuestionId.GrossMonthlyIncome).Value,
            employmentCategory: getOccupationSpecific(getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.IsSelfEmployed).Value === '0' ? false : true, getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.EmploymentCategory).Text),
            needsAnalysis: getNeedsAssessmentStatus(this.vm.userDetails, false, policyResult.PolicyDetail.AnsweredQuestions),
            policyReason: policyReason.AnswerText || policyReason.Text,
            productCategory: quircProduct.QuircProfileName,
            isAccelerator: options.isAccelerator,
            mainProductCurrentSumAssured: options.mainProductCurrentSumAssured,
            maxBenefitAmount: maxBenefitAmount
        };

        const sumAssuredResult = await this.productService.getMaxAndMinSummAssured(input).toPromise();
        return sumAssuredResult;

        function getExistingCover(skipExistingCover: boolean, policyResult: CreateOrUpdatePolicyDtoOutpuModel.ICreateOrUpdatePolicyDto): {
            existingOther: number,
            currentElevate: number,
            existingElevate: number,
            productSubCategory: string,
            mainProductCheck: boolean
        } {
            if (skipExistingCover) {
                return {
                    currentElevate: 0,
                    existingElevate: 0,
                    existingOther: 0,
                    productSubCategory: '',
                    mainProductCheck: false
                };
            }

            return {
                existingOther: getExistingOtherCover(quircProduct.AggregationCategory, policyResult),
                currentElevate: getElevateCover(quircProduct.AggregationCategory, self.vm.quotePolicyCouples, productUid),
                existingElevate: getElevateCover(quircProduct.AggregationCategory, self.vm.acceptedQuotePolicyCouples),
                productSubCategory: options.productSubCategory,
                mainProductCheck: options.mainProductCheck
            };
        }

        function getNeedsAssessmentStatus(userDetails: AnonymousUserDetail, skipCreditCheck: boolean, answeredQuestions: Array<CreateOrUpdatePolicyDtoOutpuModel.IAnsweredQuestion> = null): string {
            const covertYesNoQuestionToBool = (question: CreateOrUpdatePolicyDtoOutpuModel.IAnsweredQuestion): boolean => {
                return question && (question.AnswerText === 'Yes' || question.Text === 'Yes');
            };

            const getNeedsAssessmentStringValue = (needsAssessment: boolean, creditCheck: boolean, proofOfIncome: boolean): string => {
                if (needsAssessment) {
                    if (proofOfIncome && (creditCheck || skipCreditCheck)) {
                        return AppConsts.NeedsAnalysis.Full;
                    }

                    return AppConsts.NeedsAnalysis.Partial;
                }

                return AppConsts.NeedsAnalysis.None;
            };

            if (answeredQuestions) {
                const creditCheckQuestion = answeredQuestions.find(q => q.ExternalReferenceId === AppConsts.QuirckExtQuestionId.DndCreditCheck);
                const proofOfIncomeQuestion = answeredQuestions.find(q => q.ExternalReferenceId === AppConsts.QuirckExtQuestionId.DndIncomeConfirmation);
                const needsAssessmentQuestion = answeredQuestions.find(q => q.ExternalReferenceId === AppConsts.QuirckExtQuestionId.DndNaIndicator);

                if (creditCheckQuestion && proofOfIncomeQuestion && needsAssessmentQuestion) {
                    const creditCheckAnswer = covertYesNoQuestionToBool(creditCheckQuestion);
                    const proofOfIncomeAnswer = covertYesNoQuestionToBool(proofOfIncomeQuestion);
                    const needsAssessmentAnswer = covertYesNoQuestionToBool(needsAssessmentQuestion);

                    return getNeedsAssessmentStringValue(needsAssessmentAnswer, creditCheckAnswer, proofOfIncomeAnswer);
                }
            }

            return getNeedsAssessmentStringValue(userDetails.needsAssessment, userDetails.creditCheck, userDetails.proofOfIncome);
        }

        function getElevateCover(
            aggregationCategory: string,
            quotePolicyCouples: Array<{
                quote: QuoteModule.IQuoteDetails;
                policy: PolicyModule.IPolicyListResult;
            }>,
            // If product GUID is included, that product will not be included in the calculation
            productGuid: string = null
        ): number {
            if (!aggregationCategory) {
                return 0;
            }

            const quircProductsInCategory = self.vm.quircAfterSubmittingAnalysis
                .filter(q =>
                    q.AggregationCategory != null &&
                    q.AggregationCategory === aggregationCategory &&
                    q.MendixProductUID !== productGuid);

            if (quircProductsInCategory.length === 0) {
                return 0;
            }

            const invalidQuotesFromPolicies = self.vm.lifeInsuredPolicies.filter(x => x.policyStatus.indexOf('lapsed') >= 0).map(x => x.quoteGuid);

            const productsQuoted: Array<QuoteModule.IProductQuoted> = quotePolicyCouples
                .filter(q => q.quote.ProductQuoteds && Array.isArray(q.quote.ProductQuoteds) && !invalidQuotesFromPolicies.includes(q.quote.UID))
                .map(q => q.quote.ProductQuoteds)
                .customFlat();

            const quircMendixProductMapping: Array<{
                mendix: QuoteModule.IProductQuoted,
                quirc: IQuircAnalysisAfterSubmitting
            }> = [];

            for (const quircProductInCategory of quircProductsInCategory) {
                const productsQuotedInCategory = productsQuoted.filter(q => q.ProductConfig.UID === quircProductInCategory.MendixProductUID);

                if (!productsQuotedInCategory || productsQuotedInCategory.length === 0) {
                    continue;
                }

                quircMendixProductMapping.push(...productsQuotedInCategory.map(p => ({
                    mendix: p,
                    quirc: quircProductInCategory
                })));
            }

            const quotedAssuredValue = quircMendixProductMapping
                .map(q => q.quirc.MendixProductDisplayName === 'Family-Health Severe Illness Protection - Accelerator' ? q.mendix.AssuredValue * 12 * 10 : q.mendix.AssuredValue)
                .reduce((acc, cur) => acc + cur, 0);

            return quotedAssuredValue;
        }

        function getExistingOtherCover(aggregationCategory: string, updatePolicyResult: CreateOrUpdatePolicyDtoOutpuModel.ICreateOrUpdatePolicyDto): number {
            const extIdAggregationCategoryMap = {
                [AppConsts.AggregationCategories.lifeProtection]: AppConsts.QuirckExtQuestionId.ExistingLifeCover,
                [AppConsts.AggregationCategories.disabilityProtection]: AppConsts.QuirckExtQuestionId.ExistingIncomeProtectionCover,
                [AppConsts.AggregationCategories.impairmentProtection]: AppConsts.QuirckExtQuestionId.ExistingImpairmentCover,
                [AppConsts.AggregationCategories.severeIllnessProtection]: AppConsts.QuirckExtQuestionId.ExistingSevereIllnessCover,
            };

            const extId = extIdAggregationCategoryMap[aggregationCategory];

            if (!extId) {
                return 0;
            }

            return +getValueOrDefault(updatePolicyResult.PolicyDetail.AnsweredQuestions, extId).Value || 0;
        }

        function getValueOrDefault(res, extid, fallbackExtId: string = null) {
            const question = res.find(x => x.ExternalReferenceId === extid);

            if (question) {
                return question;
            }

            if (fallbackExtId) {
                return getValueOrDefault(res, fallbackExtId);
            }

            return '';
        }

        function getOccupationSpecific(isSelfEmp, valueGiven: string) {
            if (isSelfEmp) {
                return AppConsts.Occupations.SelfEmployed;
            }

            if (valueGiven.toLowerCase().indexOf('student') >= 0) {
                return AppConsts.Occupations.Student;
            }

            if (valueGiven.toLowerCase().indexOf('wife') >= 0 || valueGiven.toLowerCase().indexOf('husband') >= 0) {
                return AppConsts.Occupations.HouseWifeOrHouseHusband;
            }

            if (valueGiven.toLowerCase().indexOf('unemployed') >= 0) {
                return AppConsts.Occupations.Unemployed;
            }
            return AppConsts.Occupations.Employed;
        }
    }


    createOrEditRider(data: RiderProductQuote, quoteId, productQuoteID, riderQuotedId): Observable<any> {
        return this._productService.createOrUpdateRiderProduct(data, quoteId, productQuoteID, riderQuotedId)
            .pipe(map(res => {
                return res;
            }));
    }

    createOrUpdateBeneficiaryAndGetEntityId(beneficiaryDetails: BenficiaryModule.IBeneficiaryModel): Observable<string> {
        return this.productService.createOrUpdateBeneficiaryAndGetEntityId(beneficiaryDetails)
            .pipe(map(res => {
                return res;
            }));
    }

    createBeneficieryQuirk(beneficiaryForQuote: BenificeryModule.IBeneficiaryForQuote[], productQuotedId: string, quoteId: string): Observable<boolean> {
        return this.productService.addBeneficeryToQuote(productQuotedId, quoteId, beneficiaryForQuote)
            .pipe(map(res => {
                return true;
            }));
    }

    createInclusiveSet(quoteId): Observable<boolean> {
        return this.productService.addNonIndividualExclusionSet(quoteId)
            .pipe(map(res => {
                return true;
            }));
    }

    getAQuote(productQuotedId: string, benefitPatternId: string, premiumPatternId: string, quoteId: string): Observable<Array<QuoteModule.IProductQuoted>> {
        const productQuoteId = this.productQuoteDictionary.get(quoteId);
        let productQuoteSearchString = quoteId + productQuoteId + benefitPatternId + premiumPatternId;
        //let dataDictionary = this.productQuoteDictionary.get(productQuoteSearchString);
        //if (dataDictionary) {
        //    return of(dataDictionary);
        //}

        let response = this.productService.getAQuote(quoteId)
            .pipe(map(res => {
                if (res.ProductQuoteds) {
                    let keyValue = res.UID + res.ProductQuoteds[res.ProductQuoteds.length - 1].ProductConfig.UID + res.ProductQuoteds[res.ProductQuoteds.length - 1].BenefitPattern.UID + res.ProductQuoteds[res.ProductQuoteds.length - 1].PremiumPattern.UID;
                    this.productQuoteDictionary.set(productQuoteSearchString, res.ProductQuoteds[res.ProductQuoteds.length - 1]);
                    return res.ProductQuoteds;
                }
            }));
        return response;
    }

    getQuestionByExtId(item): GetQuestionariesModule.IQuestion[] {
        return this.quirkService.getQuestionByExtReferenceId(item);
    }

    getProductList(): Observable<ProductListModule.IProductListModel[]> {
        return this.productService.getProductList()
            .pipe(map((res: ProductListModule.IProductListModel[]) => {
                return res.getParsedMainAndRiderProduct();
            }));
    }

    getBenifitPatterns(uid: string[]): Observable<BenefitModule.IBenefitModel[]> {
        return this.productService.getBenefitPatterSetForMultipleUid(uid)
            .pipe(map(res => {
                return res;
            }));
    }

    getPremiumPatterns(uid: string[]): Observable<PremiumPatternModule.IPremiumPatternModel[]> {
        return this.productService.getPremiumPatterSetForMultipleUid(uid)
            .pipe(map(res => {
                return res;
            }));
    }



    getQuestionForSection(sectionName, entityId, policyId, isCached = true): Observable<{ extId: string, questions: GetQuestionariesModule.IQuestion[] }[]> {
        return this.quirkService.getQuestionForStepper(sectionName, null, this.vm.entityId, policyId, [], isCached)
            .pipe(map(res => {
                return res;
            }));
    }

    submitQuestionAnswers(section: GetQuestionariesModule.ISection, policyRefId = this.vm.currentPolicyId, isCached = false): Observable<{ extId: string, questions: GetQuestionariesModule.IQuestion[] }[]> {
        let submitQuestions: SubmitAnswerAndGetUpdatedQuestions;
        submitQuestions = {
            customerReferenceid: this.vm.entityId,
            policyReferenceId: policyRefId,
            sections: section
        };
        return this.quirkService.submitProcessQuestionaryAndProvideAsperDictonaryMapping(submitQuestions, this.vm.userDetails, isCached)
            .pipe(map(res => {
                return res;
            }));
    }

    createOrUpdateApplicant(emailId: string, firstName: string, surname: string, dob: Date, callBackToCreatePolicy) {
        let self = this;
        this.initCommandCenterVMRESET();
        this.quirkService.createOrUpdateApplicant({
            CedantId: this.CEDANT_ID,
            Customer: {
                DateOfBirth: dob,
                EmailAddress: getHashed(emailId, 1),
                ExternalReference: this.vm.entityId,
                FirstName: getHashed(firstName, 1),
                NationalID: getHashed(self.vm.userDetails.identityNumber.toString(), 7),
                Surname: getHashed(surname, 1)
            },
            ShowErrorDetails: '1',
        }).subscribe(res => {
            callBackToCreatePolicy();
        }, (err) => {
            abp.ui.clearBusy();
        });


        function getHashed(value: string, countToKeep: number) {
            return value.substr(0, countToKeep).padEnd(value.length, '#');
        }
    }

    getQuotePolicyCouples(quotes: Array<QuoteModule.IQuoteDetails>, policies: Array<PolicyModule.IPolicyListResult>): Array<{
        quote: QuoteModule.IQuoteDetails,
        policy: PolicyModule.IPolicyListResult,
        currentValues: Array<{
            productUid: string
            premium: number,
            sumAssured: number
        }>
    }> {
        const quotePolicyCouples: Array<{
            quote: QuoteModule.IQuoteDetails,
            policy: PolicyModule.IPolicyListResult,
            currentValues: Array<{
                productUid: string,
                premium: number,
                sumAssured: number
            }>
        }> = [];

        for (const quote of quotes) {
            let currentValues: Array<{
                productUid: string
                premium: number,
                sumAssured: number
            }> = [];

            if (quote.ProductQuoteds) {
                currentValues = quote.ProductQuoteds
                    .filter(p => p.PremiumValue > 0)
                    .map(p => ({
                        productUid: p.ProductConfig.UID,
                        premium: p.PremiumValue,
                        sumAssured: p.AssuredValue
                    }));
            }


            quotePolicyCouples.push({
                quote: quote,
                policy: policies.find(p => p.ExternalReferenceId === quote.UID),
                currentValues
            });
        }

        return quotePolicyCouples;
    }

    updateQuoteInPolicyCouple(quote: QuoteModule.IQuoteDetails): void {
        const couple = this.vm.quotePolicyCouples.find(c => c.quote.UID === quote.UID);
        couple.quote = quote;

        if (quote.ProductQuoteds) {
            couple.currentValues = quote.ProductQuoteds.map(p => ({
                productUid: p.ProductConfig.UID,
                premium: p.PremiumValue,
                sumAssured: p.AssuredValue
            }));
        }
    }

    updateCurrentValueInQuotePolicyCouple(quoteUid: string, currentValue: { productUid: string, premium: number, sumAssured: number }): void {
        const couple = this.vm.quotePolicyCouples.find(c => c.quote.UID === quoteUid);
        const existingSliderValue = couple.currentValues.find(c => c.productUid === currentValue.productUid);

        if (existingSliderValue) {
            existingSliderValue.premium = currentValue.premium;
            existingSliderValue.sumAssured = currentValue.sumAssured;
        } else {
            couple.currentValues.push(currentValue);
        }
    }

    getOpenQuoteIdFromPolicyCouples(): string {
        for (const couple of this.vm.quotePolicyCouples) {
            if (!couple.quote.ProductQuoteds || couple.quote.ProductQuoteds.length === 0) {
                return couple.quote.UID;
            }
        }

        return null;
    }

    getQuoteIdForProductFromPolicyCouples(productUid: string): string {
        for (const couple of this.vm.quotePolicyCouples) {
            if (!couple.quote.ProductQuoteds) {
                continue;
            }

            const product = couple.quote.ProductQuoteds.find(q => q.ProductConfig.UID === productUid);

            if (product) {
                return couple.quote.UID;
            }
        }

        return null;
    }

    clearLoadings(): void {
        this.vm.quircAfterSubmittingAnalysis.forEach(x => { x.sumPercentageLoading = 0; x.sumUnitLoading = 0; });
    }

    setLoadings(): void {
        let res = this.vm.submitQuestionaireResult.Products.map(x => ({
            quircProfileName: x.ProfileName,
            decisionName: x.DecisionName,
            sumUnitLoading: x.Premiums.reduce((a, b) => a + b.UnitLoading, 0),
            sumPercentageLoading: x.Premiums.reduce((a, b) => a + b.PercentageLoading, 0),
            productReferences: x.ProductReferences
        }));

        this.clearLoadings();

        res.forEach(item => {
            let product = this.vm.quircAfterSubmittingAnalysis.find(x => x.QuircProfileName === item.quircProfileName);
            if (product) {
                this.vm.quircAfterSubmittingAnalysis.filter(x => x.QuircProfileName === item.quircProfileName).forEach(ele => {
                    ele.decisionName = item.decisionName;
                    ele.sumUnitLoading = item.sumUnitLoading;
                    ele.sumPercentageLoading = item.sumPercentageLoading;
                    ele.productReferences = item.productReferences;
                });
            }
        });
    }

    excludeProducts(): Promise<void> {
        return new Promise(async (resolve) => {
            const useSemiUnderwritten = this.vm.submitQuestionaireResult.PolicyReferences.some(r => r.Title.toLocaleLowerCase().indexOf('semi') >= 0);
            let excludedProduct: Array<string>;

            if (useSemiUnderwritten) {
                excludedProduct = this.vm.quircAfterSubmittingAnalysis.filter(x => x.decisionName === AppConsts.QuircDecisionName.Excluded || x.decisionName === AppConsts.QuircDecisionName.Postpone || x.MendixProductName.toLowerCase().indexOf('semi') === -1).map(x => x.MendixProductUID);
            } else {
                excludedProduct = this.vm.quircAfterSubmittingAnalysis.filter(x => x.decisionName === AppConsts.QuircDecisionName.Excluded || x.decisionName === AppConsts.QuircDecisionName.Postpone || x.MendixProductName.toLowerCase().indexOf('semi') >= 0).map(x => x.MendixProductUID);
            }

            const policyInput = {
                AnsweredQuestions: [],
                CedantId: this.vm.CedantId,
                CustomerReferenceid: this.vm.entityId,
                DisplayAnswers: 'true',
                PolicyReferenceId: this.vm.currentPolicyId,
                ProcessDisclosures: 'true',
                ShowErrorDetails: '1'
            };

            const policyResult = await this.quirkService.createOrUpdatePolicy(policyInput, true).toPromise();
            const selfEmployedQuestion = policyResult.PolicyDetail.AnsweredQuestions.find(x => x.ExternalReferenceId === AppConsts.QuirckExtQuestionId.IsSelfEmployed);
            const isSelfEmployed = selfEmployedQuestion.Value === '1';

            if (!isSelfEmployed) {
                const selfEmployedProduct = this.vm.quircAfterSubmittingAnalysis
                .filter(q => q.MendixProductDisplayName === AppConsts.configProducts.SelfEmployedEnhancer || q.MendixProductDisplayName === AppConsts.configProducts.AlternateSelfEmployedEnhancer)
                    .map(x => x.MendixProductUID);

                if (selfEmployedProduct.length > 0) {
                    excludedProduct.push(...selfEmployedProduct);
                }
            }

            this.vm.productDetailsFromFactory
                .forEach(x => x.isIncludedFromQuirc = true);

            this.vm.productDetailsFromFactory
                .filter(x => excludedProduct.some(g => g === x.UID))
                .forEach(x => x.isIncludedFromQuirc = false);

            this.vm.productDetailsFromFactory
                .filter(x => x.isIncludedFromQuirc)
                .forEach(rider => {
                    if (!rider.AssociatedProducts) {
                        return;
                    }

                    rider.AssociatedProducts.filter(x => excludedProduct.some(g => g === x.UID)).forEach(x => x.isIncludedFromQuirc = false);
                });

            if (this.vm.quotePolicyCouples && this.vm.quotePolicyCouples.length > 0 && excludedProduct.length > 0) {
                const deleteObservables: Array<Observable<any>> = [];
                const getQuoteObservables: Array<Observable<QuoteModule.IQuoteDetails>> = [];
                const productsToDelete: Array<string> = [];

                for (const couple of this.vm.quotePolicyCouples) {
                    if (!couple.quote.ProductQuoteds) {
                        continue;
                    }

                    for (const product of couple.quote.ProductQuoteds) {
                        if (excludedProduct.includes(product.ProductConfig.UID)) {
                            if (productsToDelete.includes(product.ProductConfig.UID)) {
                                continue;
                            }

                            deleteObservables.push(this.productService.deleteRiderProduct(couple.quote.UID, product.ProductConfig.UID));
                            getQuoteObservables.push(this.productService.getAQuote(couple.quote.UID));

                            if (product.IsMainProduct) {
                                productsToDelete.push(...couple.quote.ProductQuoteds.map(q => q.ProductConfig.UID));
                            } else {
                                productsToDelete.push(product.ProductConfig.UID);
                            }
                        }
                    }
                }

                if (deleteObservables.length === 0) {
                    resolve();
                    return;
                }

                forkJoin(deleteObservables).subscribe(result => {
                    forkJoin(getQuoteObservables).subscribe(quoteResult => {
                        for (const quote of quoteResult) {
                            this.updateQuoteInPolicyCouple(quote);
                        }

                        resolve();
                    });
                });
            } else {
                resolve();
            }
        });
    }

    getCommissionForQuoteGuid(commissions: Array<CommissionModule.BrokerCommission>, quoteGuid: string): CommissionModule.BrokerCommission {
        if (!commissions) {
            return {
                commissionValues: [],
                primaryCommission: [],
                secondaryCommission: [],
                commissionPattern: null
            };
        }

        const commission = commissions
            .filter(c => c.commissionValues)
            .find(c => c.commissionValues.length > 0 && c.commissionValues[0].quoteGuid === quoteGuid);

        if (commission) {
            return commission;
        }

        return {
            commissionValues: [],
            primaryCommission: [],
            secondaryCommission: [],
            commissionPattern: null
        };
    }

    getInceptionDate(): moment.Moment {
            return moment().add(1, 'month').startOf('month');
    }

    expireQuotes(quoteUids: Array<string>): void {
        const quoteObservables = quoteUids.map(q => this.quirkService.CreateOrUpdateQuote({ QuoteStatus: 'Expired' }, q));

        forkJoin(quoteObservables).subscribe(
            (result) => {
                console.log('Expired quote ', result);
            },
            (err) => {
                console.error(err);
            });
    }

    startQuoteJourney(): Promise<Array<{
        extId: string;
        questions: GetQuestionariesModule.IQuestion[];
    }>> {
        const self = this;

        function getQuoteDetails(quotes: Array<GetAllQuotes>, updateQuote: boolean = true, updateInceptionDate: boolean = false): Promise<Array<QuoteModule.IQuoteDetails>> {
            return new Promise((resolve, reject) => {
                const quoteObservables: Array<Observable<QuoteModule.IQuoteDetails>> = quotes.map(q => self.productService.getAQuote(q.QuoteUID));

                if (quoteObservables.length === 0) {
                    resolve([]);
                    return;
                }

                forkJoin(quoteObservables).subscribe(
                    (result) => {
                        let inceptionDate = moment();
                        let updateQuoteObservables: any;
                        if (updateInceptionDate || result.some(r => moment(r.InceptionDate).isSameOrBefore(inceptionDate))) {
                            updateQuoteObservables = result
                                .filter(r =>
                                    !moment(r.InceptionDate).isSameOrAfter(inceptionDate, 'day'))
                                .map(r => self.CreateQuote({
                                    InceptionDate: moment().format('yyyy-MM-DD')
                                }, r.UID));
                        }

                        if (!updateQuoteObservables || updateQuoteObservables.length === 0 || !updateQuote) {
                            resolve(result);
                            return;
                        }

                        forkJoin(updateQuoteObservables).subscribe(
                            () => {
                                resolve(getQuoteDetails(quotes));
                            },
                            (err) => {
                                reject(err);
                            });
                    },
                    (err) => {
                        reject(err);
                    });
            });
        }

        function getDateDiffFromQuote(quote: GetAllQuotes): number {
            const createdDate = moment.utc(quote.DateCreated);
            const now = moment(new Date());
            const diff = now.diff(createdDate, 'minutes');
            return diff;
        }

        function getQuotePolicyCouple(): { quote: QuoteModule.IQuoteDetails, policy: PolicyModule.IPolicyListResult } {
            const submittedPolicy = self.vm.quotePolicyCouples.find(q => Boolean(q.policy) && self.policyService.isSubmitted(q.policy.PolicyStatusName));

            if (submittedPolicy) {
                return submittedPolicy;
            }

            const nonSubmittedPolicy = self.vm.quotePolicyCouples.find(q => Boolean(q.policy));

            if (nonSubmittedPolicy) {
                return nonSubmittedPolicy;
            }

            return self.vm.quotePolicyCouples[0];
        }

        function setUpdatedQuestion(policy: SubmitQuestionnaireModule.SubmitQuestionnaire): void {
            const multiAnswers = policy.QuestionAnswers.filter(q => q.AllowMultiAnswers);
            const singleAnswers = policy.QuestionAnswers.filter(q => !q.AllowMultiAnswers);
            const answersToAdd = [];

            for (const answer of multiAnswers) {
                const answersForQuestion = multiAnswers.filter(m => m.QuestionId === answer.QuestionId);

                if (!answersToAdd.some(a => a.QuestionId === answer.QuestionId)) {
                    const mergedAnswer = answersForQuestion[0];

                    mergedAnswer.MultipleAnswers = answersForQuestion.map(a => {
                        return {
                            AnswerId: a.AnswerId,
                            AnswerProfileId: a.AnswerProfileId,
                            Text: a.AnswerText,
                            Value: a.AnswerValue
                        };
                    });

                    answersToAdd.push(mergedAnswer);
                }
            }

            answersToAdd.push(...singleAnswers);
            policy.PolicyDetail.AnsweredQuestions = answersToAdd;

            for (const answer of policy.PolicyDetail.AnsweredQuestions) {
                answer.Value = answer.AnswerValue;
                answer.Text = answer.Value;
            }

            self.quirkService.cachedAllQuestionResponse.set('UpdatedQuestion', policy);
        }

        return new Promise((resolve, reject) => {
            forkJoin([
                this.quirkService.getPolicies(this.vm.entityId),
                this.productService.getQuoteOnAPersonBasedOnRole(this.vm.entityId, AppConsts.QuoteRoles.Life_Insured),
                this.productService.getQuoteOnAPersonBasedOnRole(this.vm.entityId, AppConsts.QuoteRoles.Premium_Payer),
                this.productService.getMendixProductAndQuircMapping(),
                this.policyService.getPoliciesForLifeAssured(this.vm.entityId, this._authService.user.access_token)
            ]).subscribe(
                (result) => {
                    const resumePeriod = +abp.setting.values['App.ResumePeriod'];
                    const policies = result[0] || [];
                    const lifeInsuredQuotes = result[1] || [];
                    const premiumPayerQuotes = result[2] || [];
                    const notAcceptedQuotes = lifeInsuredQuotes
                        .concat(premiumPayerQuotes)
                        .filter((value, index, array) =>
                            array.findIndex(a => a.QuoteUID === value.QuoteUID) === index &&
                            value.QuoteStatus === AppConsts.QuoteStatus.Not_Accepted
                        );

                    this.vm.quircAfterSubmittingAnalysis = result[3];
                    self.vm.lifeInsuredPolicies = result[4] || [];
                    this.clearLoadings();
                    const quotes = notAcceptedQuotes.filter(q => getDateDiffFromQuote(q) <= resumePeriod);
                    const quotesToExpire = notAcceptedQuotes.filter(q => getDateDiffFromQuote(q) > resumePeriod);
                    const acceptedQuotes = lifeInsuredQuotes.filter(q => q.QuoteStatus === AppConsts.QuoteStatus.Accepted);

                    if (quotesToExpire.length > 0) {
                        this.expireQuotes(quotesToExpire.map(q => q.QuoteUID));
                    }

                    if (quotes.length === 0) {
                        this.CreateQuote({
                            MainMember: this.vm.entityId,
                            LifeAssured: this.vm.entityId,
                            LifeAssuredAssuredType: 'Primary_member',
                            InceptionDate: moment().format('yyyy-MM-DD'),
                            Broker: abp.setting.values[AppConsts.KeyVaultSecrets.BrokerId],
                        }).subscribe(
                            (result) => {
                                this.createOrUpdatePolicyAndGetAnswers(result, 'Personal Info', false).subscribe((result) => {
                                    resolve(this.startQuoteJourney());
                                    return;
                                });
                            },
                            (err) => {
                                reject(err);
                            });
                    } else {
                        Promise.all([
                            getQuoteDetails(quotes),
                            getQuoteDetails(acceptedQuotes, false),
                        ]).then(async (result) => {
                            this.vm.quotePolicyCouples = this.getQuotePolicyCouples(result[0], policies);
                            this.vm.acceptedQuotePolicyCouples = this.getQuotePolicyCouples(result[1], policies);

                            let quotePolicyCouple = getQuotePolicyCouple();
                            this.vm.quoteId = quotePolicyCouple.quote.UID;

                            if (quotePolicyCouple.policy) {
                                this.vm.currentPolicyId = quotePolicyCouple.policy.ExternalReferenceId;
                                this.quirkService.policyId = quotePolicyCouple.policy.PolicyId;
                            } else {
                                this.vm.currentPolicyId = null;
                                this.quirkService.policyId = null;
                            }

                            if (!quotePolicyCouple.policy || !this.policyService.isSubmitted(quotePolicyCouple.policy.PolicyStatusName)) {
                                const updatedQuotes = getQuoteDetails(quotes, true, true);
                                updatedQuotes.then(result => {
                                    result.forEach(quote => {
                                        this.updateQuoteInPolicyCouple(quote);
                                    });

                                    this.createOrUpdatePolicyAndGetAnswers(null, 'Personal Info', false).subscribe(
                                        (result) => {
                                            resolve(result);
                                        },
                                        (err) => {
                                            reject(err);
                                        });
                                });
                            } else {
                                this.quirkService.getPolicySchedule(quotePolicyCouple.policy.CustomerReferenceId, quotePolicyCouple.policy.ExternalReferenceId)
                                    .subscribe(
                                        (result) => {
                                            this.vm.submitQuestionaireResult = result;
                                            this.vm.isImprovingQuoteNeccesary = true;
                                            setUpdatedQuestion(result);
                                            this.setLoadings();

                                            this.createPerson(() => {
                                                this.policyService.updatePersonAsync(this.vm.entityId).then(() => {
                                                    this.getProductAsync().then(() => {
                                                        this.excludeProducts().then(() => {
                                                            resolve(null);
                                                            return;
                                                        });
                                                    });
                                                });
                                            }, false);
                                        },
                                        (err) => {
                                            reject(err);
                                        });
                            }
                        });
                    }
                },
                (err) => {
                    reject(err);
                });
        });
    }

    pushToProductSummary(product: QuoteModule.IProductQuoted, factoryProduct: Product, quote: QuoteModule.IQuoteDetails): void {
        this.vm.quotedProductDetails.push({
            assuredValue: product.AssuredValue,
            premiumAmount: product.PremiumValue,
            productId: product.ProductConfig.UID,
            productName: product.ProductConfig.Name,
            productedQuotedId: product.ProductConfig.UID,
            quoteId: quote.UID,
            isMainProduct: product.IsMainProduct,
            productCategory: factoryProduct.Category
        });

        factoryProduct.CurrentAssuredValue = product.AssuredValue;
        factoryProduct.CurrentBenefitPattern = factoryProduct.BenefitPatternSet.find(b => b.UID === product.BenefitPattern.UID);
        factoryProduct.CurrentPremiumPattern = factoryProduct.PremiumPatternSet.find(p => p.UID === product.PremiumPattern.UID);
        factoryProduct.currentPremiumValue = product.PremiumValue;

        if (product.IsMainProduct) {
            const associatedUIDs = quote.ProductQuoteds.filter(p => !p.IsMainProduct).map(p => p.ProductConfig.UID);

            this.vm.productSummary.push({
                categoryName: factoryProduct.Category,
                productUID: product.ProductConfig.UID,
                products: [factoryProduct, ...factoryProduct.AssociatedProducts.filter(a => associatedUIDs.some(p => p === a.UID))]
            });
        }
    }

    createQuotesForMedicallyReferred(): Promise<void> {
        return new Promise((resolve, reject) => {
            const referredCouple = this.vm.quotePolicyCouples.find(c => c.policy && c.policy.PolicyStatusName === 'Referred');

            if (!referredCouple) {
                resolve();
                return;
            }

            const quoteCountToCreate = +abp.setting.values['App.CloneCount'] - this.vm.quotePolicyCouples.length;

            if (quoteCountToCreate === 0) {
                resolve();
                return;
            }

            const createQuoteObservable: Array<Observable<string>> = [];

            for (let i = 0; i < quoteCountToCreate; i++) {
                createQuoteObservable.push(this.CreateQuote({
                    MainMember: this.vm.entityId,
                    LifeAssured: this.vm.entityId,
                    LifeAssuredAssuredType: 'Primary_member',
                    InceptionDate: moment().format('yyyy-MM-DD'),
                    Broker: referredCouple.quote.Person_Broker.Identifier
                }));
            }

            forkJoin(createQuoteObservable).subscribe(
                (result) => {
                    const quoteObservables = result.map(r => this.productService.getAQuote(r));

                    forkJoin(quoteObservables).subscribe(
                        (result) => {
                            this.vm.quotePolicyCouples.push(...result.map(r => ({ quote: r, policy: undefined, commission: undefined, currentValues: [] })));
                            resolve();
                        },
                        (err) => {
                            reject(err);
                        });
                },
                (err) => {
                    reject(err);
                });
        });
    }

    clonePoliciesOnResume(): Promise<void> {
        return new Promise((resolve, reject) => {
            const nonSubmittedCouples = this.vm.quotePolicyCouples.filter(c => !c.policy || !this.policyService.isSubmitted(c.policy.PolicyStatusName));
            const submittedCouple = this.vm.quotePolicyCouples.find(c => c.policy && this.policyService.isSubmitted(c.policy.PolicyStatusName));

            if (nonSubmittedCouples.length === 0 || !submittedCouple) {
                resolve();
                return;
            }

            this.getPolicyToClone()
                .then((result) => {
                    const policyObservables: Array<Observable<CreateOrUpdatePolicyDtoOutpuModel.ICreateOrUpdatePolicyDto>> = [];

                    for (const couple of nonSubmittedCouples) {
                        const request: CreateOrUpdatePolicyInputModule.IUpdatePolicyOfApplicantWithAnswers = {
                            CedantId: this.CEDANT_ID,
                            CustomerReferenceid: this.vm.entityId,
                            DisplayAnswers: 'true',
                            PolicyReferenceId: couple.quote.UID,
                            ProcessDisclosures: 'true',
                            ShowErrorDetails: '1',
                            AnsweredQuestions: result
                        };

                        policyObservables.push(this.quirkService.createOrUpdatePolicy(request, false));
                    }

                    if (policyObservables.length === 0) {
                        resolve();
                        return;
                    }

                    forkJoin(policyObservables).subscribe(result => {
                        const submitObservables: Array<Observable<SubmitQuestionnaireModule.SubmitQuestionnaire>> = [];

                        for (const couple of nonSubmittedCouples) {
                            submitObservables.push(this.quirkService.submitQuestionnaire(undefined, {
                                CustomerReferenceid: this.vm.entityId,
                                PolicyReferenceId: couple.quote.UID
                            }));
                        }

                        forkJoin(submitObservables).subscribe(result => {
                            this.quirkService.getPolicies(this.vm.entityId).subscribe(
                                (result) => {
                                    this.vm.quotePolicyCouples = this.getQuotePolicyCouples(this.vm.quotePolicyCouples.map(c => c.quote), result);
                                    resolve();
                                },
                                (err) => {
                                    reject(err);
                                });
                        });
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    populateProducts(): Promise<boolean> {
        const self = this;
        this.vm.quotedProductDetails = [];
        this.vm.productSummary = [];

        function setUpProduct(product: QuoteModule.IProductQuoted, factoryProduct: Product, quoteId: string, mainProductId: string = null, isAccelerator: boolean = false, mainProductCurrentSumAssured: number = null): Promise<{ quoteId: string, productId: string, assuredValue: number }> {
            return new Promise(async (resolve, reject) => {
                factoryProduct.IsProductQuoted = true;
                let excludedProducts = self.vm.needsAssessmentSumAssuredLevels.filter(x => x.excluded !== null);
                let excludedProductSubCategory = excludedProducts.map(x => x.excluded).includes(factoryProduct.DisplayName) ? factoryProduct.DisplayName : '';
                let inceptionDate = moment();
                inceptionDate = inceptionDate.isSameOrBefore(moment(self.vm.quotePolicyCouples[0].quote.InceptionDate)) ? moment(self.vm.quotePolicyCouples[0].quote.InceptionDate) : inceptionDate;
                let birthDate = self.vm.userDetails.identityNumber.getDateOfBirthByIdNumber();
                let age = inceptionDate.diff(birthDate, 'years').toString();
                if (!factoryProduct.RateTable) {
                    const maxSumAssured = await self.getMaxSumAssured(factoryProduct.UID, factoryProduct.OriginalMaxBenefitAmount, { mainProductCurrentSumAssured, isAccelerator: false, productSubCategory: excludedProductSubCategory });
                    await factoryProduct.getRateTableAsync(self.injector, factoryProduct.MinBenefitAmount, maxSumAssured, null, age);
                }

                self.getMaxSumAssured(factoryProduct.UID, factoryProduct.OriginalMaxBenefitAmount, { mainProductCurrentSumAssured, isAccelerator, productSubCategory: excludedProductSubCategory }).then((result) => {
                    const sortedRateTable = factoryProduct.RateTable
                        .map(r => r.sumAssuredLimit)
                        .filter((val, index, arr) => arr.indexOf(val) === index && val <= factoryProduct.MaxBenefitAmount && val >= factoryProduct.MinBenefitAmount && val <= result)
                        .sort((a, b) => a - b);

                    if (sortedRateTable.length === 0) {
                        self.productService.deleteRiderProduct(quoteId, factoryProduct.UID).subscribe(result => {
                            self.productService.getAQuote(quoteId).subscribe(result => {
                                factoryProduct.IsProductQuoted = false;
                                self.updateQuoteInPolicyCouple(result);
                                self.vm.isMinimumBenefitNotMet = true;
                                resolve({ quoteId: quoteId, productId: product.ProductConfig.UID, assuredValue: null });
                            });
                        });
                    } else {
                        if (result < product.AssuredValue) {
                            product.AssuredValue = sortedRateTable.filter(s => s <= result)[sortedRateTable.filter(s => s <= result).length - 1];
                        } else if (!sortedRateTable.some(s => s >= product.AssuredValue)) {
                            product.AssuredValue = sortedRateTable.filter(s => s < product.AssuredValue)[sortedRateTable.filter(s => s < product.AssuredValue).length - 1];
                        }

                        const update: ProductQuote = {
                            AdditiveUnderwritingLoading: self.additiveunderwritingloading(product.ProductConfig.UID),
                            AssuredValue: product.AssuredValue,
                            BenefitPattern: product.BenefitPattern.UID,
                            EventSet: product.EventSet.ShortCode,
                            MultiplicativeUnderwritingLoading: self.multipicativeunderwritingloading(product.ProductConfig.UID),
                            PremiumPattern: product.PremiumPattern.UID,
                            Product: product.ProductConfig.UID,
                        };

                        let quoteObservable: Observable<any>;

                        if (!product.IsMainProduct) {
                            (update as RiderProductQuote).MainProduct = mainProductId;
                            quoteObservable = self.productService.createOrUpdateRiderProduct((update as RiderProductQuote), quoteId, mainProductId, product.ProductConfig.UID);
                        } else {
                            quoteObservable = self.productService.productQuoted(quoteId, update, product.ProductConfig.UID, 'PUT');
                        }

                        quoteObservable.subscribe(
                            () => {
                                resolve({ quoteId: quoteId, productId: product.ProductConfig.UID, assuredValue: product.AssuredValue });
                            },
                            (err) => {
                                reject(err);
                            });
                    }
                });
            });
        }

        return new Promise((resolve, reject) => {
            const mainProductPromises: Array<Promise<{ quoteId: string, productId: string, assuredValue: number }>> = [];
            const riderProductPromises: Array<Promise<{ quoteId: string, productId: string, assuredValue: number }>> = [];
            const boosterProductPromises: Array<Promise<{ quoteId: string, productId: string, assuredValue: number }>> = [];
            const associatedProductFactories = self.vm.productDetailsFromFactory.map(p => p.AssociatedProducts).customFlat();

            for (const couple of this.vm.quotePolicyCouples) {
                if (!couple.quote.ProductQuoteds || couple.quote.ProductQuoteds.length === 0) {
                    continue;
                }

                const mainProduct = couple.quote.ProductQuoteds.find(p => p.IsMainProduct);
                const mainProductFactory = this.vm.productDetailsFromFactory.find(p => p.UID === mainProduct.ProductConfig.UID);
                mainProductPromises.push(setUpProduct(mainProduct, mainProductFactory, couple.quote.UID));
            }

            if (mainProductPromises.length === 0) {
                resolve(true);
                return;
            }

            Promise.all(mainProductPromises)
                .then((resultMain) => {
                    const boostersToAdd: Array<{ associatedProduct: AssociatedProduct, quoteProduct: QuoteModule.IProductQuoted }> = [];

                    for (const couple of this.vm.quotePolicyCouples) {
                        if (!couple.quote.ProductQuoteds || couple.quote.ProductQuoteds.length === 0) {
                            continue;
                        }

                        let riderProducts = couple.quote.ProductQuoteds.filter(p => !p.IsMainProduct && p.PremiumValue > 0);

                        for (const riderProduct of riderProducts) {
                            const riderProductFactory = associatedProductFactories.find(p => p.UID === riderProduct.ProductConfig.UID) as AssociatedProduct;
                            if (riderProductFactory.IsParentDependent) {
                                boostersToAdd.push({ associatedProduct: riderProductFactory, quoteProduct: riderProduct });
                                continue;
                            }

                            const mainProductAssuredValue = resultMain.find(r => r.quoteId === couple.quote.UID).assuredValue;
                            const mainProductUid = couple.quote.ProductQuoteds.find(p => p.IsMainProduct).ProductConfig.UID;
                            riderProductPromises.push(setUpProduct(riderProduct, riderProductFactory, couple.quote.UID, mainProductUid, riderProductFactory.Accelerator, mainProductAssuredValue));
                        }
                    }

                    if (riderProductPromises.length === 0) {
                        riderProductPromises.push(new Promise((resolve) => { resolve({ assuredValue: null, productId: null, quoteId: null }); }));
                    }

                    Promise.all(riderProductPromises)
                        .then(async (resultRiders) => {
                            for (const boosterToAdd of boostersToAdd) {
                                let parent = resultMain.find(r => r.productId === boosterToAdd.associatedProduct.DependentParentGuid);

                                if (!parent) {
                                    parent = resultRiders.find(r => r.productId === boosterToAdd.associatedProduct.DependentParentGuid);
                                }

                                if (parent) {
                                    let quoteId = parent.quoteId;

                                    const mainProductId = this.vm.quotePolicyCouples
                                        .map(q => q.quote)
                                        .find(q => q.UID === quoteId).ProductQuoteds
                                        .find(p => p.IsMainProduct).ProductConfig.UID;

                                    boosterProductPromises.push(setUpProduct(boosterToAdd.quoteProduct, boosterToAdd.associatedProduct, quoteId, mainProductId));
                                } else {
                                    let quoteId = self.vm.quotePolicyCouples
                                        .map(c => c.quote)
                                        .find(q => q.ProductQuoteds && q.ProductQuoteds.find(p => p.ProductConfig.UID === boosterToAdd.associatedProduct.UID) !== undefined)
                                        .UID;

                                    await self.productService.deleteRiderProduct(quoteId, boosterToAdd.associatedProduct.UID).toPromise();
                                }
                            }

                            if (boosterProductPromises.length === 0) {
                                boosterProductPromises.push(new Promise((resolve) => { resolve({ assuredValue: null, productId: null, quoteId: null }); }));
                            }

                            Promise.all(boosterProductPromises)
                                .then(_ => {
                                    const quoteObservables = this.vm.quotePolicyCouples.map(q => self.productService.getAQuote(q.quote.UID));

                                    forkJoin(quoteObservables).subscribe(
                                        (quoteResult) => {
                                            for (const couple of this.vm.quotePolicyCouples) {
                                                this.updateQuoteInPolicyCouple(couple.quote);
                                                couple.quote = quoteResult.find(r => r.UID === couple.quote.UID);

                                                if (!couple.quote.ProductQuoteds) {
                                                    continue;
                                                }

                                                for (const product of couple.quote.ProductQuoteds) {
                                                    let productFactory: Product;

                                                    if (product.IsMainProduct) {
                                                        productFactory = this.vm.productDetailsFromFactory.find(p => p.UID === product.ProductConfig.UID);
                                                    } else {
                                                        productFactory = associatedProductFactories.find(p => p.UID === product.ProductConfig.UID);
                                                    }

                                                    this.pushToProductSummary(product, productFactory, couple.quote);
                                                }
                                            }

                                            resolve(true);
                                        },
                                        (err) => {
                                            reject(err);
                                        });
                                })
                                .catch(err => {
                                    reject(err);
                                });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    CreateQuote(createQuote: CreateQuote, quoteId?: string): Observable<string> {

        if (this.vm.externalQuoteReferencId) {
            createQuote.OpportunityGUID = this.vm.externalQuoteReferencId;
        }

        return this.quirkService.CreateOrUpdateQuote(createQuote, quoteId)
            .pipe(map(res => {
                return res;
            }));
    }

    createOrUpdatePolicyAndGetAnswers(policyId, sectionName, isCached = true): Observable<{ extId: string, questions: GetQuestionariesModule.IQuestion[] }[]> {
        return this.quirkService.createOrUpdatePolicy({
            AnsweredQuestions: [],
            CedantId: this.CEDANT_ID,
            CustomerReferenceid: this.vm.entityId,
            DisplayAnswers: 'true',
            PolicyReferenceId: policyId || this.vm.quoteId,
            ProcessDisclosures: 'true',
            ShowErrorDetails: '1'
        }, isCached).pipe(map(res => {
            this.vm.currentPolicyId = res.PolicyDetail.PolicyReferenceId;
            this.vm.quoteId = res.PolicyDetail.PolicyReferenceId;
            return this.quirkService.processGetQuestionForStepper(res.PolicyDetail, sectionName);
        }));
    }

    getBenefitPatternSetList(uidString: string): Observable<BenefitModule.IBenefitModel> {
        return this.productService.getBenefitPatternSet(uidString)
            .pipe(map(res => {
                return res;
            }));
    }

    getPremiumPatternSetList(uidString: string): Observable<PremiumPatternModule.IPremiumPatternModel> {
        return this.productService.getPremiumPatternSet(uidString)
            .pipe(map(res => {
                return res;
            }));
    }



    processRateTable(res: RateTableModule.IRateTableModel[]): RateTableModule.IRateTableModel[] {
        return res.filter(x => x.RatingFactors.some(y => y.ShortCode.startsWith('SocioEconomicClass') && y.Description === 'Class_1'))
            .filter(x => x.RatingFactors.some(y => y.ShortCode.startsWith('OccupationCategory') && y.Description === 'Class_1'))
            .filter(x => x.RatingFactors.some(y => y.ShortCode.startsWith('SmokingStatus') && y.Description === 'NonSmoker'))
            .filter(x => x.RatingFactors.some(y => y.ShortCode.startsWith('Gender') && y.Description === this.policyService.personRatingFactor.gender))
            .filter(x => x.RatingFactors.some(y => y.ShortCode.startsWith('Age') && y.Description === this.policyService.personRatingFactor.age.toString()));
    }


    postProductQuoted(quoteId: string, productQuoteData: ProductQuote, productQuotedId: string): Observable<any> {
        const productQuoteId = this.productQuoteDictionary.get(quoteId);
        let requestMethod = productQuoteId ? 'put' : 'post';
        return this._productService.productQuoted(quoteId, productQuoteData, productQuoteId, requestMethod)
            .pipe(map(res => {
                if (res) {
                    this.productQuoteDictionary.set(quoteId, res.GUID);
                }
                return res;
            }));
    }

    async getProductAsync() {
        let self = this;
        const allowedDisabilityProductNames = await getAllowedDisabilityProductNames();

        const securitasBrokerage = await this.quirkService.getBrokerage('Securitas').toPromise();
        const quote = this.vm.quotePolicyCouples.find(c => Boolean(c.quote) && Boolean(c.quote.Person_Broker));
        const isSecuritasBroker = quote && securitasBrokerage.Brokers.some(b => b.Person.GUID === quote.quote.Person_Broker.Identifier);

        let productFactory = await new ProductFactory(this.injector)
            .withMetadataAsync(abp.setting.values['ActiveGenerationName'], isSecuritasBroker, allowedDisabilityProductNames);

        let mainProductFullyUIDList = productFactory
            .extractFulllyMainProductUIDList();

        let mainProductSemiUIDList = productFactory
            .extractSemiMainProductUIDList();

        let allProductUIDList = productFactory
            .extractAllProductUIDList();

        let automaticRiderUIDList = productFactory
            .extractAutomaticRiderUIDList();

        let parentAndChildUIDList = productFactory
            .extractParentAndChildUIDList(automaticRiderUIDList);

        await deleteExcludedProducts(mainProductFullyUIDList, mainProductSemiUIDList, self, allProductUIDList, automaticRiderUIDList, parentAndChildUIDList);

        this.vm.productSyncedOrNot = false;
        this.vm.productDetailsFromFactory = [];
        this.vm.needsAssessmentSumAssuredLevels = await this.productService.getNeedsAssessmentSumAssuredLevels().toPromise();

        if (mainProductSemiUIDList.length > 0) {
            await populateProductDetailsFromFactory(mainProductSemiUIDList, true);
        } else {
            await populateProductDetailsFromFactory(mainProductFullyUIDList, false);
        }

        async function getAllowedDisabilityProductNames(): Promise<Array<string>> {
            const allowedDisabilityProductNames: Array<string> = [];

            if (self.vm.userDetails.age >= 60) {
                return [];
            }

            const url = abp.setting.values['PortalSettings.OccupationDisabilityExclusionsUrl'];

            const occupationsDisabilityExclusions = await self.httpMethodBaseService.get<{
                allowAll: Array<string>,
                allowDisabilityCapitalProtection: Array<string>,
                allowDisabilityIncomeProtection: Array<string>,
                allow7Day30Day: Array<string>,
                allow90Day: Array<string>
            }>(url).toPromise();

            if (occupationsDisabilityExclusions.allowAll.includes(self.vm.userDetails.occupation)) {
                allowedDisabilityProductNames.push('disability', 'occupation', '7day', '30day', '90day', 'employed');
                return allowedDisabilityProductNames;
            }

            if (occupationsDisabilityExclusions.allowDisabilityCapitalProtection.includes(self.vm.userDetails.occupation)) {
                allowedDisabilityProductNames.push('capital', 'occupation');
            }

            if (occupationsDisabilityExclusions.allowDisabilityIncomeProtection.includes(self.vm.userDetails.occupation)) {
                allowedDisabilityProductNames.push('income', 'employed', '7day', '30day', '90day');
                return allowedDisabilityProductNames;
            }

            if (occupationsDisabilityExclusions.allow7Day30Day.includes(self.vm.userDetails.occupation)) {
                allowedDisabilityProductNames.push('7day', '30day', 'employed', 'family');
            }

            if (occupationsDisabilityExclusions.allow90Day.includes(self.vm.userDetails.occupation)) {
                allowedDisabilityProductNames.push('90day');
            }

            return allowedDisabilityProductNames;
        }

        async function deleteExcludedProducts(fullyUidList: Array<string>, semiUidList: Array<string>, self: MyCommandCenterService, allProductUidList: Array<string>, automaticRiderUidList: Array<string>, parentAndChildUIDList: Map<string, string[]>): Promise<void> {
            const allGuids = fullyUidList.concat(semiUidList);
            const quotedProductGuids = (self.vm.quotePolicyCouples
                .filter(q => q.quote && q.quote.ProductQuoteds && q.quote.ProductQuoteds.length > 0)
                .map(q => q.quote.ProductQuoteds)
                .customFlat() as QuoteModule.IProductQuoted[])
                .filter(p => p.IsMainProduct)
                .map(p => p.ProductConfig.UID);

            const excludedGuids = quotedProductGuids.filter(q => !allGuids.includes(q));
            const removeProductPromises: Array<Promise<any>> = [];
            const getQuotePromises: Array<Observable<QuoteModule.IQuoteDetails>> = [];

            const allQuotedProductGuids = (self.vm.quotePolicyCouples
                .filter(q => q.quote && q.quote.ProductQuoteds && q.quote.ProductQuoteds.length > 0)
                .map(q => q.quote.ProductQuoteds)
                .customFlat() as QuoteModule.IProductQuoted[])
                .filter(p => !automaticRiderUidList.includes(p.ProductConfig.UID))
                .map(p => p.ProductConfig.UID);

            const riderExcludedGuids = allQuotedProductGuids.filter(q => !allProductUidList.includes(q));
            let allExcludedGuids = excludedGuids.concat(riderExcludedGuids);

            let ridersAlreadyRemovedDueToMainProduct: string[] = [];

            for (const [key, value] of parentAndChildUIDList.entries()) {
                value.forEach(x => {
                    if (allExcludedGuids.includes(x) && allExcludedGuids.includes(key)) {
                        ridersAlreadyRemovedDueToMainProduct.push(x);
                    }
                });
            }

            allExcludedGuids = allExcludedGuids.filter(x => !ridersAlreadyRemovedDueToMainProduct.includes(x));

            for (const excludedGuid of allExcludedGuids.filter((v, i, a) => a.indexOf(v) === i)) {
                const quoteUid = self.vm.quotePolicyCouples
                    .map(q => q.quote)
                    .filter(q => q.ProductQuoteds)
                    .find(q => q.ProductQuoteds.map(q => q.ProductConfig.UID).includes(excludedGuid))
                    .UID;

                removeProductPromises.push(self.productService.deleteRiderProduct(quoteUid, excludedGuid).toPromise());
                getQuotePromises.push(self.productService.getAQuote(quoteUid));
            }

            if (removeProductPromises.length > 0) {
                await Promise.all(removeProductPromises);
                const getResults = await Promise.all(getQuotePromises.map(q => q.toPromise()));

                for (const getResult of getResults) {
                    self.updateQuoteInPolicyCouple(getResult);
                }
            }
        }

        async function populateProductDetailsFromFactory(productUids: Array<string>, useSemi: boolean): Promise<{}> {
            return new Promise((resolve) => {
                let iterationsNeeded = productUids.length;

                let excludedProducts = self.vm.needsAssessmentSumAssuredLevels.filter(x => x.excluded !== null);
                for (let mainProduct of productUids) {
                    buildProduct(mainProduct, allowedDisabilityProductNames).then(async (product) => {
                        let setPatterns = false;

                        if ((useSemi && product.Name.toLocaleLowerCase().includes('semi')) || (!useSemi && !product.Name.toLocaleLowerCase().includes('semi'))) {
                            setPatterns = true;
                        }

                        let excludedProductSubCategory = excludedProducts.map(x => x.excluded).includes(product.DisplayName) ? product.DisplayName : '';
                        const maxSumAssured = await self.getMaxSumAssured(product.UID, product.MaxBenefitAmount, { isAccelerator: false, skipExistingCover: true, productSubCategory: excludedProductSubCategory });
                        let upperLimit: number;
                        let sumAssuredRateToAdd: number | null = null;

                        const quircCategory = self.vm.quircAfterSubmittingAnalysis.find(q => q.MendixProductUID === mainProduct).QuircProfileName;
                        const needsAssessmentSumAssuredLevel = self.vm.needsAssessmentSumAssuredLevels.find(n => n.quircCategory === quircCategory);

                        excludedProducts.forEach(element => {
                            if (product.DisplayName !== element.excluded) {
                                // If client can have sum assured higher than no-needs-assessment sum assured, get rates up to
                                // no-needs-assessment sum assured and get rates for sum assured limit = full-needs-assessment
                                // sum assured and infer rates between no-needs-assessment and full-needs-assessment.
                                if (maxSumAssured > needsAssessmentSumAssuredLevel.noNeedsAssessmentSumAssured && maxSumAssured <= needsAssessmentSumAssuredLevel.partialNeedsAssessmentSumAssured) {
                                    sumAssuredRateToAdd = needsAssessmentSumAssuredLevel.partialNeedsAssessmentSumAssured;
                                    upperLimit = needsAssessmentSumAssuredLevel.noNeedsAssessmentSumAssured;
                                } else if (maxSumAssured > needsAssessmentSumAssuredLevel.noNeedsAssessmentSumAssured) {
                                    sumAssuredRateToAdd = needsAssessmentSumAssuredLevel.fullNeedsAssessmentSumAssured;
                                    upperLimit = needsAssessmentSumAssuredLevel.noNeedsAssessmentSumAssured;
                                } else {
                                    upperLimit = maxSumAssured;
                                }
                            } else {
                                upperLimit = maxSumAssured;
                            }
                        });

                        if (upperLimit < product.MinBenefitAmount) {
                            iterationsNeeded--;

                            if (self.vm.productDetailsFromFactory.length === iterationsNeeded) {
                                notifyProductsLoaded();
                                resolve({});
                            }
                        } else {

                            if (setPatterns) {
                                let inceptionDate = moment();
                                inceptionDate = inceptionDate.isSameOrBefore(moment(self.vm.quotePolicyCouples[0].quote.InceptionDate)) ? moment(self.vm.quotePolicyCouples[0].quote.InceptionDate) : inceptionDate;
                                let birthDate = self.vm.userDetails.identityNumber.getDateOfBirthByIdNumber();
                                let age = inceptionDate.diff(birthDate, 'years').toString();
                                product.getRateTableAsync(self.injector, product.MinBenefitAmount, upperLimit, sumAssuredRateToAdd, age).then(() => {
                                    product.setDefaultBenefitPattern();
                                    product.setDefaultPremiumPattern();
                                    self.vm.productDetailsFromFactory.push(product);

                                    if (self.vm.productDetailsFromFactory.length === iterationsNeeded) {
                                        notifyProductsLoaded();
                                        resolve({});
                                    }
                                });
                            } else {
                                self.vm.productDetailsFromFactory.push(product);

                                if (self.vm.productDetailsFromFactory.length === iterationsNeeded) {
                                    notifyProductsLoaded();
                                    resolve({});
                                }
                            }
                        }
                    });
                }
            });
        }

        function notifyProductsLoaded(): void {
            self.vm.productSyncedOrNot = true;
            self.vm.producSpinnerEmitter.next(true);
        }

        async function buildProduct(mainProductUID, allowedDisabilityProductNames: Array<string>): Promise<Product> {
            let copy = Object.assign(Object.create(Object.getPrototypeOf(productFactory)), productFactory);

            copy
                .forProduct(mainProductUID)
                .extractUID()
                .extractName()
                .extractCategory()
                .extractDisplayName()
                .extractEventSet()
                .extractMaxBenefitAmount()
                .extractMinBenefitAmount()
                .excludeRiders(allowedDisabilityProductNames);

            let benefitPatternSetPromise = copy
                .extractBenefitPatternSetAsync();

            let premiumPatternSetPromise = copy
                .extractPremiumPatternSetAsync();

            await Promise.all([benefitPatternSetPromise, premiumPatternSetPromise]);
            await copy
                .extractAssociatedProductsAsync();

            let product = copy
                .build();

            return Promise.resolve(product);
        }
    }

    getPolicyToClone(): Promise<any> {
        return new Promise((resolve, reject) => {
            let request: CreateOrUpdatePolicyInputModule.IUpdatePolicyOfApplicantWithAnswers = {};
            request = {
                CedantId: this.CEDANT_ID,
                CustomerReferenceid: this.vm.entityId,
                DisplayAnswers: 'true',
                PolicyReferenceId: this.vm.quoteId,
                ProcessDisclosures: 'true',
                ShowErrorDetails: '1',
                AnsweredQuestions: []
            };

            this.quirkService.createOrUpdatePolicy(request, true).subscribe(
                (result) => {
                    try {
                        const answeredQuestions: Array<CreateOrUpdatePolicyInputModule.IAnsweredQuestion> = [];

                        for (const answer of result.PolicyDetail.AnsweredQuestions) {
                            if (!this.quirkService.checkQuestionPresent(answer.ExternalReferenceId)) {
                                continue;
                            }

                            const isNumber = this.quirkService.getQuestionByExtReferenceId([answer.ExternalReferenceId])[0].Answer[0].DataType;

                            if (answer.MultipleAnswers && answer.MultipleAnswers.length > 0) {
                                answeredQuestions.push({
                                    ExternalReferenceId: answer.ExternalReferenceId,
                                    MultipleAnswers: answer.MultipleAnswers
                                });
                            } else {
                                answeredQuestions.push({
                                    ExternalReferenceId: answer.ExternalReferenceId,
                                    Value: isNumber === 'Number' ? +answer.Value : answer.Value
                                });
                            }
                        }

                        resolve(answeredQuestions);
                    } catch (e) {
                        reject(e);
                    }
                },
                (err) => {
                    reject(err);
                });
        });
    }

    clonePolicy(quotId, anseweredQuestions: CreateOrUpdatePolicyInputModule.IAnsweredQuestion[], callback) {
        let request: CreateOrUpdatePolicyInputModule.IUpdatePolicyOfApplicantWithAnswers = {};
        request = {
            CedantId: this.CEDANT_ID,
            CustomerReferenceid: this.vm.entityId,
            DisplayAnswers: 'true',
            PolicyReferenceId: quotId,
            ProcessDisclosures: 'true',
            ShowErrorDetails: '1',
            AnsweredQuestions: anseweredQuestions
        };

        this.quirkService.createOrUpdatePolicy(request, false)
            .subscribe(res => {
                callback();
            });
    }

    checkIfStepHasQuestions() {
        let section: GetQuestionariesModule.ISection;

        section = {
            ExternalReferenceId: AppConsts.ParentSteps.Additionalinfo,
            Questions: []
        };

        this.submitQuestionAnswers(section, this.vm.currentPolicyId, true)
            .subscribe(res => {
                this.vm.isImprovingQuoteNeccesary = res.length === 0;
            });
    }


    getFormattedText(item: string): string[] {
        if (item) {

            let strArr = [];
            item = item.replace('  ', '');

            AppConsts.disclaimerText.StartingAndEndingsOfEveryParagraph.forEach(res => {
                if (item.indexOf(res.substring(0, getIndex(res))) > -1 && item.indexOf(res.substring(getIndex(res) + 2)) > -1) {
                    let items = item.substr(item.indexOf(res.substring(0, getIndex(res))), item.indexOf(res.substr(getIndex(res)).replace('##', '')) + (res.substr(getIndex(res)).replace('##', '').length));
                    let item2 = item.substr(item.indexOf(res.substring(0, getIndex(res))), items.indexOf(res.substr(getIndex(res)).replace('##', '')) + (res.substr(getIndex(res)).replace('##', '').length));
                    strArr.push(item2);
                }

            });

            if (strArr.length === 0) {
                return [item];
            }
            return strArr;
        }

        function getIndex(item: string) {
            return item.indexOf('##');
        }
    }

    getMedicalSchemes(accessToken): Observable<Array<PersonModule.MedicalScheme>> {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
        });

        let url_ = this.API_BASE_URL_IDP + 'api/DirectSales/MedicalSchemes';
        return this.httpMethodBaseService.get<Array<PersonModule.MedicalScheme>>(url_, headers);
    }

    createPerson(callback?, showMessage: boolean = true) {
        let person: PersonModule.IPersonInput = {
            Education: this.vm.userDetails.education,
            EffectiveDate: new Date(),
            GrossIncome: this.vm.userDetails.grossSalary,
            IDP_GUID: this.vm.entityId,
            SmokingStatusPUT: this.vm.userDetails.smokingStatus === 'Smoker' ? 'Smoker' : 'Non_Smoker',
            OccupationCategoryPUT: defaultOccupationClass(this.vm.userDetails.occupationCategory) || 'Class_1',
            SocioEconomicClassPUT: this.vm.userDetails.socialEconomic,
            PersonAPI: {
                ContactNumber: this.vm.userDetails.cellNumber,
                Email: this.vm.userDetails.email,
                DateOfBirth: this.vm.userDetails.identityNumber.toString().getDateOfBirthByIdNumber(),
                FirstName: this.vm.userDetails.name,
                Surname: this.vm.userDetails.surname,
                Gender: this.vm.userDetails.identityNumber.toString().getGenderFromIdNumber(),
                IdentificationType: 'IDNumber',
                IDNumber: this.vm.userDetails.identityNumber,
                Initials: this.vm.userDetails.name.substring(0, 1)
            }
        };
        this.policyService.createOrEditPerson(person, this.vm.entityId)
            .subscribe(res => {
                if (showMessage) {
                    abp.notify.success('personal details updated');
                }

                if (callback) { return callback(); }
            });
    }

    getQuoteFlowIsImprovingNeeded() {
        this.quirkService.createOrUpdatePolicy({
            AnsweredQuestions: [],
            CedantId: this.CEDANT_ID,
            CustomerReferenceid: this.vm.entityId,
            PolicyReferenceId: this.vm.quoteId,
            DisplayAnswers: 'true',
            ProcessDisclosures: 'true',
            ShowErrorDetails: '1'
        }, true).subscribe(res => {
            let result = res.PolicyDetail.AnsweredQuestions.find(x => x.ExternalReferenceId === AppConsts.DND_Questions.DND_sales_channel);
            if (result) {
                this.vm.isImprovingQuoteNeccesary = result.Value !== 1;
            }
        });
    }

    changeTemplatePopup(item, item1) {
        if (item !== undefined) {
            (document.getElementsByClassName('swal2-confirm')[0] as any).innerText = item;
            document.getElementsByClassName('swal2-confirm')[0].classList.add('modal-button-clr');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('font-size-12');
        } else {
            document.getElementsByClassName('swal2-confirm')[0].classList.add('d-none');
        }

        if (item1 !== undefined) {
            (document.getElementsByClassName('swal2-cancel')[0] as any).innerText = item1;
            document.getElementsByClassName('swal2-cancel')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-cancel')[0].classList.add('font-size-12');
        } else {
            document.getElementsByClassName('swal2-cancel')[0].classList.add('d-none');
        }
    }

    sendPolicyReferencesEmail(): void {
        const self = this;

        function getStatus(submitResult: SubmitQuestionnaireModule.SubmitQuestionnaire): string {
            if (self.isQuestionnaireReferred(submitResult)) {
                return 'Medical Referred';
            }

            if (submitResult.PolicyStatusName === AppConsts.quircSubmittingQuestionAnalysis.PolicyStatusName.Referred) {
                return 'HIV Referred';
            }

            return submitResult.PolicyStatusName;
        }

        const couple = this.vm.quotePolicyCouples.find(c => c.policy && c.policy.ExternalReferenceId && c.policy.ExternalReferenceId === this.vm.currentPolicyId);

        this.policyService.queuePolicyReferencesEmail(
            this.vm.currentPolicyId,
            this.vm.submitQuestionaireResult,
            getStatus(this.vm.submitQuestionaireResult),
            couple.quote.Person_LifeAssured.Identifier,
            couple.quote.Person_MainMember.Identifier,
            couple.quote.Person_Broker.Identifier
        ).subscribe(() => {
        }, (err) => {
            console.error(err);
        });
    }

    private showExclusionPopups(): Promise<void> {
        this.vm.productExclusionMap = [];

        for (const product of this.vm.submitQuestionaireResult.Products) {
            const exclusions: Array<string> = product.ProductReferences.filter(r => r.TypeName === 'Exclusions').map(e => e.Reference);

            if (exclusions.length === 0) {
                continue;
            }

            const quircProduct = this.vm.quircAfterSubmittingAnalysis.find(q => q.QuircProfileName === product.ProfileName);
            const descriptionCategory = product.ProfileName === 'Impairment Cover' ? 'Disability Capital Protection' : product.ProfileName.includes('Income Protection') ? 'Disability Income Protection' : quircProduct.MendixProductCategory;

            const existingProductExclusionMap = this.vm.productExclusionMap.find(p => p.descriptionCategory === descriptionCategory);

            if (existingProductExclusionMap) {
                existingProductExclusionMap.exclusions.push(...exclusions);
                existingProductExclusionMap.exclusions = existingProductExclusionMap.exclusions.filter((v, i, a) => a.indexOf(v) === i);
            } else {
                this.vm.productExclusionMap.push({
                    descriptionCategory: descriptionCategory,
                    productCategory: quircProduct.MendixProductCategory,
                    exclusions: exclusions.filter((v, i, a) => a.indexOf(v) === i)
                });
            }
        }

        let countOfDisabilityProducts = this.vm.productDetailsFromFactory.map(x => x.Category).filter(y => y === 'Disability Protection').length;

        if (countOfDisabilityProducts === 1) {
            this.vm.productExclusionMap = this.vm.productExclusionMap.filter(x => x.descriptionCategory !== 'Disability Capital Protection');
        }

        let avaiableProductCategories = this.vm.productDetailsFromFactory.map(x => x.Category).filter((v, i, a) => a.indexOf(v) === i);

        let productExclusionMap = this.vm.productExclusionMap.filter(x => avaiableProductCategories.includes(x.productCategory));

        let exlusionPopupContent = '';

        for (const productExclusion of this.vm.productExclusionMap) {
            exlusionPopupContent += '<b>' + productExclusion.descriptionCategory + '</b><br>';

            for (const exclusion of productExclusion.exclusions) {
                exlusionPopupContent += exclusion + '<br><br>';
            }
        }

        exlusionPopupContent = 'In addition to the General Exclusions that apply to all Elevate policies, the following additional individual exclusions will apply to your policies:<br><br>' + exlusionPopupContent;
        const message = abp.message.info(exlusionPopupContent, 'INDIVIDUAL EXCLUSIONS WILL APPLY', true);
        this.changeTemplatePopup('CONTINUE', undefined);
        return message;
    }

    async policyStatusChecks() {
        if (this.isQuestionnaireReferred(this.vm.submitQuestionaireResult) || this.isQuestionnaireDeclined(this.vm.submitQuestionaireResult)) {
            this.vm.isReferred = true;
            await this.showReferredModal();
        }

        if (this.isQuestionaireExcluded(this.vm.submitQuestionaireResult)) {
            await this.showExclusionPopups();
        }

        if (this.vm.isMinimumBenefitNotMet) {
            await this.showMinimumBenefitNotMetModal();
        }
    }

    isQuestionnaireDeclined(questionnaire: SubmitQuestionnaireModule.SubmitQuestionnaire): boolean {
        return questionnaire.PolicyStatusName === AppConsts.quircSubmittingQuestionAnalysis.PolicyStatusName.Declined;
    }

    isQuestionnaireReferred(questionnaire: SubmitQuestionnaireModule.SubmitQuestionnaire): boolean {
        return questionnaire.PolicyStatusName === AppConsts.quircSubmittingQuestionAnalysis.PolicyStatusName.Referred && this.vm.submitQuestionaireResult.PolicyReferences.some(p => p.TypeName === 'Refer \/ Decline Reasons');
    }

    isQuestionaireExcluded(questionnaire: SubmitQuestionnaireModule.SubmitQuestionnaire): boolean {
        if (!questionnaire.Products || questionnaire.Products.length === 0) {
            return false;
        }

        return questionnaire.Products.some(p =>
            p.ProductReferences &&
            p.ProductReferences.length > 0 &&
            p.ProductReferences.some(r => r.TypeName === 'Exclusions'));
    }

    showMinimumBenefitNotMetModal(): Promise<void> {
        const message = abp.message.warn('Given your income, one of your previously quoted products has been removed.', 'MINIMUM ASSURED LEVEL NOT MET');
        this.changeTemplatePopup('OK', undefined);
        return message;
    }

    showReferredModal(): Promise<void> {
        const message = abp.message.info(
            'We cannot confirm your underwriting just yet as we will need additional medical information from your doctor. <br><br>' +
            'An Elevate Customer Service agent will be in contact shortly to outline the next steps.<br><br>' +
            'In the meantime, please <b>CONTINUE</b> to see the Elevate products on offer.<br><br>' +
            '<b>Please note:</b> pricing will be indicative until your final premium is determined after we can confirm the remaining underwriting information needed.',
            'MORE INFORMATION NEEDED',
            true
        );

        this.changeTemplatePopup('CONTINUE', undefined);
        return message;
    }

    private showDeclinedModal(): Promise<void> {
        const popup = abp.message.error(
            'Unfortunately, we aren\'t able to tailor any products to match your needs today :(<br><br>But this shouldn\'t be the case for long!<br><br><a target="_blank" href="https://www.elevate.co.za/how-it-works/"><u>Find out more</u></a>',
            'Policy Declined',
            true);

        this.changeTemplatePopup('OK', undefined);
        return popup;
    }

    getProductDescription(product: Product): string {
        if (!product) {
            return '';
        }

        if (product.Category === 'Severe Illness Protection') {
            return 'you are to suffer a severe illness';
        }

        if (product.Category === 'Disability Protection') {
            if (product.AssociatedProducts.length > 0) {
                return 'you being unable to work due to illness or disability';
            }

            if (product.DisplayName === 'Principal Disability Capital Protection - Accelerator') {
                return 'you are to suffer a disability';
            }

            if (product.DisplayName === 'Principal Disability Capital Protection') {
                return 'you are to suffer a disability';
            }

            return 'you becoming permanently disabled';
        }

        return 'your passing';
    }

    additiveunderwritingloading(productId) {
        const quircProduct = this.vm.quircAfterSubmittingAnalysis.find(x => x.MendixProductUID === productId);
        return (quircProduct.sumUnitLoading / 1000) / 12;
    }

    multipicativeunderwritingloading(productId) {
        const quircProduct = this.vm.quircAfterSubmittingAnalysis.find(x => x.MendixProductUID === productId);
        return (quircProduct.sumPercentageLoading / 100) + 1;
    }
}
