﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class HttpMethodBaseService {
    constructor(private http: HttpClient) {
    }

    get<T>(url: string, headers?: HttpHeaders): Observable<T> {
        return this.http.get<T>(url, { headers: headers });
    }


    post<T>(url, body?, headers?: HttpHeaders): Observable<T> {
        return this.http.post<T>(url, body, { headers: headers });
    }

    put<T>(url, body?, headers?): Observable<T> {
        return this.http.put<T>(url, body, { headers: headers });

    }

    delete<T>(url, headers?: HttpHeaders): Observable<T> {
        return this.http.delete<T>(url, { headers: headers });
    }

    request<T>(request, url, body?, headers?: HttpHeaders) {
        return this.http.request<T>(request, url, { body: body, headers: headers });
    }
}
