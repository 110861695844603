var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RatingFactorModule } from '@shared/models/interfaces/elevate-data-models';
import { AppConsts } from '@shared/AppConsts';
import { HttpMethodBaseService } from './HttpMethodsBase/httpmethods-base';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';
var PolicyService = /** @class */ (function () {
    function PolicyService(httpMethodBaseService, _authService, API_BASE_URL) {
        this.httpMethodBaseService = httpMethodBaseService;
        this._authService = _authService;
        this.API_BASE_URL = API_BASE_URL;
        this.policyService = new Map();
        this.ELEVATE_ACCP_ENDPOINT = abp.setting.values[AppConsts.KeyVaultSecrets.apimElevateAccpendpoint];
        this.APIM_CLIENT_ENDPOINT = abp.setting.values[AppConsts.KeyVaultSecrets.apimClientEndpoint];
        this.API_BASE_URL_IDP = abp.setting.values['PortalSettings.authority'];
        this.personRatingFactor = new RatingFactorModule.PersonRatingFactor(undefined, null, null, null, null);
    }
    PolicyService.prototype.getPolicySets = function (policyList) {
        var _this = this;
        var responseData = [];
        policyList.forEach(function (x) {
            _this.getPolicy(x.Identifier).subscribe(function (result) {
                if (result) {
                    responseData.push(result);
                }
            });
        });
        return responseData;
    };
    PolicyService.prototype.updatePersonAsync = function (entityId) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getPerson(entityId, false).toPromise()];
                    case 1:
                        res = _a.sent();
                        this.personRatingFactor = new RatingFactorModule.PersonRatingFactorFactory()
                            .withPersonMetaData(res)
                            .getAge()
                            .getSmoker()
                            .getOccupational()
                            .getGender()
                            .getSocials()
                            .build();
                        return [2 /*return*/];
                }
            });
        });
    };
    PolicyService.prototype.getPolicy = function (policyUid, cachedResult) {
        var _this = this;
        if (cachedResult === void 0) { cachedResult = true; }
        var _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPolicy.replace('{PolicyGUID}', policyUid);
        var cachedPolicyData = this.policyService.get(_url);
        if (cachedPolicyData && cachedResult) {
            return of(cachedPolicyData);
        }
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        var response = this.httpMethodBaseService.get(_url, headers)
            .pipe(map(function (res) {
            _this.policyService.set(_url, res);
            return res.result;
        }));
        return response;
    };
    PolicyService.prototype.isSubmitted = function (policyStatusName) {
        var submittedStatuses = [
            'Declined',
            'Approved',
            'Referred',
            'ApprovedWithExclusion',
            'ApprovedWithPostponement',
            'ApprovedWithPostponementAndExclusion',
            'ApprovedWithLoading',
            'ApprovedWithLoadingAndExclusion',
            'ApprovedWithLoadingAndExclusionAndPostponement',
            'ApprovedWithLoadingAndPostponement'
        ];
        return submittedStatuses.includes(policyStatusName);
    };
    PolicyService.prototype.getPerson = function (personId, isCaching) {
        var _this = this;
        if (isCaching === void 0) { isCaching = true; }
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        var _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPerson.replace('{personGUID}', personId);
        var conditionsFromCache = this.policyService.get(_url);
        if (conditionsFromCache && isCaching) {
            return of(conditionsFromCache);
        }
        var response = this.httpMethodBaseService.get(_url, headers)
            .pipe(map(function (res) {
            _this.policyService.set(_url, res.result);
            return res.result;
        }));
        return response;
    };
    PolicyService.prototype.updateBeneficiaries = function (policyGuid, productGuid, effectiveDate, beneficiaries) {
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        var _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.updateBeneficiaries
            .replace('{PolicyGUID}', policyGuid)
            .replace('{ProductGUID}', productGuid);
        var body = {
            EffectiveDate: effectiveDate,
            Beneficiarys: beneficiaries
        };
        var _cachedUrl = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPolicy.replace('{PolicyGUID}', policyGuid);
        this.policyService.delete(_cachedUrl);
        return this.httpMethodBaseService.put(_url, body, headers);
    };
    PolicyService.prototype.createOrEditPerson = function (person, personId) {
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        var _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.createPerson.replace('{personGUID}', personId);
        return this.httpMethodBaseService.put(_url, person, headers);
    };
    PolicyService.prototype.queueUpdatePersonSync = function (personId) {
        var _url = this.API_BASE_URL_IDP + AppConsts.Endpoints.queueUserUpdate + personId;
        return this.httpMethodBaseService.post(_url);
    };
    PolicyService.prototype.queuePolicyToCrm = function (policyId) {
        var _url = this.API_BASE_URL_IDP + AppConsts.Endpoints.queuePolicy + policyId;
        return this.httpMethodBaseService.post(_url);
    };
    PolicyService.prototype.queuePolicyReferencesEmail = function (policyGuid, policyReferences, policyStatusName, lifeAssuredGuid, premiumPayerGuid, brokerGuid) {
        var headers = new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        });
        var body = {
            policyGuid: policyGuid,
            policyReferences: JSON.stringify(policyReferences, null, 1),
            policyStatusName: policyStatusName,
            lifeAssuredGuid: lifeAssuredGuid,
            premiumPayerGuid: premiumPayerGuid,
            brokerGuid: brokerGuid
        };
        var url = this.API_BASE_URL_IDP + AppConsts.Endpoints.queuePolicyReferencesMail;
        return this.httpMethodBaseService.post(url, body, headers);
    };
    PolicyService.prototype.queuePolicyForEmailNotification = function (emailid, policyId, contract, policyNumber, userName) {
        var headers = new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        });
        var body = {
            email: emailid,
            policyId: policyId,
            contract: contract,
            policyNumber: policyNumber,
            includeIntermediaryDocument: true,
            userFirstName: userName
        };
        var _url = this.API_BASE_URL_IDP + AppConsts.Endpoints.queuePolicyForEmailNotification.replace('{EMAIL}', emailid).replace('{POLICY_ID}', policyId);
        return this.httpMethodBaseService.post(_url, body, headers);
    };
    PolicyService.prototype.getPolicyContract = function (policyId) {
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        var _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getContract.replace('{policyId}', policyId);
        return this.httpMethodBaseService.get(_url, headers).pipe(map(function (res) {
            return res.result.Result;
        }));
    };
    PolicyService.prototype.getPoliciesForLifeAssured = function (lifeAssuredGuid, accessToken) {
        return this.httpMethodBaseService
            .get(this.API_BASE_URL + "/api/services/app/Policy/GetPoliciesForLifeAssured?lifeAssuredGuid=" + lifeAssuredGuid + "&accessToken=" + accessToken)
            .pipe(map(function (res) { return res.result; }));
    };
    PolicyService.prototype.getPoliciesForPremiumPayer = function (premiumPayerGuid, accessToken) {
        return this.httpMethodBaseService
            .get(this.API_BASE_URL + "/api/services/app/Policy/GetPoliciesForPremiumPayer?premiumPayerGuid=" + premiumPayerGuid + "&accessToken=" + accessToken)
            .pipe(map(function (res) { return res.result; }));
    };
    PolicyService.prototype.getPolicyFromRates = function (policyOrQuoteGuid, accessToken) {
        return this.httpMethodBaseService
            .get(this.API_BASE_URL + "/api/services/app/Policy/GetPolicy?policyOrQuoteGuid=" + policyOrQuoteGuid + "&accessToken=" + accessToken)
            .pipe(map(function (res) { return res.result; }));
    };
    return PolicyService;
}());
export { PolicyService };
