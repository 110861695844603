import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import { bounceIn } from '@shared/animations/routerTransition';
import { ClaimModifyServiceProxy, HealthCareApiServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { HraAssessmentBaseComponent } from '../hra-assessment-base/hra-assessment-base.component';

@Component({
    selector: 'hra-assessment',
    templateUrl: './hra-assessment.component.html',
    styleUrls: ['./hra-assessment.component.css'],
    animations: [bounceIn()]
})
export class HraAssessmentComponent extends HraAssessmentBaseComponent implements OnInit {
    isSaving: boolean;
    isSubmitting: boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        injector: Injector,
        authGuard: AuthGuard,
        claimService: ClaimModifyServiceProxy,
        healthCareService: HealthCareApiServiceProxy,
        claimModifyService: ClaimModifyServiceProxy
    ) {
        super(injector, authGuard, claimService, healthCareService, claimModifyService);
    }

    ngOnInit() {
        abp.ui.setBusy();

        this.updateClaimsIdsvr().then(() => {
            this.questionnaireKey = this.route.snapshot.params.questionnaireKey;
            this.defaultValues = {
                gender: this._authService.user.profile['gender'],
                age: moment(moment()).diff(moment(this._authService.user.profile['birthdate'], 'DD/MM/YYYY hh:mm:ss A'), 'years')
            };

            this.setQuestionnaire().then(() => {
                abp.ui.clearBusy();
            });
        });
    }

    async submit(isCompleted: boolean): Promise<void> {
        if (isCompleted) {
            this.isSubmitting = true;
        } else {
            this.isSaving = true;
        }

        await super.submit(isCompleted);
        this.isSubmitting = this.isSaving = false;
        this.router.navigateByUrl('/app/main/MyHealthCare/RiskAssessment');
    }

    async breadcrumbNavigation(link: string): Promise<void> {
        abp.ui.setBusy();
        await super.submit(false);
        abp.ui.clearBusy();
        this.router.navigateByUrl(link);
    }

    get answeredQuestionDescription(): string {
        let shownCount = 0;
        let answeredCount = 0;

        for (const phase of this.currentQuestionnaire.phases.filter(p => p.isVisible)) {
            for (const question of phase.questions.filter(q => q.isVisible)) {
                shownCount++;

                if (question.answersGiven.length > 0) {
                    answeredCount++;
                }
            }
        }

        return `${answeredCount} / ${shownCount}`;
    }
}
