var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ActivatedRoute } from '@angular/router';
var Theme3BrandComponent = /** @class */ (function (_super) {
    __extends(Theme3BrandComponent, _super);
    function Theme3BrandComponent(injector, document, route) {
        var _this = _super.call(this, injector) || this;
        _this.document = document;
        _this.route = route;
        _this.defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + _this.currentTheme.baseSettings.menu.asideSkin + '.svg';
        _this.remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
        _this.isAuthenticated = _this._authService.isLoggedIn();
        return _this;
    }
    Object.defineProperty(Theme3BrandComponent.prototype, "isAuthenticated", {
        get: function () {
            return this._isAuthenticated;
        },
        set: function (value) {
            this._isAuthenticated = value;
        },
        enumerable: true,
        configurable: true
    });
    Theme3BrandComponent.prototype.isInExtQuote = function () {
        try {
            var url = this.route.snapshot['_routerState'].url;
            return url.includes('ExtQuote') || url.includes('ExtCalculator') || url.includes('fna');
        }
        catch (_a) {
            return false;
        }
    };
    Theme3BrandComponent.prototype.clickTopbarToggle = function () {
        this.document.body.classList.toggle('m-topbar--on');
    };
    Theme3BrandComponent.prototype.clickLeftAsideHideToggle = function () {
        this.document.body.classList.toggle('m-aside-left--hide');
    };
    return Theme3BrandComponent;
}(AppComponentBase));
export { Theme3BrandComponent };
