var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, NgZone, Injector } from '@angular/core';
import { MyWearablesService } from '@app/shared/services/my-wearables.service';
import { SmsBody } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
var WidgetMySyncedActivitiesComponent = /** @class */ (function (_super) {
    __extends(WidgetMySyncedActivitiesComponent, _super);
    function WidgetMySyncedActivitiesComponent(zone, injector, _wearableService) {
        var _this = _super.call(this, injector) || this;
        _this.zone = zone;
        _this._wearableService = _wearableService;
        _this.imgSrc = '../../../assets/common/images/chatblack.svg';
        return _this;
    }
    WidgetMySyncedActivitiesComponent.prototype.ngOnInit = function () {
        if (!this._wearableService.getAllSyncedAct || this._wearableService.getAllSyncedAct.length === 0) {
            this.isLoading = true;
        }
    };
    WidgetMySyncedActivitiesComponent.prototype.getAllSyncedIntenseCount = function () {
        var _this = this;
        this.isLoading = true;
        this._wearableService.getAllSyncedIntenseCount().then(function () {
            _this.isLoading = false;
        });
    };
    WidgetMySyncedActivitiesComponent.prototype.sendsms = function (PageName) {
        var body = new SmsBody();
        body.userid = this._wearableService._commonHelper._user.profile.sub;
        body.firstName = this._wearableService._commonHelper._user.profile.given_name;
        body.lastName = this._wearableService._commonHelper._user.profile.family_name;
        body.email = this._wearableService._commonHelper._user.profile.email;
        body.phoneNumber = Array.isArray(this._wearableService._commonHelper._user.profile.phone_number) ? this._wearableService._commonHelper._user.profile.phone_number[0] : this._wearableService._commonHelper._user.profile.phone_number;
        body.pageName = PageName;
        body.message = this.currentUserQuery;
        this.sendQueryEmail(body);
        this.currentUserQuery = '';
    };
    return WidgetMySyncedActivitiesComponent;
}(AppComponentBase));
export { WidgetMySyncedActivitiesComponent };
