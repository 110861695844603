import { WearablesServiceProxy, Last7Days, GetGoalsDto } from '@shared/service-proxies/service-proxies';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';
import { CommonhelperService } from './commonhelper.service';
import * as i0 from "@angular/core";
import * as i1 from "./commonhelper.service";
import * as i2 from "../../../shared/service-proxies/service-proxies";
import * as i3 from "../common/IDSVRAuthProviders/auth.service";
var MyWearablesService = /** @class */ (function () {
    function MyWearablesService(_commonHelper, _wearables, _authService) {
        this._commonHelper = _commonHelper;
        this._wearables = _wearables;
        this._authService = _authService;
        this.goalsMessage = '';
        this.getAllSyncedAct = new Array();
        this.last7Days = new Last7Days();
    }
    MyWearablesService.prototype.isMyDataWearableActive = function () {
        return this._commonHelper.userPermissionAllowd;
    };
    //#region month and week
    MyWearablesService.prototype.getActiveCalorieWeek = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._wearables.getActiveCalorieWeek(_this._authService.user.access_token)
                .subscribe(function (res) {
                _this.activeCalories = res;
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    MyWearablesService.prototype.getSteps = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._wearables.getStepsCurrentWeek(_this._authService.user.access_token)
                .subscribe(function (res) {
                _this.stepsDto = res;
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    MyWearablesService.prototype.getActiveMinutesWeek = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._wearables.getActiveMinutesWeek(_this._authService.user.access_token)
                .subscribe(function (res) {
                _this.activeMinues = res;
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    MyWearablesService.prototype.getDistanceTravelledWeek = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._wearables.getDistanceTravelledWeek(_this._authService.user.access_token)
                .subscribe(function (res) {
                _this.distanceDto = res;
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    MyWearablesService.prototype.getAllSyncedIntenseCount = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._wearables.getAllSyncedIntenseCount(_this._authService.user.access_token)
                .subscribe(function (res) {
                _this.getAllSyncedAct = res;
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    MyWearablesService.prototype.getLast7Async = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._wearables.getLast7Async(_this._authService.user.access_token)
                .subscribe(function (res) {
                _this.last7Days = res;
                resolve(res);
            }, function (err) {
                reject();
            });
        });
    };
    //#endregion
    //#region year
    MyWearablesService.prototype.getStepsYear = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._wearables.getStepsCurrentYear(_this._authService.user.access_token)
                .subscribe(function (res) {
                _this.stepsDto = res;
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    MyWearablesService.prototype.getActiveMinutesYear = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._wearables.getActiveMinutesYear(_this._authService.user.access_token)
                .subscribe(function (res) {
                _this.activeMinues = res;
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    MyWearablesService.prototype.getDistanceTravelledYear = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._wearables.getDistanceTravelledYear(_this._authService.user.access_token)
                .subscribe(function (res) {
                _this.distanceDto = res;
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    MyWearablesService.prototype.getActiveCalorieYear = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._wearables.getActiveCalorieYear(_this._authService.user.access_token)
                .subscribe(function (res) {
                _this.activeCalories = res;
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    //#endregion
    MyWearablesService.prototype.getGoalsAndCheckIfEntityRegistered = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._commonHelper.isEntityRegisteredWearables().then(function () {
                _this.GetGoals().then(function () {
                    resolve();
                }).catch(function () {
                    reject();
                });
            });
        });
    };
    MyWearablesService.prototype.GetGoals = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._wearables.getGoals(_this._authService.user.access_token).subscribe(function (res) {
                _this.goalsMessages = new Array();
                _this.goalsMessages = res;
                if (res) {
                    var d = new GetGoalsDto();
                    d = _this.goalsMessages.find(function (x) { return x.isAchieved === false; });
                    if (d === null || d === undefined) {
                        if (res[0] !== undefined) {
                            _this.goalsMessage = res[0].message;
                        }
                    }
                    else {
                        _this.goalsMessage = d.message;
                    }
                    if (_this.goalsMessage === undefined) {
                        _this.goalsMessage = '';
                    }
                }
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    MyWearablesService.ngInjectableDef = i0.defineInjectable({ factory: function MyWearablesService_Factory() { return new MyWearablesService(i0.inject(i1.CommonhelperService), i0.inject(i2.WearablesServiceProxy), i0.inject(i3.AuthService)); }, token: MyWearablesService, providedIn: "root" });
    return MyWearablesService;
}());
export { MyWearablesService };
