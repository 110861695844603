import { CommonhelperService } from './commonhelper.service';
import { HealthCareApiServiceProxy } from '@shared/service-proxies/service-proxies';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./commonhelper.service";
import * as i2 from "../../../shared/service-proxies/service-proxies";
import * as i3 from "../common/IDSVRAuthProviders/auth.service";
var MyHealthcareService = /** @class */ (function () {
    function MyHealthcareService(_commonHelper, _healthCare, _authService) {
        this._commonHelper = _commonHelper;
        this._healthCare = _healthCare;
        this._authService = _authService;
        this.episodeCareData = new Array();
    }
    MyHealthcareService.prototype.isMyHealthCareActive = function () {
        return this._commonHelper.healthcareactivated;
    };
    MyHealthcareService.prototype.getEpisodeData = function (year) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._healthCare.getPopulationEpisodeData(_this._authService.user.access_token, year).subscribe(function (res) {
                _this.episodeCareData = res;
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    MyHealthcareService.prototype.getHealthCareYearDropDownList = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._healthCare.getPopulationEpisodeDataYear(_this._authService.user.access_token).subscribe(function (res) {
                _this.yearList = res;
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    MyHealthcareService.prototype.getEpisodeOfCare = function (year) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._commonHelper.getHealthCareActivatedStatus()
                .then(function () {
                _this.getHealthCareYearDropDownList().then(function () {
                    resolve();
                }).catch(function () {
                    reject();
                });
            }).catch(function () {
                reject();
            });
        });
    };
    MyHealthcareService.prototype.getHospitalEventData = function (year) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._healthCare.getHospitalEvents(_this._authService.user.access_token, year)
                .subscribe(function (res) {
                _this.hospitalEvents = res;
                resolve();
            }, function (err) {
                reject();
            });
        });
    };
    MyHealthcareService.prototype.getSummary = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this._commonHelper.healthcareactivated) {
                _this._healthCare.getSummaryHealthCare(_this._authService.user.access_token).subscribe(function (res) {
                    _this.summary = res;
                    resolve(_this.summary);
                }, function (err) {
                    reject(err);
                });
            }
            else {
                resolve(null);
            }
        });
    };
    MyHealthcareService.ngInjectableDef = i0.defineInjectable({ factory: function MyHealthcareService_Factory() { return new MyHealthcareService(i0.inject(i1.CommonhelperService), i0.inject(i2.HealthCareApiServiceProxy), i0.inject(i3.AuthService)); }, token: MyHealthcareService, providedIn: "root" });
    return MyHealthcareService;
}());
export { MyHealthcareService };
