import { Injectable } from '@angular/core';
import { QuoteGender, Education, SmokerStatus } from '@shared/AppEnums';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { XmlHttpRequestHelper } from '@shared/helpers/XmlHttpRequestHelper';
import * as moment from 'moment/moment';

import { mergeMap as _observableMergeMap, catchError as _observableCatch, catchError } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { NeedsCalculatorx, SwaggerException } from '@shared/service-proxies/service-proxies';
import { Router } from '@angular/router';
import { QuoteModule } from '@shared/models/interfaces/elevate-data-models';


@Injectable()
export class QuoteService {
    constructor(private httpCLient: HttpClient, private router: Router) { }
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
    private readonly API_BASE_URL_IDP = abp.setting.values['PortalSettings.authority'];
    unitRate = 0.00025;
    dob: Date;
    gender: string;

    result: IHttpResponseDetails;

    private getProdRequestHeaders(): {} {
        return {
            'Accept': 'application/json'
        };
    }

    getProdUrl() {
        let url_ = this.API_BASE_URL_IDP + 'api/DirectSales/IsDirectSales?';
        return url_;
    }

    updateUserProfile(profileDetails: {
        Email: string,
        CellNumber: string,
        FirstName: string,
        SurName: string
    }, userID, accessToken) {

        let headers = new HttpHeaders({
            'sub': userID,
            'Authorization': 'Bearer ' + accessToken
        });

        let url_ = this.API_BASE_URL_IDP + 'api/DirectSales/UpdateUserProfileDetails';
        return this.httpCLient.post<IHttpResponseDetails>(url_, profileDetails, { headers: headers });
    }

    resendOTP(userid, phonenumber) {
        let url_ = this.API_BASE_URL_IDP + 'api/DirectSales/ResendOTP?userid=' + userid + '&phonenumber=' + phonenumber;
        this.httpCLient.get<IHttpResponseDetails>(url_)
            .subscribe(res => {
                abp.ui.clearBusy();
                if (res.result === 200) {
                    abp.notify.success('sent.');
                } else {
                    abp.notify.error('try again.');
                }
            }, (err) => {
                abp.ui.clearBusy();
            });
    }

    checkIfUserAlreadyExist(input: AnonymousUserDetail | null | undefined): Observable<boolean> {
        let url_ = this.getProdUrl();
        if (input.cellNumber !== undefined) {
            url_ += 'cellNumber=' + encodeURIComponent('' + input.cellNumber) + '&';
        }
        if (input.dob !== undefined) {
            url_ += 'dob=' + encodeURIComponent('' + moment(input.dob).toJSON()) + '&';
        }
        if (input.email !== undefined) {
            url_ += 'email=' + encodeURIComponent('' + input.email) + '&';
        }
        if (input.gender !== undefined) {
            url_ += 'gender=' + encodeURIComponent('' + input.gender) + '&';
        }
        if (input.name !== undefined) {
            url_ += 'name=' + encodeURIComponent('' + input.name) + '&';
        }
        if (input.surname !== undefined) {
            url_ += 'surname=' + encodeURIComponent('' + input.surname) + '&';
        }
        if (input.identityNumber !== undefined) {
            url_ += 'identityNumber=' + encodeURIComponent('' + input.identityNumber);
        }

        url_ = url_.replace(/[?&]$/, '');



        let options_: any = {
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                'Accept': 'application/json',
            })
        };

        return this.httpCLient.request('get', url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processcheckIfUserAlreadyExist(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processcheckIfUserAlreadyExist(<any>response_);
                } catch (e) {
                    return <Observable<boolean>><any>_observableThrow(e);
                }
            } else {
                return <Observable<boolean>><any>_observableThrow(response_);
            }
        }));
    }

    protected processcheckIfUserAlreadyExist(response: HttpResponseBase): Observable<boolean> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = resultData200 !== undefined ? resultData200 : <any>null;
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return throwException('An unexpected server error occurred.', status, _responseText, _headers);
            }));
        }
        return _observableOf<boolean>(<any>null);
    }

    UpdateUserDetails(confimationOfCustomerOtpAndEmailDto: ConfimationOfCustomerOtpAndEmailDto, accessToken: string): Observable<string> {
        let url_ = this.API_BASE_URL_IDP + 'api/DirectSales/UpdateUserDetails';

        const content_ = JSON.stringify(confimationOfCustomerOtpAndEmailDto);

        let options_: any = {
            body: content_,
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            })
        };

        return this.httpCLient.request('post', url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processcheckIfUserDetailsUpdated(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processcheckIfUserDetailsUpdated(<any>response_);
                } catch (e) {
                    return <Observable<string>><any>_observableThrow(e);
                }
            } else {
                return <Observable<string>><any>_observableThrow(response_);
            }
        }));
    }

    protected processcheckIfUserDetailsUpdated(response: HttpResponseBase): Observable<string> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            abp.ui.clearBusy();

            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                this.result = JSON.parse(_responseText);
                if (this.result.result !== 200) {

                    if (this.result.message === 'IDENTITYNUMBER EXIST' || this.result.message === 'Identity Number Already Exist!') {
                        abp.message.confirm('We see that you are already an Elevate customer. Please log in to continue this process in your portal.', 'EXISTING CUSTOMER', (res) => {
                            if (res) {
                                return of(null);
                            } else {
                                this.router.navigate(['/app/main/MyCommandCentre']);
                            }
                        });
                        this.changeTemplatePopup('CLOSE', 'LOG IN');

                    } else if (this.result.message === 'EMAIL EXIST' || this.result.message === 'PHONENUMBER EXIST') {
                        abp.message.confirm('The email address and/or phone number that you have inputted match those of an existing Elevate customer.<br /><br />Because we share sensitive information over these mediums we do not allow two customers to have the same email address and/or phone number.<br /><br />Log in to continue this application process in your Elevate portal or provide a different email address and/or phone number.', 'EXISTING CUSTOMER', (res) => {
                            if (res) {
                                return of(null);

                            } else {
                                this.router.navigate(['/app/main/MyCommandCentre']);

                            }
                        }, true);
                        this.changeTemplatePopup('GO BACK', 'LOG IN');
                    } else if (this.result.result === 400) {
                        abp.message.confirm(this.result.message, 'PLEASE NOTE', (res) => {
                            if (res) {
                                return of(null);
                            }
                        });
                        this.changeTemplatePopup('CLOSE', undefined);
                    } else {
                        abp.message.confirm('Please Contact Support, as we could not assist at present.', 'PLEASE NOTE', (res) => {
                            if (res) {
                                return of(null);
                            }
                        });
                        this.changeTemplatePopup('CLOSE', undefined);
                    }
                }

                let userString = localStorage.getItem('current_user_details');
                let userDetails: AnonymousUserDetail;
                userDetails = JSON.parse(userString);
                userDetails.sub = this.result.sub;
                localStorage.setItem('current_user_details', JSON.stringify(userDetails));
                let result200: any = null;
                let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = resultData200 !== undefined ? resultData200 : <any>null;
                if (this.result.result === 200) {
                    return of(this.result.message);
                }
            }));
        } else if (status !== 200 && status !== 204) {
            abp.ui.clearBusy();
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                return of(null);
            }));
        }
        return _observableOf<string>(<any>false);
    }

    RegisterUser(input: AnonymousUserDetail | null | undefined, accessToken: string, GToken): Observable<any> {
        let url_ = this.API_BASE_URL_IDP + 'api/DirectSales/AutoRegister';

        const content_ = JSON.stringify(input);

        let options_: any = {
            body: content_,
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
                'GToken': GToken
            })
        };

        return this.httpCLient.request('post', url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processRegisterUser(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processRegisterUser(<any>response_);
                } catch (e) {
                    return <Observable<boolean>><any>_observableThrow(e);
                }
            } else {
                return <Observable<boolean>><any>_observableThrow(response_);
            }
        }));
    }

    protected processRegisterUser(response: HttpResponseBase): Observable<any> {
        let self = this;
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                this.result = JSON.parse(_responseText);
                if (!handleError()) {
                    return _observableOf(false);
                }
                let result200: any = null;
                let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = resultData200 !== undefined ? resultData200 : <any>null;
                if (this.result.result === 200) {
                    let user = JSON.parse(localStorage.getItem('current_user_details')) as AnonymousUserDetail;
                    user.sub = this.result.sub;
                    localStorage.setItem('current_user_details', JSON.stringify(user));
                    return _observableOf(true);
                }

                return _observableOf(false);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                return _observableOf(false);
            }));
        }
        return _observableOf<boolean>(<any>false);


        function handleError(): any {
            if (self.result.result !== 200) {

                if (self.result.message === 'IDENTITYNUMBER EXIST') {
                    abp.message.confirm('We see that you are already an Elevate customer. Please log in to continue this process in your portal.', 'EXISTING CUSTOMER', (res) => {
                        if (res) {
                            return of(false);
                        } else {
                            self.router.navigate(['/app/main/MyCommandCentre']);
                        }
                    });
                    self.changeTemplatePopup('CLOSE', 'LOG IN');

                } else if (self.result.message === 'EMAIL EXIST' || self.result.message === 'PHONENUMBER EXIST') {
                    abp.message.confirm('The email address and/or phone number that you have inputted match those of an existing Elevate customer.<br /><br />Because we share sensitive information over these mediums we do not allow two customers to have the same email address and/or phone number.<br /><br />Log in to continue this application process in your Elevate portal or provide a different email address and/or phone number.', 'EXISTING CUSTOMER', (res) => {
                        if (res) {
                            return false;
                        } else {
                            self.router.navigate(['/app/main/MyCommandCentre']);
                        }
                    }, true);
                    self.changeTemplatePopup('GO BACK', 'LOG IN');
                } else if (self.result.result === 400) {
                    abp.message.confirm(self.result.message, 'PLEASE NOTE', (res) => {
                        if (res) {
                            return false;
                        }
                    });
                    self.changeTemplatePopup('CLOSE', undefined);
                } else {
                    abp.message.confirm('Please Contact Support, as we could not assist at present.', 'PLEASE NOTE', (res) => {
                        if (res) {
                            return false;
                        }
                    });
                    self.changeTemplatePopup('CLOSE', undefined);
                }
                return true;
            }
            return true;
        }
    }
    changeTemplatePopup(item, item1) {
        if (item !== undefined) {
            (document.getElementsByClassName('swal2-confirm')[0] as any).innerText = item;
            document.getElementsByClassName('swal2-confirm')[0].classList.add('modal-button-clr');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('font-size-12');
        } else {
            document.getElementsByClassName('swal2-confirm')[0].classList.add('d-none');
        }


        if (item1 !== undefined) {
            (document.getElementsByClassName('swal2-cancel')[0] as any).innerText = item1;
            document.getElementsByClassName('swal2-cancel')[0].classList.add('modal-button-clr');
            document.getElementsByClassName('swal2-cancel')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-cancel')[0].classList.add('font-size-12');
        } else {
            document.getElementsByClassName('swal2-cancel')[0].classList.add('d-none');
        }

    }

    handleError(error: HttpErrorResponse) {
        //To know the version of RxJS npm list --depth=0 (I for this example im on version 5.5)
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred: ', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}` + ` body was: ${error.message}`
            );
        }
        // return an observable with a user-facing error message
        return throwException('Something bad happened; please try again later.', error.status, error.message, error.headers);
    }
}

export interface IAnonymousUserDetail {
    name: string;
    surname: string;
    gender: string;
    dob: Date;
    cellNumber: string;
    email: string;
}

export class AnonymousUserDetail implements IAnonymousUserDetail {
    name: string;
    surname: string;
    gender: string;
    dob: Date;
    cellNumber: string;
    email: string;
    identityNumber: string;
    sub: string;
    resident: boolean;
    socialEconomic: string;
    grossSalary: number;
    summAssured?: number;
    isQuoteImprovedForUser: boolean;
    education: string;
    medicalSchemeName: string;
    occupation: string;
    occupationCategory: string;
    smokingStatus: string;
    age: number;
    addressLine1: string;
    addressLine2: string;
    suburb: string;
    city: string;
    postalCode: string;
    maritalStatus: string;
    netIncome: number;
    proofOfIncome: boolean | null;
    proofOfIncomeDate: moment.Moment;
    creditCheck: boolean | null;
    creditCheckDate: moment.Moment;
    needsAssessment: boolean | null;
    needsAssessmentDate: moment.Moment;
    needsAssessmentLifeCover: number | null;
    needsAssessmentSevereIllnessCover: number | null;
    needsAssessmentIncomeProtection: number | null;
    needsAssessmentDisabilityImpairmentCover: number | null;
    confirmedNetIncome?: number | null;
    confirmedGrossIncome?: number | null;
    medicalSchemeId: string;
    medicalSchemeAdultDependentCount: string;
    medicalSchemeChildDependentCount: string;
    replaceExistingCover: boolean;
}

export interface IHttpResponseDetails {
    result: number;
    message: string;
    sub: string;
}

export interface ConfimationOfCustomerOtpAndEmailDto {

    identityNo: string;
    confirmationEmail: string;
    otp: string;
    socialEconomic: string;
    grossSalary: number;
    smokingStatus: string;

}



function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
    if (result !== null && result !== undefined) {
        return _observableThrow(result);
    } else {
        return _observableThrow(new SwaggerException(message, status, response, headers, null));
    }
}

function blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
        if (!blob) {
            observer.next('');
            observer.complete();
        } else {
            let reader = new FileReader();
            reader.onload = function () {
                observer.next(this.result);
                observer.complete();
            };
            reader.readAsText(blob);
        }
    });
}

