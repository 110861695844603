import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';

declare let fbq: Function;

@Injectable({
    providedIn: 'root'
})
export class FacebookService {

    constructor(
        @Inject(API_BASE_URL) private baseUrl: string,
        private http: HttpClient,
        private authService: AuthService
    ) {
    }

    logEvent(eventName: string, params: any = null): void {
        if (params) {
            fbq('trackCustom', eventName, params);
        } else {
            fbq('trackCustom', eventName);
        }

        const accessToken = this.authService && this.authService.user && this.authService.user.access_token ? this.authService.user.access_token : null;
        this.http.post<boolean>(this.baseUrl + '/api/services/app/Facebook/Conversion', { eventName, accessToken }).subscribe(result => {
        });
    }
}
