import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-idp-call-back',
  templateUrl: './idp-call-back.component.html',
  styleUrls: ['./idp-call-back.component.css']
})
export class IdpCallBackComponent implements OnInit {

    constructor(private router: Router, private authService: AuthService) { }

    ngOnInit() {
        this.authService.completeAuthentication();
    }

    callDashboard() {
        this.router.navigate(['/app/main/dashboard']);
    }

}
