import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import * as _ from 'lodash';
import { GetAllQuotes } from '@shared/models/interfaces/elevate-data-models';

@Component({
  selector: 'app-associated-product-assured-exceeded-modal',
  templateUrl: './associated-product-assured-exceeded-modal.component.html',
  styleUrls: ['./associated-product-assured-exceeded-modal.component.css']
})
export class AssociatedProductAssuredExceededModalComponent implements OnInit {
  response: Subject<string>;
  title: string;
  header: string;
  brokerList: any;
  mainProduct: string;
  associatedProduct: string;
  public fields: Object = { text: 'familyName' , value: 'brokerAdminId' };
  selectedBrokerGuid: string;
  loggedInBrokerGuid: string;
  quote: GetAllQuotes;
  value: string;
  modalService: BsModalService;

  constructor(private bsModalRef: BsModalRef, injector: Injector) {
    this.response = new Subject();
    this.modalService = injector.get(BsModalService);
  }

  ngOnInit() {
  }

  close(): void {
    this.response.next('false');
    this.bsModalRef.hide();
    this.modalService._hideModal(1);
  }

  assign(): void {
    this.response.next('true');
    this.bsModalRef.hide();
    this.modalService._hideModal(1);
  }

  onItemSelected(): Observable<string> {
    return this.response.asObservable();
  }
}
