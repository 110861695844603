<div bsModal #mySettingsModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #mySettingsModalForm="ngForm" (ngSubmit)="save('formSave')">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{"MYPROFILE" | localize}}
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" *ngIf="currentUserInfo">
                    <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-brand" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" style="cursor:pointer;" (click)="profileTab()" [class.active]="tabIndex==1" data-toggle="tab" role="tab" aria-selected="true">
                                {{'Profile' | localize}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" style="cursor:pointer;" (click)="contactTab()" [class.active]="tabIndex==2" data-toggle="tab" role="tab" aria-selected="false">
                                {{'Contact' | localize}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" style="cursor:pointer;" (click)="schemeTab()" [class.active]="tabIndex==3" data-toggle="tab" role="tab" aria-selected="false">
                                {{'Scheme' | localize}} 
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane  side-border" id="kt_widget2_tab1_content" [class.active]="tabIndex==1">
                            <div class="form-group">
                                <label for="Name">{{"Name" | localize}} *</label>
                                <div class="input-group">
                                    <input id="Name" [attr.disabled]="nameDisabled ? '' : null" #nameInput="ngModel" class="form-control" type="text" name="Name" [(ngModel)]="currentUserInfo.given_name" required maxlength="32">
                                    <div class="input-group-prepend curs" (click)="edit('name')"><span class="input-group-text"><i class="flaticon-edit"></i></span></div>
                                </div>
                                <validation-messages [formCtrl]="nameInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="Surname">{{"Surname" | localize}} *</label>
                                <div class="input-group">
                                    <input id="Surname" [attr.disabled]="surnameDisabled ? '' : null" #surnameInput="ngModel" type="text" name="Surname" class="form-control" [(ngModel)]="currentUserInfo.family_name" required>
                                    <div class="input-group-prepend curs" (click)="edit('surname')"><span class="input-group-text"><i class="flaticon-edit"></i></span></div>
                                </div>
                                <validation-messages [formCtrl]="surnameInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="birthday">{{"DateOfBirth" | localize}} *</label>
                                <div class="input-group">
                                    <input type="text"
                                           id="birthday"
                                           name="birthday"
                                           [attr.disabled]="birthdayDisabled ? '' : null"
                                           placeholder="Select Date"
                                           class="form-control"
                                           #birthdayInput="ngModel"
                                           bsDatepicker
                                           required
                                           (keyup)="onChange($event)"
                                           [(ngModel)]="restrictionDateValue"
                                           (bsValueChange)="onValueChange($event)"
                                           [bsConfig]="{dateInputFormat: 'DD-MM-YYYY',  isAnimated: true,showWeekNumbers:false }">
                                    <div class="input-group-prepend curs" (click)="edit('birthday')"><span class="input-group-text"><i class="flaticon-edit"></i></span></div>
                                </div>
                                <span *ngIf="restrictionMessage" class="m--font-danger font-size-12">{{"AgeRestriction" | localize}}</span>
                                <validation-messages [formCtrl]="birthdayInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="gender">{{"Gender" | localize}} *</label>

                                <div class="input-group">
                                    <select id="gender" #genderInput="ngModel" [attr.disabled]="genderDisabled ? '' : null" class="form-control h-auto" required name="gender" [(ngModel)]="selectedGender">
                                        <option value="" disabled>{{"SelectGender" | localize}}</option>
                                        <option *ngFor="let gender of GenderList" [ngValue]="gender">{{gender}}</option>
                                    </select>
                                    <div class="input-group-prepend curs" (click)="edit('gender')"><span class="input-group-text"><i class="flaticon-edit"></i></span></div>
                                </div>
                                <validation-messages [formCtrl]="genderInput"></validation-messages>
                            </div>

                            <!--<div class="form-group">
                                <label for="EmailAddress">{{"EmailAddress" | localize}} *</label>
                                <input id="EmailAddress" #emailAddressInput="ngModel" type="email" name="EmailAddress" class="form-control" [(ngModel)]="user.emailAddress" required email maxlength="256">
                                <validation-messages [formCtrl]="emailAddressInput"></validation-messages>
                            </div>-->
                            <!--<div *ngIf="smsEnabled && !isPhoneNumberEmpty">
                                <div class="form-group">
                                    <label>{{"PhoneNumber" | localize}}</label>
                                    <div class="input-group">
                                        <input type="text" name="PhoneNumber" class="form-control" [(ngModel)]="user.phoneNumber" maxlength="24">

                                        <span *ngIf="!isPhoneNumberConfirmed" id="SpanSmsVerificationUnverified" class="input-group-btn">
                                            <button id="btnSmsVerification" (click)="smsVerify()" class="btn btn-primary" type="button" tooltip="{{'YourPhoneNumberIsNotVerified' | localize}}"><i class="fa fa-exclamation-triangle"></i>{{'Verify' | localize}}</button>
                                        </span>

                                        <div *ngIf="!isPhoneNumberConfirmed" class="input-group-append" id="SpanSmsVerificationVerified" style="display: none;">
                                            <span class="input-group-text" tooltip="{{'YourPhoneNumberIsVerified' | localize}}">
                                                <i class="la la-check m--font-success" [attr.aria-label]="l('Verified')"></i>
                                            </span>
                                        </div>

                                        <div *ngIf="isPhoneNumberConfirmed" class="input-group-append">
                                            <span class="input-group-text" tooltip="{{'YourPhoneNumberIsVerified' | localize}}">
                                                <i class="la la-check m--font-success" [attr.aria-label]="l('Verified')"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>-->
                            <!--<div *ngIf="!smsEnabled || isPhoneNumberEmpty">
                                <div class="form-group">
                                    <label for="PhoneNumber">{{"PhoneNumber" | localize}}</label>
                                    <input id="PhoneNumber" type="text" name="PhoneNumber" class="form-control" [(ngModel)]="user.phoneNumber" maxlength="24">
                                </div>
                            </div>-->
                            <!--<div class="form-group">
                                <label for="UserName">{{"UserName" | localize}} *</label>
                                <input id="UserName" #userNameInput="ngModel" type="text" [disabled]="!canChangeUserName" class="form-control" [(ngModel)]="currentUserInfo.preferred_username" name="UserName" required maxlength="32">
                                <span class="m-form__help" *ngIf="!canChangeUserName">{{"CanNotChangeAdminUserName" | localize}}</span>
                                <validation-messages [formCtrl]="userNameInput"></validation-messages>
                            </div>-->
                            <!--<div class="form-group" *ngIf="showTimezoneSelection">
                                <label for="Timezone">{{"Timezone" | localize}}</label>
                                <timezone-combo name="Timezone" [(ngModel)]="user.timezone" defaultTimezoneScope="{{defaultTimezoneScope}}"></timezone-combo>
                            </div>-->
                        </div>

                        <div class="tab-pane  side-border" id="kt_widget2_tab1_content" [class.active]="tabIndex==2">
                            <div class="form-group">
                                <label for="EmailAddress">{{"EmailAddress" | localize}} *</label>
                                <input disabled id="EmailAddress" #emailAddressInput="ngModel" type="email" name="EmailAddress" class="form-control" [(ngModel)]="currentUserInfo.email" required email maxlength="256">
                            </div>
                            <div class="form-group">
                                <label for="Phonenumber">{{"MobileNumber" | localize}} *</label>
                                <input disabled id="Phonenumber" #PhoneNumberInput="ngModel" type="text" name="PhoneNumber" class="form-control" [(ngModel)]="currentUserInfo.phone_number" required>
                                <!--minLength="10" maxlength="10"-->
                            </div>
                        </div>

                        <div class="tab-pane  side-border" id="kt_widget2_tab1_content" [class.active]="tabIndex==3">
                            <div class="form-group">
                                <label for="schemename">{{"MedicalSchemeName" | localize}} *</label>
                                <input disabled id="schemename" #schemenameInput="ngModel" type="text" name="schemename" class="form-control" [(ngModel)]="currentUserInfo.medical_scheme_name" required>
                            </div>
                            <div class="form-group">
                                <label for="schemenumber">{{"MedicalSchemeNumber" | localize}} *</label>
                                <div class="input-group">
                                    <input id="schemenumber" #schemenameInput="ngModel" [attr.disabled]="schemeDisabled ? '' : null" type="text" name="schemenumber" class="form-control" [(ngModel)]="currentUserInfo.medical_scheme_membership_number" required>
                                    <div class="input-group-prepend curs" (click)="edit('scheme')"><span class="input-group-text"><i class="flaticon-edit"></i></span></div>
                                </div>
                                
                            </div>
                        </div>
                        <!--<tab heading="{{'TwoFactorLogin' | localize}}" customClass="m-tabs__item">
                            <div>
                                <h4>
                                    Google Authenticator
                                </h4>

                                <div [hidden]="!isGoogleAuthenticatorEnabled">
                                    <div>
                                        {{"ScanQrCodeWithYourMobileApp" | localize}}
                                    </div>
                                    <div class="text-center">
                                        <img [src]="user.qrCodeSetupImageUrl" />
                                    </div>
                                    <small>
                                        {{"GoogleAuthenticatorReferrerLink" | localize}}: <a href="https://support.google.com/accounts/answer/1066447" target="_blank" rel="noopener noreferrer">Google Authenticator</a>
                                    </small>
                                </div>

                                <div [hidden]="isGoogleAuthenticatorEnabled">
                                    <button type="button" class="btn blue" (click)="updateQrCodeSetupImageUrl()">{{"Enable" | localize}}</button>
                                </div>

                            </div>
                        </tab>-->
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving">{{"Close" | localize}}</button>
                    <button type="submit" class="btn btn-danger" [disabled]="!mySettingsModalForm.form.valid || saving">
                        <span *ngIf="!lastTabActive">{{"SaveContinue" | localize}}</span>
                        <span *ngIf="lastTabActive">{{"Submit" | localize}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<smsVerificationModal #smsVerificationModal (modalSave)="changePhoneNumberToVerified()"></smsVerificationModal>


