var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { LayoutRefService } from '@metronic/app/core/services/layout/layout-ref.service';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
var Theme2LayoutComponent = /** @class */ (function (_super) {
    __extends(Theme2LayoutComponent, _super);
    function Theme2LayoutComponent(injector, layoutRefService) {
        var _this = _super.call(this, injector) || this;
        _this.layoutRefService = layoutRefService;
        return _this;
    }
    Theme2LayoutComponent.prototype.ngOnInit = function () {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    };
    Theme2LayoutComponent.prototype.ngAfterViewInit = function () {
        this.layoutRefService.addElement('header', this.mHeader.nativeElement);
        this.initStickyHeader();
    };
    Theme2LayoutComponent.prototype.initStickyHeader = function () {
        var headerEl = mUtil.get('m_header');
        var options = {
            offset: {
                desktop: null,
                mobile: null
            },
            minimize: {
                mobile: null,
                desktop: null
            },
            classic: {
                mobile: true,
                desktop: true
            }
        };
        if (mUtil.attr(headerEl, 'm-minimize-mobile') === 'minimize') {
            options.minimize.mobile = {};
            options.minimize.mobile.on = 'm-header--minimize-on';
            options.minimize.mobile.off = 'm-header--minimize-off';
        }
        else {
            options.minimize.mobile = false;
        }
        if (mUtil.attr(headerEl, 'm-minimize') === 'minimize') {
            options.minimize.desktop = {};
            options.minimize.desktop.on = 'm-header--minimize-on';
            options.minimize.desktop.off = 'm-header--minimize-off';
        }
        else {
            options.minimize.desktop = false;
        }
        if (mUtil.attr(headerEl, 'm-minimize-offset')) {
            options.offset.desktop = mUtil.attr(headerEl, 'm-minimize-offset');
        }
        if (mUtil.attr(headerEl, 'm-minimize-mobile-offset')) {
            options.offset.mobile = mUtil.attr(headerEl, 'm-minimize-mobile-offset');
        }
        return new mHeader('m_header', options);
    };
    return Theme2LayoutComponent;
}(ThemesLayoutBaseComponent));
export { Theme2LayoutComponent };
