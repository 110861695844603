var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Product } from '../ProductFactory/Product';
import { AppConsts } from '../../../../shared/AppConsts';
var AssociatedProduct = /** @class */ (function (_super) {
    __extends(AssociatedProduct, _super);
    function AssociatedProduct(Uid, Name, MinBenefitAmount, MaxBenefitAmount, BenefitPatternSet, PremiumPatternSet, EventSet, Category, DisplayName, AssociatedProducts, DefaultBenefitPattern, DefaultPremiumPattern, RateTable, RequirementType, RiderPolicy, Accelerator, IsDisablity, IsParentDependent, DependentParentGuid) {
        var _this = _super.call(this, Uid, Name, MinBenefitAmount, MaxBenefitAmount, BenefitPatternSet, PremiumPatternSet, EventSet, Category, DisplayName, AssociatedProducts, DefaultBenefitPattern, DefaultPremiumPattern, RateTable, IsDisablity) || this;
        _this.RequirementType = RequirementType;
        _this.RiderPolicy = RiderPolicy;
        _this.Accelerator = Accelerator;
        _this.IsProductQuoted = _this.RequirementType === AppConsts.requirementTyoe.Selectable ? false : true;
        _this.IsParentDependent = IsParentDependent;
        _this.DependentParentGuid = DependentParentGuid;
        return _this;
    }
    AssociatedProduct.prototype.getIsBenefitTermEditable = function () {
        if (!this.DisplayName) {
            return false;
        }
        return this.DisplayName.toLowerCase().includes('disability') && this.Accelerator;
    };
    AssociatedProduct.prototype.getAssociatedProductPremiumAmount = function (multiplicativeUndewritingLoading, additiveUnderwritingLoading) {
        var _this = this;
        if (!this.RateTable) {
            return undefined;
        }
        var assuredValue = this.CurrentAssuredValue || this.DefaultAssuredValue;
        var benefitPatternUID = this.CurrentBenefitPattern ? this.CurrentBenefitPattern.UID : this.DefaultBenefitPattern.UID;
        var premiumPatternUID = this.CurrentPremiumPattern ? this.CurrentPremiumPattern.UID : this.DefaultPremiumPattern.UID;
        var getPremiumCalcOutput = function (assuredValue, premiumRate) {
            return (assuredValue * premiumRate.UnitRate * premiumRate.BaseRate * multiplicativeUndewritingLoading) + (assuredValue * additiveUnderwritingLoading);
        };
        var ratesForBenefitPremiumPattern = this.RateTable
            .filter(function (r) { return r.BenefitPattern && r.BenefitPattern.UID === benefitPatternUID && r.PremiumPattern && r.PremiumPattern.UID === premiumPatternUID; })
            .sort(function (a, b) { return a.sumAssuredLimit - b.sumAssuredLimit; });
        var premiumRate = ratesForBenefitPremiumPattern
            .filter(function (r) { return r.sumAssuredLimit <= _this.CurrentAssuredValue && Math.abs(_this.CurrentAssuredValue - r.sumAssuredLimit) < 100000; })
            .pop();
        if (!premiumRate) {
            var rateLowerBound = ratesForBenefitPremiumPattern
                .filter(function (r) { return r.sumAssuredLimit <= _this.CurrentAssuredValue; })
                .pop();
            var sumAssuredLowerBound = rateLowerBound.sumAssuredLimit;
            var premiumLowerBound = getPremiumCalcOutput(sumAssuredLowerBound, rateLowerBound);
            var rateUpperBound = ratesForBenefitPremiumPattern
                .filter(function (r) { return r.sumAssuredLimit > _this.CurrentAssuredValue; })
                .pop();
            var sumAssuredUpperBound = rateUpperBound.sumAssuredLimit;
            var premiumUpperBound = getPremiumCalcOutput(sumAssuredUpperBound, rateUpperBound);
            var premium_1 = premiumLowerBound + (this.CurrentAssuredValue - sumAssuredLowerBound) * ((premiumUpperBound - premiumLowerBound) / (sumAssuredUpperBound - sumAssuredLowerBound));
            return Math.round(premium_1);
        }
        var premium = getPremiumCalcOutput(assuredValue, premiumRate);
        return Math.round(premium);
    };
    return AssociatedProduct;
}(Product));
export { AssociatedProduct };
