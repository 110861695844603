var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { ProductService } from '../../services/product.service';
import { BenefitPatternFactory } from '../BenefitPatternFactory/IIncompleteBenefitPattern';
import { PremiumPatternFactory } from '../PremiumPatternFactory/IIncompletePremiumPattern';
import { RateTableEntryFactory } from '../RateTableFactory/IIncompleteRateTableEntry';
import { AppConsts } from '@shared/AppConsts';
var ProductBaseFactory = /** @class */ (function () {
    function ProductBaseFactory(injector) {
        this.injector = injector;
        this.benefitPatternSet = new Array();
        this.premiumPatternSet = new Array();
    }
    Object.defineProperty(ProductBaseFactory.prototype, "productService", {
        get: function () {
            if (!this._productService) {
                this._productService = this.injector.get(ProductService);
            }
            return this._productService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductBaseFactory.prototype, "sortedRateTable", {
        get: function () {
            if (!this.rateTable) {
                return [];
            }
            return this.rateTable.sort(function (a, b) { return a.BaseRate - b.BaseRate; });
        },
        enumerable: true,
        configurable: true
    });
    ProductBaseFactory.prototype.forProduct = function (productUID) {
        try {
            this.metaProduct = this.metaData.find(function (x) { return x.UID === productUID; });
            return this;
        }
        catch (e) {
            abp.message.error(JSON.stringify(e));
        }
    };
    ProductBaseFactory.prototype.excludeRiders = function (allowedDisabilityProductNames) {
        var _this = this;
        var isProductAllowed = function (productName) {
            var e_1, _a;
            try {
                for (var allowedDisabilityProductNames_1 = __values(allowedDisabilityProductNames), allowedDisabilityProductNames_1_1 = allowedDisabilityProductNames_1.next(); !allowedDisabilityProductNames_1_1.done; allowedDisabilityProductNames_1_1 = allowedDisabilityProductNames_1.next()) {
                    var excludedProductName = allowedDisabilityProductNames_1_1.value;
                    if (productName.toLocaleLowerCase().includes(excludedProductName)) {
                        return true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (allowedDisabilityProductNames_1_1 && !allowedDisabilityProductNames_1_1.done && (_a = allowedDisabilityProductNames_1.return)) _a.call(allowedDisabilityProductNames_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return false;
        };
        try {
            this.metaProduct.ProductRequirements = this.metaProduct.ProductRequirements.filter(function (m) {
                return _this.metaDataOriginal.find(function (p) { return p.Name === m.ProductConfig_2.Name; }).Category === 'Disability Protection'
                    || (_this.metaDataOriginal.find(function (p) { return p.Name === m.ProductConfig_2.Name; }).Category === 'Complimentary Benefits' && m.ProductConfig_2.Name.toLocaleLowerCase().includes('disability')) ?
                    isProductAllowed(m.ProductConfig_2.Name) : true;
            });
            return this;
        }
        catch (e) {
            abp.message.error(JSON.stringify(e));
        }
    };
    ProductBaseFactory.prototype.extractUID = function () {
        try {
            this.uid = this.metaProduct.UID;
            return this;
        }
        catch (e) {
            abp.message.error(JSON.stringify(e));
        }
    };
    ProductBaseFactory.prototype.extractName = function () {
        try {
            if (!this.metaProduct.Name) {
                abp.message.error('Please Contact Support, as we could not assist at present. Error Details extractName');
                abp.ui.clearBusy();
                return null;
            }
            this.name = this.metaProduct.Name;
            return this;
        }
        catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present. Error Details extractName');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    };
    ProductBaseFactory.prototype.extractMinBenefitAmount = function () {
        if (this.metaProduct.ConstraintConfig.MinBenefitAmount < 0) {
            abp.message.error('Please Contact Support, as we could not assist at present. Error Details extractMinBenefitAmount');
            abp.ui.clearBusy();
            return null;
        }
        this.minBenefitAmount = this.metaProduct.ConstraintConfig.MinBenefitAmount;
        return this;
    };
    ProductBaseFactory.prototype.extractMaxBenefitAmount = function () {
        if (this.metaProduct.ConstraintConfig.MaxBenefitAmount < this.minBenefitAmount) {
            abp.message.error('Please Contact Support, as we could not assist at present. extractMaxBenefitAmount');
            abp.ui.clearBusy();
            throw new Error('extractMaxBenefitAmount.');
            return null;
        }
        this.maxBenefitAmount = this.metaProduct.ConstraintConfig.MaxBenefitAmount;
        return this;
    };
    ProductBaseFactory.prototype.extractEventSet = function () {
        this.eventSet = this.metaProduct.EventSets[0].ShortCode;
        return this;
    };
    ProductBaseFactory.prototype.extractCategory = function () {
        this.category = this.metaProduct.Category;
        return this;
    };
    ProductBaseFactory.prototype.extractDisplayName = function () {
        this.displayName = this.metaProduct.DisplayName.trim();
        return this;
    };
    ProductBaseFactory.prototype.extractBenefitPatternSetAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var benefitPatternSetId, benefitFactory_1, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        benefitPatternSetId = this.metaProduct.BenefitPatternSets[0].UID;
                        return [4 /*yield*/, new BenefitPatternFactory(this.injector)
                                .withMetadata(benefitPatternSetId)];
                    case 1:
                        benefitFactory_1 = _a.sent();
                        this.isDisability = benefitFactory_1.getIfIdisability();
                        this.benefitPatternSet = [];
                        benefitFactory_1
                            .extractBenefitPatternUIDList()
                            .forEach(function (x) {
                            var benefitPattern = benefitFactory_1
                                .forBenefitPattern(x)
                                .extractUID()
                                .extractBenefitIncreaseIndex()
                                .extractBenefitIncrease()
                                .extractBenefitEscallationTerm()
                                .extractBenefitMaxPaymentAge()
                                .extractInClaimbenefitIncreaseIndex()
                                .extractInClaimbenefitIncrease()
                                .extractInClaimMaximumPaymentTerm()
                                .build();
                            _this.benefitPatternSet.push(benefitPattern);
                        });
                        return [2 /*return*/, this];
                    case 2:
                        error_1 = _a.sent();
                        throw new Error(error_1);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductBaseFactory.prototype.extractPremiumPatternSetAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var premiumPatternSetId, premiumFactory_1, error_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        premiumPatternSetId = this.metaProduct.PremiumPatternSets[0].UID;
                        return [4 /*yield*/, new PremiumPatternFactory(this.injector)
                                .withMetadata(premiumPatternSetId)];
                    case 1:
                        premiumFactory_1 = _a.sent();
                        this.premiumPatternSet = [];
                        premiumFactory_1.extractPremiumPatternUIDList()
                            .forEach(function (UID) {
                            var premiumPattern = premiumFactory_1
                                .forPremiumPattern(UID)
                                .extractUID()
                                .extractPremiumIncreaseIndex()
                                .extractPremiumIncrease()
                                .extractPremiumTerm()
                                .build();
                            _this.premiumPatternSet.push(premiumPattern);
                        });
                        return [2 /*return*/, this];
                    case 2:
                        error_2 = _a.sent();
                        throw new Error(error_2);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductBaseFactory.prototype.extractDependentStatus = function () {
        if (!this.displayName) {
            throw new Error('Unable to extract parent dependent status');
        }
        this.isParentDependent = this.displayName.includes(AppConsts.configProducts.FullTierBooster) || this.displayName.includes(AppConsts.configProducts.SelfEmployedEnhancer) || this.displayName.includes(AppConsts.configProducts.AlternateSelfEmployedEnhancer) || this.displayName.includes(AppConsts.configProducts.OwnOccupationBooster);
        return this;
    };
    ProductBaseFactory.prototype.extractDependentParentGuid = function (mainProductFactory) {
        var _this = this;
        if (!this.isParentDependent) {
            this.dependentParentGuid = null;
        }
        else if (mainProductFactory.category === AppConsts.AggregationCategories.severeIllnessProtection || mainProductFactory.category === AppConsts.AggregationCategories.disabilityProtection) {
            this.dependentParentGuid = mainProductFactory.uid;
        }
        else {
            var accelerators = mainProductFactory.metaData.filter(function (m) { return m.DisplayName.includes('Principal Severe Illness Protection - Accelerator') || m.DisplayName.includes('Principal Disability Capital Protection - Accelerator'); });
            var accelerator = accelerators.find(function (a) { return a.Category === _this.category; });
            if (accelerator) {
                this.dependentParentGuid = accelerator.UID;
            }
            else {
                this.dependentParentGuid = null;
            }
        }
        return this;
    };
    ProductBaseFactory.prototype.getBenefitPatternSet = function () {
        return this.benefitPatternSet;
    };
    ProductBaseFactory.prototype.getPremiumPatternSet = function () {
        return this.premiumPatternSet;
    };
    ProductBaseFactory.prototype.setRateTableAsync = function (benefitPatternSet, premiumPatternSet, lowerLimit, upperLimit, age) {
        return __awaiter(this, void 0, void 0, function () {
            var rateTableEntryFactory_1, error_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, new RateTableEntryFactory(this.injector)
                                .withMetaData(this.metaProduct.UID, lowerLimit, upperLimit, age)];
                    case 1:
                        rateTableEntryFactory_1 = _a.sent();
                        this.rateTable = [];
                        if (!!rateTableEntryFactory_1) return [3 /*break*/, 3];
                        return [4 /*yield*/, new RateTableEntryFactory(this.injector)
                                .withMetaData(this.metaProduct.UID, lowerLimit, upperLimit, age)];
                    case 2:
                        rateTableEntryFactory_1 = _a.sent();
                        _a.label = 3;
                    case 3:
                        rateTableEntryFactory_1.extractRateTableEntryUIDList()
                            .forEach(function (UID) {
                            var rateTableEntry = rateTableEntryFactory_1
                                .forRateTableEntry(UID)
                                .extractUID()
                                .extractBenefitPattern(benefitPatternSet)
                                .extractPremiumPattern(premiumPatternSet)
                                .extractBaseRate()
                                .extractUnitRate()
                                .extractSumAssuredLimit()
                                .build();
                            _this.rateTable.push(rateTableEntry);
                        });
                        return [2 /*return*/, this];
                    case 4:
                        error_3 = _a.sent();
                        abp.message.error('Please Contact Support, as we could not assist at present.');
                        abp.ui.clearBusy();
                        throw new Error(error_3);
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ProductBaseFactory.prototype.setDefaultBenefitPattern = function () {
        var _this = this;
        try {
            if (!this.sortedRateTable || this.sortedRateTable.length === 0) {
                return this;
            }
            this.defaultBenefitPattern = this.benefitPatternSet.find(function (x) { return x.UID === _this.sortedRateTable[0].BenefitPattern.UID; });
            return this;
        }
        catch (error) {
            throw new Error(error);
        }
    };
    ProductBaseFactory.prototype.setDefaultPremiumPattern = function () {
        var _this = this;
        try {
            if (!this.sortedRateTable || this.sortedRateTable.length === 0) {
                return this;
            }
            this.defaultPremiumPattern = this.premiumPatternSet.find(function (x) { return x.UID === _this.sortedRateTable[0].PremiumPattern.UID; });
            return this;
        }
        catch (error) {
            throw new Error(error);
        }
    };
    ProductBaseFactory.prototype.setDefaultAssuredValue = function () {
        throw new Error('Method not implemented.');
    };
    return ProductBaseFactory;
}());
export { ProductBaseFactory };
