/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./support-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/common/pipes/localize.pipe";
import * as i3 from "./support-modal.component";
import * as i4 from "ngx-bootstrap/modal";
var styles_SupportModalComponent = [i0.styles];
var RenderType_SupportModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SupportModalComponent, data: {} });
export { RenderType_SupportModalComponent as RenderType_SupportModalComponent };
export function View_SupportModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LocalizePipe, [i1.Injector]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "modal-body mp-0 mt-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, [["iframe", 1]], null, 0, "iframe", [["class", "no-border"], ["height", "652px"], ["src", "https://connect.solgari.com/connect/default.aspx?token=K8Rjg4"], ["title", "Support"]], [[8, "width", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), "Support")); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.getWidth(); _ck(_v, 10, 0, currVal_1); }); }
export function View_SupportModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-support-modal", [], null, null, null, View_SupportModalComponent_0, RenderType_SupportModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.SupportModalComponent, [i4.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SupportModalComponentNgFactory = i1.ɵccf("app-support-modal", i3.SupportModalComponent, View_SupportModalComponent_Host_0, {}, {}, []);
export { SupportModalComponentNgFactory as SupportModalComponentNgFactory };
