var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { RateTableEntryFactory } from '../RateTableFactory/IIncompleteRateTableEntry';
var Product = /** @class */ (function () {
    //passholdingvariables
    function Product(Uid, Name, MinBenefitAmount, MaxBenefitAmount, BenefitPatternSet, PremiumPatternSet, EventSet, Category, DisplayName, AssociatedProducts, DefaultBenefitPattern, DefaultPremiumPattern, RateTable, isDisability) {
        this.CurrentAssuredValue = 0;
        this.UID = Uid;
        this.Name = Name;
        this.MinBenefitAmount = MinBenefitAmount;
        this.MaxBenefitAmount = MaxBenefitAmount;
        this.OriginalMaxBenefitAmount = MaxBenefitAmount;
        this.BenefitPatternSet = BenefitPatternSet;
        this.PremiumPatternSet = PremiumPatternSet;
        this.EventSet = EventSet;
        this.Category = Category;
        this.DisplayName = DisplayName;
        this.AssociatedProducts = AssociatedProducts;
        this.DefaultBenefitPattern = DefaultBenefitPattern;
        this.DefaultPremiumPattern = DefaultPremiumPattern;
        this.DefaultAssuredValue = this.MinBenefitAmount;
        //this.CurrentBenefitPattern = DefaultBenefitPattern;
        //this.CurrentPremiumPattern = DefaultPremiumPattern;
        this.RateTable = RateTable;
        this.isIncludedFromQuirc = true;
        this.isDisablitiy = isDisability;
    }
    Product.prototype.getPremiumAmount = function (multiplicativeUndewritingLoading, additiveUnderwritingLoading) {
        var _this = this;
        if (additiveUnderwritingLoading === void 0) { additiveUnderwritingLoading = 0; }
        if (!this.currentRateTable ||
            !this.currentRateTable.BenefitPattern ||
            !this.currentRateTable.BenefitPattern.UID ||
            !this.currentRateTable.PremiumPattern ||
            !this.currentRateTable.PremiumPattern.UID) {
            return undefined;
        }
        var assuredValue = this.CurrentAssuredValue || this.DefaultAssuredValue;
        var benefitPatternUID = this.currentRateTable.BenefitPattern.UID;
        var premiumPatternUID = this.currentRateTable.PremiumPattern.UID;
        var getPremiumCalcOutput = function (assuredValue, premiumRate) {
            return (assuredValue * premiumRate.UnitRate * premiumRate.BaseRate * multiplicativeUndewritingLoading) + (assuredValue * additiveUnderwritingLoading);
        };
        var ratesForBenefitPremiumPattern = this.RateTable
            .filter(function (r) { return r.BenefitPattern.UID === benefitPatternUID && r.PremiumPattern.UID === premiumPatternUID; })
            .sort(function (a, b) { return a.sumAssuredLimit - b.sumAssuredLimit; });
        var premiumRate = ratesForBenefitPremiumPattern
            .filter(function (r) { return r.sumAssuredLimit <= _this.CurrentAssuredValue && Math.abs(_this.CurrentAssuredValue - r.sumAssuredLimit) < 100000; })
            .pop();
        if (!premiumRate) {
            var rateLowerBound = ratesForBenefitPremiumPattern
                .filter(function (r) { return r.sumAssuredLimit <= _this.CurrentAssuredValue; })
                .pop();
            var sumAssuredLowerBound = rateLowerBound.sumAssuredLimit;
            var premiumLowerBound = getPremiumCalcOutput(sumAssuredLowerBound, rateLowerBound);
            var rateUpperBound = ratesForBenefitPremiumPattern
                .filter(function (r) { return r.sumAssuredLimit > _this.CurrentAssuredValue; })
                .pop();
            var sumAssuredUpperBound = rateUpperBound.sumAssuredLimit;
            var premiumUpperBound = getPremiumCalcOutput(sumAssuredUpperBound, rateUpperBound);
            var premium_1 = premiumLowerBound + (this.CurrentAssuredValue - sumAssuredLowerBound) * ((premiumUpperBound - premiumLowerBound) / (sumAssuredUpperBound - sumAssuredLowerBound));
            return Math.round(premium_1);
        }
        var premium = getPremiumCalcOutput(assuredValue, premiumRate);
        return Math.round(premium);
    };
    Product.prototype.getRateTableAsync = function (injector, lowerLimit, upperLimit, sumAssuredValue, age) {
        if (sumAssuredValue === void 0) { sumAssuredValue = null; }
        return __awaiter(this, void 0, void 0, function () {
            var rateTableEntryFactory_1, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, new RateTableEntryFactory(injector)
                                .withMetaData(this.UID, lowerLimit, upperLimit, age)];
                    case 1:
                        rateTableEntryFactory_1 = _a.sent();
                        if (!sumAssuredValue) return [3 /*break*/, 3];
                        return [4 /*yield*/, rateTableEntryFactory_1.addRateEntry(this.UID, sumAssuredValue, age)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.RateTable = [];
                        if (!!rateTableEntryFactory_1) return [3 /*break*/, 5];
                        return [4 /*yield*/, new RateTableEntryFactory(injector)
                                .withMetaData(this.UID, lowerLimit, upperLimit, age)];
                    case 4:
                        rateTableEntryFactory_1 = _a.sent();
                        _a.label = 5;
                    case 5:
                        rateTableEntryFactory_1.extractRateTableEntryUIDList()
                            .forEach(function (UID) {
                            var rateTableEntry = rateTableEntryFactory_1
                                .forRateTableEntry(UID)
                                .extractUID()
                                .extractBenefitPattern(_this.BenefitPatternSet)
                                .extractPremiumPattern(_this.PremiumPatternSet)
                                .extractBaseRate()
                                .extractUnitRate()
                                .extractSumAssuredLimit()
                                .build();
                            if (rateTableEntry.BaseRate < 0) {
                                return;
                            }
                            if (rateTableEntry.BenefitPattern && rateTableEntry.BenefitPattern.maxPaymentAge && rateTableEntry.BenefitPattern.maxPaymentAge !== rateTableEntry.BenefitPattern.benefitEscallationTerm) {
                                return;
                            }
                            _this.RateTable.push(rateTableEntry);
                        });
                        return [2 /*return*/, this];
                    case 6:
                        error_1 = _a.sent();
                        abp.message.error('Please Contact Support, as we could not assist at present.');
                        abp.ui.clearBusy();
                        throw new Error(error_1);
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    Product.prototype.setDefaultBenefitPattern = function () {
        var _this = this;
        try {
            if (!this.sortedRateTable() || this.sortedRateTable().length === 0) {
                return this;
            }
            this.DefaultBenefitPattern = this.BenefitPatternSet.find(function (x) { return x.UID === _this.sortedRateTable()[0].BenefitPattern.UID; });
            return this;
        }
        catch (error) {
            throw new Error(error);
        }
    };
    Product.prototype.setDefaultPremiumPattern = function () {
        var _this = this;
        try {
            if (!this.sortedRateTable() || this.sortedRateTable().length === 0) {
                return this;
            }
            this.DefaultPremiumPattern = this.PremiumPatternSet.find(function (x) { return x.UID === _this.sortedRateTable()[0].PremiumPattern.UID; });
            return this;
        }
        catch (error) {
            throw new Error(error);
        }
    };
    Product.prototype.sortedRateTable = function () {
        if (!this.RateTable) {
            return [];
        }
        return this.RateTable.sort(function (a, b) { return a.BaseRate - b.BaseRate; });
    };
    return Product;
}());
export { Product };
