﻿import { QuestionType } from '@shared/AppEnums';
import { BenficiaryModule } from '@shared/models/interfaces/elevate-data-models';
import { AppConsts } from '../../../shared/AppConsts';

export declare module GetQuestionariesModule {

    export interface IOutCome {
        AnswerId: number;
        QuestionProfileId: number;
        SectionId: number;
        SectionVisibilityQuestionProfiles?: any;
        Visibility: string;
        id: number;
    }

    export interface IAnswer {
        AnswerSequence: number;
        AnswerType: string;
        ApplicantAttributeName: string;
        AutoComplete: boolean;
        DataType: string;
        OutComes: Array<IOutCome>;
        OverrideAnswer: boolean;
        Prompt: string;
        Text: string;
        Value: string;
        id: number;
        ExistingAnswer?: boolean;
    }

    export interface IQuestion {
        AllowMultiAnswers: boolean;
        Answer: IAnswer[];
        ExternalReferenceId: string;
        Id: number;
        IsVisibleAtStart: boolean;
        AnsweredStatus?: boolean;
        ValueGiven?: any;
        CondtionValues?: any;
        Prompt: string;
        QuestionSequence: number;
        Text: string;
        Title: string;
        QuestionType: QuestionType;
        valueCondtions?: any;
        AnswerRequired: boolean;
        overrideVisibility?: boolean;
    }

    export interface ISection {
        ExternalReferenceId?: string;
        Id?: number;
        IsVisibleAtStart?: boolean;
        ParentId?: any;
        ParentSectionSequence?: number;
        PromptText?: string;
        Questions?: IQuestion[];
        SectionSequence?: any;
        Sections?: ISection[];
        Title?: string;
    }

    export interface IQuestionDetails {
        Cedants?: any;
        DateCreated: Date;
        Id: number;
        Locked: boolean;
        Name: string;
        Sections: ISection[];
        Version: string;
    }

    export interface IGetQuestionsForSectionOutputDto {
        ErrorDetails?: any;
        QuestionDetails: IQuestionDetails;
    }

}


export interface IQuotePolicyProductModel {
    quoteId: string;
    policyId: string;
    productId?: string;
    productName?: string;
}



export declare module PolicyModule {
    export interface IPolicyListResult {
        AcceptedDate: string;
        AcceptedStatusName: string;
        ActivationDate: string;
        CustomerId: number;
        CustomerReferenceId: string;
        ExternalReferenceId: string;
        PolicyId: number;
        PolicyStatusName: string;
        Premium: number;
        QuestionnaireId: number;
        RedirectUrl: string;
    }

    export interface IGetAllPolicyList {
        ErrorDetails?: any;
        NumberOfPolicies: number;
        PolicyListResult: IPolicyListResult[];
    }
}


export declare module CreateOrUpdatePolicyInputModule {

    export interface IUpdatePolicyOfApplicantWithAnswers {
        CustomerReferenceid?: any;
        CedantId?: any;
        PolicyReferenceId?: any;
        ShowErrorDetails?: any;
        ProcessDisclosures?: any;
        DisplayAnswers?: any;
        AnsweredQuestions?: IAnsweredQuestion[];
    }

    export interface IAnsweredQuestion {
        QuestionProfileId?: number;
        Value?: any;
        ExternalReferenceId?: any;
        MultipleAnswers?: IMultipleAnswer[];
    }

    export interface IMultipleAnswer {
        Value?: any;
    }
}

export module SubmitQuestionnaireModule {

    export interface PolicyDetail {
        AcceptedDate: string;
        AcceptedStatus: number;
        ActivationDate: string;
        AnsweredQuestions?: Array<any>;
        CustomerId: number;
        CustomerReferenceId: string;
        ExternalReferenceId: string;
        PolicyId: number;
        PolicyNumber: number;
        Premium: number;
        PremiumExcelCalculatorName?: any;
        Products: any[];
        QuestionnaireId: number;
        RedirectUrl: string;
        References: any[];
        Status: number;
        SumAssured: number;
    }

    export interface PolicyReference {
        Id: number;
        Reference: string;
        ReferenceId: number;
        State?: number;
        Title: string;
        Type: number;
        TypeName: string;
    }

    export interface Premium {
        BasePremium: number;
        FinalPremium: number;
        PercentageLoading: number;
        ProfileId: number;
        ProfileName: string;
        UnitLoading: number;
    }

    export interface Product {
        DecisionName: string;
        Premium: number;
        Premiums: Premium[];
        ProductReferences: any[];
        ProductStatusReason: string;
        ProfileId: number;
        ProfileName: string;
        SumAssured: number;
    }

    export interface SubmitQuestionnaire {
        ErrorDetails?: any;
        AcceptedDate: string;
        AcceptedStatus: number;
        AcceptedStatusName: string;
        ActivationDate: string;
        CustomerId: number;
        ExternalReferenceId: string;
        PolicyDetail: PolicyDetail;
        PolicyId: number;
        PolicyReferences: PolicyReference[];
        PolicyStatusName: string;
        Premium: number;
        ProductNotes: any[];
        Products: Product[];
        QuestionAnswers?: Array<any>;
        QuestionnaireId: number;
        QuestionnaireStatusName: string;
        SectionDisclosures?: any;
        Status: number;
        SumAssured: number;
        VersionId: number;
        VersionName: string;
    }

}

export module PolicyStatusChangeModule {

    export interface PolicyListResult {
        AcceptedDate: string;
        AcceptedStatusName: string;
        ActivationDate: string;
        CustomerId: number;
        CustomerReferenceId: string;
        ExternalReferenceId: string;
        PolicyId: number;
        PolicyStatusName: string;
        Premium: number;
        QuestionnaireId: number;
        RedirectUrl: string;
    }

    export interface PolicyStatusChange {
        ErrorDetails?: any;
        NumberOfPolicies: number;
        PolicyListResult: PolicyListResult[];
    }

}


export declare module CreateOrUpdatePolicyDtoOutpuModel {

    export interface IMultipleAnswer {
        Text: string;
        Value: any;
    }

    export interface IAnsweredQuestion {
        AnswerText?: string;
        AnswerRequired?: boolean;
        ExternalReferenceId?: string;
        MultipleAnswers?: IMultipleAnswer[];
        ParentSectionExtRefId?: string;
        ParentSectionId?: number;
        ParentSectionName?: string;
        QuestionProfileId?: number;
        QuestionText?: string;
        SectionExtRefId?: string;
        SectionId?: number;
        SectionName?: string;
        Text?: string;
        Title?: string;
        Value?: any;
    }



    export interface IUnAnsweredQuestion {
        AnswerRequired: boolean;
        CurrentText?: any;
        CurrentValue?: any;
        ExternalReferenceId: string;
        ParentSectionExtRefId: string;
        ParentSectionId: number;
        ParentSectionName: string;
        QuestionExtRefId: string;
        QuestionProfileId: number;
        SectionExtRefId: string;
        SectionId: number;
        SectionName: string;
        Text: string;
        Title: string;
    }

    export interface IPolicyDetail {
        ErrorDetails?: any;
        AnsweredQuestions: IAnsweredQuestion[];
        CustomerId: number;
        PolicyDetail: IPolicyDetail;
        PolicyId: number;
        PolicyReferenceId: string;
        PolicyStatus: number;
        PolicyStatusName: string;
        QuestionnaireId: number;
        QuestionnaireStatus: number;
        QuestionnaireStatusName: string;
        RedirectUrl: string;
        SectionDisclosures: any[];
        UnAnsweredQuestions: IUnAnsweredQuestion[];
        VersionId: number;
    }

    export interface ICreateOrUpdatePolicyDto {
        ErrorDetails?: any;
        PolicyDetail: IPolicyDetail;
    }

}

export declare module CreateOrUpdateApplicantDtoOutpuModel {

    export interface ICustomer {
        FirstName: string;
        Surname: string;
        NationalID: string;
        DateOfBirth: Date;
        EmailAddress: string;
        ExternalReference: string;
    }

    export interface ICreateOrUpdateApplicant {
        CedantId: string;
        Customer: ICustomer;
        ShowErrorDetails: string;
    }

}


export declare module ConditionListModule {

    export interface IConditionOutPut {
        ErrorDetails?: any;
        Data: IDatum[];
    }

    export interface IDatum {
        Description: string;
        FloatingIndex: boolean;
        Id: number;
        Sequence: number;
        SetupTableId: number;
        Status: number;
        Synonyms: ISynonym[];
        Title: string;
    }


    export interface ISynonym {
        FloatingIndex: boolean;
        Id: number;
        Sequence: number;
        SetupTableId: number;
        Status: number;
        Title: string;
    }

}

export interface SubmitAnswerAndGetUpdatedQuestions {
    sections?: GetQuestionariesModule.ISection;
    customerReferenceid?: string;
    policyReferenceId?: string;
    updatePolicyAnswers?: CreateOrUpdatePolicyInputModule.IUpdatePolicyOfApplicantWithAnswers;
}

export interface IAddressDetails {
    line1: string;
    line2: string;
    suburb: string;
    city: string;
    code: string;
}

export interface IBankDetails {
    BankName?: string;
    BankBranchCode?: string;
    BankAccType?: string;
    BankAccHolderInitials?: string;
    BankAccHolderFirstName?: string;
    BankAccHolderLastName?: string;
    BankAccHolderIDNumber?: string;
    BankAccNumber?: string;
    BankPaymentMethod?: string;
    BankCollectionDate?: number;
    BankCheckCDV?: boolean;
    BankAccHolder?: string;
}

export interface Brokerage {
    Brokers: Array<{
        Person: {
            GUID: string, Identifier: string
        }
    }>;
}

export interface BrokerageResult {
    result: Brokerage;
}

export interface CreateQuote extends IBankDetails {
    MainMember?: string;
    LifeAssured?: string;
    LifeAssuredAssuredType?: string;
    InceptionDate?: any;
    Broker?: any;
    OpportunityGUID?: string;
    QuoteStatus?: string;
}

export declare module QuirkQuestionsDictonaryModule {

    export interface Value {
        extId: string;
        value: string;
    }

    export interface IQuirkQuestionsDictonary {
        name: string;
        value: Value[];
    }

}

export declare module QuestionaireModule {

    export interface IValue {
        label?: string;
        type: number;
        value?: any;
        tooltipValue: string;
        isMonthlySection?: boolean;
        isMonthlyHouseRentalSection?: boolean;
        isOutstandingSection?: boolean;
        isAnnualSection?: boolean;
        isExpenseSection?: boolean;
        isSavingsSection?: boolean;
        dropdownItems?: IDropdownItem[];
        genderSection?: IValue;
        currencyInitials?: string;
    }

    export interface IInsuranceOption {
        name: string;
        value: string;
        isOnlyPremiumRequired: boolean;
    }

    export interface IDropdownItem {
        name: string;
        value: string;
    }

    export interface IAnswer {
        id?: number;
        name?: string;
        isAdditionalQuestion: boolean;
        isPartnerAvailable: boolean;
        isFirstMultiSelectAnswer?: boolean;
        insuranceOptions?: IInsuranceOption[];
        value?: IValue[];
        additionalQuestion?: IAdditionalQuestion[];
    }

    export interface IAdditionalQuestion {
        id?: number;
        name?: string;
        description: string;
        label: string;
        type: string;
        value?: any;
        tooltipValue: string;
        endingString?: string;
        isAdditionalQuestion?: boolean;
        currencyInitials?: string;
    }

    export interface IDropdownItem {
        name: string;
        value: string;
        kidsAgeValue?: any;
    }

    export interface IQuestionAnswerSection {
        question: string;
        description?: string;
        isOptionalQuestion: boolean;
        isMultipleAnswer: boolean;
        answers?: IAnswer[];
    }

    export interface IQuestionaire {
        section: string;
        questionAnswerSection: IQuestionAnswerSection[];
    }

    export interface IDebtOptions {
        debtOptionName: string;
        outsandingAmount: string;
    }

    export interface IInsuranceOptions {
        coverAmount: string;
        optionName: string;
        premiumAmount: string;
        schemeName: string;
        schemeOptionName: string;
        contributionAmount?: string;
        monthlyPremium?: string;
        amount?: string;
    }

}

export declare module MedicalAidInsuranceModule {

    export interface IOption {
        optionName: string;
    }

    export interface IScheme {
        schemeName: string;
        options: IOption[];
    }

}

export declare module DbMedicalAidInsuranceModule {

    export interface IDbOption {
        optionName: string;
        incomeOptions: IDbOptionIncomeBand[];
    }

    export interface IDbOptionIncomeBand {
        incomeBand: string;
        mainMemberContributionRate: number;
        adultDependantContributionRate: number;
        childDependantContributionRate: number;
    }

    export interface IDbScheme {
        schemeName: string;
        options: IDbOption[];
    }

    export interface IFlatDbScheme {
        schemeName: string;
        schemeOption: string;
        incomeBand: string;
    }
}

export interface IEventSetOutput {
    ShortCode: string;
    DisplayName: string;
    selected?: boolean;
    UID?: string;
}


export declare module ClaimsModule {
    export interface IClaimsSummary {
        section: string;
        values: ISummaryValues[];
    }

    export interface ISummaryValues {
        mainHeading?: string;
        desc1?: string;
        desc2?: string;
        desc3?: string;
        listArr?: string[];
    }

    export interface IQuestionAnsType {
        item?: string;
        value?: string;
    }


    export interface IHealthStatus {
        diagnosis?: string;
        dateOfDiagnosis?: any;
        doctorDiagnose?: string;
        treatingGP?: string;
        treatingSpecialist?: string;
        speciality?: string;
    }

    export interface ISymptoms {
        symptoms?: string;
        whenDoYouNotice?: any;
        testConfirms?: boolean;
        specifyTest?: string;
        testDone?: any;
    }

    export interface ITreatMent {
        PopulatedTreatmentsHere?: string;
        hospitalizationDate?: any;
        futureTreatment?: any;
    }

    export interface IPersonalDetails {
        firstName?: string;
        lastName?: string;
        idNumber?: string;
        address?: string;
        emailAddress?: string;
        cellNumber?: string;
        alternativeNumber?: string;
    }

    export interface IOccupationalInfo {
        company?: string;
        jobtitle?: string;
        workAddress?: string;
        workNumber?: string;
        directNumber?: string;
    }

    export interface IMedicalPractionaire {
        medicalAid?: string;
        plan?: string;
        memberNo?: string;
        dateJoined?: string;
        familyGp?: string;
        gpContactNo?: string;
        reasonSeeingGp?: string;
    }
}
export class NeedsAssessmentType {
    assessmentType: string;
    constructor(type: string) {
        this.assessmentType = type;
    }
}

export interface IProductAndBeneficiaryDetails {
    quoteId: string;
    productId: string;
    productName: string;
    beneficiaryDetails?: Array<IBeneficiaryDetailsPolcy>;


}
export interface IBeneficiaryDetailsPolcy {
    beneficiaryId: string;
    name: string;
    productId: string;
    quoteId: string;
    percentage: number;
    beneficiaryModel?: BenficiaryModule.IBeneficiaryModel;
}

export class FamilyAssesment extends NeedsAssessmentType {
    constructor() {
        super(AppConsts.AssessmentType.FamilyAssessment);
    }

    private _whoReliesFinanciallyOnYou: number;
    public get whoReliesFinanciallyOnYou(): number {
        return this._whoReliesFinanciallyOnYou;
    }
    public set whoReliesFinanciallyOnYou(value: number) {
        this._whoReliesFinanciallyOnYou = value;
    }

    private _myDob: string;
    public get myDob(): string {
        return this._myDob;
    }
    public set myDob(value: string) {
        this._myDob = value;
    }

    private _myGender: string;
    public get myGender(): string {
        return this._myGender;
    }
    public set myGender(value: string) {
        this._myGender = value;
    }
    private _partnerGender: string;
    public get partnerGender(): string {
        return this._partnerGender;
    }
    public set partnerGender(value: string) {
        this._partnerGender = value;
    }
    private _partnerDob: string;
    public get partnerDob(): string {
        return this._partnerDob;
    }
    public set partnerDob(value: string) {
        this._partnerDob = value;
    }
    private _noOfKids: string;
    public get noOfKids(): string {
        return this._noOfKids;
    }
    public set noOfKids(value: string) {
        this._noOfKids = value;
    }
    private _allKidsYears: number[];
    public get allKidsYears(): number[] {
        return this._allKidsYears || [];
    }
    public set allKidsYears(value: number[]) {
        this._allKidsYears = value;
    }
    private _childrenEducationCost: number;
    public get childrenEducationCost(): number {
        return this._childrenEducationCost;
    }
    public set childrenEducationCost(value: number) {
        this._childrenEducationCost = value;
    }

    private _childrenLumpSumCost: number;
    public get childrenLumpSumCost(): number {
        return this._childrenLumpSumCost;
    }
    public set childrenLumpSumCost(value: number) {
        this._childrenLumpSumCost = value;
    }

    private _childrenMonthlyCost: number;
    public get childrenMonthlyCost(): number {
        return this._childrenMonthlyCost;
    }
    public set childrenMonthlyCost(value: number) {
        this._childrenMonthlyCost = value;
    }

    toJson() {


        let kids = [];

        this.allKidsYears.forEach(x => {
            kids.push(x);
        });

        for (let i = kids.length; i < 5; i++) {
            kids[i] = 0;
        }


        return [{
            question: 'What does family look like to you?',
            answer: getWhoReiliesOnYou(this.whoReliesFinanciallyOnYou)
        }, {
            question: 'I was born on',
            answer: this.myDob
        }, {
            question: 'My gender is',
            answer: this.myGender
        }, {
            question: 'My partner was born on',
            answer: this.partnerDob
        }, {
            question: 'My partner\'s gender is',
            answer: this.partnerGender
        }, {
            question: 'I have',
            answer: this.noOfKids
        }, {
            question: 'My 1st Kid is',
            answer: kids[0]
        }, {
            question: 'My 2nd Kid is',
            answer: kids[1]
        }, {
            question: 'My 3rd Kid is',
            answer: kids[2]
        }, {
            question: 'My 4th Kid is',
            answer: kids[3]
        }, {
            question: 'My 5th Kid is',
            answer: kids[4]
        }, {
            question: 'What do you pay for their annual school/university fees?',
            answer: this.childrenEducationCost
        }];

        function getWhoReiliesOnYou(item) {
            if (item === 0) {
                return 'It\'s just me.';
            }

            if (item === 1) {
                return 'It\'s my partner and I.';
            }


            if (item === 2) {
                return 'It\'s my kids and I.';
            }

            if (item === 3) {
                return 'It\'s me, my kids and my partner.';
            }
        }
    }
}

export class EarningAssesment extends NeedsAssessmentType {
    constructor() {
        super(AppConsts.AssessmentType.EarningAssessment);
    }

    private _myGrossMonthlyIncome: number;
    public get myGrossMonthlyIncome(): number {
        return this._myGrossMonthlyIncome;
    }
    public set myGrossMonthlyIncome(value: number) {
        this._myGrossMonthlyIncome = value;
    }

    private _myNetMonthlyIncome: number;
    public get myNetMonthlyIncome(): number {
        return this._myNetMonthlyIncome;
    }
    public set myNetMonthlyIncome(value: number) {
        this._myNetMonthlyIncome = value;
    }

    private _occupation: any = {};
    public get occupation(): any {
        return this._occupation;
    }
    public set occupation(value: any) {
        this._occupation = value;
    }

    private _isSelfEmployed: boolean;
    public get isSelfEmployed(): boolean {
        return this._isSelfEmployed;
    }
    public set isSelfEmployed(value: boolean) {
        this._isSelfEmployed = value;
    }

    private _educationLevel: string;
    public get educationLevel(): string {
        return this._educationLevel;
    }
    public set educationLevel(value: string) {
        this._educationLevel = value;
    }

    private _annualIncome: number;
    public get annualIncome(): number {
        return this._annualIncome;
    }
    public set annualIncome(value: number) {
        this._annualIncome = value;
    }

    private _myRetirementAge: number;
    public get myRetirementAge(): number {
        return this._myRetirementAge;
    }
    public set myRetirementAge(value: number) {
        this._myRetirementAge = value;
    }

    private _incomeLossCoverPeriod: number;
    public get incomeLossCoverPeriod(): number {
        return this._incomeLossCoverPeriod;
    }
    public set incomeLossCoverPeriod(value: number) {
        this._incomeLossCoverPeriod = value;
    }

    toJson() {
        return [{
            question: 'My gross monthly income is',
            answer: this.myGrossMonthlyIncome
        }, {
            question: 'My net monthly income is',
            answer: this.myNetMonthlyIncome
        }, {
            question: 'Please tell us what you do for work (formal / self-employed).',
            answer: JSON.stringify(this.occupation)
        }, {
            question: 'What is your education level?',
            answer: this.educationLevel
        }];
    }
}

export class HomeAssessment extends NeedsAssessmentType {
    constructor() {
        super(AppConsts.AssessmentType.HomeAssessment);
    }

    private _howDoYouDefineHome: number;
    public get howDoYouDefineHome(): number {
        return this._howDoYouDefineHome;
    }
    public set howDoYouDefineHome(value: number) {
        this._howDoYouDefineHome = value;
    }

    private _myHomeWorth: number;
    public get myHomeWorth(): number {
        return this._myHomeWorth;
    }
    public set myHomeWorth(value: number) {
        this._myHomeWorth = value;
    }

    private _iHaveYearsLeft: number;
    public get iHaveYearsLeft(): number {
        return this._iHaveYearsLeft;
    }
    public set iHaveYearsLeft(value: number) {
        this._iHaveYearsLeft = value;
    }

    private _myOutstandingMortageAmount: number;
    public get myOutstandingMortageAmount(): number {
        return this._myOutstandingMortageAmount;
    }
    public set myOutstandingMortageAmount(value: number) {
        this._myOutstandingMortageAmount = value;
    }

    private _monthlyInstallments: number;
    public get monthlyInstallments(): number {
        return this._monthlyInstallments;
    }
    public set monthlyInstallments(value: number) {
        this._monthlyInstallments = value;
    }

    private _iPayMonthlyRentOf: number;
    public get iPayMonthlyRentOf(): number {
        return this._iPayMonthlyRentOf;
    }
    public set iPayMonthlyRentOf(value: number) {
        this._iPayMonthlyRentOf = value;
    }

    private _doYouHaveOtherDebts: Array<{ debtType?: string; amount?: number; }> = new Array<{ debtType?: string; amount?: number; }>();
    public get doYouHaveOtherDebts(): Array<{ debtType?: string; amount?: number; }> {
        return this._doYouHaveOtherDebts;
    }
    public set doYouHaveOtherDebts(value: Array<{ debtType?: string; amount?: number; }>) {
        this._doYouHaveOtherDebts = value;
    }
    private _monthlyHomeRental: number;
    public get monthlyHomeRental(): number {
        return this._monthlyHomeRental;
    }
    public set monthlyHomeRental(value: number) {
        this._monthlyHomeRental = value;
    }
    private _totalMonthlyDebtAmount;
    public get totalMonthlyDebtAmount() {
        return this._totalMonthlyDebtAmount;
    }
    public set totalMonthlyDebtAmount(value) {
        this._totalMonthlyDebtAmount = value;
    }

    private _totalDebtOutstandingAmount;
    public get totalDebtOutstandingAmount() {
        return this._totalDebtOutstandingAmount;
    }
    public set totalDebtOutstandingAmount(value) {
        this._totalDebtOutstandingAmount = value;
    }

    toJson() {
        return [{
            question: 'How do you define home?',
            answer: getCardNameById(this.howDoYouDefineHome)
        }, {
            question: 'My home is worth',
            answer: this.myHomeWorth
        }, {
            question: 'My outstanding mortgage amount',
            answer: this.myOutstandingMortageAmount
        }, {
            question: 'My monthly installments are',
            answer: this.monthlyInstallments
        }, {
            question: 'I have',
            answer: this.iHaveYearsLeft + ' years left'
        }, {
            question: 'I pay monthly rent of',
            answer: this.iPayMonthlyRentOf
        }, {
            question: 'Do you have any other debts?',
            answer: JSON.stringify(this.doYouHaveOtherDebts)
        }];

        function getCardNameById(id) {
            if (id === 0) {
                return 'I own my home.';
            }
            if (id === 1) {
                return 'I own my home, with a mortgage.';
            }
            if (id === 2) {
                return 'I rent my home.';
            }
            if (id === 3) {
                return 'I live rent free.';
            }

            return '';
        }

    }
}

export class InsuranceAssessment extends NeedsAssessmentType {
    constructor() {
        super(AppConsts.AssessmentType.InsuranceAssessment);
    }

    private _throughOutYourEmployment: Array<{ name: string; schemeName: string; schemeOptionName: string; premiumAmount: number; }> = new Array<{ name: string; schemeName: string; schemeOptionName: string; premiumAmount: number; }>();
    public get throughOutYourEmployment(): Array<{ name: string; schemeName: string; schemeOptionName: string; premiumAmount: number; }> {
        return this._throughOutYourEmployment;
    }
    public set throughOutYourEmployment(value: Array<{ name: string; schemeName: string; schemeOptionName: string; premiumAmount: number; }>) {
        this._throughOutYourEmployment = value;
    }

    private _isKeepingExistingPolicies;
    public get isKeepingExistingPolicies(): boolean {
        return this._isKeepingExistingPolicies;
    }
    public set isKeepingExistingPolicies(value: boolean) {
        this._isKeepingExistingPolicies = value;
    }

    private _purchaseYourself: Array<{ name: string; schemeName: string; schemeOptionName: string; coverAmount: number; premiumAmount: number; keepPolicy?: boolean; keepPercentage?: number }> = new Array<{ name: string; schemeName: string; schemeOptionName: string; coverAmount: number; premiumAmount: number; keepPolicy?: boolean; keepPercentage?: number }>();
    public get purchaseYourself(): Array<{ name: string; schemeName: string; schemeOptionName: string; coverAmount: number; premiumAmount: number; keepPolicy?: boolean; keepPercentage?: number }> {
        return this._purchaseYourself;
    }
    public set purchaseYourself(value: Array<{ name: string; schemeName: string; schemeOptionName: string; coverAmount: number; premiumAmount: number; keepPolicy?: boolean; keepPercentage?: number }>) {
        this._purchaseYourself = value;
    }

    private _totalInsuranceExpenseAmount: number;
    public get totalInsuranceExpenseAmount(): number {
        return this._totalInsuranceExpenseAmount;
    }
    public set totalInsuranceExpenseAmount(value: number) {
        this._totalInsuranceExpenseAmount = value;
    }


    toJson() {
        return [
            {
                question: 'What existing insurance cover do you have? : Through your employment:',
                answer: JSON.stringify(this.throughOutYourEmployment, null, 4)
            },
            {
                question: 'What existing insurance cover do you have? : You purchased for yourself:',
                answer: JSON.stringify(this.purchaseYourself, null, 4)
            },
        ];
    }
}

export class MedicalInsuranceAssessment extends NeedsAssessmentType {
    constructor() {
        super(AppConsts.AssessmentType.MeidcalInsuranceAssessment);
    }

    private _childDependents = 0;
    public get childDependents(): number {
        return this._childDependents;
    }
    public set childDependents(value: number) {
        this._childDependents = value;
    }

    private _adultDependents = 0;
    public get adultDependents(): number {
        return this._adultDependents;
    }
    public set adultDependents(value: number) {
        this._adultDependents = value;
    }

    private _currentContribution: number;
    public get currentContribution(): number {
        return this._currentContribution;
    }
    public set currentContribution(value: number) {
        this._currentContribution = value;
    }

    private _userSetContribution: number;
    public get userSetContribution(): number {
        return this._userSetContribution;
    }
    public set userSetContribution(value: number) {
        this._userSetContribution = value;
    }

    private _schemeName: string;
    public get schemeName(): string {
        return this._schemeName;
    }
    public set schemeName(value: string) {
        this._schemeName = value;
    }

    private _schemeOptionName: string;
    public get schemeOptionName(): string {
        return this._schemeOptionName;
    }
    public set schemeOptionName(value: string) {
        this._schemeOptionName = value;
    }

    private _name: string;
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }

    private _userId: string;
    public get userId(): string {
        return this._userId;
    }
    public set userId(value: string) {
        this._userId = value;
    }
}

export class ExpenseAssessment extends NeedsAssessmentType {
    constructor() {
        super(AppConsts.AssessmentType.ExpenseAssessment);
    }

    private _totalMonthlyHouseholdExpense: number;
    public get totalMonthlyHouseholdExpense(): number {
        return this._totalMonthlyHouseholdExpense;
    }
    public set totalMonthlyHouseholdExpense(value: number) {
        this._totalMonthlyHouseholdExpense = value;
    }

    private _coverLossOfMonthlySavings: boolean;
    public get coverLossOfMonthlySavings(): boolean {
        return this._coverLossOfMonthlySavings;
    }
    public set coverLossOfMonthlySavings(value: boolean) {
        this._coverLossOfMonthlySavings = value;
    }

    private _totalSavingsAmount: number;
    public get totalSavingsAmount(): number {
        return this._totalSavingsAmount;
    }
    public set totalSavingsAmount(value: number) {
        this._totalSavingsAmount = value;
    }

    private _schoolFess: number;
    public get schoolFess(): number {
        return this._schoolFess;
    }
    public set schoolFess(value: number) {
        this._schoolFess = value;
    }
    private _monthlyRent: number;
    public get monthlyRent(): number {
        return this._monthlyRent;
    }
    public set monthlyRent(value: number) {
        this._monthlyRent = value;
    }
    private _debtInstallments: number;
    public get debtInstallments(): number {
        return this._debtInstallments;
    }
    public set debtInstallments(value: number) {
        this._debtInstallments = value;
    }
    private _insurancePremiums: number;
    public get insurancePremiums(): number {
        return this._insurancePremiums;
    }
    public set insurancePremiums(value: number) {
        this._insurancePremiums = value;
    }
    private _totalMonthlyExpense: number;
    public get totalMonthlyExpense(): number {
        return this._totalMonthlyExpense;
    }
    public set totalMonthlyExpense(value: number) {
        this._totalMonthlyExpense = value;
    }

    toJson() {
        return [{
            question: 'What are your monthly expenses and savings on average?: Total monthly household expenses',
            answer: this.totalMonthlyHouseholdExpense
        }, {
            question: 'What are your monthly expenses and savings on average?: Total savings amount set aside for a rainy day',
            answer: this.totalSavingsAmount
        }];
    }
}


export class EstimateAssesment extends NeedsAssessmentType {
    constructor() {
        super(AppConsts.AssessmentType.EstimateAssessment);
    }

    private _lifeInsuranceLumpsumAmount: number;
    public get lifeInsuranceLumpsumAmount(): number {
        return this._lifeInsuranceLumpsumAmount;
    }
    public set lifeInsuranceLumpsumAmount(value: number) {
        this._lifeInsuranceLumpsumAmount = value;
    }
    private _SevereIllnessLumpsumAmount: number;
    public get SevereIllnessLumpsumAmount(): number {
        return this._SevereIllnessLumpsumAmount;
    }
    public set SevereIllnessLumpsumAmount(value: number) {
        this._SevereIllnessLumpsumAmount = value;
    }

    private _DisabilityLumpsumAmount: number;
    public get DisabilityLumpsumAmount(): number {
        return this._DisabilityLumpsumAmount;
    }
    public set DisabilityLumpsumAmount(value: number) {
        this._DisabilityLumpsumAmount = value;
    }
}
