import { Component, Injector, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-product-premium-modal',
  templateUrl: './confirm-product-premium-modal.component.html',
  styleUrls: ['./confirm-product-premium-modal.component.css']
})
export class ConfirmProductPremiumModalComponent implements OnInit {
  title: string;
  header: string;
  message: string;
  modalService: BsModalService;
  confirmProductPremium: Subject<string[]>;
  isConfirmModal: boolean;

  constructor(
    private bsModalRef: BsModalRef,
    injector: Injector,
  ) {
    this.confirmProductPremium = new Subject();
    this.modalService = injector.get(BsModalService);
  }

  ngOnInit() {
  }

  close(): void {
    this.bsModalRef.hide();
  }

  closeAndRevert(): void {
    this.confirmProductPremium.next(['revert']);
    this.close();
    this.modalService._hideModal(1);
  }

  confirm(): void {
      this.confirmProductPremium.next(['confirm']);
      this.close();
      this.modalService._hideModal(1);
     }

  revert(): void {
    this.confirmProductPremium.next(['revert']);
    this.close();
    this.modalService._hideModal(1);
  }

  onItemSelected(): Observable<string[]> {
    return this.confirmProductPremium.asObservable();
  }

}
