<div class="m-portlet">
  <div class="m-portlet__head">
    <div class="m-portlet__head-caption head-text-display w-100">
      <div class="col">
        <div class="m--space-20"></div>
        <div class="m-portlet__head-title d-flex flex-row justify-content-between align-items-center">
          <h3 class="m-portlet__head-text font-weight-bold">
            {{"Last7Days"|localize|uppercase}}
          </h3>

          <i class="fa fa-circle-notch fa-spin loader" *ngIf="isLoading"></i>
        </div>
        <div class="m--space-10"></div>
        <h6 class="m-portlet__head-desc font-weight-light m--regular-font-size-sm3">
          {{"ASummaryOfYour"|localize}}
        </h6>
      </div>
    </div>
  </div>
  <div class="m-portlet__body m-portlet__body--no-top-padding">


    <div class="tab-pane active" id="kt_widget5_tab1_content" aria-expanded="true">



      <div (click)="scrollToTop()" class="m-widget5">
        <div class="m-widget5__item justify-content-between d-inline-flex steps for-widget">
          <div class="m-widget5__content text-center">
            <div class="m-widget5__pic">
              <img class="mt-2 width-percent-65" src="../../../assets/common/images/Elevate _ Wearables - Steps.png"
                alt="">
            </div>
            <div class="m-widget5__section">
              <h5 class="mt-3 m-widget5__title">
                Steps
              </h5>
            </div>
          </div>
          <div class="m-widget5__content text-center">
            <div class="mt-3 m-widget5__stats">
              <span class="m-widget5__number mr-2">
                {{_wearableService.last7Days.stepsAvg}}
              </span>
              <span class="m-widget5__sales"><small><small>{{"AvgDaily"|localize}}</small></small></span>
            </div>
          </div>
        </div>
        <div class="m-widget5__item justify-content-between d-inline-flex actmin for-widget">
          <div class="m-widget5__content text-center">
            <div class="m-widget5__pic">
              <img class="mt-2 width-percent-65"
                src="../../../assets/common/images/Elevate _ Wearables - Active Minutes.png" alt="">
            </div>
            <div class="m-widget5__section">
              <h5 class="mt-3 m-widget5__title">
                {{"ActiveMinutes"|localize}}
              </h5>
            </div>
          </div>
          <div class="m-widget5__content text-center">
            <div class="mt-3 m-widget5__stats">
              <span class="m-widget5__number mr-2">
                {{_wearableService.last7Days.durationSum}}
              </span>
              <span class="m-widget5__sales"><small><small>AVG MIN</small></small> </span>
            </div>
          </div>
        </div>

        <div class="m-widget5__item justify-content-between d-inline-flex distance for-widget">
          <div class="m-widget5__content text-center">
            <div class="m-widget5__pic">
              <img class="mt-2 width-percent-65" src="../../../assets/common/images/Elevate _ Wearables - Distance.png"
                alt="">
            </div>
            <div class="m-widget5__section">
              <h5 class="mt-3 m-widget5__title">
                {{"Distance"|localize}}
              </h5>
            </div>
          </div>
          <div class="m-widget5__content text-center">
            <div class="mt-3 m-widget5__stats">
              <span class="m-widget5__number mr-2">
                {{_wearableService.last7Days.distanceAvg | number : '1.0-2'}}
              </span>
              <span class="m-widget5__sales"><small><small>AVG KM</small></small></span>
            </div>
          </div>
        </div>

        <div [routerLink]="['/app/main/SyncedDetails']"
          class="m-widget5__item justify-content-between d-inline-flex syncact for-widget">
          <div class="m-widget5__content text-center">
            <div class="m-widget5__pic">
              <img class="mt-2 mywearable-35" src="../../../assets/common/images/my_wearables-01.png" alt="">
            </div>
            <div class="m-widget5__section text-left">
              <h5 class="mt-3 m-widget5__title">
                {{"SyncedActivities"|localize}}
              </h5>
            </div>
          </div>
          <div class="m-widget5__content text-center">
            <div class="mt-3 m-widget5__stats">
              <span class="m-widget5__number mr-2">
                {{_wearableService.last7Days.sourcesync}}
              </span>
              <span class="m-widget5__sales font-weight-bold"></span>
            </div>
          </div>
        </div>

        <div [routerLink]="['/app/main/SyncedDetails']"
          class="justify-content-between d-inline-flex m-widget5__item mact for-widget">
          <div class="m-widget5__content text-center">
            <div class="m-widget5__pic">
              <img class="mt-2" style="width: 48px !important;"
                src="../../../assets/common/images/Elevate _ Wearables - Steps.png" alt="">
            </div>
            <div class="m-widget5__section text-left">
              <h5 class="mt-3 m-widget5__title">
                {{"MostRecentActivities"|localize}}
              </h5>
            </div>
          </div>
          <div class="m-widget5__content text-center">
            <div class="mt-3 m-widget5__stats">
              <span class="m-widget5__number mr-2">
                {{_wearableService.last7Days.mstact}} &nbsp; &nbsp; {{_wearableService.last7Days.duration}}
                <span class="m-widget5__sales"><small><small>MIN</small></small></span>
              </span>
              <span class="m-widget5__sales font-weight-bold"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>