var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import * as moment from 'moment/moment';
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { SwaggerException } from '@shared/service-proxies/service-proxies';
import { Router } from '@angular/router';
var QuoteService = /** @class */ (function () {
    function QuoteService(httpCLient, router) {
        this.httpCLient = httpCLient;
        this.router = router;
        this.jsonParseReviver = undefined;
        this.API_BASE_URL_IDP = abp.setting.values['PortalSettings.authority'];
        this.unitRate = 0.00025;
    }
    QuoteService.prototype.getProdRequestHeaders = function () {
        return {
            'Accept': 'application/json'
        };
    };
    QuoteService.prototype.getProdUrl = function () {
        var url_ = this.API_BASE_URL_IDP + 'api/DirectSales/IsDirectSales?';
        return url_;
    };
    QuoteService.prototype.updateUserProfile = function (profileDetails, userID, accessToken) {
        var headers = new HttpHeaders({
            'sub': userID,
            'Authorization': 'Bearer ' + accessToken
        });
        var url_ = this.API_BASE_URL_IDP + 'api/DirectSales/UpdateUserProfileDetails';
        return this.httpCLient.post(url_, profileDetails, { headers: headers });
    };
    QuoteService.prototype.resendOTP = function (userid, phonenumber) {
        var url_ = this.API_BASE_URL_IDP + 'api/DirectSales/ResendOTP?userid=' + userid + '&phonenumber=' + phonenumber;
        this.httpCLient.get(url_)
            .subscribe(function (res) {
            abp.ui.clearBusy();
            if (res.result === 200) {
                abp.notify.success('sent.');
            }
            else {
                abp.notify.error('try again.');
            }
        }, function (err) {
            abp.ui.clearBusy();
        });
    };
    QuoteService.prototype.checkIfUserAlreadyExist = function (input) {
        var _this = this;
        var url_ = this.getProdUrl();
        if (input.cellNumber !== undefined) {
            url_ += 'cellNumber=' + encodeURIComponent('' + input.cellNumber) + '&';
        }
        if (input.dob !== undefined) {
            url_ += 'dob=' + encodeURIComponent('' + moment(input.dob).toJSON()) + '&';
        }
        if (input.email !== undefined) {
            url_ += 'email=' + encodeURIComponent('' + input.email) + '&';
        }
        if (input.gender !== undefined) {
            url_ += 'gender=' + encodeURIComponent('' + input.gender) + '&';
        }
        if (input.name !== undefined) {
            url_ += 'name=' + encodeURIComponent('' + input.name) + '&';
        }
        if (input.surname !== undefined) {
            url_ += 'surname=' + encodeURIComponent('' + input.surname) + '&';
        }
        if (input.identityNumber !== undefined) {
            url_ += 'identityNumber=' + encodeURIComponent('' + input.identityNumber);
        }
        url_ = url_.replace(/[?&]$/, '');
        var options_ = {
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                'Accept': 'application/json',
            })
        };
        return this.httpCLient.request('get', url_, options_).pipe(_observableMergeMap(function (response_) {
            return _this.processcheckIfUserAlreadyExist(response_);
        })).pipe(_observableCatch(function (response_) {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return _this.processcheckIfUserAlreadyExist(response_);
                }
                catch (e) {
                    return _observableThrow(e);
                }
            }
            else {
                return _observableThrow(response_);
            }
        }));
    };
    QuoteService.prototype.processcheckIfUserAlreadyExist = function (response) {
        var _this = this;
        var e_1, _a;
        var status = response.status;
        var responseBlob = response instanceof HttpResponse ? response.body :
            response.error instanceof Blob ? response.error : undefined;
        var _headers = {};
        if (response.headers) {
            try {
                for (var _b = __values(response.headers.keys()), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var key = _c.value;
                    _headers[key] = response.headers.get(key);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === '' ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = resultData200 !== undefined ? resultData200 : null;
                return _observableOf(result200);
            }));
        }
        else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(function (_responseText) {
                return throwException('An unexpected server error occurred.', status, _responseText, _headers);
            }));
        }
        return _observableOf(null);
    };
    QuoteService.prototype.UpdateUserDetails = function (confimationOfCustomerOtpAndEmailDto, accessToken) {
        var _this = this;
        var url_ = this.API_BASE_URL_IDP + 'api/DirectSales/UpdateUserDetails';
        var content_ = JSON.stringify(confimationOfCustomerOtpAndEmailDto);
        var options_ = {
            body: content_,
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            })
        };
        return this.httpCLient.request('post', url_, options_).pipe(_observableMergeMap(function (response_) {
            return _this.processcheckIfUserDetailsUpdated(response_);
        })).pipe(_observableCatch(function (response_) {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return _this.processcheckIfUserDetailsUpdated(response_);
                }
                catch (e) {
                    return _observableThrow(e);
                }
            }
            else {
                return _observableThrow(response_);
            }
        }));
    };
    QuoteService.prototype.processcheckIfUserDetailsUpdated = function (response) {
        var _this = this;
        var e_2, _a;
        var status = response.status;
        var responseBlob = response instanceof HttpResponse ? response.body :
            response.error instanceof Blob ? response.error : undefined;
        var _headers = {};
        if (response.headers) {
            try {
                for (var _b = __values(response.headers.keys()), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var key = _c.value;
                    _headers[key] = response.headers.get(key);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        if (status === 200) {
            abp.ui.clearBusy();
            return blobToText(responseBlob).pipe(_observableMergeMap(function (_responseText) {
                _this.result = JSON.parse(_responseText);
                if (_this.result.result !== 200) {
                    if (_this.result.message === 'IDENTITYNUMBER EXIST' || _this.result.message === 'Identity Number Already Exist!') {
                        abp.message.confirm('We see that you are already an Elevate customer. Please log in to continue this process in your portal.', 'EXISTING CUSTOMER', function (res) {
                            if (res) {
                                return of(null);
                            }
                            else {
                                _this.router.navigate(['/app/main/MyCommandCentre']);
                            }
                        });
                        _this.changeTemplatePopup('CLOSE', 'LOG IN');
                    }
                    else if (_this.result.message === 'EMAIL EXIST' || _this.result.message === 'PHONENUMBER EXIST') {
                        abp.message.confirm('The email address and/or phone number that you have inputted match those of an existing Elevate customer.<br /><br />Because we share sensitive information over these mediums we do not allow two customers to have the same email address and/or phone number.<br /><br />Log in to continue this application process in your Elevate portal or provide a different email address and/or phone number.', 'EXISTING CUSTOMER', function (res) {
                            if (res) {
                                return of(null);
                            }
                            else {
                                _this.router.navigate(['/app/main/MyCommandCentre']);
                            }
                        }, true);
                        _this.changeTemplatePopup('GO BACK', 'LOG IN');
                    }
                    else if (_this.result.result === 400) {
                        abp.message.confirm(_this.result.message, 'PLEASE NOTE', function (res) {
                            if (res) {
                                return of(null);
                            }
                        });
                        _this.changeTemplatePopup('CLOSE', undefined);
                    }
                    else {
                        abp.message.confirm('Please Contact Support, as we could not assist at present.', 'PLEASE NOTE', function (res) {
                            if (res) {
                                return of(null);
                            }
                        });
                        _this.changeTemplatePopup('CLOSE', undefined);
                    }
                }
                var userString = localStorage.getItem('current_user_details');
                var userDetails;
                userDetails = JSON.parse(userString);
                userDetails.sub = _this.result.sub;
                localStorage.setItem('current_user_details', JSON.stringify(userDetails));
                var result200 = null;
                var resultData200 = _responseText === '' ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = resultData200 !== undefined ? resultData200 : null;
                if (_this.result.result === 200) {
                    return of(_this.result.message);
                }
            }));
        }
        else if (status !== 200 && status !== 204) {
            abp.ui.clearBusy();
            return blobToText(responseBlob).pipe(_observableMergeMap(function (_responseText) {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                return of(null);
            }));
        }
        return _observableOf(false);
    };
    QuoteService.prototype.RegisterUser = function (input, accessToken, GToken) {
        var _this = this;
        var url_ = this.API_BASE_URL_IDP + 'api/DirectSales/AutoRegister';
        var content_ = JSON.stringify(input);
        var options_ = {
            body: content_,
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
                'GToken': GToken
            })
        };
        return this.httpCLient.request('post', url_, options_).pipe(_observableMergeMap(function (response_) {
            return _this.processRegisterUser(response_);
        })).pipe(_observableCatch(function (response_) {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return _this.processRegisterUser(response_);
                }
                catch (e) {
                    return _observableThrow(e);
                }
            }
            else {
                return _observableThrow(response_);
            }
        }));
    };
    QuoteService.prototype.processRegisterUser = function (response) {
        var _this = this;
        var e_3, _a;
        var self = this;
        var status = response.status;
        var responseBlob = response instanceof HttpResponse ? response.body :
            response.error instanceof Blob ? response.error : undefined;
        var _headers = {};
        if (response.headers) {
            try {
                for (var _b = __values(response.headers.keys()), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var key = _c.value;
                    _headers[key] = response.headers.get(key);
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap(function (_responseText) {
                _this.result = JSON.parse(_responseText);
                if (!handleError()) {
                    return _observableOf(false);
                }
                var result200 = null;
                var resultData200 = _responseText === '' ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = resultData200 !== undefined ? resultData200 : null;
                if (_this.result.result === 200) {
                    var user = JSON.parse(localStorage.getItem('current_user_details'));
                    user.sub = _this.result.sub;
                    localStorage.setItem('current_user_details', JSON.stringify(user));
                    return _observableOf(true);
                }
                return _observableOf(false);
            }));
        }
        else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(function (_responseText) {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                return _observableOf(false);
            }));
        }
        return _observableOf(false);
        function handleError() {
            if (self.result.result !== 200) {
                if (self.result.message === 'IDENTITYNUMBER EXIST') {
                    abp.message.confirm('We see that you are already an Elevate customer. Please log in to continue this process in your portal.', 'EXISTING CUSTOMER', function (res) {
                        if (res) {
                            return of(false);
                        }
                        else {
                            self.router.navigate(['/app/main/MyCommandCentre']);
                        }
                    });
                    self.changeTemplatePopup('CLOSE', 'LOG IN');
                }
                else if (self.result.message === 'EMAIL EXIST' || self.result.message === 'PHONENUMBER EXIST') {
                    abp.message.confirm('The email address and/or phone number that you have inputted match those of an existing Elevate customer.<br /><br />Because we share sensitive information over these mediums we do not allow two customers to have the same email address and/or phone number.<br /><br />Log in to continue this application process in your Elevate portal or provide a different email address and/or phone number.', 'EXISTING CUSTOMER', function (res) {
                        if (res) {
                            return false;
                        }
                        else {
                            self.router.navigate(['/app/main/MyCommandCentre']);
                        }
                    }, true);
                    self.changeTemplatePopup('GO BACK', 'LOG IN');
                }
                else if (self.result.result === 400) {
                    abp.message.confirm(self.result.message, 'PLEASE NOTE', function (res) {
                        if (res) {
                            return false;
                        }
                    });
                    self.changeTemplatePopup('CLOSE', undefined);
                }
                else {
                    abp.message.confirm('Please Contact Support, as we could not assist at present.', 'PLEASE NOTE', function (res) {
                        if (res) {
                            return false;
                        }
                    });
                    self.changeTemplatePopup('CLOSE', undefined);
                }
                return true;
            }
            return true;
        }
    };
    QuoteService.prototype.changeTemplatePopup = function (item, item1) {
        if (item !== undefined) {
            document.getElementsByClassName('swal2-confirm')[0].innerText = item;
            document.getElementsByClassName('swal2-confirm')[0].classList.add('modal-button-clr');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('font-size-12');
        }
        else {
            document.getElementsByClassName('swal2-confirm')[0].classList.add('d-none');
        }
        if (item1 !== undefined) {
            document.getElementsByClassName('swal2-cancel')[0].innerText = item1;
            document.getElementsByClassName('swal2-cancel')[0].classList.add('modal-button-clr');
            document.getElementsByClassName('swal2-cancel')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-cancel')[0].classList.add('font-size-12');
        }
        else {
            document.getElementsByClassName('swal2-cancel')[0].classList.add('d-none');
        }
    };
    QuoteService.prototype.handleError = function (error) {
        //To know the version of RxJS npm list --depth=0 (I for this example im on version 5.5)
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred: ', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + (" body was: " + error.message));
        }
        // return an observable with a user-facing error message
        return throwException('Something bad happened; please try again later.', error.status, error.message, error.headers);
    };
    return QuoteService;
}());
export { QuoteService };
var AnonymousUserDetail = /** @class */ (function () {
    function AnonymousUserDetail() {
    }
    return AnonymousUserDetail;
}());
export { AnonymousUserDetail };
function throwException(message, status, response, headers, result) {
    if (result !== null && result !== undefined) {
        return _observableThrow(result);
    }
    else {
        return _observableThrow(new SwaggerException(message, status, response, headers, null));
    }
}
function blobToText(blob) {
    return new Observable(function (observer) {
        if (!blob) {
            observer.next('');
            observer.complete();
        }
        else {
            var reader = new FileReader();
            reader.onload = function () {
                observer.next(this.result);
                observer.complete();
            };
            reader.readAsText(blob);
        }
    });
}
