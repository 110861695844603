import { NgModule } from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import {ExternalFnaModule} from '@app/external-fna/external-fna.module';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                children: [
                    {
                        path: '',
                        children: [
                            { path: '', redirectTo: '/dashboard', pathMatch: 'full' }
                        ]
                    },
                    {
                        path: 'main',
                        loadChildren: 'app/main/main.module#MainModule', //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'ExtQuote',
                        loadChildren: 'app/external-quote/external-quote.module#ExternalQuoteModule',
                        data: { preload: true },
                    },
                    {
                        path: 'ExtQuote/:referenceId',
                        loadChildren: 'app/external-quote/external-quote.module#ExternalQuoteModule',
                        data: { preload: true },
                    },
                    {
                        path: 'ExtCalculator',
                        loadChildren: 'app/external-calculator/external-calculator.module#ExternalCalculatorModule',
                        data: { preload: true },
                    },
                    {
                        path: 'fna',
                        loadChildren: 'app/external-fna/external-fna.module#ExternalFnaModule',
                        data: { preload: true },
                    },
                    //{
                    //    path: 'admin',
                    //    loadChildren: 'app/admin/admin.module#AdminModule', //Lazy load admin module
                    //    data: { preload: true },
                    //    canActivate: [AuthGuard]
                    //},
                    {
                        path: '**', redirectTo: 'notifications'
                    }
                ]
            }
        ])
    ],
    exports: [RouterModule],
})

export class AppRoutingModule {
    constructor(
        private router: Router
    ) {
        router.events.subscribe((event) => {

            if (event instanceof RouteConfigLoadStart) {
                abp.ui.setBusy();
            }

            if (event instanceof RouteConfigLoadEnd) {
                abp.ui.clearBusy();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
            }
        });
    }
}
