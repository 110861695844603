<!-- BEGIN: Horizontal Menu -->
<button class="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-light " id="m_aside_header_menu_mobile_close_btn"><i class="la la-close"></i></button>
<div class="{{getCssClass()}}" id="m_header_menu" #m_header_menu>
    <ul class="m-menu__nav m-menu__nav--submenu-arrow">
        <ng-container *ngFor="let item of menu.items">
            <ng-container *ngIf="item.name" [ngTemplateOutlet]="m_menu" [ngTemplateOutletContext]="{ item: item, depth: 0 }">
            </ng-container>
        </ng-container>
    </ul>
</div>
<!-- END: Horizontal Menu -->


<ng-template #m_menu let-item="item" let-parentItem="parentItem" let-depth="depth">
    <li attr.aria-haspopup="true"
        [attr.m-menu-submenu-toggle]="getItemAttrSubmenuToggle(item, parentItem, depth)"
        class="{{ getItemCssClasses(item, parentItem, depth) }}"
        *ngIf="showMenuItem(item)"
        routerLinkActive="m-menu__item--active">

        <!-- if item has submenu -->
        <ng-container *ngIf="item.items.length || (isTabMenuUsed && !parentItem && !item.items.length)">
            <a href="javascript:;" class="{{ getAnchorItemCssClasses(item, parentItem) }}">

                <ng-container [ngTemplateOutlet]="m_menu_item_inner" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: (depth + 1) }"></ng-container>

                <!-- arrow icons -->
                <i *ngIf="item.items.length && !parentItem" class="m-menu__hor-arrow la la-angle-down"></i>
                <i *ngIf="item.items.length && !parentItem" class="m-menu__ver-arrow la la-angle-right"></i>
                <!-- else arrow icons -->
                <i *ngIf="item.items.length && parentItem" class="m-menu__hor-arrow la la-angle-right"></i>
                <i *ngIf="item.items.length && parentItem" class="m-menu__ver-arrow la la-angle-right"></i>
            </a>
        </ng-container>

        <!-- if item hasn't sumbenu -->
        <ng-container *ngIf="!item.items.length && !(isTabMenuUsed && !parentItem && !item.items.length)">
            <a *ngIf="!item.external" [routerLink]="item.route" [queryParams]="item.parameters" class="m-menu__link">
                <ng-container [ngTemplateOutlet]="m_menu_item_inner" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: (depth + 1) }"></ng-container>
            </a>
            <a *ngIf="item.external" [attr.href]="[item.route]" target="_blank" class="m-menu__link">
                <ng-container [ngTemplateOutlet]="m_menu_item_inner" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: (depth + 1) }"></ng-container>
            </a>
        </ng-container>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <ng-container *ngIf="item.items.length">
            <div class="{{ getSubmenuCssClasses(item, parentItem, depth) }}">
                <span class="m-menu__arrow" [ngClass]="{ 'm-menu__arrow--adjust': !parentItem }"></span>
                <ul *ngIf="item.items.length" class="m-menu__subnav">
                    <ng-container *ngFor="let child of item.items">

                        <ng-container [ngTemplateOutlet]="m_menu" [ngTemplateOutletContext]="{ item: child, parentItem: item, depth: (depth + 1) }">
                        </ng-container>

                    </ng-container>
                </ul>
            </div>
        </ng-container>
        <ng-container *ngIf="isTabMenuUsed && !parentItem && !item.items.length">
            <div class="m-menu__submenu m-menu__submenu--classic m-menu__submenu--left" [ngClass]="{'m-menu__submenu--tabs': isTabMenuUsed}">
                <span class="m-menu__arrow" [ngClass]="{ 'm-menu__arrow--adjust': !parentItem }"></span>
                <ul class="m-menu__subnav">
                    <ng-container [ngTemplateOutlet]="m_menu" [ngTemplateOutletContext]="{ item: item, parentItem: item, depth: (depth + 1) }">
                    </ng-container>
                </ul>
            </div>
        </ng-container>
    </li>
</ng-template>

<!-- item inner -->
<ng-template #m_menu_item_inner let-item="item" let-parentItem="parentItem">
    <!-- if menu item has icon -->
    <i *ngIf="item.icon" class="m-menu__link-icon" [ngClass]="item.icon"></i>

    <ng-container>
        <span class="m-menu__item-here"></span>
        <!-- menu item title text -->
        <span class="m-menu__link-text">
            {{item.name | localize}}
        </span>
    </ng-container>
</ng-template>
