var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NotificationServiceProxy, NotificationSubscriptionDto, UpdateNotificationSettingsInput } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
var NotificationSettingsModalComponent = /** @class */ (function (_super) {
    __extends(NotificationSettingsModalComponent, _super);
    function NotificationSettingsModalComponent(injector, _notificationService) {
        var _this = _super.call(this, injector) || this;
        _this._notificationService = _notificationService;
        _this.saving = false;
        return _this;
    }
    NotificationSettingsModalComponent.prototype.show = function () {
        var _this = this;
        this.getSettings(function () {
            _this.modal.show();
        });
    };
    NotificationSettingsModalComponent.prototype.save = function () {
        var _this = this;
        var input = new UpdateNotificationSettingsInput();
        input.receiveNotifications = this.settings.receiveNotifications;
        input.notifications = _.map(this.settings.notifications, function (n) {
            var subscription = new NotificationSubscriptionDto();
            subscription.name = n.name;
            subscription.isSubscribed = n.isSubscribed;
            return subscription;
        });
        this.saving = true;
        this._notificationService.updateNotificationSettings(input)
            .pipe(finalize(function () { return _this.saving = false; }))
            .subscribe(function () {
            _this.notify.info(_this.l('SavedSuccessfully'));
            _this.close();
        });
    };
    NotificationSettingsModalComponent.prototype.close = function () {
        this.modal.hide();
    };
    NotificationSettingsModalComponent.prototype.getSettings = function (callback) {
        var _this = this;
        this._notificationService.getNotificationSettings().subscribe(function (result) {
            _this.settings = result;
            callback();
        });
    };
    return NotificationSettingsModalComponent;
}(AppComponentBase));
export { NotificationSettingsModalComponent };
