var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector, NgZone, ChangeDetectorRef } from '@angular/core';
import { MyHealthcareService } from '@app/shared/services/my-healthcare.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { SmsBody } from '@shared/service-proxies/service-proxies';
import { CurrencyPipeZar } from '@shared/common/pipes/commonElevate.pipe';
am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);
var EpisodeCareComponent = /** @class */ (function (_super) {
    __extends(EpisodeCareComponent, _super);
    function EpisodeCareComponent(zone, injector, _changeDetecter, _healtCareService, currencyProvider) {
        var _this = _super.call(this, injector) || this;
        _this.zone = zone;
        _this._changeDetecter = _changeDetecter;
        _this._healtCareService = _healtCareService;
        _this.currencyProvider = currencyProvider;
        _this.currentUserQuery = '';
        _this.yearActive = new Array();
        _this.showChart = undefined;
        _this.imgSrc = '../../../assets/common/images/chatblack.svg';
        return _this;
    }
    EpisodeCareComponent.prototype.ngOnInit = function () {
    };
    EpisodeCareComponent.prototype.loadEpisodeOFCare = function () {
        var _this = this;
        this.isLoading = true;
        this.updateClaimsIdsvr().then(function (res) {
            _this.getHealthCareYearDropDownList();
        });
    };
    EpisodeCareComponent.prototype.getHealthCareYearDropDownList = function () {
        this.currentEpisodeYear = this._healtCareService.yearList[0].toString();
        this.yearDropDownDefaultAction();
        if (this._healtCareService.yearList.length > 0) {
            this.getEpisodeofCareData(this._healtCareService.yearList[0].toString());
        }
        else {
            this.isLoading = false;
            this.showChart = false;
        }
    };
    EpisodeCareComponent.prototype.getEpisodeofCareData = function (year) {
        var _this = this;
        this.isLoading = true;
        this.currentEpisodeYear = year;
        var tempThis = this;
        this._healtCareService.getEpisodeData(year).then(function () {
            tempThis.loadChart();
        }).catch(function (err) {
            console.error(err);
        }).finally(function () {
            _this.isLoading = false;
        });
    };
    EpisodeCareComponent.prototype.loadChart = function () {
        this.showChart = this._healtCareService.episodeCareData.length > 0 ? true : false;
        this._changeDetecter.detectChanges();
        if (this.showChart) {
            this.loadEpisodeCare();
        }
    };
    EpisodeCareComponent.prototype.getCurrencyValue = function (val) {
        if (!val) {
            val = '0';
        }
        return this.currencyProvider.transform(+val, true, true, true);
    };
    EpisodeCareComponent.prototype.loadEpisodeCare = function () {
        var _this = this;
        this.zone.runOutsideAngular(function () {
            _this.chart = am4core.create('episodeofcarechart', am4charts.XYChart);
            _this.chart.tapToActivate = true;
            _this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
            _this.chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';
            _this.chart.responsive.enabled = false;
            _this.chart.data = [];
            _this._healtCareService.episodeCareData.forEach(function (res) {
                _this.chart.data.push({ name: res.description, fromDate: res.startDate, toDate: res.endDate, Diagnosis: res.diagnosis, Amount_Claimed: _this.getCurrencyValue(res.amount_claimed), shortfallamount: _this.getCurrencyValue(res.shortfall_Amount), paidAmount: _this.getCurrencyValue(res.paidAmount) });
            });
            _this.chart.numberFormatter.numberFormat = '# ###.##';
            var categoryAxis = _this.chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = 'name';
            categoryAxis.renderer.grid.template.location = 1;
            categoryAxis.renderer.inversed = true;
            categoryAxis.renderer.line.strokeOpacity = 1;
            categoryAxis.renderer.labels.template.wrap = true;
            categoryAxis.renderer.labels.template.width = 100;
            categoryAxis.renderer.labels.template.inside = false;
            categoryAxis.renderer.minGridDistance = 20;
            //categoryAxis.renderer.line.dx = 100;
            //categoryAxis.startLocation = -0.3;
            var dateAxis = _this.chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.dateFormatter.dateFormat = 'yyyy-MM-dd';
            dateAxis.renderer.tooltipLocation = 0;
            dateAxis.renderer.grid.template.disabled = true;
            dateAxis.baseInterval = { timeUnit: 'day', count: 5 },
                dateAxis.strictMinMax = false;
            dateAxis.renderer.grid.template.location = 0;
            dateAxis.renderer.labels.template.rotation = -90;
            var series1 = _this.chart.series.push(new am4charts.ColumnSeries());
            series1.columns.template.width = am4core.percent(60);
            series1.columns.template.tooltipHTML = '{fromDate.formatDate(\'dd MMM, yyyy\')} <br> {Diagnosis} <br> Claimed: {Amount_Claimed} <br> Paid: {paidAmount.formatNumber(\'#,###.##\')}';
            series1.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
            series1.tooltip.getFillFromObject = false;
            series1.tooltip.background.fill = am4core.color('#B01917');
            series1.dataFields.openDateX = 'fromDate';
            series1.dataFields.dateX = 'toDate';
            series1.dataFields.categoryY = 'name';
            series1.columns.template.fill = am4core.color('#B01917'); // get color from data
            series1.columns.template.stroke = am4core.color('#B01917');
            series1.columns.template.strokeOpacity = 1;
            series1.columns.template.column.cornerRadiusTopLeft = 50;
            series1.columns.template.column.cornerRadiusTopRight = 50;
            series1.columns.template.marginLeft = 50;
            series1.columns.template.column.cornerRadiusBottomLeft = 50;
            series1.columns.template.column.cornerRadiusBottomRight = 50;
            series1.columns.template.height = 20;
            series1.columns.template.fill = am4core.color('#B01917');
            series1.tooltip.getFillFromObject = false;
            series1.tooltip.background.fill = am4core.color('black');
            series1.sequencedInterpolation = true;
            _this.chart.cursor = new am4charts.XYCursor();
            _this.chart.cursor.behavior = 'zoomXY';
            var cellSize = 80;
            _this.chart.events.on('datavalidated', function (ev) {
                // Get objects of interest
                var chartq = ev.target;
                var categoryAxisq = chartq.yAxes.getIndex(0);
                // Calculate how we need to adjust chart height
                var adjustHeight = chartq.data.length * cellSize - categoryAxisq.pixelHeight;
                // get current chart height
                var targetHeight = chartq.pixelHeight + (adjustHeight < 0 ? 0 : adjustHeight);
                // Set it on chart's container
                chartq.svgContainer.htmlElement.style.height = targetHeight + 'px';
            });
        });
    };
    EpisodeCareComponent.prototype.yearDropDownDefaultAction = function () {
        var _this = this;
        var i = 0;
        this._healtCareService.yearList.forEach(function (x) {
            if (i === 0) {
                _this.yearActive.push(true);
            }
            else {
                _this.yearActive.push(false);
            }
            i++;
        });
    };
    EpisodeCareComponent.prototype.FilterWhithYearEpisode = function (item, i) {
        this.showChart = undefined;
        this.currentEpisodeYear = item;
        var d = this.yearActive.findIndex(function (x) { return x === true; });
        this.yearActive[d] = false;
        this.yearActive[i] = true;
        this.getEpisodeofCareData(item);
    };
    EpisodeCareComponent.prototype.sendsms = function (PageName) {
        var body = new SmsBody();
        body.userid = this._healtCareService._commonHelper._user.profile.sub;
        body.firstName = this._healtCareService._commonHelper._user.profile.given_name;
        body.lastName = this._healtCareService._commonHelper._user.profile.family_name;
        body.email = this._healtCareService._commonHelper._user.profile.email;
        body.phoneNumber = Array.isArray(this._healtCareService._commonHelper._user.profile.phone_number) ? this._healtCareService._commonHelper._user.profile.phone_number[0] : this._healtCareService._commonHelper._user.profile.phone_number;
        body.pageName = PageName;
        body.message = this.currentUserQuery;
        this.sendQueryEmail(body);
        this.currentUserQuery = '';
    };
    return EpisodeCareComponent;
}(AppComponentBase));
export { EpisodeCareComponent };
