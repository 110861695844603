<div class="m-quick-sidebar__content">
    <span id="m_Calender_close">
        <img class="m--margin-left-50-desktop m--margin-left-50-tablet-and-mobile" width="16"
            src="../../../../assets/common/images/error.png" [attr.aria-label]="l('Close')" />
    </span>
    <div class="m--space-40"></div>
    <div class="row">
        <div class=" point-cur" (click)="RefreshPageWeek()">
            <div class="card  m--margin-right-90-desktop m--margin-right-60-tablet-and-mobile"
                style="height: 100px;width: 12rem;">
                <div class="card-body bg--lightclr-grey m--align-center"
                    [ngClass]="{'bg-clr-yellow image-hover-wear':syncDayActiveStatus, 'bg--lightclr-grey': !syncDayActiveStatus }">
                    <div>
                        <small class="font-weight-bold m--regular-font-size-lg1">{{"Week"|localize| uppercase}}</small>
                    </div>
                    <img class="image-hover-wear" src="../../../../assets/common/images/Day.svg" height="50"
                        width="50" />
                </div>
            </div>
        </div>
    </div>
    <div class="m--space-20"></div>
    <div class="row">
        <div class=" point-cur" (click)="RefreshPageMonth()">
            <div class="card  m--margin-right-90-desktop m--margin-right-60-tablet-and-mobile"
                style="height: 100px;width: 12rem;">
                <div class="card-body bg--lightclr-grey m--align-center"
                    [ngClass]="{'bg-clr-yellow image-hover-wear':syncWeekActiveStatus, 'bg--lightclr-grey': !syncWeekActiveStatus }">
                    <div class="col">
                        <small
                            class="font-weight-bold m--regular-font-size-lg1">{{"Month" |localize| uppercase}}</small>
                    </div>
                    <img class="image-hover-wear" src="../../../../assets/common/images/Week.svg" height="50"
                        width="50" />
                </div>
            </div>
        </div>
    </div>
    <div class="m--space-20"></div>
    <div class="row">
        <div class=" point-cur" (click)="RefreshPageYear()">
            <div class="card  m--margin-right-90-desktop m--margin-right-60-tablet-and-mobile m--margin-bottom-90-desktop m--margin-bottom-90-tablet-and-mobile"
                style="height: 100px;width: 12rem;">
                <div class="card-body bg--lightclr-grey m--align-center"
                    [ngClass]="{'bg-clr-yellow image-hover-wear':syncMonthActiveStatus, 'bg--lightclr-grey': !syncMonthActiveStatus }">
                    <div class="col">
                        <small class="font-weight-bold m--regular-font-size-lg1">{{"Year" |localize| uppercase}}</small>
                    </div>
                    <img class="image-hover-wear" src="../../../../assets/common/images/Month.svg" height="50"
                        width="50" />
                </div>
            </div>
        </div>
    </div>
</div>