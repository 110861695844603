<div class="container-fluid">

    <div class="m-subheader row">

        <div class="d-flex align-items-center m-3">

            <div class="mr-auto">

                <h3 class="m-subheader__title pb-0 font-weight-light m--icon-font-size-sm1">

                    <a class="brd-clr" href="javascript:;" (click)="breadcrumbNavigation('/app/main/dashboard')">
                        MY DATA
                    </a>>

                    <a class="brd-clr" href="javascript:;" (click)="breadcrumbNavigation('/app/main/MyHealthCare')">
                        MY HEALTHCARE
                    </a>>

                    <a class="brd-clr" href="javascript:;"
                        (click)="breadcrumbNavigation('/app/main/MyHealthCare/RiskAssessment')">
                        HEALTH RISK ASSESSMENT
                    </a>>

                    <a class="font-weight-bold brd-clr">
                        {{questionnaireTitle | uppercase}}
                    </a>
                </h3>
            </div>
        </div>
    </div>

    <form class="m-3">

        <div class="row mt-4" *ngIf="currentQuestionnaire?.intros.length > 0">

            <div class="col-lg-5 col-md-6 col-sm-12">

                <div class="m-portlet pb-4">

                    <div class="m-portlet__head">

                        <div class="m-portlet__head-caption">

                            <div class="m-portlet__head-title">

                                <h3 class="m-portlet__head-text font-weight-bold c-dark-maroon">

                                    {{questionnaireTitle}}
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="m-portlet__body pb-0 pt-0" *ngFor="let intro of currentQuestionnaire?.intros">

                        <p class="font-weight-bold">{{intro.title}}</p>

                        <p *ngIf="intro.description" class="mt-3">
                            {{intro.description}}
                        </p>

                        <b *ngIf="intro.footer">{{intro.footer}}</b>
                    </div>

                    <div class="m-portlet__body pb-0 pt-0">

                        <p class="font-weight-bold mb-2 small">
                            PLEASE NOTE CAREFULLY:
                        </p>

                        <ol class="mb-2">

                            <li class="small">
                                The HRA is <u>not</u> a diagnostic tool. It is designed to provide you with
                                meaningful context and education on the various aspects of your wellbeing.
                                The information shared here <u>cannot ever</u> replace the personalised
                                guidance,
                                diagnosis, treatment and care from appropriate and trained healthcare
                                providers.
                            </li>

                            <li class="small">
                                The information shared in the HRA does <u>not</u> ever replace or waive your
                                responsibility to answer fully, accurately and honestly during the
                                application process. In addition, the answers to the HRA should be accurate
                                as at the time of completing the questions asked. The answers to the HRA
                                <u>should</u> not have any impact on your cover in place so long as the
                                information
                                shared during the application process was comprehensive, accurate and honest
                                per the requirements outlined therein.
                            </li>
                        </ol>
                    </div>                    
                </div>
            </div>
        </div>

        <div *ngFor="let phase of phases" class="mt-4 mb-4">

            <div *ngIf="phase.title" class="mb-2 row">

                <div class="col-lg-5 col-md-6 col-sm-12">

                    <div class="h5 gradient-text">
                        {{phase.title}}
                    </div>

                    <div class="h5 font-weight-bold" style="white-space: pre-line" *ngIf="phase.subtitle">
                        {{phase.subtitle}}
                    </div>
                </div>
            </div>

            <div class="row">

                <div *ngFor="let question of getQuestionsInPhase(phase)" class="mb-4 col-lg-5 col-md-6 col-sm-12">

                    <div class="font-weight-bold mb-1 d-flex flex-row align-items-center">
                        <div>{{question.title}}</div>

                        <a *ngIf="question.tooltip" placement="top" [ngbTooltip]="question.tooltip"
                            class="d-flex flex-row align-items-center ml-1">

                            <i class="fa fa-info-circle blur-clr"> </i>
                        </a>
                    </div>

                    <div class="row">

                        <div class="col-12">

                            <div *ngIf="question.type === questionTypeConsts.SINGLE_SELECT || question.type === questionTypeConsts.MULTI_SELECT"
                                class="d-flex align-items-start font-size-11 level-layout h-100 m-input--pill font-weight-bold form-control text-left pb-0"
                                [ngClass]="question.answers.length > 10 ? 'flex-row flex-wrap' : 'flex-column' ">

                                <div *ngFor="let answer of question.answers;"
                                    class="d-flex flex-row align-items-center justify-content-start mb-2"
                                    [ngClass]="{'w-50': question.answers.length > 10}">

                                    <div [@bouncein]="'idle-bounceIn'"
                                        class="text-right custom-control custom-radio d-flex"
                                        *ngIf="question.type === questionTypeConsts.SINGLE_SELECT">

                                        <input [id]="question.key + '_' + answer.title" [name]="question.key"
                                            [value]="answer" type="radio" class="custom-control-input"
                                            [(ngModel)]="question.answersGiven[0]" (change)="checkTriggers(question)">

                                        <label [for]="question.key + '_' + answer.title"
                                            class="custom-control-label black-clr  d-flex font-weight-bold float-left">
                                            {{answer.title}}
                                        </label>
                                    </div>

                                    <div [@bouncein]="'idle-bounceIn'"
                                        class="text-right custom-control custom-radio d-flex"
                                        *ngIf="question.type === questionTypeConsts.MULTI_SELECT">

                                        <input [id]="question.key + '_' + answer.title"
                                            [name]="question.key + '_' + answer.title" [value]="answer" type="checkbox"
                                            class="custom-control-input"
                                            (change)="onMultipleAnswerChange(answer, question); checkTriggers(question)"
                                            [checked]="isAnswerPresent(answer, question)">

                                        <label [for]="question.key + '_' + answer.title"
                                            class="custom-control-label black-clr  d-flex font-weight-bold float-left">
                                            {{answer.title}}
                                        </label>
                                    </div>
                                </div>

                                <input [@bouncein]="'idle-bounceIn'" type="text" class="form-control level-layout mb-4"
                                    placeholder="Please specify" #otherInput
                                    [value]="getTextInputAnswer(question, { title: 'Other', value: null})"
                                    (input)="setTextInputAnswer(otherInput.value, question, { title: 'Other', value: null}); checkTriggers(question)"
                                    *ngIf="question.type === questionTypeConsts.MULTI_SELECT && isAnswerPresent({ title: 'Other', value: null}, question)">
                            </div>

                            <div class="d-flex align-items-start font-size-11 level-layout h-100 m-input--pill font-weight-bold form-control text-left pl-4 pr-4 pb-4"
                                *ngIf="question.type === questionTypeConsts.SLIDER">

                                <ejs-slider cssClass="slider" [customValues]="getSliderValues(question)"
                                    [value]="getSliderValue(question)"
                                    (changed)="setSliderValue($event, question); checkTriggers(question)"
                                    [ticks]="ticks">
                                </ejs-slider>
                            </div>

                            <div class="d-flex flex-column font-size-11 font-weight-bold h-100"
                                *ngIf="question.type === questionTypeConsts.NUMBER_INPUT">

                                <div *ngFor="let answer of question.answers;"
                                    class="d-flex flex-row align-items-center justify-content-center mb-2 w-100">

                                    <input [@bouncein]="'idle-bounceIn'" type="number" #numberInput
                                        *ngIf="!question.suffix; else suffixNumberInput"
                                        class="level-layout shadow form-control cust-suffix"
                                        [placeholder]="answer.title" [id]="question.key + '_' + answer.title"
                                        [name]=" question.key + '_' + answer.title" required
                                        [value]="getTextInputAnswer(question, answer)"
                                        (input)="setTextInputAnswer(+numberInput.value, question, answer); checkTriggers(question)">

                                    <ng-template #suffixNumberInput>

                                        <input [@bouncein]="'idle-bounceIn'" type="text" #numberInput
                                            class="level-layout shadow form-control cust-suffix" autocomplete="off"
                                            [placeholder]="answer.title" [id]="question.key + '_' + answer.title"
                                            inputFormatter [changeDetectValue]="getTextInputAnswer(question, answer)"
                                            [formatType]="question.suffix" [name]=" question.key + '_' + answer.title"
                                            type="text" required [value]="getTextInputAnswer(question, answer)"
                                            (input)="setTextInputAnswer(+numberInput.value, question, answer); checkTriggers(question)">
                                    </ng-template>
                                </div>
                            </div>

                            <div class="d-flex flex-column font-size-11 font-weight-bold h-100"
                                *ngIf="question.type === questionTypeConsts.DEFAULT">

                                <div *ngFor="let answer of question.answersGiven;"
                                    class="d-flex flex-row align-items-center justify-content-center mb-2 w-100">

                                    <input [@bouncein]="'idle-bounceIn'" type="text" #defaultInput disabled
                                        class="level-layout shadow form-control" [placeholder]="answer.title"
                                        [id]="question.key + '_' + answer.title"
                                        *ngIf="!question.suffix; else suffixDefaultInput"
                                        [name]="question.key + '_' + answer.title" required [value]="answer.value">

                                    <ng-template #suffixDefaultInput>

                                        <input [@bouncein]="'idle-bounceIn'" type="text" #defaultInput disabled
                                            class="level-layout shadow form-control" [placeholder]="answer.title"
                                            [id]="question.key + '_' + answer.title" inputFormatter
                                            [changeDetectValue]="answer.value" [formatType]="question.suffix"
                                            [name]="question.key + '_' + answer.title" required [value]="answer.value">
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="row" *ngIf="currentQuestionnaire">

        <div class="col">

            <div class="floater-pos">

                <div
                    class="bx-shadow answered-questions font-weight-bold text-white mr-2 d-flex flex-column align-items-center cust-bg-red">
                    {{answeredQuestionDescription}}
                </div>

                <button [disabled]="isLoading" (click)="submit(false)"
                    class="topBtn btn bx-shadow m-btn--pill save-button"
                    [ngClass]="{'cust-bg-gradient': !allQuestionsAnswered(), 'cust-bg-red': allQuestionsAnswered()}">
                    <span class="font-weight-bold text-white">
                        <i *ngIf="!isSaving; else loader" class="fa fa-lg fa-save mr-0"></i>

                        <ng-template #loader>

                            <i class="fa fa-lg fa-circle-notch fa-spin mr-0"></i>
                        </ng-template>
                    </span>
                </button>

                <button *ngIf="allQuestionsAnswered()" [disabled]="isLoading" (click)="submit(true)"
                    [buttonBusy]="isSubmitting" [busyText]="l('SubmittingWithThreeDot')"
                    class="topBtn btn bx-shadow m-btn--pill submit-button font-weight-bold text-white cust-bg-gradient">
                    SUBMIT
                </button>
            </div>
        </div>
    </div>
</div>