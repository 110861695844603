/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-product-premium-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/modal";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "@angular/common";
import * as i5 from "./confirm-product-premium-modal.component";
var styles_ConfirmProductPremiumModalComponent = [i0.styles];
var RenderType_ConfirmProductPremiumModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmProductPremiumModalComponent, data: {} });
export { RenderType_ConfirmProductPremiumModalComponent as RenderType_ConfirmProductPremiumModalComponent };
function View_ConfirmProductPremiumModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "d-flex flex-row justify-content-center align-items-center flex-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-danger modal-button-clr border-radius-55 font-size-12 mt-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.revert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "small", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" GO BACK & REVERT INCEPTION DATE "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-danger modal-button-clr border-radius-55 font-size-12 mt-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "small", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" CONTINUE & REMOVE PRODUCT(S) "]))], null, null); }
function View_ConfirmProductPremiumModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-danger modal-button-clr border-radius-55 font-size-12"], ["style", "width: 150px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.revert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "small", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" GO BACK "]))], null, null); }
export function View_ConfirmProductPremiumModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 18, "div", [["bsModal", ""]], null, [[null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, [["confirmProductPremiumModal", 4]], 0, i2.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i3.ComponentLoaderFactory], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "modal-header d-flex flex-row justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "swal2-title mt-0 mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "close p-0 m-0"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeAndRevert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "modal-body mp-0 mt-10 d-flex flex-column justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "swal2-icon swal2-warning swal2-icon-show"], ["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h2", [["class", "swal2-title mt-0 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Entry age exceeded"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "d-flex flex-row justify-content-center align-items-center text-justify text-center mt-4 mb-4 ml-5 mr-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "d-flex flex-row justify-content-center align-items-center mt-4 mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmProductPremiumModalComponent_1)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmProductPremiumModalComponent_2)), i1.ɵdid(18, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = _co.isConfirmModal; _ck(_v, 16, 0, currVal_2); var currVal_3 = !_co.isConfirmModal; _ck(_v, 18, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 13, 0, currVal_1); }); }
export function View_ConfirmProductPremiumModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-product-premium-modal", [], null, null, null, View_ConfirmProductPremiumModalComponent_0, RenderType_ConfirmProductPremiumModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.ConfirmProductPremiumModalComponent, [i2.BsModalRef, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmProductPremiumModalComponentNgFactory = i1.ɵccf("app-confirm-product-premium-modal", i5.ConfirmProductPremiumModalComponent, View_ConfirmProductPremiumModalComponent_Host_0, {}, {}, []);
export { ConfirmProductPremiumModalComponentNgFactory as ConfirmProductPremiumModalComponentNgFactory };
