var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, NgZone, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MyHealthcareService } from '@app/shared/services/my-healthcare.service';
import { HospitalEventOutput, SmsBody } from '@shared/service-proxies/service-proxies';
var HospitalEventsComponent = /** @class */ (function (_super) {
    __extends(HospitalEventsComponent, _super);
    function HospitalEventsComponent(zone, injector, _healtCareService) {
        var _this = _super.call(this, injector) || this;
        _this.zone = zone;
        _this._healtCareService = _healtCareService;
        _this.seemoreActivated = false;
        _this.hospEventData = new HospitalEventOutput();
        _this.requestAccessToHECharts = undefined;
        _this.yearActiveHE = new Array();
        _this.currentUserQuery = '';
        _this.imgSrc = '../../../assets/common/images/chatblack.svg';
        return _this;
    }
    HospitalEventsComponent.prototype.ngOnInit = function () {
    };
    HospitalEventsComponent.prototype.loadHospitalEvent = function () {
        var _this = this;
        this.isLoading = true;
        this.hospEventSelectedYear = this._healtCareService.yearList[0];
        var i = 0;
        this._healtCareService.yearList.forEach(function (x) {
            if (i === 0) {
                _this.yearActiveHE.push(true);
            }
            else {
                _this.yearActiveHE.push(false);
            }
            i++;
        });
        this.getHospitalEvents();
    };
    HospitalEventsComponent.prototype.FilterWhithYearHospEvent = function (item, i) {
        var d = this.yearActiveHE.findIndex(function (x) { return x === true; });
        this.yearActiveHE[d] = false;
        this.yearActiveHE[i] = true;
        this.hospEventSelectedYear = item;
        this.requestAccessToHECharts = undefined;
        this.getHospitalEvents();
    };
    HospitalEventsComponent.prototype.getHospitalEvents = function () {
        var _this = this;
        this.isLoading = true;
        this._healtCareService.getHospitalEventData(this.hospEventSelectedYear)
            .then(function (res) {
            _this.loadHospitalEventsChart(_this.hospEventSelectedYear);
        })
            .catch(function () {
        })
            .finally(function () {
            _this.isLoading = false;
        });
    };
    HospitalEventsComponent.prototype.loadHospitalEventsChart = function (year) {
        var _this = this;
        this.requestAccessToHECharts = this._healtCareService.hospitalEvents.length > 0 ? true : false;
        if (this.requestAccessToHECharts) {
            this.zone.runOutsideAngular(function () {
                Promise.all([
                    import('@amcharts/amcharts4/core'),
                    import('@amcharts/amcharts4/charts'),
                    import('@amcharts/amcharts4/themes/animated')
                ]).then(function (modules) {
                    var am4core = modules[0];
                    var am4charts = modules[1];
                    var am4themes_animated = modules[2].default;
                    am4core.options.commercialLicense = true;
                    am4core.useTheme(am4themes_animated);
                    // Themes begin
                    am4core.useTheme(am4themes_animated);
                    // Themes end
                    am4core.options.autoSetClassName = true;
                    // Create chart instance
                    var chartHE = am4core.create('HospitalEvent', am4charts.XYChart);
                    chartHE.responsive.enabled = true;
                    // Add data
                    console.log(year);
                    _this._healtCareService.hospitalEvents.forEach(function (x) {
                        chartHE.data.push({
                            PaidAmount: x.totalAdmissionPaidAmount,
                            drg: x.baseDRG,
                            Headerhe: x.baseDRG,
                            amountclaimed: x.totalAdmissionClaimedAmount,
                            amountpaid: x.totalAdmissionPaidAmount,
                            shortfallamount: x.shortfall_Amount,
                            admdate: x.admissionDate,
                            disdate: x.dischargeDate,
                            hospital: x.admittingHospitalName,
                            doc: x.admittingDoctorName,
                            diagnosis: x.admissionPrimaryICD10CodeDescription
                        });
                    });
                    chartHE.numberFormatter.numberFormat = '#.##a';
                    chartHE.numberFormatter.bigNumberPrefixes = [
                        { 'number': 1e+3, 'suffix': 'K' },
                        { 'number': 1e+6, 'suffix': 'M' },
                        { 'number': 1e+9, 'suffix': 'B' }
                    ];
                    // Create axes
                    if (_this._healtCareService.hospitalEvents.length > 0) {
                        var categoryAxis1 = chartHE.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis1.dataFields.category = 'Headerhe';
                        categoryAxis1.renderer.grid.template.disabled = true;
                        categoryAxis1.renderer.labels.template.html = 'See More';
                        categoryAxis1.renderer.labels.template.fontSize = 12;
                        categoryAxis1.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                        categoryAxis1.renderer.minGridDistance = 30;
                        categoryAxis1.renderer.labels.template.events.on('over', function (event) {
                            event.target.stroke = am4core.color('#B01917');
                            document.getElementsByClassName('');
                        }, self);
                        categoryAxis1.renderer.labels.template.events.on('hit', function (event) {
                            this.hospEventData.baseDRG = event.target.dataItem.dataContext['Headerhe'];
                            this.hospEventData.totalAdmissionClaimedAmount = event.target.dataItem.dataContext['amountclaimed'];
                            this.hospEventData.totalAdmissionPaidAmount = event.target.dataItem.dataContext['amountpaid'];
                            this.hospEventData.shortfall_Amount = event.target.dataItem.dataContext['shortfallamount'];
                            this.hospEventData.admissionDate = event.target.dataItem.dataContext['admdate'];
                            this.hospEventData.dischargeDate = event.target.dataItem.dataContext['disdate'];
                            this.hospEventData.admittingHospitalName = event.target.dataItem.dataContext['hospital'];
                            this.hospEventData.admittingDoctorName = event.target.dataItem.dataContext['doc'];
                            this.hospEventData.admissionPrimaryICD10CodeDescription = event.target.dataItem.dataContext['diagnosis'];
                            this.seemoreActivated = true;
                        }, _this);
                    }
                    var categoryAxis = chartHE.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = 'Headerhe';
                    categoryAxis.renderer.grid.template.disabled = true;
                    categoryAxis.renderer.minGridDistance = 30;
                    categoryAxis.renderer.line.strokeOpacity = 1;
                    categoryAxis.renderer.labels.template.width = 105;
                    categoryAxis.renderer.labels.template.paddingLeft = 26;
                    categoryAxis.renderer.labels.template.wrap = true;
                    categoryAxis.renderer.line.stroke = am4core.color('#B01917');
                    var valueAxis = chartHE.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.renderer.line.strokeOpacity = 1;
                    valueAxis.min = 0;
                    valueAxis.renderer.line.stroke = am4core.color('#B01917');
                    valueAxis.renderer.grid.template.disabled = true;
                    valueAxis.title.text = 'Total Amount Paid';
                    valueAxis.title.dx = -12;
                    // Create series
                    var series = chartHE.series.push(new am4charts.ColumnSeries());
                    series.dataFields.valueY = 'PaidAmount';
                    series.dataFields.categoryX = 'Headerhe';
                    series.name = 'PaidAmount';
                    series.sequencedInterpolation = true;
                    series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    var label = bullet.createChild(am4core.Label);
                    label.horizontalCenter = 'middle';
                    label.verticalCenter = 'top';
                    label.y = am4core.percent(100);
                    label.propertyFields.text = 'Date';
                    label.stroke = am4core.color('#B01917');
                    label.dy = -20;
                    var columnTemplate = series.columns.template;
                    columnTemplate.width = 40;
                    columnTemplate.fill = am4core.color('#B01917');
                    columnTemplate.stroke = am4core.color('#B01917');
                })
                    .catch(function (e) {
                    console.error('Error when creating chart', e);
                });
            });
        }
    };
    HospitalEventsComponent.prototype.sendsms = function (PageName) {
        var body = new SmsBody();
        body.userid = this._healtCareService._commonHelper._user.profile.sub;
        body.firstName = this._healtCareService._commonHelper._user.profile.given_name;
        body.lastName = this._healtCareService._commonHelper._user.profile.family_name;
        body.email = this._healtCareService._commonHelper._user.profile.email;
        body.phoneNumber = Array.isArray(this._healtCareService._commonHelper._user.profile.phone_number) ? this._healtCareService._commonHelper._user.profile.phone_number[0] : this._healtCareService._commonHelper._user.profile.phone_number;
        body.pageName = PageName;
        body.message = this.currentUserQuery;
        this.sendQueryEmail(body);
        this.currentUserQuery = '';
    };
    return HospitalEventsComponent;
}(AppComponentBase));
export { HospitalEventsComponent };
