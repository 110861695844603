var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PolicyService } from '@app/shared/services/policy.service';
import { map } from 'rxjs/operators';
var MyDocumentsService = /** @class */ (function (_super) {
    __extends(MyDocumentsService, _super);
    function MyDocumentsService(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.vm = {
            currentPolicyId: '',
            entityId: ''
        };
        if (_this._authGuard.user) {
            _this.vm.entityId = _this._authGuard.user.profile.sub;
        }
        return _this;
    }
    Object.defineProperty(MyDocumentsService.prototype, "policyService", {
        get: function () {
            if (!this._policyService) {
                this._policyService = this.injector.get(PolicyService);
            }
            return this._policyService;
        },
        enumerable: true,
        configurable: true
    });
    MyDocumentsService.prototype.getPolicyList = function (policyList) {
        return this.policyService.getPolicySets(policyList);
    };
    MyDocumentsService.prototype.getAPerson = function (personId, isCaching) {
        return this.policyService.getPerson(personId, isCaching)
            .pipe(map(function (res) {
            return res;
        }));
    };
    MyDocumentsService.prototype.getPolicyById = function (policyId) {
        return this.policyService.getPolicy(policyId)
            .pipe(map(function (res) {
            return res;
        }));
    };
    return MyDocumentsService;
}(AppComponentBase));
export { MyDocumentsService };
