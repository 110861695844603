var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, AfterViewInit, EventEmitter, ElementRef, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
var MyWearablesCalenderComponent = /** @class */ (function (_super) {
    __extends(MyWearablesCalenderComponent, _super);
    function MyWearablesCalenderComponent(el, injector) {
        var _this = _super.call(this, injector) || this;
        _this.el = el;
        _this.id = 'm_Calender_toggle';
        _this.classes = 'm-quick-sidebar m-quick-sidebar--tabbed m-quick-sidebar--skin-light';
        _this.styleOverflow = 'hidden';
        _this.syncDayActiveStatus = true;
        _this.syncMonthActiveStatus = false;
        _this.syncWeekActiveStatus = false;
        _this.week = new EventEmitter();
        _this.month = new EventEmitter();
        _this.year = new EventEmitter();
        return _this;
    }
    MyWearablesCalenderComponent.prototype.RefreshPageWeek = function () {
        this.syncDayActiveStatus = !this.syncDayActiveStatus;
        if (this.syncDayActiveStatus) {
            this.syncWeekActiveStatus = false;
            this.syncMonthActiveStatus = false;
            this.week.emit(true);
        }
    };
    MyWearablesCalenderComponent.prototype.RefreshPageMonth = function () {
        this.syncWeekActiveStatus = !this.syncWeekActiveStatus;
        if (this.syncWeekActiveStatus) {
            this.syncDayActiveStatus = false;
            this.syncMonthActiveStatus = false;
            this.month.emit(true);
        }
    };
    MyWearablesCalenderComponent.prototype.RefreshPageYear = function () {
        this.syncMonthActiveStatus = !this.syncMonthActiveStatus;
        if (this.syncMonthActiveStatus) {
            this.syncWeekActiveStatus = false;
            this.syncDayActiveStatus = false;
            this.year.emit(true);
        }
    };
    MyWearablesCalenderComponent.prototype.ngOnInit = function () {
    };
    MyWearablesCalenderComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.mQuickSidebarOffcanvas = new mOffcanvas(this.el.nativeElement, {
            overlay: true,
            baseClass: 'm-quick-sidebar',
            closeBy: 'm_Calender_close',
            toggleBy: 'm_Calender_toggle'
        });
        this.mQuickSidebarOffcanvas.events.push({
            name: 'afterHide',
            handler: function () {
                if (_this._pinned) {
                    _this.mQuickSidebarOffcanvas.show();
                }
                else {
                    _this.isOpen = false;
                }
            }
        }, {
            name: 'afterShow',
            handler: function () {
                _this.isOpen = true;
            }
        });
    };
    return MyWearablesCalenderComponent;
}(AppComponentBase));
export { MyWearablesCalenderComponent };
