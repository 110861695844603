import { UserManager } from 'oidc-client';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { XmlHttpRequestHelper } from '@shared/helpers/XmlHttpRequestHelper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var AuthService = /** @class */ (function () {
    function AuthService(http, router) {
        var _this = this;
        this.http = http;
        this.router = router;
        this.manager = new UserManager(getClientSettings());
        this.user = null;
        this.urltor = '';
        this.pathName = '/app/main/dashboard';
        this.manager.events.addUserLoaded(function (user) {
            if (!_this.router.url.includes('ExtQuote') && !_this.router.url.includes('ExtCalculator') && !_this.router.url.includes('fna')) {
                _this.setUser(user);
            }
        });
    }
    AuthService.prototype.setUser = function (user) {
        this.user = user;
        if (!this.userPermission && this.user && !this.user.expired) {
            this.getUserRoleViaAjax(function () { });
        }
    };
    AuthService.prototype.getUserStatus = function (callback) {
        var _this = this;
        this.manager.getUser().then(function (user) {
            _this.setUser(user);
            // callback();
            if (!_this.user) {
                _this.startAuthentication();
            }
            if (_this.user.expired) {
                _this.startAuthentication();
            }
        });
    };
    AuthService.prototype.getDevRequestHeaders = function () {
        return {
            //'Authorization': 'Bearer ' + this.user.access_token,
            'Accept': 'application/json'
        };
    };
    AuthService.prototype.getProdRequestHeaders = function () {
        return {
            'Authorization': 'Bearer ' + this.user.access_token,
            'Accept': 'application/json'
        };
    };
    AuthService.prototype.getDevUrl = function () {
        var url_ = AppConsts.remoteServiceBaseUrl + '/api/services/app/ClaimModify/GetUserRoleAsync?';
        if (this.user && this.user.access_token !== undefined) {
            url_ += 'accessToken=' + encodeURIComponent('' + this.user.access_token) + '&';
        }
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    };
    AuthService.prototype.getProdUrl = function () {
        var url_ = abp.setting.values['PortalSettings.authority'] + 'api/UserClaim/GetCurrentUserRole';
        return url_;
    };
    AuthService.prototype.getUserRoleViaAjax = function (callback) {
        var _this = this;
        console.log('getUserRoleViaAjax');
        var requestHeaders = {};
        var url_ = '';
        requestHeaders = this.getProdRequestHeaders();
        url_ = this.getProdUrl();
        XmlHttpRequestHelper.ajax('GET', url_, requestHeaders, null, function (response) {
            _this.userPermission = response;
            var route = localStorage.getItem('nextLink') !== '/' && localStorage.getItem('nextLink') !== '/auth-callback' && localStorage.getItem('nextLink') !== 'check-auth' ? localStorage.getItem('nextLink') : '/app/main/dashboard';
            _this.router.navigate([route]);
            callback();
        });
    };
    AuthService.prototype.getUser = function (callback, forceUpdate) {
        var _this = this;
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (this.user != null && !this.user.expired && (forceUpdate || !this.user.profile)) {
            this.http.get(abp.setting.values['PortalSettings.authority'] + "connect/userinfo", {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.user.access_token
                })
            }).subscribe(function (res) {
                _this.user.profile = res;
                callback(res);
            }, function (err) {
                abp.ui.clearBusy();
            });
        }
        else if (this.user && this.user.profile) {
            callback(this.user.profile);
        }
    };
    AuthService.prototype.isLoggedIn = function () {
        return this.user != null && !this.user.expired;
    };
    AuthService.prototype.destroySession = function () {
        this.http.get(abp.setting.values['PortalSettings.authority'] + "connect/endsession?id_token_hint=" + this.user.id_token);
    };
    AuthService.prototype.getClaims = function () {
        return this.user;
    };
    AuthService.prototype.getAuthorizationHeaderValue = function () {
        return this.user.token_type + " " + this.user.access_token;
    };
    AuthService.prototype.startAuthentication = function (pathname) {
        return this.manager.signinRedirect({ state: pathname });
    };
    AuthService.prototype.startLogout = function () {
        this.destroySession();
        sessionStorage.removeItem('set_policy_user_status');
        this.manager.signoutRedirect();
    };
    AuthService.prototype.completeAuthentication = function () {
        var _this = this;
        console.log('completeAuthentication');
        return this.manager.signinRedirectCallback().then(function (user) {
            console.log('signinRedirectCallback');
            _this.user = user;
            if (user.state && (user.state !== '/auth-callback' && user.state !== '/')) {
                _this.pathName = user.state;
            }
            _this.getUserRoleViaAjax(function () { });
        }).catch(function (err) {
            console.log('complete auth failure');
            console.log(err);
            var route = localStorage.getItem('nextLink') !== '/' && localStorage.getItem('nextLink') !== '/auth-callback' && localStorage.getItem('nextLink') !== 'check-auth' ? localStorage.getItem('nextLink') : '/app/main/dashboard';
            _this.startAuthentication(route);
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
export function getClientSettings() {
    return {
        authority: abp.setting.values['PortalSettings.authority'],
        client_id: abp.setting.values['PortalSettings.ClientId'],
        redirect_uri: abp.setting.values['PortalSettings.RedirectUri'],
        post_logout_redirect_uri: abp.setting.values['PortalSettings.PostLogoutRedirectUri'],
        response_type: 'code',
        filterProtocolClaims: true,
        loadUserInfo: true,
        automaticSilentRenew: true,
        scope: abp.setting.values['PortalSettings.scope'],
        silent_redirect_uri: abp.setting.values['ClientRootAddress'].lastIndexOf('/') === abp.setting.values['ClientRootAddress'].length - 1 ? abp.setting.values['ClientRootAddress'] + 'renew-callback.html' : abp.setting.values['ClientRootAddress'] + '/renew-callback.html',
        revokeAccessTokenOnSignout: true,
        monitorSession: true,
    };
}
