var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { OnInit, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HealthCareApiServiceProxy } from '@shared/service-proxies/service-proxies';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import { forkJoin } from 'rxjs';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import 'shared/extensions/my-policies-extensions';
import { HraIntroModalComponent } from '../hra-intro-modal/hra-intro-modal.component';
import { AppConsts } from '@shared/AppConsts';
import * as moment from 'moment';
var HealthRiskAssessmentOVerviewComponent = /** @class */ (function (_super) {
    __extends(HealthRiskAssessmentOVerviewComponent, _super);
    function HealthRiskAssessmentOVerviewComponent(injector, healthCareService, authGuard) {
        var _this = _super.call(this, injector) || this;
        _this.healthCareService = healthCareService;
        _this.authGuard = authGuard;
        _this.categories = [
            {
                key: 'Intro',
                title: 'Intro',
                description: 'A quick introduction',
                chart: 'myIntroChart',
                average: 0,
                percentage: 0,
                isShown: false,
                claimType: 'hra_intro',
                status: null,
                report: null,
                questionnaire: null
            },
            {
                key: 'MyNutrition',
                title: 'My Nutrition',
                description: 'Understand more about your nutrition.',
                chart: 'myNutritionChart',
                average: 0,
                percentage: 0,
                isShown: true,
                claimType: 'hra_nutrition',
                status: null,
                report: {
                    descriptions: [
                        {
                            title: 'Why is nutrition important?',
                            content: [
                                'Most people know that good nutrition can help maintain a healthy weight. However, the benefits of good nutrition go beyond weight. Good nutrition can help reduce the risk of heart disease, diabetes, stroke, osteoporosis, and some types of cancer. A healthy diet can also lower high blood pressure and high cholesterol levels, improving your mental well-being, ability to fight infection, and recovery from illness or injury. Good nutrition can increase your energy levels.'
                            ],
                            list: []
                        }
                    ],
                    negativeOutcomes: [],
                    positiveOutcomes: []
                },
                questionnaire: null
            },
            {
                key: 'MySleep',
                title: 'My Sleep',
                description: 'Understand more about your sleep.',
                chart: 'mySleepChart',
                average: 0,
                percentage: 0,
                isShown: true,
                claimType: 'hra_sleep',
                status: null,
                report: {
                    descriptions: [
                        {
                            title: 'Why is sleep important?',
                            content: [
                                'Sleep is essential to every process in the body, affecting our physical and mental functioning the next day, our ability to fight disease and develop immunity, and our metabolism and chronic disease risk. Sleep truly touches every aspect of health.',
                            ],
                            list: []
                        },
                        {
                            title: 'What is good sleep?',
                            content: [
                                'A good night\'s sleep is when you fall asleep quite easily, do not fully wake up during the night, do not wake up too early, and feel refreshed in the morning. Regularly having difficulty falling asleep or sleeping through the night is not normal for healthy people of any age. But not everyone needs the same amount of sleep, and quality of sleep is different in different phases of life.',
                                'Young children and older people sleep more lightly than adults and teenagers. The length of time spent in deep sleep phases changes over a person\'s lifetime. Babies and toddlers need to sleep a lot more than older children and adults. By the time they reach the age of five, most children have the typical sleep patterns of an adult: they are awake during the day and then sleep through the night. The amount of time spent sleeping gradually decreases until the age of 80.'
                            ],
                            list: []
                        },
                        {
                            title: 'Generally, good sleep is defined by the following:',
                            content: [],
                            list: [
                                'You fall asleep soon after getting into bed, within 30 minutes or less.',
                                'You typically sleep straight through the night, waking up no more than once per night.',
                                'You typically sleep straight through the night, waking up no more than once per night.',
                                'You\'re able to sleep the recommended amount of hours for your age group.',
                                'You fall back asleep within 20 minutes if you do wake up.',
                                'You feel rested, restored, and energised upon waking up in the morning.'
                            ]
                        }
                    ],
                    negativeOutcomes: [],
                    positiveOutcomes: []
                },
                questionnaire: null
            },
            {
                key: 'MyHealthyBody',
                title: 'My Healthy Body',
                description: 'Understand more about your body.',
                chart: 'myHealthyBodyChart',
                average: 0,
                percentage: 0,
                isShown: true,
                claimType: 'hra_body',
                status: null,
                report: {
                    descriptions: [
                        {
                            title: null,
                            content: [
                                'Your health metrics such as your weight, blood pressure, cholesterol and blood glucose levels can give you a meaningful indication on the state of your physical wellbeing. With the help and guidance of a health practitioner, recording and monitoring these clinical metrics over time is essential to ensure that necessary interventions can be put in place if any indicate a deterioration in your clinical health.',
                                'These metrics are also a strong indication of how successfully you are managing and maintaining the important lifestyle habits focused on in the remainder of this Health Risk Assessment. They can highlight for you the importance of greater focus to be placed on achieving heathier lifestyle habits pertaining to your exercise, sleep, nutrition, and stress.',
                                'However, your lifestyle habits are often not sufficient to fully treat a chronic or acute medical condition you are suffering with. It is essential to seek the appropriate medical attention and to following recommended treatment protocols to properly manage illness and disease. The sooner a health condition is diagnosed and the more disciplined you are at maintaining the right ongoing treatment, the higher the likelihood of being cured or otherwise successfully limiting the symptoms and the severity of its impact on your life.',
                                'There is also many things you can do to ensure that you prevent or delay illness and disease from occurring. These include healthcare screenings, vaccinations, supplements, and the positive lifestyle habits noted above.'
                            ],
                            list: []
                        }
                    ],
                    negativeOutcomes: [],
                    positiveOutcomes: []
                },
                questionnaire: null
            },
            {
                key: 'MyHealthyMind',
                title: 'My Healthy Mind',
                description: 'Understand more about your mind.',
                chart: 'myHealthyMindChart',
                average: 0,
                percentage: 0,
                isShown: true,
                claimType: 'hra_mind',
                status: null,
                report: {
                    descriptions: [
                        {
                            title: 'Why is it so important to look after one\'s mental health?',
                            content: [
                                'Mental health is crucial for overall wellbeing. It impacts all of our ability to cope with daily stressors, make healthy decisions, and form meaningful relationships.'
                            ],
                            list: []
                        },
                        {
                            title: 'What are some of the ways to achieve good ongoing mental health?',
                            content: ['It is important to remember that mental health can change over time, and that good mental health is not a destination but a journey. It is our recommendation to always keep in mind that seeking help and support when needed is a sign of strength, and not something to be ashamed of.'],
                            list: [
                                'Building and maintaining positive relationships. Fundamental to this is a strong support system and connecting meaningfully with others.',
                                'Practicing self-care. This involves getting into good ongoing habits that promote physical and emotional wellbeing, such as exercise, healthy diet, getting enough sleep, and taking time for hobbies and interests.',
                                'Managing stress and anxiety. Identifying and managing the sources of stress in one\'s life can help reduce feelings of anxiety and depression. This is often difficult to achieve by yourself. Seeking effective guidance from a health practitioner and/or your community may be fundamental when working towards managing your stress.',
                                'Staying active and productive. Engaging in work, hobbies or volunteer activities can help improve self-esteem.',
                                'Mindfulness and meditation. Practicing mindfulness, such as meditation, yoga, or deep breathing exercises, has been clinically proven to help reduce stress and anxiety.'
                            ],
                        }
                    ],
                    negativeOutcomes: [],
                    positiveOutcomes: []
                },
                questionnaire: null
            },
            {
                key: 'MyExercise',
                title: 'My Exercise',
                description: 'Understand more about your exercise.',
                chart: 'myExerciseChart',
                average: 0,
                percentage: 0,
                isShown: true,
                claimType: 'hra_exercise',
                status: null,
                report: {
                    descriptions: [
                        {
                            title: 'Why is exercise important?',
                            content: [
                                'Exercise helps control weight, combats health conditions and diseases, improves mood, boosts energy, promotes better sleep, and can even put the spark back into your sex life. Exercise can be fun, and social.'
                            ],
                            list: []
                        },
                        {
                            title: 'What exercise do you need?',
                            content: [
                                'Experts recommend at least 150 minutes of moderate aerobic activity or 75 minutes of vigorous aerobic activity a week, or a combination of moderate and vigorous activity. You should spread this exercise out over the course of a week. For even greater health benefit and to assist with weight loss, at least 300 minutes a week is recommended but even small amounts of physical activity are helpful. Being active for short periods of time throughout the day add up to provide health benefit.',
                                'Moderate aerobic exercise includes activities such as brisk walking, cycling, swimming and mowing the lawn. Vigorous aerobic exercise includes activities such as running, intensive work in the garden and aerobic dancing. Strength training can include use of weight machines, your own body weight, heavy bags, resistance tubing or resistance paddles in the water, or activities such as rock climbing.',
                                'Strength training is also recommended. Do strength training exercises for all major muscle groups at least two times a week. Aim to do a single set of each exercise using a weight or resistance level heavy enough to tire your muscles after about 12 to 15 repetitions.',
                                'Remember to check with your doctor before starting a new exercise program, especially if you have any concerns about your fitness, haven\'t exercised for a long time, or have chronic health problems, such as heart disease, diabetes or arthritis.'
                            ],
                            list: []
                        }
                    ],
                    negativeOutcomes: [],
                    positiveOutcomes: []
                },
                questionnaire: null
            }
        ];
        _this.currentDate = new Date();
        _this.totals = {
            achieved: 0,
            average: 0
        };
        return _this;
    }
    HealthRiskAssessmentOVerviewComponent.prototype.ngOnInit = function () {
        this.setUpQuestionnaires();
    };
    HealthRiskAssessmentOVerviewComponent.prototype.setUpQuestionnaires = function () {
        var _this = this;
        abp.ui.setBusy();
        this.updateClaimsIdsvr().then(function () {
            var e_1, _a;
            var healthRiskAssessmentOverviewObservables = _this.categories.map(function (c) { return _this.healthCareService.getHealthRiskAssessmentOverview(_this.authGuard.user.profile.sub + "_" + c.key); });
            try {
                for (var _b = __values(_this.categories), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var category = _c.value;
                    var statusClaimType = category.claimType + '_status';
                    var dateClaimType = category.claimType + '_date';
                    var statusClaimValue = _this._authService.user.profile[statusClaimType];
                    var dateClaimValue = _this._authService.user.profile[dateClaimType];
                    if (!statusClaimValue || !dateClaimValue) {
                        category.status = null;
                    }
                    else if (statusClaimValue === AppConsts.HraQuestionnaireStatuses.SAVED) {
                        category.status = AppConsts.HraQuestionnaireStatuses.SAVED;
                    }
                    else if (statusClaimValue === AppConsts.HraQuestionnaireStatuses.COMPLETED) {
                        if (moment(dateClaimValue, 'DD/MM/YYYY hh:mm:ss A').diff(moment(), 'months') >= 6) {
                            category.status = null;
                        }
                        else {
                            category.status = AppConsts.HraQuestionnaireStatuses.COMPLETED;
                        }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            var introAnswered = _this.categories.find(function (c) { return c.key === AppConsts.HraKeys.INTRO; }).status === AppConsts.HraQuestionnaireStatuses.COMPLETED;
            forkJoin(healthRiskAssessmentOverviewObservables).subscribe(function (result) {
                var e_2, _a;
                var _loop_1 = function (overview) {
                    if (!overview) {
                        return "continue";
                    }
                    var category = _this.categories.find(function (c) { return c.key === overview.questionnaireKey; });
                    if (overview.maxScore !== 0 && category.status === AppConsts.HraQuestionnaireStatuses.COMPLETED) {
                        category.percentage = overview.achievedScore / overview.maxScore * 100;
                    }
                };
                try {
                    for (var result_1 = __values(result), result_1_1 = result_1.next(); !result_1_1.done; result_1_1 = result_1.next()) {
                        var overview = result_1_1.value;
                        _loop_1(overview);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (result_1_1 && !result_1_1.done && (_a = result_1.return)) _a.call(result_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                var shownCategories = _this.categories.filter(function (c) { return c.isShown; });
                var accessToken = _this._authService.user.access_token;
                var age = moment(moment()).diff(moment(_this._authService.user.profile.birthdate, 'DD/MM/YYYY hh:mm:ss A'), 'years');
                var gender = _this._authService.user.profile.gender;
                var peopleLikeYouScores = _this.categories
                    .filter(function (c) { return c.key !== AppConsts.HraKeys.INTRO; })
                    .map(function (c) { return _this.healthCareService.getPeopleLikeYouScore(accessToken, c.key, age, gender); });
                forkJoin(peopleLikeYouScores).subscribe(function (scoreResult) {
                    var e_3, _a, e_4, _b;
                    var _loop_2 = function (score) {
                        var category = _this.categories.find(function (c) { return c.key === score.hraCategory; });
                        if (!category || !category.percentage) {
                            return "continue";
                        }
                        category.average = score.score;
                    };
                    try {
                        for (var scoreResult_1 = __values(scoreResult), scoreResult_1_1 = scoreResult_1.next(); !scoreResult_1_1.done; scoreResult_1_1 = scoreResult_1.next()) {
                            var score = scoreResult_1_1.value;
                            _loop_2(score);
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (scoreResult_1_1 && !scoreResult_1_1.done && (_a = scoreResult_1.return)) _a.call(scoreResult_1);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                    try {
                        for (var shownCategories_1 = __values(shownCategories), shownCategories_1_1 = shownCategories_1.next(); !shownCategories_1_1.done; shownCategories_1_1 = shownCategories_1.next()) {
                            var category = shownCategories_1_1.value;
                            var dateClaim = category.claimType + '_date';
                            var subtitle = null;
                            if (_this._authService.user.profile[dateClaim] && (category.status === AppConsts.HraQuestionnaireStatuses.COMPLETED || category.status === AppConsts.HraQuestionnaireStatuses.SAVED)) {
                                if (category.status === AppConsts.HraQuestionnaireStatuses.COMPLETED) {
                                    subtitle = 'Last completed ';
                                }
                                else if (category.status === AppConsts.HraQuestionnaireStatuses.SAVED) {
                                    subtitle = 'Last saved ';
                                }
                                var stringDate = _this._authService.user.profile[dateClaim];
                                var date = moment(stringDate, 'DD/MM/YYYY h:mm:ss a');
                                subtitle += date.format('DD/MM/YYYY');
                            }
                            _this.drawGuageChart({ chartDiv: category.chart, titleText: category.title, subtitleText: subtitle, average: category.average, achieved: category.percentage });
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (shownCategories_1_1 && !shownCategories_1_1.done && (_b = shownCategories_1.return)) _b.call(shownCategories_1);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                    var categoriesWithPercentage = shownCategories.filter(function (c) { return c.isShown && c.percentage > 0; });
                    var categoriesWithAverage = shownCategories.filter(function (c) { return c.isShown && c.average > 0; });
                    if (categoriesWithPercentage.length > 0) {
                        _this.totals.achieved = Math.round(categoriesWithPercentage.map(function (c) { return c.percentage; }).reduce(function (acc, cur) { return acc + cur; }, 0) / categoriesWithPercentage.length);
                    }
                    if (categoriesWithAverage.length > 0) {
                        _this.totals.average = Math.round(categoriesWithAverage.map(function (c) { return c.average; }).reduce(function (acc, cur) { return acc + cur; }, 0) / categoriesWithAverage.length);
                    }
                    if (!introAnswered) {
                        _this.bsModalRef = _this.modalService.show(HraIntroModalComponent, {
                            backdrop: true,
                            ignoreBackdropClick: true,
                            animated: true,
                            class: 'w-350px mt-48'
                        });
                        _this.bsModalRef.content.onClose.subscribe(function (result) {
                            _this.setUpQuestionnaires();
                        });
                    }
                    abp.ui.clearBusy();
                });
            });
        });
    };
    HealthRiskAssessmentOVerviewComponent.prototype.accordianOpen = function (index, category) {
        return __awaiter(this, void 0, void 0, function () {
            var e_5, _a, e_6, _b, hra, _c, _d, phase, _e, _f, question, answerValue;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        if (index === this.openAccordianIndex) {
                            this.openAccordianIndex = null;
                        }
                        else {
                            this.openAccordianIndex = index;
                        }
                        if (!(!category.questionnaire && category.report)) return [3 /*break*/, 2];
                        this.isLoadingReport = true;
                        return [4 /*yield*/, this.healthCareService.GetLatestHealthRiskAssessment(this.authGuard.user.profile.sub + "_" + category.key).toPromise()];
                    case 1:
                        hra = _g.sent();
                        category.questionnaire = hra.data.healthRiskAssessment;
                        try {
                            for (_c = __values(category.questionnaire.phases), _d = _c.next(); !_d.done; _d = _c.next()) {
                                phase = _d.value;
                                try {
                                    for (_e = __values(phase.questions), _f = _e.next(); !_f.done; _f = _e.next()) {
                                        question = _f.value;
                                        if (!question.report || question.answersGiven.length === 0) {
                                            continue;
                                        }
                                        answerValue = question.answersGiven[0].value;
                                        if (question.report.positive.scores.includes(answerValue)) {
                                            category.report.positiveOutcomes.push(question.report.positive.message);
                                        }
                                        else if (question.report.negative.scores.includes(answerValue)) {
                                            category.report.negativeOutcomes.push(question.report.negative.message);
                                        }
                                    }
                                }
                                catch (e_6_1) { e_6 = { error: e_6_1 }; }
                                finally {
                                    try {
                                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                                    }
                                    finally { if (e_6) throw e_6.error; }
                                }
                            }
                        }
                        catch (e_5_1) { e_5 = { error: e_5_1 }; }
                        finally {
                            try {
                                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                            }
                            finally { if (e_5) throw e_5.error; }
                        }
                        this.isLoadingReport = false;
                        _g.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    HealthRiskAssessmentOVerviewComponent.prototype.getSelectedAccordian = function (index) {
        return this.openAccordianIndex === index;
    };
    HealthRiskAssessmentOVerviewComponent.prototype.drawGuageChart = function (params) {
        am4core.useTheme(am4themes_animated);
        var chart = am4core.create(params.chartDiv, am4charts.GaugeChart);
        chart.hiddenState.properties.opacity = 0;
        if (params.subtitleText) {
            var subtitle = chart.titles.create();
            subtitle.text = params.subtitleText;
            subtitle.align = 'left';
            subtitle.fontSize = '0.8rem';
            subtitle.fontWeight = '400';
            subtitle.fill = am4core.color('#575962');
            subtitle.fontFamily = 'Montserrat';
        }
        var chartTitle = chart.titles.create();
        chartTitle.text = params.titleText.toUpperCase();
        chartTitle.align = 'left';
        chartTitle.fontSize = '1.3rem';
        chartTitle.fontWeight = '700';
        chartTitle.fill = am4core.color('#c71412');
        chartTitle.fontFamily = 'Montserrat';
        var labelList = new am4core.ListTemplate(new am4core.Label());
        labelList.template.isMeasured = false;
        labelList.template.background.strokeWidth = 2;
        labelList.template.fontSize = 14;
        labelList.template.fontWeight = '700';
        labelList.template.minWidth = 50;
        labelList.template.padding(10, 10, 10, 10);
        labelList.template.y = am4core.percent(50);
        labelList.template.textAlign = 'middle';
        labelList.template.horizontalCenter = 'middle';
        var label = labelList.create();
        label.parent = chart.chartContainer;
        label.x = am4core.percent(40);
        label.fill = new am4core.Color({
            r: 41,
            g: 74,
            b: 117
        });
        var rect = new am4core.RoundedRectangle();
        rect.stroke = new am4core.Color({
            r: 41,
            g: 74,
            b: 117
        });
        rect.strokeWidth = 2;
        label.background = rect;
        label.text = params.average ? params.average.toFixed(0) : '?';
        var label2 = labelList.create();
        label2.parent = chart.chartContainer;
        label2.x = am4core.percent(60);
        label2.fill = new am4core.Color({
            r: 79,
            g: 194,
            b: 192
        });
        var rect2 = new am4core.RoundedRectangle();
        rect2.stroke = new am4core.Color({
            r: 79,
            g: 194,
            b: 192
        });
        rect2.strokeWidth = 2;
        label2.background = rect2;
        label2.text = params.achieved ? params.achieved.toFixed(0) : '?';
        var axis = chart.xAxes.push(new am4charts.ValueAxis());
        axis.min = 0;
        axis.max = 100;
        axis.strictMinMax = true;
        axis.renderer.inside = true;
        axis.renderer.radius = am4core.percent(100);
        axis.renderer.line.strokeOpacity = 1;
        axis.renderer.line.strokeWidth = 16;
        axis.marginTop = -15;
        axis.paddingTop = -15;
        var gradient = new am4core.LinearGradient();
        gradient.stops.push({ color: am4core.color('#D0261A') });
        gradient.stops.push({ color: am4core.color('#A69623') });
        gradient.stops.push({ color: am4core.color('#9BB226') });
        axis.renderer.line.stroke = gradient;
        axis.renderer.ticks.template.disabled = false;
        axis.renderer.ticks.template.stroke = new am4core.LinearGradient();
        axis.renderer.labels.template.radius = 35;
        axis.renderer.ticks.template.strokeOpacity = 1;
        axis.renderer.grid.template.disabled = true;
        axis.renderer.ticks.template.length = 10;
        axis.hiddenState.properties.opacity = 1;
        axis.hiddenState.properties.visible = true;
        axis.setStateOnChildren = true;
        axis.renderer.hiddenState.properties.endAngle = 180;
        var hand = chart.hands.push(new am4charts.ClockHand());
        hand.axis = axis;
        hand.pin.radius = 12;
        hand.startWidth = 12;
        hand.radius = am4core.percent(90);
        hand.fill = new am4core.Color({
            r: 41,
            g: 74,
            b: 117
        });
        hand.stroke = new am4core.Color({
            r: 41,
            g: 74,
            b: 117
        });
        var hand2 = chart.hands.push(new am4charts.ClockHand());
        hand2.pin.radius = 8;
        hand2.startWidth = 12;
        hand2.radius = am4core.percent(90);
        hand2.fill = new am4core.Color({
            r: 79,
            g: 194,
            b: 192
        });
        hand2.stroke = new am4core.Color({
            r: 79,
            g: 194,
            b: 192
        });
        setTimeout(function () {
            if (params.average) {
                hand.showValue(params.average, 1000, am4core.ease.cubicOut);
            }
            if (params.achieved) {
                hand2.showValue(params.achieved, 1000, am4core.ease.cubicOut);
            }
        }, 2000);
    };
    return HealthRiskAssessmentOVerviewComponent;
}(AppComponentBase));
export { HealthRiskAssessmentOVerviewComponent };
