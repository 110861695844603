/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./decline-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./decline-modal.component";
import * as i3 from "ngx-bootstrap/modal";
var styles_DeclineModalComponent = [i0.styles];
var RenderType_DeclineModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeclineModalComponent, data: {} });
export { RenderType_DeclineModalComponent as RenderType_DeclineModalComponent };
export function View_DeclineModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "https://elevateme.blob.core.windows.net/publicimages/elevatebrand.jpg"], ["width", "200"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "modal-body border-0 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "d-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h5", [["class", "font-weight-bold pb-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Unfortunately, we aren't able to tailor any products to match your needs today :( "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["src", "../../../../../assets/common/images/message.png"], ["width", "150"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h5", [["class", "font-weight-bold pb-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" But this shouldn't be the case for long! "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["href", "https://www.elevate.co.za/how-it-works/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Find out more"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "a", [["class", "btn btn-danger"], ["href", "https://www.elevate.co.za/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" OK "]))], null, null); }
export function View_DeclineModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-decline-modal", [], null, null, null, View_DeclineModalComponent_0, RenderType_DeclineModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.DeclineModalComponent, [i3.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeclineModalComponentNgFactory = i1.ɵccf("app-decline-modal", i2.DeclineModalComponent, View_DeclineModalComponent_Host_0, {}, {}, []);
export { DeclineModalComponentNgFactory as DeclineModalComponentNgFactory };
