var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { RateTableEntry } from './RateTableEntry';
import { ProductService } from '@app/shared/services/product.service';
import { PolicyService } from '@app/shared/services/policy.service';
var RateTableEntryFactory = /** @class */ (function () {
    function RateTableEntryFactory(injector) {
        this.injector = injector;
    }
    Object.defineProperty(RateTableEntryFactory.prototype, "productService", {
        get: function () {
            if (!this._productService) {
                this._productService = this.injector.get(ProductService);
            }
            return this._productService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RateTableEntryFactory.prototype, "policyService", {
        get: function () {
            if (!this._policyService) {
                this._policyService = this.injector.get(PolicyService);
            }
            return this._policyService;
        },
        enumerable: true,
        configurable: true
    });
    RateTableEntryFactory.prototype.getRatingFactors = function (sumAssuredLimit, age) {
        if (sumAssuredLimit === void 0) { sumAssuredLimit = null; }
        var userProfile = this.policyService.personRatingFactor;
        var ratingFactors = new Array();
        ratingFactors.push({
            Description: age,
            RatingFactorType: 'Age'
        });
        ratingFactors.push({
            Description: userProfile.smoker === 'Smoker' ? 'Smoker' : 'Non_Smoker',
            RatingFactorType: 'SmokingStatus'
        });
        ratingFactors.push({
            Description: userProfile.occupational || 'Class_1',
            RatingFactorType: 'OccupationCategory'
        });
        ratingFactors.push({
            Description: userProfile.socials || 'Class_1',
            RatingFactorType: 'SocioEconomicClass'
        });
        ratingFactors.push({
            Description: userProfile.gender,
            RatingFactorType: 'Gender'
        });
        if (sumAssuredLimit !== null) {
            ratingFactors.push({
                Description: sumAssuredLimit.toString(),
                RatingFactorType: 'SumAssuredLimit'
            });
        }
        return ratingFactors;
    };
    RateTableEntryFactory.prototype.addRateEntry = function (productId, sumAssuredLimit, age) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, ratesToAdd, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        if (!this.metaData) {
                            throw new Error('No rate table instance');
                        }
                        return [4 /*yield*/, this.productService.getRateTableForProduct(productId, this.getRatingFactors(sumAssuredLimit, age)).toPromise()];
                    case 1:
                        ratesToAdd = _b.sent();
                        (_a = this.metaData).push.apply(_a, __spread(ratesToAdd));
                        return [2 /*return*/, this];
                    case 2:
                        error_1 = _b.sent();
                        abp.message.error('Please Contact Support, as we could not assist at present.');
                        abp.ui.clearBusy();
                        throw new Error(error_1);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    RateTableEntryFactory.prototype.withMetaData = function (productId, lowerLimit, upperLimit, age) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this.productService.getRateTableForProduct(productId, this.getRatingFactors(null, age), lowerLimit, upperLimit).toPromise()];
                    case 1:
                        _a.metaData = _b.sent();
                        return [2 /*return*/, this];
                    case 2:
                        error_2 = _b.sent();
                        abp.message.error('Please Contact Support, as we could not assist at present.');
                        abp.ui.clearBusy();
                        throw new Error(error_2);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    RateTableEntryFactory.prototype.extractRateTableEntryUIDList = function () {
        try {
            return this.metaData.map(function (_a) {
                var UID = _a.UID;
                return ({ UID: UID }.UID);
            });
        }
        catch (error) {
            alert(JSON.stringify(error));
            throw new Error(error);
        }
    };
    RateTableEntryFactory.prototype.forRateTableEntry = function (UID) {
        this.RateEntry = this.metaData.find(function (x) { return x.UID === UID; });
        return this;
    };
    RateTableEntryFactory.prototype.extractUID = function () {
        this.UID = this.RateEntry.UID;
        return this;
    };
    RateTableEntryFactory.prototype.extractSumAssuredLimit = function () {
        this.sumAssuredLimit = Number.parseInt(this.RateEntry.RatingFactor_SumAssuredLimit_Description);
        return this;
    };
    RateTableEntryFactory.prototype.extractBenefitPattern = function (benefitPatternSet) {
        var _this = this;
        if (!this.RateEntry.BenefitPatternGUID) {
            abp.message.error('Please Contact Support, as we could not assist at present. Error Details extractName');
            abp.ui.clearBusy();
        }
        this.BenfitPattern = benefitPatternSet.find(function (item) { return item.UID === _this.RateEntry.BenefitPatternGUID; });
        return this;
    };
    RateTableEntryFactory.prototype.extractPremiumPattern = function (premiumPatternSet) {
        var _this = this;
        if (!this.RateEntry.PremiumPatternGUID) {
            throw new Error('Method not implemented.');
        }
        this.PremiumPattern = premiumPatternSet.find(function (item) { return item.UID === _this.RateEntry.PremiumPatternGUID; });
        return this;
    };
    RateTableEntryFactory.prototype.extractBaseRate = function () {
        if (!this.RateEntry.BaseRate) {
            throw new Error('Method not implemented.');
        }
        this.BaseRate = this.RateEntry.BaseRate;
        return this;
    };
    RateTableEntryFactory.prototype.extractUnitRate = function () {
        if (!this.RateEntry.ProductOfFactors) {
            throw new Error('Method not implemented.');
        }
        this.UnitRate = this.RateEntry.ProductOfFactors;
        return this;
    };
    RateTableEntryFactory.prototype.build = function () {
        return new RateTableEntry(this.UID, this.BenfitPattern, this.PremiumPattern, this.BaseRate, this.UnitRate, this.sumAssuredLimit);
    };
    return RateTableEntryFactory;
}());
export { RateTableEntryFactory };
