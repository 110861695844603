var StyleLoaderService = /** @class */ (function () {
    function StyleLoaderService() {
        this.styles = {};
    }
    StyleLoaderService.prototype.load = function () {
        var _this = this;
        var styles = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            styles[_i] = arguments[_i];
        }
        this.styles = styles;
        var promises = [];
        styles.forEach(function (style) { return promises.push(_this.loadStyle(style)); });
        return Promise.all(promises);
    };
    StyleLoaderService.prototype.loadArray = function (styles) {
        var _this = this;
        this.styles = styles;
        var promises = [];
        styles.forEach(function (style) { return promises.push(_this.loadStyle(style)); });
        return Promise.all(promises);
    };
    StyleLoaderService.prototype.loadStyle = function (name) {
        return new Promise(function (resolve, reject) {
            var style = document.createElement('link');
            style.type = 'text/css';
            style.rel = 'stylesheet';
            style.href = name;
            if (style.readyState) { //IE
                style.onreadystatechange = function () {
                    if (style.readyState === 'loaded' || style.readyState === 'complete') {
                        style.onreadystatechange = null;
                        resolve({ style: name, loaded: true, status: 'Loaded' });
                    }
                };
            }
            else { //Others
                style.onload = function () {
                    resolve({ style: name, loaded: true, status: 'Loaded' });
                };
            }
            style.onerror = function (error) { return resolve({ style: name, loaded: false, status: 'Loaded' }); };
            document.getElementsByTagName('head')[0].appendChild(style);
        });
    };
    return StyleLoaderService;
}());
export { StyleLoaderService };
