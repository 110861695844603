<div class="row">
  <div class="col-md-6 col-sm-6 col-xl-6">
    <div class="m-portlet">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="col pt-3">
            <div class="m-portlet__head-title">
              <h5 class="mt-3 m-portlet__head-text font-weight-bold black-clr">
                {{"HE" | localize}}
              </h5>
              <a class="mt-3" placement="right" ngbTooltip="{{'HEIN' | localize}}" tooltipClass="customHeightforinfo">
                <i class="fa fa-info-circle clr-dark-maroon m--margin-bottom-20 m--margin-left-5">
                </i>
              </a>
            </div>
            <h6 class="m-portlet__head-desc font-weight-light m--regular-font-size-sm3">
              {{"HESUB" | localize}}
            </h6>
          </div>
        </div>
        <div id="my-healthrec-drpdwn"
          class="my-epcare-drpdwn top28 d-flex flex-row justify-content-start align-items-center">
          <i class="fa fa-circle-notch fa-spin loader mr-2" *ngIf="isLoading"></i>

          <button type="button"
            class="btn btn-sm bg-dark-maroon m-btn--pill z-index-2 dropdown-toggle font-weight-bold text-white"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{hospEventSelectedYear}}
          </button>
          <div class="max-height dropdown-lg z-index-1 dropdown-menu">
            <a [className]="yearActiveHE[i] ? 'active dropdown-item' : 'dropdown-item'"
              (click)="FilterWhithYearHospEvent(item,i);seemoreActivated=false"
              *ngFor="let item of _healtCareService.yearList;index as i"
              class="active-drpdown dropdown-item">{{item}}</a>
          </div>
        </div>
      </div>
      <div class="m-portlet__body pb-0">
        <div style="height: 450px;" *ngIf="requestAccessToHECharts || requestAccessToHECharts==undefined"
          id="HospitalEvent" class="font-weight-bold m--regular-font-size-sm5"></div>
        <div *ngIf="!requestAccessToHECharts" id="HospitalEvent" class="padding-40 h-400">
          <label class="transform-healrec font-weight-bold m--regular-font-size-lg1"
            *ngIf="!requestAccessToHECharts && requestAccessToHECharts!=undefined">
            No hospital admissions in
            {{hospEventSelectedYear}}
          </label>
        </div>
      </div>
      <div class="m-portlet__foot m-portlet__no-border pt-0">
        <ng-template #tooltipContent>

          <div class="row">
            <div class="col-10">
              <div class="m--align-left m--margin-left-10">
                {{"ChatToolTipDescription"|localize}}
              </div>
            </div>
            <div class="col-2 right-px">
              <i class="fa fa-times" (click)="h.toggle()"></i>
            </div>
          </div>
          <textarea rows="3" cols="40" [(ngModel)]="currentUserQuery" placeholder="Description here" type="text"
            name="disputing"
            class="m--margin-top-20  m--margin-10-tablet-and-mobile healthcare-chat-box-border"></textarea>
          <div class="m--align-left m--margin-left-10">
            <button (click)="sendsms('Hospital Events')"
              class="disputing-css btn m-btn--sm m-btn--pill bg-dark-maroon m--margin-top-10">
              <small class="font-weight-bold text-white">
                {{"Send"|localize}}

              </small>
            </button>
          </div>
        </ng-template>
        <div class="m--align-right">
          <button type="button" (mouseout)="imgSrc = '../../../assets/common/images/chatblack.svg'"
            (mouseover)="imgSrc = '../../../assets/common/images/Elevate _ Dispute.png'"
            class="readmore btn m-btn--pill font-weight-bold bg-dark-maroon btn-circle " placement="left"
            #h="ngbTooltip" [ngbTooltip]="tooltipContent" triggers="click" [autoClose]="'outside'"
            tooltipClass="round-tooltip">
            <img style="width:20px" [src]="imgSrc">

          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-6 col-xl-6" *ngIf="seemoreActivated">
    <div class="m-portlet" style="transition: transform 1s, left 1s;">
      <div class="m-portlet__head ">
        <div class="m-portlet__head-caption">
          <div class="col">
            <div class="m--space-20"></div>
            <div class="m-portlet__head-title">
              <h5 class="font-weight-light">
                {{hospEventData.baseDRG}}
              </h5>
            </div>
            <h6 class="m-portlet__head-desc disabledtxt font-weight-light m--regular-font-size-sm3">
              {{"hipinfo" | localize}}
            </h6>
          </div>
        </div>
      </div>
      <div
        class="m-portlet__body m-portlet__body--no-padding m--padding-left-40-desktop  m--padding-right-40-desktop m--padding-right-40-tablet-and-mobile m--padding-left-40-tablet-and-mobile">
        <table class="table table-borderless ">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <small>Amount claimed</small>
              </th>
              <td>
                <small>{{hospEventData.totalAdmissionClaimedAmount | CurrencyZar : true : true : true}}</small>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <small>Amount paid</small>
              </th>
              <td>
                <small>{{hospEventData.totalAdmissionPaidAmount | CurrencyZar : true : true : true}}</small>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <small>Date of insident</small>
              </th>
              <td>
                <small>{{hospEventData.admissionDate | date:'shortDate'}}</small>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <small>Hospital</small>
              </th>
              <td>
                <small>{{hospEventData.admittingHospitalName}}</small>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <small>Doctor</small>
              </th>
              <td>
                <small>{{hospEventData.admittingDoctorName}}</small>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <small>Description:</small>
              </th>
            </tr>
          </tbody>
        </table>
        <label class="ml-5">
          <small>{{hospEventData.admissionPrimaryICD10CodeDescription}}</small>
        </label>
      </div>
      <div class="m-portlet__foot m-portlet__no-border">

        <ng-template #tooltipContent>
          <div class="row">
            <div class="col-10">
              <div class="m--align-left m--margin-left-10">
                {{"ChatToolTipDescription"|localize}}
              </div>
            </div>
            <div class="col-2 right-px">
              <i class="fa fa-times" (click)="h2.toggle()"></i>
            </div>
          </div>
          <textarea rows="3" cols="40" placeholder="Description here" type="text" name="disputing"
            class="m--margin-top-20  m--margin-10-tablet-and-mobile healthcare-chat-box-border"></textarea>
          <div class="m--align-left m--margin-left-10">
            <button class="disputing-css btn m-btn--sm m-btn--pill bg-dark-maroon m--margin-top-10">
              <small class="font-weight-bold text-white">
                {{"Send"|localize}}
              </small>
            </button>
          </div>
        </ng-template>
        <div class="m--align-right">
          <button type="button" (mouseout)="imgSrc = '../../../assets/common/images/chatblack.svg'"
            (mouseover)="imgSrc = '../../../assets/common/images/Elevate _ Dispute.png'"
            class="readmore btn m-btn--pill font-weight-bold bg-dark-maroon btn-circle " placement="left"
            #h2="ngbTooltip" [ngbTooltip]="tooltipContent" triggers="click" [autoClose]="'outside'"
            tooltipClass="round-tooltip">
            <img class="width-px-20" [src]="imgSrc">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>