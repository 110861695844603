<div id="WearableDashboard">
    <div class="m-subheader mt-3">
        <div class="row align-items-center">
            <div class="mr-auto ml-3">
                <h3 class="m-subheader__title pb-0 font-weight-light m--icon-font-size-sm1">
                    <a class="brd-clr" [routerLink]="['/app/main/dashboard']">
                        {{"MyData" | localize}}
                    </a>>
                    <a class="font-weight-bold brd-clr ">
                        {{"MyWearables" | localize}}
                    </a>
                </h3>
                <small class="m-section__sub">
                    {{"MWPnote" | localize}}
                </small>

            </div>
            <div class="flex-row-reverse mr-3">
                <div id="m_Calender_toggle" class="hov-whit mr-2 d-inline" *ngIf="!determineWindowsWidth()">
                    <i *ngIf="_wearableService.isMyDataWearableActive()== true && _wearableService.isMyDataWearableActive()!=undefined"
                        class="flaticon-calendar-1 m--icon-font-size-lg5"></i>

                </div>
                <div class="filter-wearables hov-whit mr-3 d-inline" *ngIf="determineWindowsWidth()">
                    <div *ngIf="_wearableService.isMyDataWearableActive()== true && _wearableService.isMyDataWearableActive()!=undefined"
                        id="my-wearable-drpdwn" class="mr-2_m pr-2 btn-group dropdown float-right btn-group">
                        <button type="button"
                            class="w-110 h-40 btn z-index-2 btn-sm yellow-clr m-btn--pill dropdown-toggle font-weight-bold text-white"
                            id="dropdownMenuButtonChartFilter" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            {{showchart | titlecase }}
                        </button>
                        <div class="max-height dropdown-menu z-index-1" aria-labelledby="dropdownMenuButtonChartFilter">
                            <a (click)="defaultFilter(item.id);item.IsSelected=true"
                                [className]="item.IsSelected ? 'active active-drpdown dropdown-item':'active-drpdown dropdown-item'"
                                *ngFor="let item of chartFilterTimePeriod">{{item.Name}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="m-content"
        *ngIf="_wearableService.isMyDataWearableActive() == false && _wearableService.isMyDataWearableActive() != undefined">

        <div class="row">
            <div class="col-md-6">
                <div class="m-portlet yellow-clr m-portlet--full-height sales-summary-chart">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text">
                                    <small class="font-weight-bold black-clr">
                                        {{"ActivateMyWearablesTo" |localize }}
                                    </small>
                                </h3>
                            </div>
                        </div>
                        <div class="m-portlet__head-tools">
                            <button (click)="ConnectHumanApi()"
                                class="topBtn bg-white btn m-btn--pill  font-weight-bold">
                                <small class="font-weight-bold m--margin-left-10 m--margin-right-10">
                                    {{"Activate" | localize }}
                                </small>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="m-content"
        *ngIf="_wearableService.isMyDataWearableActive()== true && _wearableService.isMyDataWearableActive()!=undefined">

        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="m-portlet m-portlet--full-height sales-summary-chart yellow-clr">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text">
                                    <small class="font-weight-bold black-clr">
                                        {{this._wearableService?.goalsMessage || 'See all your current goals'}}
                                    </small>
                                </h3>
                            </div>
                        </div>
                        <div class="m-portlet__head-tools">
                            <button [routerLink]="['/app/main/MyGoals']"
                                class="readmore btn m-btn--pill font-weight-bold">
                                <small class="font-weight-bold m--margin-left-10 m--margin-right-10">
                                    {{"SeeMore" | localize}}
                                </small>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="m-portlet sales-summary-chart yellow-clr">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text">
                                    <small class="font-weight-bold black-clr">
                                        {{"shareinfor" | localize}}
                                    </small>
                                </h3>
                            </div>
                        </div>
                        <div class="m-portlet__head-tools">
                            <button (click)="ConnectHumanApi()" class="readmore btn m-btn--pill font-weight-bold">
                                <small class="font-weight-bold m--margin-left-10 m--margin-right-10">
                                    {{"AddDevice" | localize}}
                                </small>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <app-widget-my-active-minutes #widgetmyactiveminutes></app-widget-my-active-minutes>
                <app-widget-my-total-distance #widgetmytotaldistance></app-widget-my-total-distance>
                <app-widget-my-synced-activities #appwidgetmysyncedactivities></app-widget-my-synced-activities>
            </div>
            <div class="col-md-6 col-sm-12">
                <app-widget-my-steps #appwidgetmysteps></app-widget-my-steps>
                <app-widget-my-active-calories #appwidgetmyactivecalories></app-widget-my-active-calories>
                <app-widget-last-seven-days #appwidgetlastsevendays></app-widget-last-seven-days>
            </div>
        </div>
    </div>
</div>
<!-- <appwearables (onVo2Change)="onVo2Change()" (distanceChange)="distanceChange()" (onActivemin)="onActivemin()"
    (onHeartChange)="onHeartChange()" (onfitBitChange)="onfitBitChange()" (syncActive)="onSyncchange($event)"
    style="width:270px !important;height:100% !important; top:91px !important;"></appwearables> -->
<appmywearablescalender (week)="weekChange()" (month)="monthChange()" (year)="yearChange()"
    style="height:100% !important;width:200px !important; top:91px !important"></appmywearablescalender>