import { Component, OnInit, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HealthCareApiServiceProxy } from '@shared/service-proxies/service-proxies';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import { forkJoin } from 'rxjs';
import { HealthRiskAssessmentModule } from '@shared/models/interfaces/elevate-data-models';
import { bounceIn } from '@shared/animations/routerTransition';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import 'shared/extensions/my-policies-extensions';
import { HraIntroModalComponent } from '../hra-intro-modal/hra-intro-modal.component';
import { AppConsts } from '@shared/AppConsts';
import { Moment } from 'moment';
import * as moment from 'moment';


@Component({
    selector: 'app-health-risk-assessment-overview',
    templateUrl: './health-risk-assessment-overview.component.html',
    styleUrls: ['./health-risk-assessment-overview.component.css'],
    animations: [bounceIn()]
})
export class HealthRiskAssessmentOVerviewComponent extends AppComponentBase implements OnInit {
    categories: Array<HealthRiskAssessmentModule.Category>;
    openAccordianIndex: number | null;
    currentDate: Date;
    descriptionShown: boolean;
    isLoadingReport: boolean;
    totals: {
        achieved: number,
        average: number
    };

    constructor(
        injector: Injector,
        private healthCareService: HealthCareApiServiceProxy,
        private authGuard: AuthGuard
    ) {
        super(injector);
        this.categories = [
            {
                key: 'Intro',
                title: 'Intro',
                description: 'A quick introduction',
                chart: 'myIntroChart',
                average: 0,
                percentage: 0,
                isShown: false,
                claimType: 'hra_intro',
                status: null,
                report: null,
                questionnaire: null
            },
            {
                key: 'MyNutrition',
                title: 'My Nutrition',
                description: 'Understand more about your nutrition.',
                chart: 'myNutritionChart',
                average: 0,
                percentage: 0,
                isShown: true,
                claimType: 'hra_nutrition',
                status: null,
                report: {
                    descriptions: [
                        {
                            title: 'Why is nutrition important?',
                            content: [
                                'Most people know that good nutrition can help maintain a healthy weight. However, the benefits of good nutrition go beyond weight. Good nutrition can help reduce the risk of heart disease, diabetes, stroke, osteoporosis, and some types of cancer. A healthy diet can also lower high blood pressure and high cholesterol levels, improving your mental well-being, ability to fight infection, and recovery from illness or injury. Good nutrition can increase your energy levels.'
                            ],
                            list: []
                        }],
                    negativeOutcomes: [],
                    positiveOutcomes: []
                },
                questionnaire: null
            },
            {
                key: 'MySleep',
                title: 'My Sleep',
                description: 'Understand more about your sleep.',
                chart: 'mySleepChart',
                average: 0,
                percentage: 0,
                isShown: true,
                claimType: 'hra_sleep',
                status: null,
                report: {
                    descriptions: [
                        {
                            title: 'Why is sleep important?',
                            content: [
                                'Sleep is essential to every process in the body, affecting our physical and mental functioning the next day, our ability to fight disease and develop immunity, and our metabolism and chronic disease risk. Sleep truly touches every aspect of health.',
                            ],
                            list: []
                        },
                        {
                            title: 'What is good sleep?',
                            content: [
                                'A good night\'s sleep is when you fall asleep quite easily, do not fully wake up during the night, do not wake up too early, and feel refreshed in the morning. Regularly having difficulty falling asleep or sleeping through the night is not normal for healthy people of any age. But not everyone needs the same amount of sleep, and quality of sleep is different in different phases of life.',
                                'Young children and older people sleep more lightly than adults and teenagers. The length of time spent in deep sleep phases changes over a person\'s lifetime. Babies and toddlers need to sleep a lot more than older children and adults. By the time they reach the age of five, most children have the typical sleep patterns of an adult: they are awake during the day and then sleep through the night. The amount of time spent sleeping gradually decreases until the age of 80.'
                            ],
                            list: []
                        },
                        {
                            title: 'Generally, good sleep is defined by the following:',
                            content: [],
                            list: [
                                'You fall asleep soon after getting into bed, within 30 minutes or less.',
                                'You typically sleep straight through the night, waking up no more than once per night.',
                                'You typically sleep straight through the night, waking up no more than once per night.',
                                'You\'re able to sleep the recommended amount of hours for your age group.',
                                'You fall back asleep within 20 minutes if you do wake up.',
                                'You feel rested, restored, and energised upon waking up in the morning.'
                            ]
                        }
                    ],
                    negativeOutcomes: [],
                    positiveOutcomes: []
                },
                questionnaire: null
            },
            {
                key: 'MyHealthyBody',
                title: 'My Healthy Body',
                description: 'Understand more about your body.',
                chart: 'myHealthyBodyChart',
                average: 0,
                percentage: 0,
                isShown: true,
                claimType: 'hra_body',
                status: null,
                report: {
                    descriptions: [
                        {
                            title: null,
                            content: [
                                'Your health metrics such as your weight, blood pressure, cholesterol and blood glucose levels can give you a meaningful indication on the state of your physical wellbeing. With the help and guidance of a health practitioner, recording and monitoring these clinical metrics over time is essential to ensure that necessary interventions can be put in place if any indicate a deterioration in your clinical health.',
                                'These metrics are also a strong indication of how successfully you are managing and maintaining the important lifestyle habits focused on in the remainder of this Health Risk Assessment. They can highlight for you the importance of greater focus to be placed on achieving heathier lifestyle habits pertaining to your exercise, sleep, nutrition, and stress.',
                                'However, your lifestyle habits are often not sufficient to fully treat a chronic or acute medical condition you are suffering with. It is essential to seek the appropriate medical attention and to following recommended treatment protocols to properly manage illness and disease. The sooner a health condition is diagnosed and the more disciplined you are at maintaining the right ongoing treatment, the higher the likelihood of being cured or otherwise successfully limiting the symptoms and the severity of its impact on your life.',
                                'There is also many things you can do to ensure that you prevent or delay illness and disease from occurring. These include healthcare screenings, vaccinations, supplements, and the positive lifestyle habits noted above.'
                            ],
                            list: []
                        }],
                    negativeOutcomes: [],
                    positiveOutcomes: []
                },
                questionnaire: null
            },
            {
                key: 'MyHealthyMind',
                title: 'My Healthy Mind',
                description: 'Understand more about your mind.',
                chart: 'myHealthyMindChart',
                average: 0,
                percentage: 0,
                isShown: true,
                claimType: 'hra_mind',
                status: null,
                report: {
                    descriptions: [
                        {
                            title: 'Why is it so important to look after one\'s mental health?',
                            content: [
                                'Mental health is crucial for overall wellbeing. It impacts all of our ability to cope with daily stressors, make healthy decisions, and form meaningful relationships.'
                            ],
                            list: []
                        },
                        {
                            title: 'What are some of the ways to achieve good ongoing mental health?',
                            content: ['It is important to remember that mental health can change over time, and that good mental health is not a destination but a journey. It is our recommendation to always keep in mind that seeking help and support when needed is a sign of strength, and not something to be ashamed of.'],
                            list: [
                                'Building and maintaining positive relationships. Fundamental to this is a strong support system and connecting meaningfully with others.',
                                'Practicing self-care. This involves getting into good ongoing habits that promote physical and emotional wellbeing, such as exercise, healthy diet, getting enough sleep, and taking time for hobbies and interests.',
                                'Managing stress and anxiety. Identifying and managing the sources of stress in one\'s life can help reduce feelings of anxiety and depression. This is often difficult to achieve by yourself. Seeking effective guidance from a health practitioner and/or your community may be fundamental when working towards managing your stress.',
                                'Staying active and productive. Engaging in work, hobbies or volunteer activities can help improve self-esteem.',
                                'Mindfulness and meditation. Practicing mindfulness, such as meditation, yoga, or deep breathing exercises, has been clinically proven to help reduce stress and anxiety.'
                            ],
                        }],
                    negativeOutcomes: [],
                    positiveOutcomes: []
                },
                questionnaire: null
            },
            {
                key: 'MyExercise',
                title: 'My Exercise',
                description: 'Understand more about your exercise.',
                chart: 'myExerciseChart',
                average: 0,
                percentage: 0,
                isShown: true,
                claimType: 'hra_exercise',
                status: null,
                report: {
                    descriptions: [
                        {
                            title: 'Why is exercise important?',
                            content: [
                                'Exercise helps control weight, combats health conditions and diseases, improves mood, boosts energy, promotes better sleep, and can even put the spark back into your sex life. Exercise can be fun, and social.'
                            ],
                            list: []
                        },
                        {
                            title: 'What exercise do you need?',
                            content: [
                                'Experts recommend at least 150 minutes of moderate aerobic activity or 75 minutes of vigorous aerobic activity a week, or a combination of moderate and vigorous activity. You should spread this exercise out over the course of a week. For even greater health benefit and to assist with weight loss, at least 300 minutes a week is recommended but even small amounts of physical activity are helpful. Being active for short periods of time throughout the day add up to provide health benefit.',
                                'Moderate aerobic exercise includes activities such as brisk walking, cycling, swimming and mowing the lawn. Vigorous aerobic exercise includes activities such as running, intensive work in the garden and aerobic dancing. Strength training can include use of weight machines, your own body weight, heavy bags, resistance tubing or resistance paddles in the water, or activities such as rock climbing.',
                                'Strength training is also recommended. Do strength training exercises for all major muscle groups at least two times a week. Aim to do a single set of each exercise using a weight or resistance level heavy enough to tire your muscles after about 12 to 15 repetitions.',
                                'Remember to check with your doctor before starting a new exercise program, especially if you have any concerns about your fitness, haven\'t exercised for a long time, or have chronic health problems, such as heart disease, diabetes or arthritis.'
                            ],
                            list: []
                        }],
                    negativeOutcomes: [],
                    positiveOutcomes: []
                },
                questionnaire: null
            }
        ];

        this.currentDate = new Date();
        this.totals = {
            achieved: 0,
            average: 0
        };
    }

    ngOnInit() {
        this.setUpQuestionnaires();
    }

    private setUpQuestionnaires(): void {
        abp.ui.setBusy();

        this.updateClaimsIdsvr().then(() => {
            const healthRiskAssessmentOverviewObservables = this.categories.map(c => this.healthCareService.getHealthRiskAssessmentOverview(`${this.authGuard.user.profile.sub}_${c.key}`));

            for (const category of this.categories) {
                const statusClaimType = category.claimType + '_status';
                const dateClaimType = category.claimType + '_date';
                const statusClaimValue = this._authService.user.profile[statusClaimType];
                const dateClaimValue = this._authService.user.profile[dateClaimType];

                if (!statusClaimValue || !dateClaimValue) {
                    category.status = null;
                } else if (statusClaimValue === AppConsts.HraQuestionnaireStatuses.SAVED) {
                    category.status = AppConsts.HraQuestionnaireStatuses.SAVED;
                } else if (statusClaimValue === AppConsts.HraQuestionnaireStatuses.COMPLETED) {
                    if (moment(dateClaimValue, 'DD/MM/YYYY hh:mm:ss A').diff(moment(), 'months') >= 6) {
                        category.status = null;
                    } else {
                        category.status = AppConsts.HraQuestionnaireStatuses.COMPLETED;
                    }
                }
            }

            const introAnswered = this.categories.find(c => c.key === AppConsts.HraKeys.INTRO).status === AppConsts.HraQuestionnaireStatuses.COMPLETED;

            forkJoin(healthRiskAssessmentOverviewObservables).subscribe(result => {
                for (const overview of result) {
                    if (!overview) {
                        continue;
                    }

                    const category = this.categories.find(c => c.key === overview.questionnaireKey);

                    if (overview.maxScore !== 0 && category.status === AppConsts.HraQuestionnaireStatuses.COMPLETED) {
                        category.percentage = overview.achievedScore / overview.maxScore * 100;
                    }
                }

                const shownCategories = this.categories.filter(c => c.isShown);

                const accessToken = this._authService.user.access_token;
                const age = moment(moment()).diff(moment(this._authService.user.profile.birthdate, 'DD/MM/YYYY hh:mm:ss A'), 'years');
                const gender = this._authService.user.profile.gender;

                const peopleLikeYouScores = this.categories
                    .filter(c => c.key !== AppConsts.HraKeys.INTRO)
                    .map(c => this.healthCareService.getPeopleLikeYouScore(accessToken, c.key, age, gender));

                forkJoin(peopleLikeYouScores).subscribe(scoreResult => {
                    for (const score of scoreResult) {
                        const category = this.categories.find(c => c.key === score.hraCategory);

                        if (!category || !category.percentage) {
                            continue;
                        }

                        category.average = score.score;
                    }

                    for (const category of shownCategories) {
                        const dateClaim = category.claimType + '_date';
                        let subtitle: string = null;

                        if (this._authService.user.profile[dateClaim] && (category.status === AppConsts.HraQuestionnaireStatuses.COMPLETED || category.status === AppConsts.HraQuestionnaireStatuses.SAVED)) {
                            if (category.status === AppConsts.HraQuestionnaireStatuses.COMPLETED) {
                                subtitle = 'Last completed ';
                            } else if (category.status === AppConsts.HraQuestionnaireStatuses.SAVED) {
                                subtitle = 'Last saved ';
                            }

                            const stringDate = this._authService.user.profile[dateClaim];
                            const date = moment(stringDate, 'DD/MM/YYYY h:mm:ss a');
                            subtitle += date.format('DD/MM/YYYY');
                        }

                        this.drawGuageChart({ chartDiv: category.chart, titleText: category.title, subtitleText: subtitle, average: category.average, achieved: category.percentage });
                    }

                    const categoriesWithPercentage = shownCategories.filter(c => c.isShown && c.percentage > 0);
                    const categoriesWithAverage = shownCategories.filter(c => c.isShown && c.average > 0);

                    if (categoriesWithPercentage.length > 0) {
                        this.totals.achieved = Math.round(categoriesWithPercentage.map(c => c.percentage).reduce((acc, cur) => acc + cur, 0) / categoriesWithPercentage.length);
                    }

                    if (categoriesWithAverage.length > 0) {
                        this.totals.average = Math.round(categoriesWithAverage.map(c => c.average).reduce((acc, cur) => acc + cur, 0) / categoriesWithAverage.length);
                    }

                    if (!introAnswered) {
                        this.bsModalRef = this.modalService.show(HraIntroModalComponent, {
                            backdrop: true,
                            ignoreBackdropClick: true,
                            animated: true,
                            class: 'w-350px mt-48'
                        });

                        this.bsModalRef.content.onClose.subscribe(result => {
                            this.setUpQuestionnaires();
                        });
                    }

                    abp.ui.clearBusy();
                });
            });
        });
    }

    async accordianOpen(index: number, category: HealthRiskAssessmentModule.Category) {
        if (index === this.openAccordianIndex) {
            this.openAccordianIndex = null;
        } else {
            this.openAccordianIndex = index;
        }

        if (!category.questionnaire && category.report) {
            this.isLoadingReport = true;

            const hra = await this.healthCareService.GetLatestHealthRiskAssessment(`${this.authGuard.user.profile.sub}_${category.key}`).toPromise();
            category.questionnaire = hra.data.healthRiskAssessment;


            for (const phase of category.questionnaire.phases) {
                for (const question of phase.questions) {
                    if (!question.report || question.answersGiven.length === 0) {
                        continue;
                    }

                    const answerValue = question.answersGiven[0].value;

                    if (question.report.positive.scores.includes(answerValue)) {
                        category.report.positiveOutcomes.push(question.report.positive.message);
                    } else if (question.report.negative.scores.includes(answerValue)) {
                        category.report.negativeOutcomes.push(question.report.negative.message);
                    }
                }
            }

            this.isLoadingReport = false;
        }
    }

    getSelectedAccordian(index: number): boolean {
        return this.openAccordianIndex === index;
    }

    drawGuageChart(params: { chartDiv: string, titleText: string, subtitleText: string, achieved: number, average: number }) {
        am4core.useTheme(am4themes_animated);

        let chart = am4core.create(params.chartDiv, am4charts.GaugeChart);
        chart.hiddenState.properties.opacity = 0;

        if (params.subtitleText) {
            let subtitle = chart.titles.create();
            subtitle.text = params.subtitleText;
            subtitle.align = 'left';
            subtitle.fontSize = '0.8rem';
            subtitle.fontWeight = '400';
            subtitle.fill = am4core.color('#575962');
            subtitle.fontFamily = 'Montserrat';
        }

        let chartTitle = chart.titles.create();
        chartTitle.text = params.titleText.toUpperCase();
        chartTitle.align = 'left';
        chartTitle.fontSize = '1.3rem';
        chartTitle.fontWeight = '700';
        chartTitle.fill = am4core.color('#c71412');
        chartTitle.fontFamily = 'Montserrat';

        const labelList = new am4core.ListTemplate(new am4core.Label());
        labelList.template.isMeasured = false;
        labelList.template.background.strokeWidth = 2;
        labelList.template.fontSize = 14;
        labelList.template.fontWeight = '700';
        labelList.template.minWidth = 50;
        labelList.template.padding(10, 10, 10, 10);
        labelList.template.y = am4core.percent(50);
        labelList.template.textAlign = 'middle';
        labelList.template.horizontalCenter = 'middle';

        const label = labelList.create();
        label.parent = chart.chartContainer;
        label.x = am4core.percent(40);
        label.fill = new am4core.Color({
            r: 41,
            g: 74,
            b: 117
        });

        const rect = new am4core.RoundedRectangle();
        rect.stroke = new am4core.Color({
            r: 41,
            g: 74,
            b: 117
        });

        rect.strokeWidth = 2;
        label.background = rect;
        label.text = params.average ? params.average.toFixed(0) : '?';

        const label2 = labelList.create();
        label2.parent = chart.chartContainer;
        label2.x = am4core.percent(60);
        label2.fill = new am4core.Color({
            r: 79,
            g: 194,
            b: 192
        });

        const rect2 = new am4core.RoundedRectangle();
        rect2.stroke = new am4core.Color({
            r: 79,
            g: 194,
            b: 192
        });

        rect2.strokeWidth = 2;
        label2.background = rect2;
        label2.text = params.achieved ? params.achieved.toFixed(0) : '?';

        let axis = chart.xAxes.push(new am4charts.ValueAxis()) as am4charts.ValueAxis<am4charts.AxisRenderer>;
        axis.min = 0;
        axis.max = 100;
        axis.strictMinMax = true;
        axis.renderer.inside = true;
        (axis.renderer as any).radius = am4core.percent(100);
        axis.renderer.line.strokeOpacity = 1;
        axis.renderer.line.strokeWidth = 16;
        axis.marginTop = -15;
        axis.paddingTop = -15;

        let gradient = new am4core.LinearGradient();
        gradient.stops.push({ color: am4core.color('#D0261A') });
        gradient.stops.push({ color: am4core.color('#A69623') });
        gradient.stops.push({ color: am4core.color('#9BB226') });

        axis.renderer.line.stroke = gradient;
        axis.renderer.ticks.template.disabled = false;
        axis.renderer.ticks.template.stroke = new am4core.LinearGradient();
        (axis.renderer.labels.template as any).radius = 35;
        axis.renderer.ticks.template.strokeOpacity = 1;
        axis.renderer.grid.template.disabled = true;
        axis.renderer.ticks.template.length = 10;
        axis.hiddenState.properties.opacity = 1;
        axis.hiddenState.properties.visible = true;
        axis.setStateOnChildren = true;
        (axis.renderer.hiddenState.properties as any).endAngle = 180;

        let hand = chart.hands.push(new am4charts.ClockHand());
        hand.axis = axis;
        hand.pin.radius = 12;
        hand.startWidth = 12;
        hand.radius = am4core.percent(90);
        hand.fill = new am4core.Color({
            r: 41,
            g: 74,
            b: 117
        });
        hand.stroke = new am4core.Color({
            r: 41,
            g: 74,
            b: 117
        });

        let hand2 = chart.hands.push(new am4charts.ClockHand());
        hand2.pin.radius = 8;
        hand2.startWidth = 12;
        hand2.radius = am4core.percent(90);
        hand2.fill = new am4core.Color({
            r: 79,
            g: 194,
            b: 192
        });
        hand2.stroke = new am4core.Color({
            r: 79,
            g: 194,
            b: 192
        });

        setTimeout(() => {
            if (params.average) {
                hand.showValue(params.average, 1000, am4core.ease.cubicOut);
            }

            if (params.achieved) {
                hand2.showValue(params.achieved, 1000, am4core.ease.cubicOut);
            }
        }, 2000);
    }
}
