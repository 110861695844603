import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';

@Component({
  selector: 'app-check-auth',
  templateUrl: './check-auth.component.html',
  styleUrls: ['./check-auth.component.css']
})
export class CheckAuthComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService) { }

  ngOnInit() {
    // this.route;
    setTimeout(() => {
      this.authService.getUserStatus(() => {
        this.router.navigate([localStorage.getItem('nextLink') ? localStorage.getItem('nextLink') : '/app/main/dashboard']);
      });
    }, 150);

  }

}
