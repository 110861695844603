var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { AbpSessionService } from '@abp/session/abp-session.service';
import { LinkedAccountService } from '@app/shared/layout/linked-account.service';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ChangeUserLanguageDto, LinkedUserDto, ProfileServiceProxy, UserLinkServiceProxy } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { SupportModalComponent } from '../common/modals/support-modal/support-modal.component';
var TopBarComponent = /** @class */ (function (_super) {
    __extends(TopBarComponent, _super);
    function TopBarComponent(injector, _abpSessionService, _abpMultiTenancyService, _profileServiceProxy, _userLinkServiceProxy, 
    //private _impersonationService: ImpersonationService,
    _linkedAccountService, router) {
        var _this = _super.call(this, injector) || this;
        _this._abpSessionService = _abpSessionService;
        _this._abpMultiTenancyService = _abpMultiTenancyService;
        _this._profileServiceProxy = _profileServiceProxy;
        _this._userLinkServiceProxy = _userLinkServiceProxy;
        _this._linkedAccountService = _linkedAccountService;
        _this.router = router;
        _this.isImpersonatedLogin = false;
        _this.isMultiTenancyEnabled = false;
        _this.shownLoginName = '';
        _this.tenancyName = '';
        _this.userName = '';
        _this.firstName = '';
        _this.lastName = '';
        _this.faebookprofilepicture = '';
        _this.profilePicture = AppConsts.appBaseUrl + '/assets/common/images/user.png';
        _this.defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + _this.currentTheme.baseSettings.menu.asideSkin + '.svg';
        _this.unreadChatMessageCount = 0;
        _this.remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
        _this.chatConnected = false;
        _this.currentUrlForChangePassword = '';
        _this.isDefaultProfilePicture = false;
        _this.isLiveChatHover = false;
        if (abp.setting.values['PortalSettings.authority'].lastIndexOf('/') === abp.setting.values['PortalSettings.authority'].length - 1) {
            _this.currentUrlForChangePassword = abp.setting.values['PortalSettings.authority'] + 'account/changepassword';
        }
        else {
            _this.currentUrlForChangePassword = abp.setting.values['PortalSettings.authority'] + '/account/changepassword';
        }
        return _this;
    }
    Object.defineProperty(TopBarComponent.prototype, "isUserLoggedIn", {
        get: function () {
            return this._isUserLoggedIn;
        },
        set: function (value) {
            this._isUserLoggedIn = value;
        },
        enumerable: true,
        configurable: true
    });
    TopBarComponent.prototype.ngOnInit = function () {
        this.isUserLoggedIn = this._authService.isLoggedIn();
        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
        this.languages = _.filter(this.localization.languages, function (l) { return (l).isDisabled === false; });
        this.currentLanguage = this.localization.currentLanguage;
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
        if (this.isUserLoggedIn) {
            this.setCurrentLoginInformations();
            this.registerToEvents();
            this.getProfilePicture();
        }
    };
    TopBarComponent.prototype.isExtQuote = function () {
        return this.router.url.indexOf('ExtQuote') > 0 || this.router.url.indexOf('ExtCalculator') > 0 || this.router.url.indexOf('fna') > 0;
    };
    TopBarComponent.prototype.getHeaderText = function () {
        if (this.isExtQuote()) {
            return null;
        }
        if (this.router.url.indexOf('ExtCalculator') > 0 || this.router.url.indexOf('MyNeedsCalculator') > 0) {
            return 'MY NEEDS CALCULATOR';
        }
        return this.l('MWP');
    };
    TopBarComponent.prototype.registerToEvents = function () {
        var _this = this;
        abp.event.on('profilePictureChanged', function () {
            _this.getProfilePicture(true);
        });
        abp.event.on('app.chat.unreadMessageCountChanged', function (messageCount) {
            _this.unreadChatMessageCount = messageCount;
        });
        abp.event.on('app.chat.connected', function () {
            _this.chatConnected = true;
        });
        abp.event.on('app.getRecentlyLinkedUsers', function () {
            _this.getRecentlyLinkedUsers();
        });
        abp.event.on('app.onMySettingsModalSaved', function () {
            _this.onMySettingsModalSaved();
        });
    };
    TopBarComponent.prototype.changeLanguage = function (languageName) {
        var input = new ChangeUserLanguageDto();
        input.languageName = languageName;
        this._profileServiceProxy.changeLanguage(input).subscribe(function () {
            abp.utils.setCookieValue('Abp.Localization.CultureName', languageName, new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
            abp.appPath);
            window.location.reload();
        });
    };
    TopBarComponent.prototype.setCurrentLoginInformations = function () {
        var _this = this;
        var abpEventTriggered = false;
        abp.event.on('updateProfileName', function (data) {
            abpEventTriggered = true;
            _this._authService.getUser(function (res) {
                _this.firstName = res.given_name;
            }, true);
        });
        if (!abpEventTriggered) {
            if (this._authService) {
                this._authService.getUser(function (res) {
                    _this.firstName = res.given_name;
                });
            }
        }
        // this.firstName = this.authGuard.user.profile.given_name;
    };
    TopBarComponent.prototype.getShownUserName = function (linkedUser) {
        if (!this._abpMultiTenancyService.isEnabled) {
            return linkedUser.username;
        }
        return (linkedUser.tenantId ? linkedUser.tenancyName : '.') + '\\' + linkedUser.username;
    };
    TopBarComponent.prototype.getProfilePicture = function (forceUpdate) {
        var _this = this;
        if (forceUpdate === void 0) { forceUpdate = false; }
        this._authGuard.GetUserInfo(function (res) {
            _this.profilePicture = res.picture;
            _this.isDefaultProfilePicture = false;
            if (_this.profilePicture === undefined || _this.profilePicture === null || _this.profilePicture === '') {
                _this.isDefaultProfilePicture = true;
                _this.profilePicture = AppConsts.appBaseUrl + '/assets/common/images/user.png';
                _this.profilePictureWhite = AppConsts.appBaseUrl + '/assets/common/images/user-white.png';
            }
        }, forceUpdate);
    };
    TopBarComponent.prototype.getfacebookProfilePicture = function () {
        var _this = this;
        this._profileServiceProxy.getFacebookFeeds().subscribe(function (result) {
            _this.profilePicture = result.url;
        });
    };
    TopBarComponent.prototype.getRecentlyLinkedUsers = function () {
        var _this = this;
        this._userLinkServiceProxy.getRecentlyUsedLinkedUsers().subscribe(function (result) {
            _this.recentlyLinkedUsers = result.items;
        });
    };
    TopBarComponent.prototype.showLoginAttempts = function () {
        abp.event.trigger('app.show.loginAttemptsModal');
    };
    TopBarComponent.prototype.showLinkedAccounts = function () {
        abp.event.trigger('app.show.linkedAccountsModal');
    };
    TopBarComponent.prototype.changePassword = function () {
        abp.event.trigger('app.show.changePasswordModal');
    };
    TopBarComponent.prototype.changeProfilePicture = function () {
        abp.event.trigger('app.show.changeProfilePictureModal');
    };
    TopBarComponent.prototype.changeMySettings = function () {
        abp.event.trigger('app.show.mySettingsModal');
    };
    TopBarComponent.prototype.logout = function () {
        this._authService.startLogout();
        //this._authService.logout();
    };
    TopBarComponent.prototype.onMySettingsModalSaved = function () {
        this.shownLoginName = this.appSession.getShownLoginName();
    };
    TopBarComponent.prototype.backToMyAccount = function () {
        //this._impersonationService.backToImpersonator();
    };
    TopBarComponent.prototype.switchToLinkedUser = function (linkedUser) {
        this._linkedAccountService.switchToAccount(linkedUser.id, linkedUser.tenantId);
    };
    Object.defineProperty(TopBarComponent.prototype, "chatEnabled", {
        get: function () {
            return (!this._abpSessionService.tenantId || this.feature.isEnabled('App.ChatFeature'));
        },
        enumerable: true,
        configurable: true
    });
    TopBarComponent.prototype.downloadCollectedData = function () {
        var _this = this;
        this._profileServiceProxy.prepareCollectedData().subscribe(function () {
            _this.message.success(_this.l('GdprDataPrepareStartedNotification'));
        });
    };
    TopBarComponent.prototype.openpopup = function () {
        this.configModal.class = 'mw-400px mt-48';
        this.configModal.ignoreBackdropClick = false;
        this.bsModalRef = this.modalService.show(SupportModalComponent, this.configModal);
    };
    TopBarComponent.prototype.getClr = function () {
        return '#4fc2c0 !important';
    };
    return TopBarComponent;
}(AppComponentBase));
export { TopBarComponent };
