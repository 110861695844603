import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { XmlHttpRequestHelper } from '@shared/helpers/XmlHttpRequestHelper';
import * as moment from 'moment';
import { HttpMethodBaseService } from './HttpMethodsBase/httpmethods-base';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class QuoteStatusService {

    readonly API_BASE_URL_IDP = abp.setting.values['PortalSettings.authority'];

    constructor(
        private _authService: AuthService,
        private httpMethodBaseService: HttpMethodBaseService
    ) {}

    pushMessages(quoteGuids: Array<string>, brokerGuid: string, status: number, isApproved: boolean = false): void {
        let data = {
            'quoteGuids' : quoteGuids,
            'status' : status,
            'timeStamp' : moment(),
            'isApproved' : isApproved,
            'accessToken': this._authService.user.access_token,
            'brokerGuid': brokerGuid
            };

        this.httpMethodBaseService.post<any>(AppConsts.remoteServiceBaseUrl + '/api/services/app/QuoteStatus/PushMessages', JSON.stringify(data), new HttpHeaders({'Content-Type': 'application/json'}))
        .pipe((res => {
            return res;
        })).subscribe(result => {});
    }
}
