import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-action-modal',
    templateUrl: './action-modal.component.html',
    styleUrls: ['./action-modal.component.css']
})
export class ActionModalComponent implements OnInit {
    title: string;
    closeBtnName: string;
    list: any[] = [];

    haveYouToldUsEverything: boolean;
    withToggle: boolean;

    icon: string;
    @Output() actionEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    message: string;
    messages: string[];
    actionButtonName: string;
    textAlignLeft = false;
    constructor(public bsModalRef: BsModalRef) { }

    ngOnInit() {
    }

    close() {


        this.actionEvent.next(this.actionButtonName ? false : true);
        this.bsModalRef.hide();
    }

    submit() {
        if (this.withToggle) {
            this.actionEvent.next(this.haveYouToldUsEverything);
            this.bsModalRef.hide();
            return;
        }
        this.actionEvent.next(true);
        this.bsModalRef.hide();
    }

}
