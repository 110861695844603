import * as i0 from "@angular/core";
var MonthPipeService = /** @class */ (function () {
    function MonthPipeService() {
        this.monthArray = [];
    }
    MonthPipeService.prototype.transform = function (items) {
        var _this = this;
        this.monthArray = new Array();
        if (items == null || items.length <= 0) {
            return null;
        }
        items.forEach(function (item) {
            switch (item) {
                case 'Jan':
                    _this.monthArray.push('1');
                    break;
                case 'Feb':
                    _this.monthArray.push('2');
                    break;
                case 'Mar':
                    _this.monthArray.push('3');
                    break;
                case 'Apr':
                    _this.monthArray.push('4');
                    break;
                case 'May':
                    _this.monthArray.push('5');
                    break;
                case 'Jun':
                    _this.monthArray.push('6');
                    break;
                case 'Jul':
                    _this.monthArray.push('7');
                    break;
                case 'Aug':
                    _this.monthArray.push('8');
                    break;
                case 'Sep':
                    _this.monthArray.push('9');
                    break;
                case 'Oct':
                    _this.monthArray.push('10');
                    break;
                case 'Nov':
                    _this.monthArray.push('11');
                    break;
                case 'Dec':
                    _this.monthArray.push('12');
                    break;
            }
        });
        return this.monthArray;
    };
    MonthPipeService.ngInjectableDef = i0.defineInjectable({ factory: function MonthPipeService_Factory() { return new MonthPipeService(); }, token: MonthPipeService, providedIn: "root" });
    return MonthPipeService;
}());
export { MonthPipeService };
