import { Component, OnInit, NgZone, Injector } from '@angular/core';
import { MyWearablesService } from '@app/shared/services/my-wearables.service';
import { SmsBody } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'app-widget-my-synced-activities',
  templateUrl: './widget-my-synced-activities.component.html',
  styleUrls: ['./widget-my-synced-activities.component.css']
})
export class WidgetMySyncedActivitiesComponent extends AppComponentBase implements OnInit {
  isLoading: boolean;
  currentUserQuery: string;
  imgSrc: any = '../../../assets/common/images/chatblack.svg';

  constructor(private zone: NgZone, injector: Injector, public _wearableService: MyWearablesService) {
    super(injector);
  }

  ngOnInit() {
    if (!this._wearableService.getAllSyncedAct || this._wearableService.getAllSyncedAct.length === 0) {
      this.isLoading = true;
    }
  }

  getAllSyncedIntenseCount() {
    this.isLoading = true;
    this._wearableService.getAllSyncedIntenseCount().then(() => {
      this.isLoading = false;
    });
  }

  sendsms(PageName?) {
    let body = new SmsBody();
    body.userid = this._wearableService._commonHelper._user.profile.sub;
    body.firstName = this._wearableService._commonHelper._user.profile.given_name;
    body.lastName = this._wearableService._commonHelper._user.profile.family_name;
    body.email = this._wearableService._commonHelper._user.profile.email;
    body.phoneNumber = Array.isArray(this._wearableService._commonHelper._user.profile.phone_number) ? this._wearableService._commonHelper._user.profile.phone_number[0] : this._wearableService._commonHelper._user.profile.phone_number;
    body.pageName = PageName;
    body.message = this.currentUserQuery;
    this.sendQueryEmail(body);
    this.currentUserQuery = '';
  }
}
