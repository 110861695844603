var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfilePictureDto, UserServiceProxy, ModifyClaimDto, ClaimModifyServiceProxy } from '@shared/service-proxies/service-proxies';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
var ChangeProfilePictureModalComponent = /** @class */ (function (_super) {
    __extends(ChangeProfilePictureModalComponent, _super);
    function ChangeProfilePictureModalComponent(injector, _userService, _claims) {
        var _this = _super.call(this, injector) || this;
        _this._userService = _userService;
        _this._claims = _claims;
        _this.active = false;
        _this.saving = false;
        _this.maxProfilPictureBytesUserFriendlyValue = 5;
        _this._uploaderOptions = {};
        _this.profileChange = new ProfilePictureDto();
        _this.imageChangedEvent = '';
        _this.inValidExt = false;
        return _this;
    }
    ChangeProfilePictureModalComponent.prototype.initializeModal = function () {
        this.active = true;
        this.temporaryPictureUrl = '';
        this.temporaryPictureFileName = '';
        this.inValidExt = false;
        this.profilePicture = '';
        this.getProfilePicture();
    };
    ChangeProfilePictureModalComponent.prototype.show = function () {
        this.initializeModal();
        this.modal.show();
    };
    ChangeProfilePictureModalComponent.prototype.close = function () {
        this.active = false;
        this.imageChangedEvent = '';
        this.modal.hide();
    };
    ChangeProfilePictureModalComponent.prototype.getProfilePicture = function () {
        var _this = this;
        this._authGuard.GetUserInfo(function (res) {
            _this.profilePicture = res.picture;
            if (_this.profilePicture === undefined || _this.profilePicture == null || _this.profilePicture === '') {
                _this.profilePicture = '';
            }
        });
    };
    ChangeProfilePictureModalComponent.prototype.fileChangeEvent = function (event) {
        this.imageChangedEvent = event;
        if (this.imageChangedEvent.target.files.length > 0) {
            var selectedFile = this.imageChangedEvent.target.files[0];
            var idxDot = selectedFile.name.lastIndexOf('.') + 1;
            var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png' || extFile === 'svg') {
                this.inValidExt = false;
            }
            else {
                this.imageChangedEvent = '';
                this.inValidExt = true;
            }
        }
        else {
            this.imageChangedEvent = '';
            this.inValidExt = false;
        }
    };
    ChangeProfilePictureModalComponent.prototype.save = function () {
        var _this = this;
        this.saving = true;
        this.profileChange.fileName = this._authGuard.user.profile.sub;
        this.profileChange.claimData = new ModifyClaimDto();
        this.profileChange.claimData.accessToken = this._authService.user.access_token;
        this.profileChange.claimData.oldClaimType = 'picture';
        this.profileChange.claimData.newClaimType = 'picture';
        this.profileChange.claimData.userId = this._authGuard.user.profile.sub;
        this.profileChange.claimData.oldClaimValue = this._authGuard.user.profile.picture;
        this._userService.updateProfilePicture(this.profileChange)
            .pipe(finalize(function () { _this.saving = false; }))
            .subscribe(function (res) {
            if (res) {
                abp.event.trigger('profilePictureChanged');
                _this.close();
                abp.notify.success('Profile Picture Changed');
            }
            else {
                _this.close();
                abp.notify.error('Profile Picture not changed.');
            }
        }, function (err) {
            abp.event.trigger('profilePictureChanged');
            _this.close();
        });
    };
    ChangeProfilePictureModalComponent.prototype.remove = function () {
        var _this = this;
        if (this.imageChangedEvent) {
            this.imageChangedEvent.srcElement.value = '';
            this.imageChangedEvent = '';
        }
        else if (this.profilePicture) {
            this.profileChange.claimData = new ModifyClaimDto();
            this.profileChange.claimData.accessToken = this._authService.user.access_token;
            this.profileChange.claimData.oldClaimType = 'picture';
            this.profileChange.claimData.newClaimType = 'picture';
            this.profileChange.claimData.userId = this._authGuard.user.profile.sub;
            this.profileChange.claimData.oldClaimValue = this._authGuard.user.profile.picture;
            this.profileChange.claimData.newClaimValue = '';
            this._claims.modifyClaim(this.profileChange.claimData).subscribe(function (res) {
                _this.modal.hide();
                abp.event.trigger('profilePictureChanged');
                abp.notify.success('Profile Picture Removed');
            });
        }
    };
    ChangeProfilePictureModalComponent.prototype.fileUpload = function (file) {
        var _this = this;
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.profileChange.base64 = e.target.result;
        };
        reader.readAsDataURL(file);
    };
    return ChangeProfilePictureModalComponent;
}(AppComponentBase));
export { ChangeProfilePictureModalComponent };
