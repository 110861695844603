var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
var ChatSignalrService = /** @class */ (function (_super) {
    __extends(ChatSignalrService, _super);
    function ChatSignalrService(injector, _zone) {
        var _this = _super.call(this, injector) || this;
        _this._zone = _zone;
        _this.isChatConnected = false;
        return _this;
    }
    ChatSignalrService.prototype.configureConnection = function (connection) {
        var _this = this;
        // Set the common hub
        this.chatHub = connection;
        // Reconnect if hub disconnects
        connection.onclose(function (e) {
            _this.isChatConnected = false;
            if (e) {
                abp.log.debug('Chat connection closed with error: ' + e);
            }
            else {
                abp.log.debug('Chat disconnected');
            }
            if (!abp.signalr.autoConnect) {
                return;
            }
            setTimeout(function () {
                connection.start().then(function (result) {
                    _this.isChatConnected = true;
                });
            }, 5000);
        });
        // Register to get notifications
        this.registerChatEvents(connection);
    };
    ChatSignalrService.prototype.registerChatEvents = function (connection) {
        connection.on('getChatMessage', function (message) {
            abp.event.trigger('app.chat.messageReceived', message);
        });
        connection.on('getAllFriends', function (friends) {
            abp.event.trigger('abp.chat.friendListChanged', friends);
        });
        connection.on('getFriendshipRequest', function (friendData, isOwnRequest) {
            abp.event.trigger('app.chat.friendshipRequestReceived', friendData, isOwnRequest);
        });
        connection.on('getUserConnectNotification', function (friend, isConnected) {
            abp.event.trigger('app.chat.userConnectionStateChanged', {
                friend: friend,
                isConnected: isConnected
            });
        });
        connection.on('getUserStateChange', function (friend, state) {
            abp.event.trigger('app.chat.userStateChanged', {
                friend: friend,
                state: state
            });
        });
        connection.on('getallUnreadMessagesOfUserRead', function (friend) {
            abp.event.trigger('app.chat.allUnreadMessagesOfUserRead', {
                friend: friend
            });
        });
        connection.on('getReadStateChange', function (friend) {
            abp.event.trigger('app.chat.readStateChange', {
                friend: friend
            });
        });
    };
    ChatSignalrService.prototype.sendMessage = function (messageData, callback) {
        if (!this.isChatConnected) {
            if (callback) {
                callback();
            }
            abp.notify.warn(this.l('ChatIsNotConnectedWarning'));
            return;
        }
        this.chatHub.invoke('sendMessage', messageData).then(function (result) {
            if (result) {
                abp.notify.warn(result);
            }
            if (callback) {
                callback();
            }
        }).catch(function (error) {
            abp.log.error(error);
            if (callback) {
                callback();
            }
        });
    };
    ChatSignalrService.prototype.init = function () {
        var _this = this;
        this._zone.runOutsideAngular(function () {
            abp.signalr.connect();
            abp.signalr.startConnection(abp.appPath + 'signalr-chat', function (connection) {
                abp.event.trigger('app.chat.connected');
                _this.isChatConnected = true;
                _this.configureConnection(connection);
            });
        });
    };
    return ChatSignalrService;
}(AppComponentBase));
export { ChatSignalrService };
