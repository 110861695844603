<div class="m-portlet">
  <div class="m-portlet__head">
      <div class="m-portlet__head-caption head-text-display w-100">
          <div class="col">
              <div class="m--space-20"></div>
              <div class="m-portlet__head-title d-flex flex-row justify-content-between align-items-center">
                  <h3 class="m-portlet__head-text font-weight-bold">
                      {{"activemininfo" | localize}}
                  </h3>

                  <i *ngIf="isLoading" class="fa fa-circle-notch fa-spin loader"></i>
              </div>
              <div class="m--space-10"></div>
              <h6 class="m-portlet__head-desc font-weight-light m--regular-font-size-sm3">
                  {{"YourRecordedMinutesOf" | localize}} {{showchart}}.
              </h6>
          </div>
      </div>
  </div>
  <div class="m-portlet__body m-portlet__body--no-top-padding">
      <div class="row">
          <div class="col-md-6 col-md-12">
              <div id="activeminute" class="min-height-200 font-weight-bold m--regular-font-size-sm5">
              </div>
              <!--<span class="float-md-right black-clr font-weight-bold">{{"SeeMore"|localize}}</span>-->
          </div>
      </div>
  </div>
</div>