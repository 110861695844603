var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileServiceProxy, VerifySmsCodeInputDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
var SmsVerificationModalComponent = /** @class */ (function (_super) {
    __extends(SmsVerificationModalComponent, _super);
    function SmsVerificationModalComponent(injector, _profileService) {
        var _this = _super.call(this, injector) || this;
        _this._profileService = _profileService;
        _this.modalSave = new EventEmitter();
        _this.active = false;
        _this.saving = false;
        _this.verifyCode = new VerifySmsCodeInputDto();
        return _this;
    }
    SmsVerificationModalComponent.prototype.show = function () {
        this.active = true;
        this.modal.show();
    };
    SmsVerificationModalComponent.prototype.close = function () {
        this.active = false;
        this.modal.hide();
    };
    SmsVerificationModalComponent.prototype.save = function () {
        this.saving = true;
        //this._profileService.verifySmsCode(this.verifyCode)
        //    .pipe(finalize(() => { this.saving = false; }))
        //    .subscribe(() => {
        //        this.close();
        //        this.modalSave.emit(null);
        //    });
    };
    return SmsVerificationModalComponent;
}(AppComponentBase));
export { SmsVerificationModalComponent };
