import { Component, OnInit, NgZone, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MyWearablesService } from '@app/shared/services/my-wearables.service';

@Component({
  selector: 'app-widget-last-seven-days',
  templateUrl: './widget-last-seven-days.component.html',
  styleUrls: ['./widget-last-seven-days.component.css']
})
export class WidgetLastSevenDaysComponent extends AppComponentBase implements OnInit {
  isLoading: boolean;

  constructor(private zone: NgZone, injector: Injector, public _wearableService: MyWearablesService) {
    super(injector);
  }

  ngOnInit() {
    if (!this._wearableService.last7Days || !this._wearableService.last7Days.mstact) {
      this.isLoading = true;
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  getLast7Async() {
    this._wearableService.getLast7Async().then(() => {
      this.isLoading = false;
    });
  }

}
