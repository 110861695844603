<div class="modal-dialog modal-lg mt-0 mb-0">

    <div class="modal-content">

        <form>

            <div class="modal-header d-flex flex-row align-items-center">

                <h5 class="modal-title">
                    BEFORE WE GET STARTED,<br>A FEW GENERAL QUESTIONS
                </h5>

                <button type="button" class="close" (click)="redirect()">

                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body pb-0">

                <div class="row" *ngFor="let intro of currentQuestionnaire?.intros">

                    <div class="col-lg-10 col-md-6">

                        <div class="m-portlet">

                            <div class="m-portlet__head portlet-head-layout h-100">

                                <div class="m-portlet__head-caption head-text-display pt-4 pl-2 pr-2 pb-4">

                                    <div class="m-portlet__head-title">

                                        <h3 class="c-dark-maroon m-portlet__head-text font-weight-bold mb-3">
                                            {{intro.title | uppercase}}
                                        </h3>
                                    </div>

                                    <p>
                                        {{intro.description}}
                                    </p>

                                    <b>{{intro.footer}}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngFor="let phase of phases">

                    <div *ngIf="phase.title" class="mb-2">

                        <div class="h3 gradient-text">
                            {{phase.title}}
                        </div>

                        <div class="h5 font-weight-bold" style="white-space: pre-line" *ngIf="phase.subtitle">
                            {{phase.subtitle}}
                        </div>
                    </div>

                    <div class="row">

                        <div *ngFor="let question of getQuestionsInPhase(phase); let i = index;" [ngClass]=""
                            class="mb-25px col-md-6 col-sm-12">

                            <div class="font-weight-bold mb-1">
                                {{question.title}}
                            </div>

                            <div class="row">

                                <div class="col-12">

                                    <div *ngIf="question.type === questionTypeConsts.SINGLE_SELECT || question.type === questionTypeConsts.MULTI_SELECT"
                                        class="d-flex flex-column align-items-start font-size-11 level-layout h-100 m-input--pill font-weight-bold form-control text-left pb-0">

                                        <div *ngFor="let answer of question.answers;"
                                            class="d-flex flex-row align-items-center justify-content-center mb-2">

                                            <div [@bouncein]="'idle-bounceIn'"
                                                class="text-right custom-control custom-radio d-flex"
                                                *ngIf="question.type === questionTypeConsts.SINGLE_SELECT">

                                                <input [id]="question.key + '_' + answer.title" [name]="question.key"
                                                    [value]="answer" type="radio" class="custom-control-input"
                                                    [(ngModel)]="question.answersGiven[0]"
                                                    (change)="checkTriggers(question)">

                                                <label [for]="question.key + '_' + answer.title"
                                                    class="custom-control-label black-clr  d-flex font-weight-bold float-left">
                                                    {{answer.title}}
                                                </label>
                                            </div>

                                            <div [@bouncein]="'idle-bounceIn'"
                                                class="text-right custom-control custom-radio d-flex"
                                                *ngIf="question.type === questionTypeConsts.MULTI_SELECT">

                                                <input [id]="question.key + '_' + answer.title"
                                                    [name]="question.key + '_' + answer.title" [value]="answer"
                                                    type="checkbox" class="custom-control-input"
                                                    (change)="onMultipleAnswerChange(answer, question); checkTriggers(question)"
                                                    [checked]="isAnswerPresent(answer, question)">

                                                <label [for]="question.key + '_' + answer.title"
                                                    class="custom-control-label black-clr  d-flex font-weight-bold float-left">
                                                    {{answer.title}}
                                                </label>
                                            </div>
                                        </div>

                                        <input [@bouncein]="'idle-bounceIn'" type="text"
                                            class="form-control level-layout mb-4" placeholder="Please specify"
                                            #otherInput
                                            [value]="getTextInputAnswer(question, { title: 'Other', value: null})"
                                            (input)="setTextInputAnswer(otherInput.value, question, { title: 'Other', value: null}); checkTriggers(question)"
                                            *ngIf="question.type === questionTypeConsts.MULTI_SELECT && isAnswerPresent({ title: 'Other', value: null}, question)">
                                    </div>

                                    <div class="d-flex font-size-11 font-weight-bold h-100"
                                        *ngIf="question.type === questionTypeConsts.NUMBER_INPUT">

                                        <div *ngFor="let answer of question.answers;"
                                            class="d-flex flex-row align-items-center justify-content-center mb-2 w-100">

                                            <input [@bouncein]="'idle-bounceIn'" type="text" #numberInput
                                                class="level-layout shadow form-control"
                                                [id]="question.key + '_' + answer.title"
                                                [name]="question.key + '_' + answer.title" type="number" required
                                                [value]="getTextInputAnswer(question, answer)"
                                                (input)="setTextInputAnswer(+numberInput.value, question, answer); checkTriggers(question)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">

                <button [disabled]="!allQuestionsAnswered()"
                    class="btn bg-dark-maroon position-relative black m-btn--pill text-white font-weight-bold" (click)="submit()"
                    [buttonBusy]="isLoading" [busyText]="l('SavingWithThreeDot')">

                    <small class="font-weight-bold">
                        CONTINUE
                    </small>
                </button>
            </div>
        </form>
    </div>
</div>