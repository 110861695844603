<div bsModal #myhealthmodal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myhealthmodal"
    aria-hidden="true" [config]="{backdrop: 'static'}">

    <div class="modal-dialog modal-body-clr modal-md">
        <div class="modal-content">
            <form *ngIf="active" #healthForm="ngForm" class="m-login__form m-form login-form" novalidate
                (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h5 class="modal-title">
                        MY HEALTHCARE
                    </h5>
                </div>

                <div class="modal-body">

                    <div class="d-flex flex-row align-items-center">
                        Please enter your:
                    </div>

                    <div class="m--space-20"></div>

                    <div class="d-flex flex-row align-items-center">

                        <label for="HealthId" class="mb-0 w-35">Medical scheme name: </label>

                        <div class="col-md-7">
                            <select style="padding: 0" id="HealthId" #HealthId="ngModel" name="HealthId"
                                [(ngModel)]="schemename" class="form-control m-input input-for-modal" required>
                                <option *ngFor="let medicalScheme of medicalSchemes"
                                    [value]="medicalScheme.medicalSchemeName">{{medicalScheme.medicalSchemeName}}
                                </option>
                            </select>
                            <validation-messages [formCtrl]="HealthId"></validation-messages>
                        </div>
                    </div>
                    <div class="m--space-20"></div>
                    <div class="d-flex flex-row align-items-center">

                        <label for="DependentID" class="mb-0 w-35">Membership no: </label>

                        <div class="col-md-7">
                            <input id="DependentID" style="padding-left: 6px" type="text" #DependentId="ngModel"
                                name="DependentID" [(ngModel)]="MemberShipNo"
                                class="form-control m-input input-for-modal" maxlength="24" required>
                            <validation-messages [formCtrl]="DependentId"></validation-messages>
                        </div>
                    </div>
                    <div class="m--space-20"></div>
                    <div class="d-flex flex-row align-items-center">
                        <label for="IdNumber" class="mb-0 w-35">Please enter your SA ID no: </label>
                        <div class="col-md-7">
                            <input id="IdNumber" style="padding-left: 6px" type="text" type="number"
                                #idNumberInput="ngModel" name="IdNumberIrd" [(ngModel)]="idNumber"
                                class="form-control m-input input-for-modal" required maxlength="24">
                            <validation-messages [formCtrl]="idNumberInput"></validation-messages>

                        </div>
                    </div>

                    <div class="form-group m-form__group mt-3">
                        <label class="m-checkbox m-checkbox--air m-checkbox">
                            <input type="checkbox" id="acceptTerms" #acceptTerms="ngModel" name="acceptTerms"
                                [(ngModel)]="checkedTf" autocomplete="off" required aria-required="true">
                            I agree to the <a target="_blank"
                                href="http://www.elevate.co.za/terms-and-conditions/">terms and conditions.</a>
                            <span></span>
                            <span></span>
                            <validation-messages [formCtrl]="acceptTerms"></validation-messages>
                        </label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" (click)="close()"><span>CANCEL</span></button>
                    <button type="submit" class="btn btn-danger" [disabled]="!healthForm.form.valid"
                        [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><span>ACTIVATE</span></button>
                </div>
            </form>
        </div>
    </div>
</div>