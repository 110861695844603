import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyWearablesComponent } from './my-wearables.component';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      children: [
        { path: '', component: MyWearablesComponent, canActivate: [AuthGuard] }
      ]
    }
  ])],
  exports: [RouterModule]
})
export class MyWearablesRoutingModule { }
