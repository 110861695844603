var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, NgZone, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MyWearablesService } from '@app/shared/services/my-wearables.service';
var WidgetMyActiveMinutesComponent = /** @class */ (function (_super) {
    __extends(WidgetMyActiveMinutesComponent, _super);
    function WidgetMyActiveMinutesComponent(zone, injector, _wearableService) {
        var _this = _super.call(this, injector) || this;
        _this.zone = zone;
        _this._wearableService = _wearableService;
        _this.showchart = 'week';
        _this.chartXAxistest = 'LAST 7 DAYS';
        return _this;
    }
    WidgetMyActiveMinutesComponent.prototype.ngOnInit = function () {
        if (!this._wearableService.activeMinues || this._wearableService.activeMinues.length === 0) {
            this.isLoading = true;
        }
    };
    WidgetMyActiveMinutesComponent.prototype.getActiveMinutesWeekOrMonth = function (chartXAxistest, showchart) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.showchart = showchart;
            _this.chartXAxistest = chartXAxistest;
            _this.isLoading = true;
            _this._wearableService.getActiveMinutesWeek().then(function () {
                _this.MyActiveMin();
                _this.isLoading = false;
                resolve();
            });
        });
    };
    WidgetMyActiveMinutesComponent.prototype.getActiveMinutesYear = function (chartXAxistest, showchart) {
        var _this = this;
        this.showchart = showchart;
        this.chartXAxistest = chartXAxistest;
        this.isLoading = true;
        this._wearableService.getActiveMinutesYear().then(function () {
            _this.MyActiveMin();
            _this.isLoading = false;
        });
    };
    WidgetMyActiveMinutesComponent.prototype.MyActiveMin = function () {
        var _this = this;
        this.zone.runOutsideAngular(function () {
            Promise.all([
                import('@amcharts/amcharts4/core'),
                import('@amcharts/amcharts4/charts'),
                import('@amcharts/amcharts4/themes/animated')
            ])
                .then(function (modules) {
                var am4core = modules[0];
                var am4charts = modules[1];
                var am4themes_animated = modules[2].default;
                am4core.options.commercialLicense = true;
                am4core.useTheme(am4themes_animated);
                var chart = am4core.create('activeminute', am4charts.XYChart);
                chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
                chart.dateFormatter.inputDateFormat = 'YYYY-MM-dd';
                _this._wearableService.activeMinues.forEach(function (x) {
                    chart.data.push({
                        date: x.startTime.format('YYYY-MM-DD'),
                        price: Math.round(x.duration / 60)
                    });
                });
                var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                if (_this.showchart === 'week') {
                    dateAxis.renderer.grid.template.strokeOpacity = 0;
                    dateAxis.renderer.minGridDistance = 20;
                    dateAxis.dateFormats.setKey('day', 'd');
                    dateAxis.tooltip.hiddenState.properties.opacity = 1;
                    dateAxis.tooltip.hiddenState.properties.visible = true;
                    dateAxis.tooltip.adapter.add('x', function (x, target) {
                        return am4core.utils.spritePointToSvg({ x: chart.plotContainer.pixelX, y: 0 }, chart.plotContainer).x - 12 + chart.plotContainer.pixelWidth / 2;
                    });
                }
                else if (_this.showchart === 'month') {
                    dateAxis.renderer.minGridDistance = 100;
                    dateAxis.periodChangeDateFormats.setKey('month', '[bold]MMM[/]');
                    dateAxis.renderer.grid.template.disabled = true;
                    dateAxis.renderer.grid.template.location = 0;
                }
                else if (_this.showchart === 'year') {
                    dateAxis.renderer.minGridDistance = 10;
                    dateAxis.periodChangeDateFormats.setKey('month', '[bold]yyyy[/]');
                    dateAxis.renderer.grid.template.disabled = true;
                }
                else {
                    dateAxis.renderer.grid.template.disabled = true;
                    dateAxis.renderer.grid.template.location = 0;
                    dateAxis.renderer.tooltip.disabled = true;
                    dateAxis.renderer.ticks.template.disabled = true;
                }
                dateAxis.title.align = 'left';
                dateAxis.title.text = _this.chartXAxistest;
                dateAxis.renderer.line.strokeOpacity = 1;
                dateAxis.renderer.line.strokeWidth = 2;
                dateAxis.renderer.line.stroke = am4core.color('#FFB822');
                dateAxis.title.dy = 12;
                dateAxis.title.paddingLeft = 25;
                var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                if (_this.showchart === 'week') {
                    valueAxis.renderer.inside = true;
                    valueAxis.renderer.labels.template.fillOpacity = 0.3;
                    valueAxis.renderer.grid.template.strokeOpacity = 0;
                    valueAxis.cursorTooltipEnabled = false;
                }
                valueAxis.renderer.grid.template.disabled = true;
                valueAxis.renderer.labels.template.disabled = true;
                valueAxis.title.text = 'MINUTES';
                valueAxis.renderer.line.strokeOpacity = 1;
                valueAxis.renderer.line.strokeWidth = 2;
                valueAxis.renderer.line.stroke = am4core.color('#FFB822');
                valueAxis.title.dx = -12;
                // Create series
                var series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = 'price';
                series.dataFields.dateX = 'date';
                series.columns.template.tooltipText = '{valueY}';
                series.columns.template.fill = am4core.color('#FCBB33');
                series.tooltip.getFillFromObject = false;
                series.tooltip.background.fill = am4core.color('black');
                series.stroke = am4core.color('#FCBB33');
                series.sequencedInterpolation = true;
                if (_this.showchart === 'week') {
                    series.tooltip.pointerOrientation = 'vertical';
                    series.tooltip.hiddenState.properties.opacity = 1;
                    series.tooltip.hiddenState.properties.visible = true;
                    series.tooltip.adapter.add('x', function (x, target) {
                        return am4core.utils.spritePointToSvg({ x: chart.plotContainer.pixelX, y: 0 }, chart.plotContainer).x - 12 + chart.plotContainer.pixelWidth / 2;
                    });
                }
                var columnTemplate = series.columns.template;
                columnTemplate.width = 10;
                columnTemplate.strokeOpacity = 0;
                columnTemplate.column.cornerRadiusTopLeft = 50;
                columnTemplate.column.cornerRadiusTopRight = 50;
                chart.cursor = new am4charts.XYCursor();
                if (_this.showchart === 'week') {
                    chart.cursor.behavior = 'panX';
                    chart.cursor.lineX.disabled = true;
                    chart.zoomOutButton.disabled = true;
                    chart.events.on('datavalidated', function () {
                        var y = this._wearableService.activeMinues[this._wearableService.activeMinues.length - 1].startTime.year();
                        var m = this._wearableService.activeMinues[this._wearableService.activeMinues.length - 1].startTime.month();
                        var d = this._wearableService.activeMinues[this._wearableService.activeMinues.length - 1].startTime.date();
                        var subdays = this._wearableService.activeMinues[this._wearableService.activeMinues.length - 1].startTime.subtract(6, 'days');
                        var y1 = subdays.year();
                        var m1 = subdays.month();
                        var d1 = subdays.date();
                        dateAxis.zoomToDates(new Date(y1, m1, d1), new Date(y, m, d + 1), false, true);
                    }, _this);
                    var middleLine = chart.plotContainer.createChild(am4core.Line);
                    middleLine.strokeOpacity = 1;
                    middleLine.stroke = am4core.color('#000000');
                    middleLine.strokeDasharray = '2,2';
                    middleLine.align = 'center';
                    middleLine.zIndex = 1;
                    middleLine.adapter.add('y2', function (y2, target) {
                        return target.parent.pixelHeight;
                    });
                    chart.cursor.events.on('cursorpositionchanged', updateTooltip);
                    dateAxis.events.on('datarangechanged', updateTooltip);
                }
                else {
                    chart.zoomOutButton.disabled = true;
                    chart.cursor.behavior = 'none';
                }
                function updateTooltip() {
                    dateAxis.showTooltipAtPosition(0.5);
                    series.showTooltipAtPosition(0.5, 0);
                    series.tooltip.validate(); // otherwise will show other columns values for a second
                }
            })
                .catch(function (e) {
                console.error('Error when creating chart', e);
            });
        });
    };
    return WidgetMyActiveMinutesComponent;
}(AppComponentBase));
export { WidgetMyActiveMinutesComponent };
