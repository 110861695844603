import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../service-proxies/service-proxies";
import * as i2 from "@angular/common/http";
import * as i3 from "../../app/shared/common/IDSVRAuthProviders/auth.service";
var FacebookService = /** @class */ (function () {
    function FacebookService(baseUrl, http, authService) {
        this.baseUrl = baseUrl;
        this.http = http;
        this.authService = authService;
    }
    FacebookService.prototype.logEvent = function (eventName, params) {
        if (params === void 0) { params = null; }
        if (params) {
            fbq('trackCustom', eventName, params);
        }
        else {
            fbq('trackCustom', eventName);
        }
        var accessToken = this.authService && this.authService.user && this.authService.user.access_token ? this.authService.user.access_token : null;
        this.http.post(this.baseUrl + '/api/services/app/Facebook/Conversion', { eventName: eventName, accessToken: accessToken }).subscribe(function (result) {
        });
    };
    FacebookService.ngInjectableDef = i0.defineInjectable({ factory: function FacebookService_Factory() { return new FacebookService(i0.inject(i1.API_BASE_URL), i0.inject(i2.HttpClient), i0.inject(i3.AuthService)); }, token: FacebookService, providedIn: "root" });
    return FacebookService;
}());
export { FacebookService };
