import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-decline-modal',
    templateUrl: './decline-modal.component.html',
    styleUrls: ['./decline-modal.component.css']
})
export class DeclineModalComponent implements OnInit {

    modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public bsModalRef: BsModalRef) { }

    ngOnInit() {
    }


    close() {

        this.modalClose.next(true);

        this.bsModalRef.hide();
    }

}
