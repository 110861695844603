var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Injector, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppNavigationService } from './app-navigation.service';
import * as objectPath from 'object-path';
import { filter } from 'rxjs/operators';
import { MenuHorizontalDirective } from '@metronic/app/core/directives/menu-horizontal.directive';
import { MenuHorizontalOffcanvasDirective } from '@metronic/app/core/directives/menu-horizontal-offcanvas.directive';
var TopBarMenuComponent = /** @class */ (function (_super) {
    __extends(TopBarMenuComponent, _super);
    function TopBarMenuComponent(injector, router, permission, _appNavigationService) {
        var _this = _super.call(this, injector) || this;
        _this.router = router;
        _this.permission = permission;
        _this._appNavigationService = _appNavigationService;
        _this.menu = null;
        _this.currentRouteUrl = '';
        return _this;
    }
    TopBarMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.menu = this._appNavigationService.getMenu();
        this.currentRouteUrl = this.router.url;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this.currentRouteUrl = _this.router.url;
        });
    };
    TopBarMenuComponent.prototype.ngAfterViewInit = function () {
        this.mMenuHorOffcanvas = new MenuHorizontalOffcanvasDirective(this.el);
        this.mMenuHorOffcanvas.ngAfterViewInit();
        this.mMenuHorizontal = new MenuHorizontalDirective(this.el);
        this.mMenuHorizontal.ngAfterViewInit();
    };
    TopBarMenuComponent.prototype.showMenuItem = function (menuItem) {
        return this._appNavigationService.showMenuItem(menuItem);
    };
    TopBarMenuComponent.prototype.getItemCssClasses = function (item, parentItem, depth) {
        var isRootLevel = item && !parentItem;
        var cssClasses = 'm-menu__item';
        if (objectPath.get(item, 'items.length') || this.isRootTabMenuItemWithoutChildren(item, isRootLevel)) {
            cssClasses += ' m-menu__item--submenu';
        }
        if (objectPath.get(item, 'icon-only')) {
            cssClasses += ' m-menu__item--icon-only';
        }
        if (this.isMenuItemIsActive(item)) {
            cssClasses += ' m-menu__item--active';
            if (this.isTabMenuUsed && isRootLevel) {
                cssClasses += ' m-menu__item--hover';
            }
        }
        if (this.isTabMenuUsed && isRootLevel) {
            cssClasses += ' m-menu__item--tabs';
        }
        if (this.isTabMenuUsed && !isRootLevel && item.items.length) {
            cssClasses += ' m-menu__item--submenu m-menu__item--rel';
            if (depth && depth === 1) {
                cssClasses += ' m-menu__item--submenu-tabs m-menu__item--open-dropdown m-menu__item--hover';
            }
        }
        else if (!this.isTabMenuUsed && item.items.length) {
            if (depth && depth >= 1) {
                cssClasses += ' m-menu__item--submenu';
            }
            else {
                cssClasses += ' m-menu__item--rel';
            }
        }
        return cssClasses;
    };
    TopBarMenuComponent.prototype.getAnchorItemCssClasses = function (item, parentItem) {
        var isRootLevel = item && !parentItem;
        var cssClasses = 'm-menu__link';
        if ((this.isTabMenuUsed && isRootLevel) || item.items.length) {
            cssClasses += ' m-menu__toggle';
        }
        return cssClasses;
    };
    TopBarMenuComponent.prototype.getSubmenuCssClasses = function (item, parentItem, depth) {
        var cssClasses = 'm-menu__submenu m-menu__submenu--classic';
        if (this.isTabMenuUsed) {
            if (depth === 0) {
                cssClasses += ' m-menu__submenu--tabs';
            }
            cssClasses += ' m-menu__submenu--' + (depth >= 2 ? 'right' : 'left');
        }
        else {
            cssClasses += ' m-menu__submenu--' + (depth >= 1 ? 'right' : 'left');
        }
        return cssClasses;
    };
    TopBarMenuComponent.prototype.isRootTabMenuItemWithoutChildren = function (item, isRootLevel) {
        return this.isTabMenuUsed && isRootLevel && !item.items.length;
    };
    TopBarMenuComponent.prototype.isMenuItemIsActive = function (item) {
        if (item.items.length) {
            return this.isMenuRootItemIsActive(item);
        }
        if (!item.route) {
            return false;
        }
        return item.route === this.currentRouteUrl;
    };
    TopBarMenuComponent.prototype.isMenuRootItemIsActive = function (item) {
        var e_1, _a;
        if (item.items) {
            try {
                for (var _b = __values(item.items), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var subItem = _c.value;
                    if (this.isMenuItemIsActive(subItem)) {
                        return true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return false;
    };
    TopBarMenuComponent.prototype.getItemAttrSubmenuToggle = function (menuItem, parentItem, depth) {
        var isRootLevel = menuItem && !parentItem;
        if (isRootLevel && this.isTabMenuUsed) {
            return 'tab';
        }
        else {
            if (depth && depth >= 1) {
                return 'hover';
            }
            else {
                return 'click';
            }
        }
    };
    TopBarMenuComponent.prototype.getCssClass = function () {
        var menuCssClass = 'm-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-' + this.currentTheme.baseSettings.header.headerSkin;
        menuCssClass += ' m-header-menu--submenu-skin-light';
        menuCssClass += ' m-aside-header-menu-mobile--skin-light';
        menuCssClass += ' m-aside-header-menu-mobile--submenu-skin-light';
        if (this.currentTheme.baseSettings.layout.layoutType === 'boxed') {
            return menuCssClass + ' m-container--xxl';
        }
        return menuCssClass;
    };
    return TopBarMenuComponent;
}(AppComponentBase));
export { TopBarMenuComponent };
