var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { Product } from './Product';
import { ProductBaseFactory } from './IIncompleteProductBase';
import { AssociatedProductFactory } from '../AssociatedProductFactory/IIncomplete-associated-product';
import * as _ from 'lodash';
var ProductFactory = /** @class */ (function (_super) {
    __extends(ProductFactory, _super);
    function ProductFactory(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        return _this;
    }
    ProductFactory.prototype.withMetadataAsync = function (generationName, isSecuritasBroker, allowedDisabilityProductNames, isTestUser) {
        if (isSecuritasBroker === void 0) { isSecuritasBroker = false; }
        if (allowedDisabilityProductNames === void 0) { allowedDisabilityProductNames = []; }
        if (isTestUser === void 0) { isTestUser = false; }
        return __awaiter(this, void 0, void 0, function () {
            var isProductAllowed_1, metaData, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        isProductAllowed_1 = function (productName) {
                            var e_1, _a;
                            try {
                                for (var allowedDisabilityProductNames_1 = __values(allowedDisabilityProductNames), allowedDisabilityProductNames_1_1 = allowedDisabilityProductNames_1.next(); !allowedDisabilityProductNames_1_1.done; allowedDisabilityProductNames_1_1 = allowedDisabilityProductNames_1.next()) {
                                    var allowedProductName = allowedDisabilityProductNames_1_1.value;
                                    if (productName.toLocaleLowerCase().includes(allowedProductName)) {
                                        return true;
                                    }
                                }
                            }
                            catch (e_1_1) { e_1 = { error: e_1_1 }; }
                            finally {
                                try {
                                    if (allowedDisabilityProductNames_1_1 && !allowedDisabilityProductNames_1_1.done && (_a = allowedDisabilityProductNames_1.return)) _a.call(allowedDisabilityProductNames_1);
                                }
                                finally { if (e_1) throw e_1.error; }
                            }
                            return false;
                        };
                        return [4 /*yield*/, this.productService.getProductList(generationName).toPromise()];
                    case 1:
                        metaData = _a.sent();
                        this.metaDataOriginal = _.cloneDeep(metaData);
                        if (!isSecuritasBroker) {
                            metaData = metaData.filter(function (m) { return !m.Name.includes('SEF'); });
                        }
                        metaData = metaData.filter(function (m) { return m.Category === 'Disability Protection' || (m.Category === 'Complimentary Benefits' && m.Name.toLocaleLowerCase().includes('disability')) ? isProductAllowed_1(m.Name) : true; });
                        this.metaData = metaData;
                        return [2 /*return*/, this];
                    case 2:
                        err_1 = _a.sent();
                        abp.message.error(JSON.stringify(err_1));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductFactory.prototype.extractSemiMainProductUIDList = function () {
        try {
            if (!this || this.metaData.IsNullOrEmpty()) {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                abp.ui.clearBusy();
                return null;
            }
            this.mainProductSemiUIDList = this.metaData.filter(function (x) { return x.ProductRequirements_2 === undefined && x.Name.trim().toLowerCase().indexOf('semi') >= 0; }).map(function (_a) {
                var UID = _a.UID;
                return ({ UID: UID }.UID);
            });
            return this.mainProductSemiUIDList;
        }
        catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    };
    ProductFactory.prototype.extractFulllyMainProductUIDList = function () {
        try {
            if (!this || this.metaData.IsNullOrEmpty()) {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                abp.ui.clearBusy();
                return null;
            }
            this.mainProductFullyUIDList = this.metaData.filter(function (x) { return x.ProductRequirements_2 === undefined && x.Name.trim().toLowerCase().indexOf('semi') === -1; }).map(function (_a) {
                var UID = _a.UID;
                return ({ UID: UID }.UID);
            });
            return this.mainProductFullyUIDList;
        }
        catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    };
    ProductFactory.prototype.extractAllProductUIDList = function () {
        try {
            if (!this || this.metaData.IsNullOrEmpty()) {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                abp.ui.clearBusy();
                return null;
            }
            this.allProductUIDList = this.metaData.map(function (_a) {
                var UID = _a.UID;
                return ({ UID: UID }.UID);
            });
            return this.allProductUIDList;
        }
        catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    };
    ProductFactory.prototype.extractAutomaticRiderUIDList = function () {
        try {
            if (!this || this.metaDataOriginal.IsNullOrEmpty()) {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                abp.ui.clearBusy();
                return null;
            }
            this.automaticRiderUIDList = this.metaDataOriginal.filter(function (x) { return x.Category === 'Complimentary Benefits'; }).map(function (_a) {
                var UID = _a.UID;
                return ({ UID: UID }.UID);
            });
            return this.automaticRiderUIDList;
        }
        catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    };
    ProductFactory.prototype.extractParentAndChildUIDList = function (automaticRiderUIDList) {
        var _this = this;
        try {
            if (!this || this.metaData.IsNullOrEmpty()) {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                abp.ui.clearBusy();
                return null;
            }
            this.metaDataOriginal.forEach(function (x) {
                if (!automaticRiderUIDList.includes(x.UID)) {
                    if (x.ProductRequirements_2) {
                        if (!_this.parentAndChildUIDList) {
                            _this.parentAndChildUIDList = new Map();
                        }
                        if (!_this.parentAndChildUIDList.has(x.ProductRequirements_2[0].ProductConfig.UID)) {
                            _this.parentAndChildUIDList.set(x.ProductRequirements_2[0].ProductConfig.UID, [x.UID]);
                        }
                        else {
                            _this.parentAndChildUIDList.get(x.ProductRequirements_2[0].ProductConfig.UID).push(x.UID);
                        }
                    }
                }
            });
            return this.parentAndChildUIDList;
        }
        catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    };
    ProductFactory.prototype.extractAssociatedProductsAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _loop_1, this_1, i, e_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        this.associatedProducts = [];
                        if (!this.metaProduct.ProductRequirements) {
                            return [2 /*return*/, this];
                        }
                        _loop_1 = function (i) {
                            var riderIntermediateProduct, benefitPatternSetPromise, premiumPatternSetPromise, product;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, new AssociatedProductFactory(this_1.injector)];
                                    case 1:
                                        riderIntermediateProduct = _a.sent();
                                        return [4 /*yield*/, riderIntermediateProduct.withMetadataAsync(abp.setting.values['ActiveGenerationName'])];
                                    case 2:
                                        _a.sent();
                                        riderIntermediateProduct
                                            .forRiderProduct(this_1.metaData.find(function (x) { return x.UID === _this.metaProduct.ProductRequirements[i].ProductConfig_2.UID; }))
                                            .setCurrentMainProducUID(this_1.uid)
                                            .extractRequirementType()
                                            .extractRiderPolicy()
                                            .extractAccelerator()
                                            .forProduct(this_1.metaData.find(function (x) { return x.UID === _this.metaProduct.ProductRequirements[i].ProductConfig_2.UID; }).UID)
                                            .extractUID()
                                            .extractName()
                                            .extractDisplayName()
                                            .extractEventSet()
                                            .extractCategory()
                                            .extractMaxBenefitAmount()
                                            .extractMinBenefitAmount()
                                            .extractDependentStatus()
                                            .extractDependentParentGuid(this_1);
                                        benefitPatternSetPromise = riderIntermediateProduct
                                            .extractBenefitPatternSetAsync();
                                        premiumPatternSetPromise = riderIntermediateProduct
                                            .extractPremiumPatternSetAsync();
                                        return [4 /*yield*/, Promise.all([benefitPatternSetPromise, premiumPatternSetPromise])];
                                    case 3:
                                        _a.sent();
                                        riderIntermediateProduct
                                            .setDefaultBenefitPattern()
                                            .setDefaultPremiumPattern();
                                        product = riderIntermediateProduct
                                            .build();
                                        this_1.associatedProducts.push(product);
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < this.metaProduct.ProductRequirements.length)) return [3 /*break*/, 4];
                        return [5 /*yield**/, _loop_1(i)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, this];
                    case 5:
                        e_2 = _a.sent();
                        abp.message.error(JSON.stringify(e_2));
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ProductFactory.prototype.build = function () {
        return new Product(this.uid, this.name, this.minBenefitAmount, this.maxBenefitAmount, this.benefitPatternSet, this.premiumPatternSet, this.eventSet, this.category, this.displayName, this.associatedProducts, this.defaultBenefitPattern, this.defaultPremiumPattern, this.rateTable, this.isDisability);
    };
    return ProductFactory;
}(ProductBaseFactory));
export { ProductFactory };
