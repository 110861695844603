﻿import { Product } from './Product';
import { Injector } from '@angular/core';
import { mergeMap as _observableMergeMap, catchError as _observableCatch, map } from 'rxjs/operators';
import { IIncompleteProductBase, ProductBaseFactory } from './IIncompleteProductBase';
import { AssociatedProductFactory } from '../AssociatedProductFactory/IIncomplete-associated-product';
import { AppConsts } from '../../../../shared/AppConsts';
import { AssociatedProduct } from '../AssociatedProductFactory/associated-product';
import { length } from '@amcharts/amcharts4/.internal/core/utils/Iterator';
import * as _ from 'lodash';

export interface IIncompleteProduct extends IIncompleteProductBase {
    extractAutomaticRiderUIDList():  string[];
    withMetadataAsync(generationName: string): Promise<IIncompleteProduct>;
    extractFulllyMainProductUIDList(): string[];
    extractSemiMainProductUIDList(): string[];
    extractAssociatedProductsAsync(): Promise<IIncompleteProduct>;
    extractAllProductUIDList(): string[];
    extractParentAndChildUIDList(automaticRiderUIDList: string[]): Map<string, string[]>;
    build(): Product;
}


export class ProductFactory extends ProductBaseFactory implements IIncompleteProduct {

    mainProductFullyUIDList: string[];
    mainProductSemiUIDList: string[];
    allProductUIDList: string[];
    automaticRiderUIDList: string[];
    parentAndChildUIDList: Map<string, string[]>;

    associatedProducts: AssociatedProduct[];

    constructor(protected injector: Injector) {
        super(injector);
    }

    async withMetadataAsync(generationName: string, isSecuritasBroker: boolean = false, allowedDisabilityProductNames: Array<string> = [], isTestUser: boolean = false): Promise<IIncompleteProduct> {
        try {
            const isProductAllowed = (productName: string): boolean => {
                for (const allowedProductName of allowedDisabilityProductNames) {
                    if (productName.toLocaleLowerCase().includes(allowedProductName)) {
                        return true;
                    }
                }

                return false;
            };

            let metaData = await this.productService.getProductList(generationName).toPromise();
            this.metaDataOriginal = _.cloneDeep(metaData);

            if (!isSecuritasBroker) {
                metaData = metaData.filter(m => !m.Name.includes('SEF'));
            }

            metaData = metaData.filter(m => m.Category === 'Disability Protection' || (m.Category === 'Complimentary Benefits' && m.Name.toLocaleLowerCase().includes('disability')) ? isProductAllowed(m.Name) : true);

            this.metaData = metaData;
            return this;
        } catch (err) {
            abp.message.error(JSON.stringify(err));
        }

    }

    extractSemiMainProductUIDList(): string[] {
        try {
            if (!this || this.metaData.IsNullOrEmpty()) {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                abp.ui.clearBusy();
                return null;
            }
            this.mainProductSemiUIDList = this.metaData.filter(x => x.ProductRequirements_2 === undefined && x.Name.trim().toLowerCase().indexOf('semi') >= 0).map(({ UID }) => ({ UID }.UID));
            return this.mainProductSemiUIDList;
        } catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    }

    extractFulllyMainProductUIDList(): string[] {
        try {
            if (!this || this.metaData.IsNullOrEmpty()) {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                abp.ui.clearBusy();
                return null;
            }
            this.mainProductFullyUIDList = this.metaData.filter(x => x.ProductRequirements_2 === undefined && x.Name.trim().toLowerCase().indexOf('semi') === -1).map(({ UID }) => ({ UID }.UID));
            return this.mainProductFullyUIDList;
        } catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    }

    extractAllProductUIDList(): string[] {
        try {
            if (!this || this.metaData.IsNullOrEmpty()) {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                abp.ui.clearBusy();
                return null;
            }
            this.allProductUIDList = this.metaData.map(({ UID }) => ({ UID }.UID));
            return this.allProductUIDList;
        } catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    }

    extractAutomaticRiderUIDList(): string[] {
        try {
            if (!this || this.metaDataOriginal.IsNullOrEmpty()) {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                abp.ui.clearBusy();
                return null;
            }
            this.automaticRiderUIDList = this.metaDataOriginal.filter(x => x.Category === 'Complimentary Benefits').map(({ UID }) => ({ UID }.UID));
            return this.automaticRiderUIDList;
        } catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    }

    extractParentAndChildUIDList(automaticRiderUIDList: string[]): Map<string, string[]> {
        try {
            if (!this || this.metaData.IsNullOrEmpty()) {
                abp.message.error('Please Contact Support, as we could not assist at present.');
                abp.ui.clearBusy();
                return null;
            }

            this.metaDataOriginal.forEach(x => {
                if (!automaticRiderUIDList.includes(x.UID)) {
                    if (x.ProductRequirements_2) {
                        if (!this.parentAndChildUIDList) {
                            this.parentAndChildUIDList = new Map<string, string[]>();
                        }

                        if (!this.parentAndChildUIDList.has(x.ProductRequirements_2[0].ProductConfig.UID)) {
                            this.parentAndChildUIDList.set(x.ProductRequirements_2[0].ProductConfig.UID, [x.UID]);
                        } else {
                            this.parentAndChildUIDList.get(x.ProductRequirements_2[0].ProductConfig.UID).push(x.UID);
                        }
                    }
                }
            });

            return this.parentAndChildUIDList;
        } catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    }

    async extractAssociatedProductsAsync(): Promise<IIncompleteProduct> {
        try {
            this.associatedProducts = [];
            if (!this.metaProduct.ProductRequirements) { return this; }
            for (let i = 0; i < this.metaProduct.ProductRequirements.length; i++) {
                let riderIntermediateProduct = await new AssociatedProductFactory(this.injector);


                await riderIntermediateProduct.withMetadataAsync(abp.setting.values['ActiveGenerationName']);

                riderIntermediateProduct
                    .forRiderProduct(this.metaData.find(x => x.UID === this.metaProduct.ProductRequirements[i].ProductConfig_2.UID))
                    .setCurrentMainProducUID(this.uid)
                    .extractRequirementType()
                    .extractRiderPolicy()
                    .extractAccelerator()
                    .forProduct(this.metaData.find(x => x.UID === this.metaProduct.ProductRequirements[i].ProductConfig_2.UID).UID)
                    .extractUID()
                    .extractName()
                    .extractDisplayName()
                    .extractEventSet()
                    .extractCategory()
                    .extractMaxBenefitAmount()
                    .extractMinBenefitAmount()
                    .extractDependentStatus()
                    .extractDependentParentGuid(this);
                //we fetch the benefit pattern set and premium pattern set cocurrently since thery are dependent of each other

                let benefitPatternSetPromise = riderIntermediateProduct
                    .extractBenefitPatternSetAsync();

                let premiumPatternSetPromise = riderIntermediateProduct
                    .extractPremiumPatternSetAsync();

                await Promise.all([benefitPatternSetPromise, premiumPatternSetPromise]);

                riderIntermediateProduct
                    .setDefaultBenefitPattern()
                    .setDefaultPremiumPattern();

                let product = riderIntermediateProduct
                    .build();
                this.associatedProducts.push(product);
            }
            return this;
        } catch (e) {
            abp.message.error(JSON.stringify(e));
        }
    }



    build(): Product {
        return new Product(this.uid,
            this.name,
            this.minBenefitAmount,
            this.maxBenefitAmount,
            this.benefitPatternSet,
            this.premiumPatternSet,
            this.eventSet,
            this.category,
            this.displayName,
            this.associatedProducts,
            this.defaultBenefitPattern,
            this.defaultPremiumPattern,
            this.rateTable, this.isDisability);
    }
}
