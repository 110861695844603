var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AppConsts } from '../../../shared/AppConsts';
var NeedsAssessmentType = /** @class */ (function () {
    function NeedsAssessmentType(type) {
        this.assessmentType = type;
    }
    return NeedsAssessmentType;
}());
export { NeedsAssessmentType };
var FamilyAssesment = /** @class */ (function (_super) {
    __extends(FamilyAssesment, _super);
    function FamilyAssesment() {
        return _super.call(this, AppConsts.AssessmentType.FamilyAssessment) || this;
    }
    Object.defineProperty(FamilyAssesment.prototype, "whoReliesFinanciallyOnYou", {
        get: function () {
            return this._whoReliesFinanciallyOnYou;
        },
        set: function (value) {
            this._whoReliesFinanciallyOnYou = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FamilyAssesment.prototype, "myDob", {
        get: function () {
            return this._myDob;
        },
        set: function (value) {
            this._myDob = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FamilyAssesment.prototype, "myGender", {
        get: function () {
            return this._myGender;
        },
        set: function (value) {
            this._myGender = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FamilyAssesment.prototype, "partnerGender", {
        get: function () {
            return this._partnerGender;
        },
        set: function (value) {
            this._partnerGender = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FamilyAssesment.prototype, "partnerDob", {
        get: function () {
            return this._partnerDob;
        },
        set: function (value) {
            this._partnerDob = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FamilyAssesment.prototype, "noOfKids", {
        get: function () {
            return this._noOfKids;
        },
        set: function (value) {
            this._noOfKids = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FamilyAssesment.prototype, "allKidsYears", {
        get: function () {
            return this._allKidsYears || [];
        },
        set: function (value) {
            this._allKidsYears = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FamilyAssesment.prototype, "childrenEducationCost", {
        get: function () {
            return this._childrenEducationCost;
        },
        set: function (value) {
            this._childrenEducationCost = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FamilyAssesment.prototype, "childrenLumpSumCost", {
        get: function () {
            return this._childrenLumpSumCost;
        },
        set: function (value) {
            this._childrenLumpSumCost = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FamilyAssesment.prototype, "childrenMonthlyCost", {
        get: function () {
            return this._childrenMonthlyCost;
        },
        set: function (value) {
            this._childrenMonthlyCost = value;
        },
        enumerable: true,
        configurable: true
    });
    FamilyAssesment.prototype.toJson = function () {
        var kids = [];
        this.allKidsYears.forEach(function (x) {
            kids.push(x);
        });
        for (var i = kids.length; i < 5; i++) {
            kids[i] = 0;
        }
        return [{
                question: 'What does family look like to you?',
                answer: getWhoReiliesOnYou(this.whoReliesFinanciallyOnYou)
            }, {
                question: 'I was born on',
                answer: this.myDob
            }, {
                question: 'My gender is',
                answer: this.myGender
            }, {
                question: 'My partner was born on',
                answer: this.partnerDob
            }, {
                question: 'My partner\'s gender is',
                answer: this.partnerGender
            }, {
                question: 'I have',
                answer: this.noOfKids
            }, {
                question: 'My 1st Kid is',
                answer: kids[0]
            }, {
                question: 'My 2nd Kid is',
                answer: kids[1]
            }, {
                question: 'My 3rd Kid is',
                answer: kids[2]
            }, {
                question: 'My 4th Kid is',
                answer: kids[3]
            }, {
                question: 'My 5th Kid is',
                answer: kids[4]
            }, {
                question: 'What do you pay for their annual school/university fees?',
                answer: this.childrenEducationCost
            }];
        function getWhoReiliesOnYou(item) {
            if (item === 0) {
                return 'It\'s just me.';
            }
            if (item === 1) {
                return 'It\'s my partner and I.';
            }
            if (item === 2) {
                return 'It\'s my kids and I.';
            }
            if (item === 3) {
                return 'It\'s me, my kids and my partner.';
            }
        }
    };
    return FamilyAssesment;
}(NeedsAssessmentType));
export { FamilyAssesment };
var EarningAssesment = /** @class */ (function (_super) {
    __extends(EarningAssesment, _super);
    function EarningAssesment() {
        var _this = _super.call(this, AppConsts.AssessmentType.EarningAssessment) || this;
        _this._occupation = {};
        return _this;
    }
    Object.defineProperty(EarningAssesment.prototype, "myGrossMonthlyIncome", {
        get: function () {
            return this._myGrossMonthlyIncome;
        },
        set: function (value) {
            this._myGrossMonthlyIncome = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EarningAssesment.prototype, "myNetMonthlyIncome", {
        get: function () {
            return this._myNetMonthlyIncome;
        },
        set: function (value) {
            this._myNetMonthlyIncome = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EarningAssesment.prototype, "occupation", {
        get: function () {
            return this._occupation;
        },
        set: function (value) {
            this._occupation = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EarningAssesment.prototype, "isSelfEmployed", {
        get: function () {
            return this._isSelfEmployed;
        },
        set: function (value) {
            this._isSelfEmployed = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EarningAssesment.prototype, "educationLevel", {
        get: function () {
            return this._educationLevel;
        },
        set: function (value) {
            this._educationLevel = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EarningAssesment.prototype, "annualIncome", {
        get: function () {
            return this._annualIncome;
        },
        set: function (value) {
            this._annualIncome = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EarningAssesment.prototype, "myRetirementAge", {
        get: function () {
            return this._myRetirementAge;
        },
        set: function (value) {
            this._myRetirementAge = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EarningAssesment.prototype, "incomeLossCoverPeriod", {
        get: function () {
            return this._incomeLossCoverPeriod;
        },
        set: function (value) {
            this._incomeLossCoverPeriod = value;
        },
        enumerable: true,
        configurable: true
    });
    EarningAssesment.prototype.toJson = function () {
        return [{
                question: 'My gross monthly income is',
                answer: this.myGrossMonthlyIncome
            }, {
                question: 'My net monthly income is',
                answer: this.myNetMonthlyIncome
            }, {
                question: 'Please tell us what you do for work (formal / self-employed).',
                answer: JSON.stringify(this.occupation)
            }, {
                question: 'What is your education level?',
                answer: this.educationLevel
            }];
    };
    return EarningAssesment;
}(NeedsAssessmentType));
export { EarningAssesment };
var HomeAssessment = /** @class */ (function (_super) {
    __extends(HomeAssessment, _super);
    function HomeAssessment() {
        var _this = _super.call(this, AppConsts.AssessmentType.HomeAssessment) || this;
        _this._doYouHaveOtherDebts = new Array();
        return _this;
    }
    Object.defineProperty(HomeAssessment.prototype, "howDoYouDefineHome", {
        get: function () {
            return this._howDoYouDefineHome;
        },
        set: function (value) {
            this._howDoYouDefineHome = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeAssessment.prototype, "myHomeWorth", {
        get: function () {
            return this._myHomeWorth;
        },
        set: function (value) {
            this._myHomeWorth = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeAssessment.prototype, "iHaveYearsLeft", {
        get: function () {
            return this._iHaveYearsLeft;
        },
        set: function (value) {
            this._iHaveYearsLeft = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeAssessment.prototype, "myOutstandingMortageAmount", {
        get: function () {
            return this._myOutstandingMortageAmount;
        },
        set: function (value) {
            this._myOutstandingMortageAmount = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeAssessment.prototype, "monthlyInstallments", {
        get: function () {
            return this._monthlyInstallments;
        },
        set: function (value) {
            this._monthlyInstallments = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeAssessment.prototype, "iPayMonthlyRentOf", {
        get: function () {
            return this._iPayMonthlyRentOf;
        },
        set: function (value) {
            this._iPayMonthlyRentOf = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeAssessment.prototype, "doYouHaveOtherDebts", {
        get: function () {
            return this._doYouHaveOtherDebts;
        },
        set: function (value) {
            this._doYouHaveOtherDebts = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeAssessment.prototype, "monthlyHomeRental", {
        get: function () {
            return this._monthlyHomeRental;
        },
        set: function (value) {
            this._monthlyHomeRental = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeAssessment.prototype, "totalMonthlyDebtAmount", {
        get: function () {
            return this._totalMonthlyDebtAmount;
        },
        set: function (value) {
            this._totalMonthlyDebtAmount = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeAssessment.prototype, "totalDebtOutstandingAmount", {
        get: function () {
            return this._totalDebtOutstandingAmount;
        },
        set: function (value) {
            this._totalDebtOutstandingAmount = value;
        },
        enumerable: true,
        configurable: true
    });
    HomeAssessment.prototype.toJson = function () {
        return [{
                question: 'How do you define home?',
                answer: getCardNameById(this.howDoYouDefineHome)
            }, {
                question: 'My home is worth',
                answer: this.myHomeWorth
            }, {
                question: 'My outstanding mortgage amount',
                answer: this.myOutstandingMortageAmount
            }, {
                question: 'My monthly installments are',
                answer: this.monthlyInstallments
            }, {
                question: 'I have',
                answer: this.iHaveYearsLeft + ' years left'
            }, {
                question: 'I pay monthly rent of',
                answer: this.iPayMonthlyRentOf
            }, {
                question: 'Do you have any other debts?',
                answer: JSON.stringify(this.doYouHaveOtherDebts)
            }];
        function getCardNameById(id) {
            if (id === 0) {
                return 'I own my home.';
            }
            if (id === 1) {
                return 'I own my home, with a mortgage.';
            }
            if (id === 2) {
                return 'I rent my home.';
            }
            if (id === 3) {
                return 'I live rent free.';
            }
            return '';
        }
    };
    return HomeAssessment;
}(NeedsAssessmentType));
export { HomeAssessment };
var InsuranceAssessment = /** @class */ (function (_super) {
    __extends(InsuranceAssessment, _super);
    function InsuranceAssessment() {
        var _this = _super.call(this, AppConsts.AssessmentType.InsuranceAssessment) || this;
        _this._throughOutYourEmployment = new Array();
        _this._purchaseYourself = new Array();
        return _this;
    }
    Object.defineProperty(InsuranceAssessment.prototype, "throughOutYourEmployment", {
        get: function () {
            return this._throughOutYourEmployment;
        },
        set: function (value) {
            this._throughOutYourEmployment = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InsuranceAssessment.prototype, "isKeepingExistingPolicies", {
        get: function () {
            return this._isKeepingExistingPolicies;
        },
        set: function (value) {
            this._isKeepingExistingPolicies = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InsuranceAssessment.prototype, "purchaseYourself", {
        get: function () {
            return this._purchaseYourself;
        },
        set: function (value) {
            this._purchaseYourself = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InsuranceAssessment.prototype, "totalInsuranceExpenseAmount", {
        get: function () {
            return this._totalInsuranceExpenseAmount;
        },
        set: function (value) {
            this._totalInsuranceExpenseAmount = value;
        },
        enumerable: true,
        configurable: true
    });
    InsuranceAssessment.prototype.toJson = function () {
        return [
            {
                question: 'What existing insurance cover do you have? : Through your employment:',
                answer: JSON.stringify(this.throughOutYourEmployment, null, 4)
            },
            {
                question: 'What existing insurance cover do you have? : You purchased for yourself:',
                answer: JSON.stringify(this.purchaseYourself, null, 4)
            },
        ];
    };
    return InsuranceAssessment;
}(NeedsAssessmentType));
export { InsuranceAssessment };
var MedicalInsuranceAssessment = /** @class */ (function (_super) {
    __extends(MedicalInsuranceAssessment, _super);
    function MedicalInsuranceAssessment() {
        var _this = _super.call(this, AppConsts.AssessmentType.MeidcalInsuranceAssessment) || this;
        _this._childDependents = 0;
        _this._adultDependents = 0;
        return _this;
    }
    Object.defineProperty(MedicalInsuranceAssessment.prototype, "childDependents", {
        get: function () {
            return this._childDependents;
        },
        set: function (value) {
            this._childDependents = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MedicalInsuranceAssessment.prototype, "adultDependents", {
        get: function () {
            return this._adultDependents;
        },
        set: function (value) {
            this._adultDependents = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MedicalInsuranceAssessment.prototype, "currentContribution", {
        get: function () {
            return this._currentContribution;
        },
        set: function (value) {
            this._currentContribution = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MedicalInsuranceAssessment.prototype, "userSetContribution", {
        get: function () {
            return this._userSetContribution;
        },
        set: function (value) {
            this._userSetContribution = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MedicalInsuranceAssessment.prototype, "schemeName", {
        get: function () {
            return this._schemeName;
        },
        set: function (value) {
            this._schemeName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MedicalInsuranceAssessment.prototype, "schemeOptionName", {
        get: function () {
            return this._schemeOptionName;
        },
        set: function (value) {
            this._schemeOptionName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MedicalInsuranceAssessment.prototype, "name", {
        get: function () {
            return this._name;
        },
        set: function (value) {
            this._name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MedicalInsuranceAssessment.prototype, "userId", {
        get: function () {
            return this._userId;
        },
        set: function (value) {
            this._userId = value;
        },
        enumerable: true,
        configurable: true
    });
    return MedicalInsuranceAssessment;
}(NeedsAssessmentType));
export { MedicalInsuranceAssessment };
var ExpenseAssessment = /** @class */ (function (_super) {
    __extends(ExpenseAssessment, _super);
    function ExpenseAssessment() {
        return _super.call(this, AppConsts.AssessmentType.ExpenseAssessment) || this;
    }
    Object.defineProperty(ExpenseAssessment.prototype, "totalMonthlyHouseholdExpense", {
        get: function () {
            return this._totalMonthlyHouseholdExpense;
        },
        set: function (value) {
            this._totalMonthlyHouseholdExpense = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpenseAssessment.prototype, "coverLossOfMonthlySavings", {
        get: function () {
            return this._coverLossOfMonthlySavings;
        },
        set: function (value) {
            this._coverLossOfMonthlySavings = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpenseAssessment.prototype, "totalSavingsAmount", {
        get: function () {
            return this._totalSavingsAmount;
        },
        set: function (value) {
            this._totalSavingsAmount = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpenseAssessment.prototype, "schoolFess", {
        get: function () {
            return this._schoolFess;
        },
        set: function (value) {
            this._schoolFess = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpenseAssessment.prototype, "monthlyRent", {
        get: function () {
            return this._monthlyRent;
        },
        set: function (value) {
            this._monthlyRent = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpenseAssessment.prototype, "debtInstallments", {
        get: function () {
            return this._debtInstallments;
        },
        set: function (value) {
            this._debtInstallments = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpenseAssessment.prototype, "insurancePremiums", {
        get: function () {
            return this._insurancePremiums;
        },
        set: function (value) {
            this._insurancePremiums = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpenseAssessment.prototype, "totalMonthlyExpense", {
        get: function () {
            return this._totalMonthlyExpense;
        },
        set: function (value) {
            this._totalMonthlyExpense = value;
        },
        enumerable: true,
        configurable: true
    });
    ExpenseAssessment.prototype.toJson = function () {
        return [{
                question: 'What are your monthly expenses and savings on average?: Total monthly household expenses',
                answer: this.totalMonthlyHouseholdExpense
            }, {
                question: 'What are your monthly expenses and savings on average?: Total savings amount set aside for a rainy day',
                answer: this.totalSavingsAmount
            }];
    };
    return ExpenseAssessment;
}(NeedsAssessmentType));
export { ExpenseAssessment };
var EstimateAssesment = /** @class */ (function (_super) {
    __extends(EstimateAssesment, _super);
    function EstimateAssesment() {
        return _super.call(this, AppConsts.AssessmentType.EstimateAssessment) || this;
    }
    Object.defineProperty(EstimateAssesment.prototype, "lifeInsuranceLumpsumAmount", {
        get: function () {
            return this._lifeInsuranceLumpsumAmount;
        },
        set: function (value) {
            this._lifeInsuranceLumpsumAmount = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EstimateAssesment.prototype, "SevereIllnessLumpsumAmount", {
        get: function () {
            return this._SevereIllnessLumpsumAmount;
        },
        set: function (value) {
            this._SevereIllnessLumpsumAmount = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EstimateAssesment.prototype, "DisabilityLumpsumAmount", {
        get: function () {
            return this._DisabilityLumpsumAmount;
        },
        set: function (value) {
            this._DisabilityLumpsumAmount = value;
        },
        enumerable: true,
        configurable: true
    });
    return EstimateAssesment;
}(NeedsAssessmentType));
export { EstimateAssesment };
