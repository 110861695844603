var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { LayoutRefService } from '@metronic/app/core/services/layout/layout-ref.service';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { MenuAsideOffcanvasDirective } from '@metronic/app/core/directives/menu-aside-offcanvas.directive';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import { ActivatedRoute } from '@angular/router';
var Theme3LayoutComponent = /** @class */ (function (_super) {
    __extends(Theme3LayoutComponent, _super);
    function Theme3LayoutComponent(injector, layoutRefService, authService, route) {
        var _this = _super.call(this, injector) || this;
        _this.layoutRefService = layoutRefService;
        _this.authService = authService;
        _this.route = route;
        return _this;
    }
    Object.defineProperty(Theme3LayoutComponent.prototype, "isAuthenticated", {
        get: function () {
            return this._isAuthenticated;
        },
        set: function (value) {
            this._isAuthenticated = value;
        },
        enumerable: true,
        configurable: true
    });
    Theme3LayoutComponent.prototype.ngOnInit = function () {
        this.isAuthenticated = this.authService.isLoggedIn();
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    };
    Theme3LayoutComponent.prototype.getTheme = function () {
        try {
            var url = this.route.snapshot['_routerState'].url;
            if (url.includes('ExtQuote') || url.includes('ExtCalculator') || url.includes('fna')) {
                return 'ext-quote';
            }
            return this.currentTheme.baseSettings.menu.asideSkin;
        }
        catch (_a) {
            return this.currentTheme.baseSettings.menu.asideSkin;
        }
    };
    Theme3LayoutComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            if (!_this.mAsideLeft) {
                return;
            }
            _this.mMenuAsideOffcanvas = new MenuAsideOffcanvasDirective(_this.mAsideLeft);
            if (_this.mMenuAsideOffcanvas) {
                _this.mMenuAsideOffcanvas.ngAfterViewInit();
            }
        });
        this.layoutRefService.addElement('header', this.mHeader.nativeElement);
    };
    return Theme3LayoutComponent;
}(ThemesLayoutBaseComponent));
export { Theme3LayoutComponent };
