﻿import { Inject, Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { mergeMap as _observableMergeMap, catchError as _observableCatch, map } from 'rxjs/operators';
import { PoliciesModule, PersonModule, RatingFactorModule } from '@shared/models/interfaces/elevate-data-models';
import { AppConsts } from '@shared/AppConsts';
import { HttpMethodBaseService } from './HttpMethodsBase/httpmethods-base';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';
import { head } from 'lodash';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';

@Injectable()
export class PolicyService {
    private policyService = new Map();
    readonly ELEVATE_ACCP_ENDPOINT = abp.setting.values[AppConsts.KeyVaultSecrets.apimElevateAccpendpoint];
    readonly APIM_CLIENT_ENDPOINT = abp.setting.values[AppConsts.KeyVaultSecrets.apimClientEndpoint];
    readonly API_BASE_URL_IDP = abp.setting.values['PortalSettings.authority'];

    constructor(
        private httpMethodBaseService: HttpMethodBaseService,
        private _authService: AuthService,
        @Inject(API_BASE_URL) private readonly API_BASE_URL
    ) { }

    personRatingFactor = new RatingFactorModule.PersonRatingFactor(undefined, null, null, null, null);
    getPolicySets(policyList: PersonModule.IPolicyAPI[]): PoliciesModule.IPolicyModel[] {
        let responseData: PoliciesModule.IPolicyModel[] = [];


        policyList.forEach(x => {
            this.getPolicy(x.Identifier).subscribe((result: PoliciesModule.IPolicyModel) => {
                if (result) {
                    responseData.push(result);
                }
            });
        });


        return responseData;
    }

    async updatePersonAsync(entityId) {
        let res = await this.getPerson(entityId, false).toPromise();
        this.personRatingFactor = new RatingFactorModule.PersonRatingFactorFactory()
            .withPersonMetaData(res)
            .getAge()
            .getSmoker()
            .getOccupational()
            .getGender()
            .getSocials()
            .build();
    }

    getPolicy(policyUid: string, cachedResult = true): Observable<PoliciesModule.IPolicyModel> {
        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPolicy.replace('{PolicyGUID}', policyUid);
        const cachedPolicyData = this.policyService.get(_url);
        if (cachedPolicyData && cachedResult) { return of(cachedPolicyData); }
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        let response = this.httpMethodBaseService.get<PoliciesModule.IPolicyModelResult>(_url, headers)
            .pipe(map((res: PoliciesModule.IPolicyModelResult) => {
                this.policyService.set(_url, res);
                return res.result;
            }));
        return response;
    }

    isSubmitted(policyStatusName: string): boolean {
        const submittedStatuses = [
            'Declined',
            'Approved',
            'Referred',
            'ApprovedWithExclusion',
            'ApprovedWithPostponement',
            'ApprovedWithPostponementAndExclusion',
            'ApprovedWithLoading',
            'ApprovedWithLoadingAndExclusion',
            'ApprovedWithLoadingAndExclusionAndPostponement',
            'ApprovedWithLoadingAndPostponement'
        ];

        return submittedStatuses.includes(policyStatusName);
    }

    getPerson(personId: string, isCaching = true): Observable<PersonModule.IPersonModel> {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPerson.replace('{personGUID}', personId);
        const conditionsFromCache = this.policyService.get(_url);
        if (conditionsFromCache && isCaching) { return of(conditionsFromCache); }
        let response = this.httpMethodBaseService.get<PersonModule.IPersonModelResult>(_url, headers)
            .pipe(map((res: PersonModule.IPersonModelResult) => {
                this.policyService.set(_url, res.result);
                return res.result;
            }));
        return response;
    }

    updateBeneficiaries(policyGuid: string, productGuid: string, effectiveDate: Date, beneficiaries: Array<{
        AllocationPercentage: number,
        BeneficiaryAssuredType: string,
        Person: {
            Identifier: string
        }
    }>): Observable<any> {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.updateBeneficiaries
            .replace('{PolicyGUID}', policyGuid)
            .replace('{ProductGUID}', productGuid);

        const body = {
            EffectiveDate: effectiveDate,
            Beneficiarys: beneficiaries
        };

        let _cachedUrl = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPolicy.replace('{PolicyGUID}', policyGuid);
        this.policyService.delete(_cachedUrl);

        return this.httpMethodBaseService.put(_url, body, headers);
    }

    createOrEditPerson(person: PersonModule.IPersonInput, personId): Observable<any> {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.createPerson.replace('{personGUID}', personId);
        return this.httpMethodBaseService.put(_url, person, headers);
    }

    queueUpdatePersonSync(personId: any): Observable<any> {
        let _url = this.API_BASE_URL_IDP + AppConsts.Endpoints.queueUserUpdate + personId;
        return this.httpMethodBaseService.post(_url);
    }

    queuePolicyToCrm(policyId) {
        let _url = this.API_BASE_URL_IDP + AppConsts.Endpoints.queuePolicy + policyId;
        return this.httpMethodBaseService.post(_url);
    }

    queuePolicyReferencesEmail(policyGuid, policyReferences, policyStatusName, lifeAssuredGuid, premiumPayerGuid, brokerGuid): Observable<boolean> {
        let headers = new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        });

        let body = {
            policyGuid,
            policyReferences: JSON.stringify(policyReferences, null, 1),
            policyStatusName,
            lifeAssuredGuid,
            premiumPayerGuid,
            brokerGuid
        };

        const url = this.API_BASE_URL_IDP + AppConsts.Endpoints.queuePolicyReferencesMail;
        return this.httpMethodBaseService.post(url, body, headers);
    }

    queuePolicyForEmailNotification(emailid, policyId, contract, policyNumber, userName) {
        let headers = new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        });

        let body = {
            email: emailid,
            policyId: policyId,
            contract: contract,
            policyNumber: policyNumber,
            includeIntermediaryDocument: true,
            userFirstName: userName
        };

        let _url = this.API_BASE_URL_IDP + AppConsts.Endpoints.queuePolicyForEmailNotification.replace('{EMAIL}', emailid).replace('{POLICY_ID}', policyId);
        return this.httpMethodBaseService.post(_url, body, headers);
    }

    getPolicyContract(policyId) {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        let _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getContract.replace('{policyId}', policyId);
        return this.httpMethodBaseService.get(_url, headers).pipe(map((res: any) => {
            return res.result.Result;
        }));
    }

    getPoliciesForLifeAssured(lifeAssuredGuid: string, accessToken: string): Observable<Array<PoliciesModule.IPolicy>> {
        return this.httpMethodBaseService
            .get<{ result: Array<PoliciesModule.IPolicy> }>(`${this.API_BASE_URL}/api/services/app/Policy/GetPoliciesForLifeAssured?lifeAssuredGuid=${lifeAssuredGuid}&accessToken=${accessToken}`)
            .pipe(map(res => res.result));
    }

    getPoliciesForPremiumPayer(premiumPayerGuid: string, accessToken: string): Observable<Array<PoliciesModule.IPolicy>> {
        return this.httpMethodBaseService
            .get<{ result: Array<PoliciesModule.IPolicy> }>(`${this.API_BASE_URL}/api/services/app/Policy/GetPoliciesForPremiumPayer?premiumPayerGuid=${premiumPayerGuid}&accessToken=${accessToken}`)
            .pipe(map(res => res.result));
    }

    getPolicyFromRates(policyOrQuoteGuid: string, accessToken: string): Observable<PoliciesModule.IPolicy> {
        return this.httpMethodBaseService
            .get<{ result: PoliciesModule.IPolicy }>(`${this.API_BASE_URL}/api/services/app/Policy/GetPolicy?policyOrQuoteGuid=${policyOrQuoteGuid}&accessToken=${accessToken}`)
            .pipe(map(res => res.result));
    }
}
