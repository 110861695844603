/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./associated-product-assured-exceeded-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/modal";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "./associated-product-assured-exceeded-modal.component";
var styles_AssociatedProductAssuredExceededModalComponent = [i0.styles];
var RenderType_AssociatedProductAssuredExceededModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssociatedProductAssuredExceededModalComponent, data: {} });
export { RenderType_AssociatedProductAssuredExceededModalComponent as RenderType_AssociatedProductAssuredExceededModalComponent };
export function View_AssociatedProductAssuredExceededModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 24, "div", [["bsModal", ""]], null, [[null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, [["associatedProductAssuredExceededModal", 4]], 0, i2.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i3.ComponentLoaderFactory], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "modal-header d-flex flex-row justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "swal2-title mt-0 mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "close p-0 m-0"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 16, "div", [["class", "modal-body mp-0 mt-10 d-flex flex-column justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "swal2-icon swal2-warning swal2-icon-show"], ["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "swal2-icon-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["!"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "h2", [["class", "swal2-title mt-0 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sum assured mismatch"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, [" ", " "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "d-flex flex-row justify-content-center align-items-center mt-4 ml-5 mr-5"], ["style", "width: 450px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, [" ", " sum assured value does not match ", ". Would you like to lower the ", " to match? "])), (_l()(), i1.ɵeld(18, 0, null, null, 6, "div", [["class", "d-flex flex-row justify-content-center align-items-center mt-4 mb-5 ml-5 mr-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "btn btn-primary kt-btn--pill btn-pill btn-gradient-clr mr-3"], ["style", "width: 150px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.assign() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "small", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Yes "])), (_l()(), i1.ɵeld(22, 0, null, null, 2, "button", [["class", "btn btn-primary kt-btn--pill btn-pill btn-gradient-clr mr-3"], ["style", "width: 150px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "small", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No "]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 15, 0, currVal_1); var currVal_2 = _co.mainProduct; var currVal_3 = _co.associatedProduct; var currVal_4 = _co.mainProduct; _ck(_v, 17, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_AssociatedProductAssuredExceededModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-associated-product-assured-exceeded-modal", [], null, null, null, View_AssociatedProductAssuredExceededModalComponent_0, RenderType_AssociatedProductAssuredExceededModalComponent)), i1.ɵdid(1, 114688, null, 0, i4.AssociatedProductAssuredExceededModalComponent, [i2.BsModalRef, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssociatedProductAssuredExceededModalComponentNgFactory = i1.ɵccf("app-associated-product-assured-exceeded-modal", i4.AssociatedProductAssuredExceededModalComponent, View_AssociatedProductAssuredExceededModalComponent_Host_0, {}, {}, []);
export { AssociatedProductAssuredExceededModalComponentNgFactory as AssociatedProductAssuredExceededModalComponentNgFactory };
