<!-- BEGIN: Aside Menu -->
<div mMenuAside (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)" id="m_ver_menu"
    data-menu-vertical="true" class="{{ui.getSideBarMenuClass()}}" [attr.m-menu-dropdown-timeout]="500"
    [attr.m-menu-scrollable]="ui.getIsMenuScrollable() ? 1 : 0"
    [attr.m-menu-dropdown]="ui.getIsMenuScrollable() ? 0 : 1" [attr.aria-label]="l('LeftMenu')">
    <ul class="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
        <li (click)="gotToBuyAPolicy()" class="buy-a-policy-container" (click)="removeSidebar()"
            [ngClass]="{'buy-a-policy-container-bg-white': isRoutedToBuyAPolicy()}"
            *ngIf="!this._authService.userPermission.includes('PolicyUser1')">

            <img *ngIf="isRoutedToBuyAPolicy()" class="adjust-image-width"
                src="../../../../assets/common/images/buy_a_policy_orange.png" />

            <img *ngIf="!isRoutedToBuyAPolicy()" class="buy-a-policy-icon adjust-image-width" />

        </li>

        <ng-container [ngTemplateOutlet]="mMenuListing"></ng-container>
    </ul>
</div>
<!-- END: Aside Menu -->
<!-- </div> -->
<ng-template #mMenuListing>
    <ng-container *ngFor="let child of menu.items">
        <ng-container *ngIf="showMenuItem(child)" [ngTemplateOutlet]="mMenuItem"
            [ngTemplateOutletContext]="{ item: child, parentItem: menu }"></ng-container>
    </ng-container>
</ng-template>

<ng-template #mMenuItem let-item="item" let-parentItem="parentItem">
    <li *ngIf="showMenuItem(item)"
        class="{{ui.getSideBarMenuItemClass(item, isMenuItemIsActive(item))}} border-bottom-white" aria-haspopup="true"
        m-menu-submenu-toggle="hover">

        <!-- if menu item hasn't submenu -->
        <a (click)="removeSidebar()" *ngIf="!hasOneVisibleSubItem(item) && !item.external"
            [ngClass]="{'h-auto-sidebar':item.name.indexOf('REWARDS')>=0}" [queryParams]="item.parameters"
            [routerLink]="item.route" class="d-inline-flex-sidebar m-menu__link m-menu__toggle">
            <ng-container [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>
        <a *ngIf="!hasOneVisibleSubItem(item) && item.external" [attr.href]="item.route" target="_blank"
            class="m-menu__link m-menu__toggle">
            <ng-container [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child  -->
        <a *ngIf="!isMobileView_New() && hasOneVisibleSubItem(item)" href="javascript:;"
            class="m-menu__link m-menu__toggle d-inline-flex-sidebar" [routerLink]="item.route">
            <ng-container [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <div *ngIf="isMobileView_New() && hasOneVisibleSubItem(item)" href="javascript:;"
            class="m-menu__link m-menu__toggle d-inline-flex-sidebar">
            <ng-container [ngTemplateOutlet]="mMenuItemTextMobile"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </div>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="hasOneVisibleSubItem(item)" class="m-menu__submenu">
            <span class="m-menu__arrow text-white"></span>
            <ul class="m-menu__subnav bg-white">
                <ng-container *ngFor="let child of item.items">
                    <ng-container [ngTemplateOutlet]="mMenuItem"
                        [ngTemplateOutletContext]="{ item: child, parentItem: item }"></ng-container>
                </ng-container>
            </ul>
        </div>
    </li>
</ng-template>

<ng-template #mMenuItemText let-item="item" let-parentItem="parentItem">
    <!--<span class="m-menu__item-here"></span>-->
    <!-- if menu item has icon -->
    <i *ngIf="item.icon && item.icon2=='NA'" class="m-menu__link-icon" [ngClass]="item.icon"></i>
    <img *ngIf="item.icon && item.icon2!='NA'" class="m-menu__link-icon menu-icon-size pa-right-sidebar"
        [src]="item.icon2" />

    <!-- if menu item using bullet -->
    <i *ngIf="parentItem && parentItem.bullet === 'dot'" class="m-menu__link-bullet m-menu__link-bullet--dot">
        <span></span>
    </i>
    <i *ngIf="parentItem && parentItem.bullet === 'line'" class="m-menu__link-bullet m-menu__link-bullet--line">
        <span></span>
    </i>

    <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
        <!-- menu item title text -->
        <span id="linkText" class="m-menu__link-text" [ngClass]="item.icon2!='NA'?item.icon:''">
            {{item.name | localize}}
        </span>
    </ng-container>

    <ng-template #mMenuLinkBadge>
        <!-- menu item with badge -->
        <span class="m-menu__link-title">
            <span class="m-menu__link-wrap">
                <span class="m-menu__link-text">
                    {{item.name | localize}}
                </span>
                <span class="m-menu__link-badge">
                    <span class="m-badge" [ngClass]="item.badge.type">{{item.badge.value}}</span>
                </span>
            </span>
        </span>
    </ng-template>

    <!-- if menu item has submenu child then put arrow icon -->
    <i *ngIf="hasOneVisibleSubItem(item)" class="m-menu__ver-arrow la la-angle-right m-t-sidebar"></i>
</ng-template>

<ng-template #mMenuItemTextMobile let-item="item" let-parentItem="parentItem">
    <!--<span class="m-menu__item-here"></span>-->
    <!-- if menu item has icon -->
    <a (click)="removeSidebar()" [routerLink]="item.route" class="d-flex flex-row w-100">
        <i *ngIf="item.icon && item.icon2=='NA'" class="m-menu__link-icon" [ngClass]="item.icon"></i>
        <img *ngIf="item.icon && item.icon2!='NA'" class="m-menu__link-icon menu-icon-size pa-right-sidebar"
            [src]="item.icon2" />

        <!-- if menu item using bullet -->
        <i *ngIf="parentItem && parentItem.bullet === 'dot'" class="m-menu__link-bullet m-menu__link-bullet--dot">
            <span></span>
        </i>
        <i *ngIf="parentItem && parentItem.bullet === 'line'" class="m-menu__link-bullet m-menu__link-bullet--line">
            <span></span>
        </i>

        <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
            <!-- menu item title text -->
            <span id="linkText" class="m-menu__link-text" [ngClass]="item.icon2!='NA'?item.icon:''">
                {{item.name | localize}}
            </span>
        </ng-container>

        <ng-template #mMenuLinkBadge>
            <!-- menu item with badge -->
            <span class="m-menu__link-title">
                <span class="m-menu__link-wrap">
                    <span class="m-menu__link-text">
                        {{item.name | localize}}
                    </span>
                    <span class="m-menu__link-badge">
                        <span class="m-badge" [ngClass]="item.badge.type">{{item.badge.value}}</span>
                    </span>
                </span>
            </span>
        </ng-template>
    </a>
    <!-- if menu item has submenu child then put arrow icon -->
    <i *ngIf="hasOneVisibleSubItem(item)" class="m-menu__ver-arrow la la-angle-right m-t-sidebar"></i>
</ng-template>
<!-- END: Left Aside -->