import { SyncingHumanApiServiceServiceProxy, HealthCareApiServiceProxy } from '@shared/service-proxies/service-proxies';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/service-proxies/service-proxies";
import * as i2 from "../common/IDSVRAuthProviders/auth.service";
var CommonhelperService = /** @class */ (function () {
    function CommonhelperService(_syncingHumanApiServiceServiceProxy, _healthCare, _authService) {
        this._syncingHumanApiServiceServiceProxy = _syncingHumanApiServiceServiceProxy;
        this._healthCare = _healthCare;
        this._authService = _authService;
        this._userPermissionAllowd = undefined;
        this._healthcareactivated = undefined;
        this._myFinancialactivated = undefined;
        this._myCreditactivated = undefined;
        this._userPermissionAllowd = undefined;
        this._healthcareactivated = undefined;
    }
    CommonhelperService.prototype.isEntityRegisteredWearables = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._syncingHumanApiServiceServiceProxy
                .isEntityRegistered(_this._authService.user.access_token)
                .subscribe(function (res) {
                _this.userPermissionAllowd = res;
                resolve(res);
            }, function (err) {
                _this.userPermissionAllowd = false;
                reject(err);
            });
        });
    };
    CommonhelperService.prototype.getHealthCareActivatedStatus = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._healthCare.getDataActivatedStatus(_this.accessToken).subscribe(function (res) {
                _this.healthcareactivated = res;
                resolve(res);
                abp.ui.clearBusy();
            }, function (err) {
                _this.healthcareactivated = false;
                reject(err);
                abp.ui.clearBusy();
            });
        });
    };
    Object.defineProperty(CommonhelperService.prototype, "myFinancialactivated", {
        get: function () {
            return this._myFinancialactivated && this._user.profile.allow_financial === 'true';
        },
        set: function (value) {
            this._myFinancialactivated = value && this._user.profile.allow_financial === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonhelperService.prototype, "myCreditactivated", {
        get: function () {
            return this._myCreditactivated && this._user.profile.allow_credit === 'true';
        },
        set: function (value) {
            this._myCreditactivated = value && this._user.profile.allow_credit === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonhelperService.prototype, "accessToken", {
        get: function () {
            return this._accessToken;
        },
        set: function (value) {
            this._accessToken = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonhelperService.prototype, "userPermissionAllowd", {
        //wearable active status
        get: function () {
            return this._userPermissionAllowd && (this._user.profile.allow_wearable === 'true');
        },
        set: function (value) {
            this._userPermissionAllowd = value && (this._user.profile.allow_wearable === 'true');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonhelperService.prototype, "healthcareactivated", {
        //healthcareactivestatus
        get: function () {
            return this._healthcareactivated && (this._user.profile.allow_healthcare === 'true');
        },
        set: function (value) {
            this._healthcareactivated = value && (this._user.profile.allow_healthcare === 'true');
        },
        enumerable: true,
        configurable: true
    });
    CommonhelperService.prototype.init = function (user) {
        this._user = user;
        this._accessToken = user.access_token;
    };
    CommonhelperService.ngInjectableDef = i0.defineInjectable({ factory: function CommonhelperService_Factory() { return new CommonhelperService(i0.inject(i1.SyncingHumanApiServiceServiceProxy), i0.inject(i1.HealthCareApiServiceProxy), i0.inject(i2.AuthService)); }, token: CommonhelperService, providedIn: "root" });
    return CommonhelperService;
}());
export { CommonhelperService };
