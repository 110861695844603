import { Injectable } from '@angular/core';
import { SyncingHumanApiServiceServiceProxy, HealthCareApiServiceProxy } from '@shared/service-proxies/service-proxies';
import { AuthGuard } from '../common/IDSVRAuthProviders/auth.guard.service';
import { RoutingArray } from '@app/main/my-financials/my-financial-breadcrumb/my-financial-breadcrumb.component';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CommonhelperService {
  public _user: any;
  private _accessToken: string;
  private _userPermissionAllowd: boolean = undefined;
  private _healthcareactivated: boolean = undefined;
  private _myFinancialactivated: boolean = undefined;
  private _myCreditactivated: boolean = undefined;




  constructor(
    private _syncingHumanApiServiceServiceProxy: SyncingHumanApiServiceServiceProxy,
    private _healthCare: HealthCareApiServiceProxy,
    private _authService: AuthService
  ) {
    this._userPermissionAllowd = undefined;
    this._healthcareactivated = undefined;
  }


  isEntityRegisteredWearables(): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      this._syncingHumanApiServiceServiceProxy
        .isEntityRegistered(this._authService.user.access_token)
        .subscribe((res: boolean) => {
          this.userPermissionAllowd = res;
          resolve(res);
        }, (err) => {
          this.userPermissionAllowd = false;
          reject(err);
        });
    });
  }

  getHealthCareActivatedStatus(): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      this._healthCare.getDataActivatedStatus(this.accessToken).subscribe((res: boolean) => {
        this.healthcareactivated = res;
        resolve(res);
        abp.ui.clearBusy();
      }, (err) => {
        this.healthcareactivated = false;
        reject(err);
        abp.ui.clearBusy();
      });
    });
  }


  public get myFinancialactivated(): boolean {
    return this._myFinancialactivated && this._user.profile.allow_financial === 'true';
  }
  public set myFinancialactivated(value: boolean) {
    this._myFinancialactivated = value && this._user.profile.allow_financial === 'true';
  }

  public get myCreditactivated(): boolean {
    return this._myCreditactivated && this._user.profile.allow_credit === 'true';
  }
  public set myCreditactivated(value: boolean) {
    this._myCreditactivated = value && this._user.profile.allow_credit === 'true';
  }


  public get accessToken(): string {
    return this._accessToken;
  }

  public set accessToken(value: string) {
    this._accessToken = value;
  }

  //wearable active status
  public get userPermissionAllowd(): boolean {
    return this._userPermissionAllowd && (this._user.profile.allow_wearable === 'true');
  }
  public set userPermissionAllowd(value: boolean) {
    this._userPermissionAllowd = value && (this._user.profile.allow_wearable === 'true');
  }


  //healthcareactivestatus
  public get healthcareactivated(): boolean {
    return this._healthcareactivated && (this._user.profile.allow_healthcare === 'true');
  }
  public set healthcareactivated(value: boolean) {
    this._healthcareactivated = value && (this._user.profile.allow_healthcare === 'true');
  }

  init(user) {
    this._user = user;
    this._accessToken = user.access_token;
  }
}
