import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import { AppTimezoneScope, DefaultTimezoneScope } from '@shared/AppEnums';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ClaimModifyServiceProxy, CurrentUserProfileEditDto, ModifyClaimDto, ProfileServiceProxy, UpdateGoogleAuthenticatorKeyOutput, UserClaimEditDto } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { document, ModalDirective } from 'ngx-bootstrap';
import { Profile, User } from 'oidc-client';
import { Observable } from 'rxjs';
import { SmsVerificationModalComponent } from './sms-verification-modal.component';


@Component({
    selector: 'mySettingsModal',
    templateUrl: './my-settings-modal.component.html'
})
export class MySettingsModalComponent extends AppComponentBase {

    @ViewChild('mySettingsModal') modal: ModalDirective;
    @ViewChild('smsVerificationModal') smsVerificationModal: SmsVerificationModalComponent;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public active = false;
    public saving = false;
    public isGoogleAuthenticatorEnabled = false;
    public isPhoneNumberConfirmed: boolean;
    public isPhoneNumberEmpty = false;
    public smsEnabled: boolean;
    public user: CurrentUserProfileEditDto;
    public showTimezoneSelection: boolean = abp.clock.provider.supportsMultipleTimezone;
    public canChangeUserName: boolean;
    public defaultTimezoneScope: DefaultTimezoneScope = AppTimezoneScope.User;
    private _initialTimezone: string = undefined;
    currentUserInfo: Profile;
    restrictionDateValue = new Date();
    GenderList: any = ['Male', 'Female', 'Non-binary'];
    selectedGender: any;
    restrictionMessage: any;
    nameDisabled: boolean;
    surnameDisabled: boolean;
    birthdayDisabled: boolean;
    genderDisabled: boolean;
    private wasInside = false;
    tabIndex: number;
    profile_contact_scheme: string;
    existsCurrentUserInfo: Profile;
    lastTabActive: boolean;
    schemeDisabled: boolean;
    existsRestrictionDateValue: Date;
    enabledField: boolean[] = [];

    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy,
        private _claimModifyService: ClaimModifyServiceProxy,
    ) {
        super(injector);
        this.getCurrentUserInfo();

    }

    getCurrentUserInfo() {
        abp.event.on('getExistsUserInfo', () => {
            this._authService.getUser((res: Profile) => {
                this.existsCurrentUserInfo = res;
                let utcDate = this.existsCurrentUserInfo.birthdate;
                this.existsRestrictionDateValue = moment(utcDate, 'DD/MM/YYYY hh:mm:ss A').toDate();
            });
        });
    }

    show(): void {
        this.active = true;
        this.restrictionMessage = false;
        this.lastTabActive = false;
        this.tabIndex = 1;
        this.profile_contact_scheme = 'profile';
        this.nameDisabled = true;
        this.surnameDisabled = true;
        this.genderDisabled = true;
        this.birthdayDisabled = true;
        this._authService.getUser((res: Profile) => {
            this.currentUserInfo = res;
            this.selectedGender = this.currentUserInfo.gender;
            let birthDate = this.currentUserInfo.birthdate;
            this.restrictionDateValue = moment(birthDate, 'DD/MM/YYYY hh:mm:ss A').toDate();
            abp.event.trigger('getExistsUserInfo');
            this.modal.show();
        });
    }

    profileTab() {


        this.lastTabActive = false;
        this.schemeDisabled = true;
        this.profile_contact_scheme = 'profile';
        this.tabIndex = 1;

        if ((this.existsCurrentUserInfo as any).medical_scheme_membership_number !== (this.currentUserInfo as any).medical_scheme_membership_number) {

            this.changesConfirmPopup();
        } else {
            this.disabledField();
        }

    }

    contactTab() {
        this.nameDisabled = true;
        this.surnameDisabled = true;
        this.schemeDisabled = true;
        this.genderDisabled = true;
        this.birthdayDisabled = true;

        this.lastTabActive = false;
        this.profile_contact_scheme = 'contact';
        this.tabIndex = 2;

        if (((this.existsCurrentUserInfo as any).gender !== this.selectedGender)
            || ((this.existsCurrentUserInfo as any).family_name !== (this.currentUserInfo as any).family_name)
            || ((this.existsCurrentUserInfo as any).given_name !== (this.currentUserInfo as any).given_name)
            || this.existsRestrictionDateValue.getTime() !== this.restrictionDateValue.getTime()
            || ((this.existsCurrentUserInfo as any).medical_scheme_membership_number !== (this.currentUserInfo as any).medical_scheme_membership_number)) {

            this.changesConfirmPopup();
        } else {
            this.disabledField();
        }

    }

    schemeTab() {
        this.profile_contact_scheme = 'scheme';
        this.tabIndex = 3;
        this.lastTabActive = true;
        this.nameDisabled = true;
        this.surnameDisabled = true;
        this.schemeDisabled = true;
        this.genderDisabled = true;
        this.birthdayDisabled = true;

        if (((this.existsCurrentUserInfo as any).gender !== this.selectedGender)
            || ((this.existsCurrentUserInfo as any).family_name !== (this.currentUserInfo as any).family_name)
            || ((this.existsCurrentUserInfo as any).given_name !== (this.currentUserInfo as any).given_name)
            || this.existsRestrictionDateValue.getTime() !== this.restrictionDateValue.getTime()) {

            this.changesConfirmPopup();
        } else {
            this.disabledField();
        }

    }

    changesConfirmPopup() {
        let d = this;
        abp.message.confirm('', 'Do you want to save profile changes before you continue?',
            function (isConfirmed) {
                if (isConfirmed) {
                    this._authservice.getUser((res: Profile) => {
                        d.currentUserInfo = res;
                        d.selectedGender = d.currentUserInfo.gender;
                        let birthDate = d.currentUserInfo.birthdate;
                        d.restrictionDateValue = moment(birthDate, 'DD/MM/YYYY hh:mm:ss A').toDate();
                    });
                } else {
                    d.save(null);
                }
            }, true
        );
        document.getElementsByClassName('swal2-confirm')[0].innerText = 'Continue without saving';
        document.getElementsByClassName('swal2-confirm')[0].classList.add('modal-button-clr');
        document.getElementsByClassName('swal2-confirm')[0].classList.add('border-radius-55');
        document.getElementsByClassName('swal2-confirm')[0].classList.add('font-size-12');


        document.getElementsByClassName('swal2-cancel')[0].innerText = 'Save';
        document.getElementsByClassName('swal2-cancel')[0].classList.add('modal-button-clr');
        document.getElementsByClassName('swal2-cancel')[0].classList.add('border-radius-55');
        document.getElementsByClassName('swal2-cancel')[0].classList.add('font-size-12');
    }

    disabledField() {
        this.nameDisabled = true;
        this.surnameDisabled = true;
        this.schemeDisabled = true;
        this.genderDisabled = true;
        this.birthdayDisabled = true;
    }

    updateQrCodeSetupImageUrl(): void {
        this._profileService.updateGoogleAuthenticatorKey().subscribe((result: UpdateGoogleAuthenticatorKeyOutput) => {
            this.user.qrCodeSetupImageUrl = result.qrCodeSetupImageUrl;
            this.isGoogleAuthenticatorEnabled = true;
        });
    }

    smsVerify(): void {
        //this._profileService.sendVerificationSms()
        //    .subscribe(() => {
        //        this.smsVerificationModal.show();
        //    });
    }

    changePhoneNumberToVerified(): void {
        this.isPhoneNumberConfirmed = true;
    }

    onShown(): void {
        document.getElementById('Name').focus();
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(saveForm: string): void {
        this.saving = true;
        this.saving = false;


        let claimList = new ModifyClaimDto();

        let claims = new UserClaimEditDto();
        claimList.userClaimEditDtos = new Array<UserClaimEditDto>();
        abp.ui.setBusy();


        let given_name = (this.currentUserInfo as any).given_name;
        claims.claimType = 'given_name';
        claims.claimValue = given_name;
        claimList.userClaimEditDtos.push(claims);


        let family_name = (this.currentUserInfo as any).family_name.toString();
        claims = new UserClaimEditDto();
        claims.claimType = 'family_name';
        claims.claimValue = family_name;
        claimList.userClaimEditDtos.push(claims);

        let selectedDate = moment(this.restrictionDateValue).format('DD/MM/YYYY hh:mm:ss A');
        claims = new UserClaimEditDto();
        claims.claimType = 'birthdate';
        claims.claimValue = selectedDate;
        claimList.userClaimEditDtos.push(claims);

        let selectedGender = this.selectedGender;
        claims = new UserClaimEditDto();
        claims.claimType = 'gender';
        claims.claimValue = selectedGender;
        claimList.userClaimEditDtos.push(claims);

        claimList.accessToken = this._authService.user.access_token;

        let medical_scheme_membership_number = (this.currentUserInfo as any).medical_scheme_membership_number;
        claims = new UserClaimEditDto();
        claims.claimType = 'medical_scheme_membership_number';
        claims.claimValue = medical_scheme_membership_number;
        claimList.userClaimEditDtos.push(claims);

        claimList.userId = (this.currentUserInfo as any).sub;

        this._claimModifyService.modifyClaim(claimList).subscribe(() => {
            abp.event.trigger('updateProfileName');
            this.lastTabActive ? this.saveContinueSubmit('SavedSuccessfully', null) : this.saveContinueSubmit('ChangesSavedSuccessfully', saveForm);


        }, (error) => {
            abp.ui.clearBusy();

            console.log(error);
        });

    }

    saveContinueSubmit(value, saveForm: string) {
        if (value === 'SavedSuccessfully') {
            this.notify.info(this.l('SavedSuccessfully'));
            abp.ui.clearBusy();
            this.close();
        } else {
            this.notify.info(this.l('ChangesSavedSuccessfully'));
            if (saveForm != null) {
                this.tabIndex === 1 ? this.tabIndex = 2 : this.tabIndex = 3;
                // tslint:disable-next-line: no-unused-expression
                this.tabIndex === 3 ? this.lastTabActive = true : null;
                this.nameDisabled = true;
                this.surnameDisabled = true;
                this.schemeDisabled = true;
                this.genderDisabled = true;
                this.birthdayDisabled = true;
            }

            abp.event.trigger('getExistsUserInfo');
            abp.ui.clearBusy();
        }
        this.modalSave.emit(null);
    }

    onValueChange(value: Date): void {
        let restrictionyear = new Date().getFullYear() - 18;
        let selectedFullyear = value != null ? value.getFullYear() : null;
        if (selectedFullyear >= restrictionyear) {
            this.restrictionMessage = true;
            this.saving = true;
        } else {
            this.restrictionMessage = false;
            this.saving = false;
        }
    }

    onChange(value: any) {
        if (value === '') {
            this.restrictionMessage = false;
        }
        this.restrictionDateValue = value;

    }

    edit(value: any) {

        this.nameDisabled = true;
        this.surnameDisabled = true;
        this.schemeDisabled = true;
        this.genderDisabled = true;
        this.birthdayDisabled = true;
        this._authService.getUser((res: Profile) => {
            this.currentUserInfo = res;
            this.selectedGender = this.currentUserInfo.gender;
            let birthDate = this.currentUserInfo.birthdate;
            this.restrictionDateValue = moment(birthDate, 'DD/MM/YYYY hh:mm:ss A').toDate();
        });

        switch (value) {
            case 'name': this.nameDisabled = false;
                break;
            case 'surname': this.surnameDisabled = false;
                break;
            case 'birthday': this.birthdayDisabled = false;
                break;
            case 'gender': this.genderDisabled = false;
                break;
            case 'scheme': this.schemeDisabled = false;
                break;
        }
    }
}
