import { Component, OnInit, Injector, NgZone, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MyHealthcareService } from '@app/shared/services/my-healthcare.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { XYChart } from '@amcharts/amcharts4/charts';
import { SmsBody } from '@shared/service-proxies/service-proxies';
import { CurrencyPipeZar } from '@shared/common/pipes/commonElevate.pipe';
am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);


@Component({
  selector: 'app-episode-care',
  templateUrl: './episode-care.component.html',
  styleUrls: ['./episode-care.component.css', '../../../../../main/my-healthcare/my-healthcare.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [CurrencyPipeZar]
})
export class EpisodeCareComponent extends AppComponentBase implements OnInit {
  isLoading: boolean;
  currentUserQuery = '';
  yearActive = new Array<boolean>();
  showChart: boolean = undefined;
  chart: XYChart;
  currentEpisodeYear: string;
  imgSrc: any = '../../../assets/common/images/chatblack.svg';

  constructor(
    private zone: NgZone,
    injector: Injector,
    private _changeDetecter: ChangeDetectorRef,
    public _healtCareService: MyHealthcareService,
    public currencyProvider: CurrencyPipeZar
  ) {
    super(injector);
  }

  ngOnInit() {
  }


  loadEpisodeOFCare() {
    this.isLoading = true;
    this.updateClaimsIdsvr().then(res => {
      this.getHealthCareYearDropDownList();
    });
  }

  getHealthCareYearDropDownList() {

    this.currentEpisodeYear = this._healtCareService.yearList[0].toString();
    this.yearDropDownDefaultAction();
    if (this._healtCareService.yearList.length > 0) {
      this.getEpisodeofCareData(this._healtCareService.yearList[0].toString());
    } else {
      this.isLoading = false;
      this.showChart = false;
    }
  }
  getEpisodeofCareData(year: string) {
    this.isLoading = true;
    this.currentEpisodeYear = year;
    let tempThis = this;
    this._healtCareService.getEpisodeData(year).then(() => {
      tempThis.loadChart();
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      this.isLoading = false;
    });
  }

  loadChart() {
    this.showChart = this._healtCareService.episodeCareData.length > 0 ? true : false;
    this._changeDetecter.detectChanges();

    if (this.showChart) {
      this.loadEpisodeCare();
    }
  }

  getCurrencyValue(val: string): string {
    if (!val) {
      val = '0';
    }

    return this.currencyProvider.transform(+val, true, true, true);
  }

  loadEpisodeCare() {

    this.zone.runOutsideAngular(() => {
      this.chart = am4core.create('episodeofcarechart', am4charts.XYChart);
      this.chart.tapToActivate = true;
      this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
      this.chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';
      this.chart.responsive.enabled = false;
      this.chart.data = [];
      this._healtCareService.episodeCareData.forEach(res => {
        this.chart.data.push({ name: res.description, fromDate: res.startDate, toDate: res.endDate, Diagnosis: res.diagnosis, Amount_Claimed: this.getCurrencyValue(res.amount_claimed), shortfallamount: this.getCurrencyValue(res.shortfall_Amount), paidAmount: this.getCurrencyValue(res.paidAmount) });
      });
      this.chart.numberFormatter.numberFormat = '# ###.##';
      let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'name';
      categoryAxis.renderer.grid.template.location = 1;
      categoryAxis.renderer.inversed = true;
      categoryAxis.renderer.line.strokeOpacity = 1;
      categoryAxis.renderer.labels.template.wrap = true;
      categoryAxis.renderer.labels.template.width = 100;
      categoryAxis.renderer.labels.template.inside = false;
      categoryAxis.renderer.minGridDistance = 20;

      //categoryAxis.renderer.line.dx = 100;
      //categoryAxis.startLocation = -0.3;


      let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.dateFormatter.dateFormat = 'yyyy-MM-dd';
      dateAxis.renderer.tooltipLocation = 0;
      dateAxis.renderer.grid.template.disabled = true;
      dateAxis.baseInterval = { timeUnit: 'day', count: 5 },
        dateAxis.strictMinMax = false;
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.labels.template.rotation = -90;

      let series1 = this.chart.series.push(new am4charts.ColumnSeries());
      series1.columns.template.width = am4core.percent(60);
      series1.columns.template.tooltipHTML = '{fromDate.formatDate(\'dd MMM, yyyy\')} <br> {Diagnosis} <br> Claimed: {Amount_Claimed} <br> Paid: {paidAmount.formatNumber(\'#,###.##\')}';
      series1.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      series1.tooltip.getFillFromObject = false;
      series1.tooltip.background.fill = am4core.color('#B01917');
      series1.dataFields.openDateX = 'fromDate';
      series1.dataFields.dateX = 'toDate';
      series1.dataFields.categoryY = 'name';
      series1.columns.template.fill = am4core.color('#B01917'); // get color from data
      series1.columns.template.stroke = am4core.color('#B01917');
      series1.columns.template.strokeOpacity = 1;
      series1.columns.template.column.cornerRadiusTopLeft = 50;
      series1.columns.template.column.cornerRadiusTopRight = 50;
      series1.columns.template.marginLeft = 50;
      series1.columns.template.column.cornerRadiusBottomLeft = 50;
      series1.columns.template.column.cornerRadiusBottomRight = 50;
      series1.columns.template.height = 20;
      series1.columns.template.fill = am4core.color('#B01917');
      series1.tooltip.getFillFromObject = false;
      series1.tooltip.background.fill = am4core.color('black');
      series1.sequencedInterpolation = true;
      this.chart.cursor = new am4charts.XYCursor();
      this.chart.cursor.behavior = 'zoomXY';
      let cellSize = 80;
      this.chart.events.on('datavalidated', function (ev) {
        // Get objects of interest
        let chartq = ev.target;
        let categoryAxisq = chartq.yAxes.getIndex(0);

        // Calculate how we need to adjust chart height
        let adjustHeight = chartq.data.length * cellSize - categoryAxisq.pixelHeight;

        // get current chart height
        let targetHeight = chartq.pixelHeight + (adjustHeight < 0 ? 0 : adjustHeight);

        // Set it on chart's container
        chartq.svgContainer.htmlElement.style.height = targetHeight + 'px';
      });
    });

  }

  yearDropDownDefaultAction() {
    let i = 0;
    this._healtCareService.yearList.forEach(x => {
      if (i === 0) {
        this.yearActive.push(true);
      } else {
        this.yearActive.push(false);
      }
      i++;
    });
  }


  FilterWhithYearEpisode(item, i) {
    this.showChart = undefined;
    this.currentEpisodeYear = item;
    let d = this.yearActive.findIndex(x => x === true);
    this.yearActive[d] = false;
    this.yearActive[i] = true;
    this.getEpisodeofCareData(item);
  }


  sendsms(PageName?) {
    let body = new SmsBody();
    body.userid = this._healtCareService._commonHelper._user.profile.sub;
    body.firstName = this._healtCareService._commonHelper._user.profile.given_name;
    body.lastName = this._healtCareService._commonHelper._user.profile.family_name;
    body.email = this._healtCareService._commonHelper._user.profile.email;
    body.phoneNumber = Array.isArray(this._healtCareService._commonHelper._user.profile.phone_number) ? this._healtCareService._commonHelper._user.profile.phone_number[0] : this._healtCareService._commonHelper._user.profile.phone_number;
    body.pageName = PageName;
    body.message = this.currentUserQuery;
    this.sendQueryEmail(body);
    this.currentUserQuery = '';
  }

}
