var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ErrorHandler, Injector } from '@angular/core';
import { AzureInsightsService } from './azure-insights.service';
import * as i0 from "@angular/core";
var MonitorServiceService = /** @class */ (function (_super) {
    __extends(MonitorServiceService, _super);
    function MonitorServiceService(injector) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        return _this;
    }
    MonitorServiceService.prototype.handleError = function (error) {
        //abp.ui.clearBusy();
        var monitoringService = this.injector.get(AzureInsightsService);
        monitoringService.logError(error);
        _super.prototype.handleError.call(this, error);
    };
    MonitorServiceService.ngInjectableDef = i0.defineInjectable({ factory: function MonitorServiceService_Factory() { return new MonitorServiceService(i0.inject(i0.INJECTOR)); }, token: MonitorServiceService, providedIn: "root" });
    return MonitorServiceService;
}(ErrorHandler));
export { MonitorServiceService };
