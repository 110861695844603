import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MyHealthcareRoutingModule } from './my-healthcare-routing.module';
import { ModalModule, BsDropdownModule, TooltipModule, BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap';
import { AppCommonModule } from '@app/shared/common/app-common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { MyHealthcareComponent } from './my-healthcare.component';
import { MyHealthcareWidgetsModule } from '@app/shared/common/widgets/my-healthcare-widgets/my-healthcare-widgets.module';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { HealthRiskAssessmentOVerviewComponent } from './health-risk-assessment-overview/health-risk-assessment-overview.component';
import { MyCommandCenterService } from '../my-command-centre/my-command-center.service';
import { HraAssessmentComponent } from './hra-assessment/hra-assessment.component';
import { HraIntroModalComponent } from './hra-intro-modal/hra-intro-modal.component';
import { SliderModule } from '@syncfusion/ej2-angular-inputs';

@NgModule({
  declarations: [
    MyHealthcareComponent,
    HealthRiskAssessmentOVerviewComponent,
    HraAssessmentComponent,
    HraIntroModalComponent
  ],
  imports: [
    CommonModule,
    ModalModule,
    AppCommonModule,
    UtilsModule,
    NgbModule,
    BsDropdownModule.forRoot(),
    TooltipModule,
    RouterModule,
    MyHealthcareRoutingModule,
    MyHealthcareWidgetsModule,
    MultiSelectModule,
    ButtonModule,
    SliderModule
  ],
  providers: [
    DatePipe,
    MyCommandCenterService,
    { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
    { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
    { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    HraIntroModalComponent
  ]
})
export class MyHealthcareModule { }
