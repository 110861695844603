var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { Injector, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { forkJoin, Observable } from 'rxjs';
import { ProductListModule, BenefitModule, PremiumPatternModule, RateTableModule, BenficiaryModule, BenificeryModule, CommissionModule, QuoteModule, IQuircAnalysisAfterSubmitting, PersonModule, GetMaxSumAssuredModule, defaultOccupationClass, GetAllQuotes } from '@shared/models/interfaces/elevate-data-models';
import { ProductService } from '@app/shared/services/product.service';
import { map } from 'rxjs/operators';
import { GetQuestionariesModule, SubmitAnswerAndGetUpdatedQuestions, CreateQuote, CreateOrUpdatePolicyInputModule, SubmitQuestionnaireModule, PolicyModule, CreateOrUpdatePolicyDtoOutpuModel, MedicalInsuranceAssessment } from '@app/shared/models_elevate/models';
import { QuirkService } from '@app/shared/services/quirk-api-service-proxy.service';
import { AppConsts } from '@shared/AppConsts';
import { AnonymousUserDetail } from '@app/shared/services/quote.service';
import * as moment from 'moment';
import { PolicyService } from '@app/shared/services/policy.service';
import { ProductFactory } from '@app/shared/FactoryResources/ProductFactory/IIncompleteProduct';
import { HttpHeaders } from '@angular/common/http';
import { HttpMethodBaseService } from '@app/shared/services/HttpMethodsBase/httpmethods-base';
var MyCommandCenterService = /** @class */ (function (_super) {
    __extends(MyCommandCenterService, _super);
    function MyCommandCenterService(injector, httpMethodBaseService) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.httpMethodBaseService = httpMethodBaseService;
        _this.CEDANT_ID = abp.setting.values[AppConsts.KeyVaultSecrets.cedantId];
        _this.API_BASE_URL_IDP = abp.setting.values['PortalSettings.authority'];
        _this.productQuoteDictionary = new Map();
        _this.hasFinishedSubscription = true;
        _this.vm = {
            externalQuoteReferencId: '',
            sumAssuredExtQuote: '',
            productSyncedOrNot: false,
            producSpinnerEmitter: new EventEmitter(),
            productEmitter: new EventEmitter(),
            productDetailsFromFactory: new Array(),
            questionsTobeStore: new Array(),
            currentPolicyId: '',
            CustomerReferenceid: '',
            CedantId: abp.setting.values[AppConsts.KeyVaultSecrets.cedantId],
            userDetails: new AnonymousUserDetail(),
            initialUserDetails: new AnonymousUserDetail(),
            entityId: '',
            opportunityId: '',
            quoteId: '',
            productDetails: new Array(),
            quotedProductDetails: new Array(),
            conditionliststore: new Map(),
            selectedProduct: new Array(),
            medicalStepsValidater: new Map(),
            additionalInfoStepsValidater: new Map(),
            productSummary: Array(),
            quircAfterSubmittingAnalysis: Array(),
            isImprovingQuoteNeccesary: false,
            isReferred: false,
            isMinimumBenefitNotMet: false,
            submitQuestionaireResult: null,
            quotePolicyCouples: [],
            acceptedQuotePolicyCouples: [],
            quickQuoteMaxSumAssuredInput: {
                age: null,
                existingCover: {
                    currentElevate: null,
                    existingElevate: null,
                    existingOther: null,
                    productSubCategory: null,
                },
                grossMonthlyIncome: null,
                houseHoldIncome: null,
                isAccelerator: null,
                isSelfEmployed: false,
                mainProductCurrentSumAssured: null,
                netMonthlyIncome: null,
                employmentCategory: null,
                needsAnalysis: 'None',
                policyReason: null,
                productCategory: null,
                maxBenefitAmount: null
            },
            productExclusionMap: [],
            riderProductAdded: null,
            riderProductRemoved: null,
            needsAssessmentSumAssuredLevels: [],
            insuranceAssessment: new MedicalInsuranceAssessment(),
            lifeInsuredPolicies: []
        };
        _this.paymentObject = {
            productDetails: new Array(),
            beneficiaryDetails: new Array(),
            bankingDetails: new Array()
        };
        if (_this._authGuard.user) {
            _this.vm.entityId = _this._authGuard.user.profile.sub;
        }
        return _this;
    }
    Object.defineProperty(MyCommandCenterService.prototype, "productService", {
        get: function () {
            if (!this._productService) {
                this._productService = this.injector.get(ProductService);
            }
            return this._productService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MyCommandCenterService.prototype, "policyService", {
        get: function () {
            if (!this._policyService) {
                this._policyService = this.injector.get(PolicyService);
            }
            return this._policyService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MyCommandCenterService.prototype, "quirkService", {
        get: function () {
            if (!this._quirkService) {
                this._quirkService = this.injector.get(QuirkService);
            }
            return this._quirkService;
        },
        enumerable: true,
        configurable: true
    });
    MyCommandCenterService.prototype.initCommandCenterVMRESET = function () {
        var _this = this;
        this.vm.quotedProductDetails = [];
        this.vm.questionsTobeStore = [];
        this.vm.quoteId = '';
        this.vm.currentPolicyId = '';
        this.vm.selectedProduct = [];
        this.vm.productSummary = [];
        this.vm.isReferred = false;
        this.vm.isMinimumBenefitNotMet = false;
        this.vm.submitQuestionaireResult = {};
        this.vm.quotePolicyCouples = [];
        this.vm.acceptedQuotePolicyCouples = [];
        this.vm.medicalStepsValidater = new Map();
        this.vm.productExclusionMap = [];
        this.vm.needsAssessmentSumAssuredLevels = [];
        this.vm.productDetailsFromFactory.forEach(function (x) {
            x.CurrentBenefitPattern = undefined;
            x.CurrentPremiumPattern = undefined;
            x.IsProductQuoted = false;
            x.currentRateTable = undefined;
            x.currentPremiumValue = undefined;
            x.CurrentAssuredValue = _this.getDeepCopied(x.MinBenefitAmount);
            if (!x.AssociatedProducts) {
                return;
            }
            x.AssociatedProducts.forEach(function (item) {
                item.CurrentBenefitPattern = undefined;
                item.CurrentPremiumPattern = undefined;
                item.IsProductQuoted = item.RequirementType === AppConsts.requirementTyoe.Selectable ? false : true;
                item.currentRateTable = undefined;
                item.CurrentAssuredValue = _this.getDeepCopied(item.MinBenefitAmount);
                item.currentPremiumValue = undefined;
            });
        });
    };
    MyCommandCenterService.prototype.getMaxSumAssured = function (productUid, maxBenefitAmount, options) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            function getExistingCover(skipExistingCover, policyResult) {
                if (skipExistingCover) {
                    return {
                        currentElevate: 0,
                        existingElevate: 0,
                        existingOther: 0,
                        productSubCategory: '',
                        mainProductCheck: false
                    };
                }
                return {
                    existingOther: getExistingOtherCover(quircProduct.AggregationCategory, policyResult),
                    currentElevate: getElevateCover(quircProduct.AggregationCategory, self.vm.quotePolicyCouples, productUid),
                    existingElevate: getElevateCover(quircProduct.AggregationCategory, self.vm.acceptedQuotePolicyCouples),
                    productSubCategory: options.productSubCategory,
                    mainProductCheck: options.mainProductCheck
                };
            }
            function getNeedsAssessmentStatus(userDetails, skipCreditCheck, answeredQuestions) {
                if (answeredQuestions === void 0) { answeredQuestions = null; }
                var covertYesNoQuestionToBool = function (question) {
                    return question && (question.AnswerText === 'Yes' || question.Text === 'Yes');
                };
                var getNeedsAssessmentStringValue = function (needsAssessment, creditCheck, proofOfIncome) {
                    if (needsAssessment) {
                        if (proofOfIncome && (creditCheck || skipCreditCheck)) {
                            return AppConsts.NeedsAnalysis.Full;
                        }
                        return AppConsts.NeedsAnalysis.Partial;
                    }
                    return AppConsts.NeedsAnalysis.None;
                };
                if (answeredQuestions) {
                    var creditCheckQuestion = answeredQuestions.find(function (q) { return q.ExternalReferenceId === AppConsts.QuirckExtQuestionId.DndCreditCheck; });
                    var proofOfIncomeQuestion = answeredQuestions.find(function (q) { return q.ExternalReferenceId === AppConsts.QuirckExtQuestionId.DndIncomeConfirmation; });
                    var needsAssessmentQuestion = answeredQuestions.find(function (q) { return q.ExternalReferenceId === AppConsts.QuirckExtQuestionId.DndNaIndicator; });
                    if (creditCheckQuestion && proofOfIncomeQuestion && needsAssessmentQuestion) {
                        var creditCheckAnswer = covertYesNoQuestionToBool(creditCheckQuestion);
                        var proofOfIncomeAnswer = covertYesNoQuestionToBool(proofOfIncomeQuestion);
                        var needsAssessmentAnswer = covertYesNoQuestionToBool(needsAssessmentQuestion);
                        return getNeedsAssessmentStringValue(needsAssessmentAnswer, creditCheckAnswer, proofOfIncomeAnswer);
                    }
                }
                return getNeedsAssessmentStringValue(userDetails.needsAssessment, userDetails.creditCheck, userDetails.proofOfIncome);
            }
            function getElevateCover(aggregationCategory, quotePolicyCouples, 
            // If product GUID is included, that product will not be included in the calculation
            productGuid) {
                if (productGuid === void 0) { productGuid = null; }
                var e_1, _a;
                if (!aggregationCategory) {
                    return 0;
                }
                var quircProductsInCategory = self.vm.quircAfterSubmittingAnalysis
                    .filter(function (q) {
                    return q.AggregationCategory != null &&
                        q.AggregationCategory === aggregationCategory &&
                        q.MendixProductUID !== productGuid;
                });
                if (quircProductsInCategory.length === 0) {
                    return 0;
                }
                var invalidQuotesFromPolicies = self.vm.lifeInsuredPolicies.filter(function (x) { return x.policyStatus.indexOf('lapsed') >= 0; }).map(function (x) { return x.quoteGuid; });
                var productsQuoted = quotePolicyCouples
                    .filter(function (q) { return q.quote.ProductQuoteds && Array.isArray(q.quote.ProductQuoteds) && !invalidQuotesFromPolicies.includes(q.quote.UID); })
                    .map(function (q) { return q.quote.ProductQuoteds; })
                    .customFlat();
                var quircMendixProductMapping = [];
                var _loop_1 = function (quircProductInCategory) {
                    var productsQuotedInCategory = productsQuoted.filter(function (q) { return q.ProductConfig.UID === quircProductInCategory.MendixProductUID; });
                    if (!productsQuotedInCategory || productsQuotedInCategory.length === 0) {
                        return "continue";
                    }
                    quircMendixProductMapping.push.apply(quircMendixProductMapping, __spread(productsQuotedInCategory.map(function (p) { return ({
                        mendix: p,
                        quirc: quircProductInCategory
                    }); })));
                };
                try {
                    for (var quircProductsInCategory_1 = __values(quircProductsInCategory), quircProductsInCategory_1_1 = quircProductsInCategory_1.next(); !quircProductsInCategory_1_1.done; quircProductsInCategory_1_1 = quircProductsInCategory_1.next()) {
                        var quircProductInCategory = quircProductsInCategory_1_1.value;
                        _loop_1(quircProductInCategory);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (quircProductsInCategory_1_1 && !quircProductsInCategory_1_1.done && (_a = quircProductsInCategory_1.return)) _a.call(quircProductsInCategory_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                var quotedAssuredValue = quircMendixProductMapping
                    .map(function (q) { return q.quirc.MendixProductDisplayName === 'Family-Health Severe Illness Protection - Accelerator' ? q.mendix.AssuredValue * 12 * 10 : q.mendix.AssuredValue; })
                    .reduce(function (acc, cur) { return acc + cur; }, 0);
                return quotedAssuredValue;
            }
            function getExistingOtherCover(aggregationCategory, updatePolicyResult) {
                var _a;
                var extIdAggregationCategoryMap = (_a = {},
                    _a[AppConsts.AggregationCategories.lifeProtection] = AppConsts.QuirckExtQuestionId.ExistingLifeCover,
                    _a[AppConsts.AggregationCategories.disabilityProtection] = AppConsts.QuirckExtQuestionId.ExistingIncomeProtectionCover,
                    _a[AppConsts.AggregationCategories.impairmentProtection] = AppConsts.QuirckExtQuestionId.ExistingImpairmentCover,
                    _a[AppConsts.AggregationCategories.severeIllnessProtection] = AppConsts.QuirckExtQuestionId.ExistingSevereIllnessCover,
                    _a);
                var extId = extIdAggregationCategoryMap[aggregationCategory];
                if (!extId) {
                    return 0;
                }
                return +getValueOrDefault(updatePolicyResult.PolicyDetail.AnsweredQuestions, extId).Value || 0;
            }
            function getValueOrDefault(res, extid, fallbackExtId) {
                if (fallbackExtId === void 0) { fallbackExtId = null; }
                var question = res.find(function (x) { return x.ExternalReferenceId === extid; });
                if (question) {
                    return question;
                }
                if (fallbackExtId) {
                    return getValueOrDefault(res, fallbackExtId);
                }
                return '';
            }
            function getOccupationSpecific(isSelfEmp, valueGiven) {
                if (isSelfEmp) {
                    return AppConsts.Occupations.SelfEmployed;
                }
                if (valueGiven.toLowerCase().indexOf('student') >= 0) {
                    return AppConsts.Occupations.Student;
                }
                if (valueGiven.toLowerCase().indexOf('wife') >= 0 || valueGiven.toLowerCase().indexOf('husband') >= 0) {
                    return AppConsts.Occupations.HouseWifeOrHouseHusband;
                }
                if (valueGiven.toLowerCase().indexOf('unemployed') >= 0) {
                    return AppConsts.Occupations.Unemployed;
                }
                return AppConsts.Occupations.Employed;
            }
            var self, quircProduct, policyResult, policyReason, input, sumAssuredResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        self = this;
                        quircProduct = this.vm.quircAfterSubmittingAnalysis.find(function (q) { return q.MendixProductUID === productUid; });
                        return [4 /*yield*/, this.quirkService.createOrUpdatePolicy({
                                AnsweredQuestions: [],
                                CedantId: this.vm.CedantId,
                                CustomerReferenceid: this.vm.entityId,
                                DisplayAnswers: 'true',
                                PolicyReferenceId: this.vm.currentPolicyId,
                                ProcessDisclosures: 'true',
                                ShowErrorDetails: '1'
                            }, true).toPromise()];
                    case 1:
                        policyResult = _a.sent();
                        policyReason = getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.PolicyReason);
                        input = {
                            age: +getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.Age).Value,
                            existingCover: getExistingCover(options.skipExistingCover, policyResult),
                            grossMonthlyIncome: this.vm.userDetails.confirmedGrossIncome || +getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.GrossMonthlyIncome).Value,
                            houseHoldIncome: +getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.HouseHoldIncome, AppConsts.QuirckExtQuestionId.GrossMonthlyIncome).Value,
                            isSelfEmployed: getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.IsSelfEmployed).Value === '0' ? false : true,
                            netMonthlyIncome: this.vm.userDetails.confirmedNetIncome || +getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.NetMonthlyIncome, AppConsts.QuirckExtQuestionId.GrossMonthlyIncome).Value,
                            employmentCategory: getOccupationSpecific(getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.IsSelfEmployed).Value === '0' ? false : true, getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.EmploymentCategory).Text),
                            needsAnalysis: getNeedsAssessmentStatus(this.vm.userDetails, false, policyResult.PolicyDetail.AnsweredQuestions),
                            policyReason: policyReason.AnswerText || policyReason.Text,
                            productCategory: quircProduct.QuircProfileName,
                            isAccelerator: options.isAccelerator,
                            mainProductCurrentSumAssured: options.mainProductCurrentSumAssured,
                            maxBenefitAmount: maxBenefitAmount
                        };
                        return [4 /*yield*/, this.productService.getMaxAndMinSummAssured(input).toPromise()];
                    case 2:
                        sumAssuredResult = _a.sent();
                        return [2 /*return*/, sumAssuredResult];
                }
            });
        });
    };
    MyCommandCenterService.prototype.createOrEditRider = function (data, quoteId, productQuoteID, riderQuotedId) {
        return this._productService.createOrUpdateRiderProduct(data, quoteId, productQuoteID, riderQuotedId)
            .pipe(map(function (res) {
            return res;
        }));
    };
    MyCommandCenterService.prototype.createOrUpdateBeneficiaryAndGetEntityId = function (beneficiaryDetails) {
        return this.productService.createOrUpdateBeneficiaryAndGetEntityId(beneficiaryDetails)
            .pipe(map(function (res) {
            return res;
        }));
    };
    MyCommandCenterService.prototype.createBeneficieryQuirk = function (beneficiaryForQuote, productQuotedId, quoteId) {
        return this.productService.addBeneficeryToQuote(productQuotedId, quoteId, beneficiaryForQuote)
            .pipe(map(function (res) {
            return true;
        }));
    };
    MyCommandCenterService.prototype.createInclusiveSet = function (quoteId) {
        return this.productService.addNonIndividualExclusionSet(quoteId)
            .pipe(map(function (res) {
            return true;
        }));
    };
    MyCommandCenterService.prototype.getAQuote = function (productQuotedId, benefitPatternId, premiumPatternId, quoteId) {
        var _this = this;
        var productQuoteId = this.productQuoteDictionary.get(quoteId);
        var productQuoteSearchString = quoteId + productQuoteId + benefitPatternId + premiumPatternId;
        //let dataDictionary = this.productQuoteDictionary.get(productQuoteSearchString);
        //if (dataDictionary) {
        //    return of(dataDictionary);
        //}
        var response = this.productService.getAQuote(quoteId)
            .pipe(map(function (res) {
            if (res.ProductQuoteds) {
                var keyValue = res.UID + res.ProductQuoteds[res.ProductQuoteds.length - 1].ProductConfig.UID + res.ProductQuoteds[res.ProductQuoteds.length - 1].BenefitPattern.UID + res.ProductQuoteds[res.ProductQuoteds.length - 1].PremiumPattern.UID;
                _this.productQuoteDictionary.set(productQuoteSearchString, res.ProductQuoteds[res.ProductQuoteds.length - 1]);
                return res.ProductQuoteds;
            }
        }));
        return response;
    };
    MyCommandCenterService.prototype.getQuestionByExtId = function (item) {
        return this.quirkService.getQuestionByExtReferenceId(item);
    };
    MyCommandCenterService.prototype.getProductList = function () {
        return this.productService.getProductList()
            .pipe(map(function (res) {
            return res.getParsedMainAndRiderProduct();
        }));
    };
    MyCommandCenterService.prototype.getBenifitPatterns = function (uid) {
        return this.productService.getBenefitPatterSetForMultipleUid(uid)
            .pipe(map(function (res) {
            return res;
        }));
    };
    MyCommandCenterService.prototype.getPremiumPatterns = function (uid) {
        return this.productService.getPremiumPatterSetForMultipleUid(uid)
            .pipe(map(function (res) {
            return res;
        }));
    };
    MyCommandCenterService.prototype.getQuestionForSection = function (sectionName, entityId, policyId, isCached) {
        if (isCached === void 0) { isCached = true; }
        return this.quirkService.getQuestionForStepper(sectionName, null, this.vm.entityId, policyId, [], isCached)
            .pipe(map(function (res) {
            return res;
        }));
    };
    MyCommandCenterService.prototype.submitQuestionAnswers = function (section, policyRefId, isCached) {
        if (policyRefId === void 0) { policyRefId = this.vm.currentPolicyId; }
        if (isCached === void 0) { isCached = false; }
        var submitQuestions;
        submitQuestions = {
            customerReferenceid: this.vm.entityId,
            policyReferenceId: policyRefId,
            sections: section
        };
        return this.quirkService.submitProcessQuestionaryAndProvideAsperDictonaryMapping(submitQuestions, this.vm.userDetails, isCached)
            .pipe(map(function (res) {
            return res;
        }));
    };
    MyCommandCenterService.prototype.createOrUpdateApplicant = function (emailId, firstName, surname, dob, callBackToCreatePolicy) {
        var self = this;
        this.initCommandCenterVMRESET();
        this.quirkService.createOrUpdateApplicant({
            CedantId: this.CEDANT_ID,
            Customer: {
                DateOfBirth: dob,
                EmailAddress: getHashed(emailId, 1),
                ExternalReference: this.vm.entityId,
                FirstName: getHashed(firstName, 1),
                NationalID: getHashed(self.vm.userDetails.identityNumber.toString(), 7),
                Surname: getHashed(surname, 1)
            },
            ShowErrorDetails: '1',
        }).subscribe(function (res) {
            callBackToCreatePolicy();
        }, function (err) {
            abp.ui.clearBusy();
        });
        function getHashed(value, countToKeep) {
            return value.substr(0, countToKeep).padEnd(value.length, '#');
        }
    };
    MyCommandCenterService.prototype.getQuotePolicyCouples = function (quotes, policies) {
        var e_2, _a;
        var quotePolicyCouples = [];
        var _loop_2 = function (quote) {
            var currentValues = [];
            if (quote.ProductQuoteds) {
                currentValues = quote.ProductQuoteds
                    .filter(function (p) { return p.PremiumValue > 0; })
                    .map(function (p) { return ({
                    productUid: p.ProductConfig.UID,
                    premium: p.PremiumValue,
                    sumAssured: p.AssuredValue
                }); });
            }
            quotePolicyCouples.push({
                quote: quote,
                policy: policies.find(function (p) { return p.ExternalReferenceId === quote.UID; }),
                currentValues: currentValues
            });
        };
        try {
            for (var quotes_1 = __values(quotes), quotes_1_1 = quotes_1.next(); !quotes_1_1.done; quotes_1_1 = quotes_1.next()) {
                var quote = quotes_1_1.value;
                _loop_2(quote);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (quotes_1_1 && !quotes_1_1.done && (_a = quotes_1.return)) _a.call(quotes_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return quotePolicyCouples;
    };
    MyCommandCenterService.prototype.updateQuoteInPolicyCouple = function (quote) {
        var couple = this.vm.quotePolicyCouples.find(function (c) { return c.quote.UID === quote.UID; });
        couple.quote = quote;
        if (quote.ProductQuoteds) {
            couple.currentValues = quote.ProductQuoteds.map(function (p) { return ({
                productUid: p.ProductConfig.UID,
                premium: p.PremiumValue,
                sumAssured: p.AssuredValue
            }); });
        }
    };
    MyCommandCenterService.prototype.updateCurrentValueInQuotePolicyCouple = function (quoteUid, currentValue) {
        var couple = this.vm.quotePolicyCouples.find(function (c) { return c.quote.UID === quoteUid; });
        var existingSliderValue = couple.currentValues.find(function (c) { return c.productUid === currentValue.productUid; });
        if (existingSliderValue) {
            existingSliderValue.premium = currentValue.premium;
            existingSliderValue.sumAssured = currentValue.sumAssured;
        }
        else {
            couple.currentValues.push(currentValue);
        }
    };
    MyCommandCenterService.prototype.getOpenQuoteIdFromPolicyCouples = function () {
        var e_3, _a;
        try {
            for (var _b = __values(this.vm.quotePolicyCouples), _c = _b.next(); !_c.done; _c = _b.next()) {
                var couple = _c.value;
                if (!couple.quote.ProductQuoteds || couple.quote.ProductQuoteds.length === 0) {
                    return couple.quote.UID;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return null;
    };
    MyCommandCenterService.prototype.getQuoteIdForProductFromPolicyCouples = function (productUid) {
        var e_4, _a;
        try {
            for (var _b = __values(this.vm.quotePolicyCouples), _c = _b.next(); !_c.done; _c = _b.next()) {
                var couple = _c.value;
                if (!couple.quote.ProductQuoteds) {
                    continue;
                }
                var product = couple.quote.ProductQuoteds.find(function (q) { return q.ProductConfig.UID === productUid; });
                if (product) {
                    return couple.quote.UID;
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return null;
    };
    MyCommandCenterService.prototype.clearLoadings = function () {
        this.vm.quircAfterSubmittingAnalysis.forEach(function (x) { x.sumPercentageLoading = 0; x.sumUnitLoading = 0; });
    };
    MyCommandCenterService.prototype.setLoadings = function () {
        var _this = this;
        var res = this.vm.submitQuestionaireResult.Products.map(function (x) { return ({
            quircProfileName: x.ProfileName,
            decisionName: x.DecisionName,
            sumUnitLoading: x.Premiums.reduce(function (a, b) { return a + b.UnitLoading; }, 0),
            sumPercentageLoading: x.Premiums.reduce(function (a, b) { return a + b.PercentageLoading; }, 0),
            productReferences: x.ProductReferences
        }); });
        this.clearLoadings();
        res.forEach(function (item) {
            var product = _this.vm.quircAfterSubmittingAnalysis.find(function (x) { return x.QuircProfileName === item.quircProfileName; });
            if (product) {
                _this.vm.quircAfterSubmittingAnalysis.filter(function (x) { return x.QuircProfileName === item.quircProfileName; }).forEach(function (ele) {
                    ele.decisionName = item.decisionName;
                    ele.sumUnitLoading = item.sumUnitLoading;
                    ele.sumPercentageLoading = item.sumPercentageLoading;
                    ele.productReferences = item.productReferences;
                });
            }
        });
    };
    MyCommandCenterService.prototype.excludeProducts = function () {
        var _this = this;
        return new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
            var e_5, _a, e_6, _b, useSemiUnderwritten, excludedProduct, policyInput, policyResult, selfEmployedQuestion, isSelfEmployed, selfEmployedProduct, deleteObservables, getQuoteObservables_1, productsToDelete, _c, _d, couple, _e, _f, product;
            var _this = this;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        useSemiUnderwritten = this.vm.submitQuestionaireResult.PolicyReferences.some(function (r) { return r.Title.toLocaleLowerCase().indexOf('semi') >= 0; });
                        if (useSemiUnderwritten) {
                            excludedProduct = this.vm.quircAfterSubmittingAnalysis.filter(function (x) { return x.decisionName === AppConsts.QuircDecisionName.Excluded || x.decisionName === AppConsts.QuircDecisionName.Postpone || x.MendixProductName.toLowerCase().indexOf('semi') === -1; }).map(function (x) { return x.MendixProductUID; });
                        }
                        else {
                            excludedProduct = this.vm.quircAfterSubmittingAnalysis.filter(function (x) { return x.decisionName === AppConsts.QuircDecisionName.Excluded || x.decisionName === AppConsts.QuircDecisionName.Postpone || x.MendixProductName.toLowerCase().indexOf('semi') >= 0; }).map(function (x) { return x.MendixProductUID; });
                        }
                        policyInput = {
                            AnsweredQuestions: [],
                            CedantId: this.vm.CedantId,
                            CustomerReferenceid: this.vm.entityId,
                            DisplayAnswers: 'true',
                            PolicyReferenceId: this.vm.currentPolicyId,
                            ProcessDisclosures: 'true',
                            ShowErrorDetails: '1'
                        };
                        return [4 /*yield*/, this.quirkService.createOrUpdatePolicy(policyInput, true).toPromise()];
                    case 1:
                        policyResult = _g.sent();
                        selfEmployedQuestion = policyResult.PolicyDetail.AnsweredQuestions.find(function (x) { return x.ExternalReferenceId === AppConsts.QuirckExtQuestionId.IsSelfEmployed; });
                        isSelfEmployed = selfEmployedQuestion.Value === '1';
                        if (!isSelfEmployed) {
                            selfEmployedProduct = this.vm.quircAfterSubmittingAnalysis
                                .filter(function (q) { return q.MendixProductDisplayName === AppConsts.configProducts.SelfEmployedEnhancer || q.MendixProductDisplayName === AppConsts.configProducts.AlternateSelfEmployedEnhancer; })
                                .map(function (x) { return x.MendixProductUID; });
                            if (selfEmployedProduct.length > 0) {
                                excludedProduct.push.apply(excludedProduct, __spread(selfEmployedProduct));
                            }
                        }
                        this.vm.productDetailsFromFactory
                            .forEach(function (x) { return x.isIncludedFromQuirc = true; });
                        this.vm.productDetailsFromFactory
                            .filter(function (x) { return excludedProduct.some(function (g) { return g === x.UID; }); })
                            .forEach(function (x) { return x.isIncludedFromQuirc = false; });
                        this.vm.productDetailsFromFactory
                            .filter(function (x) { return x.isIncludedFromQuirc; })
                            .forEach(function (rider) {
                            if (!rider.AssociatedProducts) {
                                return;
                            }
                            rider.AssociatedProducts.filter(function (x) { return excludedProduct.some(function (g) { return g === x.UID; }); }).forEach(function (x) { return x.isIncludedFromQuirc = false; });
                        });
                        if (this.vm.quotePolicyCouples && this.vm.quotePolicyCouples.length > 0 && excludedProduct.length > 0) {
                            deleteObservables = [];
                            getQuoteObservables_1 = [];
                            productsToDelete = [];
                            try {
                                for (_c = __values(this.vm.quotePolicyCouples), _d = _c.next(); !_d.done; _d = _c.next()) {
                                    couple = _d.value;
                                    if (!couple.quote.ProductQuoteds) {
                                        continue;
                                    }
                                    try {
                                        for (_e = __values(couple.quote.ProductQuoteds), _f = _e.next(); !_f.done; _f = _e.next()) {
                                            product = _f.value;
                                            if (excludedProduct.includes(product.ProductConfig.UID)) {
                                                if (productsToDelete.includes(product.ProductConfig.UID)) {
                                                    continue;
                                                }
                                                deleteObservables.push(this.productService.deleteRiderProduct(couple.quote.UID, product.ProductConfig.UID));
                                                getQuoteObservables_1.push(this.productService.getAQuote(couple.quote.UID));
                                                if (product.IsMainProduct) {
                                                    productsToDelete.push.apply(productsToDelete, __spread(couple.quote.ProductQuoteds.map(function (q) { return q.ProductConfig.UID; })));
                                                }
                                                else {
                                                    productsToDelete.push(product.ProductConfig.UID);
                                                }
                                            }
                                        }
                                    }
                                    catch (e_6_1) { e_6 = { error: e_6_1 }; }
                                    finally {
                                        try {
                                            if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                                        }
                                        finally { if (e_6) throw e_6.error; }
                                    }
                                }
                            }
                            catch (e_5_1) { e_5 = { error: e_5_1 }; }
                            finally {
                                try {
                                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                                }
                                finally { if (e_5) throw e_5.error; }
                            }
                            if (deleteObservables.length === 0) {
                                resolve();
                                return [2 /*return*/];
                            }
                            forkJoin(deleteObservables).subscribe(function (result) {
                                forkJoin(getQuoteObservables_1).subscribe(function (quoteResult) {
                                    var e_7, _a;
                                    try {
                                        for (var quoteResult_1 = __values(quoteResult), quoteResult_1_1 = quoteResult_1.next(); !quoteResult_1_1.done; quoteResult_1_1 = quoteResult_1.next()) {
                                            var quote = quoteResult_1_1.value;
                                            _this.updateQuoteInPolicyCouple(quote);
                                        }
                                    }
                                    catch (e_7_1) { e_7 = { error: e_7_1 }; }
                                    finally {
                                        try {
                                            if (quoteResult_1_1 && !quoteResult_1_1.done && (_a = quoteResult_1.return)) _a.call(quoteResult_1);
                                        }
                                        finally { if (e_7) throw e_7.error; }
                                    }
                                    resolve();
                                });
                            });
                        }
                        else {
                            resolve();
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
    MyCommandCenterService.prototype.getCommissionForQuoteGuid = function (commissions, quoteGuid) {
        if (!commissions) {
            return {
                commissionValues: [],
                primaryCommission: [],
                secondaryCommission: [],
                commissionPattern: null
            };
        }
        var commission = commissions
            .filter(function (c) { return c.commissionValues; })
            .find(function (c) { return c.commissionValues.length > 0 && c.commissionValues[0].quoteGuid === quoteGuid; });
        if (commission) {
            return commission;
        }
        return {
            commissionValues: [],
            primaryCommission: [],
            secondaryCommission: [],
            commissionPattern: null
        };
    };
    MyCommandCenterService.prototype.getInceptionDate = function () {
        return moment().add(1, 'month').startOf('month');
    };
    MyCommandCenterService.prototype.expireQuotes = function (quoteUids) {
        var _this = this;
        var quoteObservables = quoteUids.map(function (q) { return _this.quirkService.CreateOrUpdateQuote({ QuoteStatus: 'Expired' }, q); });
        forkJoin(quoteObservables).subscribe(function (result) {
            console.log('Expired quote ', result);
        }, function (err) {
            console.error(err);
        });
    };
    MyCommandCenterService.prototype.startQuoteJourney = function () {
        var _this = this;
        var self = this;
        function getQuoteDetails(quotes, updateQuote, updateInceptionDate) {
            if (updateQuote === void 0) { updateQuote = true; }
            if (updateInceptionDate === void 0) { updateInceptionDate = false; }
            return new Promise(function (resolve, reject) {
                var quoteObservables = quotes.map(function (q) { return self.productService.getAQuote(q.QuoteUID); });
                if (quoteObservables.length === 0) {
                    resolve([]);
                    return;
                }
                forkJoin(quoteObservables).subscribe(function (result) {
                    var inceptionDate = moment();
                    var updateQuoteObservables;
                    if (updateInceptionDate || result.some(function (r) { return moment(r.InceptionDate).isSameOrBefore(inceptionDate); })) {
                        updateQuoteObservables = result
                            .filter(function (r) {
                            return !moment(r.InceptionDate).isSameOrAfter(inceptionDate, 'day');
                        })
                            .map(function (r) { return self.CreateQuote({
                            InceptionDate: moment().format('yyyy-MM-DD')
                        }, r.UID); });
                    }
                    if (!updateQuoteObservables || updateQuoteObservables.length === 0 || !updateQuote) {
                        resolve(result);
                        return;
                    }
                    forkJoin(updateQuoteObservables).subscribe(function () {
                        resolve(getQuoteDetails(quotes));
                    }, function (err) {
                        reject(err);
                    });
                }, function (err) {
                    reject(err);
                });
            });
        }
        function getDateDiffFromQuote(quote) {
            var createdDate = moment.utc(quote.DateCreated);
            var now = moment(new Date());
            var diff = now.diff(createdDate, 'minutes');
            return diff;
        }
        function getQuotePolicyCouple() {
            var submittedPolicy = self.vm.quotePolicyCouples.find(function (q) { return Boolean(q.policy) && self.policyService.isSubmitted(q.policy.PolicyStatusName); });
            if (submittedPolicy) {
                return submittedPolicy;
            }
            var nonSubmittedPolicy = self.vm.quotePolicyCouples.find(function (q) { return Boolean(q.policy); });
            if (nonSubmittedPolicy) {
                return nonSubmittedPolicy;
            }
            return self.vm.quotePolicyCouples[0];
        }
        function setUpdatedQuestion(policy) {
            var e_8, _a, e_9, _b;
            var multiAnswers = policy.QuestionAnswers.filter(function (q) { return q.AllowMultiAnswers; });
            var singleAnswers = policy.QuestionAnswers.filter(function (q) { return !q.AllowMultiAnswers; });
            var answersToAdd = [];
            var _loop_3 = function (answer) {
                var answersForQuestion = multiAnswers.filter(function (m) { return m.QuestionId === answer.QuestionId; });
                if (!answersToAdd.some(function (a) { return a.QuestionId === answer.QuestionId; })) {
                    var mergedAnswer = answersForQuestion[0];
                    mergedAnswer.MultipleAnswers = answersForQuestion.map(function (a) {
                        return {
                            AnswerId: a.AnswerId,
                            AnswerProfileId: a.AnswerProfileId,
                            Text: a.AnswerText,
                            Value: a.AnswerValue
                        };
                    });
                    answersToAdd.push(mergedAnswer);
                }
            };
            try {
                for (var multiAnswers_1 = __values(multiAnswers), multiAnswers_1_1 = multiAnswers_1.next(); !multiAnswers_1_1.done; multiAnswers_1_1 = multiAnswers_1.next()) {
                    var answer = multiAnswers_1_1.value;
                    _loop_3(answer);
                }
            }
            catch (e_8_1) { e_8 = { error: e_8_1 }; }
            finally {
                try {
                    if (multiAnswers_1_1 && !multiAnswers_1_1.done && (_a = multiAnswers_1.return)) _a.call(multiAnswers_1);
                }
                finally { if (e_8) throw e_8.error; }
            }
            answersToAdd.push.apply(answersToAdd, __spread(singleAnswers));
            policy.PolicyDetail.AnsweredQuestions = answersToAdd;
            try {
                for (var _c = __values(policy.PolicyDetail.AnsweredQuestions), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var answer = _d.value;
                    answer.Value = answer.AnswerValue;
                    answer.Text = answer.Value;
                }
            }
            catch (e_9_1) { e_9 = { error: e_9_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                }
                finally { if (e_9) throw e_9.error; }
            }
            self.quirkService.cachedAllQuestionResponse.set('UpdatedQuestion', policy);
        }
        return new Promise(function (resolve, reject) {
            forkJoin([
                _this.quirkService.getPolicies(_this.vm.entityId),
                _this.productService.getQuoteOnAPersonBasedOnRole(_this.vm.entityId, AppConsts.QuoteRoles.Life_Insured),
                _this.productService.getQuoteOnAPersonBasedOnRole(_this.vm.entityId, AppConsts.QuoteRoles.Premium_Payer),
                _this.productService.getMendixProductAndQuircMapping(),
                _this.policyService.getPoliciesForLifeAssured(_this.vm.entityId, _this._authService.user.access_token)
            ]).subscribe(function (result) {
                var resumePeriod = +abp.setting.values['App.ResumePeriod'];
                var policies = result[0] || [];
                var lifeInsuredQuotes = result[1] || [];
                var premiumPayerQuotes = result[2] || [];
                var notAcceptedQuotes = lifeInsuredQuotes
                    .concat(premiumPayerQuotes)
                    .filter(function (value, index, array) {
                    return array.findIndex(function (a) { return a.QuoteUID === value.QuoteUID; }) === index &&
                        value.QuoteStatus === AppConsts.QuoteStatus.Not_Accepted;
                });
                _this.vm.quircAfterSubmittingAnalysis = result[3];
                self.vm.lifeInsuredPolicies = result[4] || [];
                _this.clearLoadings();
                var quotes = notAcceptedQuotes.filter(function (q) { return getDateDiffFromQuote(q) <= resumePeriod; });
                var quotesToExpire = notAcceptedQuotes.filter(function (q) { return getDateDiffFromQuote(q) > resumePeriod; });
                var acceptedQuotes = lifeInsuredQuotes.filter(function (q) { return q.QuoteStatus === AppConsts.QuoteStatus.Accepted; });
                if (quotesToExpire.length > 0) {
                    _this.expireQuotes(quotesToExpire.map(function (q) { return q.QuoteUID; }));
                }
                if (quotes.length === 0) {
                    _this.CreateQuote({
                        MainMember: _this.vm.entityId,
                        LifeAssured: _this.vm.entityId,
                        LifeAssuredAssuredType: 'Primary_member',
                        InceptionDate: moment().format('yyyy-MM-DD'),
                        Broker: abp.setting.values[AppConsts.KeyVaultSecrets.BrokerId],
                    }).subscribe(function (result) {
                        _this.createOrUpdatePolicyAndGetAnswers(result, 'Personal Info', false).subscribe(function (result) {
                            resolve(_this.startQuoteJourney());
                            return;
                        });
                    }, function (err) {
                        reject(err);
                    });
                }
                else {
                    Promise.all([
                        getQuoteDetails(quotes),
                        getQuoteDetails(acceptedQuotes, false),
                    ]).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                        var quotePolicyCouple, updatedQuotes;
                        var _this = this;
                        return __generator(this, function (_a) {
                            this.vm.quotePolicyCouples = this.getQuotePolicyCouples(result[0], policies);
                            this.vm.acceptedQuotePolicyCouples = this.getQuotePolicyCouples(result[1], policies);
                            quotePolicyCouple = getQuotePolicyCouple();
                            this.vm.quoteId = quotePolicyCouple.quote.UID;
                            if (quotePolicyCouple.policy) {
                                this.vm.currentPolicyId = quotePolicyCouple.policy.ExternalReferenceId;
                                this.quirkService.policyId = quotePolicyCouple.policy.PolicyId;
                            }
                            else {
                                this.vm.currentPolicyId = null;
                                this.quirkService.policyId = null;
                            }
                            if (!quotePolicyCouple.policy || !this.policyService.isSubmitted(quotePolicyCouple.policy.PolicyStatusName)) {
                                updatedQuotes = getQuoteDetails(quotes, true, true);
                                updatedQuotes.then(function (result) {
                                    result.forEach(function (quote) {
                                        _this.updateQuoteInPolicyCouple(quote);
                                    });
                                    _this.createOrUpdatePolicyAndGetAnswers(null, 'Personal Info', false).subscribe(function (result) {
                                        resolve(result);
                                    }, function (err) {
                                        reject(err);
                                    });
                                });
                            }
                            else {
                                this.quirkService.getPolicySchedule(quotePolicyCouple.policy.CustomerReferenceId, quotePolicyCouple.policy.ExternalReferenceId)
                                    .subscribe(function (result) {
                                    _this.vm.submitQuestionaireResult = result;
                                    _this.vm.isImprovingQuoteNeccesary = true;
                                    setUpdatedQuestion(result);
                                    _this.setLoadings();
                                    _this.createPerson(function () {
                                        _this.policyService.updatePersonAsync(_this.vm.entityId).then(function () {
                                            _this.getProductAsync().then(function () {
                                                _this.excludeProducts().then(function () {
                                                    resolve(null);
                                                    return;
                                                });
                                            });
                                        });
                                    }, false);
                                }, function (err) {
                                    reject(err);
                                });
                            }
                            return [2 /*return*/];
                        });
                    }); });
                }
            }, function (err) {
                reject(err);
            });
        });
    };
    MyCommandCenterService.prototype.pushToProductSummary = function (product, factoryProduct, quote) {
        this.vm.quotedProductDetails.push({
            assuredValue: product.AssuredValue,
            premiumAmount: product.PremiumValue,
            productId: product.ProductConfig.UID,
            productName: product.ProductConfig.Name,
            productedQuotedId: product.ProductConfig.UID,
            quoteId: quote.UID,
            isMainProduct: product.IsMainProduct,
            productCategory: factoryProduct.Category
        });
        factoryProduct.CurrentAssuredValue = product.AssuredValue;
        factoryProduct.CurrentBenefitPattern = factoryProduct.BenefitPatternSet.find(function (b) { return b.UID === product.BenefitPattern.UID; });
        factoryProduct.CurrentPremiumPattern = factoryProduct.PremiumPatternSet.find(function (p) { return p.UID === product.PremiumPattern.UID; });
        factoryProduct.currentPremiumValue = product.PremiumValue;
        if (product.IsMainProduct) {
            var associatedUIDs_1 = quote.ProductQuoteds.filter(function (p) { return !p.IsMainProduct; }).map(function (p) { return p.ProductConfig.UID; });
            this.vm.productSummary.push({
                categoryName: factoryProduct.Category,
                productUID: product.ProductConfig.UID,
                products: __spread([factoryProduct], factoryProduct.AssociatedProducts.filter(function (a) { return associatedUIDs_1.some(function (p) { return p === a.UID; }); }))
            });
        }
    };
    MyCommandCenterService.prototype.createQuotesForMedicallyReferred = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var referredCouple = _this.vm.quotePolicyCouples.find(function (c) { return c.policy && c.policy.PolicyStatusName === 'Referred'; });
            if (!referredCouple) {
                resolve();
                return;
            }
            var quoteCountToCreate = +abp.setting.values['App.CloneCount'] - _this.vm.quotePolicyCouples.length;
            if (quoteCountToCreate === 0) {
                resolve();
                return;
            }
            var createQuoteObservable = [];
            for (var i = 0; i < quoteCountToCreate; i++) {
                createQuoteObservable.push(_this.CreateQuote({
                    MainMember: _this.vm.entityId,
                    LifeAssured: _this.vm.entityId,
                    LifeAssuredAssuredType: 'Primary_member',
                    InceptionDate: moment().format('yyyy-MM-DD'),
                    Broker: referredCouple.quote.Person_Broker.Identifier
                }));
            }
            forkJoin(createQuoteObservable).subscribe(function (result) {
                var quoteObservables = result.map(function (r) { return _this.productService.getAQuote(r); });
                forkJoin(quoteObservables).subscribe(function (result) {
                    var _a;
                    (_a = _this.vm.quotePolicyCouples).push.apply(_a, __spread(result.map(function (r) { return ({ quote: r, policy: undefined, commission: undefined, currentValues: [] }); })));
                    resolve();
                }, function (err) {
                    reject(err);
                });
            }, function (err) {
                reject(err);
            });
        });
    };
    MyCommandCenterService.prototype.clonePoliciesOnResume = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var nonSubmittedCouples = _this.vm.quotePolicyCouples.filter(function (c) { return !c.policy || !_this.policyService.isSubmitted(c.policy.PolicyStatusName); });
            var submittedCouple = _this.vm.quotePolicyCouples.find(function (c) { return c.policy && _this.policyService.isSubmitted(c.policy.PolicyStatusName); });
            if (nonSubmittedCouples.length === 0 || !submittedCouple) {
                resolve();
                return;
            }
            _this.getPolicyToClone()
                .then(function (result) {
                var e_10, _a;
                var policyObservables = [];
                try {
                    for (var nonSubmittedCouples_1 = __values(nonSubmittedCouples), nonSubmittedCouples_1_1 = nonSubmittedCouples_1.next(); !nonSubmittedCouples_1_1.done; nonSubmittedCouples_1_1 = nonSubmittedCouples_1.next()) {
                        var couple = nonSubmittedCouples_1_1.value;
                        var request = {
                            CedantId: _this.CEDANT_ID,
                            CustomerReferenceid: _this.vm.entityId,
                            DisplayAnswers: 'true',
                            PolicyReferenceId: couple.quote.UID,
                            ProcessDisclosures: 'true',
                            ShowErrorDetails: '1',
                            AnsweredQuestions: result
                        };
                        policyObservables.push(_this.quirkService.createOrUpdatePolicy(request, false));
                    }
                }
                catch (e_10_1) { e_10 = { error: e_10_1 }; }
                finally {
                    try {
                        if (nonSubmittedCouples_1_1 && !nonSubmittedCouples_1_1.done && (_a = nonSubmittedCouples_1.return)) _a.call(nonSubmittedCouples_1);
                    }
                    finally { if (e_10) throw e_10.error; }
                }
                if (policyObservables.length === 0) {
                    resolve();
                    return;
                }
                forkJoin(policyObservables).subscribe(function (result) {
                    var e_11, _a;
                    var submitObservables = [];
                    try {
                        for (var nonSubmittedCouples_2 = __values(nonSubmittedCouples), nonSubmittedCouples_2_1 = nonSubmittedCouples_2.next(); !nonSubmittedCouples_2_1.done; nonSubmittedCouples_2_1 = nonSubmittedCouples_2.next()) {
                            var couple = nonSubmittedCouples_2_1.value;
                            submitObservables.push(_this.quirkService.submitQuestionnaire(undefined, {
                                CustomerReferenceid: _this.vm.entityId,
                                PolicyReferenceId: couple.quote.UID
                            }));
                        }
                    }
                    catch (e_11_1) { e_11 = { error: e_11_1 }; }
                    finally {
                        try {
                            if (nonSubmittedCouples_2_1 && !nonSubmittedCouples_2_1.done && (_a = nonSubmittedCouples_2.return)) _a.call(nonSubmittedCouples_2);
                        }
                        finally { if (e_11) throw e_11.error; }
                    }
                    forkJoin(submitObservables).subscribe(function (result) {
                        _this.quirkService.getPolicies(_this.vm.entityId).subscribe(function (result) {
                            _this.vm.quotePolicyCouples = _this.getQuotePolicyCouples(_this.vm.quotePolicyCouples.map(function (c) { return c.quote; }), result);
                            resolve();
                        }, function (err) {
                            reject(err);
                        });
                    });
                });
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    MyCommandCenterService.prototype.populateProducts = function () {
        var _this = this;
        var self = this;
        this.vm.quotedProductDetails = [];
        this.vm.productSummary = [];
        function setUpProduct(product, factoryProduct, quoteId, mainProductId, isAccelerator, mainProductCurrentSumAssured) {
            var _this = this;
            if (mainProductId === void 0) { mainProductId = null; }
            if (isAccelerator === void 0) { isAccelerator = false; }
            if (mainProductCurrentSumAssured === void 0) { mainProductCurrentSumAssured = null; }
            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var excludedProducts, excludedProductSubCategory, inceptionDate, birthDate, age, maxSumAssured;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            factoryProduct.IsProductQuoted = true;
                            excludedProducts = self.vm.needsAssessmentSumAssuredLevels.filter(function (x) { return x.excluded !== null; });
                            excludedProductSubCategory = excludedProducts.map(function (x) { return x.excluded; }).includes(factoryProduct.DisplayName) ? factoryProduct.DisplayName : '';
                            inceptionDate = moment();
                            inceptionDate = inceptionDate.isSameOrBefore(moment(self.vm.quotePolicyCouples[0].quote.InceptionDate)) ? moment(self.vm.quotePolicyCouples[0].quote.InceptionDate) : inceptionDate;
                            birthDate = self.vm.userDetails.identityNumber.getDateOfBirthByIdNumber();
                            age = inceptionDate.diff(birthDate, 'years').toString();
                            if (!!factoryProduct.RateTable) return [3 /*break*/, 3];
                            return [4 /*yield*/, self.getMaxSumAssured(factoryProduct.UID, factoryProduct.OriginalMaxBenefitAmount, { mainProductCurrentSumAssured: mainProductCurrentSumAssured, isAccelerator: false, productSubCategory: excludedProductSubCategory })];
                        case 1:
                            maxSumAssured = _a.sent();
                            return [4 /*yield*/, factoryProduct.getRateTableAsync(self.injector, factoryProduct.MinBenefitAmount, maxSumAssured, null, age)];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3:
                            self.getMaxSumAssured(factoryProduct.UID, factoryProduct.OriginalMaxBenefitAmount, { mainProductCurrentSumAssured: mainProductCurrentSumAssured, isAccelerator: isAccelerator, productSubCategory: excludedProductSubCategory }).then(function (result) {
                                var sortedRateTable = factoryProduct.RateTable
                                    .map(function (r) { return r.sumAssuredLimit; })
                                    .filter(function (val, index, arr) { return arr.indexOf(val) === index && val <= factoryProduct.MaxBenefitAmount && val >= factoryProduct.MinBenefitAmount && val <= result; })
                                    .sort(function (a, b) { return a - b; });
                                if (sortedRateTable.length === 0) {
                                    self.productService.deleteRiderProduct(quoteId, factoryProduct.UID).subscribe(function (result) {
                                        self.productService.getAQuote(quoteId).subscribe(function (result) {
                                            factoryProduct.IsProductQuoted = false;
                                            self.updateQuoteInPolicyCouple(result);
                                            self.vm.isMinimumBenefitNotMet = true;
                                            resolve({ quoteId: quoteId, productId: product.ProductConfig.UID, assuredValue: null });
                                        });
                                    });
                                }
                                else {
                                    if (result < product.AssuredValue) {
                                        product.AssuredValue = sortedRateTable.filter(function (s) { return s <= result; })[sortedRateTable.filter(function (s) { return s <= result; }).length - 1];
                                    }
                                    else if (!sortedRateTable.some(function (s) { return s >= product.AssuredValue; })) {
                                        product.AssuredValue = sortedRateTable.filter(function (s) { return s < product.AssuredValue; })[sortedRateTable.filter(function (s) { return s < product.AssuredValue; }).length - 1];
                                    }
                                    var update = {
                                        AdditiveUnderwritingLoading: self.additiveunderwritingloading(product.ProductConfig.UID),
                                        AssuredValue: product.AssuredValue,
                                        BenefitPattern: product.BenefitPattern.UID,
                                        EventSet: product.EventSet.ShortCode,
                                        MultiplicativeUnderwritingLoading: self.multipicativeunderwritingloading(product.ProductConfig.UID),
                                        PremiumPattern: product.PremiumPattern.UID,
                                        Product: product.ProductConfig.UID,
                                    };
                                    var quoteObservable = void 0;
                                    if (!product.IsMainProduct) {
                                        update.MainProduct = mainProductId;
                                        quoteObservable = self.productService.createOrUpdateRiderProduct(update, quoteId, mainProductId, product.ProductConfig.UID);
                                    }
                                    else {
                                        quoteObservable = self.productService.productQuoted(quoteId, update, product.ProductConfig.UID, 'PUT');
                                    }
                                    quoteObservable.subscribe(function () {
                                        resolve({ quoteId: quoteId, productId: product.ProductConfig.UID, assuredValue: product.AssuredValue });
                                    }, function (err) {
                                        reject(err);
                                    });
                                }
                            });
                            return [2 /*return*/];
                    }
                });
            }); });
        }
        return new Promise(function (resolve, reject) {
            var e_12, _a;
            var mainProductPromises = [];
            var riderProductPromises = [];
            var boosterProductPromises = [];
            var associatedProductFactories = self.vm.productDetailsFromFactory.map(function (p) { return p.AssociatedProducts; }).customFlat();
            var _loop_4 = function (couple) {
                if (!couple.quote.ProductQuoteds || couple.quote.ProductQuoteds.length === 0) {
                    return "continue";
                }
                var mainProduct = couple.quote.ProductQuoteds.find(function (p) { return p.IsMainProduct; });
                var mainProductFactory = _this.vm.productDetailsFromFactory.find(function (p) { return p.UID === mainProduct.ProductConfig.UID; });
                mainProductPromises.push(setUpProduct(mainProduct, mainProductFactory, couple.quote.UID));
            };
            try {
                for (var _b = __values(_this.vm.quotePolicyCouples), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var couple = _c.value;
                    _loop_4(couple);
                }
            }
            catch (e_12_1) { e_12 = { error: e_12_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_12) throw e_12.error; }
            }
            if (mainProductPromises.length === 0) {
                resolve(true);
                return;
            }
            Promise.all(mainProductPromises)
                .then(function (resultMain) {
                var e_13, _a;
                var boostersToAdd = [];
                var _loop_5 = function (couple) {
                    var e_14, _a;
                    if (!couple.quote.ProductQuoteds || couple.quote.ProductQuoteds.length === 0) {
                        return "continue";
                    }
                    var riderProducts = couple.quote.ProductQuoteds.filter(function (p) { return !p.IsMainProduct && p.PremiumValue > 0; });
                    var _loop_6 = function (riderProduct) {
                        var riderProductFactory = associatedProductFactories.find(function (p) { return p.UID === riderProduct.ProductConfig.UID; });
                        if (riderProductFactory.IsParentDependent) {
                            boostersToAdd.push({ associatedProduct: riderProductFactory, quoteProduct: riderProduct });
                            return "continue";
                        }
                        var mainProductAssuredValue = resultMain.find(function (r) { return r.quoteId === couple.quote.UID; }).assuredValue;
                        var mainProductUid = couple.quote.ProductQuoteds.find(function (p) { return p.IsMainProduct; }).ProductConfig.UID;
                        riderProductPromises.push(setUpProduct(riderProduct, riderProductFactory, couple.quote.UID, mainProductUid, riderProductFactory.Accelerator, mainProductAssuredValue));
                    };
                    try {
                        for (var riderProducts_1 = __values(riderProducts), riderProducts_1_1 = riderProducts_1.next(); !riderProducts_1_1.done; riderProducts_1_1 = riderProducts_1.next()) {
                            var riderProduct = riderProducts_1_1.value;
                            _loop_6(riderProduct);
                        }
                    }
                    catch (e_14_1) { e_14 = { error: e_14_1 }; }
                    finally {
                        try {
                            if (riderProducts_1_1 && !riderProducts_1_1.done && (_a = riderProducts_1.return)) _a.call(riderProducts_1);
                        }
                        finally { if (e_14) throw e_14.error; }
                    }
                };
                try {
                    for (var _b = __values(_this.vm.quotePolicyCouples), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var couple = _c.value;
                        _loop_5(couple);
                    }
                }
                catch (e_13_1) { e_13 = { error: e_13_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_13) throw e_13.error; }
                }
                if (riderProductPromises.length === 0) {
                    riderProductPromises.push(new Promise(function (resolve) { resolve({ assuredValue: null, productId: null, quoteId: null }); }));
                }
                Promise.all(riderProductPromises)
                    .then(function (resultRiders) { return __awaiter(_this, void 0, void 0, function () {
                    var e_15, _a, _loop_7, this_1, boostersToAdd_1, boostersToAdd_1_1, boosterToAdd, e_15_1;
                    var _this = this;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _loop_7 = function (boosterToAdd) {
                                    var parent_1, quoteId_1, mainProductId, quoteId;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                parent_1 = resultMain.find(function (r) { return r.productId === boosterToAdd.associatedProduct.DependentParentGuid; });
                                                if (!parent_1) {
                                                    parent_1 = resultRiders.find(function (r) { return r.productId === boosterToAdd.associatedProduct.DependentParentGuid; });
                                                }
                                                if (!parent_1) return [3 /*break*/, 1];
                                                quoteId_1 = parent_1.quoteId;
                                                mainProductId = this_1.vm.quotePolicyCouples
                                                    .map(function (q) { return q.quote; })
                                                    .find(function (q) { return q.UID === quoteId_1; }).ProductQuoteds
                                                    .find(function (p) { return p.IsMainProduct; }).ProductConfig.UID;
                                                boosterProductPromises.push(setUpProduct(boosterToAdd.quoteProduct, boosterToAdd.associatedProduct, quoteId_1, mainProductId));
                                                return [3 /*break*/, 3];
                                            case 1:
                                                quoteId = self.vm.quotePolicyCouples
                                                    .map(function (c) { return c.quote; })
                                                    .find(function (q) { return q.ProductQuoteds && q.ProductQuoteds.find(function (p) { return p.ProductConfig.UID === boosterToAdd.associatedProduct.UID; }) !== undefined; })
                                                    .UID;
                                                return [4 /*yield*/, self.productService.deleteRiderProduct(quoteId, boosterToAdd.associatedProduct.UID).toPromise()];
                                            case 2:
                                                _a.sent();
                                                _a.label = 3;
                                            case 3: return [2 /*return*/];
                                        }
                                    });
                                };
                                this_1 = this;
                                _b.label = 1;
                            case 1:
                                _b.trys.push([1, 6, 7, 8]);
                                boostersToAdd_1 = __values(boostersToAdd), boostersToAdd_1_1 = boostersToAdd_1.next();
                                _b.label = 2;
                            case 2:
                                if (!!boostersToAdd_1_1.done) return [3 /*break*/, 5];
                                boosterToAdd = boostersToAdd_1_1.value;
                                return [5 /*yield**/, _loop_7(boosterToAdd)];
                            case 3:
                                _b.sent();
                                _b.label = 4;
                            case 4:
                                boostersToAdd_1_1 = boostersToAdd_1.next();
                                return [3 /*break*/, 2];
                            case 5: return [3 /*break*/, 8];
                            case 6:
                                e_15_1 = _b.sent();
                                e_15 = { error: e_15_1 };
                                return [3 /*break*/, 8];
                            case 7:
                                try {
                                    if (boostersToAdd_1_1 && !boostersToAdd_1_1.done && (_a = boostersToAdd_1.return)) _a.call(boostersToAdd_1);
                                }
                                finally { if (e_15) throw e_15.error; }
                                return [7 /*endfinally*/];
                            case 8:
                                if (boosterProductPromises.length === 0) {
                                    boosterProductPromises.push(new Promise(function (resolve) { resolve({ assuredValue: null, productId: null, quoteId: null }); }));
                                }
                                Promise.all(boosterProductPromises)
                                    .then(function (_) {
                                    var quoteObservables = _this.vm.quotePolicyCouples.map(function (q) { return self.productService.getAQuote(q.quote.UID); });
                                    forkJoin(quoteObservables).subscribe(function (quoteResult) {
                                        var e_16, _a;
                                        var _loop_8 = function (couple) {
                                            var e_17, _a;
                                            _this.updateQuoteInPolicyCouple(couple.quote);
                                            couple.quote = quoteResult.find(function (r) { return r.UID === couple.quote.UID; });
                                            if (!couple.quote.ProductQuoteds) {
                                                return "continue";
                                            }
                                            var _loop_9 = function (product) {
                                                var productFactory = void 0;
                                                if (product.IsMainProduct) {
                                                    productFactory = _this.vm.productDetailsFromFactory.find(function (p) { return p.UID === product.ProductConfig.UID; });
                                                }
                                                else {
                                                    productFactory = associatedProductFactories.find(function (p) { return p.UID === product.ProductConfig.UID; });
                                                }
                                                _this.pushToProductSummary(product, productFactory, couple.quote);
                                            };
                                            try {
                                                for (var _b = __values(couple.quote.ProductQuoteds), _c = _b.next(); !_c.done; _c = _b.next()) {
                                                    var product = _c.value;
                                                    _loop_9(product);
                                                }
                                            }
                                            catch (e_17_1) { e_17 = { error: e_17_1 }; }
                                            finally {
                                                try {
                                                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                                }
                                                finally { if (e_17) throw e_17.error; }
                                            }
                                        };
                                        try {
                                            for (var _b = __values(_this.vm.quotePolicyCouples), _c = _b.next(); !_c.done; _c = _b.next()) {
                                                var couple = _c.value;
                                                _loop_8(couple);
                                            }
                                        }
                                        catch (e_16_1) { e_16 = { error: e_16_1 }; }
                                        finally {
                                            try {
                                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                            }
                                            finally { if (e_16) throw e_16.error; }
                                        }
                                        resolve(true);
                                    }, function (err) {
                                        reject(err);
                                    });
                                })
                                    .catch(function (err) {
                                    reject(err);
                                });
                                return [2 /*return*/];
                        }
                    });
                }); })
                    .catch(function (err) {
                    reject(err);
                });
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    MyCommandCenterService.prototype.CreateQuote = function (createQuote, quoteId) {
        if (this.vm.externalQuoteReferencId) {
            createQuote.OpportunityGUID = this.vm.externalQuoteReferencId;
        }
        return this.quirkService.CreateOrUpdateQuote(createQuote, quoteId)
            .pipe(map(function (res) {
            return res;
        }));
    };
    MyCommandCenterService.prototype.createOrUpdatePolicyAndGetAnswers = function (policyId, sectionName, isCached) {
        var _this = this;
        if (isCached === void 0) { isCached = true; }
        return this.quirkService.createOrUpdatePolicy({
            AnsweredQuestions: [],
            CedantId: this.CEDANT_ID,
            CustomerReferenceid: this.vm.entityId,
            DisplayAnswers: 'true',
            PolicyReferenceId: policyId || this.vm.quoteId,
            ProcessDisclosures: 'true',
            ShowErrorDetails: '1'
        }, isCached).pipe(map(function (res) {
            _this.vm.currentPolicyId = res.PolicyDetail.PolicyReferenceId;
            _this.vm.quoteId = res.PolicyDetail.PolicyReferenceId;
            return _this.quirkService.processGetQuestionForStepper(res.PolicyDetail, sectionName);
        }));
    };
    MyCommandCenterService.prototype.getBenefitPatternSetList = function (uidString) {
        return this.productService.getBenefitPatternSet(uidString)
            .pipe(map(function (res) {
            return res;
        }));
    };
    MyCommandCenterService.prototype.getPremiumPatternSetList = function (uidString) {
        return this.productService.getPremiumPatternSet(uidString)
            .pipe(map(function (res) {
            return res;
        }));
    };
    MyCommandCenterService.prototype.processRateTable = function (res) {
        var _this = this;
        return res.filter(function (x) { return x.RatingFactors.some(function (y) { return y.ShortCode.startsWith('SocioEconomicClass') && y.Description === 'Class_1'; }); })
            .filter(function (x) { return x.RatingFactors.some(function (y) { return y.ShortCode.startsWith('OccupationCategory') && y.Description === 'Class_1'; }); })
            .filter(function (x) { return x.RatingFactors.some(function (y) { return y.ShortCode.startsWith('SmokingStatus') && y.Description === 'NonSmoker'; }); })
            .filter(function (x) { return x.RatingFactors.some(function (y) { return y.ShortCode.startsWith('Gender') && y.Description === _this.policyService.personRatingFactor.gender; }); })
            .filter(function (x) { return x.RatingFactors.some(function (y) { return y.ShortCode.startsWith('Age') && y.Description === _this.policyService.personRatingFactor.age.toString(); }); });
    };
    MyCommandCenterService.prototype.postProductQuoted = function (quoteId, productQuoteData, productQuotedId) {
        var _this = this;
        var productQuoteId = this.productQuoteDictionary.get(quoteId);
        var requestMethod = productQuoteId ? 'put' : 'post';
        return this._productService.productQuoted(quoteId, productQuoteData, productQuoteId, requestMethod)
            .pipe(map(function (res) {
            if (res) {
                _this.productQuoteDictionary.set(quoteId, res.GUID);
            }
            return res;
        }));
    };
    MyCommandCenterService.prototype.getProductAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            function getAllowedDisabilityProductNames() {
                return __awaiter(this, void 0, void 0, function () {
                    var allowedDisabilityProductNames, url, occupationsDisabilityExclusions;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                allowedDisabilityProductNames = [];
                                if (self.vm.userDetails.age >= 60) {
                                    return [2 /*return*/, []];
                                }
                                url = abp.setting.values['PortalSettings.OccupationDisabilityExclusionsUrl'];
                                return [4 /*yield*/, self.httpMethodBaseService.get(url).toPromise()];
                            case 1:
                                occupationsDisabilityExclusions = _a.sent();
                                if (occupationsDisabilityExclusions.allowAll.includes(self.vm.userDetails.occupation)) {
                                    allowedDisabilityProductNames.push('disability', 'occupation', '7day', '30day', '90day', 'employed');
                                    return [2 /*return*/, allowedDisabilityProductNames];
                                }
                                if (occupationsDisabilityExclusions.allowDisabilityCapitalProtection.includes(self.vm.userDetails.occupation)) {
                                    allowedDisabilityProductNames.push('capital', 'occupation');
                                }
                                if (occupationsDisabilityExclusions.allowDisabilityIncomeProtection.includes(self.vm.userDetails.occupation)) {
                                    allowedDisabilityProductNames.push('income', 'employed', '7day', '30day', '90day');
                                    return [2 /*return*/, allowedDisabilityProductNames];
                                }
                                if (occupationsDisabilityExclusions.allow7Day30Day.includes(self.vm.userDetails.occupation)) {
                                    allowedDisabilityProductNames.push('7day', '30day', 'employed', 'family');
                                }
                                if (occupationsDisabilityExclusions.allow90Day.includes(self.vm.userDetails.occupation)) {
                                    allowedDisabilityProductNames.push('90day');
                                }
                                return [2 /*return*/, allowedDisabilityProductNames];
                        }
                    });
                });
            }
            function deleteExcludedProducts(fullyUidList, semiUidList, self, allProductUidList, automaticRiderUidList, parentAndChildUIDList) {
                return __awaiter(this, void 0, void 0, function () {
                    var e_18, _a, e_19, _b, e_20, _c, allGuids, quotedProductGuids, excludedGuids, removeProductPromises, getQuotePromises, allQuotedProductGuids, riderExcludedGuids, allExcludedGuids, ridersAlreadyRemovedDueToMainProduct, _loop_10, _d, _e, _f, key, value, _loop_11, _g, _h, excludedGuid, getResults, getResults_1, getResults_1_1, getResult;
                    return __generator(this, function (_j) {
                        switch (_j.label) {
                            case 0:
                                allGuids = fullyUidList.concat(semiUidList);
                                quotedProductGuids = self.vm.quotePolicyCouples
                                    .filter(function (q) { return q.quote && q.quote.ProductQuoteds && q.quote.ProductQuoteds.length > 0; })
                                    .map(function (q) { return q.quote.ProductQuoteds; })
                                    .customFlat()
                                    .filter(function (p) { return p.IsMainProduct; })
                                    .map(function (p) { return p.ProductConfig.UID; });
                                excludedGuids = quotedProductGuids.filter(function (q) { return !allGuids.includes(q); });
                                removeProductPromises = [];
                                getQuotePromises = [];
                                allQuotedProductGuids = self.vm.quotePolicyCouples
                                    .filter(function (q) { return q.quote && q.quote.ProductQuoteds && q.quote.ProductQuoteds.length > 0; })
                                    .map(function (q) { return q.quote.ProductQuoteds; })
                                    .customFlat()
                                    .filter(function (p) { return !automaticRiderUidList.includes(p.ProductConfig.UID); })
                                    .map(function (p) { return p.ProductConfig.UID; });
                                riderExcludedGuids = allQuotedProductGuids.filter(function (q) { return !allProductUidList.includes(q); });
                                allExcludedGuids = excludedGuids.concat(riderExcludedGuids);
                                ridersAlreadyRemovedDueToMainProduct = [];
                                _loop_10 = function (key, value) {
                                    value.forEach(function (x) {
                                        if (allExcludedGuids.includes(x) && allExcludedGuids.includes(key)) {
                                            ridersAlreadyRemovedDueToMainProduct.push(x);
                                        }
                                    });
                                };
                                try {
                                    for (_d = __values(parentAndChildUIDList.entries()), _e = _d.next(); !_e.done; _e = _d.next()) {
                                        _f = __read(_e.value, 2), key = _f[0], value = _f[1];
                                        _loop_10(key, value);
                                    }
                                }
                                catch (e_18_1) { e_18 = { error: e_18_1 }; }
                                finally {
                                    try {
                                        if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                                    }
                                    finally { if (e_18) throw e_18.error; }
                                }
                                allExcludedGuids = allExcludedGuids.filter(function (x) { return !ridersAlreadyRemovedDueToMainProduct.includes(x); });
                                _loop_11 = function (excludedGuid) {
                                    var quoteUid = self.vm.quotePolicyCouples
                                        .map(function (q) { return q.quote; })
                                        .filter(function (q) { return q.ProductQuoteds; })
                                        .find(function (q) { return q.ProductQuoteds.map(function (q) { return q.ProductConfig.UID; }).includes(excludedGuid); })
                                        .UID;
                                    removeProductPromises.push(self.productService.deleteRiderProduct(quoteUid, excludedGuid).toPromise());
                                    getQuotePromises.push(self.productService.getAQuote(quoteUid));
                                };
                                try {
                                    for (_g = __values(allExcludedGuids.filter(function (v, i, a) { return a.indexOf(v) === i; })), _h = _g.next(); !_h.done; _h = _g.next()) {
                                        excludedGuid = _h.value;
                                        _loop_11(excludedGuid);
                                    }
                                }
                                catch (e_19_1) { e_19 = { error: e_19_1 }; }
                                finally {
                                    try {
                                        if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                                    }
                                    finally { if (e_19) throw e_19.error; }
                                }
                                if (!(removeProductPromises.length > 0)) return [3 /*break*/, 3];
                                return [4 /*yield*/, Promise.all(removeProductPromises)];
                            case 1:
                                _j.sent();
                                return [4 /*yield*/, Promise.all(getQuotePromises.map(function (q) { return q.toPromise(); }))];
                            case 2:
                                getResults = _j.sent();
                                try {
                                    for (getResults_1 = __values(getResults), getResults_1_1 = getResults_1.next(); !getResults_1_1.done; getResults_1_1 = getResults_1.next()) {
                                        getResult = getResults_1_1.value;
                                        self.updateQuoteInPolicyCouple(getResult);
                                    }
                                }
                                catch (e_20_1) { e_20 = { error: e_20_1 }; }
                                finally {
                                    try {
                                        if (getResults_1_1 && !getResults_1_1.done && (_c = getResults_1.return)) _c.call(getResults_1);
                                    }
                                    finally { if (e_20) throw e_20.error; }
                                }
                                _j.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            }
            function populateProductDetailsFromFactory(productUids, useSemi) {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        return [2 /*return*/, new Promise(function (resolve) {
                                var e_21, _a;
                                var iterationsNeeded = productUids.length;
                                var excludedProducts = self.vm.needsAssessmentSumAssuredLevels.filter(function (x) { return x.excluded !== null; });
                                var _loop_12 = function (mainProduct) {
                                    buildProduct(mainProduct, allowedDisabilityProductNames).then(function (product) { return __awaiter(_this, void 0, void 0, function () {
                                        var setPatterns, excludedProductSubCategory, maxSumAssured, upperLimit, sumAssuredRateToAdd, quircCategory, needsAssessmentSumAssuredLevel, inceptionDate, birthDate, age;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    setPatterns = false;
                                                    if ((useSemi && product.Name.toLocaleLowerCase().includes('semi')) || (!useSemi && !product.Name.toLocaleLowerCase().includes('semi'))) {
                                                        setPatterns = true;
                                                    }
                                                    excludedProductSubCategory = excludedProducts.map(function (x) { return x.excluded; }).includes(product.DisplayName) ? product.DisplayName : '';
                                                    return [4 /*yield*/, self.getMaxSumAssured(product.UID, product.MaxBenefitAmount, { isAccelerator: false, skipExistingCover: true, productSubCategory: excludedProductSubCategory })];
                                                case 1:
                                                    maxSumAssured = _a.sent();
                                                    sumAssuredRateToAdd = null;
                                                    quircCategory = self.vm.quircAfterSubmittingAnalysis.find(function (q) { return q.MendixProductUID === mainProduct; }).QuircProfileName;
                                                    needsAssessmentSumAssuredLevel = self.vm.needsAssessmentSumAssuredLevels.find(function (n) { return n.quircCategory === quircCategory; });
                                                    excludedProducts.forEach(function (element) {
                                                        if (product.DisplayName !== element.excluded) {
                                                            // If client can have sum assured higher than no-needs-assessment sum assured, get rates up to
                                                            // no-needs-assessment sum assured and get rates for sum assured limit = full-needs-assessment
                                                            // sum assured and infer rates between no-needs-assessment and full-needs-assessment.
                                                            if (maxSumAssured > needsAssessmentSumAssuredLevel.noNeedsAssessmentSumAssured && maxSumAssured <= needsAssessmentSumAssuredLevel.partialNeedsAssessmentSumAssured) {
                                                                sumAssuredRateToAdd = needsAssessmentSumAssuredLevel.partialNeedsAssessmentSumAssured;
                                                                upperLimit = needsAssessmentSumAssuredLevel.noNeedsAssessmentSumAssured;
                                                            }
                                                            else if (maxSumAssured > needsAssessmentSumAssuredLevel.noNeedsAssessmentSumAssured) {
                                                                sumAssuredRateToAdd = needsAssessmentSumAssuredLevel.fullNeedsAssessmentSumAssured;
                                                                upperLimit = needsAssessmentSumAssuredLevel.noNeedsAssessmentSumAssured;
                                                            }
                                                            else {
                                                                upperLimit = maxSumAssured;
                                                            }
                                                        }
                                                        else {
                                                            upperLimit = maxSumAssured;
                                                        }
                                                    });
                                                    if (upperLimit < product.MinBenefitAmount) {
                                                        iterationsNeeded--;
                                                        if (self.vm.productDetailsFromFactory.length === iterationsNeeded) {
                                                            notifyProductsLoaded();
                                                            resolve({});
                                                        }
                                                    }
                                                    else {
                                                        if (setPatterns) {
                                                            inceptionDate = moment();
                                                            inceptionDate = inceptionDate.isSameOrBefore(moment(self.vm.quotePolicyCouples[0].quote.InceptionDate)) ? moment(self.vm.quotePolicyCouples[0].quote.InceptionDate) : inceptionDate;
                                                            birthDate = self.vm.userDetails.identityNumber.getDateOfBirthByIdNumber();
                                                            age = inceptionDate.diff(birthDate, 'years').toString();
                                                            product.getRateTableAsync(self.injector, product.MinBenefitAmount, upperLimit, sumAssuredRateToAdd, age).then(function () {
                                                                product.setDefaultBenefitPattern();
                                                                product.setDefaultPremiumPattern();
                                                                self.vm.productDetailsFromFactory.push(product);
                                                                if (self.vm.productDetailsFromFactory.length === iterationsNeeded) {
                                                                    notifyProductsLoaded();
                                                                    resolve({});
                                                                }
                                                            });
                                                        }
                                                        else {
                                                            self.vm.productDetailsFromFactory.push(product);
                                                            if (self.vm.productDetailsFromFactory.length === iterationsNeeded) {
                                                                notifyProductsLoaded();
                                                                resolve({});
                                                            }
                                                        }
                                                    }
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                };
                                try {
                                    for (var productUids_1 = __values(productUids), productUids_1_1 = productUids_1.next(); !productUids_1_1.done; productUids_1_1 = productUids_1.next()) {
                                        var mainProduct = productUids_1_1.value;
                                        _loop_12(mainProduct);
                                    }
                                }
                                catch (e_21_1) { e_21 = { error: e_21_1 }; }
                                finally {
                                    try {
                                        if (productUids_1_1 && !productUids_1_1.done && (_a = productUids_1.return)) _a.call(productUids_1);
                                    }
                                    finally { if (e_21) throw e_21.error; }
                                }
                            })];
                    });
                });
            }
            function notifyProductsLoaded() {
                self.vm.productSyncedOrNot = true;
                self.vm.producSpinnerEmitter.next(true);
            }
            function buildProduct(mainProductUID, allowedDisabilityProductNames) {
                return __awaiter(this, void 0, void 0, function () {
                    var copy, benefitPatternSetPromise, premiumPatternSetPromise, product;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                copy = Object.assign(Object.create(Object.getPrototypeOf(productFactory)), productFactory);
                                copy
                                    .forProduct(mainProductUID)
                                    .extractUID()
                                    .extractName()
                                    .extractCategory()
                                    .extractDisplayName()
                                    .extractEventSet()
                                    .extractMaxBenefitAmount()
                                    .extractMinBenefitAmount()
                                    .excludeRiders(allowedDisabilityProductNames);
                                benefitPatternSetPromise = copy
                                    .extractBenefitPatternSetAsync();
                                premiumPatternSetPromise = copy
                                    .extractPremiumPatternSetAsync();
                                return [4 /*yield*/, Promise.all([benefitPatternSetPromise, premiumPatternSetPromise])];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, copy
                                        .extractAssociatedProductsAsync()];
                            case 2:
                                _a.sent();
                                product = copy
                                    .build();
                                return [2 /*return*/, Promise.resolve(product)];
                        }
                    });
                });
            }
            var self, allowedDisabilityProductNames, securitasBrokerage, quote, isSecuritasBroker, productFactory, mainProductFullyUIDList, mainProductSemiUIDList, allProductUIDList, automaticRiderUIDList, parentAndChildUIDList, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        self = this;
                        return [4 /*yield*/, getAllowedDisabilityProductNames()];
                    case 1:
                        allowedDisabilityProductNames = _b.sent();
                        return [4 /*yield*/, this.quirkService.getBrokerage('Securitas').toPromise()];
                    case 2:
                        securitasBrokerage = _b.sent();
                        quote = this.vm.quotePolicyCouples.find(function (c) { return Boolean(c.quote) && Boolean(c.quote.Person_Broker); });
                        isSecuritasBroker = quote && securitasBrokerage.Brokers.some(function (b) { return b.Person.GUID === quote.quote.Person_Broker.Identifier; });
                        return [4 /*yield*/, new ProductFactory(this.injector)
                                .withMetadataAsync(abp.setting.values['ActiveGenerationName'], isSecuritasBroker, allowedDisabilityProductNames)];
                    case 3:
                        productFactory = _b.sent();
                        mainProductFullyUIDList = productFactory
                            .extractFulllyMainProductUIDList();
                        mainProductSemiUIDList = productFactory
                            .extractSemiMainProductUIDList();
                        allProductUIDList = productFactory
                            .extractAllProductUIDList();
                        automaticRiderUIDList = productFactory
                            .extractAutomaticRiderUIDList();
                        parentAndChildUIDList = productFactory
                            .extractParentAndChildUIDList(automaticRiderUIDList);
                        return [4 /*yield*/, deleteExcludedProducts(mainProductFullyUIDList, mainProductSemiUIDList, self, allProductUIDList, automaticRiderUIDList, parentAndChildUIDList)];
                    case 4:
                        _b.sent();
                        this.vm.productSyncedOrNot = false;
                        this.vm.productDetailsFromFactory = [];
                        _a = this.vm;
                        return [4 /*yield*/, this.productService.getNeedsAssessmentSumAssuredLevels().toPromise()];
                    case 5:
                        _a.needsAssessmentSumAssuredLevels = _b.sent();
                        if (!(mainProductSemiUIDList.length > 0)) return [3 /*break*/, 7];
                        return [4 /*yield*/, populateProductDetailsFromFactory(mainProductSemiUIDList, true)];
                    case 6:
                        _b.sent();
                        return [3 /*break*/, 9];
                    case 7: return [4 /*yield*/, populateProductDetailsFromFactory(mainProductFullyUIDList, false)];
                    case 8:
                        _b.sent();
                        _b.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    MyCommandCenterService.prototype.getPolicyToClone = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var request = {};
            request = {
                CedantId: _this.CEDANT_ID,
                CustomerReferenceid: _this.vm.entityId,
                DisplayAnswers: 'true',
                PolicyReferenceId: _this.vm.quoteId,
                ProcessDisclosures: 'true',
                ShowErrorDetails: '1',
                AnsweredQuestions: []
            };
            _this.quirkService.createOrUpdatePolicy(request, true).subscribe(function (result) {
                var e_22, _a;
                try {
                    var answeredQuestions = [];
                    try {
                        for (var _b = __values(result.PolicyDetail.AnsweredQuestions), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var answer = _c.value;
                            if (!_this.quirkService.checkQuestionPresent(answer.ExternalReferenceId)) {
                                continue;
                            }
                            var isNumber = _this.quirkService.getQuestionByExtReferenceId([answer.ExternalReferenceId])[0].Answer[0].DataType;
                            if (answer.MultipleAnswers && answer.MultipleAnswers.length > 0) {
                                answeredQuestions.push({
                                    ExternalReferenceId: answer.ExternalReferenceId,
                                    MultipleAnswers: answer.MultipleAnswers
                                });
                            }
                            else {
                                answeredQuestions.push({
                                    ExternalReferenceId: answer.ExternalReferenceId,
                                    Value: isNumber === 'Number' ? +answer.Value : answer.Value
                                });
                            }
                        }
                    }
                    catch (e_22_1) { e_22 = { error: e_22_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_22) throw e_22.error; }
                    }
                    resolve(answeredQuestions);
                }
                catch (e) {
                    reject(e);
                }
            }, function (err) {
                reject(err);
            });
        });
    };
    MyCommandCenterService.prototype.clonePolicy = function (quotId, anseweredQuestions, callback) {
        var request = {};
        request = {
            CedantId: this.CEDANT_ID,
            CustomerReferenceid: this.vm.entityId,
            DisplayAnswers: 'true',
            PolicyReferenceId: quotId,
            ProcessDisclosures: 'true',
            ShowErrorDetails: '1',
            AnsweredQuestions: anseweredQuestions
        };
        this.quirkService.createOrUpdatePolicy(request, false)
            .subscribe(function (res) {
            callback();
        });
    };
    MyCommandCenterService.prototype.checkIfStepHasQuestions = function () {
        var _this = this;
        var section;
        section = {
            ExternalReferenceId: AppConsts.ParentSteps.Additionalinfo,
            Questions: []
        };
        this.submitQuestionAnswers(section, this.vm.currentPolicyId, true)
            .subscribe(function (res) {
            _this.vm.isImprovingQuoteNeccesary = res.length === 0;
        });
    };
    MyCommandCenterService.prototype.getFormattedText = function (item) {
        if (item) {
            var strArr_1 = [];
            item = item.replace('  ', '');
            AppConsts.disclaimerText.StartingAndEndingsOfEveryParagraph.forEach(function (res) {
                if (item.indexOf(res.substring(0, getIndex(res))) > -1 && item.indexOf(res.substring(getIndex(res) + 2)) > -1) {
                    var items = item.substr(item.indexOf(res.substring(0, getIndex(res))), item.indexOf(res.substr(getIndex(res)).replace('##', '')) + (res.substr(getIndex(res)).replace('##', '').length));
                    var item2 = item.substr(item.indexOf(res.substring(0, getIndex(res))), items.indexOf(res.substr(getIndex(res)).replace('##', '')) + (res.substr(getIndex(res)).replace('##', '').length));
                    strArr_1.push(item2);
                }
            });
            if (strArr_1.length === 0) {
                return [item];
            }
            return strArr_1;
        }
        function getIndex(item) {
            return item.indexOf('##');
        }
    };
    MyCommandCenterService.prototype.getMedicalSchemes = function (accessToken) {
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
        });
        var url_ = this.API_BASE_URL_IDP + 'api/DirectSales/MedicalSchemes';
        return this.httpMethodBaseService.get(url_, headers);
    };
    MyCommandCenterService.prototype.createPerson = function (callback, showMessage) {
        if (showMessage === void 0) { showMessage = true; }
        var person = {
            Education: this.vm.userDetails.education,
            EffectiveDate: new Date(),
            GrossIncome: this.vm.userDetails.grossSalary,
            IDP_GUID: this.vm.entityId,
            SmokingStatusPUT: this.vm.userDetails.smokingStatus === 'Smoker' ? 'Smoker' : 'Non_Smoker',
            OccupationCategoryPUT: defaultOccupationClass(this.vm.userDetails.occupationCategory) || 'Class_1',
            SocioEconomicClassPUT: this.vm.userDetails.socialEconomic,
            PersonAPI: {
                ContactNumber: this.vm.userDetails.cellNumber,
                Email: this.vm.userDetails.email,
                DateOfBirth: this.vm.userDetails.identityNumber.toString().getDateOfBirthByIdNumber(),
                FirstName: this.vm.userDetails.name,
                Surname: this.vm.userDetails.surname,
                Gender: this.vm.userDetails.identityNumber.toString().getGenderFromIdNumber(),
                IdentificationType: 'IDNumber',
                IDNumber: this.vm.userDetails.identityNumber,
                Initials: this.vm.userDetails.name.substring(0, 1)
            }
        };
        this.policyService.createOrEditPerson(person, this.vm.entityId)
            .subscribe(function (res) {
            if (showMessage) {
                abp.notify.success('personal details updated');
            }
            if (callback) {
                return callback();
            }
        });
    };
    MyCommandCenterService.prototype.getQuoteFlowIsImprovingNeeded = function () {
        var _this = this;
        this.quirkService.createOrUpdatePolicy({
            AnsweredQuestions: [],
            CedantId: this.CEDANT_ID,
            CustomerReferenceid: this.vm.entityId,
            PolicyReferenceId: this.vm.quoteId,
            DisplayAnswers: 'true',
            ProcessDisclosures: 'true',
            ShowErrorDetails: '1'
        }, true).subscribe(function (res) {
            var result = res.PolicyDetail.AnsweredQuestions.find(function (x) { return x.ExternalReferenceId === AppConsts.DND_Questions.DND_sales_channel; });
            if (result) {
                _this.vm.isImprovingQuoteNeccesary = result.Value !== 1;
            }
        });
    };
    MyCommandCenterService.prototype.changeTemplatePopup = function (item, item1) {
        if (item !== undefined) {
            document.getElementsByClassName('swal2-confirm')[0].innerText = item;
            document.getElementsByClassName('swal2-confirm')[0].classList.add('modal-button-clr');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('font-size-12');
        }
        else {
            document.getElementsByClassName('swal2-confirm')[0].classList.add('d-none');
        }
        if (item1 !== undefined) {
            document.getElementsByClassName('swal2-cancel')[0].innerText = item1;
            document.getElementsByClassName('swal2-cancel')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-cancel')[0].classList.add('font-size-12');
        }
        else {
            document.getElementsByClassName('swal2-cancel')[0].classList.add('d-none');
        }
    };
    MyCommandCenterService.prototype.sendPolicyReferencesEmail = function () {
        var _this = this;
        var self = this;
        function getStatus(submitResult) {
            if (self.isQuestionnaireReferred(submitResult)) {
                return 'Medical Referred';
            }
            if (submitResult.PolicyStatusName === AppConsts.quircSubmittingQuestionAnalysis.PolicyStatusName.Referred) {
                return 'HIV Referred';
            }
            return submitResult.PolicyStatusName;
        }
        var couple = this.vm.quotePolicyCouples.find(function (c) { return c.policy && c.policy.ExternalReferenceId && c.policy.ExternalReferenceId === _this.vm.currentPolicyId; });
        this.policyService.queuePolicyReferencesEmail(this.vm.currentPolicyId, this.vm.submitQuestionaireResult, getStatus(this.vm.submitQuestionaireResult), couple.quote.Person_LifeAssured.Identifier, couple.quote.Person_MainMember.Identifier, couple.quote.Person_Broker.Identifier).subscribe(function () {
        }, function (err) {
            console.error(err);
        });
    };
    MyCommandCenterService.prototype.showExclusionPopups = function () {
        var e_23, _a, e_24, _b, e_25, _c;
        this.vm.productExclusionMap = [];
        var _loop_13 = function (product) {
            var _a;
            var exclusions = product.ProductReferences.filter(function (r) { return r.TypeName === 'Exclusions'; }).map(function (e) { return e.Reference; });
            if (exclusions.length === 0) {
                return "continue";
            }
            var quircProduct = this_2.vm.quircAfterSubmittingAnalysis.find(function (q) { return q.QuircProfileName === product.ProfileName; });
            var descriptionCategory = product.ProfileName === 'Impairment Cover' ? 'Disability Capital Protection' : product.ProfileName.includes('Income Protection') ? 'Disability Income Protection' : quircProduct.MendixProductCategory;
            var existingProductExclusionMap = this_2.vm.productExclusionMap.find(function (p) { return p.descriptionCategory === descriptionCategory; });
            if (existingProductExclusionMap) {
                (_a = existingProductExclusionMap.exclusions).push.apply(_a, __spread(exclusions));
                existingProductExclusionMap.exclusions = existingProductExclusionMap.exclusions.filter(function (v, i, a) { return a.indexOf(v) === i; });
            }
            else {
                this_2.vm.productExclusionMap.push({
                    descriptionCategory: descriptionCategory,
                    productCategory: quircProduct.MendixProductCategory,
                    exclusions: exclusions.filter(function (v, i, a) { return a.indexOf(v) === i; })
                });
            }
        };
        var this_2 = this;
        try {
            for (var _d = __values(this.vm.submitQuestionaireResult.Products), _e = _d.next(); !_e.done; _e = _d.next()) {
                var product = _e.value;
                _loop_13(product);
            }
        }
        catch (e_23_1) { e_23 = { error: e_23_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_23) throw e_23.error; }
        }
        var countOfDisabilityProducts = this.vm.productDetailsFromFactory.map(function (x) { return x.Category; }).filter(function (y) { return y === 'Disability Protection'; }).length;
        if (countOfDisabilityProducts === 1) {
            this.vm.productExclusionMap = this.vm.productExclusionMap.filter(function (x) { return x.descriptionCategory !== 'Disability Capital Protection'; });
        }
        var avaiableProductCategories = this.vm.productDetailsFromFactory.map(function (x) { return x.Category; }).filter(function (v, i, a) { return a.indexOf(v) === i; });
        var productExclusionMap = this.vm.productExclusionMap.filter(function (x) { return avaiableProductCategories.includes(x.productCategory); });
        var exlusionPopupContent = '';
        try {
            for (var _f = __values(this.vm.productExclusionMap), _g = _f.next(); !_g.done; _g = _f.next()) {
                var productExclusion = _g.value;
                exlusionPopupContent += '<b>' + productExclusion.descriptionCategory + '</b><br>';
                try {
                    for (var _h = __values(productExclusion.exclusions), _j = _h.next(); !_j.done; _j = _h.next()) {
                        var exclusion = _j.value;
                        exlusionPopupContent += exclusion + '<br><br>';
                    }
                }
                catch (e_25_1) { e_25 = { error: e_25_1 }; }
                finally {
                    try {
                        if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                    }
                    finally { if (e_25) throw e_25.error; }
                }
            }
        }
        catch (e_24_1) { e_24 = { error: e_24_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
            }
            finally { if (e_24) throw e_24.error; }
        }
        exlusionPopupContent = 'In addition to the General Exclusions that apply to all Elevate policies, the following additional individual exclusions will apply to your policies:<br><br>' + exlusionPopupContent;
        var message = abp.message.info(exlusionPopupContent, 'INDIVIDUAL EXCLUSIONS WILL APPLY', true);
        this.changeTemplatePopup('CONTINUE', undefined);
        return message;
    };
    MyCommandCenterService.prototype.policyStatusChecks = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.isQuestionnaireReferred(this.vm.submitQuestionaireResult) || this.isQuestionnaireDeclined(this.vm.submitQuestionaireResult))) return [3 /*break*/, 2];
                        this.vm.isReferred = true;
                        return [4 /*yield*/, this.showReferredModal()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.isQuestionaireExcluded(this.vm.submitQuestionaireResult)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.showExclusionPopups()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        if (!this.vm.isMinimumBenefitNotMet) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.showMinimumBenefitNotMetModal()];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    MyCommandCenterService.prototype.isQuestionnaireDeclined = function (questionnaire) {
        return questionnaire.PolicyStatusName === AppConsts.quircSubmittingQuestionAnalysis.PolicyStatusName.Declined;
    };
    MyCommandCenterService.prototype.isQuestionnaireReferred = function (questionnaire) {
        return questionnaire.PolicyStatusName === AppConsts.quircSubmittingQuestionAnalysis.PolicyStatusName.Referred && this.vm.submitQuestionaireResult.PolicyReferences.some(function (p) { return p.TypeName === 'Refer \/ Decline Reasons'; });
    };
    MyCommandCenterService.prototype.isQuestionaireExcluded = function (questionnaire) {
        if (!questionnaire.Products || questionnaire.Products.length === 0) {
            return false;
        }
        return questionnaire.Products.some(function (p) {
            return p.ProductReferences &&
                p.ProductReferences.length > 0 &&
                p.ProductReferences.some(function (r) { return r.TypeName === 'Exclusions'; });
        });
    };
    MyCommandCenterService.prototype.showMinimumBenefitNotMetModal = function () {
        var message = abp.message.warn('Given your income, one of your previously quoted products has been removed.', 'MINIMUM ASSURED LEVEL NOT MET');
        this.changeTemplatePopup('OK', undefined);
        return message;
    };
    MyCommandCenterService.prototype.showReferredModal = function () {
        var message = abp.message.info('We cannot confirm your underwriting just yet as we will need additional medical information from your doctor. <br><br>' +
            'An Elevate Customer Service agent will be in contact shortly to outline the next steps.<br><br>' +
            'In the meantime, please <b>CONTINUE</b> to see the Elevate products on offer.<br><br>' +
            '<b>Please note:</b> pricing will be indicative until your final premium is determined after we can confirm the remaining underwriting information needed.', 'MORE INFORMATION NEEDED', true);
        this.changeTemplatePopup('CONTINUE', undefined);
        return message;
    };
    MyCommandCenterService.prototype.showDeclinedModal = function () {
        var popup = abp.message.error('Unfortunately, we aren\'t able to tailor any products to match your needs today :(<br><br>But this shouldn\'t be the case for long!<br><br><a target="_blank" href="https://www.elevate.co.za/how-it-works/"><u>Find out more</u></a>', 'Policy Declined', true);
        this.changeTemplatePopup('OK', undefined);
        return popup;
    };
    MyCommandCenterService.prototype.getProductDescription = function (product) {
        if (!product) {
            return '';
        }
        if (product.Category === 'Severe Illness Protection') {
            return 'you are to suffer a severe illness';
        }
        if (product.Category === 'Disability Protection') {
            if (product.AssociatedProducts.length > 0) {
                return 'you being unable to work due to illness or disability';
            }
            if (product.DisplayName === 'Principal Disability Capital Protection - Accelerator') {
                return 'you are to suffer a disability';
            }
            if (product.DisplayName === 'Principal Disability Capital Protection') {
                return 'you are to suffer a disability';
            }
            return 'you becoming permanently disabled';
        }
        return 'your passing';
    };
    MyCommandCenterService.prototype.additiveunderwritingloading = function (productId) {
        var quircProduct = this.vm.quircAfterSubmittingAnalysis.find(function (x) { return x.MendixProductUID === productId; });
        return (quircProduct.sumUnitLoading / 1000) / 12;
    };
    MyCommandCenterService.prototype.multipicativeunderwritingloading = function (productId) {
        var quircProduct = this.vm.quircAfterSubmittingAnalysis.find(function (x) { return x.MendixProductUID === productId; });
        return (quircProduct.sumPercentageLoading / 100) + 1;
    };
    return MyCommandCenterService;
}(AppComponentBase));
export { MyCommandCenterService };
