<div bsModal #confirmProductPremiumModal="bs-modal">

  <div class="modal-header d-flex flex-row justify-content-between align-items-center">
    <h2 class="swal2-title mt-0 mb-0">{{header}}</h2>
    <button type="button" class="close p-0 m-0" (click)="closeAndRevert()">

      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body mp-0 mt-10 d-flex flex-column justify-content-center align-items-center">

    <div class="swal2-icon swal2-warning swal2-icon-show" style="display: flex;">
    </div>

    <h2 class="swal2-title mt-0 mb-3">Entry age exceeded</h2>

    <div class="d-flex flex-row justify-content-center align-items-center text-justify text-center mt-4 mb-4 ml-5 mr-5">
      {{title}}
    </div>

    <div class="d-flex flex-row justify-content-center align-items-center mt-4 mb-5">
      <div *ngIf="isConfirmModal" class="d-flex flex-row justify-content-center align-items-center flex-wrap">
        <button (click)="revert()" class="btn btn-danger modal-button-clr border-radius-55 font-size-12 mt-2">
          <small class="font-weight-bold">
            GO BACK & REVERT INCEPTION DATE
          </small>
        </button> 
        
        <button (click)="confirm()"
          class="btn btn-danger modal-button-clr border-radius-55 font-size-12 mt-2">
          <small class="font-weight-bold">
            CONTINUE & REMOVE PRODUCT(S)
          </small>
        </button>
      </div>
      <div *ngIf="!isConfirmModal">
        <button style="width: 150px" (click)="revert()"
          class="btn btn-danger modal-button-clr border-radius-55 font-size-12">
          <small class="font-weight-bold">
            GO BACK
          </small>
        </button>
      </div>
    </div>
  </div>
</div>