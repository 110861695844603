var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ProductService } from '@app/shared/services/product.service';
import { BenefitPattern } from './BenefitPattern';
var BenefitPatternFactory = /** @class */ (function () {
    function BenefitPatternFactory(injector) {
        this.injector = injector;
    }
    Object.defineProperty(BenefitPatternFactory.prototype, "productService", {
        get: function () {
            if (!this._productService) {
                this._productService = this.injector.get(ProductService);
            }
            return this._productService;
        },
        enumerable: true,
        configurable: true
    });
    BenefitPatternFactory.prototype.withMetadata = function (benefitPatternSetId) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this.productService.getBenefitPatternSet(benefitPatternSetId).toPromise()];
                    case 1:
                        _a.metaData = _b.sent();
                        return [2 /*return*/, this];
                    case 2:
                        e_1 = _b.sent();
                        abp.message.error('Please Contact Support, as we could not assist at present.');
                        abp.ui.clearBusy();
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BenefitPatternFactory.prototype.forBenefitPattern = function (benefitPatternUID) {
        this.MetadataBenefitPattern = this.metaData.BenefitPatterns.find(function (res) { return res.UID === benefitPatternUID; });
        return this;
    };
    BenefitPatternFactory.prototype.extractBenefitPatternUIDList = function () {
        if (!this || !this.metaData || this.metaData.BenefitPatterns.IsNullOrEmpty()) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            return null;
        }
        this.benefitPatternList = this.metaData.BenefitPatterns.map(function (_a) {
            var UID = _a.UID;
            return ({ UID: UID }.UID);
        });
        return this.benefitPatternList;
    };
    BenefitPatternFactory.prototype.extractUID = function () {
        this.UID = this.MetadataBenefitPattern.UID;
        return this;
    };
    BenefitPatternFactory.prototype.extractBenefitIncreaseIndex = function () {
        if (this.MetadataBenefitPattern.BeforeClaimEscalation_2 && this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked && this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked.Index) {
            this.benefitIncreaseIndex = this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked.Index.Name;
            return this;
        }
        if (this.MetadataBenefitPattern.BeforeClaimEscalation) {
            this.benefitIncreaseIndex = this.MetadataBenefitPattern.BeforeClaimEscalation;
            return this;
        }
        abp.message.error('Please Contact Support, as we could not assist at present.');
        abp.ui.clearBusy();
        throw new Error('Benefit Increase Index Empty.');
    };
    BenefitPatternFactory.prototype.extractBenefitIncrease = function () {
        this.benefitIncrease = '0';
        if (this.MetadataBenefitPattern.BeforeClaimEscalation_2) {
            if (this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked
                && this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked.BCAdditionalEscalation !== undefined && this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked.BCAdditionalEscalation !== null) {
                this.benefitIncrease = this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked.BCAdditionalEscalation;
                if (this.benefitIncreaseIndex.toLocaleLowerCase() === 'cpi') {
                    this.benefitIncrease = 'CPI + ' + this.benefitIncrease;
                }
            }
            if (this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_Fixed
                && this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation !== undefined && this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation !== null) {
                this.benefitIncrease = this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation;
                if (this.benefitIncreaseIndex.toLocaleLowerCase() === 'cpi') {
                    this.benefitIncrease = 'CPI + ' + this.benefitIncrease;
                }
                return this;
            }
        }
        return this;
    };
    BenefitPatternFactory.prototype.extractBenefitEscallationTerm = function () {
        if (this.MetadataBenefitPattern.CoverInForceCeaseAge) {
            this.benefitEscallationTerm = this.MetadataBenefitPattern.CoverInForceCeaseAge;
            return this;
        }
        abp.message.error('Please Contact Support, as we could not assist at present.');
        abp.ui.clearBusy();
        throw new Error('Benefit Term Not Found.');
    };
    BenefitPatternFactory.prototype.extractBenefitMaxPaymentAge = function () {
        if (this.MetadataBenefitPattern.InClaimEscalation_2 && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum) {
            this.maxPaymentAge = this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.MaxPaymentAge;
        }
        return this;
    };
    BenefitPatternFactory.prototype.extractInClaimbenefitIncreaseIndex = function () {
        if (this.MetadataBenefitPattern.InClaimEscalation_2 && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.ICE_NLS_IndexLinked && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.ICE_NLS_IndexLinked.Index.Name) {
            this.InClaimbenefitIncreaseIndex = this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.ICE_NLS_IndexLinked.Index.Name;
        }
        else if (this.MetadataBenefitPattern.InClaimEscalation_2) {
            this.InClaimbenefitIncreaseIndex = this.MetadataBenefitPattern.InClaimEscalation;
        }
        return this;
    };
    BenefitPatternFactory.prototype.extractInClaimbenefitIncrease = function () {
        if (this.MetadataBenefitPattern.InClaimEscalation_2 && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.ICE_NLS_IndexLinked && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.ICE_NLS_IndexLinked.Index.Name) {
            this.InClaimbenefitIncrease = 'CPI + 0';
            return this;
        }
        else if (this.MetadataBenefitPattern.InClaimEscalation_2) {
            this.InClaimbenefitIncrease = '0';
            return this;
        }
        return this;
    };
    BenefitPatternFactory.prototype.extractInClaimMaximumPaymentTerm = function () {
        if (this.MetadataBenefitPattern.InClaimEscalation_2 && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.MaxPaymentTerm) {
            this.InClaimMaximumPaymentTerm = this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.MaxPaymentTerm;
        }
        return this;
    };
    BenefitPatternFactory.prototype.getIfIdisability = function () {
        if (this.metaData.BenefitPatterns.some(function (x) { return x.PaymentFrequency.trim().toLocaleLowerCase() === 'monthly'; })) {
            return true;
        }
        return false;
    };
    BenefitPatternFactory.prototype.build = function () {
        return new BenefitPattern(this.UID, this.benefitIncreaseIndex, this.benefitIncrease, this.benefitEscallationTerm, this.InClaimbenefitIncreaseIndex, this.InClaimbenefitIncrease, this.maxPaymentAge, this.InClaimMaximumPaymentTerm);
    };
    return BenefitPatternFactory;
}());
export { BenefitPatternFactory };
