var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { HttpHeaders } from '@angular/common/http';
import { forkJoin, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';
import { KeyedCollection } from 'shared/utils/dictonary-helper';
import { QuestionType } from '@shared/AppEnums';
import { HttpMethodBaseService } from './HttpMethodsBase/httpmethods-base';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./HttpMethodsBase/httpmethods-base";
import * as i2 from "../common/IDSVRAuthProviders/auth.service";
//#region newcode
var ACCESS_TOKEN = '';
var QuirkService = /** @class */ (function () {
    function QuirkService(httpMethodBaseService, _authService) {
        this.httpMethodBaseService = httpMethodBaseService;
        this._authService = _authService;
        this.API_BASE_QUIRK_URL = abp.setting.values[AppConsts.KeyVaultSecrets.quirkRootUrl];
        this.CEDANT_ID = abp.setting.values[AppConsts.KeyVaultSecrets.cedantId];
        this.ELEVATE_ACCP_ENDPOINT = abp.setting.values[AppConsts.KeyVaultSecrets.apimElevateAccpendpoint];
        this.APIM_CLIENT_ENDPOINT = abp.setting.values[AppConsts.KeyVaultSecrets.apimClientEndpoint];
        this.cachedAllQuestionResponse = new Map();
        this.allQuestionsNeedToBePresented = [];
        this.conditionsSubject = new Subject();
    }
    QuirkService.prototype.init = function () {
        this.getQuestionnaireProfileVersion();
        this.getKeyToValueMapping('Personal Info');
        this.getSocioEconomicDimension();
    };
    QuirkService.prototype.getSocioEconomicDimension = function () {
        var _this = this;
        var endPoint = abp.setting.values[AppConsts.KeyVaultSecrets.QuirkApiDetailsQuestionMapping] + AppConsts.Endpoints.socioEconomicDimension;
        var socioResponse = this.cachedAllQuestionResponse.get(endPoint);
        if (socioResponse) {
            return socioResponse;
        }
        this.httpMethodBaseService.get(endPoint)
            .subscribe(function (res) {
            _this.cachedAllQuestionResponse.set(endPoint, res);
            return res;
        });
    };
    QuirkService.prototype.getLatestPolicy = function (userEntityGuid) {
        var _this = this;
        var _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getPolicyList;
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        return this.httpMethodBaseService.post(_url, {
            CustomerReferenceId: userEntityGuid,
            ShowErrorDetails: true
        }, headers).pipe(map(function (response) {
            _this.policies = response.PolicyListResult;
            if (response.NumberOfPolicies === 0) {
                return null;
            }
            var policyList = response.PolicyListResult.reverse()[0];
            if (policyList.AcceptedStatusName === 'TakenUp' || policyList.AcceptedStatusName === 'CaptureError') {
                return null;
            }
            return policyList;
        }));
    };
    QuirkService.prototype.getPolicies = function (userEntityGuid) {
        var _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getPolicyList;
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        return this.httpMethodBaseService.post(_url, {
            CustomerReferenceId: userEntityGuid,
            ShowErrorDetails: true
        }, headers).pipe(map(function (response) {
            return response.PolicyListResult;
        }));
    };
    QuirkService.prototype.getPolicy = function (userEntityGuid, quoteGuid) {
        var _this = this;
        var _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getPolicyList;
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        return this.httpMethodBaseService.post(_url, {
            CustomerReferenceId: userEntityGuid,
            ShowErrorDetails: true
        }, headers).pipe(map(function (response) {
            if (response.NumberOfPolicies === 0) {
                return null;
            }
            _this.policies = response.PolicyListResult;
            var policy = response.PolicyListResult.find(function (p) { return p.ExternalReferenceId === quoteGuid; });
            if (!policy) {
                return null;
            }
            return policy;
        }));
    };
    QuirkService.prototype.createOrUpdatePolicy = function (updatePolicyOfApplicantWithAnswers, isCached) {
        var _this = this;
        var _headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        var cachedQuestions = this.cachedAllQuestionResponse.get('UpdatedQuestion');
        if (isCached && cachedQuestions) {
            return of(cachedQuestions);
        }
        return this.httpMethodBaseService.post(this.API_BASE_QUIRK_URL + AppConsts.Endpoints.createOrUpdatePolicy, updatePolicyOfApplicantWithAnswers, _headers).pipe(map(function (response) {
            _this.cachedAllQuestionResponse.set('UpdatedQuestion', response);
            return response;
        }));
    };
    QuirkService.prototype.createOrUpdateApplicant = function (createOrUpdateApplicantDtoOutpuModel) {
        var _headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        return this.httpMethodBaseService.post(this.API_BASE_QUIRK_URL + AppConsts.Endpoints.createOrUpdateApplicant, createOrUpdateApplicantDtoOutpuModel, _headers).pipe(map(function (response) {
            return response;
        }));
    };
    QuirkService.prototype.getBrokerage = function (brokerageGuid) {
        var _this = this;
        var url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.getBrokerage.replace('{BrokerageGUID}', brokerageGuid);
        var cachedBrokerage = this.cachedAllQuestionResponse.get(url);
        if (cachedBrokerage) {
            return of(cachedBrokerage);
        }
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        return this.httpMethodBaseService.get(url, headers).pipe(map(function (brokerage) {
            _this.cachedAllQuestionResponse.set(url, brokerage.result);
            return brokerage.result;
        }));
    };
    QuirkService.prototype.getConditionsFromQuestion = function (externalReferenceId) {
        var _this = this;
        var _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getConditionList;
        var conditionsFromCache = this.cachedAllQuestionResponse.get(_url + externalReferenceId);
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        if (conditionsFromCache) {
            return of(conditionsFromCache);
        }
        var response = this.httpMethodBaseService.post(_url, {
            CedantId: parseInt(this.CEDANT_ID),
            ExternalReferenceId: externalReferenceId,
            ShowErrorDetails: '1'
        }, headers).pipe(map(function (response) {
            return response.Data;
        }));
        response.pipe(tap(function (conditionsList) { return _this.cachedAllQuestionResponse.set(_url + externalReferenceId, conditionsList); }));
        return response;
    };
    QuirkService.prototype.getOccupationList = function (externalReferenceId) {
        var _this = this;
        var _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getConditionList;
        var conditionsFromCache = this.cachedAllQuestionResponse.get(_url);
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        if (conditionsFromCache) {
            return of(conditionsFromCache);
        }
        var response = this.httpMethodBaseService.post(_url, {
            CedantId: parseInt(this.CEDANT_ID),
            ExternalReferenceId: externalReferenceId,
            ShowErrorDetails: '1'
        }, headers).pipe(map(function (response) {
            return response.Data;
        }));
        response.subscribe(function (occupationList) { return _this.cachedAllQuestionResponse.set(_url, occupationList); });
        return response;
    };
    QuirkService.prototype.submitProcessQuestionaryAndProvideAsperDictonaryMapping = function (submitQuestionAndAnswers, userDetails, isCached) {
        return this.getQuestionForStepper(submitQuestionAndAnswers.sections.ExternalReferenceId, ACCESS_TOKEN, submitQuestionAndAnswers.customerReferenceid, submitQuestionAndAnswers.policyReferenceId, this.getAnsweredQuestions(submitQuestionAndAnswers.sections, userDetails), isCached).pipe(map(function (res) {
            return res;
        }));
    };
    QuirkService.prototype.getQuestionForStepper = function (sectionName, accessToken, customerReferenceId, policyReferenceId, answereQuestions, isCached) {
        var _this = this;
        return this.createOrUpdatePolicy({
            AnsweredQuestions: answereQuestions,
            CedantId: this.CEDANT_ID,
            CustomerReferenceid: customerReferenceId,
            DisplayAnswers: 'true',
            PolicyReferenceId: policyReferenceId,
            ProcessDisclosures: 'true',
            ShowErrorDetails: '1'
        }, isCached).pipe(map(function (res) {
            _this.policyId = res.PolicyDetail.PolicyId;
            return _this.processGetQuestionForStepper(res.PolicyDetail, sectionName);
        }));
    };
    QuirkService.prototype.getQuestionById = function (id) {
        var _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getQuestionnaireProfileVersion;
        var res = JSON.parse(this.cachedAllQuestionResponse.get(_url));
        var sectionToplayer = res.QuestionDetails.Sections;
        var result1 = sectionToplayer.map(function (_a) {
            var Questions = _a.Questions;
            return ({ Questions: Questions }.Questions.filter(function (x) { return id.some(function (a) { return a === x.Id; }); }));
        }).customFlat();
        var result2 = sectionToplayer.map(function (_a) {
            var Sections = _a.Sections;
            return ({ Sections: Sections }.Sections.map(function (_a) {
                var Questions = _a.Questions;
                return ({ Questions: Questions }.Questions.filter(function (x) { return id.some(function (a) { return a === x.Id; }); }));
            }));
        }).customFlat().customFlat();
        var result3Temp = sectionToplayer.map(function (_a) {
            var Sections = _a.Sections;
            return ({ Sections: Sections }.Sections.map(function (_a) {
                var Sections = _a.Sections;
                return ({ Sections: Sections }.Sections);
            }));
        }).customFlat().customFlat();
        var result3 = result3Temp.map(function (_a) {
            var Questions = _a.Questions;
            return ({ Questions: Questions }.Questions.filter(function (x) { return id.some(function (a) { return a === x.Id; }); }));
        }).customFlat();
        if (result1 && result1.length) {
            return result1.setQuestionType();
        }
        else if (result2 && result2.length) {
            return result2.setQuestionType();
        }
        else if (result3 && result3.length) {
            return result3.setQuestionType();
        }
        else {
            return null;
        }
    };
    QuirkService.prototype.checkQuestionPresent = function (item) {
        return this.allQuestionsNeedToBePresented.some(function (x) { return x.extId === item.toLowerCase() || x.extId === item.toUpperCase(); });
    };
    QuirkService.prototype.getQuestionByExtReferenceId = function (extReferenceId) {
        var _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getQuestionnaireProfileVersion;
        var res = JSON.parse(this.cachedAllQuestionResponse.get(_url));
        var sectionToplayer = res.QuestionDetails.Sections;
        res = undefined;
        var result1 = sectionToplayer.map(function (_a) {
            var Questions = _a.Questions;
            return ({ Questions: Questions }.Questions.filter(function (x) { return extReferenceId.some(function (a) { return a === x.ExternalReferenceId; }); }));
        }).customFlat();
        var result2 = sectionToplayer.map(function (_a) {
            var Sections = _a.Sections;
            return ({ Sections: Sections }.Sections.map(function (_a) {
                var Questions = _a.Questions;
                return ({ Questions: Questions }.Questions.filter(function (x) { return extReferenceId.some(function (a) { return a === x.ExternalReferenceId; }); }));
            }));
        }).customFlat().customFlat();
        var result3Temp = sectionToplayer.map(function (_a) {
            var Sections = _a.Sections;
            return ({ Sections: Sections }.Sections.map(function (_a) {
                var Sections = _a.Sections;
                return ({ Sections: Sections }.Sections);
            }));
        }).customFlat().customFlat();
        var result3 = result3Temp.map(function (_a) {
            var Questions = _a.Questions;
            return ({ Questions: Questions }.Questions.filter(function (x) { return extReferenceId.some(function (a) { return a === x.ExternalReferenceId; }); }));
        }).customFlat();
        if (result1 && result1.length) {
            return result1.setQuestionType();
        }
        else if (result2 && result2.length) {
            return result2.setQuestionType();
        }
        else if (result3 && result3.length) {
            return result3.setQuestionType();
        }
        else {
            return null;
        }
    };
    QuirkService.prototype.processGetQuestionForStepper = function (policyDetails, sectionName) {
        var keyValueMappingDictonary = this.getKeyToValueMapping(sectionName);
        var filteredPerSection = policyDetails.AnsweredQuestions.filter(function (x) { return keyValueMappingDictonary.ContainsKey(x.ExternalReferenceId.toLowerCase()); });
        var keyValueSet = this.handleAnsweredQuestions(filteredPerSection, keyValueMappingDictonary);
        var filteredPerSectionQuestion = policyDetails.UnAnsweredQuestions.filter(function (x) { return keyValueMappingDictonary.ContainsKey(x.ExternalReferenceId.toLowerCase()); });
        keyValueSet = this.handleUnAnsweredQuestions(filteredPerSectionQuestion, keyValueMappingDictonary, keyValueSet);
        return keyValueSet.getList();
    };
    //#region Helper Methods
    /**
     * comment practicing for code readability
     * Create A Quote Or Update A Quote
     * @param createQuote
     * @param quoteGUid
     */
    QuirkService.prototype.CreateOrUpdateQuote = function (createQuote, quoteGUid) {
        var _url = '';
        var request = '';
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this._authService.user.access_token
        });
        if (quoteGUid) {
            _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.updateQuote.replace('{Quote_GUID}', quoteGUid);
            request = 'put';
        }
        else {
            _url = this.APIM_CLIENT_ENDPOINT + AppConsts.ElevateMendixEndpoints.createQuote;
            request = 'post';
        }
        return this.httpMethodBaseService.request(request, _url, createQuote, headers)
            .pipe(map(function (response) {
            return response.result.GUID;
        }));
    };
    QuirkService.prototype.getPolicySchedule = function (customerReferenceid, policyExternalReferenceId) {
        var url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getPolicySchedule;
        var body = {
            CedantId: this.CEDANT_ID,
            ShowErrorDetails: '1',
            CustomerReferenceid: customerReferenceid,
            PolicyReferenceId: policyExternalReferenceId
        };
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        return this.httpMethodBaseService.post(url, body, headers);
    };
    QuirkService.prototype.getQuestionnaireProfileVersion = function () {
        var _this = this;
        var _url = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getQuestionnaireProfileVersion;
        var alreadyCachedResult = this.cachedAllQuestionResponse.get(_url);
        if (alreadyCachedResult) {
            return of(JSON.parse(alreadyCachedResult));
        }
        var _body = {
            CedantId: this.CEDANT_ID,
            ShowErrorDetails: '1'
        };
        var _headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ACCESS_TOKEN
        });
        var response = this.httpMethodBaseService.post(_url, _body, _headers).pipe(map(function (response) {
            return response;
        }));
        var conditions = [];
        if (!this.hasLoadedConditions) {
            response.subscribe(function (res) {
                var e_1, _a, e_2, _b;
                try {
                    // Get conditions for dropdown questions.
                    for (var _c = __values(res.QuestionDetails.Sections), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var section = _d.value;
                        try {
                            for (var _e = __values(section.Questions), _f = _e.next(); !_f.done; _f = _e.next()) {
                                var question = _f.value;
                                if (question.Answer.length > 0 && (question.Answer[0].AnswerType === AppConsts.AnswerType.SelectionAnswerProvider || question.Answer[0].AnswerType === AppConsts.AnswerType.MultipleSelectionAnswerProvider)) {
                                    conditions.push({
                                        question: question,
                                        conditions: _this.getConditionsFromQuestion(question.ExternalReferenceId)
                                    });
                                }
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                if (conditions.length > 0) {
                    var allConditions = conditions.map(function (c) { return c.conditions; });
                    forkJoin(allConditions).subscribe(function (conditionRes) {
                        for (var i = 0; i < conditions.length; i++) {
                            conditions[i].question.CondtionValues = conditionRes[i];
                        }
                        _this.cachedAllQuestionResponse.set(_url, JSON.stringify(res));
                        _this.hasLoadedConditions = true;
                        _this.conditionsSubject.next(true);
                    });
                }
                else {
                    _this.cachedAllQuestionResponse.set(_url, JSON.stringify(res));
                    _this.hasLoadedConditions = true;
                    _this.conditionsSubject.next(true);
                }
            });
        }
        return response;
    };
    QuirkService.prototype.conditionsObservable = function () {
        return this.conditionsSubject.asObservable();
    };
    QuirkService.prototype.handleUnAnsweredQuestions = function (filteredPerSection, keyValueMappingDictonary, keyValueSet) {
        var _this = this;
        var keyValueWithQuestion = keyValueSet;
        filteredPerSection.forEach(function (item) {
            var questionForCurrentIteration = _this.getQuestionByExtReferenceId([item.ExternalReferenceId])[0];
            questionForCurrentIteration = [questionForCurrentIteration].setQuestionType()[0];
            var currSection = keyValueMappingDictonary.Item(item.ExternalReferenceId.toLowerCase());
            var extValue = keyValueWithQuestion.Item(currSection);
            if (extValue) {
                keyValueWithQuestion.Item(currSection).push(questionForCurrentIteration);
            }
            else {
                keyValueWithQuestion.Add(currSection, [questionForCurrentIteration]);
            }
        });
        return keyValueWithQuestion;
    };
    QuirkService.prototype.handleAnsweredQuestions = function (filteredPerSection, keyValueMappingDictonary) {
        var _this = this;
        var keyValueWithQuestion = new KeyedCollection();
        filteredPerSection.forEach(function (item) {
            var questionForCurrentIteration = _this.getQuestionByExtReferenceId([item.ExternalReferenceId])[0];
            if (item.MultipleAnswers != null && item.MultipleAnswers.length > 0 &&
                questionForCurrentIteration &&
                questionForCurrentIteration.Answer.length > 0 && questionForCurrentIteration.AllowMultiAnswers &&
                questionForCurrentIteration.Answer[0].AnswerType === AppConsts.AnswerType.BasicAnswerProvider) {
                questionForCurrentIteration.ValueGiven = item.MultipleAnswers.map(function (_a) {
                    var Value = _a.Value;
                    return ({ Value: Value }.Value);
                }).customFlat();
                questionForCurrentIteration.Answer.filter(function (x) { return item.MultipleAnswers.some(function (c) { return c.Value === x.Value; }); }).forEach(function (a) {
                    a.ExistingAnswer = true;
                });
                questionForCurrentIteration.QuestionType = QuestionType.MultiAnswer;
            }
            else if ((item.MultipleAnswers == null || item.MultipleAnswers.length === 0) &&
                questionForCurrentIteration &&
                questionForCurrentIteration.Answer.length > 0 && !questionForCurrentIteration.AllowMultiAnswers &&
                (questionForCurrentIteration.Answer[0].AnswerType === AppConsts.AnswerType.BasicAnswerProvider)) {
                questionForCurrentIteration.Answer.filter(function (x) { return x.AnswerType === 'SingleEntryAnswerProvider' && item.Value === x.Value; }).forEach(function (x) { return x.ExistingAnswer = true; });
                questionForCurrentIteration.ValueGiven = questionForCurrentIteration.Answer[0].DataType === 'Number' ? +item.Value : item.Value;
                questionForCurrentIteration.QuestionType = QuestionType.SingleSelect;
            }
            else if ((item.MultipleAnswers == null || item.MultipleAnswers.length === 0) &&
                questionForCurrentIteration &&
                questionForCurrentIteration.Answer.length > 0 && !questionForCurrentIteration.AllowMultiAnswers &&
                (questionForCurrentIteration.Answer[0].AnswerType === AppConsts.AnswerType.SingleEntryAnswerProvider)) {
                questionForCurrentIteration.Answer.filter(function (x) { return x.AnswerType === 'SingleEntryAnswerProvider' && item.Value === x.Value; }).forEach(function (x) { return x.ExistingAnswer = true; });
                questionForCurrentIteration.ValueGiven = questionForCurrentIteration.Answer[0].DataType === 'Number' ? +item.Value : item.Value;
                questionForCurrentIteration.QuestionType = QuestionType.FreeText;
            }
            else if (questionForCurrentIteration &&
                questionForCurrentIteration.Answer.length > 0 && questionForCurrentIteration.AllowMultiAnswers &&
                questionForCurrentIteration.Answer[0].AnswerType === AppConsts.AnswerType.MultipleSelectionAnswerProvider) {
                questionForCurrentIteration.QuestionType = QuestionType.ConditionsMultiSelect;
                questionForCurrentIteration.ValueGiven = item.MultipleAnswers.map(function (res) { return +res.Value; });
                questionForCurrentIteration.valueCondtions = item.MultipleAnswers.map(function (res) { return +res.Value; });
            }
            else if (questionForCurrentIteration &&
                questionForCurrentIteration.Answer.length > 0 && !questionForCurrentIteration.AllowMultiAnswers &&
                questionForCurrentIteration.Answer[0].AnswerType === AppConsts.AnswerType.SelectionAnswerProvider) {
                questionForCurrentIteration.QuestionType = QuestionType.ConditionsSingleSelect;
                questionForCurrentIteration.ValueGiven = item.Value;
                questionForCurrentIteration.valueCondtions = +item.Value;
            }
            if (questionForCurrentIteration && (questionForCurrentIteration.ExternalReferenceId.indexOf('consent') > -1 || questionForCurrentIteration.ExternalReferenceId.indexOf('disclaimer') > -1)) {
                questionForCurrentIteration.QuestionType = QuestionType.DataConsent;
                questionForCurrentIteration.AnsweredStatus = questionForCurrentIteration.Answer.some(function (x) { return x.Value === item.Value; });
            }
            questionForCurrentIteration.AnsweredStatus = true;
            var currSection = keyValueMappingDictonary.Item(item.ExternalReferenceId.toLowerCase());
            var extItem = keyValueWithQuestion.Item(currSection);
            if (extItem) {
                keyValueWithQuestion.Item(currSection).push(questionForCurrentIteration);
            }
            else {
                keyValueWithQuestion.Add(currSection, [questionForCurrentIteration]);
            }
        });
        return keyValueWithQuestion;
    };
    QuirkService.prototype.getKeyToValueMapping = function (parentSectId) {
        var _this = this;
        var endPoint = abp.setting.values[AppConsts.KeyVaultSecrets.QuirkApiDetailsQuestionMapping] + AppConsts.Endpoints.quirkDictonary;
        var keyValueMapped = this.cachedAllQuestionResponse.get(endPoint);
        if (keyValueMapped) {
            return keyValueMapped.Item(parentSectId);
        }
        var keyValue = new KeyedCollection();
        this.httpMethodBaseService.get(endPoint)
            .subscribe(function (res) {
            res.forEach(function (x) {
                x.value.forEach(function (x) { return x.extId = x.extId.toLowerCase(); });
                var d = new KeyedCollection();
                keyValue.Add(x.name, d);
                keyValue.Item(x.name).AddRange(x.value);
                _this.allQuestionsNeedToBePresented = _this.allQuestionsNeedToBePresented.concat(x.value);
            });
            _this.cachedAllQuestionResponse.set(endPoint, keyValue);
        });
        return keyValue.Item(parentSectId);
    };
    QuirkService.prototype.getAnsweredQuestions = function (section, userDetails) {
        var _this = this;
        var anseweredQuestions = [];
        section.Questions.forEach(function (item) {
            if (['net_monthly_income', 'Household_income', 'monthly_income'].some(function (x) { return x === item.ExternalReferenceId; })) {
                _this.cachedAllQuestionResponse.set(item.ExternalReferenceId, item.ValueGiven);
            }
            var answeres;
            if (item.ExternalReferenceId.toUpperCase().startsWith('DND')) {
                item = [item].checkForAnsweringDND(userDetails);
            }
            if (item.AllowMultiAnswers) {
                var multiAnswer_1 = [];
                if (item.ValueGiven) {
                    item.ValueGiven.forEach(function (x) {
                        multiAnswer_1.push({
                            Value: x
                        });
                    });
                }
                answeres = {
                    QuestionProfileId: item.Id,
                    MultipleAnswers: multiAnswer_1
                };
            }
            else {
                answeres = {
                    QuestionProfileId: item.Id,
                    Value: item.Answer[0].DataType === 'Number' ? +item.ValueGiven : item.ValueGiven
                };
            }
            anseweredQuestions.push(answeres);
        });
        return anseweredQuestions;
    };
    QuirkService.prototype.submitQuestionnaire = function (paramSubmit, paramSubmit2) {
        var _this = this;
        if (paramSubmit2) {
            paramSubmit2.CedantId = this.CEDANT_ID;
        }
        var submitquestionnare = this.cachedAllQuestionResponse.get(paramSubmit ? paramSubmit.PolicyId : paramSubmit2.PolicyReferenceId);
        if (submitquestionnare) {
            return of(submitquestionnare);
        }
        var endPoint = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.submitQuestionnaire;
        return this.httpMethodBaseService.post(endPoint, paramSubmit ? paramSubmit : paramSubmit2).pipe(map(function (res) {
            _this.cachedAllQuestionResponse.set(paramSubmit ? paramSubmit.PolicyId : paramSubmit2.PolicyReferenceId, res);
            return res;
        }));
    };
    QuirkService.prototype.updatePolicyStatus = function (paramPolicy) {
        var endPoint = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.updatePolicyStatus;
        return this.httpMethodBaseService.post(endPoint, paramPolicy).pipe(map(function (res) {
            console.log(res);
            return res;
        }));
    };
    QuirkService.prototype.getConditionSearch = function (setupTable) {
        var _this = this;
        setupTable.ShowErrorDetails = '1';
        var resultFromCache = this.cachedAllQuestionResponse.get(setupTable.SearchFilter + setupTable.SetupTableId);
        if (resultFromCache) {
            return of(resultFromCache);
        }
        var endPoint = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.getSetupTableData;
        return this.httpMethodBaseService.post(endPoint, setupTable).pipe(map(function (res) {
            _this.cachedAllQuestionResponse.set(setupTable.SearchFilter + setupTable.SetupTableId, res.Data);
            return res.Data;
        }));
    };
    QuirkService.prototype.clonePolicy = function (policyId) {
        var _this = this;
        var endPoint = this.API_BASE_QUIRK_URL + AppConsts.Endpoints.clonePolicy;
        var body = {
            PolicyReferenceId: policyId,
            ShowErrorDetails: '1'
        };
        return this.httpMethodBaseService.post(endPoint, body).pipe(map(function (res) {
            _this.cachedAllQuestionResponse.set(endPoint, res);
            return res;
        }));
    };
    QuirkService.prototype.getAnsweredQuestion = function (customerReferenceId, policyReferenceId, extId) {
        return __awaiter(this, void 0, void 0, function () {
            var policyInput, policyResult, question;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        policyInput = {
                            AnsweredQuestions: [],
                            CedantId: this.CEDANT_ID,
                            CustomerReferenceid: customerReferenceId,
                            DisplayAnswers: 'true',
                            PolicyReferenceId: policyReferenceId,
                            ProcessDisclosures: 'true',
                            ShowErrorDetails: '1'
                        };
                        return [4 /*yield*/, this.createOrUpdatePolicy(policyInput, true).toPromise()];
                    case 1:
                        policyResult = _a.sent();
                        question = policyResult.PolicyDetail.AnsweredQuestions.find(function (a) { return a.ExternalReferenceId === extId; });
                        return [2 /*return*/, question];
                }
            });
        });
    };
    QuirkService.ngInjectableDef = i0.defineInjectable({ factory: function QuirkService_Factory() { return new QuirkService(i0.inject(i1.HttpMethodBaseService), i0.inject(i2.AuthService)); }, token: QuirkService, providedIn: "root" });
    return QuirkService;
}());
export { QuirkService };
