import { BenefitModule, PremiumPatternModule, RateTableModule, ProductListModule } from '@shared/models/interfaces/elevate-data-models';
import * as _ from 'lodash';

export class BenefitPattern {
    name: string;
    value: BenefitModule.IBenefitPattern;
}

export class PremiumPattern {
    name: string;
    value: PremiumPatternModule.PremiumPattern;
}

export class ProductQuote {
    Product: string;
    BenefitPattern: string;
    PremiumPattern: string;
    EventSet: string;
    AssuredValue: number;
    MultiplicativeUnderwritingLoading: number;
    AdditiveUnderwritingLoading: number;
}

export class RiderProductQuote extends ProductQuote {
    MainProduct: string;
}

declare global {

    export interface Array<T> {
        fetchBenefitNames(): Array<BenefitPattern>;
        fetchPremiumNames(): Array<PremiumPattern>;
        deleteDuplicateBenefitNames(): Array<BenefitPattern>;
        filterBenefitIncrease(benefit: BenefitModule.IBenefitPattern): Array<BenefitModule.IBenefitPattern>;
        filterBenefitTerm(benefit: BenefitModule.IBenefitPattern): Array<BenefitModule.IBenefitPattern>;
        filterBenefitPatterns(rateTableData: Array<RateTableModule.IRateTableModel>): Array<BenefitModule.IBenefitPattern>;
        fetchAllBenefitUids(): Array<string>;
        fetchAllPremiumUid(): Array<string>;
        filterPremiumPatterns(rateTableData: Array<RateTableModule.IRateTableModel>): Array<PremiumPatternModule.PremiumPattern>;
        filterPatternIncrease(premiumPattern: PremiumPatternModule.PremiumPattern): Array<PremiumPatternModule.PremiumPattern>;
        filterPremiumPatternTerm(premiumPattern: PremiumPatternModule.PremiumPattern): Array<PremiumPatternModule.PremiumPattern>;
        deleteDuplicatePremiumIndex(): Array<PremiumPatternModule.PremiumPattern>;
        deleteDuplicatePremiumNames(): Array<PremiumPattern>;
        deleteDuplicateBenefitIncreases(): Array<BenefitModule.IBenefitPattern>;
        deleteDuplicateBenefitTerms(): Array<BenefitModule.IBenefitPattern>;
        getTotalPremiumAmount(): number;
        distinctList(rateTable: RateTableModule.IRateTableModel[]): BenefitModule.IBenefitPattern[];
        getIncreaseIndex(): string;
        getBenefitInrease(): any;
        getBenefitTerm(): any;
        getPremiumIncreaseIndex(): any;
        getPremiumIncrease(): any;
        fetchProductList(): Array<{ productSectionName: string, products: Array<ProductListModule.ISelectedProductDetails> }>;
        customFlat(): any;
    }

}

Array.prototype.customFlat = function () {
    let _self = this as Array<any>;
    return _self.reduce((a, b) => a.concat(b), []);
};

Array.prototype.getPremiumIncrease = function () {
    return '';
};
Array.prototype.getPremiumIncreaseIndex = function () {
    let _self = this[0] as PremiumPatternModule.PremiumPattern;

    return _self.PremiumEscalationType ? _self.IndexLinkedPremium ? _self.IndexLinkedPremium.Index.Name : _self.PremiumEscalationType : '***';
};

Array.prototype.getIncreaseIndex = function () {
    let _self = this[0] as BenefitModule.IBenefitPattern;
    if (_self && _self.BeforeClaimEscalation_2 && _self.BeforeClaimEscalation_2.BCE_IndexLinked && _self.BeforeClaimEscalation_2.BCE_IndexLinked.Index && _self.BeforeClaimEscalation_2.BCE_IndexLinked.Index.Name) {
        return _self.BeforeClaimEscalation_2.BCE_IndexLinked.Index.Name;
    } else {
        return _self.BeforeClaimEscalation;
    }
};

Array.prototype.getBenefitInrease = function () {
    let _self = this[0] as BenefitModule.IBenefitPattern;
    if (_self && _self.BeforeClaimEscalation_2 && _self.BeforeClaimEscalation_2.BCE_Fixed && _self.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation) {
        return _self.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation;
    } else if (_self && _self.BeforeClaimEscalation_2 && _self.BeforeClaimEscalation_2.BCE_IndexLinked && _self.BeforeClaimEscalation_2.BCE_IndexLinked.BCAdditionalEscalation) {
        return _self.BeforeClaimEscalation_2.BCE_IndexLinked.BCAdditionalEscalation;
    } else if (_self && _self.BeforeClaimEscalation_2 && _self.BeforeClaimEscalation_2.BCE_Reviewable && _self.BeforeClaimEscalation_2.BCE_Reviewable.ReviewPeriod) {
        return _self.BeforeClaimEscalation_2.BCE_Reviewable.ReviewPeriod.replace('_', '');
    } else {
        return '0';
    }
};
Array.prototype.getBenefitTerm = function () {
    let _self = this[0] as BenefitModule.IBenefitPattern;
    if (_self && _self.CoverInForceCeaseAge) {
        return _self.CoverInForceCeaseAge;
    } else {
        return '';
    }
};


Array.prototype.fetchBenefitNames = function () {
    let _self = this as any as Array<BenefitModule.IBenefitPattern>;
    let benefitPatternArray: BenefitPattern[] = [];
    _self.forEach(data => {
        let patternData: BenefitPattern = new BenefitPattern();
        if (data.BeforeClaimEscalation) {
            if (data.BeforeClaimEscalation_2.BCE_IndexLinked) {
                if (data.BeforeClaimEscalation_2.BCE_IndexLinked.Index) {
                    patternData.name = data.BeforeClaimEscalation_2.BCE_IndexLinked.Index.Name;
                    patternData.value = data;
                }
            } else {
                patternData.name = data.BeforeClaimEscalation;
                patternData.value = data;
            }
        }
        benefitPatternArray.push(patternData);
    });
    return benefitPatternArray;
};



Array.prototype.distinctList = function (rateTable: RateTableModule.IRateTableModel[]) {
    let _self = this as BenefitModule.IBenefitPattern[];
    return _self.filter(t => rateTable.some(x => x.BenefitPatterns.some(f => f.UID === t.UID))).filter(
        (thing, i, arr) => arr.findIndex(t => (t.BeforeClaimEscalation || t.BeforeClaimEscalation_2.BCE_IndexLinked.Index.Name) === (thing.BeforeClaimEscalation || thing.BeforeClaimEscalation_2.BCE_IndexLinked.Index.Name)) === i
    );
};

Array.prototype.deleteDuplicateBenefitNames = function () {
    let _self = this as any as Array<BenefitPattern>;
    let filteredPatternSet = _self.filter((element, index, array) =>
        index === array.findIndex((data) =>
            data.name.toLowerCase() === element.name.toLowerCase())
    );
    return filteredPatternSet;

};

Array.prototype.filterBenefitPatterns = function (rateTableData) {
    let _self = this as any as Array<BenefitModule.IBenefitPattern>;
    let filteredBenefitPatternSet: BenefitModule.IBenefitPattern[] = [];
    _self.forEach(benefit => {
        rateTableData.forEach(rateTable => {
            rateTable.BenefitPatterns.forEach(data => {
                if (benefit.UID === data.UID) {
                    filteredBenefitPatternSet.push(benefit);
                }
            });
        });
    });
    return filteredBenefitPatternSet;
};


Array.prototype.filterBenefitIncrease = function (benefit) {
    let _self = this as any as Array<BenefitModule.IBenefitPattern>;
    let defaultData = new Array<BenefitModule.IBenefitPattern>();
    if (benefit.BeforeClaimEscalation_2.BCE_Fixed) {
        return _self.filter(x => x.BeforeClaimEscalation.toLowerCase()
            .includes(benefit.BeforeClaimEscalation.toLowerCase()));
    }
    if (benefit.BeforeClaimEscalation_2.BCE_IndexLinked) {
        return _self.filter(x => x.BeforeClaimEscalation.toLowerCase().includes(benefit.BeforeClaimEscalation.toLowerCase()));
    }
    if (benefit.BeforeClaimEscalation_2.BCE_Reviewable) {
        return _self.filter(x => x.BeforeClaimEscalation.toLowerCase().includes(benefit.BeforeClaimEscalation.toLowerCase()));
    } else {
        defaultData.push(benefit);
        return defaultData;
    }
};

Array.prototype.filterBenefitTerm = function (benefit) {
    let _self = this as any as Array<BenefitModule.IBenefitPattern>;
    if (benefit.BeforeClaimEscalation_2.BCE_Fixed) {
        return _self.filter(x => x.BeforeClaimEscalation.toLowerCase().includes(benefit.BeforeClaimEscalation.toLowerCase()) && x.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation === benefit.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation);
    }
    if (benefit.BeforeClaimEscalation_2.BCE_IndexLinked) {
        return _self.filter(x => x.BeforeClaimEscalation.toLowerCase().includes(benefit.BeforeClaimEscalation.toLowerCase()) && x.BeforeClaimEscalation_2.BCE_IndexLinked.BCAdditionalEscalation === benefit.BeforeClaimEscalation_2.BCE_IndexLinked.BCAdditionalEscalation);
    }
    if (benefit.BeforeClaimEscalation_2.BCE_Reviewable) {
        return _self.filter(x => x.BeforeClaimEscalation.toLowerCase().includes(benefit.BeforeClaimEscalation.toLowerCase()) && x.BeforeClaimEscalation_2.BCE_Reviewable.ReviewPeriod === benefit.BeforeClaimEscalation_2.BCE_Reviewable.ReviewPeriod);
    }
    if (benefit.BeforeClaimEscalation) {
        return _self.filter(x => x.BeforeClaimEscalation.toLowerCase().includes(benefit.BeforeClaimEscalation.toLowerCase()));
    }

};

Array.prototype.fetchAllBenefitUids = function () {
    let _self = this as any as Array<BenefitModule.IBenefitPattern>;
    return _self.map(a => a.UID);
};

Array.prototype.fetchAllPremiumUid = function () {
    let _self = this as any as Array<PremiumPatternModule.PremiumPattern>;
    return _self.map(a => a.UID);
};

Array.prototype.filterPremiumPatterns = function (rateTableData) {
    let _self = this as any as Array<PremiumPatternModule.PremiumPattern>;
    let filteredPremiumPatternSet: PremiumPatternModule.PremiumPattern[] = [];
    _self.forEach(premium => {
        rateTableData.forEach(rateTable => {
            rateTable.PremiumPatterns.forEach(data => {
                if (premium.UID === data.UID) {
                    filteredPremiumPatternSet.push(premium);
                }
            });
        });
    });
    return filteredPremiumPatternSet;
};

Array.prototype.filterPatternIncrease = function (premiumPattern) {
    let _self = this as any as Array<PremiumPatternModule.PremiumPattern>;
    let levelPremiumData: PremiumPatternModule.PremiumPattern[] = [];

    if (premiumPattern.IndexLinkedPremium || premiumPattern.FixedIncreasePremium) {
        return _self.filter(x => x.PremiumEscalationType.toLowerCase().includes(premiumPattern.PremiumEscalationType.toLowerCase()));
    } else {
        levelPremiumData.push(premiumPattern);
        return levelPremiumData;
        //return _self.filter(x => x.PremiumEscalationType.toLowerCase()
        //    .includes(premiumPattern.PremiumEscalationType.toLowerCase()));
    }
};

Array.prototype.filterPremiumPatternTerm = function (premiumPattern) {
    let _self = this as any as Array<PremiumPatternModule.PremiumPattern>;
    if (premiumPattern.FixedIncreasePremium) {
        return _self.filter(x => x.PremiumEscalationType.toLowerCase().includes(premiumPattern.PremiumEscalationType.toLowerCase()) && x.FixedIncreasePremium.OverallEscalation === premiumPattern.FixedIncreasePremium.OverallEscalation);
    }
    if (premiumPattern.IndexLinkedPremium) {
        return _self.filter(x => x.PremiumEscalationType.toLowerCase().includes(premiumPattern.PremiumEscalationType.toLowerCase()) && x.IndexLinkedPremium.AdditionalEscalation === premiumPattern.IndexLinkedPremium.AdditionalEscalation);
    } else {
        return _self.filter(x => x.PremiumEscalationType.toLowerCase() === premiumPattern.PremiumEscalationType.toLowerCase());
    }
};


Array.prototype.fetchPremiumNames = function () {
    let _self = this as any as Array<PremiumPatternModule.PremiumPattern>;
    let premiumPatternArray: PremiumPattern[] = [];
    _self.forEach(data => {
        let patternData: PremiumPattern = new PremiumPattern();
        if (data.PremiumEscalationType) {
            if (data.IndexLinkedPremium) {
                patternData.name = data.IndexLinkedPremium.Index.Name;
                patternData.value = data;
            } else {
                patternData.name = data.PremiumEscalationType;
                patternData.value = data;
            }
        }
        premiumPatternArray.push(patternData);
    });
    return premiumPatternArray;
};

Array.prototype.deleteDuplicatePremiumNames = function () {
    let _self = this as any as Array<PremiumPattern>;
    let filteredPatternSet = _self.filter((element, index, array) =>
        index === array.findIndex((data) =>
            data.name.toLowerCase() === element.name.toLowerCase())
    );
    return filteredPatternSet;

};

Array.prototype.deleteDuplicatePremiumIndex = function () {
    let _self = this as any as Array<PremiumPatternModule.PremiumPattern>;
    let filteredPatternSet = _self.filter((element, index, array) =>
        index === array.findIndex((data) =>
            data.PremiumEscalationType ? data.IndexLinkedPremium ? data.IndexLinkedPremium.Index.Name === element.IndexLinkedPremium.Index.Name : data.PremiumEscalationType === element.PremiumEscalationType : null)
    );
    return filteredPatternSet;

};

Array.prototype.deleteDuplicateBenefitIncreases = function () {
    let _self = this as any as Array<BenefitModule.IBenefitPattern>;
    let filteredPatternSet = _self.filter((element, index, array) =>
        index === array.findIndex((data) =>
            data.BeforeClaimEscalation_2.BCE_Fixed ? data.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation === element.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation :
                data.BeforeClaimEscalation_2.BCE_IndexLinked ? data.BeforeClaimEscalation_2.BCE_IndexLinked.BCAdditionalEscalation === data.BeforeClaimEscalation_2.BCE_IndexLinked.BCAdditionalEscalation :
                    data.BeforeClaimEscalation_2.BCE_Reviewable ? data.BeforeClaimEscalation_2.BCE_Reviewable.ReviewPeriod === element.BeforeClaimEscalation_2.BCE_Reviewable.ReviewPeriod : null)
    );
    return filteredPatternSet;

};

Array.prototype.deleteDuplicateBenefitTerms = function () {
    let _self = this as any as Array<BenefitModule.IBenefitPattern>;
    let filteredPatternSet = _self.filter((element, index, array) =>
        index === array.findIndex((data) =>
            data.CoverInForceCeaseAge.toLowerCase() === element.CoverInForceCeaseAge.toLowerCase())
    );
    return filteredPatternSet;

};

Array.prototype.getTotalPremiumAmount = function () {
    let _self = this as any as Array<ProductListModule.ISelectedProductDetails>;
    let totalPremiumAmount = 0;
    if (_self.length > 0) {
        _self.forEach(x => {
            if (x.premiumAmount) {
                totalPremiumAmount = totalPremiumAmount + x.premiumAmount;
            }
        });
    }
    return totalPremiumAmount;
};

Array.prototype.fetchProductList = function () {
    let _self = this as any as Array<ProductListModule.ISelectedProductDetails>;
    let productList = new Array<{ productSectionName: string, products: Array<ProductListModule.ISelectedProductDetails> }>();
    let groupedResult = _.groupBy(_self.reverse(), 'quoteId');
    // tslint:disable-next-line: forin
    for (let key in groupedResult) {
        let filteredProductList = _.groupBy(groupedResult[key], 'productCategory');
        let productCategory = groupedResult[key].find(x => x.isMainProduct === true).productCategory;
        productList.push({ productSectionName: productCategory, products: groupedResult[key] });
    }
    return productList;
};

export { };






