<div bsModal #associatedProductAssuredExceededModal="bs-modal">

  <div class="modal-header d-flex flex-row justify-content-between align-items-center">

    <h2 class="swal2-title mt-0 mb-0">{{header}}</h2>

    <button type="button" class="close p-0 m-0" (click)="close()">

      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body mp-0 mt-10 d-flex flex-column justify-content-center align-items-center">

    <div class="swal2-icon swal2-warning swal2-icon-show" style="display: flex;">

      <div class="swal2-icon-content">!</div>
    </div>

    <h2 class="swal2-title mt-0 mb-3">Sum assured mismatch</h2>

    <div>
      {{title}}
    </div>

    <div class="d-flex flex-row justify-content-center align-items-center mt-4 ml-5 mr-5" style="width: 450px;">
      {{mainProduct}} sum assured value does not match {{associatedProduct}}. Would you like to lower the {{mainProduct}} to match? 
    </div>

    <div class="d-flex flex-row justify-content-center align-items-center mt-4 mb-5 ml-5 mr-5">
      <button style="width: 150px" (click)="assign()"
        class="btn btn-primary kt-btn--pill btn-pill btn-gradient-clr mr-3">
        <small class="font-weight-bold">
          Yes
        </small>
      </button>
      <button style="width: 150px" (click)="close()"
      class="btn btn-primary kt-btn--pill btn-pill btn-gradient-clr mr-3">
      <small class="font-weight-bold">
        No
      </small>
    </button>
    </div>
    
  </div>
</div>