import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import { bounceIn } from '@shared/animations/routerTransition';
import { ClaimModifyServiceProxy, HealthCareApiServiceProxy } from '@shared/service-proxies/service-proxies';
import { BsModalRef } from 'ngx-bootstrap';
import { HraAssessmentBaseComponent } from '../hra-assessment-base/hra-assessment-base.component';

@Component({
    selector: 'hra-intro-modal',
    templateUrl: './hra-intro-modal.component.html',
    styleUrls: ['./hra-intro-modal.component.css'],
    animations: [bounceIn()]
})
export class HraIntroModalComponent extends HraAssessmentBaseComponent implements OnInit {
    saving: boolean;
    @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private modalRef: BsModalRef,
        private router: Router,
        injector: Injector,
        authGuard: AuthGuard,
        claimService: ClaimModifyServiceProxy,
        healthCareService: HealthCareApiServiceProxy,
        claimModifyService: ClaimModifyServiceProxy
    ) {
        super(injector, authGuard, claimService, healthCareService, claimModifyService);
    }

    ngOnInit() {
        abp.ui.setBusy();
        this.questionnaireKey = 'Intro';

        this.setQuestionnaire().then(() => {
            abp.ui.clearBusy();
        });
    }

    async submit(): Promise<void> {
        await super.submit(true);
        this.modalRef.hide();
        this.onClose.next(true);
    }

    redirect(): void {
        this.modalRef.hide();
        this.router.navigateByUrl('/app/main/MyHealthCare');
    }
}
