<div class="modal-header">
    <img width="200" src="https://elevateme.blob.core.windows.net/publicimages/elevatebrand.jpg" />
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body border-0 text-center">
    <div class="d-block">
        <h5 class="font-weight-bold pb-5">
            Unfortunately, we aren't able to tailor any products to match your needs today :(
        </h5>

        <img width="150" src="../../../../../assets/common/images/message.png" />

        <h5 class="font-weight-bold pb-5">
            But this shouldn't be the case for long!
        </h5>
        <a target="_blank" href="https://www.elevate.co.za/how-it-works/">
            <u>Find out more</u>
        </a>
    </div>



</div>
<div class="modal-footer">
    <a href="https://www.elevate.co.za/" class="btn btn-danger">
        OK
    </a>
</div>