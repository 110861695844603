import { Component, OnInit, Injector, NgZone, ViewEncapsulation, ViewChild, ChangeDetectorRef, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { GetActiveCalorieDto, GetActiveMinutesDto, GetDistanceDto, GetStepsDto, GetAllSourceAndInfoDTO, Last7Days, ClaimModifyServiceProxy, HumanAppsDto, SyncingHumanApiServiceServiceProxy, TokenClassDto, ModifyClaimDto, GetGoalsDto, SmsBody } from '@shared/service-proxies/service-proxies';
import { IPoint, DATE, string } from '@amcharts/amcharts4/core';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { error } from 'util';
import { throwError } from 'rxjs';
import { MyWearablesService } from '@app/shared/services/my-wearables.service';
import { WidgetMyActiveMinutesComponent } from '@app/shared/common/widgets/my-wearables-widgets/widget-my-active-minutes/widget-my-active-minutes.component';
import { WidgetMyTotalDistanceComponent } from '@app/shared/common/widgets/my-wearables-widgets/widget-my-total-distance/widget-my-total-distance.component';
import { WidgetMySyncedActivitiesComponent } from '@app/shared/common/widgets/my-wearables-widgets/widget-my-synced-activities/widget-my-synced-activities.component';
import { WidgetMyStepsComponent } from '@app/shared/common/widgets/my-wearables-widgets/widget-my-steps/widget-my-steps.component';
import { WidgetMyActiveCaloriesComponent } from '@app/shared/common/widgets/my-wearables-widgets/widget-my-active-calories/widget-my-active-calories.component';
import { WidgetLastSevenDaysComponent } from '@app/shared/common/widgets/my-wearables-widgets/widget-last-seven-days/widget-last-seven-days.component';
import { MyWearablesModalComponent } from './my-wearables-modal/my-wearables-modal.component';
declare function HumanApiConnect(serverAdd, entityNumber, publicToken, accessTokenIDSVR): any;
declare var formComponent: any;
declare var abphc: any;

@Component({
    selector: 'app-my-wearables',
    templateUrl: './my-wearables.component.html',
    styleUrls: ['./my-wearables.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class MyWearablesComponent extends AppComponentBase implements OnInit, AfterViewInit {

    constructor(injector: Injector,
        private zone: NgZone,
        private _claimModify: ClaimModifyServiceProxy,
        private _syncingHumanApiServiceServiceProxy: SyncingHumanApiServiceServiceProxy,
        public _wearableService: MyWearablesService,
        private _changeDetecter: ChangeDetectorRef
    ) {
        super(injector);
    }


    @ViewChildren('widgetmyactiveminutes')
    public widgetmyactiveminutes: QueryList<WidgetMyActiveMinutesComponent>;

    @ViewChildren('widgetmytotaldistance')
    public widgetmytotaldistance: QueryList<WidgetMyTotalDistanceComponent>;

    @ViewChildren('appwidgetmysyncedactivities')
    public appwidgetmysyncedactivities: QueryList<WidgetMySyncedActivitiesComponent>;

    @ViewChildren('appwidgetmyactivecalories')
    public appwidgetmyactivecalories: QueryList<WidgetMyActiveCaloriesComponent>;

    @ViewChildren('appwidgetmysteps')
    public appwidgetmysteps: QueryList<WidgetMyStepsComponent>;

    @ViewChildren('appwidgetlastsevendays')
    public appwidgetlastsevendays: QueryList<WidgetLastSevenDaysComponent>;


    private widgetLastSevenDaysComponent: WidgetLastSevenDaysComponent;
    private widgetMyActiveCaloriesComponent: WidgetMyActiveCaloriesComponent;
    private widgetMyStepsComponent: WidgetMyStepsComponent;
    private widgetMyActiveMinutesComponent: WidgetMyActiveMinutesComponent;
    private widgetMyTotalDistanceComponent: WidgetMyTotalDistanceComponent;
    private widgetMySyncedActivitiesComponent: WidgetMySyncedActivitiesComponent;


    ststs = true;
    chartFilterTimePeriod = [{
        id: 1,
        Name: 'Week',
        IsSelected: true
    },
    {
        id: 2,
        Name: 'Month',
        IsSelected: false
    },
    {
        id: 3,
        Name: 'Year',
        IsSelected: false
    }];
    private chart: any;
    ipoint: IPoint = { x: 0, y: 1 };
    openBullet: any;
    activeCalories: GetActiveCalorieDto[] = new Array<GetActiveCalorieDto>();
    imgSrc: any = '../../../assets/common/images/chatblack.svg';
    Humanappdto: HumanAppsDto = new HumanAppsDto();
    saving = false;
    publicToken: any;
    chartXAxistest = 'LAST 7 DAYS';
    wearableenum = true;
    activeMinues: GetActiveMinutesDto[] = new Array<GetActiveMinutesDto>();
    distanceDto: GetDistanceDto[] = new Array<GetDistanceDto>();
    stepsDto: GetStepsDto[] = new Array<GetStepsDto>();
    getAllSyncedAct: GetAllSourceAndInfoDTO[] = new Array<GetAllSourceAndInfoDTO>();
    last7Days: Last7Days = new Last7Days();
    mlist = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    ifActivated: boolean = undefined;
    goalsMessages: GetGoalsDto[] = new Array<GetGoalsDto>();
    goalsMessage = '';
    vo2change = true;
    distance = true;
    MyActiveMinval = true;
    heartRatechange = true;
    fitBitchange = true;
    currentUserInfo: any;
    showchart: string;
    currentUserQuery: string;

    ngOnInit() {
        formComponent = this;
        abphc = abp;
        this.showchart = 'week';
        this.getPageData();
    }
    getChartsForWeek() {
        this._changeDetecter.detectChanges();

        if (this.widgetmyactiveminutes.first || this.widgetmytotaldistance.first || this.appwidgetmyactivecalories.first || this.appwidgetmysteps.first) {
            this.callWetherTherisNoChange();
            return;
        }

        this.widgetmyactiveminutes.changes.subscribe((comps: QueryList<WidgetMyActiveMinutesComponent>) => {
            this.widgetMyActiveMinutesComponent = comps.first;
            this.widgetMyActiveMinutesComponent.getActiveMinutesWeekOrMonth(this.chartXAxistest, this.showchart).then(() => {
                abp.ui.clearBusy();
            });
        });

        this.widgetmytotaldistance.changes.subscribe((comps: QueryList<WidgetMyTotalDistanceComponent>) => {
            this.widgetMyTotalDistanceComponent = comps.first;
            this.widgetMyTotalDistanceComponent.getDistanceTravelledWeek(this.chartXAxistest, this.showchart).then(() => {
                abp.ui.clearBusy();
            });
        });


        this.appwidgetmysteps.changes.subscribe((comps: QueryList<WidgetMyStepsComponent>) => {
            this.widgetMyStepsComponent = comps.first;
            this.widgetMyStepsComponent.getStepsCurrentWeek(this.chartXAxistest, this.showchart).then(() => {
                abp.ui.clearBusy();
            });
        });


        this.appwidgetmyactivecalories.changes.subscribe((comps: QueryList<WidgetMyActiveCaloriesComponent>) => {
            this.widgetMyActiveCaloriesComponent = comps.first;
            this.widgetMyActiveCaloriesComponent.getActiveCalorieWeek(this.chartXAxistest, this.showchart).then(() => {
                abp.ui.clearBusy();
            });
        });

    }

    callWetherTherisNoChange() {
        this._changeDetecter.detectChanges();

        this.widgetMyActiveMinutesComponent = this.widgetmyactiveminutes.first;
        this.widgetMyTotalDistanceComponent = this.widgetmytotaldistance.first;
        this.widgetMyActiveCaloriesComponent = this.appwidgetmyactivecalories.first;
        this.widgetMyStepsComponent = this.appwidgetmysteps.first;

        Promise.all([
            this.widgetMyActiveMinutesComponent.getActiveMinutesWeekOrMonth(this.chartXAxistest, this.showchart),
            this.widgetMyTotalDistanceComponent.getDistanceTravelledWeek(this.chartXAxistest, this.showchart),
            this.widgetMyActiveCaloriesComponent.getActiveCalorieWeek(this.chartXAxistest, this.showchart),
            this.widgetMyStepsComponent.getStepsCurrentWeek(this.chartXAxistest, this.showchart)
        ]).then(() => {
            abp.ui.clearBusy();
        });
    }

    getNonYearFilterData() {
        this.appwidgetlastsevendays.changes.subscribe((comps: QueryList<WidgetLastSevenDaysComponent>) => {
            this.widgetLastSevenDaysComponent = comps.first;
            this.widgetLastSevenDaysComponent.getLast7Async();
        });

        this.appwidgetmysyncedactivities.changes.subscribe((comps: QueryList<WidgetMySyncedActivitiesComponent>) => {
            this.widgetMySyncedActivitiesComponent = comps.first;
            this.widgetMySyncedActivitiesComponent.getAllSyncedIntenseCount();
        });
    }

    callWetherTherisNoChangeNonYearFilterData() {

        this.widgetMySyncedActivitiesComponent = this.appwidgetmysyncedactivities.first;
        this.widgetMySyncedActivitiesComponent.getAllSyncedIntenseCount();

        this.widgetLastSevenDaysComponent = this.appwidgetlastsevendays.first;
        this.widgetLastSevenDaysComponent.getLast7Async();
    }

    ngAfterViewInit(): void {
    }

    getPageData() {
        abp.ui.setBusy();
        this.updateClaimsIdsvr().then(() => {
            this._wearableService.getGoalsAndCheckIfEntityRegistered().then(res => {
                abp.ui.clearBusy();
                if (this._wearableService.isMyDataWearableActive() === true) {
                    this.getChartsForWeek();
                    if (this.appwidgetmysyncedactivities.first || this.appwidgetlastsevendays.first) {
                        this.callWetherTherisNoChangeNonYearFilterData();
                        return;
                    }
                    this.getNonYearFilterData();
                } else {
                    abp.ui.clearBusy();
                }
            });
        });
    }

    defaultFilter(id) {

        switch (id) {
            case 1: {
                this.weekChange();
                break;
            }

            case 2: {
                this.monthChange();
                break;
            }

            case 3: {
                this.yearChange();
                break;
            }

            default: {
                this.weekChange();
                break;
            }
        }
        this.chartFilterTimePeriod.forEach(x => {
            x.IsSelected = false;
        });
    }

    initializeChart() {
    }

    determineWindowsWidth() {
        if (window.innerWidth < 700) {
            return false;
        } else {
            return true;
        }
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    ActivateUserOnConnect() {
        this.getPageData();
        this.updateClaimsIdsvr().then(() => {
            this._wearableService.getGoalsAndCheckIfEntityRegistered();
        });
    }

    getIfActivated() {
        this.getPageData();
    }

    weekChange() {
        this.chartXAxistest = 'LAST 7 DAYS ';
        this.showchart = 'week';
        this.getChartsForWeek();
    }

    monthChange() {
        this.chartXAxistest = 'LAST 4 WEEKS';
        this.showchart = 'month';
        this.getWearableMonth();
    }

    yearChange() {
        this.chartXAxistest = 'LAST 12 MONTHS';
        this.showchart = 'year';
        this.getWearablesYear();
    }

    getAccessToken(): string {
        return this._authService.user.access_token;
    }


    async getWearables() {
        if (this.getAccessToken() !== undefined) {

        }
    }

    getWearableMonth() {
        Promise.all([
            this.widgetMyActiveCaloriesComponent.getActiveCalorieWeek(this.chartXAxistest, this.showchart),
            this.widgetMyActiveMinutesComponent.getActiveMinutesWeekOrMonth(this.chartXAxistest, this.showchart),
            this.widgetMyTotalDistanceComponent.getDistanceTravelledWeek(this.chartXAxistest, this.showchart),
            this.widgetMyStepsComponent.getStepsCurrentWeek(this.chartXAxistest, this.showchart)
        ]).then(() => {
            abp.ui.clearBusy();
        });
    }

    getWearablesYear() {
        if (this.getAccessToken() !== undefined) {
            Promise.all([
                this.widgetMyActiveCaloriesComponent.getActiveCalorieYear(this.chartXAxistest, this.showchart),
                this.widgetMyActiveMinutesComponent.getActiveMinutesYear(this.chartXAxistest, this.showchart),
                this.widgetMyTotalDistanceComponent.getDistanceTravelledYear(this.chartXAxistest, this.showchart),
                this.widgetMyStepsComponent.getStepsCurrentYear(this.chartXAxistest, this.showchart)
            ]).then(() => {
                abp.ui.clearBusy();
            });
        } else {
            abp.ui.clearBusy();
        }
    }

    ConnectHumanApi() {
        let entityNumber = this._authGuard.user.profile.sub;
        let serverAdd = abp.setting.get('ServerRootAddress');
        this.saving = true;
        let wd = new ModifyClaimDto();
        if (this._authGuard.user.profile.allow_wearable === 'false') {
            this.ifActivated = true;
            wd.accessToken = this._authService.user.access_token;
            wd.userId = this._authGuard.user.profile.sub;
            wd.newClaimType = 'allow_wearable';
            wd.newClaimValue = 'true';
            wd.oldClaimType = 'allow_wearable';
            wd.oldClaimValue = 'false';
            this._claimModify.modifyClaim(wd).subscribe(res => {
                this.updateClaimsIdsvr();
                this._syncingHumanApiServiceServiceProxy.getPublicAccessTokenAsync(this._authService.user.access_token).subscribe(result => {
                    this.publicToken = result.publicToken;
                    HumanApiConnect(serverAdd, entityNumber, this.publicToken, this._authService.user.access_token);
                    this.getPageData();
                });
            });
        } else {
            this._syncingHumanApiServiceServiceProxy.getPublicAccessTokenAsync(this._authService.user.access_token).subscribe(result => {
                this.publicToken = result.publicToken;
                HumanApiConnect(serverAdd, entityNumber, this.publicToken, this._authService.user.access_token);
            });
        }
    }

    registerEntity(err, sessionTokenObject) {
        abphc.ui.setBusy();
        let tdto = new TokenClassDto();
        tdto.accessToken = this._authService.user.access_token;
        tdto.clientId = sessionTokenObject.clientId;
        tdto.entityNumber = this._authGuard.user.profile.sub;
        tdto.humanId = sessionTokenObject.humanId;
        tdto.userId = sessionTokenObject.userId;
        tdto.sessionToken = sessionTokenObject.sessionToken;
        tdto.birthDate = this._authGuard.user.profile.birthdate;
        tdto.gender = this._authGuard.user.profile.gender;

        this._syncingHumanApiServiceServiceProxy.registerEntity(tdto).subscribe(res => {
            if (res) {
                this.ifActivated = res;
                let wd = new ModifyClaimDto();
                wd.accessToken = this._authService.user.access_token;
                wd.userId = this._authGuard.user.profile.sub;
                wd.newClaimType = 'allow_wearable';
                wd.newClaimValue = 'true';
                wd.oldClaimType = 'allow_wearable';
                wd.oldClaimValue = 'false';
                this._claimModify.modifyClaim(wd).subscribe(res => {
                    this.updateClaimsIdsvr();
                    abp.ui.clearBusy();
                    this.getPageData();
                    this.callCharts(true);
                    abp.message.success('Please note that activity data can take upto <strong>1 hour</strong> to be visualized.', 'Wearable data source activated!', true);
                    let d = document.getElementsByClassName('swal2-confirm') as any;
                    d[0].classList.add('modal-button-clr');
                    d[0].classList.add('border-radius-55');


                    d[0].innerText = 'CLOSE';
                });
            }

        }, (err) => {
            abp.ui.clearBusy();
        });

    }
    callCharts(item) {


    }

    callOnClose(item) {
        this._syncingHumanApiServiceServiceProxy.isEntityRegistered(this._authService.user.access_token).subscribe(result => {
            if (!result) {
                let wd = new ModifyClaimDto({
                    accessToken: this._authService.user.access_token,
                    newClaimType: 'allow_wearable',
                    newClaimValue: 'false',
                    oldClaimType: 'allow_wearable',
                    oldClaimValue: 'true',
                    userId: this._authGuard.user.profile.sub,
                    userClaimEditDtos: null
                });

                this._claimModify.modifyClaim(wd).subscribe(res => {
                    this.updateClaimsIdsvr();
                });
            }
        });
    }

    sendsms(PageName?) {
        let body = new SmsBody();
        body.userid = this._authGuard.user.profile.sub;
        body.firstName = this._authGuard.user.profile.given_name;
        body.lastName = this._authGuard.user.profile.family_name;
        body.email = this._authGuard.user.profile.email;
        body.phoneNumber = Array.isArray(this._authGuard.user.profile.phone_number) ? this._authGuard.user.profile.phone_number[0] : this._authGuard.user.profile.phone_number;
        body.pageName = PageName;
        body.message = this.currentUserQuery;
        this.sendQueryEmail(body);
        this.currentUserQuery = '';
    }
}
