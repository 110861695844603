<!-- BEGIN: Topbar -->
<div *ngIf="isUserLoggedIn" class="m-topbar info-panel float-left m-stack m-stack--ver m-stack--general"
    style="z-index:1;">
    <div class="m-subheader">
        <div class="row align-items-center">
            <div class="mr-auto col-auto">
                <h3 class="m-subheader__title m-subheader__title--separator custom-clr">
                    <span>
                        {{getHeaderText()}}
                    </span>
                </h3>
                <span *ngIf="!isExtQuote()" class="m-section__sub">
                    {{"quote" | localize}}
                </span>
            </div>

        </div>
    </div>
</div>

<div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general"
    [ngClass]="{'w-100': !isUserLoggedIn}">

    <nav *ngIf="isUserLoggedIn" class="m-stack__item m-topbar__nav-wrapper" [attr.aria-label]="l('TopMenu')">
        <ul class="m-topbar__nav m-nav m-nav--inline" aria-label="TopMenu" role="menubar">

            <li headerNotifications
                class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width"
                m-dropdown-toggle="click" data-dropdown-persistent="true" id="header_notification_bar"></li>

            <li class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                m-dropdown-toggle="click">
                <a href="#" class="m-nav__link m-dropdown__toggle">
                    <span class="m-topbar__username user-name m--icon-font-size-sm1 font-weight-bold">
                        <i *ngIf="isImpersonatedLogin" class="fa fa-reply m--font-danger"></i>
                        HI {{firstName | uppercase}}
                    </span>
                    <span class="m-topbar__userpic">
                        <img *ngIf="profilePicture" alt=""
                            class="header-profile-picture m--img-rounded m--marginless m--img-centered"
                            [src]="profilePicture" />
                    </span>
                </a>
                <div class="m-dropdown__wrapper">
                    <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                    <div class="m-dropdown__inner">
                        <div class="m-dropdown__header m--align-center elevategrad">
                            <div class="m-card-user m-card-user--skin-dark">
                                <div class="m-card-user__pic">
                                    <img *ngIf="isDefaultProfilePicture" alt=""
                                        class="header-profile-picture m--img-rounded m--marginless m--img-centered"
                                        [src]="profilePictureWhite" />
                                    <img *ngIf="!isDefaultProfilePicture" alt=""
                                        class="header-profile-picture m--img-rounded m--marginless m--img-centered"
                                        [src]="profilePicture" />
                                </div>
                                <div class="m-card-user__details">
                                    <span class="m-card-user__name m--font-weight-500">
                                        {{firstName}} {{lastName}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="m-dropdown__body">
                            <nav class="m-dropdown__content" [attr.aria-label]="l('UserAccount')">
                                <ul class="m-nav m-nav--skin-light" [attr.aria-label]="l('UserAccount')" role="menu">
                                    <li *ngIf="isImpersonatedLogin" class="m-nav__item" role="none">
                                        <a href="javascript:;" class="m-nav__link" (click)="backToMyAccount()"
                                            role="menuitem" aria-haspopup="true" aria-expanded="false" tabindex="-1">
                                            <i class="m-nav__link-icon fa fa-reply m--font-danger"></i>
                                            <span class="m-nav__link-title">
                                                <span class="m-nav__link-wrap">
                                                    <span id="UserProfileBackToMyAccountButton"
                                                        class="m-nav__link-text">
                                                        {{"BackToMyAccount" | localize}}
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                    <li *ngIf="isImpersonatedLogin" class="m-nav__separator m-nav__separator--fit"
                                        role="none"></li>

                                    <li class="m-nav__separator m-nav__separator--fit" role="none"></li>
                                    <li class="m-nav__item" role="none">
                                        <a href="{{currentUrlForChangePassword}}" class="m-nav__link" role="menuitem"
                                            aria-haspopup="true" aria-expanded="false" tabindex="-1">
                                            <i class="m-nav__link-icon flaticon-more-v6"></i>
                                            <span class="m-nav__link-title">
                                                <span class="m-nav__link-wrap">
                                                    <span id="UserProfileChangePasswordLink" class="m-nav__link-text">
                                                        {{"ChangePassword" | localize}}
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>

                                    <li class="m-nav__item" role="none">
                                        <a href="javascript:;" class="m-nav__link" (click)="changeProfilePicture()"
                                            role="menuitem" aria-haspopup="true" aria-expanded="false" tabindex="-1">
                                            <i class="m-nav__link-icon flaticon-profile-1"></i>
                                            <span class="m-nav__link-title">
                                                <span class="m-nav__link-wrap">
                                                    <span id="UserProfileChangePictureLink" class="m-nav__link-text">
                                                        {{"ChangeProfilePicture" | localize}}
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                    <li class="m-nav__item" role="none">
                                        <a href="javascript:;" class="m-nav__link" (click)="changeMySettings()"
                                            role="menuitem" aria-haspopup="true" aria-expanded="false" tabindex="-1">
                                            <i class="m-nav__link-icon flaticon-cogwheel"></i>
                                            <span class="m-nav__link-title">
                                                <span class="m-nav__link-wrap">
                                                    <span id="UserProfileMySettingsLink" class="m-nav__link-text">
                                                        {{"MySettings" | localize}}
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>

                                    <li class="m-nav__item" role="none">
                                        <a routerLink="/app/main/my-permission" class="m-nav__link" role="menuitem"
                                            aria-haspopup="true" aria-expanded="false" tabindex="-1">
                                            <i class="m-nav__link-icon flaticon-medical"></i>
                                            <span class="m-nav__link-title">
                                                <span class="m-nav__link-wrap">
                                                    <span class="m-nav__link-text">
                                                        {{"MyPermission" | localize}}
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>

                                    <li class="m-nav__separator m-nav__separator--fit" role="none"></li>
                                    <li class="m-nav__item" role="none">
                                        <a href="javascript:;" (click)="logout()"
                                            class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder button-gradient"
                                            role="menuitem" aria-haspopup="true" aria-expanded="false"
                                            tabindex="-1">{{"Logout" | localize}}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </nav>

    <nav *ngIf="!isUserLoggedIn"
        class="m-stack__item m-topbar__nav-wrapper d-flex flex-row justify-content-between flex-wrap"
        [attr.aria-label]="l('TopMenu')">

        <ul class="m-topbar__nav m-nav m-nav--inline m-0 ml-auto mr-auto" aria-label="TopMenu" role="menubar">
            <li class="m-nav__item m-dropdown--mobile-full-width" style="vertical-align:baseline; cursor: pointer;">
                <a href="tel:+27110307184" class="m-nav__link p-0" role="menuitem" tabindex="-1">
                    <span class="m-nav__link-title">
                        <span class="m-nav__link-wrap">
                            <span class="m-nav__link-text d-flex flex-row align-items-center">
                                <i id="support-tel-icon" class="la la-phone fa-2x mr-1"></i>
                                <div id="support-tel" *ngIf="!isMobileView_New()"
                                    class="font-weight-bold hover-underline">+27 11 030 7184</div>
                            </span>
                        </span>
                    </span>
                </a>
            </li>
        </ul>

        <ul class="m-topbar__nav m-nav m-nav--inline m-0" aria-label="TopMenu" role="menubar"
            [ngClass]="{'m-auto': isMobileView_New()}">
            <li class="m-nav__item m-dropdown--mobile-full-width" style="vertical-align:baseline">
                <a href="https://www.elevate.co.za" target="_blank" class="m-nav__link p-0" role="menuitem"
                    tabindex="-1">
                    <span class="m-nav__link-title">
                        <span class="m-nav__link-wrap">
                            <span style="color: #4fc2c0 !important;font-weight: bold !important"
                                class="m-nav__link-text ">
                                Home
                            </span>
                        </span>
                    </span>
                </a>
            </li>
            <li class="m-nav__item m-dropdown--mobile-full-width" style="vertical-align:baseline">
                <a href="https://www.elevate.co.za/our-story/" target="_blank" class="m-nav__link p-0" role="menuitem"
                    tabindex="-1">
                    <span class="m-nav__link-title">
                        <span class="m-nav__link-wrap">
                            <span style="color: black !important;" class="m-nav__link-text text-black">
                                Our Story
                            </span>
                        </span>
                    </span>
                </a>

            </li>
            <li class="m-nav__item m-dropdown--mobile-full-width" style="vertical-align:baseline">
                <a href="https://www.elevate.co.za/life-protection/" target="_blank" class="m-nav__link p-0"
                    role="menuitem" tabindex="-1">
                    <span class="m-nav__link-title">
                        <span class="m-nav__link-wrap">
                            <span style="color: black !important;" class="m-nav__link-text text-black">
                                Offering
                            </span>
                        </span>
                    </span>
                </a>

            </li>
            <li class="m-nav__item m-dropdown--mobile-full-width" style="vertical-align:baseline">
                <a href="https://www.elevate.co.za/how-it-works/" target="_blank" class="m-nav__link p-0"
                    role="menuitem" tabindex="-1">
                    <span class="m-nav__link-title">
                        <span class="m-nav__link-wrap">
                            <span style="color: black !important;" class="m-nav__link-text text-black">
                                How it works
                            </span>
                        </span>
                    </span>
                </a>

            </li>
            <li *ngIf="!isMobileView_New()" class="m-nav__item m-dropdown--mobile-full-width"
                style="vertical-align:baseline">
                <a href="https://www.elevate.co.za/blog/" target="_blank" class="m-nav__link p-0" role="menuitem"
                    tabindex="-1">
                    <span class="m-nav__link-title">
                        <span class="m-nav__link-wrap">
                            <span style="color: black !important;" class="m-nav__link-text text-black">
                                Blog
                            </span>
                        </span>
                    </span>
                </a>

            </li>

            <li *ngIf="!isMobileView_New()" class="m-nav__item m-dropdown--mobile-full-width"
                style="vertical-align:baseline">
                <a href="https://www.elevate.co.za/contact-us/" target="_blank" class="m-nav__link p-0" role="menuitem"
                    tabindex="-1">
                    <span class="m-nav__link-title">
                        <span class="m-nav__link-wrap">
                            <span style="color: black !important;" class="m-nav__link-text text-black">
                                Contact Us
                            </span>
                        </span>
                    </span>
                </a>
            </li>

            <!-- <li class="m-nav__item m-dropdown--mobile-full-width" style="vertical-align:baseline">
                <a *ngIf="!isUserLoggedIn && !isMobileView_New()" href="https://www.elevate.co.za/" target="_blank"
                    style="text-align:center" class="m-nav__link p-0" role="menuitem" tabindex="-1">
                    <div class="d-block pr-3" style="padding-top:13px;">
                        <span class="font-size-13 teal-text-clr font-weight-bold">Want to know more?</span>
                        <p class="font-size-13 teal-text-clr">Visit our Website.</p>
                    </div>
                    <span><img width="60"
                            src="../../../assets/common/images/Elevate Me _ Web App - Event Landing Page - Website.png" /></span>
                </a>
            </li> -->
        </ul>
    </nav>

</div>
<!-- END: Topbar -->